import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { Formik } from "formik";
import React from "react";
import { useIntl, FormattedMessage } from "react-intl"; // import ReactSVG from "react-svg";

import { Button, ButtonLink } from "@components/atoms";
import { InputSelect, TextField } from "@components/molecules";
import { commonMessages } from "@temp/intl";
import * as S from "./styles"; // import cameraDelivery from "../../../../images/delivery/cameraDelivery.svg";
// import addGreenDelivery from "../../../../images/delivery/addGreenDelivery.svg";

export var AccountUpdateForm = function AccountUpdateForm(_ref) {
  var handleSubmit = _ref.handleSubmit,
      hide = _ref.hide,
      initialValues = _ref.initialValues;
  var intl = useIntl();
  var identityCardTypeOptions = React.useMemo(function () {
    return [{
      name: "Cédula de ciudadanía",
      value: "CC"
    }, {
      name: "Pasaporte",
      value: "PP"
    }, {
      name: "Cédula de extranjería",
      value: "CE"
    }, {
      name: "NIT",
      value: "NIT"
    }];
  }, []);

  var _React$useState = React.useState((identityCardTypeOptions === null || identityCardTypeOptions === void 0 ? void 0 : identityCardTypeOptions.find(function (identityCardType) {
    return identityCardType.value.toLowerCase() === initialValues.identityCardType.toLowerCase();
  })) || (identityCardTypeOptions === null || identityCardTypeOptions === void 0 ? void 0 : identityCardTypeOptions.find(function (identityCardType) {
    return identityCardType.value === "CC";
  }))),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      identityCardTypeValue = _React$useState2[0],
      setIdentityCardTypeValue = _React$useState2[1];

  var handleIdentityCardTypeChange = function handleIdentityCardTypeChange(value) {
    setIdentityCardTypeValue(value);
  }; // const fileInputRef = React.useRef<HTMLInputElement>(null);
  // const [
  //   userImage,
  //   setUserImage,
  // ] = React.useState<FormData | null>();
  // const handleImageClick = () => {
  //   fileInputRef?.current?.click();
  // };
  // const comprimirImagen = (imagenComoArchivo: any, porcentajeCalidad: any) =>
  //   new Promise((resolve, reject) => {
  //     const canvas = document.createElement("canvas");
  //     const imagen = new Image();
  //     imagen.onload = () => {
  //       canvas.width = imagen.width;
  //       canvas.height = imagen.height;
  //       canvas?.getContext("2d")?.drawImage(imagen, 0, 0);
  //       canvas.toBlob(
  //         blob => {
  //           if (blob === null) {
  //             return reject(blob);
  //           } else {
  //             resolve(blob);
  //           }
  //         },
  //         "image/jpeg",
  //         porcentajeCalidad / 100
  //       );
  //     };
  //     imagen.src = URL.createObjectURL(imagenComoArchivo);
  //     return imagen;
  //   });
  // const handleChangeImage = async (e: any) => {
  //   if (e.target.files[0].size > 4000000) {
  //     // setPhotoError(true);
  //   } else {
  //     const imagen = e.target.files[0];
  //     const { name: imagenName } = imagen;
  //     const blob: any = await comprimirImagen(imagen, 18);
  //     blob.name = imagenName;
  //     setUserImage(blob);
  //     const blobUrl = URL.createObjectURL(blob);
  //     document?.getElementById("showimage")?.setAttribute("src", blobUrl);
  //   }
  // };


  return React.createElement(React.Fragment, null, React.createElement(Formik, {
    initialValues: initialValues,
    onSubmit: function onSubmit(values, _ref2) {
      var setSubmitting = _ref2.setSubmitting;
      handleSubmit({
        firstName: values.firstName,
        identityCardType: identityCardTypeValue.value,
        identityCard: values.identityCard,
        userEmail: values.userEmail
      });
      setSubmitting(false);
    }
  }, function (_ref3) {
    var handleChange = _ref3.handleChange,
        handleSubmit = _ref3.handleSubmit,
        handleBlur = _ref3.handleBlur,
        values = _ref3.values,
        isSubmitting = _ref3.isSubmitting,
        isValid = _ref3.isValid;
    return React.createElement(S.Form, {
      onSubmit: handleSubmit,
      "data-test": "accountUpdateForm"
    }, React.createElement(S.ContentEditOneLine, null, React.createElement(S.ContentExtendInput, null, React.createElement(TextField, {
      name: "firstName",
      label: intl.formatMessage(commonMessages.firstName),
      type: "text",
      value: values.firstName,
      onBlur: handleBlur,
      onChange: handleChange
    })), React.createElement(S.ContentExtendSelect, null, React.createElement(InputSelect, {
      id: "identityCardType",
      defaultValue: identityCardTypeValue,
      name: "identityCardType",
      options: identityCardTypeOptions.length > 0 ? identityCardTypeOptions : [],
      value: identityCardTypeValue,
      label: intl.formatMessage(commonMessages.IDType),
      onChange: handleIdentityCardTypeChange,
      optionLabelKey: "name",
      optionValueKey: "value",
      autoComplete: "identityCardType"
    })), React.createElement(S.ContentExtendInput, null, React.createElement(TextField, {
      name: "identityCard",
      label: intl.formatMessage(commonMessages.ID),
      type: "text",
      value: values.identityCard,
      onBlur: handleBlur,
      onChange: handleChange
    })), React.createElement(S.ContentExtendInput, null, React.createElement(TextField, {
      name: "userEmail",
      label: intl.formatMessage(commonMessages.eMail),
      type: "text",
      value: values.userEmail,
      onBlur: handleBlur,
      onChange: handleChange
    }))), React.createElement(S.FormButtons, null, React.createElement(ButtonLink, {
      testingContext: "cancelButton",
      type: "button",
      color: "secondary",
      onClick: hide
    }, React.createElement(FormattedMessage, commonMessages.cancel)), React.createElement(Button, {
      testingContext: "submit",
      type: "submit",
      disabled: isSubmitting,
      size: "sm"
    }, React.createElement(FormattedMessage, commonMessages.save))));
  }));
};