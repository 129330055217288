import { useSubcategoriesAndCategoriesSaleByTrade } from "@saleor/sdk";
import { useEffect } from "react";
import { updateSubcategoriesCategoriesSaleLoaded, useAppDispatch, useAppSelector } from "@temp/redux";
import { organizeDataCategories } from "../utils";
export var useQuerySaleCategoriesSubcategories = function useQuerySaleCategoriesSubcategories() {
  var dispatch = useAppDispatch();

  var _useAppSelector = useAppSelector(function (store) {
    return store.search;
  }),
      subcategoriesCategoriesSaleLoaded = _useAppSelector.subcategoriesCategoriesSaleLoaded;

  var fetchPolicyValue = subcategoriesCategoriesSaleLoaded ? "cache-first" : "network-only";

  var _useSubcategoriesAndC = useSubcategoriesAndCategoriesSaleByTrade({
    fetchPolicy: "".concat(fetchPolicyValue)
  }),
      data = _useSubcategoriesAndC.data,
      loading = _useSubcategoriesAndC.loading;

  var organizeCategoriesData = organizeDataCategories(data === null || data === void 0 ? void 0 : data.subcategoriesCategoriesSaleTrade);
  var categoriesData = Object.values(organizeCategoriesData);
  useEffect(function () {
    if (!loading && !subcategoriesCategoriesSaleLoaded && (data === null || data === void 0 ? void 0 : data.subcategoriesCategoriesSaleTrade.length) > 0) {
      dispatch(updateSubcategoriesCategoriesSaleLoaded(true));
    }
  }, [loading]);
  return {
    categoriesDataJSON: organizeCategoriesData,
    categoriesData: categoriesData,
    loading: loading
  };
};