import { createSlice } from "@reduxjs/toolkit";
var initialState = {
  ordersAmount: 0
};
export var deliveryOrdersAmountByUserSlice = createSlice({
  name: "deliveryOrdersAmountByUser",
  initialState: initialState,
  reducers: {
    updateOrdersAmountByUser: function updateOrdersAmountByUser(state, action) {
      state.ordersAmount = action.payload.number.ordersAmount;
    }
  }
});
var updateOrdersAmountByUser = deliveryOrdersAmountByUserSlice.actions.updateOrdersAmountByUser;
export { updateOrdersAmountByUser };