import * as React from "react";
import Media from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "@saleor/sdk";
import { xMediumScreen } from "@styles/constants";
import { AccountMenuMobile } from "@components/molecules";
import { OrdersHistory } from "@pages";
import AddressBook from "../../account/AddressBook/AddressBook";
import { accountUrl, addressBookUrl, baseDeliveryUrl, orderHistoryUrl, orderInProgressUrl } from "../../app/routes";
import { Loader, MainMenu, Footer } from "../../components";
import "./scss/index.scss";
import { AccountMedia } from "./AccountMedia";

var returnTab = function returnTab(path, userDetails, navigate) {
  var tabContent = React.createElement(React.Fragment, null);

  switch (path) {
    case addressBookUrl:
      {
        tabContent = React.createElement(AddressBook, {
          user: userDetails
        });
        break;
      }

    case orderHistoryUrl:
      {
        tabContent = React.createElement(OrdersHistory, {
          navigate: navigate
        });
        break;
      }

    case orderInProgressUrl:
      {
        tabContent = React.createElement(OrdersHistory, {
          navigate: navigate
        });
        break;
      }

    case accountUrl:
    default:
      tabContent = React.createElement(AccountMedia, null);
      break;
  }

  return tabContent;
};

var Account = function Account() {
  var _user$role, _user$role2, _user$role3;

  var _useAuth = useAuth(),
      user = _useAuth.user,
      loaded = _useAuth.loaded;

  var location = useLocation();
  var navigate = useNavigate();
  var links = (user === null || user === void 0 ? void 0 : (_user$role = user.role) === null || _user$role === void 0 ? void 0 : _user$role.toUpperCase()) === "CUSTOMER" ? [accountUrl, addressBookUrl] : [accountUrl, baseDeliveryUrl];

  if (!loaded) {
    return React.createElement(Loader, null);
  }

  var canShowMenu = React.useCallback(function () {
    return location.pathname !== orderInProgressUrl;
  }, [location.pathname, orderInProgressUrl]);
  var getActiveLink = React.useCallback(function () {
    if (links.indexOf(location.pathname) > -1) {
      return location.pathname;
    }

    return accountUrl;
  }, [location.pathname]);
  return React.createElement(React.Fragment, null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(MainMenu, {
    goback: false,
    gobackHome: false,
    search: false,
    cart: false
  })), React.createElement(Media, {
    maxWidth: xMediumScreen
  }, React.createElement(MainMenu, {
    goback: false,
    gobackHome: (user === null || user === void 0 ? void 0 : (_user$role2 = user.role) === null || _user$role2 === void 0 ? void 0 : _user$role2.toUpperCase()) === "CUSTOMER",
    gobackAssignedOrders: (user === null || user === void 0 ? void 0 : (_user$role3 = user.role) === null || _user$role3 === void 0 ? void 0 : _user$role3.toUpperCase()) === "DELIVERY_MAN",
    search: false,
    cart: false
  })), React.createElement("div", {
    className: "container"
  }, React.createElement("div", {
    className: "account"
  }, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(Footer, null), React.createElement("div", {
    className: "account__menu"
  })), canShowMenu() && location.pathname !== orderHistoryUrl && React.createElement(Media, {
    maxWidth: xMediumScreen
  }, React.createElement("div", {
    className: "account__menu_mobile"
  }, React.createElement(AccountMenuMobile, {
    links: links,
    active: getActiveLink()
  }))), React.createElement("div", {
    className: "account__content"
  }, returnTab(location.pathname, user, navigate)))));
};

Account.displayName = "Account";
export default Account;