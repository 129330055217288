import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject17() {
  var data = _taggedTemplateLiteral(["\n  width: 35px;\n  height: 35px;\n  margin-left: 10px;\n  display: inline-block;\n  align-self: center;\n  line-height: 35px;\n  background-color: #afafaf;\n  color: white;\n  border-radius: 50%;\n  text-align: center;\n"]);

  _templateObject17 = function _templateObject17() {
    return data;
  };

  return data;
}

function _templateObject16() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  margin-top: 1rem;\n  button {\n    margin: 0 auto;\n    border-radius: 10px;\n  }\n"]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = _taggedTemplateLiteral(["\n  text-transform: uppercase;\n  text-align: center;\n"]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = _taggedTemplateLiteral(["\n  text-transform: uppercase;\n  text-align: center;\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\n  text-transform: uppercase;\n  text-align: center;\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n  text-transform: uppercase;\n  text-align: center;\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  text-transform: uppercase;\n  text-align: center;\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  text-transform: uppercase;\n  text-align: center;\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  padding: 15px 10px;\n  margin: 0 auto;\n  margin-top: 20px;\n  display: grid;\n  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;\n  border-radius: 10px;\n  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n  padding-left: 25px;\n  font-size: 1.5rem;\n  font-weigth: bold;\n  text-transform: lowercase;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n  @media (max-width: 850px) {\n    padding-left: 0px;\n    padding-top: 20px;\n  }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  align-self: center;\n  font-size: 1rem;\n  text-transform: lowercase;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  width: 110px;\n  display: grid;\n  grid-template-columns: 40% 40%;\n  position: fixed;\n  top: 5rem;\n  left: 270px;\n  color: #f76268;\n  cursor: pointer;\n  border-radius: 6px;\n  background-color: white;\n  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);\n  cursor: pointer;\n  font-size: 1rem;\n  text-transform: uppercase;\n  z-index: 9999;\n  div {\n    height: 35px;\n    align-self: center;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  svg {\n    transform: scale(0.4);\n  }\n  @media (min-width: 1500px) {\n    left: 330px;\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 70px;\n  text-align: center;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: center;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  margin-left: -5% !important;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  @media (min-width: 850px) {\n    margin-top: 70px;\n  }\n  @media (min-width: 950px) {\n    width: 70vw;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 70vh;\n  display: flex;\n  justify-content: center;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var WrapperLoader = styled.div(_templateObject());
export var WrapperAll = styled.div(_templateObject2());
export var Footer = styled.div(_templateObject3());
export var Image = styled.div(_templateObject4());
export var SubTitle = styled.div(_templateObject5());
export var BackTitle = styled.div(_templateObject6());
export var BackTitleParragraph = styled.p(_templateObject7());
export var Tile = styled.p(_templateObject8());
export var TableHead = styled.div(_templateObject9());
export var HeadShop = styled.p(_templateObject10());
export var HeadNumberOrder = styled.p(_templateObject11());
export var HeadOrder = styled.p(_templateObject12());
export var HeadStatus = styled.p(_templateObject13());
export var HeadDate = styled.p(_templateObject14());
export var HeadDetail = styled.p(_templateObject15());
export var Wrapper = styled.div(_templateObject16());
export var TitleAmount = styled.p(_templateObject17());