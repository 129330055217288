import React from "react";
import { FormattedMessage } from "react-intl";
import { Money } from "@components/containers";
import { useCart } from "@saleor/sdk";
import * as S from "./styles";
/**
 * Address summary
 */

var TotalSummary = function TotalSummary() {
  var _useCart = useCart(),
      shippingPrice = _useCart.shippingPrice,
      subtotalPrice = _useCart.subtotalPrice,
      totalPrice = _useCart.totalPrice,
      discount = _useCart.discount;

  return React.createElement(React.Fragment, null, shippingPrice && subtotalPrice && totalPrice && React.createElement(S.Wrapper, null, React.createElement(S.TotalLabel, null, React.createElement("span", null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.TotalSummary.TotalSummary.781550514",
    defaultMessage: "Subtotal"
  }), ":", " "), React.createElement("span", null, React.createElement(Money, {
    "data-test": "checkoutReviewSubTotalPrice",
    money: subtotalPrice === null || subtotalPrice === void 0 ? void 0 : subtotalPrice.net
  })), " ", React.createElement("br", null)), React.createElement(S.TotalLabel, null, React.createElement("span", null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.TotalSummary.TotalSummary.3967708147",
    defaultMessage: "Cost of shipping"
  }), ":", " "), React.createElement("span", null, React.createElement(Money, {
    "data-test": "checkoutReviewShippingPrice",
    money: shippingPrice
  })), " ", React.createElement("br", null)), discount && discount.amount > 0 && React.createElement(S.TotalLabel, null, React.createElement("span", null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.TotalSummary.TotalSummary.293722515",
    defaultMessage: "discount"
  }), ":", " "), React.createElement("span", null, " -", React.createElement(Money, {
    "data-test": "checkoutReviewDiscountPrice",
    money: discount
  })), " ", React.createElement("br", null)), React.createElement(S.TotalLabel, null, React.createElement("span", null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.TotalSummary.TotalSummary.878013594",
    defaultMessage: "Total"
  }), ":", " "), React.createElement("span", null, React.createElement(Money, {
    "data-test": "checkoutReviewSubTotalPrice",
    money: totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.net
  })), React.createElement("br", null))));
};

export { TotalSummary };