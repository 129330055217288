import * as React from "react";
import ReactSVG from "react-svg";
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useAuth, useOrdersInProgressAmountByUser } from "@saleor/sdk";
import { CurrentTradeDetailsContext } from "@temp/contexts";
import "./scss/index.scss";
import { removeScrollItems } from "@temp/core/utils";
import * as paths from "../../app/routes/paths";
import { baseDeliveryUrl } from "../../app/routes/pathsDelivery";

var shopImage = require("../../images/nav/shop.svg");

var hallWaysImage = require("../../images/nav/aisles.svg");

var offersImage = require("../../images/nav/offers.svg"); // const favoritesImage = require("../../images/nav/favorites.svg");


var profileImage = require("../../images/nav/profile.svg");

var restaurantImage = require("../../images/nav/restaurant.svg");

var myOrdersImageDesktop = require("../../images/myOrdersImgDesktop.svg");

var easyPedidoImage = require("../../images/EasyPedidoImg.svg");

var NavItem = function NavItem(props) {
  var className = props.linkTo === window.location.pathname ? "footer__lateral-container__link footer__lateral-container__link--active" : "footer__lateral-container__link";
  var color = props.linkTo === window.location.pathname ? "yellow" : "white";
  var classNameLabel = props.linkTo === window.location.pathname ? "active" : "";
  var classNameSvg = props.className === "orders" ? "orders" : "";

  var _useOrdersInProgressA = useOrdersInProgressAmountByUser({
    fetchPolicy: "network-only"
  }),
      dataOrderInProgressAmount = _useOrdersInProgressA.data,
      loadingOrderInProgressAmount = _useOrdersInProgressA.loading;

  return React.createElement(NavLink, {
    onClick: function onClick() {
      removeScrollItems();

      if (props.handleOnClick) {
        props.handleOnClick();
      }
    },
    to: props.linkTo,
    className: className
  }, React.createElement(ReactSVG, {
    path: props.svgPath,
    svgStyle: {
      fill: color
    },
    className: classNameSvg
  }), React.createElement("span", {
    className: classNameLabel
  }, props.label), dataOrderInProgressAmount !== null && dataOrderInProgressAmount.ordersInProgressAmount > 0 && !loadingOrderInProgressAmount && props.linkTo === paths.orderHistoryUrl && React.createElement("div", null, React.createElement("p", {
    className: "footer__orderInProgress__navLateral"
  }, dataOrderInProgressAmount.ordersInProgressAmount)));
};

var NavLateral = function NavLateral(props) {
  var _dataAttention$curren, _user$role, _user$role2;

  var _useAuth = useAuth(),
      user = _useAuth.user;

  var restaurant = function restaurant(user) {
    var _user$currentTrade, _user$currentTrade$tr;

    var indexFinalData = (_user$currentTrade = user.currentTrade) === null || _user$currentTrade === void 0 ? void 0 : (_user$currentTrade$tr = _user$currentTrade.tradeTypes) === null || _user$currentTrade$tr === void 0 ? void 0 : _user$currentTrade$tr.findIndex(function (data) {
      return data.uniqueType.includes(5);
    });

    if (indexFinalData !== -1) {
      return true;
    }

    return false;
  };

  var restaurantValue = restaurant(user);

  var _React$useContext = React.useContext(CurrentTradeDetailsContext),
      dataAttention = _React$useContext.data,
      loadingAttention = _React$useContext.loading;

  var handleOnClickSaleLink = React.useCallback(function () {
    localStorage.setItem("sale_search_clean", " true");
  }, []);
  var handleOnClickHomeLink = React.useCallback(function () {
    localStorage.setItem("home_search_clean", " true");
  }, []);
  return user.currentTrade ? React.createElement("div", {
    className: "footer__lateral",
    id: "footer",
    style: !loadingAttention && dataAttention && !(dataAttention === null || dataAttention === void 0 ? void 0 : (_dataAttention$curren = dataAttention.currentTrade) === null || _dataAttention$curren === void 0 ? void 0 : _dataAttention$curren.hasAttentionAtCurrentTime) ? {
      top: "145px"
    } : {}
  }, React.createElement("footer", {
    className: "footer__lateral-nav"
  }, React.createElement("div", {
    className: "footer__lateral-user-container-container"
  }, React.createElement("div", {
    className: "footer__lateral-container"
  }, restaurantValue ? React.createElement(NavItem, {
    label: React.createElement(FormattedMessage, {
      id: "components.Footer.NavLateral.1119152923",
      defaultMessage: "Menu"
    }),
    linkTo: paths.baseUrl,
    svgPath: restaurantImage,
    handleOnClick: function handleOnClick() {
      return handleOnClickHomeLink();
    }
  }) : React.createElement(NavItem, {
    label: React.createElement(FormattedMessage, {
      id: "components.Footer.NavLateral.3069060397",
      defaultMessage: "Shop"
    }),
    linkTo: paths.baseUrl,
    svgPath: shopImage,
    handleOnClick: function handleOnClick() {
      return handleOnClickHomeLink();
    }
  }), restaurantValue && !props.withOutCategories ? React.createElement(NavItem, {
    label: React.createElement(FormattedMessage, {
      id: "components.Footer.NavLateral.3583204912",
      defaultMessage: "Categories"
    }),
    linkTo: paths.categoriesUrl,
    svgPath: hallWaysImage
  }) : !props.withOutCategories && React.createElement(NavItem, {
    label: React.createElement(FormattedMessage, {
      id: "components.Footer.NavLateral.3583204912",
      defaultMessage: "Categories"
    }),
    linkTo: paths.categoriesUrl,
    svgPath: hallWaysImage
  }), React.createElement(NavItem, {
    label: React.createElement(FormattedMessage, {
      id: "components.Footer.NavLateral.468441986",
      defaultMessage: "Sale"
    }),
    linkTo: paths.saleUrl,
    svgPath: offersImage,
    handleOnClick: function handleOnClick() {
      return handleOnClickSaleLink();
    }
  }), React.createElement(NavItem, {
    label: React.createElement(FormattedMessage, {
      id: "components.Footer.NavLateral.2364847682",
      defaultMessage: "Orders"
    }),
    linkTo: paths.orderHistoryUrl,
    svgPath: myOrdersImageDesktop,
    className: "orders"
  }))), React.createElement("div", {
    className: "footer__lateral-contact-session-container"
  }, React.createElement(ReactSVG, {
    path: easyPedidoImage
  }))), React.createElement("div", {
    className: "footer__lateral-slogan"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.Footer.NavLateral.1370108609",
    defaultMessage: "the domis of your neighborhood in a single whatsapp contact"
  })))) : React.createElement("div", {
    className: "footer__lateral",
    id: "footer"
  }, React.createElement("footer", {
    className: "footer__lateral-nav"
  }, React.createElement("div", {
    className: "footer__lateral-user-container-container"
  }, React.createElement("div", {
    className: "footer__lateral-container"
  }, React.createElement(NavItem, {
    label: React.createElement(FormattedMessage, {
      id: "components.Footer.NavLateral.1534468327",
      defaultMessage: "Profile"
    }),
    linkTo: paths.accountUrl,
    svgPath: profileImage
  }), React.createElement(NavItem, {
    label: (user === null || user === void 0 ? void 0 : (_user$role = user.role) === null || _user$role === void 0 ? void 0 : _user$role.toUpperCase()) === "CUSTOMER" ? React.createElement(FormattedMessage, {
      id: "components.Footer.NavLateral.1045389126",
      defaultMessage: "My orders"
    }) : React.createElement(FormattedMessage, {
      id: "components.Footer.NavLateral.3256806584",
      defaultMessage: "Assigned orders"
    }),
    linkTo: (user === null || user === void 0 ? void 0 : (_user$role2 = user.role) === null || _user$role2 === void 0 ? void 0 : _user$role2.toUpperCase()) === "CUSTOMER" ? paths.orderHistoryUrl : baseDeliveryUrl,
    svgPath: myOrdersImageDesktop,
    className: "orders"
  }))), React.createElement("div", {
    className: "footer__lateral-contact-session-container"
  }, React.createElement(ReactSVG, {
    path: easyPedidoImage
  }))), React.createElement("div", {
    className: "footer__lateral-slogan"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.Footer.NavLateral.1370108609",
    defaultMessage: "the domis of your neighborhood in a single whatsapp contact"
  }))));
};

export default NavLateral;