// colors
export var autofillColor = "#9f4880";
export var autofillColorSelected = "#AF8294";
export var baseFontColor = "#323232";
export var baseFontColorSemiTransparent = "rgba(50,50,50,0.6)";
export var baseFontColorTransparent = "rgba(50,50,50,0.1)";
export var black = "#323232";
export var blue = "rgb(33,18,94)";
export var blueDark = "#9f4880";
export var blueLight = "#AF8294";
export var blueOverlay = "#E52528";
export var blueOverlayDark = "#9f4880";
export var defaultColor = "#F76268";
export var discount = "#F76268";
export var gray = "#7d7d7d";
export var grayMedium = "#c4c4c4";
export var grayDark = "#323232";
export var grayLight = "#EFEFF2";
export var grayStrong = "#e5d1cd";
export var green = "#3ed256";
export var overlayColor = "rgba(199, 207, 207, 0.8)";
export var rose = "#c22d74";
export var turquoise = "#ED1C24";
export var turquoiseDark = "#ED1C24";
export var turquoiseLight = "#ED1C24";
export var turquoiseTransparent = "rgba(6, 132, 123, 0.1)";
export var tertiaryGrey = "#8C8C8C";
export var white = "#fff";
export var tabelGray = "#eaeaea";
export var darkGreen = "#06847B";
export var secondaryGrey = "#EBF1F6";
export var secondaryGreyDark = "#9D9FB1";
export var secondaryBlue = "#036DFF";
export var red = "#E52528"; // theme colors

export var theme = {
  activeMenuOption: turquoise,
  autofill: autofillColor,
  autofillSelected: autofillColorSelected,
  bannerBackground: secondaryGrey,
  bannerEdge: secondaryGreyDark,
  bannerLink: secondaryBlue,
  baseFont: baseFontColor,
  baseFontColorSemiTransparent: baseFontColorSemiTransparent,
  baseFontColorTransparent: baseFontColorTransparent,
  discount: discount,
  dark: black,
  disabled: gray,
  divider: grayLight,
  dividerDark: grayMedium,
  error: rose,
  hoverLightBackground: turquoiseLight,
  item: tertiaryGrey,
  lightStrong: grayStrong,
  light: grayLight,
  lightFont: gray,
  listAttributeName: baseFontColorSemiTransparent,
  listBullet: darkGreen,
  overlay: overlayColor,
  primary: turquoise,
  primaryDark: turquoiseDark,
  primaryLight: turquoiseLight,
  primaryTransparent: turquoiseTransparent,
  secondary: red,
  secondaryDark: blueDark,
  secondaryLight: blueLight,
  secondaryOverlay: blueOverlay,
  secondaryOverlayDark: blueOverlayDark,
  success: defaultColor,
  tabTitle: darkGreen,
  tableDivider: tabelGray,
  tabsBorder: baseFontColorTransparent,
  thumbnailBorder: darkGreen,
  white: white
}; // typography

export var baseFontFamily = "'Inter', sans-serif";
export var baseFontSize = "1rem"; // 16px

export var baseLineHeight = "1.25rem"; // 20px

export var boldFontWeight = 600;
export var extraBoldFontWeight = 800;
export var h1FontSize = "4rem"; // 64px

export var h2FontSize = "3rem"; // 48px

export var h1LineHeight = 1;
export var h3FontSize = "1.5rem"; // 24px

export var h4FontSize = "1.125rem"; // 18px

export var labelFontSize = "0.75rem"; // 12px

export var smallFontSize = "0.875rem"; // 14px

export var ultraBigFont = "6rem"; // 96px
// spacing

export var spacer = 1; // rem

export var fieldSpacer = "1.875rem"; // breakpoints

export var xxxLargeScreen = 1920;
export var xxLargeScreen = 1600;
export var xLargeScreen = 1280;
export var largeScreen = 992;
export var xxMediumScreen = 849;
export var xMediumScreen = 850;
export var mediumScreen = 720;
export var smallScreen = 540;