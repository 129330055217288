import React from "react";
import { FormattedMessage } from "react-intl";
import ReactSVG from "react-svg";
import Media from "react-responsive";
import { xMediumScreen } from "@styles/constants";
import { StatusData, StatusDataFooter } from "@components/molecules"; // import { Checkbox, ErrorMessage } from "@components/atoms";

import { statusMessages } from "@temp/intl"; // import { LuzattAcceptanceField } from "../../../../components";

import * as S from "./styles";

var goBackImg = require("../../../../images/arrowBack.svg");

var arrivedImg = require("../../../../images/changeDelivery.gif");

/**
 * sent status structure
 */
var StatusCanceledSent = function StatusCanceledSent(_ref) {
  var _data$lastFulfillment, _data$lastFulfillment2;

  var data = _ref.data,
      goBack = _ref.goBack,
      navigation = _ref.navigation;
  return React.createElement(S.Wrapper, null, React.createElement(S.Tile, null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(S.Title, null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(S.BackTitle, {
    onClick: goBack
  }, React.createElement(ReactSVG, {
    path: goBackImg
  }), React.createElement(S.BackTitleParragraph, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.StatusCanceledSent.StatusCanceledSent.3471378517",
    defaultMessage: "back"
  })))), React.createElement(FormattedMessage, statusMessages.stepNameCanceledSending)))), React.createElement(S.Content, null, React.createElement(Media, {
    maxWidth: xMediumScreen
  }, React.createElement(S.Navigation, null, navigation)), React.createElement(Media, {
    maxWidth: xMediumScreen
  }, React.createElement(S.Title, null, React.createElement(FormattedMessage, statusMessages.stepNameCanceledSending))), React.createElement(S.ContentContainer, null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(S.Navigation, null, navigation)), React.createElement(S.Gif, null, React.createElement("img", {
    alt: "cargando",
    src: arrivedImg
  })), (data === null || data === void 0 ? void 0 : (_data$lastFulfillment = data.lastFulfillment) === null || _data$lastFulfillment === void 0 ? void 0 : _data$lastFulfillment.cancellationReason) && React.createElement(S.Parragraph, null, React.createElement(S.Span, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.StatusCanceledSent.StatusCanceledSent.3077186403",
    defaultMessage: "Reason"
  }), ": "), data === null || data === void 0 ? void 0 : (_data$lastFulfillment2 = data.lastFulfillment) === null || _data$lastFulfillment2 === void 0 ? void 0 : _data$lastFulfillment2.cancellationReason))), React.createElement(S.Aside, null, React.createElement(StatusData, {
    deliveryPhone: false,
    data: data,
    deliveryData: false
  })), React.createElement(S.Footer, null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(StatusDataFooter, {
    data: data
  }))));
};

export { StatusCanceledSent };