import "./scss/index.scss";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSaleData } from "@temp/hooks";
import { useCart } from "@saleor/sdk";
import { initialStateCategory, initialStateSubcategory, initialInputValueAndQueryValue, useAppSelector } from "@temp/redux";
import { generateSaleCategoryProductUrl } from "@temp/core/utils";
import { Skeleton } from "@components/atoms/Skeleton/Skeleton";
import { Sale } from "@temp/components";

var SaleContent = function SaleContent(_ref) {
  var data = _ref.data,
      loading = _ref.loading,
      maxProductsPerCategory = _ref.maxProductsPerCategory;

  var _useAppSelector = useAppSelector(function (state) {
    return state.search;
  }),
      searchQueryValue = _useAppSelector.searchQueryValue,
      searchCategory = _useAppSelector.searchCategory,
      searchSubcategory = _useAppSelector.searchSubcategory;

  var location = useLocation();

  var _useCart = useCart(),
      items = _useCart.items;

  var navigate = useNavigate();

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  var _useSaleData = useSaleData(data, items),
      sendProducts = _useSaleData.sendProducts,
      categoryAmount = _useSaleData.categoryAmount,
      ProductsSaleSubCategory = _useSaleData.ProductsSaleSubCategory;

  React.useEffect(function () {
    localStorage.setItem("product_url", location.pathname);
    localStorage.removeItem("categories_url");
    localStorage.removeItem("category_url");
    localStorage.removeItem("subcategory_url");
    localStorage.removeItem("salesubcategory_url");
    localStorage.removeItem("home_url");
    localStorage.removeItem("loadingLoadMoreSubCategory");
    localStorage.removeItem("loadingLoadMoreSaleCategory");
    localStorage.setItem("sale_url", location.pathname);
    localStorage.setItem("sale_cache", "true");
    return function () {};
  }, []);
  React.useEffect(function () {
    if (!loading && data) {
      if (categoryAmount === 1 && searchQueryValue === initialInputValueAndQueryValue && searchCategory.value === initialStateCategory.value && searchSubcategory.value === initialStateSubcategory.value) {
        var _ProductsSaleSubCateg, _ProductsSaleSubCateg2, _ProductsSaleSubCateg3, _ProductsSaleSubCateg4;

        var saleCategoryUrl = generateSaleCategoryProductUrl((_ProductsSaleSubCateg = ProductsSaleSubCategory[0]) === null || _ProductsSaleSubCateg === void 0 ? void 0 : (_ProductsSaleSubCateg2 = _ProductsSaleSubCateg.category) === null || _ProductsSaleSubCateg2 === void 0 ? void 0 : _ProductsSaleSubCateg2.id, (_ProductsSaleSubCateg3 = ProductsSaleSubCategory[0]) === null || _ProductsSaleSubCateg3 === void 0 ? void 0 : (_ProductsSaleSubCateg4 = _ProductsSaleSubCateg3.category) === null || _ProductsSaleSubCateg4 === void 0 ? void 0 : _ProductsSaleSubCateg4.name);

        if (saleCategoryUrl) {
          navigate("".concat(saleCategoryUrl, "true/"));
        }
      } else {
        localStorage.setItem("sale_cache", "true");
      }
    }

    if (loading) {
      localStorage.removeItem("sale_cache");
      localStorage.removeItem("salesubcategory_url");
    }
  }, [loading, categoryAmount]);

  if (loading) {
    return React.createElement("div", {
      className: "categories-page"
    }, React.createElement("div", {
      className: "categories-pageCate__loading"
    }, React.createElement(Skeleton, null)));
  }

  return React.createElement(Sale, {
    data: data,
    loading: loading,
    sendProducts: sendProducts,
    maxProductsPerCategory: maxProductsPerCategory
  });
};

export default SaleContent;