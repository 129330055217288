import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: block;\n  position: relative;\n  background-color: white;\n  padding: 20px;\n  ", "\n  border-radius: 10px;\n  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);\n  cursor: pointer;\n  z-index: 9;\n  div {\n    width: 100%;\n    display: grid;\n    grid-template-columns: 10% 20% 50% 20%;\n    div {\n      padding-left: 1.5px;\n    }\n  }\n  p {\n    align-self: center;\n    padding-left: 10px;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 20px;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var Tile = styled.label(_templateObject2(), function (props) {
  return props.checked && "background-color: #EFEFF2;";
});