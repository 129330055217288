import "./scss/index.scss";
import * as React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "@temp/redux";
import Page from "./Page";
import { MainMenu, Footer } from "../../components";

var View = function View() {
  var location = useLocation();

  var _useAppSelector = useAppSelector(function (store) {
    return store.categories;
  }),
      categories = _useAppSelector.categories;

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  React.useEffect(function () {
    var scrollProduct = localStorage.getItem("scroll-product");
    var scrollCategories = localStorage.getItem("scroll-categories");

    if (!scrollCategories && !scrollProduct) {
      window.scrollTo(0, 0);
    }

    if (scrollProduct !== null) {
      window.scrollTo(0, +scrollProduct);
      localStorage.removeItem("scroll-product");
    }

    if (scrollCategories !== null) {
      window.scrollTo(0, +scrollCategories);
      localStorage.removeItem("scroll-categories");
    }

    localStorage.removeItem("sale_url");
    localStorage.removeItem("salesubcategory_url");
    localStorage.removeItem("subcategory_url");
    localStorage.removeItem("category_url");
    localStorage.removeItem("home_url");
    localStorage.removeItem("scroll-category");
    localStorage.removeItem("loadingLoadMoreSubCategory");
    localStorage.removeItem("loadingLoadMoreSaleCategory");
    localStorage.setItem("categories_url", location.pathname);
    return function () {};
  }, []);
  return !categories ? React.createElement(Navigate, {
    to: "/"
  }) : React.createElement(React.Fragment, null, React.createElement(MainMenu, {
    goback: false,
    search: true,
    cart: true
  }), React.createElement("div", {
    className: "categories-page"
  }, React.createElement(Page, {
    loading: false,
    categoriesList: categories
  })), React.createElement(Footer, null));
};

export default View;