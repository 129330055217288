import "./scss/index.scss";
import React from "react";
import ReactSVG from "react-svg";
import { FormattedMessage } from "react-intl";
import { Button } from "@components/atoms";

var closeWhite = require("../../images/closewhite.svg"); // const greaterThanEqual = require("../../images/greaterThanEqual.svg");


export var AlertCheckoutValue = function AlertCheckoutValue(_ref) {
  var hideModal = _ref.hideModal,
      minSpent = _ref.minSpent,
      currency = _ref.currency;
  var currencyLocal = currency || "COP";
  var conversorValue = currencyLocal === "COP" ? "es-CO" : undefined;
  var maximumFractionsDigitsValue = currencyLocal === "COP" ? 0 : 2;
  var totalPriceValue = Number(minSpent).toLocaleString(conversorValue, {
    currency: currencyLocal,
    style: "currency",
    minimumFractionDigits: 0,
    maximumFractionDigits: maximumFractionsDigitsValue
  });
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "showContainer",
    onClick: hideModal
  }), React.createElement("div", {
    className: "showContainer__data"
  }, React.createElement("div", {
    className: "showContainer__data__close"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.AlertCheckoutValue.index.709932324",
    defaultMessage: "Alert"
  })), React.createElement(ReactSVG, {
    path: closeWhite,
    onClick: hideModal
  })), React.createElement("div", {
    className: "showContainer__data__content showContainer__data__content--first"
  }), React.createElement("div", {
    className: "showContainer__data__content showContainer__data__content--value showContainer__data__content--first showContainer__data__content--last"
  }, React.createElement(FormattedMessage, {
    id: "components.AlertCheckoutValue.index.2504438373",
    defaultMessage: "The value of the purchase must be greater than <span>{totalPriceValue}</span>",
    description: "Alert minimum per purchase",
    values: {
      totalPriceValue: totalPriceValue,
      span: function span() {
        for (var _len = arguments.length, chunks = new Array(_len), _key = 0; _key < _len; _key++) {
          chunks[_key] = arguments[_key];
        }

        return React.createElement("span", null, chunks);
      }
    }
  })), React.createElement("div", {
    className: "showContainer__data__footer"
  }, React.createElement(Button, {
    type: "button",
    testingContext: "",
    onClick: hideModal
  }, React.createElement(FormattedMessage, {
    id: "components.AlertCheckoutValue.index.321321683",
    defaultMessage: "Acept"
  })))));
};
export default AlertCheckoutValue;