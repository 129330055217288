import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject16() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: fixed;\n  top: 0;\n  left: 0;\n  background: rgba(0, 0, 0, 0.2);\n  z-index: 99999;\n"]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = _taggedTemplateLiteral(["\n  font-weight: bold;\n"]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = _taggedTemplateLiteral(["\n  width: 11rem;\n  height: 4rem;\n  color: black;\n  text-align: center;\n  vertical-align: middle;\n  display: table-cell;\n  font-size: 16pt;\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\n  width: 11rem;\n  height: 10rem;\n  background-color: #95517d;\n  color: #ffff;\n  text-align: center;\n  vertical-align: middle;\n  display: table-cell;\n  font-size: 16pt;\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n  width: 9rem;\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  width: 11rem;\n  height: 10rem;\n  text-align: center;\n  vertical-align: middle;\n  display: table-cell;\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  width: 10rem;\n  height: 10rem;\n  background-color: #95517d;\n  text-align: center;\n  vertical-align: middle;\n  display: table-cell;\n  padding-left: 1rem;\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  font-weight: bold;\n  margin-bottom: 1rem;\n  margin-top: 1rem;\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  padding: 1rem 0.5rem;\n  vertical-align: middle;\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  padding: 1rem 0.5rem;\n  vertical-align: middle;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  width: auto;\n  font-size: 0.875rem;\n  margin: 0rem 0;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  padding-top: 10px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-around;\n  > p {\n    align-self: center;\n  }\n  > span:first-of-type {\n    padding: 10px 20px;\n    color: rgb(247, 98, 104);\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  display: block;\n  padding: 18px;\n  border: 1px;\n  border-color: gray;\n  margin-top: 18px;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  font-weight: bold;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  margin: 0px 18px 18px 18px;\n  input {\n    outline: none;\n  }\n  p {\n    align-self: start;\n  }\n  div {\n    border-radius: 8px;\n  }\n  div + p {\n    margin-bottom: 10px;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  padding-bottom: 10px;\n  position: relative;\n  top: -25px;\n  border-bottom-left-radius: 8px;\n  border-bottom-right-radius: 8px;\n  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Form = styled.form(_templateObject());
export var Field = styled.div(_templateObject2());
export var BoldText = styled.span(_templateObject3());
export var Instructions = styled.div(_templateObject4());
export var Total = styled.div(_templateObject5());
export var Table = styled.table(_templateObject6());
export var Td = styled.td(_templateObject7());
export var PaymentWay = styled.div(_templateObject8());
export var Paragraph = styled.p(_templateObject9());
export var QRContainer = styled.div(_templateObject10());
export var QRContainerMobile = styled.div(_templateObject11());
export var QRImage = styled.img(_templateObject12());
export var Number = styled.div(_templateObject13());
export var NumberMobile = styled.div(_templateObject14());
export var PaymentMethodTitle = styled.p(_templateObject15());
export var Loader = styled.div(_templateObject16());