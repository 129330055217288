import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  padding: ", ";\n  background: white;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  padding: ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  display: flex;\n  position: relative;\n  flex-direction: column;\n  justify-content: space-between;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Modal = styled.div(_templateObject());
export var Content = styled.div(_templateObject2(), function (_ref) {
  var spacing = _ref.theme.spacing;
  return "2rem ".concat(spacing.gutter, " 0.5rem ").concat(spacing.gutter);
});
export var Footer = styled.div(_templateObject3());
export var Loader = styled.div(_templateObject4(), function (props) {
  return "0.5rem ".concat(props.theme.spacing.gutter, " 0.5rem ").concat(props.theme.spacing.gutter);
});