import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  width: auto;\n  height: 250px;\n  align-self: center;\n  order: 2;\n  overflow: hidden;\n  img {\n    width: 100%;\n    height: inherit;\n    object-fit: contain;\n  }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 100%;\n  position: relative;\n  overflow-y: scroll;\n  overflow-x: hidden;\n  scrollbar-width: none;\n  ::-webkit-scrollbar {\n    width: 0px;\n  }\n\n  ul#productGalleryUL {\n    width: 100% !important;\n    height: 100% !important;\n    position: absolute;\n    display: block;\n    padding: 0;\n    margin: 0;\n  }\n\n  ul#productGalleryUL li {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 100%;\n  margin: 0 auto;\n  order: 1;\n  transform: rotate(360deg);\n  position: relative;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  bottom: 0;\n  right: calc(50% - 12.5px);\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  top: 0;\n  left: calc(50% - 12.5px);\n\n  transform: rotate(180deg);\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  height: 20px;\n  width: 25px;\n  position: absolute;\n  z-index: 1;\n  background-color: rgba(50, 50, 50, 0.3);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  width: 76px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-width: 4px;\n  border-style: solid;\n  border-color: ", ";\n  height: 80px;\n  overflow: hidden;\n  img {\n    width: 100%;\n    object-fit: contain;\n  }\n\n  margin-top: 10px;\n  margin-bottom: 10px;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-columns: 20% 80%;\n  height: 100%;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var Thumbnail = styled.div(_templateObject2(), function (props) {
  return props.activeThumbnail === true ? props.theme.colors.thumbnailBorder : "transparent";
});
export var Button = styled.div(_templateObject3());
export var TopButton = styled(Button)(_templateObject4());
export var BottomButton = styled(Button)(_templateObject5());
export var ThumbnailsContainer = styled.div(_templateObject6());
export var ThumbnailList = styled.div(_templateObject7());
export var Preview = styled.div(_templateObject8());