import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { useSaleProduct } from "./useSaleProduct";
export var useSubCategoryData = function useSubCategoryData(data, items, subCategoryId) {
  var _ProductsSubCategory$;

  var ProductsSubCategory = [];
  var subCategoryName = "";
  data === null || data === void 0 ? void 0 : data.edges.forEach(function (productVariant) {
    var _productVariant$node;

    if (productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node = productVariant.node) === null || _productVariant$node === void 0 ? void 0 : _productVariant$node.categoryIdLightLoad) {
      var _productVariant$node3, _productVariant$node19;

      var sumItems = items === null || items === void 0 ? void 0 : items.filter(function (itemsCart) {
        var _itemsCart$variant, _productVariant$node2;

        return (itemsCart === null || itemsCart === void 0 ? void 0 : (_itemsCart$variant = itemsCart.variant) === null || _itemsCart$variant === void 0 ? void 0 : _itemsCart$variant.id) === (productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node2 = productVariant.node) === null || _productVariant$node2 === void 0 ? void 0 : _productVariant$node2.id);
      }).map(function (item) {
        return item.quantity;
      }).reduce(function (a, b) {
        return a + b;
      }, 0);
      var itemsQuantity = sumItems > 0 ? sumItems : null;

      var _useSaleProduct = useSaleProduct(productVariant === null || productVariant === void 0 ? void 0 : productVariant.node),
          discountPercentage = _useSaleProduct.discountPercentage,
          discountValue = _useSaleProduct.discountValue;

      if ((productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node3 = productVariant.node) === null || _productVariant$node3 === void 0 ? void 0 : _productVariant$node3.subCategoryIdLightLoad) === subCategoryId && (ProductsSubCategory === null || ProductsSubCategory === void 0 ? void 0 : ProductsSubCategory.length) === 0) {
        var _productVariant$node4, _productVariant$node5, _productVariant$node6, _productVariant$node7, _productVariant$node8, _productVariant$node9, _productVariant$node10, _productVariant$node11, _productVariant$node12, _productVariant$node13, _productVariant$node14, _productVariant$node15, _productVariant$node16, _productVariant$node17, _productVariant$node18;

        subCategoryName = productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node4 = productVariant.node) === null || _productVariant$node4 === void 0 ? void 0 : _productVariant$node4.subCategoryNameLightLoad;
        ProductsSubCategory.push({
          category: {
            id: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node5 = productVariant.node) === null || _productVariant$node5 === void 0 ? void 0 : _productVariant$node5.categoryIdLightLoad,
            name: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node6 = productVariant.node) === null || _productVariant$node6 === void 0 ? void 0 : _productVariant$node6.categoryNameLightLoad
          },
          subCategory: {
            id: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node7 = productVariant.node) === null || _productVariant$node7 === void 0 ? void 0 : _productVariant$node7.subCategoryIdLightLoad,
            name: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node8 = productVariant.node) === null || _productVariant$node8 === void 0 ? void 0 : _productVariant$node8.subCategoryNameLightLoad
          },
          products: [{
            category: {
              id: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node9 = productVariant.node) === null || _productVariant$node9 === void 0 ? void 0 : _productVariant$node9.categoryIdLightLoad,
              name: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node10 = productVariant.node) === null || _productVariant$node10 === void 0 ? void 0 : _productVariant$node10.categoryNameLightLoad
            },
            subCategory: {
              id: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node11 = productVariant.node) === null || _productVariant$node11 === void 0 ? void 0 : _productVariant$node11.subCategoryIdLightLoad,
              name: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node12 = productVariant.node) === null || _productVariant$node12 === void 0 ? void 0 : _productVariant$node12.subCategoryNameLightLoad
            },
            id: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node13 = productVariant.node) === null || _productVariant$node13 === void 0 ? void 0 : _productVariant$node13.id,
            name: (productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node14 = productVariant.node) === null || _productVariant$node14 === void 0 ? void 0 : _productVariant$node14.name) || (productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node15 = productVariant.node) === null || _productVariant$node15 === void 0 ? void 0 : _productVariant$node15.productNameLightLoad),
            price: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node16 = productVariant.node) === null || _productVariant$node16 === void 0 ? void 0 : _productVariant$node16.priceLightLoad,
            discountPercentage: discountPercentage,
            discountValue: discountValue,
            itemsQuantity: itemsQuantity,
            orderIndexSubcategory: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node17 = productVariant.node) === null || _productVariant$node17 === void 0 ? void 0 : _productVariant$node17.orderIndexSubCategoryLightLoad,
            thumbnail: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node18 = productVariant.node) === null || _productVariant$node18 === void 0 ? void 0 : _productVariant$node18.thumbnailLightLoad,
            __typename: "Product"
          }]
        });
      } else if ((productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node19 = productVariant.node) === null || _productVariant$node19 === void 0 ? void 0 : _productVariant$node19.subCategoryIdLightLoad) === subCategoryId && ProductsSubCategory.length > 0) {
        var _productVariant$node20, _productVariant$node21, _productVariant$node22, _productVariant$node23, _productVariant$node24, _productVariant$node25, _productVariant$node26, _productVariant$node27, _productVariant$node28, _productVariant$node29;

        var tempProducts = ProductsSubCategory[0].products;
        ProductsSubCategory[0].products = [].concat(_toConsumableArray(tempProducts), [{
          category: {
            id: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node20 = productVariant.node) === null || _productVariant$node20 === void 0 ? void 0 : _productVariant$node20.categoryIdLightLoad,
            name: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node21 = productVariant.node) === null || _productVariant$node21 === void 0 ? void 0 : _productVariant$node21.categoryNameLightLoad
          },
          subCategory: {
            id: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node22 = productVariant.node) === null || _productVariant$node22 === void 0 ? void 0 : _productVariant$node22.subCategoryIdLightLoad,
            name: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node23 = productVariant.node) === null || _productVariant$node23 === void 0 ? void 0 : _productVariant$node23.subCategoryNameLightLoad
          },
          id: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node24 = productVariant.node) === null || _productVariant$node24 === void 0 ? void 0 : _productVariant$node24.id,
          name: (productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node25 = productVariant.node) === null || _productVariant$node25 === void 0 ? void 0 : _productVariant$node25.name) || (productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node26 = productVariant.node) === null || _productVariant$node26 === void 0 ? void 0 : _productVariant$node26.productNameLightLoad),
          price: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node27 = productVariant.node) === null || _productVariant$node27 === void 0 ? void 0 : _productVariant$node27.priceLightLoad,
          discountPercentage: discountPercentage,
          discountValue: discountValue,
          itemsQuantity: itemsQuantity,
          orderIndexSubcategory: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node28 = productVariant.node) === null || _productVariant$node28 === void 0 ? void 0 : _productVariant$node28.orderIndexSubCategoryLightLoad,
          thumbnail: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node29 = productVariant.node) === null || _productVariant$node29 === void 0 ? void 0 : _productVariant$node29.thumbnailLightLoad,
          __typename: "Product"
        }]);
      }
    }
  });
  var products = {
    products: (_ProductsSubCategory$ = ProductsSubCategory[0]) === null || _ProductsSubCategory$ === void 0 ? void 0 : _ProductsSubCategory$.products.sort(function (a, b) {
      return a.orderIndex - b.orderIndex;
    })
  };
  return {
    products: products,
    ProductsSubCategory: ProductsSubCategory,
    subCategoryName: subCategoryName
  };
};