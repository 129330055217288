import "./scss/index.scss";
import React from "react";
import ReactSVG from "react-svg";
import { FormattedMessage } from "react-intl";
import Media from "react-responsive";
import { xmediumScreen } from "../../globalStyles/scss/variables.scss";

var closeWhite = require("../../images/closewhite.svg");

var callImg = require("../../images/alert/helpCall.svg"); // const greaterThanEqual = require("../../images/greaterThanEqual.svg");


export var AlertHelp = function AlertHelp(_ref) {
  var hideModal = _ref.hideModal,
      tradeNumber = _ref.tradeNumber,
      orderNumber = _ref.orderNumber;
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "showContainerHelp",
    onClick: hideModal
  }), React.createElement("div", {
    className: "showContainerHelp__data"
  }, React.createElement("div", {
    className: "showContainerHelp__data__close"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.AlertHelp.index.2222696742",
    defaultMessage: "Help"
  })), React.createElement(ReactSVG, {
    path: closeWhite,
    onClick: hideModal
  })), React.createElement("div", {
    className: "showContainerHelp__data__content showContainer__data__content--first"
  }), React.createElement("div", {
    className: "showContainerHelp__data__content  showContainer__data__content--amount showContainer__data__content--first"
  }, React.createElement(FormattedMessage, {
    id: "components.AlertHelp.index.3785590846",
    defaultMessage: "If you have any news please contact the establishment"
  })), React.createElement("div", {
    className: "showContainerHelp__data__content__orderNumber"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.AlertHelp.index.3268682209",
    defaultMessage: "Order number"
  }), ": "), React.createElement("span", null, orderNumber)), React.createElement("div", {
    className: "showContainerHelp__data__footer"
  }, React.createElement(Media, {
    maxWidth: xmediumScreen
  }, function (matches) {
    return matches ? React.createElement("a", {
      href: "tel:".concat(tradeNumber)
    }, React.createElement(ReactSVG, {
      path: callImg
    }), React.createElement("p", null, React.createElement(FormattedMessage, {
      id: "components.AlertHelp.index.3662309742",
      defaultMessage: "call"
    }))) : React.createElement(React.Fragment, null, React.createElement("p", null, React.createElement(FormattedMessage, {
      id: "components.AlertHelp.index.3662309742",
      defaultMessage: "call"
    })), React.createElement("p", null, tradeNumber));
  }))));
};
export default AlertHelp;