import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  query SaleCategoryProductsListLoadMore(\n    $perPage: Int!\n    $after: String\n    $categoryId: Int!\n    $search: String\n  ) {\n    saleCategoryProductsLoadMore(\n      after: $after\n      first: $perPage\n      categoryId: $categoryId\n      search: $search\n      sortBy: { field: SUB_CATEGORY, direction: ASC }\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...ProductsLightLoad\n        }\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedQuery } from "../../core/queries";
import { productsLightLoadFragment } from "../../core/fragments";
var saleCategoryProductsLoadMore = gql(_templateObject(), productsLightLoadFragment);
export var TypedSaleCategoryProductsListLoadMore = TypedQuery(saleCategoryProductsLoadMore);