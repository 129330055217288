import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  p {\n    padding-left: 5px;\n  }\n  span {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n  }\n  svg {\n    width: 20px;\n    height: 20px;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Button = styled.div(_templateObject());