import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React from "react";
import { FormattedMessage } from "react-intl";
import { IconButton } from "@components/atoms";
import { AttributeValuesChecklist } from "@components/molecules";
import { useHandlerWhenClickedOutside } from "@hooks";
import { commonMessages } from "@temp/intl";
import { Overlay } from "..";
import * as S from "./styles";

var checkIfAttributeIsChecked = function checkIfAttributeIsChecked(filters, value, slug) {
  if (filters.attributes && filters.attributes.hasOwnProperty(slug)) {
    if (filters.attributes[slug].find(function (filter) {
      return filter === value.slug;
    })) {
      return true;
    }

    return false;
  }

  return false;
};

export var FilterSidebar = function FilterSidebar(_ref) {
  var hide = _ref.hide,
      filters = _ref.filters,
      show = _ref.show,
      attributes = _ref.attributes,
      target = _ref.target,
      onAttributeFiltersChange = _ref.onAttributeFiltersChange;

  var _useHandlerWhenClicke = useHandlerWhenClickedOutside(function () {
    hide();
  }),
      setElementRef = _useHandlerWhenClicke.setElementRef;

  return React.createElement(Overlay, {
    duration: 0,
    position: "left",
    show: show,
    hide: hide,
    transparent: true,
    target: target
  }, React.createElement(S.Wrapper, {
    ref: setElementRef(),
    "data-test": "filterSidebar"
  }, React.createElement(S.Header, null, React.createElement("span", null, React.createElement(FormattedMessage, commonMessages.filterHeader)), React.createElement(IconButton, {
    testingContext: "hideFilters",
    onClick: hide,
    name: "x",
    size: 18,
    color: "000"
  })), attributes.map(function (_ref2) {
    var id = _ref2.id,
        name = _ref2.name,
        slug = _ref2.slug,
        values = _ref2.values;
    return React.createElement(AttributeValuesChecklist, {
      key: id,
      title: name,
      name: slug,
      values: values.map(function (value) {
        return _objectSpread(_objectSpread({}, value), {}, {
          selected: checkIfAttributeIsChecked(filters, value, slug)
        });
      }),
      valuesShowLimit: true,
      onValueClick: function onValueClick(value) {
        return onAttributeFiltersChange(slug, value.slug);
      }
    });
  })));
};