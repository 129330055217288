import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { ProductList } from "@components/organisms";
import { ChangeAmountProductsContext } from "@temp/contexts";
import { generateCategoryProductUrl } from "../../core/utils";

var OtherProductsPage = function OtherProductsPage(_ref) {
  var _products$products;

  var products = _ref.products,
      productId = _ref.productId,
      subCategoryIdFromDB = _ref.subCategoryIdFromDB,
      subCategoryName = _ref.subCategoryName;

  var _React$useContext = React.useContext(ChangeAmountProductsContext),
      maxProductsPerCategory = _React$useContext.maxProductsPerCategory;

  var finalProducts = products === null || products === void 0 ? void 0 : (_products$products = products.products) === null || _products$products === void 0 ? void 0 : _products$products.filter(function (product) {
    return product.id !== productId;
  });
  var handleClickTo = React.useCallback(function () {
    localStorage.removeItem("scroll-product");
    localStorage.setItem("other-product", "true");
  }, []);
  return (finalProducts === null || finalProducts === void 0 ? void 0 : finalProducts.length) > 0 ? React.createElement("div", {
    className: "product-page__other-products"
  }, React.createElement("div", {
    className: "container"
  }, React.createElement("h4", {
    className: "product-page__other-products__title"
  }, React.createElement(FormattedMessage, {
    id: "views.Product.OtherPage.2558168760",
    defaultMessage: "Other products in"
  }), " ".concat(subCategoryName)), (finalProducts === null || finalProducts === void 0 ? void 0 : finalProducts.length) >= maxProductsPerCategory ? React.createElement(React.Fragment, null, React.createElement("div", {
    className: "product-page__other-products__see-more"
  }, React.createElement(Link, {
    to: generateCategoryProductUrl(subCategoryIdFromDB, subCategoryName),
    onClick: handleClickTo,
    key: subCategoryIdFromDB
  }, React.createElement(FormattedMessage, {
    id: "views.Product.OtherPage.207418477",
    defaultMessage: "See all"
  }))), React.createElement(ProductList, {
    seeMore: true,
    handleClickTo: handleClickTo,
    products: finalProducts === null || finalProducts === void 0 ? void 0 : finalProducts.slice(0, maxProductsPerCategory),
    to: generateCategoryProductUrl(subCategoryIdFromDB, subCategoryName)
  })) : React.createElement(ProductList, {
    products: finalProducts === null || finalProducts === void 0 ? void 0 : finalProducts.slice(0, maxProductsPerCategory)
  }))) : React.createElement(React.Fragment, null);
};

export default OtherProductsPage;