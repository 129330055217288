import "./scss/index.scss";
import React from "react";
import ReactSVG from "react-svg";
import { FormattedMessage } from "react-intl";
import { Button } from "@components/atoms";

var closeWhite = require("../../images/closewhite.svg");

export var AlertBusinessOpening = function AlertBusinessOpening(_ref) {
  var hideModal = _ref.hideModal,
      hour = _ref.hour,
      minutes = _ref.minutes,
      dayName = _ref.dayName,
      hourAmPm = _ref.hourAmPm;
  var min = "".concat(minutes);

  if (minutes <= 9) {
    min = "0".concat(minutes);
  }

  var schedule = "".concat(hour, ":").concat(min, " ").concat(hourAmPm);
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "showBussinesOpening",
    onClick: hideModal
  }), React.createElement("div", {
    className: "showBussinesOpening__data"
  }, React.createElement("div", {
    className: "showBussinesOpening__data__close"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.AlertBusinessOpening.index.709932324",
    defaultMessage: "Alert"
  })), React.createElement(ReactSVG, {
    path: closeWhite,
    onClick: hideModal
  })), React.createElement("div", {
    className: "showBussinesOpening__data__content showBussinesOpening__data__content--first"
  }), dayName ? React.createElement(React.Fragment, null, React.createElement("div", {
    className: "showBussinesOpening__data__content showBussinesOpening__data__content--value showBussinesOpening__data__content--first"
  }, React.createElement(FormattedMessage, {
    id: "components.AlertBusinessOpening.index.1649998565",
    defaultMessage: "Sorry, the establishment is not available for delivery, you can order from ",
    description: "Opening time alert"
  })), React.createElement("div", {
    className: "showBussinesOpening__data__content showBussinesOpening__data__content--value showBussinesOpening__data__content--first showBussinesOpening__data__content--last"
  }, React.createElement(FormattedMessage, {
    id: "components.AlertBusinessOpening.index.1080852925",
    defaultMessage: "<span>{dayName}</span> at \uD83D\uDD56 <span>{schedule}</span>",
    description: "Opening time alert",
    values: {
      dayName: dayName,
      schedule: schedule,
      span: function span() {
        for (var _len = arguments.length, chunks = new Array(_len), _key = 0; _key < _len; _key++) {
          chunks[_key] = arguments[_key];
        }

        return React.createElement("span", null, chunks);
      }
    }
  }))) : React.createElement("div", {
    className: "showBussinesOpening__data__content showBussinesOpening__data__content--value showBussinesOpening__data__content--first"
  }, React.createElement(FormattedMessage, {
    id: "components.AlertBusinessOpening.index.1380313859",
    defaultMessage: "Sorry, the establishment is not available for delivery at this time",
    description: "Opening time alert"
  })), React.createElement("div", {
    className: "showBussinesOpening__data__footer"
  }, React.createElement(Button, {
    type: "button",
    testingContext: "",
    onClick: hideModal
  }, React.createElement(FormattedMessage, {
    id: "components.AlertBusinessOpening.index.321321683",
    defaultMessage: "Acept"
  })))));
};
export default AlertBusinessOpening;