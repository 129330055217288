import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { AddNewTile, TileGrid } from "@components/atoms";
import { AddressTile } from "@components/molecules";
import { xMediumScreen } from "@styles/constants";
import { AccountTab } from "@pages";
import { useAuth } from "@saleor/sdk";

/**
 * Addresses tiles with add new address tile opening address form addition modal.
 */
export var AddressGrid = function AddressGrid(_ref) {
  var addresses = _ref.addresses,
      addNewAddress = _ref.addNewAddress;

  var _useAuth = useAuth(),
      user = _useAuth.user;

  var _React$useState = React.useState(React.createElement(AddNewTile, {
    key: "newTile",
    type: "address",
    onClick: addNewAddress
  })),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      addNewTile = _React$useState2[0],
      setaddNewTile = _React$useState2[1];

  var changeAddNewTile = function changeAddNewTile() {
    if (screen.width <= xMediumScreen) {
      setaddNewTile(React.createElement(AddNewTile, {
        key: "newTile",
        type: "address",
        onClick: addNewAddress
      }));
    } else {
      var _user$role;

      setaddNewTile(React.createElement(React.Fragment, null, React.createElement(AccountTab, null), (user === null || user === void 0 ? void 0 : (_user$role = user.role) === null || _user$role === void 0 ? void 0 : _user$role.toUpperCase()) === "CUSTOMER" && React.createElement(AddNewTile, {
        key: "newTile",
        type: "address",
        onClick: addNewAddress
      })));
    }
  };

  React.useEffect(function () {
    window.addEventListener("resize", changeAddNewTile);
    return function () {
      window.removeEventListener("resize", changeAddNewTile);
    };
  }, []);
  React.useEffect(function () {
    changeAddNewTile();
    return function () {};
  }, []);
  var addressTiles = addresses.reduce(function (elements, address) {
    elements.push(React.createElement(AddressTile, _extends({
      key: "addressTile-".concat(address.id)
    }, address)));
    return elements;
  }, [addNewTile]);
  return React.createElement(TileGrid, {
    columns: 2,
    elements: addressTiles
  });
};