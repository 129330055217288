import * as React from "react";
import ReactSVG from "react-svg";
import Media from "react-responsive";
import { FormattedMessage } from "react-intl";
import { isEqual } from "lodash";
import { TaxedMoney } from "@components/containers";
import { Thumbnail } from "@components/molecules";
import { xLargeScreen } from "@styles/constants";
import { commonMessages } from "@temp/intl";
import "./scss/index.scss";
import percentajeImg from "../../images/ofertadescuenta.svg";
export var TableLineView = React.memo(function (_ref) {
  var allToppingsNameState = _ref.allToppingsNameState,
      allToppingsNameRef = _ref.allToppingsNameRef,
      allToppingsNameSeeLessRef = _ref.allToppingsNameSeeLessRef,
      discountedPercentageValue = _ref.discountedPercentageValue,
      ellipsisToppingsNameDesktopState = _ref.ellipsisToppingsNameDesktopState,
      ellipsisToppingsNameState = _ref.ellipsisToppingsNameState,
      ellipsisToppingsNameMobilepRef = _ref.ellipsisToppingsNameMobilepRef,
      ellipsisToppingsNameDesktopRef = _ref.ellipsisToppingsNameDesktopRef,
      handleSeeMore = _ref.handleSeeMore,
      line = _ref.line,
      name = _ref.name,
      priceUndiscounted = _ref.priceUndiscounted,
      price = _ref.price,
      seeMore = _ref.seeMore,
      unitPriceDefined = _ref.unitPriceDefined,
      thumbnail = _ref.thumbnail;
  return React.createElement("li", {
    "data-test": "cartRow",
    "data-test-id": line
  }, React.createElement(Media, {
    minWidth: xLargeScreen
  }, function (matches) {
    return matches ? React.createElement("div", {
      className: "tableItem"
    }, React.createElement("div", {
      className: "tableItem__image"
    }, React.createElement(Thumbnail, {
      source: thumbnail
    })), React.createElement("div", {
      className: "tableItem__name"
    }, React.createElement("p", null, name)), React.createElement("div", {
      className: "tableItem__toppings"
    }, ellipsisToppingsNameDesktopState !== "" ? React.createElement("p", null, !seeMore ? React.createElement(React.Fragment, null, React.createElement("span", {
      className: "tableItem__toppings__seemore__content",
      ref: ellipsisToppingsNameDesktopRef
    }), React.createElement("span", {
      className: "tableItem__toppings__seemore",
      onClick: handleSeeMore
    }, React.createElement(FormattedMessage, commonMessages.seeMore))) : React.createElement(React.Fragment, null, React.createElement("span", {
      className: "tableItem__toppings__seemore__content",
      ref: allToppingsNameSeeLessRef
    }), React.createElement("span", {
      className: "tableItem__toppings__seemore",
      onClick: handleSeeMore
    }, React.createElement(FormattedMessage, commonMessages.seeLess)))) : allToppingsNameState !== "" && React.createElement("span", {
      ref: allToppingsNameRef,
      className: "tableItem__toppings__seemore__content"
    })), React.createElement("div", {
      className: "tableItem__price"
    }, React.createElement("p", null, React.createElement(TaxedMoney, {
      taxedMoney: unitPriceDefined
    }))), React.createElement("div", {
      className: "tableItem__observations"
    }, line.observation !== "null" && line.observation !== null && line.observation !== "" ? line.observation : React.createElement(FormattedMessage, commonMessages.notDefined)), React.createElement("div", {
      className: "tableItem__discount"
    }, !isEqual(priceUndiscounted, price) ? React.createElement("p", null, discountedPercentageValue) : React.createElement("p", null, " - ")), React.createElement("div", {
      className: "tableItem__quantity"
    }, React.createElement("p", null, " ", line.quantity, " ")), React.createElement("div", {
      className: "tableItem__totalPrice"
    }, React.createElement("p", null, React.createElement(TaxedMoney, {
      taxedMoney: line.totalPrice
    })))) : React.createElement("div", {
      className: "itemOrderContainer"
    }, React.createElement("div", {
      className: "itemOrder"
    }, React.createElement("div", {
      className: "itemOrder__left"
    }, React.createElement("div", {
      className: "itemOrder__left__image"
    }, React.createElement(Thumbnail, {
      source: thumbnail
    }), !isEqual(priceUndiscounted, price) && React.createElement("div", {
      className: "itemOrder__left__discount"
    }, React.createElement("div", {
      className: "itemOrder__left__discount--value"
    }, React.createElement("span", null, discountedPercentageValue), React.createElement(ReactSVG, {
      path: percentajeImg
    })))), React.createElement("span", {
      className: "itemOrder__left__quantity"
    }, React.createElement(FormattedMessage, {
      id: "components.CartTable.TableLineView.3897981513",
      defaultMessage: "Qty: {quantity}",
      values: {
        quantity: line.quantity
      }
    }))), React.createElement("div", {
      className: "itemOrder__right"
    }, React.createElement("div", {
      className: "itemOrder__right__nameAndToppings"
    }, React.createElement("p", {
      className: "itemOrder__right__name"
    }, name), ellipsisToppingsNameState !== "" ? React.createElement("p", {
      className: "itemOrder__right__toppings"
    }, !seeMore ? React.createElement(React.Fragment, null, React.createElement("span", {
      className: "itemOrder__right__toppings__seemore__content",
      ref: ellipsisToppingsNameMobilepRef
    }), React.createElement("span", {
      className: "itemOrder__right__toppings__seemore",
      onClick: handleSeeMore
    }, React.createElement(FormattedMessage, commonMessages.seeMore))) : React.createElement(React.Fragment, null, React.createElement("span", {
      className: "itemOrder__right__toppings__seemore__content",
      ref: allToppingsNameSeeLessRef
    }), React.createElement("span", {
      className: "itemOrder__right__toppings__seemore",
      onClick: handleSeeMore
    }, React.createElement(FormattedMessage, commonMessages.seeLess)))) : allToppingsNameState !== "" && React.createElement("p", {
      className: "itemOrder__right__toppings"
    }, React.createElement("span", {
      ref: allToppingsNameRef,
      className: "itemOrder__right__toppings__seemore__content"
    }))), React.createElement("div", {
      className: "itemOrder__right__price"
    }, React.createElement("div", {
      className: "itemOrder__right__price--value"
    }, React.createElement("p", null, React.createElement(FormattedMessage, {
      id: "components.CartTable.TableLineView.1134347598",
      defaultMessage: "Price"
    })), React.createElement("p", null, React.createElement(TaxedMoney, {
      taxedMoney: unitPriceDefined
    }))), React.createElement("div", {
      className: "itemOrder__right__price--value"
    }, React.createElement("p", null, React.createElement(FormattedMessage, {
      id: "components.CartTable.TableLineView.878013594",
      defaultMessage: "Total"
    })), React.createElement("p", null, React.createElement(TaxedMoney, {
      taxedMoney: line.totalPrice
    })))))), React.createElement("div", {
      className: "observationOrder"
    }, React.createElement("p", null, React.createElement(FormattedMessage, {
      id: "components.CartTable.TableLineView.722592990",
      defaultMessage: "Observations"
    }), ": "), React.createElement("p", null, line.observation !== "null" && line.observation !== null && line.observation !== "" ? line.observation : React.createElement(FormattedMessage, commonMessages.notDefined))));
  }));
});
TableLineView.displayName = "TableLineView";