import * as React from "react";
import { useParams } from "react-router-dom";
import { ChangeAmountProductsContext } from "@temp/contexts";
import OtherProductsCache from "./OtherCache";
import OtherProductsNetwork from "./OtherNetwork";
export var OtherProducts = React.memo(function () {
  var _useParams = useParams(),
      subCategoryId = _useParams.subCategoryId;

  var _React$useContext = React.useContext(ChangeAmountProductsContext),
      maxProductsPerCategory = _React$useContext.maxProductsPerCategory;

  React.useEffect(function () {
    var categoryIdLocalStorage = JSON.parse(localStorage.getItem("subcategory_products_cache")) || [];

    if (categoryIdLocalStorage.length === 0) {
      localStorage.setItem("subcategory_products_cache", JSON.stringify([subCategoryId]));
    } else if (categoryIdLocalStorage.length > 0 && !categoryIdLocalStorage.includes(subCategoryId)) {
      categoryIdLocalStorage.push(subCategoryId);
      localStorage.setItem("subcategory_products_cache", JSON.stringify(categoryIdLocalStorage));
    }

    return function () {};
  }, []);
  var categoryIdRemove = JSON.parse(localStorage.getItem("subcategory_products_cache")) || [];

  if (categoryIdRemove.includes(subCategoryId)) {
    return React.createElement(React.Fragment, null, React.createElement(OtherProductsCache, {
      PRODUCTS_PER_PAGE: maxProductsPerCategory
    }));
  }

  return React.createElement(React.Fragment, null, React.createElement(OtherProductsNetwork, {
    PRODUCTS_PER_PAGE: maxProductsPerCategory
  }));
});
export default OtherProducts;