import { createSlice } from "@reduxjs/toolkit";
var initialState = {
  edges: [],
  hasChangedOrderAmount: false
};
export var deliveryOrdersFinishedSlice = createSlice({
  name: "deliveryOrdersFinished",
  initialState: initialState,
  reducers: {
    addListFinished: function addListFinished(state, action) {
      state.edges.unshift(action.payload.data);
    },
    deleteListFinished: function deleteListFinished(state, action) {
      state.edges = state.edges.filter(function (data) {
        var _data$node;

        return (data === null || data === void 0 ? void 0 : (_data$node = data.node) === null || _data$node === void 0 ? void 0 : _data$node.id.toString()) !== action.payload.id.toString();
      });
    },
    hasntChangedOrderAmount: function hasntChangedOrderAmount(state) {
      state.hasChangedOrderAmount = false;
    },
    updateAllDataFinished: function updateAllDataFinished(state, action) {
      state.edges = action.payload.data;
    },
    updateHasChangedOrderAmountTrue: function updateHasChangedOrderAmountTrue(state, action) {
      var orderIndex = state.edges.findIndex(function (data) {
        return data.node.id.toString() === action.payload.id.toString();
      });

      if (orderIndex !== -1) {
        state.hasChangedOrderAmount = true;
      }
    },
    updateOrderDataFinished: function updateOrderDataFinished(state, action) {
      var orderIndex = state.edges.findIndex(function (data) {
        return data.node.id.toString() === action.payload.data.node.id.toString();
      });

      if (orderIndex !== -1) {
        state.edges[orderIndex] = action.payload.data;
      }
    }
  }
});
var _deliveryOrdersFinish = deliveryOrdersFinishedSlice.actions,
    addListFinished = _deliveryOrdersFinish.addListFinished,
    deleteListFinished = _deliveryOrdersFinish.deleteListFinished,
    hasntChangedOrderAmount = _deliveryOrdersFinish.hasntChangedOrderAmount,
    updateAllDataFinished = _deliveryOrdersFinish.updateAllDataFinished,
    updateHasChangedOrderAmountTrue = _deliveryOrdersFinish.updateHasChangedOrderAmountTrue,
    updateOrderDataFinished = _deliveryOrdersFinish.updateOrderDataFinished;
export { addListFinished, deleteListFinished, hasntChangedOrderAmount, updateAllDataFinished, updateHasChangedOrderAmountTrue, updateOrderDataFinished };