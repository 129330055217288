import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { createSlice } from "@reduxjs/toolkit";
var initialState = {
  orders: null
};
export var ordersSlice = createSlice({
  name: "orders",
  initialState: initialState,
  reducers: {
    addOrders: function addOrders(state, action) {
      state.orders = action.payload.orders;
    },
    updateOrderStatus: function updateOrderStatus(state, action) {
      var _state$orders;

      state.orders = (_state$orders = state.orders) === null || _state$orders === void 0 ? void 0 : _state$orders.map(function (order) {
        if (order.id === action.payload.order.id) {
          return action.payload.order;
        }

        return order;
      });
    },
    removeOrder: function removeOrder(state, action) {
      var _state$orders2;

      state.orders = (_state$orders2 = state.orders) === null || _state$orders2 === void 0 ? void 0 : _state$orders2.filter(function (order) {
        return order.id !== action.payload.orderId;
      });
    },
    addOrder: function addOrder(state, action) {
      if (!state.orders) {
        state.orders = [action.payload.order];
      } else {
        var newOrders = state.orders.filter(function (order) {
          return order.id !== action.payload.order.id;
        });
        state.orders = [].concat(_toConsumableArray(newOrders), [action.payload.order]);
      }
    }
  }
});
var _ordersSlice$actions = ordersSlice.actions,
    addOrders = _ordersSlice$actions.addOrders,
    updateOrderStatus = _ordersSlice$actions.updateOrderStatus,
    removeOrder = _ordersSlice$actions.removeOrder,
    addOrder = _ordersSlice$actions.addOrder;
export { addOrders, updateOrderStatus, removeOrder, addOrder };