/* eslint-disable import/no-unresolved */

/* eslint-disable no-case-declarations */

/* eslint-disable no-alert */

/* eslint-disable prettier/prettier */

/* eslint-disable dot-notation */
import { Formik } from "formik";
import React from "react";
import ReactSVG from "react-svg";
import { FormattedMessage } from "react-intl";
import * as S from "./styles";

var nequiImg = require("../../../../images/logoNequi.svg");
/**
 * CustomNequi payment gateway.
 */


var NequiPaymentGateway = function NequiPaymentGateway(_ref) {
  var changeSubmitProgress = _ref.changeSubmitProgress,
      processPayment = _ref.processPayment,
      formRef = _ref.formRef,
      formId = _ref.formId,
      initialStatus = _ref.initialStatus,
      config = _ref.config,
      totalPrice = _ref.totalPrice;
  return React.createElement(Formik, {
    initialValues: {
      reference_code: initialStatus || ""
    },
    onSubmit: function onSubmit(values, _ref2) {
      var setSubmitting = _ref2.setSubmitting;

      if (changeSubmitProgress) {
        changeSubmitProgress(true);
      }

      processPayment("nequi");
      setSubmitting(false); // changeSubmitProgress(false);
    }
  }, function (_ref3) {
    var handleChange = _ref3.handleChange,
        handleSubmit = _ref3.handleSubmit,
        handleBlur = _ref3.handleBlur,
        values = _ref3.values,
        isSubmitting = _ref3.isSubmitting,
        isValid = _ref3.isValid;
    return React.createElement(S.Form, {
      id: formId,
      ref: formRef,
      onSubmit: handleSubmit,
      "data-test": "nequiPaymentGatewayForm"
    }, React.createElement(S.Wrapper, null, React.createElement(S.Image, null, React.createElement(ReactSVG, {
      path: nequiImg,
      className: "overlay__header-back"
    })), React.createElement(S.Paragraph, null, React.createElement("p", null, React.createElement(FormattedMessage, {
      id: "@next.components.organisms.NequiPaymentGateway.NequiPaymentGateway.2442821705",
      defaultMessage: "The establishment will send you the payment instructions to your WhatsApp"
    })))));
  });
};

export { NequiPaymentGateway };