import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n    grid-template-columns: 1fr;\n    grid-row-gap: 20px;\n    width: 100%;\n  "]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  grid-column-gap: 30px;\n  position: fixed;\n  bottom: 0;\n  left: 0;\n  padding: 20px;\n  background-color: white;\n  button {\n    padding-left: 0;\n    padding-right: 0;\n    border: none;\n    border-radius: 8px;\n  }\n\n  button:nth-child(1) {\n    background: white;\n    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);\n  }\n\n  @media (min-width: 850px) {\n    width: 80%;\n    left: calc(50% - 40%);\n  }\n\n  ", "\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n  margin: 0;\n  margin-top: 40px;\n  margin-bottom: 8px;\n  padding-bottom: 160px;\n  text-align: center;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n  margin: 0;\n  margin-bottom: 8px;\n  line-height: 170%;\n  text-align: center;\n\n  span {\n    display: block;\n    width: 120px;\n    margin: 0 auto;\n    text-align: center;\n    border-radius: 8px;\n    background: #efeff2;\n    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);\n    color: #f76268;\n    font-weight: ", ";\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n  margin: 0;\n  line-height: 110%;\n  margin-bottom: 30px;\n  margin-top: 30px;\n  font-weight: bold;\n  font-size: 1.5rem;\n  text-align: center;\n  color: #ed1c24;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  div {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    margin: 40px 0;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  margin: 80px 0;\n  padding-top: 60px;\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), media.smallScreen(_templateObject2()));
export var ThankYouImg = styled.div(_templateObject3());
export var ThankYouHeader = styled.p(_templateObject4(), function (props) {
  return props.theme.typography.ultraBigFontSize;
});
export var Paragraph = styled.p(_templateObject5(), function (props) {
  return props.theme.typography.h4FontSize;
}, function (props) {
  return props.theme.typography.boldFontWeight;
});
export var ParagraphSend = styled.p(_templateObject6(), function (props) {
  return props.theme.typography.h4FontSize;
});
export var Buttons = styled.div(_templateObject7(), media.smallScreen(_templateObject8()));