import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  grid-area: button;\n  margin: 0 0 0 0;\n  button {\n    margin: 0 auto;\n    display: block;\n    border-radius: 8px;\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  grid-area: paymentGateways;\n  margin-bottom: 40px;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  grid-area: status;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  grid-area: navigation;\n  height: 85px;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 115px;\n  margin-bottom: 30px;\n  display: grid;\n\n  grid-template-columns: 1fr;\n  grid-template-rows: 35px auto auto;\n  grid-column-gap: 30px;\n  grid-template-areas:\n    \"navigation\"\n    \"status\";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(255, 255, 255, 0.7);\n  z-index: 99999;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Loader = styled.div(_templateObject());
export var Wrapper = styled.div(_templateObject2());
export var Navigation = styled.div(_templateObject3());
export var Status = styled.div(_templateObject4());
export var PaymentGateways = styled.div(_templateObject5(), function (props) {
  return props.hide && "display: none;";
});
export var Button = styled.div(_templateObject6());