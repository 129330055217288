import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useContext } from "react";
import ReactSVG from "react-svg";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import Media from "react-responsive";
import { xMediumScreen } from "@styles/constants";
import { Money } from "@components/containers";
import { CartSummaryRow } from "@components/molecules";
import { useCart, useCheckout } from "@saleor/sdk";
import { commonMessages } from "@temp/intl";
import { baseUrl } from "@temp/app/routes";
import { CurrentTradeDetailsContext } from "@temp/contexts";
import * as S from "./styles";
import closeImg from "../../../../images/closewhite.svg";

var CostLine = function CostLine(_ref) {
  var name = _ref.name,
      cost = _ref.cost,
      _ref$last = _ref.last,
      last = _ref$last === void 0 ? false : _ref$last,
      _ref$negative = _ref.negative,
      negative = _ref$negative === void 0 ? false : _ref$negative;
  return React.createElement(S.CostLine, {
    last: last
  }, React.createElement("span", null, name), React.createElement("span", {
    "data-test": "cartSummaryCost".concat(name.replace(/\s/g, ""))
  }, negative && "- ", React.createElement(Money, {
    money: cost
  })));
};

var Costs = function Costs(_ref2) {
  var _promoCode$net, _promoCode$net2;

  var subtotal = _ref2.subtotal,
      priceShipping = _ref2.priceShipping,
      promoCode = _ref2.promoCode,
      shipping = _ref2.shipping,
      total = _ref2.total,
      navigate = _ref2.navigate,
      mobileCartOpened = _ref2.mobileCartOpened,
      setMobileCartOpened = _ref2.setMobileCartOpened;
  var intl = useIntl();
  var scaleButton = useSpring({
    config: {
      friction: 40,
      mass: 5,
      tension: 350
    },
    from: {
      scale: 1.1
    },
    loop: true,
    to: {
      scale: 1
    }
  });
  return React.createElement(S.Costs, {
    mobileCartOpened: mobileCartOpened
  }, React.createElement(S.ButtonModify, {
    mobileCartOpened: mobileCartOpened
  }, React.createElement(S.Modify, {
    onClick: function onClick() {
      navigate(baseUrl);
    }
  }, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.CartSummary.CartSummary.3554578821",
    defaultMessage: "Add product"
  }))), React.createElement(S.TotalCost, null, subtotal && React.createElement(CostLine, {
    name: intl.formatMessage(commonMessages.subtotal),
    cost: subtotal.net
  }), priceShipping && React.createElement(CostLine, {
    name: intl.formatMessage(commonMessages.shipping),
    cost: priceShipping
  }), promoCode && (promoCode === null || promoCode === void 0 ? void 0 : promoCode.net) && (promoCode === null || promoCode === void 0 ? void 0 : (_promoCode$net = promoCode.net) === null || _promoCode$net === void 0 ? void 0 : _promoCode$net.amount) !== undefined && (promoCode === null || promoCode === void 0 ? void 0 : (_promoCode$net2 = promoCode.net) === null || _promoCode$net2 === void 0 ? void 0 : _promoCode$net2.amount) > 0 && React.createElement(CostLine, {
    name: intl.formatMessage(commonMessages.promoCode),
    cost: promoCode.net,
    negative: true
  }), total && React.createElement(CostLine, {
    name: intl.formatMessage(commonMessages.total),
    cost: total.net,
    last: true
  }), React.createElement(Media, {
    maxWidth: xMediumScreen
  }, React.createElement(S.ButtonContinue, null, React.createElement(animated.button, {
    onClick: function onClick() {
      return setMobileCartOpened(!mobileCartOpened);
    },
    style: scaleButton
  }, React.createElement(S.Continue, null, React.createElement(FormattedMessage, commonMessages.checkout)))))));
};
/**
 * Cart summary displayed in checkout page
 */


var CartSummary = function CartSummary(_ref3) {
  var _dataCurrentTrade$cur, _dataCurrentTrade$cur2, _shippingMethods$, _shippingMethods$$pri, _shippingMethods$2, _shippingMethods$2$pr, _total$gross, _total$gross2, _total$net, _total$net2, _total$gross3, _total$gross4, _total$net3, _total$net4;

  var subtotal = _ref3.subtotal,
      total = _ref3.total,
      shipping = _ref3.shipping,
      promoCode = _ref3.promoCode,
      products = _ref3.products,
      mobileCartOpened = _ref3.mobileCartOpened,
      setMobileCartOpened = _ref3.setMobileCartOpened;
  var navigate = useNavigate();

  var _useCheckout = useCheckout(),
      checkout = _useCheckout.checkout;

  var _useCart = useCart(),
      totalPrice = _useCart.totalPrice;

  var _useContext = useContext(CurrentTradeDetailsContext),
      dataCurrentTrade = _useContext.data;

  var shippingMethods = (dataCurrentTrade === null || dataCurrentTrade === void 0 ? void 0 : (_dataCurrentTrade$cur = dataCurrentTrade.currentShippingMethods) === null || _dataCurrentTrade$cur === void 0 ? void 0 : _dataCurrentTrade$cur.length) && (dataCurrentTrade === null || dataCurrentTrade === void 0 ? void 0 : (_dataCurrentTrade$cur2 = dataCurrentTrade.currentShippingMethods) === null || _dataCurrentTrade$cur2 === void 0 ? void 0 : _dataCurrentTrade$cur2.length) > 0 ? dataCurrentTrade === null || dataCurrentTrade === void 0 ? void 0 : dataCurrentTrade.currentShippingMethods : [];

  var _React$useState = React.useState({
    amount: (_shippingMethods$ = shippingMethods[0]) === null || _shippingMethods$ === void 0 ? void 0 : (_shippingMethods$$pri = _shippingMethods$.price) === null || _shippingMethods$$pri === void 0 ? void 0 : _shippingMethods$$pri.amount,
    currency: (_shippingMethods$2 = shippingMethods[0]) === null || _shippingMethods$2 === void 0 ? void 0 : (_shippingMethods$2$pr = _shippingMethods$2.price) === null || _shippingMethods$2$pr === void 0 ? void 0 : _shippingMethods$2$pr.currency
  }),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      priceShipping = _React$useState2[0],
      setPriceShipping = _React$useState2[1];

  React.useEffect(function () {
    if (totalPrice && shippingMethods && shippingMethods.length > 1) {
      shippingMethods.forEach(function (shippingMethod) {
        if ((shippingMethod === null || shippingMethod === void 0 ? void 0 : shippingMethod.minimumOrderPrice) && (shippingMethod === null || shippingMethod === void 0 ? void 0 : shippingMethod.maximumOrderPrice) && (shippingMethod === null || shippingMethod === void 0 ? void 0 : shippingMethod.minimumOrderPrice.amount) <= totalPrice.net.amount && totalPrice.net.amount <= (shippingMethod === null || shippingMethod === void 0 ? void 0 : shippingMethod.maximumOrderPrice.amount) || (shippingMethod === null || shippingMethod === void 0 ? void 0 : shippingMethod.minimumOrderPrice) && !(shippingMethod === null || shippingMethod === void 0 ? void 0 : shippingMethod.maximumOrderPrice) && (shippingMethod === null || shippingMethod === void 0 ? void 0 : shippingMethod.minimumOrderPrice.amount) <= totalPrice.net.amount) {
          var _shippingMethod$price, _shippingMethod$price2;

          setPriceShipping({
            amount: shippingMethod === null || shippingMethod === void 0 ? void 0 : (_shippingMethod$price = shippingMethod.price) === null || _shippingMethod$price === void 0 ? void 0 : _shippingMethod$price.amount,
            currency: shippingMethod === null || shippingMethod === void 0 ? void 0 : (_shippingMethod$price2 = shippingMethod.price) === null || _shippingMethod$price2 === void 0 ? void 0 : _shippingMethod$price2.currency
          });
        }
      });
    } else {
      var _shippingMethods$3, _shippingMethods$4;

      setPriceShipping({
        amount: (_shippingMethods$3 = shippingMethods[0]) === null || _shippingMethods$3 === void 0 ? void 0 : _shippingMethods$3.price.amount,
        currency: (_shippingMethods$4 = shippingMethods[0]) === null || _shippingMethods$4 === void 0 ? void 0 : _shippingMethods$4.price.currency
      });
    }
  }, [totalPrice]);
  var finalTotalPrice = (total === null || total === void 0 ? void 0 : (_total$gross = total.gross) === null || _total$gross === void 0 ? void 0 : _total$gross.amount) && (total === null || total === void 0 ? void 0 : (_total$gross2 = total.gross) === null || _total$gross2 === void 0 ? void 0 : _total$gross2.currency) && (total === null || total === void 0 ? void 0 : (_total$net = total.net) === null || _total$net === void 0 ? void 0 : _total$net.amount) && (total === null || total === void 0 ? void 0 : (_total$net2 = total.net) === null || _total$net2 === void 0 ? void 0 : _total$net2.currency) && (priceShipping === null || priceShipping === void 0 ? void 0 : priceShipping.amount) && (priceShipping === null || priceShipping === void 0 ? void 0 : priceShipping.amount) > 0 && !(checkout === null || checkout === void 0 ? void 0 : checkout.shippingMethod) ? {
    gross: {
      amount: (total === null || total === void 0 ? void 0 : (_total$gross3 = total.gross) === null || _total$gross3 === void 0 ? void 0 : _total$gross3.amount) + (priceShipping === null || priceShipping === void 0 ? void 0 : priceShipping.amount),
      currency: total === null || total === void 0 ? void 0 : (_total$gross4 = total.gross) === null || _total$gross4 === void 0 ? void 0 : _total$gross4.currency
    },
    net: {
      amount: (total === null || total === void 0 ? void 0 : (_total$net3 = total.net) === null || _total$net3 === void 0 ? void 0 : _total$net3.amount) + (priceShipping === null || priceShipping === void 0 ? void 0 : priceShipping.amount),
      currency: total === null || total === void 0 ? void 0 : (_total$net4 = total.net) === null || _total$net4 === void 0 ? void 0 : _total$net4.currency
    }
  } : totalPrice;
  return React.createElement(S.Wrapper, {
    mobileCartOpened: mobileCartOpened
  }, React.createElement(S.Title, {
    "data-test": "cartSummaryTitle",
    onClick: function onClick() {
      return setMobileCartOpened(!mobileCartOpened);
    },
    mobileCartOpened: mobileCartOpened
  }, React.createElement(S.SubTile, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.CartSummary.CartSummary.567093157",
    defaultMessage: "my order"
  })), React.createElement(Media, {
    maxWidth: xMediumScreen
  }, React.createElement(S.Image, null, React.createElement(ReactSVG, {
    path: closeImg
  })))), React.createElement(S.Content, null, React.createElement(S.HR, null), React.createElement(S.CartSummaryProductList, null, products === null || products === void 0 ? void 0 : products.map(function (product, index) {
    return React.createElement("div", {
      key: "".concat(product.sku, "-").concat(index)
    }, React.createElement(S.ProductLine, null, React.createElement(CartSummaryRow, _extends({
      index: index
    }, product))), React.createElement(S.HR, null));
  })), React.createElement(Costs, {
    subtotal: subtotal,
    total: finalTotalPrice,
    shipping: shipping,
    promoCode: promoCode,
    navigate: navigate,
    mobileCartOpened: mobileCartOpened,
    setMobileCartOpened: setMobileCartOpened,
    priceShipping: priceShipping
  })));
};

export { CartSummary };