import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import Media from "react-responsive";
import ReactSVG from "react-svg"; // import ReactSVG from "react-svg";

import { Money } from "@components/containers";
import { CartButton, RemoveFromCartButton } from "@components/molecules";
import { xMediumScreen } from "@styles/constants";
import { commonMessages } from "@temp/intl";
import QuantityInput from "../../molecules/QuantityInput";
import gobackImg from "../../../../images/goback.svg"; // import ProductVariantPicker from "../ProductVariantPicker";

import * as S from "./styles";
import { getAvailableQuantity // canAddToCart,
} from "./stockHelpers";
import { ProductObservationsToppings } from ".."; // const favoriteImg = require("../../../../images/favorito64x64.svg");

var AddToCartSection = React.memo(function (_ref) {
  var allowAddProduct = _ref.allowAddProduct,
      items = _ref.items,
      name = _ref.name,
      obligatoryButton = _ref.obligatoryButton,
      productPrice = _ref.productPrice,
      discountProductPrice = _ref.discountProductPrice,
      discountProductPercentage = _ref.discountProductPercentage,
      productObservationMessage = _ref.productObservationMessage,
      handleSetProductObservationMessage = _ref.handleSetProductObservationMessage,
      onAddToCart = _ref.onAddToCart,
      variantId = _ref.variantId,
      itemsQuantity = _ref.itemsQuantity,
      itemsObservation = _ref.itemsObservation,
      toppings = _ref.toppings,
      onEditCart = _ref.onEditCart,
      onRemoveFromCart = _ref.onRemoveFromCart,
      lineId = _ref.lineId,
      hasAttributes = _ref.hasAttributes,
      loadingNetwork = _ref.loadingNetwork,
      attributes = _ref.attributes,
      itemsToppings = _ref.itemsToppings,
      handleAllowAddProduct = _ref.handleAllowAddProduct,
      handleUpdateToppingsToAddAndProductPrice = _ref.handleUpdateToppingsToAddAndProductPrice,
      handleShowModalObligatoryToppings = _ref.handleShowModalObligatoryToppings;
  var intl = useIntl();
  var ref = useRef(null);

  var _useState = useState(1),
      _useState2 = _slicedToArray(_useState, 2),
      quantity = _useState2[0],
      setQuantity = _useState2[1];

  var _useState3 = useState(1000),
      _useState4 = _slicedToArray(_useState3, 1),
      variantStock = _useState4[0];

  var _useState5 = useState(false),
      _useState6 = _slicedToArray(_useState5, 2),
      checkProductObservation = _useState6[0],
      setCheckProductObservation = _useState6[1];

  var _useState7 = useState(false),
      _useState8 = _slicedToArray(_useState7, 2),
      selectProduct = _useState8[0],
      setSelectProduct = _useState8[1];

  var _useState9 = useState(false),
      _useState10 = _slicedToArray(_useState9, 2),
      intersection = _useState10[0],
      setIntersection = _useState10[1];

  var _useState11 = useState(productPrice),
      _useState12 = _slicedToArray(_useState11, 2),
      productFinalPrice = _useState12[0],
      setProductFinalPrice = _useState12[1];

  var _useState13 = useState(discountProductPrice),
      _useState14 = _slicedToArray(_useState13, 2),
      productFinalDiscountPrice = _useState14[0],
      setProductFinalDiscountPrice = _useState14[1]; // const [
  //   variantPricing,
  //   setVariantPricing,
  // ] = useState<ProductDetails_product_variants_pricing | null>(null);


  var availableQuantity = getAvailableQuantity(items, variantId, variantStock);
  var isOutOfStock = !!variantId && variantStock === 0; // const noPurchaseAvailable =
  //   !isAvailableForPurchase && !availableForPurchase;
  // const purchaseAvailableDate =
  //   !isAvailableForPurchase &&
  //   availableForPurchase &&
  //   Date.parse(availableForPurchase);

  var isNoItemsAvailable = !!variantId && !isOutOfStock && !availableQuantity; // const isLowStock =
  //   !!variantId &&
  //   !isOutOfStock &&
  //   !isNoItemsAvailable &&
  //   availableQuantity < LOW_STOCK_QUANTITY;

  var handleChangeCheckProductObservation = React.useCallback(function () {
    setCheckProductObservation(function (checkProductObservation) {
      return !checkProductObservation;
    });
  }, []);
  var createObserver = React.useCallback(function () {
    var options = {
      root: null,
      rootMargin: "5px",
      threshold: [0.0, 0.85, 1.0]
    };
    var observer = new IntersectionObserver(function (_ref2) {
      var _ref3 = _slicedToArray(_ref2, 1),
          entry = _ref3[0];

      setIntersection(entry.isIntersecting);
    }, options);

    if (ref === null || ref === void 0 ? void 0 : ref.current) {
      observer.observe(ref === null || ref === void 0 ? void 0 : ref.current);
    }

    return function () {
      return observer.disconnect();
    };
  }, []);

  var renderErrorMessage = function renderErrorMessage(message, testingContextId) {
    return React.createElement(S.ErrorMessage, {
      "data-test": "stockErrorMessage",
      "data-testid": testingContextId
    }, message);
  };

  var handleSelectProduct = useCallback(function () {
    if (!selectProduct) {
      setSelectProduct(true);
    }
  }, [setSelectProduct]);
  var handleSubmit = useCallback(function (flagEdit, id, quantity, productObservationMessage, toppings) {
    if (flagEdit) {
      onEditCart(id, quantity, productObservationMessage, toppings);
    } else {
      onAddToCart(id, productObservationMessage, quantity, toppings);
    }
  }, []);
  useEffect(function () {
    if (selectProduct && !loadingNetwork && allowAddProduct) {
      var toppingsWithValues = toppings === null || toppings === void 0 ? void 0 : toppings.filter(function (item) {
        return item.totalAmount > 0;
      }).map(function (item) {
        var newDataValues = item.dataValues.filter(function (value) {
          return value.quantity > 0;
        });
        return _objectSpread(_objectSpread({}, item), {}, {
          dataValues: newDataValues
        });
      });
      handleSubmit(itemsQuantity && lineId, itemsQuantity && lineId ? lineId : variantId, quantity, productObservationMessage, toppingsWithValues);
      setSelectProduct(false);
    } else if (selectProduct && !loadingNetwork && !allowAddProduct) {
      handleShowModalObligatoryToppings();
      setSelectProduct(false);
    }
  }, [selectProduct, loadingNetwork]);
  useEffect(function () {
    if (itemsObservation && itemsObservation !== "null") {
      setCheckProductObservation(true);
      handleSetProductObservationMessage(itemsObservation);
    } else if (itemsObservation && itemsObservation === "null") {
      setCheckProductObservation(false);
      handleSetProductObservationMessage("");
    }

    return function () {
      setCheckProductObservation(false);
      handleSetProductObservationMessage("");
    };
  }, [itemsObservation]);
  useEffect(function () {
    if (itemsQuantity) {
      setQuantity(itemsQuantity);
    }

    return function () {
      setQuantity(1);
    };
  }, [itemsQuantity]);
  useEffect(function () {
    if (quantity) {
      setProductFinalPrice({
        __typename: "Price",
        amount: productPrice.amount * quantity,
        currency: productPrice.currency
      });
      setProductFinalDiscountPrice({
        __typename: "Price",
        amount: discountProductPrice.amount * quantity,
        currency: discountProductPrice.currency
      });
    }

    return function () {};
  }, [quantity, productPrice]);
  useLayoutEffect(function () {
    createObserver();
    return function () {};
  }, []);
  return React.createElement(S.AddToCartSelection, {
    id: "containerAddToCart"
  }, React.createElement(S.ContainerProductNamePricingQuantity, null, React.createElement(S.ProductNameHeader, {
    "data-test": "productName"
  }, name), React.createElement(S.ProductNamePricing, null, isOutOfStock ? renderErrorMessage(intl.formatMessage(commonMessages.outOfStock), "outOfStock") : discountProductPercentage > 0 ? React.createElement(S.ProductPricing, null, React.createElement(S.UndiscountedPrice, null, React.createElement(Money, {
    money: productFinalPrice
  })), React.createElement(Money, {
    money: productFinalDiscountPrice
  })) : React.createElement(S.ProductUndiscountedPricing, null, React.createElement(Money, {
    money: productFinalPrice
  })))), React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(ProductObservationsToppings, {
    checkProductObservation: checkProductObservation,
    desktop: true,
    hasAttributes: hasAttributes,
    loadingNetwork: loadingNetwork,
    obligatoryButton: obligatoryButton,
    productObservationMessage: itemsObservation && itemsObservation !== "null" ? itemsObservation : productObservationMessage,
    itemsToppings: itemsToppings,
    toppingsProduct: attributes,
    handleChangeCheckProductObservation: handleChangeCheckProductObservation,
    handleAllowAddProduct: handleAllowAddProduct,
    handleSetProductObservationMessage: handleSetProductObservationMessage,
    handleUpdateToppingsToAddAndProductPrice: handleUpdateToppingsToAddAndProductPrice
  }), React.createElement(S.ContainerArrow, {
    intersection: intersection,
    edit: !!itemsQuantity && !!lineId
  }, React.createElement("a", {
    href: "#final"
  }, React.createElement(ReactSVG, {
    path: gobackImg
  }))), React.createElement(S.PReference, {
    ref: ref
  })), React.createElement(S.ContainerFooter, null, React.createElement(S.FavoriteQuantity, null, React.createElement(S.QuantityInput, null, React.createElement(QuantityInput, {
    quantity: quantity,
    maxQuantity: availableQuantity,
    disabled: isOutOfStock || isNoItemsAvailable,
    onQuantityChange: setQuantity,
    hideErrors: !variantId || isOutOfStock || isNoItemsAvailable,
    testingContext: "addToCartQuantity"
  }))), React.createElement(S.EditAddToCart, null, React.createElement(CartButton, {
    loading: selectProduct && loadingNetwork,
    message: itemsQuantity && lineId ? intl.formatMessage(commonMessages.update) : intl.formatMessage(commonMessages.select),
    handleOnClick: handleSelectProduct
  })), itemsQuantity && lineId && React.createElement(S.RemoveFromCart, null, React.createElement(RemoveFromCartButton, {
    onSubmit: function onSubmit() {
      return onRemoveFromCart(lineId);
    }
  }))));
});
AddToCartSection.displayName = "AddToCartSection";
export default AddToCartSection;