import _extends from "@babel/runtime/helpers/extends";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import ReactSVG from "react-svg";
import { InputSelect, TextField } from "@components/molecules";
import { checkoutMessages } from "@temp/intl";
import * as S from "./styles";
import { AlertReasonIdentityCard } from "../../../../components/AlertReasonIdentityCard";

var addressHome = require("../../../../images/addressHome.svg");

export var AddressFormContent = function AddressFormContent(_ref) {
  var _JSON$parse, _obligatoryFieldIdent, _obligatoryFieldIdent2, _JSON$parse2, _obligatoryFieldIdent3, _JSON$parse3, _obligatoryFieldIdent4, _obligatoryFieldIdent6, _obligatoryFieldIdent7, _obligatoryFieldUserE, _obligatoryFieldUserE2, _streetAddress, _city, _values$firstNameSele, _values$firstNameSele2, _values$firstNameSele3, _values$firstNameSele4;

  var formRef = _ref.formRef,
      handleChange = _ref.handleChange,
      handleBlur = _ref.handleBlur,
      formId = _ref.formId,
      errorName = _ref.errorName,
      errorsQuery = _ref.errorsQuery,
      identityCardValue = _ref.identityCardValue,
      identityCardTypeValue = _ref.identityCardTypeValue,
      userEmailValue = _ref.userEmailValue,
      clearError = _ref.clearError,
      handleSubmit = _ref.handleSubmit,
      handleSubmitKeyDown = _ref.handleSubmitKeyDown,
      values = _ref.values,
      validateErrors = _ref.validateErrors,
      neighborhoodsAttention = _ref.neighborhoodsAttention,
      obligatoryFieldIdentityCard = _ref.obligatoryFieldIdentityCard,
      obligatoryFieldIdentityCardType = _ref.obligatoryFieldIdentityCardType,
      obligatoryFieldUserEmail = _ref.obligatoryFieldUserEmail,
      nameOptions = _ref.nameOptions,
      defaultValue = _ref.defaultValue,
      setErrorsQuery = _ref.setErrorsQuery,
      setFieldValue = _ref.setFieldValue,
      testingContext = _ref.testingContext,
      showIdentityCard = _ref.showIdentityCard,
      showIdentityCardType = _ref.showIdentityCardType,
      showUserEmail = _ref.showUserEmail,
      showUserName = _ref.showUserName,
      handleOnChange = _ref.handleOnChange,
      handleOnChangeIdentityCard = _ref.handleOnChangeIdentityCard,
      handleOnChangeIdentityCardType = _ref.handleOnChangeIdentityCardType,
      handleOnChangeUserEmail = _ref.handleOnChangeUserEmail,
      nameValue = _ref.nameValue,
      _ref$includeEmail = _ref.includeEmail,
      includeEmail = _ref$includeEmail === void 0 ? false : _ref$includeEmail;

  var _React$useState = React.useState({
    open: false,
    reason: ""
  }),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      showObligatoryField = _React$useState2[0],
      setShowObligatoryField = _React$useState2[1];

  var _React$useState3 = React.useState(errorsQuery),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      errorsInput = _React$useState4[0],
      setErrorsInput = _React$useState4[1];

  var basicInputProps = useCallback(function () {
    return {
      onBlur: handleBlur,
      onChange: handleChange
    };
  }, [handleChange, handleBlur]);
  var intl = useIntl();
  var fieldErrors = {};

  if (errorsInput) {
    errorsInput.map(function (_ref2) {
      var field = _ref2.field,
          message = _ref2.message;
      fieldErrors[field] = fieldErrors[field] ? [].concat(_toConsumableArray(fieldErrors[field]), [{
        message: message
      }]) : [{
        message: message
      }];
    });
  }

  React.useEffect(function () {
    setErrorsInput(errorsQuery);
    return function () {
      setErrorsInput(undefined);
    };
  }, [errorsQuery]);
  return React.createElement(React.Fragment, null, React.createElement(S.AddressForm, {
    id: formId,
    ref: formRef,
    onSubmit: handleSubmit,
    "data-test": testingContext
  }, React.createElement(S.Wrapper, null, showUserName && React.createElement(S.RowWithOneCell, null, React.createElement(TextField, {
    id: "userName",
    autoFocus: true,
    name: "userName",
    label: intl.formatMessage(_defineProperty({
      defaultMessage: "In whose name",
      "id": "@next.components.organisms.AddressForm.AddressFormContent.3075794330"
    }, "id", "@next.components.organisms.AddressForm.AddressFormContent.3075794330")),
    value: nameValue || "",
    autoComplete: "name",
    placeholder: "",
    errors: errorName === null || errorName === void 0 ? void 0 : errorName.userName,
    onChange: handleOnChange,
    onKeyDown: function onKeyDown() {
      if (clearError) {
        clearError("userName");
      }

      if (handleSubmitKeyDown) {
        handleSubmitKeyDown();
      }
    }
  })), showIdentityCardType && React.createElement(S.RowWithOneCell, null, React.createElement(InputSelect, {
    id: "identityCardType",
    defaultValue: identityCardTypeValue || ((_JSON$parse = JSON.parse(obligatoryFieldIdentityCardType && ((_obligatoryFieldIdent = obligatoryFieldIdentityCardType[0]) === null || _obligatoryFieldIdent === void 0 ? void 0 : _obligatoryFieldIdent.fieldValues) || "{}")) === null || _JSON$parse === void 0 ? void 0 : _JSON$parse.values[0]),
    label: obligatoryFieldIdentityCardType && ((_obligatoryFieldIdent2 = obligatoryFieldIdentityCardType[0]) === null || _obligatoryFieldIdent2 === void 0 ? void 0 : _obligatoryFieldIdent2.fieldLabel) || "",
    name: "identityCardType",
    options: (_JSON$parse2 = JSON.parse(obligatoryFieldIdentityCardType && ((_obligatoryFieldIdent3 = obligatoryFieldIdentityCardType[0]) === null || _obligatoryFieldIdent3 === void 0 ? void 0 : _obligatoryFieldIdent3.fieldValues) || "{}")) === null || _JSON$parse2 === void 0 ? void 0 : _JSON$parse2.values,
    value: identityCardTypeValue || ((_JSON$parse3 = JSON.parse(obligatoryFieldIdentityCardType && ((_obligatoryFieldIdent4 = obligatoryFieldIdentityCardType[0]) === null || _obligatoryFieldIdent4 === void 0 ? void 0 : _obligatoryFieldIdent4.fieldValues) || "{}")) === null || _JSON$parse3 === void 0 ? void 0 : _JSON$parse3.values[0]),
    errors: (errorName === null || errorName === void 0 ? void 0 : errorName.identityCardType) || fieldErrors.identityCardType,
    onChange: function onChange(value, name) {
      if ((errorName === null || errorName === void 0 ? void 0 : errorName.identityCardType) && clearError) {
        clearError("identityCardType");
      }

      if (value && handleOnChangeIdentityCardType) {
        handleOnChangeIdentityCardType(value);
      }
    },
    optionLabelKey: "name",
    optionValueKey: "value",
    autoComplete: "identityCardType"
  }), React.createElement(S.ButtonInputIdentityCard, {
    onClick: function onClick() {
      var _obligatoryFieldIdent5;

      return setShowObligatoryField({
        open: true,
        reason: obligatoryFieldIdentityCardType && ((_obligatoryFieldIdent5 = obligatoryFieldIdentityCardType[0]) === null || _obligatoryFieldIdent5 === void 0 ? void 0 : _obligatoryFieldIdent5.reason) || ""
      });
    }
  }, React.createElement("p", null, intl.formatMessage(_defineProperty({
    defaultMessage: "Why?",
    "id": "@next.components.organisms.AddressForm.AddressFormContent.1724709724"
  }, "id", "@next.components.organisms.AddressForm.AddressFormContent.1724709724"))))), showIdentityCard && React.createElement(S.RowWithOneCell, null, React.createElement(TextField, {
    id: "identityCard",
    autoFocus: !showUserName || showUserName && nameValue !== "",
    name: "identityCard",
    label: obligatoryFieldIdentityCard && ((_obligatoryFieldIdent6 = obligatoryFieldIdentityCard[0]) === null || _obligatoryFieldIdent6 === void 0 ? void 0 : _obligatoryFieldIdent6.fieldLabel),
    value: identityCardValue || "",
    autoComplete: "on",
    placeholder: obligatoryFieldIdentityCard && ((_obligatoryFieldIdent7 = obligatoryFieldIdentityCard[0]) === null || _obligatoryFieldIdent7 === void 0 ? void 0 : _obligatoryFieldIdent7.fieldPlaceholder),
    errors: errorName === null || errorName === void 0 ? void 0 : errorName.identityCard,
    onChange: handleOnChangeIdentityCard,
    onKeyDown: function onKeyDown() {
      if (clearError) {
        clearError("identityCard");
      }

      if (handleSubmitKeyDown) {
        handleSubmitKeyDown();
      }
    }
  }), React.createElement(S.ButtonInputIdentityCard, {
    onClick: function onClick() {
      var _obligatoryFieldIdent8;

      return setShowObligatoryField({
        open: true,
        reason: obligatoryFieldIdentityCard && ((_obligatoryFieldIdent8 = obligatoryFieldIdentityCard[0]) === null || _obligatoryFieldIdent8 === void 0 ? void 0 : _obligatoryFieldIdent8.reason) || ""
      });
    }
  }, React.createElement("p", null, intl.formatMessage(_defineProperty({
    defaultMessage: "Why?",
    "id": "@next.components.organisms.AddressForm.AddressFormContent.1724709724"
  }, "id", "@next.components.organisms.AddressForm.AddressFormContent.1724709724"))))), showUserEmail && React.createElement(S.RowWithOneCell, null, React.createElement(TextField, {
    id: "userEmail",
    autoFocus: (!showUserName || showUserName && nameValue !== "") && (!showIdentityCard || showIdentityCard && identityCardValue !== ""),
    name: "userEmail",
    label: obligatoryFieldUserEmail && ((_obligatoryFieldUserE = obligatoryFieldUserEmail[0]) === null || _obligatoryFieldUserE === void 0 ? void 0 : _obligatoryFieldUserE.fieldLabel),
    value: userEmailValue || "",
    autoComplete: "on",
    placeholder: obligatoryFieldUserEmail && ((_obligatoryFieldUserE2 = obligatoryFieldUserEmail[0]) === null || _obligatoryFieldUserE2 === void 0 ? void 0 : _obligatoryFieldUserE2.fieldPlaceholder),
    errors: errorName === null || errorName === void 0 ? void 0 : errorName.userEmail,
    onChange: handleOnChangeUserEmail,
    onKeyDown: function onKeyDown() {
      if (clearError) {
        clearError("userEmail");
      }

      if (handleSubmitKeyDown) {
        handleSubmitKeyDown();
      }
    }
  }), React.createElement(S.ButtonInputIdentityCard, {
    onClick: function onClick() {
      var _obligatoryFieldUserE3;

      return setShowObligatoryField({
        open: true,
        reason: obligatoryFieldUserEmail && ((_obligatoryFieldUserE3 = obligatoryFieldUserEmail[0]) === null || _obligatoryFieldUserE3 === void 0 ? void 0 : _obligatoryFieldUserE3.reason) || ""
      });
    }
  }, React.createElement("p", null, intl.formatMessage(_defineProperty({
    defaultMessage: "Why?",
    "id": "@next.components.organisms.AddressForm.AddressFormContent.1724709724"
  }, "id", "@next.components.organisms.AddressForm.AddressFormContent.1724709724"))))), React.createElement(S.RowWithOneCell, null, React.createElement(TextField, _extends({
    id: "userAddress",
    autoFocus: (!showUserName || showUserName && nameValue !== "") && (!showIdentityCard || showIdentityCard && identityCardValue !== "") && (!showUserEmail || showUserEmail && userEmailValue !== ""),
    name: "streetAddress1",
    label: intl.formatMessage(_defineProperty({
      defaultMessage: "Address",
      "id": "@next.components.organisms.AddressForm.AddressFormContent.359810770"
    }, "id", "@next.components.organisms.AddressForm.AddressFormContent.359810770")),
    value: ((_streetAddress = values.streetAddress1) === null || _streetAddress === void 0 ? void 0 : _streetAddress.toLowerCase()) === "no definida" ? "" : values.streetAddress1 || "",
    autoComplete: "address-line1",
    placeholder: intl.formatMessage(_defineProperty({
      defaultMessage: "Ex: Calle 3 # 5 -20",
      "id": "@next.components.organisms.AddressForm.AddressFormContent.362889475"
    }, "id", "@next.components.organisms.AddressForm.AddressFormContent.362889475")),
    errors: (errorName === null || errorName === void 0 ? void 0 : errorName.userAddress) || fieldErrors.streetAddress1
  }, basicInputProps(), {
    onKeyDown: function onKeyDown(e) {
      var newFieldErrors = errorsInput === null || errorsInput === void 0 ? void 0 : errorsInput.filter(function (error) {
        return error.field !== "streetAddress1";
      });
      setErrorsInput(newFieldErrors);

      if (setErrorsQuery) {
        setErrorsQuery(newFieldErrors);
      }

      if (errorName && clearError) {
        clearError("userAddress");
      }

      if (handleSubmitKeyDown) {
        handleSubmitKeyDown();
      }
    }
  }))), React.createElement(S.RowWithOneCell, null, React.createElement(TextField, _extends({
    name: "streetAddress2",
    label: intl.formatMessage(_defineProperty({
      defaultMessage: "Additional Information",
      "id": "@next.components.organisms.AddressForm.AddressFormContent.2440017124"
    }, "id", "@next.components.organisms.AddressForm.AddressFormContent.2440017124")),
    value: values.streetAddress2 === undefined || values.streetAddress2 === null ? "" : values.streetAddress2,
    autoComplete: "address-line2",
    placeholder: intl.formatMessage(_defineProperty({
      defaultMessage: "Ex: Conjunto Bellavista Apto 101",
      "id": "@next.components.organisms.AddressForm.AddressFormContent.1685157935"
    }, "id", "@next.components.organisms.AddressForm.AddressFormContent.1685157935")),
    errors: fieldErrors.streetAddress2
  }, basicInputProps(), {
    onKeyDown: function onKeyDown(e) {
      var newFieldErrors = errorsInput === null || errorsInput === void 0 ? void 0 : errorsInput.filter(function (error) {
        return error.field !== "streetAddress2";
      });
      setErrorsInput(newFieldErrors);

      if (setErrorsQuery) {
        setErrorsQuery(newFieldErrors);
      }

      if (handleSubmitKeyDown) {
        handleSubmitKeyDown();
      }
    }
  }))), React.createElement(S.RowWithTwoCells, null, React.createElement(InputSelect, {
    id: "userNeighborhood",
    defaultValue: values === null || values === void 0 ? void 0 : values.neighborhood,
    label: intl.formatMessage(_defineProperty({
      defaultMessage: "Neighbborhood",
      "id": "@next.components.organisms.AddressForm.AddressFormContent.1990505920"
    }, "id", "@next.components.organisms.AddressForm.AddressFormContent.1990505920")),
    name: "neighborhood",
    options: neighborhoodsAttention,
    value: values === null || values === void 0 ? void 0 : values.neighborhood,
    errors: (errorName === null || errorName === void 0 ? void 0 : errorName.userNeighborhood) || fieldErrors.country,
    onChange: function onChange(value, name) {
      setFieldValue("neighborhood", value);

      if ((errorName === null || errorName === void 0 ? void 0 : errorName.userNeighborhood) && clearError) {
        clearError("userNeighborhood");
      }
    },
    optionLabelKey: "name",
    optionValueKey: "id",
    autoComplete: "neighborhood"
  })), React.createElement(S.RowWithTwoCells, null, React.createElement(TextField, _extends({
    name: "city",
    label: intl.formatMessage(_defineProperty({
      defaultMessage: "City",
      "id": "@next.components.organisms.AddressForm.AddressFormContent.253031977"
    }, "id", "@next.components.organisms.AddressForm.AddressFormContent.253031977")),
    value: ((_city = values.city2) === null || _city === void 0 ? void 0 : _city.name) || "",
    autoComplete: "address-level2",
    disabled: true,
    errors: fieldErrors.city
  }, basicInputProps()))), React.createElement(S.RowWithTwoCells, null, React.createElement(InputSelect, {
    defaultValue: (values === null || values === void 0 ? void 0 : values.firstNameSelect) || {
      id: intl.formatMessage(checkoutMessages.myHome),
      name: intl.formatMessage(checkoutMessages.myHome)
    },
    label: intl.formatMessage(checkoutMessages.firstName),
    name: "firstNameSelect",
    options: nameOptions,
    value: values === null || values === void 0 ? void 0 : values.firstNameSelect,
    onChange: function onChange(value, name) {
      return setFieldValue("firstNameSelect", value);
    },
    optionLabelKey: "name",
    optionValueKey: "id",
    errors: fieldErrors.firstName,
    autoComplete: "given-name"
  }), (values === null || values === void 0 ? void 0 : values.firstNameSelect) !== undefined && ((values === null || values === void 0 ? void 0 : (_values$firstNameSele = values.firstNameSelect) === null || _values$firstNameSele === void 0 ? void 0 : (_values$firstNameSele2 = _values$firstNameSele.id) === null || _values$firstNameSele2 === void 0 ? void 0 : _values$firstNameSele2.toUpperCase()) === intl.formatMessage(checkoutMessages.other).toUpperCase() || (values === null || values === void 0 ? void 0 : (_values$firstNameSele3 = values.firstNameSelect) === null || _values$firstNameSele3 === void 0 ? void 0 : (_values$firstNameSele4 = _values$firstNameSele3.id) === null || _values$firstNameSele4 === void 0 ? void 0 : _values$firstNameSele4.toUpperCase()) === intl.formatMessage(checkoutMessages.other).toUpperCase()) && React.createElement(S.TextNameWrite, null, React.createElement(S.TextNameWriteImg, null, React.createElement(ReactSVG, {
    path: addressHome
  })), React.createElement(TextField, _extends({
    id: "userFirstNameWrite",
    name: "firstNameWrite",
    label: intl.formatMessage(checkoutMessages.defineFirstName),
    value: values.firstNameWrite || "",
    autoComplete: "",
    autoFocus: true,
    errors: (errorName === null || errorName === void 0 ? void 0 : errorName.userFirstNameWrite) || fieldErrors.firstNameWrite,
    onKeyDown: function onKeyDown() {
      if (values.firstNameWrite !== "" && values.firstNameWrite !== null && values.firstNameWrite !== undefined) {
        var newFieldErrors = errorsInput === null || errorsInput === void 0 ? void 0 : errorsInput.filter(function (error) {
          return error.field !== "firstName";
        });
        setErrorsInput(newFieldErrors);

        if (setErrorsQuery) {
          setErrorsQuery(newFieldErrors);
        }

        if ((errorName === null || errorName === void 0 ? void 0 : errorName.userFirstNameWrite) && clearError) {
          clearError("userFirstNameWrite");
        }

        if (handleSubmitKeyDown) {
          handleSubmitKeyDown();
        }
      }
    }
  }, basicInputProps())))), React.createElement(S.RowWithOneCell, null, React.createElement(TextField, _extends({
    name: "lastName",
    label: intl.formatMessage(_defineProperty({
      defaultMessage: "Details",
      "id": "@next.components.organisms.AddressForm.AddressFormContent.928039481"
    }, "id", "@next.components.organisms.AddressForm.AddressFormContent.928039481")),
    value: values.lastName === undefined || values.lastName === null ? "" : values.lastName,
    autoComplete: "last-name",
    errors: fieldErrors.lastName
  }, basicInputProps(), {
    textAreaInputLabel: true,
    placeholder: intl.formatMessage(_defineProperty({
      defaultMessage: "Ask for Don Juan",
      "id": "@next.components.organisms.AddressForm.AddressFormContent.433902264"
    }, "id", "@next.components.organisms.AddressForm.AddressFormContent.433902264"))
  }))))), (showObligatoryField === null || showObligatoryField === void 0 ? void 0 : showObligatoryField.open) && React.createElement(AlertReasonIdentityCard, {
    hideModal: function hideModal() {
      setShowObligatoryField({
        open: false,
        reason: ""
      });
    },
    reason: showObligatoryField === null || showObligatoryField === void 0 ? void 0 : showObligatoryField.reason
  }));
};