import { useOrdersInProgressByUser } from "@saleor/sdk/";
import React from "react";
import Media from "react-responsive";
import { FormattedMessage } from "react-intl";
import ReactSVG from "react-svg";
import { Loader } from "@components/atoms";
import { xMediumScreen } from "@styles/constants";
import { Footer } from "@temp/components";
import { OrderTable } from "@components/organisms/OrderTable";
import { Navigate } from "react-router-dom";
import { perPage } from "@temp/constants";
import * as S from "./styles";
import goBackImg from "../../../images/arrowBack.svg";
var ORDERS_PER_APICALL = Number(perPage);
export var OrdersInProgress = function OrdersInProgress(_ref) {
  var navigate = _ref.navigate;

  var _useOrdersInProgressB = useOrdersInProgressByUser({
    perPage: ORDERS_PER_APICALL
  }, {
    fetchPolicy: "network-only"
  }),
      data = _useOrdersInProgressB.data,
      loading = _useOrdersInProgressB.loading,
      loadMore = _useOrdersInProgressB.loadMore;

  if ((data === null || data === void 0 ? void 0 : data.edges.length) === 0 && !loading) {
    return React.createElement(Navigate, {
      to: "/"
    });
  }

  return (data === null || data === void 0 ? void 0 : data.edges.length) === 0 ? null : loading && !data ? React.createElement(S.WrapperLoader, null, React.createElement(Loader, null)) : React.createElement(S.WrapperAll, null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(S.BackTitle, {
    onClick: function onClick() {
      return navigate(-1);
    }
  }, React.createElement(ReactSVG, {
    path: goBackImg
  }), React.createElement(S.BackTitleParragraph, null, React.createElement(FormattedMessage, {
    id: "@next.pages.OrdersInProgress.OrdersInProgress.3471378517",
    defaultMessage: "back"
  }))), React.createElement(Footer, null)), React.createElement(S.Tile, null, React.createElement(S.TileParragraph, null, React.createElement(FormattedMessage, {
    id: "@next.pages.OrdersInProgress.OrdersInProgress.1775784005",
    defaultMessage: "Ongoing orders"
  })), React.createElement(S.TitleAmount, null, data === null || data === void 0 ? void 0 : data.edges.length)), React.createElement(OrderTable, {
    data: data,
    inProgressFlag: true,
    loading: loading,
    loadMore: loadMore
  }));
};