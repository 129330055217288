import React from "react";
import { FormattedMessage } from "react-intl";
import ReactSVG from "react-svg";
import Media from "react-responsive";
import { xMediumScreen } from "@styles/constants";
import { StatusData, StatusDataFooter } from "@components/molecules";
import * as S from "./styles";

var orderNoApprovedImg = require("../../../../images/noApproved.svg");

var goBackImg = require("../../../../images/arrowBack.svg");

/**
 * Canceled status structure
 */
var StatusCanceled = function StatusCanceled(_ref) {
  var data = _ref.data,
      goBack = _ref.goBack;
  return React.createElement(S.Wrapper, null, React.createElement(S.Tile, null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(S.Title, null, React.createElement(S.BackTitle, {
    onClick: goBack
  }, React.createElement(ReactSVG, {
    path: goBackImg
  }), React.createElement(S.BackTitleParragraph, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.StatusCanceled.StatusCanceled.3471378517",
    defaultMessage: "back"
  }))), React.createElement(FormattedMessage, {
    id: "@next.components.organisms.StatusCanceled.StatusCanceled.158747495",
    defaultMessage: "request not approved"
  })))), React.createElement(S.Content, null, React.createElement(Media, {
    maxWidth: xMediumScreen
  }, React.createElement(S.Title, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.StatusCanceled.StatusCanceled.158747495",
    defaultMessage: "request not approved"
  }))), React.createElement(S.ContentContainer, null, React.createElement(S.Images, null, React.createElement(ReactSVG, {
    path: orderNoApprovedImg
  })), React.createElement(S.ParragraphTitle, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.StatusCanceled.StatusCanceled.683885602",
    defaultMessage: "the establishment has not approved the order by"
  })), data.cancellationReason && React.createElement(S.Parragraph, null, React.createElement(S.Span, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.StatusCanceled.StatusCanceled.3077186403",
    defaultMessage: "Reason"
  }), ": "), data.cancellationReason))), React.createElement(S.Aside, null, React.createElement(StatusData, {
    deliveryPhone: false,
    data: data,
    deliveryData: true
  })), React.createElement(S.Footer, null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(StatusDataFooter, {
    data: data
  }))));
};

export { StatusCanceled };