import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  padding: 0.4rem;\n  margin: 0;\n  border: none;\n  width: 100%;\n  outline: 0;\n  outline: none !important;\n  font-size: 15px;\n  font-weight: bold;\n  text-align: center;\n  background-color: transparent;\n  color: white;\n  &:hover,\n  &:active,\n  &:focus {\n    outline: 0;\n    outline: none !important;\n    -webkit-appearance: none;\n    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);\n    -webkit-focus-ring-color: rgba(0, 0, 0, 0);\n  }\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  width: 40%;\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  padding: 0.8rem 1rem;\n  margin: 0;\n  border: none;\n  width: 100%;\n  font-size: ", ";\n  outline: 0;\n  outline: none !important;\n  background-color: transparent;\n  color: black;\n  &:hover,\n  &:active,\n  &:focus {\n    outline: none;\n    border-radius: 8px;\n  }\n  &:hover ~ label,\n  &:active ~ label,\n  &:focus ~ label {\n    color: #6f6f6f;\n  }\n  &:-webkit-autofill {\n    & + label {\n      ", ";\n    }\n  }\n  ::placeholder {\n    color: #bdc3c7;\n  }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  padding: 0.8rem 1rem;\n  margin: 0;\n  border: none;\n  width: 100%;\n  font-size: ", ";\n  outline: 0;\n  outline: none !important;\n  background-color: transparent;\n  color: black;\n  &:-webkit-autofill {\n    & + label {\n      ", ";\n    }\n  }\n  &:disabled {\n    font-weight: bold;\n  }\n  &:hover,\n  &:active,\n  &:focus {\n    outline: none;\n    border-radius: 8px;\n  }\n  &:hover ~ label,\n  &:active ~ label,\n  &:focus ~ label {\n    color: #6f6f6f;\n  }\n  ::placeholder {\n    color: #bdc3c7;\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  width: 100%;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  width: 30%;\n  display: flex;\n  align-items: center;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  width: 30%;\n  display: flex;\n  align-items: center;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  width: 80%;\n  margin: 0 auto;\n  background: #ed1c24;\n  border-radius: 20px;\n  > div > div {\n    width: 80%;\n    margin: 0 auto !important;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  border: ", ";\n  border-radius: 8px;\n  color: black;\n  transition: all 0.3s ease;\n\n  &:hover,\n  &:active,\n  &:focus {\n    outline: none;\n    border: 2px solid #448bfd;\n    border-radius: 8px;\n  }\n\n  &:hover ~ label,\n  &:active ~ label,\n  &:focus ~ label {\n    color: #6f6f6f;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
import { activeLabelStyles } from "../InputLabel";

var getEdgeColor = function getEdgeColor(_ref) {
  var active = _ref.active,
      error = _ref.error,
      disabled = _ref.disabled,
      theme = _ref.theme;
  var hovered = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  if (disabled) {
    return "1px solid ".concat(theme.colors.disabled);
  }

  if (error) {
    return "1px solid ".concat(theme.colors.secondary);
  }

  if (hovered) {
    return "2px solid #448bfd";
  }

  return active ? " 2px solid #448bfd" : " 1px solid ".concat(theme.colors.dark);
};

export var Wrapper = styled.div(_templateObject(), function (props) {
  return getEdgeColor(props);
});
export var WrapperQuantity = styled.div(_templateObject2());
export var Content = styled.span(_templateObject3());
export var ContentLeft = styled.span(_templateObject4());
export var ContentRight = styled.span(_templateObject5());
export var InputWrapper = styled.div(_templateObject6());
export var Input = styled.input(_templateObject7(), function (props) {
  return props.theme.typography.baseFontSize;
}, function (props) {
  return activeLabelStyles(props.theme, props.labelBackground);
});
export var TextAreaInput = styled.textarea(_templateObject8(), function (props) {
  return props.theme.typography.baseFontSize;
}, function (props) {
  return activeLabelStyles(props.theme, props.labelBackground);
});
export var InputWrapperQuantity = styled.div(_templateObject9());
export var InputQuantity = styled.input(_templateObject10());