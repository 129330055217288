/* eslint-disable jsx-a11y/label-has-associated-control */

/* eslint-disable jsx-a11y/no-autofocus */
import "./scss/styles.scss";
import React from "react";
import ReactSVG from "react-svg";
export var EasyLinkListItem = function EasyLinkListItem(_ref) {
  var label = _ref.label,
      logo = _ref.logo,
      handleClick = _ref.handleClick;
  var ref = React.useRef(null);
  React.useLayoutEffect(function () {
    if (ref === null || ref === void 0 ? void 0 : ref.current) {
      var _ref$current, _ref$current$classLis;

      ref === null || ref === void 0 ? void 0 : (_ref$current = ref.current) === null || _ref$current === void 0 ? void 0 : (_ref$current$classLis = _ref$current.classList) === null || _ref$current$classLis === void 0 ? void 0 : _ref$current$classLis.remove("easyLinkListItem--active");
    }

    return function () {
      if (ref === null || ref === void 0 ? void 0 : ref.current) {
        var _ref$current2, _ref$current2$classLi;

        ref === null || ref === void 0 ? void 0 : (_ref$current2 = ref.current) === null || _ref$current2 === void 0 ? void 0 : (_ref$current2$classLi = _ref$current2.classList) === null || _ref$current2$classLi === void 0 ? void 0 : _ref$current2$classLi.remove("easyLinkListItem--active");
      }
    };
  }, []);
  React.useEffect(function () {
    var handleVisibilityChange = function handleVisibilityChange() {
      if (ref === null || ref === void 0 ? void 0 : ref.current) {
        var _ref$current3, _ref$current3$classLi;

        ref === null || ref === void 0 ? void 0 : (_ref$current3 = ref.current) === null || _ref$current3 === void 0 ? void 0 : (_ref$current3$classLi = _ref$current3.classList) === null || _ref$current3$classLi === void 0 ? void 0 : _ref$current3$classLi.remove("easyLinkListItem--active");
      }
    };

    window.addEventListener("visibilitychange", handleVisibilityChange);
    return function () {
      window.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
  return React.createElement("div", {
    ref: ref,
    onClick: function onClick() {
      handleClick();
      ref.current.classList.add("easyLinkListItem--active");
    },
    onTouchEnd: function onTouchEnd() {
      ref.current.classList.remove("easyLinkListItem--active");
    },
    onTouchMove: function onTouchMove() {
      ref.current.classList.remove("easyLinkListItem--active");
    },
    onTouchCancel: function onTouchCancel() {
      ref.current.classList.remove("easyLinkListItem--active");
    },
    onMouseOut: function onMouseOut() {
      ref.current.classList.remove("easyLinkListItem--active");
    },
    onBlur: function onBlur() {
      ref.current.classList.remove("easyLinkListItem--active");
    },
    className: "easyLinkListItem"
  }, React.createElement("div", {
    className: "easyLinkListItem__img"
  }, React.createElement("div", {
    className: "easyLinkListItem__img__svg"
  }, React.createElement(ReactSVG, {
    path: logo
  }))), React.createElement("p", null, label));
};
EasyLinkListItem.displayName = "EasyLinkListItem";