import * as React from "react";
import { CachedImage } from "@components/molecules";
import { useAuth } from "@saleor/sdk";
import { Carousel } from "../../components";
import noPhotoImg from "../../images/no-photo.svg";

var GalleryCarousel = function GalleryCarousel(_ref) {
  var _user$currentTrade3, _user$currentTrade3$d, _user$currentTrade4, _user$currentTrade4$d;

  var images = _ref.images;

  var _useAuth = useAuth(),
      user = _useAuth.user;

  return React.createElement("div", {
    className: "product-page__product__gallery"
  }, React.createElement(Carousel, {
    renderCenterLeftControls: function renderCenterLeftControls() {
      return null;
    },
    renderCenterRightControls: function renderCenterRightControls() {
      return null;
    },
    renderBottomCenterControls: function renderBottomCenterControls(props) {
      var indexes = new Array(props.slideCount).fill(0).map(function (_, i) {
        return i;
      });
      return React.createElement("ul", {
        className: "product-page__product__gallery__nav"
      }, indexes === null || indexes === void 0 ? void 0 : indexes.map(function (index) {
        return React.createElement("li", {
          key: index,
          onClick: props.goToSlide.bind(null, index),
          className: props.currentSlide === index ? "active" : ""
        }, React.createElement("span", null));
      }));
    }
  }, images.length > 0 ? images === null || images === void 0 ? void 0 : images.map(function (image, index) {
    var _user$currentTrade, _user$currentTrade$de, _user$currentTrade2, _user$currentTrade2$d;

    return React.createElement(CachedImage, {
      url: (image === null || image === void 0 ? void 0 : image.url) || (user === null || user === void 0 ? void 0 : (_user$currentTrade = user.currentTrade) === null || _user$currentTrade === void 0 ? void 0 : (_user$currentTrade$de = _user$currentTrade.defaultProductImage) === null || _user$currentTrade$de === void 0 ? void 0 : _user$currentTrade$de.url) || noPhotoImg,
      key: "".concat(index, "-carousel")
    }, React.createElement("img", {
      src: (image === null || image === void 0 ? void 0 : image.url) || (user === null || user === void 0 ? void 0 : (_user$currentTrade2 = user.currentTrade) === null || _user$currentTrade2 === void 0 ? void 0 : (_user$currentTrade2$d = _user$currentTrade2.defaultProductImage) === null || _user$currentTrade2$d === void 0 ? void 0 : _user$currentTrade2$d.url) || noPhotoImg,
      alt: (image === null || image === void 0 ? void 0 : image.alt) || ""
    }));
  }) : React.createElement(CachedImage, {
    url: (user === null || user === void 0 ? void 0 : (_user$currentTrade3 = user.currentTrade) === null || _user$currentTrade3 === void 0 ? void 0 : (_user$currentTrade3$d = _user$currentTrade3.defaultProductImage) === null || _user$currentTrade3$d === void 0 ? void 0 : _user$currentTrade3$d.url) || noPhotoImg,
    key: "carousel"
  }, React.createElement("img", {
    src: (user === null || user === void 0 ? void 0 : (_user$currentTrade4 = user.currentTrade) === null || _user$currentTrade4 === void 0 ? void 0 : (_user$currentTrade4$d = _user$currentTrade4.defaultProductImage) === null || _user$currentTrade4$d === void 0 ? void 0 : _user$currentTrade4$d.url) || noPhotoImg,
    alt: ""
  }))));
};

export default GalleryCarousel;