import _extends from "@babel/runtime/helpers/extends";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import NukaCarousel from "nuka-carousel";
import * as React from "react"; // import Media from "react-media";

import ReactSVG from "react-svg";
import arrowImg from "../../images/carousel-arrow.svg"; // import {
//   mediumScreen,
//   xmediumScreen,
// } from "../../globalStyles/scss/variables.scss";

import "./scss/index.scss";

var Carousel = function Carousel(_ref) {
  var children = _ref.children,
      rest = _objectWithoutProperties(_ref, ["children"]);

  var settings = _objectSpread({
    className: "carousel",
    renderBottomCenterControls: function renderBottomCenterControls() {
      return null;
    },
    renderCenterLeftControls: function renderCenterLeftControls(_ref2) {
      var previousSlide = _ref2.previousSlide,
          currentSlide = _ref2.currentSlide;
      return currentSlide !== 0 ? React.createElement("div", {
        onClick: previousSlide,
        className: "carousel__control carousel__control--left"
      }, React.createElement(ReactSVG, {
        path: arrowImg
      })) : null;
    },
    renderCenterRightControls: function renderCenterRightControls(_ref3) {
      var nextSlide = _ref3.nextSlide,
          currentSlide = _ref3.currentSlide,
          slideCount = _ref3.slideCount,
          slidesToShow = _ref3.slidesToShow;
      return slideCount - slidesToShow !== currentSlide ? React.createElement("div", {
        onClick: nextSlide,
        className: "carousel__control carousel__control--right"
      }, React.createElement(ReactSVG, {
        path: arrowImg
      })) : null;
    }
  }, rest);

  var carousel = function carousel(slides) {
    return React.createElement(NukaCarousel, _extends({
      slidesToShow: slides,
      slidesToScroll: slides
    }, settings), children);
  };

  return carousel(1); // <Media query={{ maxWidth: xmediumScreen }}>
  //   {matches =>
  //     matches ? (
  //       carousel(1)
  //     ) : (
  //       <Media query={{ maxWidth: mediumScreen }}>
  //         {matches => carousel(matches ? 2 : 4)}
  //       </Media>
  //     )
  //   }
  // </Media>
};

export default Carousel;