import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject17() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  margin-top: 1rem;\n  button {\n    margin: 0 auto;\n    border-radius: 10px;\n  }\n"]);

  _templateObject17 = function _templateObject17() {
    return data;
  };

  return data;
}

function _templateObject16() {
  var data = _taggedTemplateLiteral(["\n  width: 80%;\n  height: 80px;\n  margin: 0 auto;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  cursor: pointer;\n  border-radius: 8px;\n\n  p {\n    padding: 10px;\n    margin-left: auto;\n    font-size: 18px;\n    background-color: #3dabf0;\n    color: white;\n    border-radius: 8px;\n  }\n"]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 5px;\n  border-top-left-radius: 8px;\n  border-bottom-left-radius: 8px;\n  background-color: ", ";\n  cursor: ", ";\n  div {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n  svg {\n    path {\n      fill: ", ";\n    }\n  }\n"]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 45px;\n  position: relative;\n  display: grid;\n  grid-template-columns: 10% 90%;\n  border: 1px solid;\n  border-radius: 8px;\n  input {\n    display: block;\n    height: 100%;\n    padding-left: 5px;\n    font-size: 1rem;\n    border: none;\n    outline: none;\n  }\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\n  span {\n    display: inline-block;\n    margin-left: 5px;\n    color: #f76268;\n  }\n  margin: 0 auto;\n  margin-bottom: 30px;\n  margin-top: 20px;\n  text-align: center;\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n  width: 80%;\n  margin: 0 auto;\n  display: flex;\n  align-items: center;\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  svg {\n    width: 350px;\n    height: 350px;\n    margin-bottom: 10px;\n  }\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  margin: 5px auto;\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  svg {\n    transform: rotate(180deg);\n  }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 55px;\n  display: grid;\n  grid-template-columns: 15% 70% 15%;\n  margin: 5px auto;\n  margin-bottom: 13px;\n  padding-top: 10px;\n  padding-bottom: 10px;\n  cursor: pointer;\n  border-radius: 10px;\n  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  margin: 0 auto;\n  padding-top: 10px;\n  padding-bottom: 60px;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  @media (max-width: 900px) {\n    display: none;\n  }\n  display: block;\n  padding-right: 30px;\n  padding-top: 40px;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  @media (min-width: 900px) {\n    display: grid;\n    grid-template-columns: 30% 70%;\n  }\n  width: 80%;\n  margin: 0 auto;\n  padding-top: 10px;\n  padding-bottom: 60px;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  width: 80%;\n  margin: 0 auto;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  padding-top: ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Body = styled.div(_templateObject(), function (props) {
  return props.hasAttentionAtCurrentTime ? "60px" : "80px";
});
export var City = styled.div(_templateObject2());
export var Main = styled.div(_templateObject3());
export var MainLeft = styled.div(_templateObject4());
export var MainRight = styled.div(_templateObject5());
export var Neighborhoods = styled.div(_templateObject6());
export var Neighborhood = styled.div(_templateObject7());
export var NeighborhoodArrow = styled.div(_templateObject8());
export var NeighborhoodNotFound = styled.div(_templateObject9());
export var NeighborhoodIcon = styled.div(_templateObject10());
export var NeighborhoodIconNotFound = styled.div(_templateObject11());
export var NeighborhoodName = styled.p(_templateObject12());
export var NeighborhoodNameNotFound = styled.p(_templateObject13());
export var NeighborhoodTextInput = styled.div(_templateObject14());
export var NeighborhoodCleanSearch = styled.div(_templateObject15(), function (props) {
  return props.searchValue ? "#ffffff" : "#f0f0f0";
}, function (props) {
  return props.searchValue ? "pointer" : "";
}, function (props) {
  return props.searchValue ? "#000000" : "#989898";
});
export var NeighborhoodTextLocateMe = styled.div(_templateObject16());
export var Wrapper = styled.div(_templateObject17());