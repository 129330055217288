import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "./scss/index.scss";
import * as React from "react";
import axios from "axios";
import { animated, useSpring } from "react-spring";
import { FormattedMessage } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import AlertLeftEpTerms from "../../components/AlertLeftEpTerms";
import { NotFound } from "../../components";
import { maybe } from "../../core/utils";
import * as paths from "../../app/routes/paths";
import Page from "./Page";
import { TypedArticleQuery } from "./query";
import { pageTermsSlug, pagePpdSlug, returnToWaFromEpTermsUrl, wantToReadEpTermsUrl } from "../../constants";

var canDisplay = function canDisplay(page) {
  return maybe(function () {
    return !!page && !!page.title && !!page.contentJson;
  });
};

export var View = function View() {
  var _useParams = useParams(),
      userId = _useParams.userId;

  var navigate = useNavigate();
  var ppdRef = React.useRef(null);

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      ppdHasBeenRead = _React$useState2[0],
      setPpdHasBeenRead = _React$useState2[1];

  var _React$useState3 = React.useState(true),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      showAlert = _React$useState4[0],
      setShowAlert = _React$useState4[1];

  var scaleButton = useSpring({
    config: {
      friction: 40,
      mass: 5,
      tension: 350
    },
    from: {
      scale: 1.1
    },
    loop: true,
    to: {
      scale: 1
    }
  });

  var continueToWa = function continueToWa() {
    if (!ppdHasBeenRead && (ppdRef === null || ppdRef === void 0 ? void 0 : ppdRef.current) && window.pageYOffset < ppdRef.current.offsetHeight) {
      ppdRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
      setPpdHasBeenRead(true);
    } else {
      navigate(paths.baseUrl);
      window.open(returnToWaFromEpTermsUrl.replace(":userId", userId).replace(":way", "read-terms"));
    }
  };

  var _returnToWa = function returnToWa() {
    navigate(paths.baseUrl);
    window.open(returnToWaFromEpTermsUrl.replace(":userId", userId).replace(":way", "without-read-terms"));
  };

  var _wantToReadTerms = function wantToReadTerms() {
    axios({
      url: wantToReadEpTermsUrl,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data"
      },
      data: {
        user_id: userId
      }
    });
  };

  return React.createElement(React.Fragment, null, React.createElement(TypedArticleQuery, {
    loaderFull: true,
    variables: {
      slug: pageTermsSlug
    },
    errorPolicy: "all"
  }, function (_ref) {
    var data = _ref.data;
    var page = data.page;

    if (canDisplay(page)) {
      return React.createElement(React.Fragment, null, React.createElement(Page, {
        page: data.page
      }));
    }

    if (page === null) {
      return React.createElement(NotFound, null);
    }
  }), React.createElement(TypedArticleQuery, {
    loaderFull: true,
    variables: {
      slug: pagePpdSlug
    },
    errorPolicy: "all"
  }, function (_ref2) {
    var data = _ref2.data;
    var page = data.page;

    if (canDisplay(page)) {
      return React.createElement("div", {
        ref: ppdRef
      }, React.createElement(Page, {
        page: data.page
      }));
    }

    if (page === null) {
      return React.createElement(NotFound, null);
    }
  }), showAlert ? React.createElement(AlertLeftEpTerms, {
    wantToReadTerms: function wantToReadTerms() {
      _wantToReadTerms();

      setShowAlert(false);
    },
    returnToWa: function returnToWa() {
      _returnToWa();
    }
  }) : React.createElement(animated.button, {
    className: ppdHasBeenRead ? "epTerms-article-accept accept_green" : "epTerms-article-accept",
    onClick: continueToWa,
    style: scaleButton
  }, React.createElement("span", null, React.createElement(FormattedMessage, {
    id: "views.EpTerms.View.3640052215",
    defaultMessage: "Continue"
  }))));
};
export default View;