import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import closeWhite from "@temp/images/closewhite.svg";
import easyPedido from "@temp/images/easyLogoBigBackground.jpg";
import easyPedidoFooter from "@temp/images/easypedido-tienda640x640.jpg";
import shareIcon from "@temp/images/easyLink/shareEasy.svg";
import logoIcon from "@temp/images/easyLink/isoEasy.svg";
import shoppingCar from "@temp/images/easyLink/shoppingCar.svg";
import telephone from "@temp/images/easyLink/telephone.svg";
import whatsAppIcon from "@temp/images/easyLink/whatsAppIcon.svg";
import seeMore from "@temp/images/easyLink/seeMore.svg";
import { useIntl } from "react-intl";
import { easyLinkMessages } from "@temp/intl";
import { apiUrl, appUrl } from "@temp/constants";
import AlertShareOptions from "@temp/components/AlertShareOptions";
import ReactSVG from "react-svg";
import { EasyLinkListItem } from "../Components/EasyLinkListItem";
export var EasyLinkView = function EasyLinkView(_ref) {
  var _data$logoImage, _data$logoImage2, _data$logoImage3, _data$logoImage4;

  var data = _ref.data,
      setShowCall = _ref.setShowCall;
  var intl = useIntl();

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      showOptionsModal = _React$useState2[0],
      setShowOptionsModal = _React$useState2[1];

  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "easyLinkView"
  }, React.createElement("div", {
    className: "easyLinkView__menu"
  }, React.createElement("div", {
    onClick: function onClick() {
      return setShowOptionsModal(true);
    },
    className: "easyLinkView__menu__item"
  }, React.createElement(ReactSVG, {
    path: seeMore
  }))), React.createElement("div", {
    className: "easyLinkView__profile"
  }, React.createElement("div", null, React.createElement("img", {
    alt: (data === null || data === void 0 ? void 0 : (_data$logoImage = data.logoImage) === null || _data$logoImage === void 0 ? void 0 : _data$logoImage.alt) ? data === null || data === void 0 ? void 0 : (_data$logoImage2 = data.logoImage) === null || _data$logoImage2 === void 0 ? void 0 : _data$logoImage2.alt : "EasyPedido Logo",
    src: (data === null || data === void 0 ? void 0 : (_data$logoImage3 = data.logoImage) === null || _data$logoImage3 === void 0 ? void 0 : _data$logoImage3.url) ? data === null || data === void 0 ? void 0 : (_data$logoImage4 = data.logoImage) === null || _data$logoImage4 === void 0 ? void 0 : _data$logoImage4.url : easyPedido
  })), React.createElement("p", null, data === null || data === void 0 ? void 0 : data.name)), React.createElement("div", {
    className: "easyLinkView__list"
  }, React.createElement(EasyLinkListItem, {
    logo: whatsAppIcon,
    label: intl.formatMessage(easyLinkMessages.orderByWhatsappBot),
    handleClick: function handleClick() {
      location.href = "".concat(apiUrl.replace("graphql/", ""), "pedir-domicilio/").concat(data === null || data === void 0 ? void 0 : data.slug, "/");
    }
  }), React.createElement(EasyLinkListItem, {
    logo: shoppingCar,
    label: intl.formatMessage(easyLinkMessages.orderOnline),
    handleClick: function handleClick() {
      location.href = "".concat(appUrl, "web/").concat(data === null || data === void 0 ? void 0 : data.slug);
    }
  }), (data === null || data === void 0 ? void 0 : data.deliveryPhoneNumber) && React.createElement(EasyLinkListItem, {
    logo: telephone,
    label: intl.formatMessage(easyLinkMessages.orderByCall),
    handleClick: function handleClick() {
      setShowCall(true);
    }
  })), React.createElement("div", {
    className: "easyLinkView__powered"
  }, React.createElement("p", null, "Powered By"), React.createElement("img", {
    alt: "redMoped",
    src: easyPedidoFooter
  }))), React.createElement("div", {
    className: "easyLinkView__icons"
  }, React.createElement(ReactSVG, {
    path: closeWhite
  }), React.createElement(ReactSVG, {
    path: shareIcon
  }), React.createElement(ReactSVG, {
    path: logoIcon
  })), showOptionsModal && React.createElement(AlertShareOptions, {
    closeIcon: closeWhite,
    hideModal: function hideModal() {
      return setShowOptionsModal(false);
    },
    easyLink: "".concat(appUrl, "easylink/").concat(data === null || data === void 0 ? void 0 : data.slug),
    logoIcon: logoIcon,
    shareIcon: shareIcon,
    slug: data === null || data === void 0 ? void 0 : data.slug,
    tradeName: data === null || data === void 0 ? void 0 : data.name
  }));
};