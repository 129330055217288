import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import classNames from "classnames";
import React from "react";
import Media from "react-media";
import { FormattedMessage } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import ReactSVG from "react-svg";
import AddToCartSection from "@components/organisms/AddToCartSection";
import { OverlayContext, Footer, OverlayTheme, OverlayType, AlertObligatoryToppings, ProductDescriptionGlobal } from "@temp/components";
import { xMediumScreen } from "@styles/constants";
import { ProductGallery, ProductObservationsToppings } from "@components/organisms";
import { baseUrl } from "@temp/app/routes";
import GalleryCarousel from "./GalleryCarousel";
import OtherProducts from "./Other";

var percentajeImg = require("../../images/sale-double.svg");

var goBackImg = require("../../images/arrowBack.svg");

var Page = function Page(_ref) {
  var dataNetwork = _ref.dataNetwork,
      loadingNetwork = _ref.loadingNetwork,
      add = _ref.add,
      product = _ref.product,
      items = _ref.items,
      updateItem = _ref.updateItem,
      removeItem = _ref.removeItem,
      lineId = _ref.lineId;

  var _useParams = useParams(),
      id = _useParams.id;

  var _React$useState = React.useState(""),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      productObservationMessage = _React$useState2[0],
      setProductObservationMessage = _React$useState2[1];

  var _React$useState3 = React.useState(false),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      checkProductObservation = _React$useState4[0],
      setCheckProductObservation = _React$useState4[1];

  var _React$useState5 = React.useState(false),
      _React$useState6 = _slicedToArray(_React$useState5, 2),
      allowAddProduct = _React$useState6[0],
      setAllowAddProduct = _React$useState6[1];

  var _React$useState7 = React.useState(false),
      _React$useState8 = _slicedToArray(_React$useState7, 2),
      obligatoryButton = _React$useState8[0],
      setObligatoryButton = _React$useState8[1];

  var _React$useState9 = React.useState(false),
      _React$useState10 = _slicedToArray(_React$useState9, 2),
      viewOtherProductsMobile = _React$useState10[0],
      setViewOtherProductsMobile = _React$useState10[1];

  var _React$useState11 = React.useState(false),
      _React$useState12 = _slicedToArray(_React$useState11, 2),
      showModalObligatoryToppings = _React$useState12[0],
      setShowModalObligatoryToppings = _React$useState12[1];

  var _React$useState13 = React.useState(),
      _React$useState14 = _slicedToArray(_React$useState13, 2),
      toppingsToAdd = _React$useState14[0],
      setToppingsToAdd = _React$useState14[1];

  var _React$useState15 = React.useState(product === null || product === void 0 ? void 0 : product.price),
      _React$useState16 = _slicedToArray(_React$useState15, 2),
      productPrice = _React$useState16[0],
      setProductPrice = _React$useState16[1];

  var _React$useState17 = React.useState(product === null || product === void 0 ? void 0 : product.discountValue),
      _React$useState18 = _slicedToArray(_React$useState17, 2),
      productDiscountPrice = _React$useState18[0],
      setProductDiscountPrice = _React$useState18[1];

  var overlayContext = React.useContext(OverlayContext);
  var productGallery = React.useRef();
  var variantId = React.useMemo(function () {
    return product === null || product === void 0 ? void 0 : product.id;
  }, [product]);
  var navigate = useNavigate();

  var getImages = function getImages() {
    return product === null || product === void 0 ? void 0 : product.images;
  };

  var handleUpdateToppingsToAddAndProductPrice = React.useCallback(function (toppings) {
    var _product$price, _product$discountValu;

    var toppingsCost = toppings === null || toppings === void 0 ? void 0 : toppings.reduce(function (a, b) {
      return a.concat.apply(a, _toConsumableArray(b.dataValues));
    }, []).filter(function (toppingValue) {
      return (toppingValue === null || toppingValue === void 0 ? void 0 : toppingValue.quantity) > 0 && (toppingValue === null || toppingValue === void 0 ? void 0 : toppingValue.price) !== null;
    }).map(function (toppingValue) {
      var _toppingValue$price;

      return (toppingValue === null || toppingValue === void 0 ? void 0 : (_toppingValue$price = toppingValue.price) === null || _toppingValue$price === void 0 ? void 0 : _toppingValue$price.amount) * (toppingValue === null || toppingValue === void 0 ? void 0 : toppingValue.quantity);
    });
    var productAmountPlusToppingsCost = toppingsCost.reduce(function (a, b) {
      return a + b;
    }, product === null || product === void 0 ? void 0 : (_product$price = product.price) === null || _product$price === void 0 ? void 0 : _product$price.amount);
    var discountAmountPlusToppingsCost = toppingsCost.reduce(function (a, b) {
      return a + b;
    }, product === null || product === void 0 ? void 0 : (_product$discountValu = product.discountValue) === null || _product$discountValu === void 0 ? void 0 : _product$discountValu.amount);
    setProductPrice({
      __typename: "Price",
      amount: productAmountPlusToppingsCost,
      currency: productPrice === null || productPrice === void 0 ? void 0 : productPrice.currency
    });
    setProductDiscountPrice({
      __typename: "Price",
      amount: discountAmountPlusToppingsCost,
      currency: productDiscountPrice === null || productDiscountPrice === void 0 ? void 0 : productDiscountPrice.currency
    });
    setToppingsToAdd(toppings);
  }, [setProductDiscountPrice, setProductPrice, setToppingsToAdd, product === null || product === void 0 ? void 0 : product.discountValue, product === null || product === void 0 ? void 0 : product.price]);
  var handleAddToCart = React.useCallback(function (variantId, observation, quantity, toppingsToAdd) {
    var urlProduct = localStorage.getItem("product_url");

    if (!isNaN(quantity) && quantity > 0) {
      var observationInput = observation.trim() || "null";
      add(variantId, quantity, observationInput, toppingsToAdd);
      overlayContext.show(OverlayType.cart, OverlayTheme.right);
      overlayContext.hide();
    }

    if (urlProduct) {
      navigate(urlProduct);
    } else {
      navigate(baseUrl);
    } // setTimeout(() => {
    //   history.goBack();
    // }, 10);
    // overlayContext;
    // overlayContext.hide();
    // window.localStorage.setItem('cat', JSON.stringify(category));

  }, [localStorage.getItem("product_url")]);
  var handleShowModalObligatoryToppings = React.useCallback(function () {
    setShowModalObligatoryToppings(true);
    setObligatoryButton(false);
  }, [setShowModalObligatoryToppings]);
  var handleObligatoryToppings = React.useCallback(function () {
    setShowModalObligatoryToppings(false);
    setObligatoryButton(true);
  }, [setObligatoryButton, setShowModalObligatoryToppings]);
  var handleEditCart = React.useCallback(function (lineId, quantity, observation, attributes) {
    var urlProduct = localStorage.getItem("product_url");
    var urlCheckout = localStorage.getItem("checkout-modify") === "true";

    if (!isNaN(quantity) && quantity > 0) {
      var observationInput = observation.trim() || "null";
      updateItem(lineId, quantity, observationInput, attributes);
    }

    if (urlCheckout) {
      navigate("/checkout");
    } else if (urlProduct) {
      navigate(urlProduct);
    } else {
      navigate(baseUrl);
    }
  }, [localStorage.getItem("product_url")]);
  var handleRemoveFromCart = React.useCallback(function (lineId) {
    var urlProduct = localStorage.getItem("product_url");
    var urlCheckout = localStorage.getItem("checkout-modify") === "true";
    removeItem(lineId);

    if (urlCheckout && items.length > 1) {
      navigate("/checkout");
    } else if (urlProduct) {
      navigate(urlProduct);
    } else {
      navigate(baseUrl);
    }
  }, [localStorage.getItem("product_url")]);
  var handleAllowAddProduct = React.useCallback(function (addProduct) {
    setAllowAddProduct(addProduct);
  }, [setAllowAddProduct]);
  var handleSetProductObservationMessage = React.useCallback(function (observationMessage) {
    setProductObservationMessage(observationMessage);
  }, [setProductObservationMessage]);
  var handleChangeCheckProductObservation = React.useCallback(function () {
    setCheckProductObservation(function (checkProductObservation) {
      return !checkProductObservation;
    });
  }, []);
  var addToCartSection = React.createElement(AddToCartSection, {
    allowAddProduct: allowAddProduct,
    items: items,
    productId: product === null || product === void 0 ? void 0 : product.id,
    name: product === null || product === void 0 ? void 0 : product.name,
    productPrice: productPrice,
    discountProductPrice: productDiscountPrice,
    discountProductPercentage: product === null || product === void 0 ? void 0 : product.discountPercentage,
    variantId: variantId,
    onAddToCart: handleAddToCart,
    onEditCart: handleEditCart,
    onRemoveFromCart: handleRemoveFromCart,
    itemsQuantity: product === null || product === void 0 ? void 0 : product.itemsQuantity,
    itemsObservation: product === null || product === void 0 ? void 0 : product.itemsObservation,
    productObservationMessage: productObservationMessage,
    handleSetProductObservationMessage: handleSetProductObservationMessage,
    toppings: toppingsToAdd,
    lineId: lineId,
    hasAttributes: product === null || product === void 0 ? void 0 : product.hasAttributes,
    loadingNetwork: loadingNetwork,
    attributes: (product === null || product === void 0 ? void 0 : product.attributes) || (dataNetwork === null || dataNetwork === void 0 ? void 0 : dataNetwork.attributes),
    itemsToppings: product === null || product === void 0 ? void 0 : product.itemsToppings,
    handleAllowAddProduct: handleAllowAddProduct,
    handleUpdateToppingsToAddAndProductPrice: handleUpdateToppingsToAddAndProductPrice,
    handleShowModalObligatoryToppings: handleShowModalObligatoryToppings,
    obligatoryButton: obligatoryButton
  });
  var goBackProduct = React.useCallback(function () {
    var urlProduct = localStorage.getItem("product_url");

    if (urlProduct) {
      navigate(urlProduct);
    } else {
      navigate(baseUrl);
    }
  }, [localStorage.getItem("product_url")]);
  React.useEffect(function () {
    if (!(product === null || product === void 0 ? void 0 : product.hasAttributes) && !allowAddProduct) {
      setAllowAddProduct(true);
    }
  }, [product === null || product === void 0 ? void 0 : product.hasAttributes]);
  React.useEffect(function () {
    if ((product === null || product === void 0 ? void 0 : product.itemsObservation) && (product === null || product === void 0 ? void 0 : product.itemsObservation) !== "null") {
      setCheckProductObservation(true);
      handleSetProductObservationMessage(product === null || product === void 0 ? void 0 : product.itemsObservation);
    } else if ((product === null || product === void 0 ? void 0 : product.itemsObservation) && (product === null || product === void 0 ? void 0 : product.itemsObservation) === "null") {
      setCheckProductObservation(false);
      handleSetProductObservationMessage("");
    }

    return function () {
      setCheckProductObservation(false);
      handleSetProductObservationMessage("");
    };
  }, [product === null || product === void 0 ? void 0 : product.itemsObservation]);
  React.useEffect(function () {
    if (localStorage.getItem("products_cache") !== null) {
      var productsCache = JSON.parse(localStorage.getItem("products_cache"));

      if (!productsCache.includes(id)) {
        localStorage.setItem("products_cache", JSON.stringify([].concat(_toConsumableArray(productsCache), [id])));
      }
    } else {
      localStorage.setItem("products_cache", JSON.stringify([id]));
    }
  }, []);
  React.useEffect(function () {
    var scrollViewOtherProducts = function scrollViewOtherProducts() {
      if (window.scrollY > 100 && !viewOtherProductsMobile) {
        setViewOtherProductsMobile(true);
      }
    };

    window.addEventListener("scroll", scrollViewOtherProducts);
    return function () {
      window.removeEventListener("scroll", scrollViewOtherProducts);
    };
  }, []);
  return React.createElement("div", {
    className: "product-page"
  }, React.createElement(Media, {
    query: {
      minWidth: xMediumScreen
    }
  }, function (matches) {
    return matches && React.createElement("div", {
      className: "goback",
      onClick: goBackProduct
    }, React.createElement("div", {
      className: "goback__img"
    }, React.createElement(ReactSVG, {
      path: goBackImg
    })), React.createElement("p", {
      className: "goback__letter"
    }, React.createElement(FormattedMessage, {
      id: "views.Product.Page.3471378517",
      defaultMessage: "back"
    })));
  }), React.createElement("div", {
    className: "container"
  }, React.createElement("div", {
    className: "product-page__product"
  }, React.createElement(Media, {
    query: {
      maxWidth: xMediumScreen
    }
  }, function (matches) {
    return matches ? React.createElement(React.Fragment, null, React.createElement("div", {
      className: "product-page__product__gallery product-page__product__gallery__carousel",
      ref: productGallery
    }, React.createElement(GalleryCarousel, {
      images: getImages()
    }), (product === null || product === void 0 ? void 0 : product.discountPercentage) > 0 && React.createElement("div", {
      className: "product-page__product__gallery__percentaje"
    }, React.createElement("div", {
      className: "product-page__product__gallery__percentaje--value"
    }, React.createElement("span", null, "-".concat(product === null || product === void 0 ? void 0 : product.discountPercentage, "%")), React.createElement(ReactSVG, {
      path: percentajeImg
    })))), React.createElement("div", {
      className: "product-page__product__info"
    }, addToCartSection)) : React.createElement(React.Fragment, null, React.createElement("div", {
      className: "product-page__product__gallery",
      ref: productGallery
    }, React.createElement(ProductGallery, {
      images: getImages()
    }), (product === null || product === void 0 ? void 0 : product.discountPercentage) > 0 && React.createElement("div", {
      className: "product-page__product__gallery__percentaje"
    }, React.createElement("div", {
      className: "product-page__product__gallery__percentaje--value"
    }, React.createElement("span", null, "-".concat(product === null || product === void 0 ? void 0 : product.discountPercentage, "%")), React.createElement(ReactSVG, {
      path: percentajeImg
    }))), React.createElement(ProductDescriptionGlobal, {
      loadingNetwork: loadingNetwork,
      productDescriptionCache: product === null || product === void 0 ? void 0 : product.description,
      dataNetwork: dataNetwork
    }), React.createElement(OtherProducts, null)), React.createElement("div", {
      className: "product-page__product__info"
    }, React.createElement("div", {
      className: classNames("product-page__product__info--fixed")
    }, addToCartSection)), React.createElement(Footer, null));
  }))), React.createElement(Media, {
    query: {
      maxWidth: xMediumScreen
    }
  }, function (matches) {
    return matches && React.createElement(React.Fragment, null, React.createElement(ProductDescriptionGlobal, {
      loadingNetwork: loadingNetwork,
      productDescriptionCache: product === null || product === void 0 ? void 0 : product.description,
      dataNetwork: dataNetwork
    }), React.createElement(ProductObservationsToppings, {
      checkProductObservation: checkProductObservation,
      desktop: false,
      loadingNetwork: loadingNetwork,
      obligatoryButton: obligatoryButton,
      productObservationMessage: productObservationMessage,
      toppingsProduct: (product === null || product === void 0 ? void 0 : product.attributes) || (dataNetwork === null || dataNetwork === void 0 ? void 0 : dataNetwork.attributes),
      itemsToppings: product === null || product === void 0 ? void 0 : product.itemsToppings,
      hasAttributes: product === null || product === void 0 ? void 0 : product.hasAttributes,
      handleChangeCheckProductObservation: handleChangeCheckProductObservation,
      handleAllowAddProduct: handleAllowAddProduct,
      handleSetProductObservationMessage: handleSetProductObservationMessage,
      handleUpdateToppingsToAddAndProductPrice: handleUpdateToppingsToAddAndProductPrice
    }), viewOtherProductsMobile && React.createElement(OtherProducts, null));
  }), showModalObligatoryToppings && React.createElement(AlertObligatoryToppings, {
    hideModal: handleObligatoryToppings,
    toppingsName: toppingsToAdd ? toppingsToAdd === null || toppingsToAdd === void 0 ? void 0 : toppingsToAdd.filter(function (topping) {
      return !(topping === null || topping === void 0 ? void 0 : topping.checkMinTotalAmount);
    }).map(function (topping) {
      return topping === null || topping === void 0 ? void 0 : topping.name;
    }) : []
  }));
};

export default Page;