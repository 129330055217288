import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "./scss/index.scss";
import * as React from "react";
import { useIntl } from "react-intl";
import { Button, Icon, Loader } from "@components/atoms";
import { useCart } from "@saleor/sdk";
import { commonMessages } from "@temp/intl";
import { useAppDispatch, useAppSelector, updateCategorySearchValue, updateSugcategorySearchValue } from "@temp/redux";
import { maybe } from "../../core/utils";
import { OrderButton, ProductNoFound, ProductsFeatured } from "../../components";
import { ProductList } from "../../@next/components/organisms";

var Page = function Page(_ref) {
  var activeFilters = _ref.activeFilters,
      activeSortOption = _ref.activeSortOption,
      attributes = _ref.attributes,
      category = _ref.category,
      displayLoader = _ref.displayLoader,
      hasNextPage = _ref.hasNextPage,
      redirect = _ref.redirect,
      redirectHome = _ref.redirectHome,
      subcategory = _ref.subcategory,
      clearFilters = _ref.clearFilters,
      onLoadMore = _ref.onLoadMore,
      products = _ref.products,
      filters = _ref.filters,
      onOrder = _ref.onOrder,
      sortOptions = _ref.sortOptions,
      onAttributeFiltersChange = _ref.onAttributeFiltersChange;

  var _useCart = useCart(),
      items = _useCart.items;

  var _useAppSelector = useAppSelector(function (state) {
    return state;
  }),
      _useAppSelector$searc = _useAppSelector.search,
      searchSubcategory = _useAppSelector$searc.searchSubcategory,
      searchCategory = _useAppSelector$searc.searchCategory,
      searchQueryValue = _useAppSelector$searc.searchQueryValue,
      searchTitleHeight = _useAppSelector$searc.searchTitleHeight;

  var dispatch = useAppDispatch();
  var canDisplayProducts = maybe(function () {
    return !!products && products.products.length > 0;
  });
  var hasProducts = canDisplayProducts && products.products.length > 0;
  var intl = useIntl();

  var _React$useState = React.useState(0),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      scrollPosition = _React$useState2[0],
      setScrollPosition = _React$useState2[1];

  var _React$useState3 = React.useState(window.innerWidth <= 850 ? 200 : 240),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      marginTopState = _React$useState4[0],
      setMarginTopState = _React$useState4[1];

  var _useAppSelector2 = useAppSelector(function (store) {
    return store;
  }),
      hasAttentionAtCurrentTime = _useAppSelector2.tradeAttention.hasAttentionAtCurrentTime;

  React.useEffect(function () {
    var handleScroll = function handleScroll() {
      var position = window.pageYOffset;

      if (position > 0 && scrollPosition === 0 || position === 0 && scrollPosition > 0) {
        setScrollPosition(position);
      } else if (position <= 0) {
        setScrollPosition(0);
      }
    };

    window.addEventListener("scroll", handleScroll, {
      passive: true
    });
    return function () {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  React.useEffect(function () {
    var changeMarginTop = function changeMarginTop() {
      if (window.innerWidth <= 850 && marginTopState !== 230 + searchTitleHeight && hasAttentionAtCurrentTime) {
        setMarginTopState(230 + searchTitleHeight);
      } else if (window.innerWidth > 850 && marginTopState !== 250 + searchTitleHeight && hasAttentionAtCurrentTime) {
        setMarginTopState(250 + searchTitleHeight);
      } else if (window.innerWidth <= 850 && marginTopState !== 260 + searchTitleHeight && !hasAttentionAtCurrentTime) {
        setMarginTopState(260 + searchTitleHeight);
      } else if (window.innerWidth > 850 && marginTopState !== 270 + searchTitleHeight && !hasAttentionAtCurrentTime) {
        setMarginTopState(270 + searchTitleHeight);
      }
    };

    changeMarginTop();
    return function () {};
  }, [searchTitleHeight]);
  React.useEffect(function () {
    var changeMarginTop = function changeMarginTop() {
      if (window.innerWidth <= 850 && marginTopState !== 230 + searchTitleHeight && hasAttentionAtCurrentTime) {
        setMarginTopState(230 + searchTitleHeight);
      } else if (window.innerWidth > 850 && marginTopState !== 250 + searchTitleHeight && hasAttentionAtCurrentTime) {
        setMarginTopState(250 + searchTitleHeight);
      } else if (window.innerWidth <= 850 && marginTopState !== 260 + searchTitleHeight && !hasAttentionAtCurrentTime) {
        setMarginTopState(260 + searchTitleHeight);
      } else if (window.innerWidth > 850 && marginTopState !== 270 + searchTitleHeight && !hasAttentionAtCurrentTime) {
        setMarginTopState(270 + searchTitleHeight);
      }
    };

    changeMarginTop();
    window.addEventListener("resize", changeMarginTop);
    return function () {
      window.removeEventListener("resize", changeMarginTop);
    };
  }, []);
  React.useEffect(function () {
    if (searchSubcategory.value === "" || searchSubcategory.value.toUpperCase() === intl.formatMessage(commonMessages.all).toUpperCase() && subcategory) {
      var newValue = subcategory === null || subcategory === void 0 ? void 0 : subcategory.id;
      var newName = subcategory === null || subcategory === void 0 ? void 0 : subcategory.name;
      dispatch(updateSugcategorySearchValue({
        searchSubcategory: {
          name: newName,
          value: newValue
        }
      }));
    }

    if (searchCategory.value === "" || searchCategory.value.toUpperCase() === intl.formatMessage(commonMessages.all).toUpperCase() && category && searchCategory.value !== category.id) {
      var _newValue = category === null || category === void 0 ? void 0 : category.id;

      var _newName = category === null || category === void 0 ? void 0 : category.name;

      dispatch(updateCategorySearchValue({
        searchCategory: {
          name: _newName,
          value: _newValue,
          subcategories: []
        }
      }));
    }
  }, [subcategory, category]);
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "categories-page"
  }, React.createElement("div", {
    className: "container"
  }, React.createElement("div", {
    className: "container__subCategory",
    style: {
      marginTop: marginTopState
    }
  }, React.createElement("div", null, canDisplayProducts ? React.createElement(React.Fragment, null, React.createElement(OrderButton, {
    amount: items !== undefined ? items.length : 0
  }), React.createElement(ProductList, {
    products: products.products
  }), !displayLoader && hasNextPage ? React.createElement("div", {
    className: "container__subCategory__loadMore"
  }, React.createElement(Button, {
    testingContext: "ProductList",
    onClick: onLoadMore
  }, React.createElement(Icon, {
    size: 24,
    name: "plus",
    color: "#ED1C24"
  }), React.createElement("span", null, "Ver m\xE1s"))) : displayLoader && hasNextPage && React.createElement("div", {
    className: "container__subCategory__loading"
  }, React.createElement(Loader, null))) : (searchQueryValue !== "" || searchSubcategory.value !== "") && React.createElement(ProductNoFound, null)))), !hasProducts && searchQueryValue === "" && searchSubcategory.value === "" && React.createElement(ProductsFeatured, {
    title: intl.formatMessage(commonMessages.youMightLike)
  })));
};

export default Page;