import * as React from "react";
import { useParams } from "react-router-dom";
import { useCart, useSubCategoryProducts } from "@saleor/sdk";
import { useSubCategoryData } from "@temp/hooks";
import { Loader } from "@components/atoms";
import { getGraphqlIdFromDBId } from "../../core/utils";
import OtherProductsPage from "./OtherPage";

var OtherProductsCache = function OtherProductsCache(_ref) {
  var PRODUCTS_PER_PAGE = _ref.PRODUCTS_PER_PAGE;

  var _useParams = useParams(),
      id = _useParams.id,
      subCategoryId = _useParams.subCategoryId;

  var _useCart = useCart(),
      items = _useCart.items;

  var _useSubCategoryProduc = useSubCategoryProducts({
    perPage: PRODUCTS_PER_PAGE + 1,
    subCategoryId: +subCategoryId,
    search: ""
  }, {
    fetchPolicy: "network-only"
  }),
      data = _useSubCategoryProduc.data;

  if (!data) {
    return React.createElement(React.Fragment, null, React.createElement(Loader, null));
  }

  var subCategoryIdFromDB = getGraphqlIdFromDBId(subCategoryId, "Category");
  var productIdFromDB = getGraphqlIdFromDBId(id, "ProductVariant");

  var _useSubCategoryData = useSubCategoryData(data, items, subCategoryIdFromDB),
      products = _useSubCategoryData.products,
      subCategoryName = _useSubCategoryData.subCategoryName;

  return React.createElement(OtherProductsPage, {
    products: products,
    productId: productIdFromDB,
    subCategoryIdFromDB: subCategoryIdFromDB,
    subCategoryName: subCategoryName
  });
};

export default OtherProductsCache;