import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "./scss/index.scss";
import * as React from "react";
import { Skeleton } from "@components/atoms/Skeleton/Skeleton";
import { MetaWrapper, HomeSaleProducts } from "@temp/components";
import { useHomeProductsSaleData } from "@temp/hooks";
import { useCart } from "@saleor/sdk";

var HomeContentSaleProducts = function HomeContentSaleProducts(_ref) {
  var homeSaleProductsData = _ref.homeSaleProductsData,
      loadingHomeSaleProducts = _ref.loadingHomeSaleProducts;

  var _useCart = useCart(),
      items = _useCart.items;

  var _React$useState = React.useState(true),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      loadingFlag = _React$useState2[0],
      setLoadingFlag = _React$useState2[1];

  var _useHomeProductsSaleD = useHomeProductsSaleData(homeSaleProductsData, items),
      saleProducts = _useHomeProductsSaleD.saleProducts;

  React.useEffect(function () {
    if (!loadingHomeSaleProducts && loadingFlag) {
      var timeOut = setTimeout(function () {
        setLoadingFlag(false);
      }, 600);
      return function () {
        clearTimeout(timeOut);
      };
    }

    if (loadingHomeSaleProducts && !loadingFlag) {
      setLoadingFlag(true);
    }
  }, [loadingHomeSaleProducts]);

  if (loadingFlag && !(localStorage.getItem("home_cache") === "true")) {
    return React.createElement(React.Fragment, null, React.createElement(MetaWrapper, {
      meta: {
        type: "product.sale"
      }
    }, React.createElement("div", {
      className: "container-categoryHome__loading"
    }, React.createElement(Skeleton, null))));
  }

  return React.createElement(HomeSaleProducts, {
    saleProducts: saleProducts
  });
};

export default HomeContentSaleProducts;