import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  width: 30px;\n  height: 30px;\n\n  span {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 30px;\n    height: 31px;\n    position: absolute;\n    top: 2px;\n    left: 0px;\n    z-index: 5;\n    color: white;\n    font-size: 12px;\n    font-weight: bold;\n  }\n\n  svg {\n    transform: scale(1.2);\n  }\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  position: absolute;\n  top: -10px;\n  right: -10px;\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  width: 22px;\n  height: 22px;\n  position: absolute;\n  top: 70px;\n  right: 70px;\n  font-size: 0.8rem;\n  text-align: center;\n  background: black;\n  color: white;\n  border-radius: 3px;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  width: 86px;\n  height: 86px;\n  margin: 0 auto;\n  position: relative;\n  max-width: 100%;\n  border-radius: 8px;\n  > img {\n    width: 86px;\n    height: 86px;\n    max-width: 100%;\n    padding: 4px;\n    object-fit: contain;\n    border-radius: 15px;\n    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.4);\n  }\n\n  span {\n    display: inline-block;\n    background-image: url(\"../../../../images/ofertadescuenta.svg\");\n    background-repeat: no-repeat;\n    background-position: center;\n    background-size: contain;\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  color: black;\n  ", "\n  display: inline-block;\n  margin: 0;\n  margin-top: 10px;\n  text-align: center;\n  font-weight: bold;\n  > span span {\n    color: grey;\n    text-decoration: line-through;\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  display: inline-block;\n  margin: 0;\n  margin-top: 10px;\n  text-align: center;\n  font-weight: bold;\n  > span {\n    color: black;\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  width: 86px;\n  height: 100px;\n  overflow: hidden;\n  font-weight: bold;\n  color: ", ";\n  ", "\n  margin: 0px;\n  text-align: center;\n  font-size: 0.875rem;\n  text-overflow: ellipsis;\n  &&::first-letter {\n    text-transform: uppercase;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  width: 110px;\n  height: auto;\n  margin: 0 auto;\n  background: ", ";\n  text-align: center;\n  max-height: 30rem;\n  transition: 0.3s;\n  padding: 10px;\n  // :hover {\n  //   background-color: ", ";\n  //   color: ", ";\n  // }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n  margin: 0 0 0.5rem 0;\n  text-align: left;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
import { css } from "styled-components";
var textProps = css(_templateObject(), function (props) {
  return props.theme.typography.baseFontSize;
});
export var Wrapper = styled.div(_templateObject2(), function (props) {
  return props.theme.colors.white;
}, function (props) {
  return props.theme.colors.hoverLightBackground;
}, function (props) {
  return props.theme.colors.white;
});
export var Title = styled.h4(_templateObject3(), function (props) {
  return props.theme.colors.item;
}, textProps);
export var Price = styled.span(_templateObject4(), textProps);
export var PriceWithDiscount = styled.span(_templateObject5(), textProps);
export var Image = styled.div(_templateObject6());
export var ItemsQuantity = styled.p(_templateObject7());
export var Percentaje = styled.div(_templateObject8());
export var PercentajeValue = styled.div(_templateObject9());