import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject82() {
  var data = _taggedTemplateLiteral(["\n  width: 160px;\n  padding: 10px;\n  margin-left: auto;\n  background-color: #ed1c24;\n  border-radius: 8px;\n  color: white;\n  text-align: center;\n  text-transform: lowercase;\n  cursor: pointer;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n"]);

  _templateObject82 = function _templateObject82() {
    return data;
  };

  return data;
}

function _templateObject81() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n  width: 50%;\n  vertical-align: bottom;\n"]);

  _templateObject81 = function _templateObject81() {
    return data;
  };

  return data;
}

function _templateObject80() {
  var data = _taggedTemplateLiteral(["\n  display: block;\n  margin-left: auto;\n  color: #606060;\n  width: 100%;\n"]);

  _templateObject80 = function _templateObject80() {
    return data;
  };

  return data;
}

function _templateObject79() {
  var data = _taggedTemplateLiteral(["\n  text-transform: uppercase;\n  color: black;\n  text-transform: lowercase;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n"]);

  _templateObject79 = function _templateObject79() {
    return data;
  };

  return data;
}

function _templateObject78() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 5px;\n  display: flex;\n  align-items: center;\n  flex-direction: row;\n  justify-content: space-between;\n"]);

  _templateObject78 = function _templateObject78() {
    return data;
  };

  return data;
}

function _templateObject77() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 1.45rem;\n  display: block;\n  margin-top: 0px;\n  border-radius: 3px;\n  background: linear-gradient(90deg, #e8e8e8 0px, #f8f8f8 40px, #e8e8e8 80px);\n  background-size: 350px;\n  animation: animationSkeleton 2s infinite;\n"]);

  _templateObject77 = function _templateObject77() {
    return data;
  };

  return data;
}

function _templateObject76() {
  var data = _taggedTemplateLiteral(["\n  margin-left: auto;\n  color: #606060;\n"]);

  _templateObject76 = function _templateObject76() {
    return data;
  };

  return data;
}

function _templateObject75() {
  var data = _taggedTemplateLiteral(["\n  text-transform: uppercase;\n  color: black;\n  text-transform: lowercase;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n"]);

  _templateObject75 = function _templateObject75() {
    return data;
  };

  return data;
}

function _templateObject74() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n"]);

  _templateObject74 = function _templateObject74() {
    return data;
  };

  return data;
}

function _templateObject73() {
  var data = _taggedTemplateLiteral(["\n  margin-left: auto;\n  color: #606060;\n"]);

  _templateObject73 = function _templateObject73() {
    return data;
  };

  return data;
}

function _templateObject72() {
  var data = _taggedTemplateLiteral(["\n  text-transform: uppercase;\n  color: black;\n  text-transform: lowercase;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n"]);

  _templateObject72 = function _templateObject72() {
    return data;
  };

  return data;
}

function _templateObject71() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n"]);

  _templateObject71 = function _templateObject71() {
    return data;
  };

  return data;
}

function _templateObject70() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n"]);

  _templateObject70 = function _templateObject70() {
    return data;
  };

  return data;
}

function _templateObject69() {
  var data = _taggedTemplateLiteral(["\n  align-self: center;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  div {\n    display: flex;\n    align-items: center;\n  }\n"]);

  _templateObject69 = function _templateObject69() {
    return data;
  };

  return data;
}

function _templateObject68() {
  var data = _taggedTemplateLiteral(["\n  padding: 10px;\n  display: grid;\n  grid-template-columns: 20% 80%;\n  align-items: center;\n"]);

  _templateObject68 = function _templateObject68() {
    return data;
  };

  return data;
}

function _templateObject67() {
  var data = _taggedTemplateLiteral(["\n  color: #f76268;\n"]);

  _templateObject67 = function _templateObject67() {
    return data;
  };

  return data;
}

function _templateObject66() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n  color: #606060;\n"]);

  _templateObject66 = function _templateObject66() {
    return data;
  };

  return data;
}

function _templateObject65() {
  var data = _taggedTemplateLiteral(["\n  color: black;\n  font-weight: 700;\n"]);

  _templateObject65 = function _templateObject65() {
    return data;
  };

  return data;
}

function _templateObject64() {
  var data = _taggedTemplateLiteral(["\n  color: #afafaf;\n"]);

  _templateObject64 = function _templateObject64() {
    return data;
  };

  return data;
}

function _templateObject63() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  padding: 10px 0px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n"]);

  _templateObject63 = function _templateObject63() {
    return data;
  };

  return data;
}

function _templateObject62() {
  var data = _taggedTemplateLiteral(["\n  color: #25d366;\n"]);

  _templateObject62 = function _templateObject62() {
    return data;
  };

  return data;
}

function _templateObject61() {
  var data = _taggedTemplateLiteral(["\n  color: #afafaf;\n"]);

  _templateObject61 = function _templateObject61() {
    return data;
  };

  return data;
}

function _templateObject60() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n"]);

  _templateObject60 = function _templateObject60() {
    return data;
  };

  return data;
}

function _templateObject59() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n  color: black;\n"]);

  _templateObject59 = function _templateObject59() {
    return data;
  };

  return data;
}

function _templateObject58() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n"]);

  _templateObject58 = function _templateObject58() {
    return data;
  };

  return data;
}

function _templateObject57() {
  var data = _taggedTemplateLiteral(["\n  color: black;\n  text-transform: lowercase;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n"]);

  _templateObject57 = function _templateObject57() {
    return data;
  };

  return data;
}

function _templateObject56() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n"]);

  _templateObject56 = function _templateObject56() {
    return data;
  };

  return data;
}

function _templateObject55() {
  var data = _taggedTemplateLiteral(["\n  div {\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  svg {\n    transform: scale(0.7);\n  }\n"]);

  _templateObject55 = function _templateObject55() {
    return data;
  };

  return data;
}

function _templateObject54() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"]);

  _templateObject54 = function _templateObject54() {
    return data;
  };

  return data;
}

function _templateObject53() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-columns: 20% 80%;\n  align-items: center;\n"]);

  _templateObject53 = function _templateObject53() {
    return data;
  };

  return data;
}

function _templateObject52() {
  var data = _taggedTemplateLiteral(["\n  padding: 10px;\n  display: flex;\n  flex-direction: column;\n"]);

  _templateObject52 = function _templateObject52() {
    return data;
  };

  return data;
}

function _templateObject51() {
  var data = _taggedTemplateLiteral(["\n  width: 110px;\n  padding: 10px;\n  margin-left: auto;\n  background-color: #ed1c24;\n  border-radius: 8px;\n  color: white;\n  text-align: center;\n  text-transform: lowercase;\n  cursor: pointer;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n"]);

  _templateObject51 = function _templateObject51() {
    return data;
  };

  return data;
}

function _templateObject50() {
  var data = _taggedTemplateLiteral(["\n  min-width: 10px;\n  display: inline-block;\n  vertical-align: bottom;\n  color: #f76268;\n"]);

  _templateObject50 = function _templateObject50() {
    return data;
  };

  return data;
}

function _templateObject49() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n  font-weight: bold;\n"]);

  _templateObject49 = function _templateObject49() {
    return data;
  };

  return data;
}

function _templateObject48() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject48 = function _templateObject48() {
    return data;
  };

  return data;
}

function _templateObject47() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"]);

  _templateObject47 = function _templateObject47() {
    return data;
  };

  return data;
}

function _templateObject46() {
  var data = _taggedTemplateLiteral(["\n  padding: 10px;\n  display: grid;\n  grid-template-columns: 20% 35% 45%;\n  align-items: center;\n"]);

  _templateObject46 = function _templateObject46() {
    return data;
  };

  return data;
}

function _templateObject45() {
  var data = _taggedTemplateLiteral(["\n  padding-right: 10px;\n  display: block;\n  color: #afafaf;\n"]);

  _templateObject45 = function _templateObject45() {
    return data;
  };

  return data;
}

function _templateObject44() {
  var data = _taggedTemplateLiteral(["\n  padding-right: 10px;\n  display: inline-block;\n  color: #afafaf;\n  min-width: 200px;\n"]);

  _templateObject44 = function _templateObject44() {
    return data;
  };

  return data;
}

function _templateObject43() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n  font-weight: bold;\n  color: black;\n  text-transform: lowercase;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n"]);

  _templateObject43 = function _templateObject43() {
    return data;
  };

  return data;
}

function _templateObject42() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject42 = function _templateObject42() {
    return data;
  };

  return data;
}

function _templateObject41() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"]);

  _templateObject41 = function _templateObject41() {
    return data;
  };

  return data;
}

function _templateObject40() {
  var data = _taggedTemplateLiteral(["\n  padding: 10px;\n  display: grid;\n  grid-template-columns: 20% 80%;\n  align-items: center;\n"]);

  _templateObject40 = function _templateObject40() {
    return data;
  };

  return data;
}

function _templateObject39() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n"]);

  _templateObject39 = function _templateObject39() {
    return data;
  };

  return data;
}

function _templateObject38() {
  var data = _taggedTemplateLiteral(["\n  width: 40px;\n  height: 40px;\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  svg {\n    width: 75%;\n    margin-top: 10px;\n    animation: rotateTelephone 2s infinite;\n    transition: all 2s ease;\n  }\n"]);

  _templateObject38 = function _templateObject38() {
    return data;
  };

  return data;
}

function _templateObject37() {
  var data = _taggedTemplateLiteral(["\n  align-self: center;\n"]);

  _templateObject37 = function _templateObject37() {
    return data;
  };

  return data;
}

function _templateObject36() {
  var data = _taggedTemplateLiteral(["\n  div {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n"]);

  _templateObject36 = function _templateObject36() {
    return data;
  };

  return data;
}

function _templateObject35() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 15px;\n  div {\n    width: 32px;\n    height: 32px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border-radius: inherit;\n  }\n  svg {\n    width: 32px;\n    height: 32px;\n    border-radius: inherit;\n  }\n  img {\n    width: 32px;\n    height: 32px;\n    object-fit: contain;\n    border-radius: inherit;\n  }\n"]);

  _templateObject35 = function _templateObject35() {
    return data;
  };

  return data;
}

function _templateObject34() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  padding: 10px;\n  display: grid;\n  grid-template-columns: 20% 65% 15%;\n  align-items: center;\n  a {\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n  }\n"]);

  _templateObject34 = function _templateObject34() {
    return data;
  };

  return data;
}

function _templateObject33() {
  var data = _taggedTemplateLiteral(["\n    display: flex;\n    flex-direction: row;\n    justify-content: space-around;\n  "]);

  _templateObject33 = function _templateObject33() {
    return data;
  };

  return data;
}

function _templateObject32() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n\n  ", ";\n"]);

  _templateObject32 = function _templateObject32() {
    return data;
  };

  return data;
}

function _templateObject31() {
  var data = _taggedTemplateLiteral(["\n  font-weight: 700;\n"]);

  _templateObject31 = function _templateObject31() {
    return data;
  };

  return data;
}

function _templateObject30() {
  var data = _taggedTemplateLiteral(["\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n  "]);

  _templateObject30 = function _templateObject30() {
    return data;
  };

  return data;
}

function _templateObject29() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-columns: 50% 50%;\n  align-items: center;\n\n  ", ";\n"]);

  _templateObject29 = function _templateObject29() {
    return data;
  };

  return data;
}

function _templateObject28() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n"]);

  _templateObject28 = function _templateObject28() {
    return data;
  };

  return data;
}

function _templateObject27() {
  var data = _taggedTemplateLiteral(["\n    padding-left: 0;\n  "]);

  _templateObject27 = function _templateObject27() {
    return data;
  };

  return data;
}

function _templateObject26() {
  var data = _taggedTemplateLiteral(["\n  padding-left: 15px;\n  align-self: center;\n  color: #afafaf;\n  text-align: end;\n  ", ";\n"]);

  _templateObject26 = function _templateObject26() {
    return data;
  };

  return data;
}

function _templateObject25() {
  var data = _taggedTemplateLiteral(["\n  align-self: center;\n  color: #afafaf;\n"]);

  _templateObject25 = function _templateObject25() {
    return data;
  };

  return data;
}

function _templateObject24() {
  var data = _taggedTemplateLiteral(["\n  div {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n"]);

  _templateObject24 = function _templateObject24() {
    return data;
  };

  return data;
}

function _templateObject23() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  padding: 10px;\n  display: grid;\n  grid-template-columns: 20% 80%;\n  align-items: center;\n"]);

  _templateObject23 = function _templateObject23() {
    return data;
  };

  return data;
}

function _templateObject22() {
  var data = _taggedTemplateLiteral(["\n  align-self: center;\n"]);

  _templateObject22 = function _templateObject22() {
    return data;
  };

  return data;
}

function _templateObject21() {
  var data = _taggedTemplateLiteral(["\n  div {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  svg {\n    transform: scale(0.5);\n  }\n"]);

  _templateObject21 = function _templateObject21() {
    return data;
  };

  return data;
}

function _templateObject20() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  padding: 10px;\n  display: grid;\n  grid-template-columns: 20% 80%;\n  align-items: center;\n"]);

  _templateObject20 = function _templateObject20() {
    return data;
  };

  return data;
}

function _templateObject19() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject19 = function _templateObject19() {
    return data;
  };

  return data;
}

function _templateObject18() {
  var data = _taggedTemplateLiteral(["\n  text-transform: lowercase;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n"]);

  _templateObject18 = function _templateObject18() {
    return data;
  };

  return data;
}

function _templateObject17() {
  var data = _taggedTemplateLiteral(["\n  padding: 7px 15px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  background: #efeff2;\n  border-radius: 8px;\n  color: #f76268;\n"]);

  _templateObject17 = function _templateObject17() {
    return data;
  };

  return data;
}

function _templateObject16() {
  var data = _taggedTemplateLiteral(["\n  padding: 10px 15px;\n  background: #efeff2;\n  border-radius: 8px;\n  color: #f76268;\n  text-transform: lowercase;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n"]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 200px;\n  margin-top: 1rem;\n  margin-bottom: 1rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  div {\n    height: 200px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n"]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 200px;\n  margin-top: 1rem;\n  margin-bottom: 1rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  div {\n    height: 200px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  svg {\n    transform: scale(1.5);\n  }\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\n  font-weight: 700;\n  color: black;\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n  width: 90%;\n  margin: 20px auto;\n  text-align: center;\n  color: #afafaf;\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 250px;\n  margin-bottom: 1rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  img {\n    height: 250px;\n  }\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 200px;\n  margin-top: 1rem;\n  margin-bottom: 1rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  img {\n    height: 200px;\n  }\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 200px;\n  margin-top: 1rem;\n  margin-bottom: 1rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  img {\n    height: 200px;\n  }\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n    padding: 1rem 0.6rem 0rem 0.6rem;\n    margin-bottom: 0rem;\n    border-bottom: 1px solid rgba(50, 50, 50, 0.1);\n    box-shadow: none;\n  "]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  padding: 0.6rem;\n  margin-bottom: 1rem;\n  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);\n  border-radius: 10px;\n  border-bottom: none;\n  font-size: 1.5rem;\n  font-weight: ", ";\n  text-align: center;\n  text-transform: lowercase;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n  ", "\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n    padding: 1rem 0.6rem 0.6rem 0.6rem;\n    border-bottom: 1px solid rgba(50, 50, 50, 0.1);\n    box-shadow: none;\n  "]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  padding: 0.6rem;\n  margin-bottom: 1rem;\n  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);\n  border-radius: 10px;\n  border-bottom: none;\n  font-size: 1.5rem;\n  font-weight: ", ";\n  text-align: center;\n  text-transform: lowercase;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n  ", "\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  align-self: center;\n  font-size: 1rem;\n  text-transform: lowercase;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-columns: 50% 50%;\n  align-items: center;\n  position: absolute;\n  top: 0.4rem;\n  left: 0.5rem;\n  color: #f76268;\n  cursor: pointer;\n  font-size: 1rem;\n  text-transform: uppercase;\n  div {\n    height: 35px;\n    align-self: center;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  svg {\n    transform: scale(0.4);\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    padding-top: 0;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  margin: 0px 0px 20px 0px;\n  padding-top: 30px;\n  padding-bottom: 60px;\n  ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled, media } from "@styles";
export var Wrapper = styled.div(_templateObject(), media.xMediumScreen(_templateObject2()));
export var BackTitle = styled.div(_templateObject3());
export var BackTitleParragraph = styled.p(_templateObject4());
export var Tile = styled.h3(_templateObject5(), function (props) {
  return props.theme.typography.boldFontWeight;
}, media.xMediumScreen(_templateObject6()));
export var TileCanceledFulfillment = styled.h3(_templateObject7(), function (props) {
  return props.theme.typography.boldFontWeight;
}, media.xMediumScreen(_templateObject8()));
export var Gif = styled.div(_templateObject9());
export var GifArrived = styled.div(_templateObject10());
export var GifCanceledFulfillment = styled.div(_templateObject11());
export var ParragraphCanceledFulfillment = styled.p(_templateObject12());
export var SpanCanceledFulfillment = styled.span(_templateObject13());
export var Images = styled.div(_templateObject14());
export var ImagesCanceled = styled.div(_templateObject15());
export var Title = styled.p(_templateObject16());
export var TitleMyOrder = styled.div(_templateObject17());
export var TitleMyOrderTitle = styled.p(_templateObject18());
export var TitleMyOrderNumber = styled.p(_templateObject19());
export var CheckoutObservation = styled.div(_templateObject20());
export var CheckoutObservationImage = styled.div(_templateObject21());
export var CheckoutObservationData = styled.p(_templateObject22());
export var Management = styled.div(_templateObject23());
export var ManagementImg = styled.div(_templateObject24());
export var ManagementDate = styled.p(_templateObject25());
export var ManagementTime = styled.p(_templateObject26(), media.largeScreen(_templateObject27()));
export var ManagementData = styled.div(_templateObject28());
export var ManagementContainer = styled.div(_templateObject29(), media.largeScreen(_templateObject30()));
export var ManagementParragraph = styled.p(_templateObject31());
export var ManagementHour = styled.div(_templateObject32(), media.largeScreen(_templateObject33()));
export var Customer = styled.div(_templateObject34());
export var CustomerData = styled.div(_templateObject35());
export var CustomerImgData = styled.div(_templateObject36());
export var CustomerNameData = styled.p(_templateObject37());
export var CustomerImage = styled.div(_templateObject38());
export var CustomerNumber = styled.div(_templateObject39());
export var Shipping = styled.div(_templateObject40());
export var ShippingImage = styled.div(_templateObject41());
export var ShippingData = styled.div(_templateObject42());
export var ShippingBoldData = styled.span(_templateObject43());
export var ShippingParragraphData = styled.p(_templateObject44());
export var ShippingParragraphObservationsData = styled.p(_templateObject45());
export var Order = styled.div(_templateObject46());
export var OrderImage = styled.div(_templateObject47());
export var OrderData = styled.div(_templateObject48());
export var OrderParragraphData = styled.p(_templateObject49());
export var OrderBoldData = styled.span(_templateObject50());
export var OrderButton = styled.div(_templateObject51());
export var Payment = styled.div(_templateObject52());
export var PaymentContainer = styled.div(_templateObject53());
export var PaymentImage = styled.div(_templateObject54());
export var PaymentDataphoneImage = styled.div(_templateObject55());
export var PaymentData = styled.div(_templateObject56());
export var PaymentParragraphCashData = styled.p(_templateObject57());
export var PaymentParragraph = styled.div(_templateObject58());
export var PaymentParragraphDataStatus = styled.p(_templateObject59());
export var PaymentParragraphReturns = styled.div(_templateObject60());
export var PaymentParragraphDataReturns = styled.p(_templateObject61());
export var PaymentBoldDataReturns = styled.span(_templateObject62());
export var PaymentParragraphDataState = styled.div(_templateObject63());
export var PaymentParragraphData = styled.p(_templateObject64());
export var PaymentParragraphDataSpan = styled.span(_templateObject65());
export var PaymentParragraphDataSpanState = styled.span(_templateObject66());
export var PaymentBoldData = styled.span(_templateObject67());
export var Total = styled.div(_templateObject68());
export var TotalImage = styled.div(_templateObject69());
export var TotalData = styled.div(_templateObject70());
export var SubTotalData = styled.div(_templateObject71());
export var SubTotalParragraphData = styled.p(_templateObject72());
export var SubTotalSpanData = styled.span(_templateObject73());
export var ShippingTotalData = styled.div(_templateObject74());
export var ShippingTotalParragraphData = styled.p(_templateObject75());
export var ShippingTotalSpanData = styled.span(_templateObject76());
export var SkeletonPlaceholder = styled.span(_templateObject77());
export var TotalTotalData = styled.div(_templateObject78());
export var TotalTotalParragraphData = styled.p(_templateObject79());
export var TotalTotalSpanData = styled.span(_templateObject80());
export var SpanSkeleton = styled.span(_templateObject81());
export var VoucherButton = styled.div(_templateObject82());