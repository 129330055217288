import React from "react";
import { Money } from "@components/containers";
import * as S from "./styles";
export var ToppingValueMultiSelectAdd = function ToppingValueMultiSelectAdd(_ref) {
  var descriptionValue = _ref.descriptionValue,
      dispatch = _ref.dispatch,
      disabled = _ref.disabled,
      factor = _ref.factor,
      idTopping = _ref.idTopping,
      idValue = _ref.idValue,
      isActive = _ref.isActive,
      isLastToppingValueAndTopping = _ref.isLastToppingValueAndTopping,
      light = _ref.light,
      nameValue = _ref.nameValue,
      orderValue = _ref.orderValue,
      price = _ref.price,
      quantity = _ref.quantity,
      ref = _ref.ref,
      valueValue = _ref.valueValue;
  var handleOnChange = React.useCallback(function (idTopping, idValue, isActive, factor, nameValue, newQuantity, orderValue, price, ref, valueValue, descriptionValue) {
    dispatch({
      type: "update-specific-topping",
      payload: {
        idTopping: idTopping,
        dataValue: {
          idValue: idValue,
          isActive: isActive,
          factor: factor,
          nameValue: nameValue,
          orderValue: orderValue,
          price: price,
          ref: ref,
          valueValue: valueValue,
          descriptionValue: descriptionValue,
          quantity: newQuantity
        }
      }
    });
  }, [dispatch]);
  return React.createElement(React.Fragment, null, isLastToppingValueAndTopping && React.createElement("p", {
    id: "final"
  }), React.createElement(S.Value, {
    light: light
  }, React.createElement(S.ValueNamePrice, null, React.createElement(S.ValueParragraph, {
    htmlFor: "".concat(idValue)
  }, nameValue || valueValue), React.createElement(S.ValueDescription, null, descriptionValue), React.createElement(S.ValuePrice, null, price && price.amount && " +", price && React.createElement(Money, {
    money: price
  }))), React.createElement(S.ValueAddition, null, React.createElement(S.ValueLess, {
    onClick: function onClick() {
      if (quantity - 1 >= 0) {
        var newRef = ref || null;
        handleOnChange(idTopping, idValue, isActive, factor, nameValue, quantity - 1, orderValue, price, newRef, valueValue, descriptionValue);
      }
    }
  }, " ", "-", " "), React.createElement(S.ValueInput, {
    readOnly: true,
    type: "number",
    value: quantity
  }), React.createElement(S.ValueMore, {
    onClick: function onClick() {
      if (!disabled) {
        var newRef = ref || null;
        handleOnChange(idTopping, idValue, isActive, factor, nameValue, quantity + 1, orderValue, price, newRef, valueValue, descriptionValue);
      }
    },
    disabled: disabled
  }, " ", "+", " "))));
};
ToppingValueMultiSelectAdd.displayName = "ToppingValueMultiSelectAdd";