import "./scss/index.scss";
import React from "react";
import { SkeletonDescription } from "../SkeletonDescription/SkeletonDescription";
import { SkeletonProduct } from "../SkeletonProduct/SkeletonProduct";
export var Skeleton = function Skeleton() {
  return React.createElement(React.Fragment, null, location.pathname.includes("product") ? React.createElement(React.Fragment, null, React.createElement("section", {
    className: "box",
    style: {
      position: "absolute",
      left: "7%",
      top: "15%"
    }
  }, React.createElement("div", {
    className: "product-img",
    style: {
      width: "15rem",
      height: "15rem"
    }
  }), React.createElement("div", {
    className: "skeleton-placeholder",
    style: {
      width: "250%",
      height: "2.45rem"
    }
  }), React.createElement("div", {
    className: "skeleton-placeholder",
    style: {
      width: "250%",
      height: "2.45rem"
    }
  }))) : !location.pathname.includes("subcategory") ? React.createElement(React.Fragment, null, React.createElement(SkeletonDescription, null)) : React.createElement(React.Fragment, null, React.createElement(SkeletonProduct, null), React.createElement(SkeletonProduct, null)));
};