import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "./scss/index.scss";
import React, { useContext } from "react";
import Media from "react-media";
import { FormattedMessage } from "react-intl";
import { animated, useSpring } from "react-spring";
import ReactSVG from "react-svg";
import { xMediumScreen } from "@styles/constants";
import { useAppSelector } from "@temp/redux";
import { AlertCheckoutOrder } from "../AlertCheckoutOrder";
import { OverlayContext, OverlayTheme, OverlayType } from "..";

var flechaPedir = require("../../images/flechaPedir.svg");

export var OrderButton = function OrderButton(_ref) {
  var amount = _ref.amount;
  var overlayContext = useContext(OverlayContext);

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      showAlert = _React$useState2[0],
      setShowAlert = _React$useState2[1];

  var _useAppSelector = useAppSelector(function (store) {
    return store;
  }),
      openModal = _useAppSelector.search.openModal,
      hasAttentionAtCurrentTime = _useAppSelector.tradeAttention.hasAttentionAtCurrentTime;

  var scaleButton = useSpring({
    config: {
      friction: 40,
      mass: 5,
      tension: 350
    },
    from: {
      scale: 1.3
    },
    loop: true,
    to: {
      scale: 1
    }
  });

  var action = function action() {
    if (amount > 0) {
      overlayContext.show(OverlayType.cart, OverlayTheme.right);
    } else {
      setShowAlert(true);
    }
  };

  return React.createElement(React.Fragment, null, React.createElement("div", {
    onClick: action
  }, React.createElement(Media, {
    query: {
      minWidth: xMediumScreen
    }
  }, function (matches) {
    return matches ? React.createElement(animated.button, {
      className: "orderContainerDesktop ".concat(hasAttentionAtCurrentTime ? "orderContainerDesktop__hasAttention" : "orderContainerDesktop__hasNotAttention", " ").concat(openModal && "orderContainerDesktop__openSearchModal"),
      style: scaleButton
    }, React.createElement("p", null, React.createElement(FormattedMessage, {
      id: "components.OrderButton.index.3620521256",
      defaultMessage: "Order"
    })), React.createElement(ReactSVG, {
      className: "orderContainerDesktop__SVG",
      path: flechaPedir
    })) : React.createElement(animated.button, {
      className: "orderContainerMobile ".concat(openModal && "orderContainerMobile__openSearchModal"),
      style: scaleButton
    }, React.createElement("p", null, React.createElement(FormattedMessage, {
      id: "components.OrderButton.index.3620521256",
      defaultMessage: "Order"
    })));
  })), showAlert && React.createElement(AlertCheckoutOrder, {
    hideModal: function hideModal() {
      setShowAlert(false);
    }
  }));
};
export default OrderButton;