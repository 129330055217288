import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 80px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: fixed;\n  bottom: -7px;\n  left: 0;\n  & button {\n    width: 250px;\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  font-size: 1.7rem;\n  padding: 10px;\n  margin-bottom: 30px;\n  text-align: center;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  align-self: center;\n  text-transform: lowercase;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  height: 35px;\n  align-self: center;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  svg {\n    transform: scale(0.4);\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  width: 110px;\n  display: grid;\n  grid-template-columns: 40% 40%;\n  position: fixed;\n  top: 108px;\n  left: 30px;\n  color: #f76268;\n  cursor: pointer;\n  border-radius: 6px;\n  background-color: white;\n  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);\n  font-size: 1rem;\n  text-transform: uppercase;\n  z-index: 9999;\n  @media (min-width: 1500px) {\n    left: 85px;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: 80%;\n  margin: 0 auto;\n  padding-top: 100px;\n  padding-bottom: 60px;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Body = styled.div(_templateObject());
export var GoBack = styled.div(_templateObject2());
export var GoBackImg = styled.div(_templateObject3());
export var GoBackLetter = styled.p(_templateObject4());
export var Title = styled.h1(_templateObject5());
export var Footer = styled.div(_templateObject6());