import _extends from "@babel/runtime/helpers/extends";
import React from "react";
import { FormattedMessage } from "react-intl";
import ReactSVG from "react-svg";
import { isEqual } from "lodash";
import { Money } from "@components/containers";
import { commonMessages } from "@temp/intl";
import { Link } from "react-router-dom";
import { getDBIdFromGraphqlId } from "@utils/core";
import { CachedImage } from "../CachedImage";
import * as S from "./styles";

var editImg = require("../../../../images/edit.svg");
/**
 * Row with product to display in cart summary.
 */


var percentajeImg = require("../../../../images/sale-double.svg");

var CartSummaryRow = function CartSummaryRow(_ref) {
  var _totalPriceWithOutTop, _totalPriceUndiscount, _totalPriceWithOutTop2, _totalPriceUndiscount2, _price$net, _priceUndiscounted$ne;

  var lineId = _ref.lineId,
      index = _ref.index,
      sku = _ref.sku,
      name = _ref.name,
      price = _ref.price,
      priceUndiscounted = _ref.priceUndiscounted,
      quantity = _ref.quantity,
      productVariantId = _ref.productVariantId,
      subCategoryId = _ref.subCategoryId,
      thumbnail = _ref.thumbnail,
      toppingsName = _ref.toppingsName,
      totalPriceUndiscountedWithOutToppingsCost = _ref.totalPriceUndiscountedWithOutToppingsCost,
      totalPriceWithOutToppingsCost = _ref.totalPriceWithOutToppingsCost;
  var discountedPercentage = totalPriceWithOutToppingsCost && (totalPriceWithOutToppingsCost === null || totalPriceWithOutToppingsCost === void 0 ? void 0 : (_totalPriceWithOutTop = totalPriceWithOutToppingsCost.net) === null || _totalPriceWithOutTop === void 0 ? void 0 : _totalPriceWithOutTop.amount) && (totalPriceUndiscountedWithOutToppingsCost === null || totalPriceUndiscountedWithOutToppingsCost === void 0 ? void 0 : (_totalPriceUndiscount = totalPriceUndiscountedWithOutToppingsCost.net) === null || _totalPriceUndiscount === void 0 ? void 0 : _totalPriceUndiscount.amount) && totalPriceUndiscountedWithOutToppingsCost && 100 - (totalPriceWithOutToppingsCost === null || totalPriceWithOutToppingsCost === void 0 ? void 0 : (_totalPriceWithOutTop2 = totalPriceWithOutToppingsCost.net) === null || _totalPriceWithOutTop2 === void 0 ? void 0 : _totalPriceWithOutTop2.amount) * 100 / (totalPriceUndiscountedWithOutToppingsCost === null || totalPriceUndiscountedWithOutToppingsCost === void 0 ? void 0 : (_totalPriceUndiscount2 = totalPriceUndiscountedWithOutToppingsCost.net) === null || _totalPriceUndiscount2 === void 0 ? void 0 : _totalPriceUndiscount2.amount);
  var discountedPercentageValue = "0";

  if (typeof discountedPercentage === "number") {
    discountedPercentageValue = "-".concat(Math.floor(discountedPercentage), "%");
  }

  return React.createElement(S.Wrapper, {
    "data-test": "cartSummary",
    "data-test-id": sku
  }, React.createElement(S.Name, {
    "data-test": "name"
  }, name), React.createElement(S.Quantity, null, React.createElement(FormattedMessage, commonMessages.quantity), ": ", React.createElement("span", {
    "data-test": "quantity"
  }, quantity)), React.createElement(S.ToppingsName, null, toppingsName), React.createElement(S.ContainerPhoto, null, React.createElement(Link, {
    to: "/product/edit/".concat(name, "/").concat(getDBIdFromGraphqlId(productVariantId, "ProductVariant"), "/").concat(getDBIdFromGraphqlId(subCategoryId, "Category"), "/").concat(lineId),
    onClick: function onClick() {
      return localStorage.setItem("checkout-modify", "true");
    }
  }, React.createElement(S.Photo, null, React.createElement(CachedImage, _extends({
    "data-test": "image"
  }, thumbnail)), !isEqual(priceUndiscounted, price) && React.createElement(S.Discount, null, React.createElement(S.DiscountValue, null, React.createElement(S.DiscountValueSpan, null, discountedPercentageValue), React.createElement(ReactSVG, {
    path: percentajeImg
  }))), React.createElement(S.EditImg, null, React.createElement(ReactSVG, {
    path: editImg
  })))), (price === null || price === void 0 ? void 0 : (_price$net = price.net) === null || _price$net === void 0 ? void 0 : _price$net.amount) !== (priceUndiscounted === null || priceUndiscounted === void 0 ? void 0 : (_priceUndiscounted$ne = priceUndiscounted.net) === null || _priceUndiscounted$ne === void 0 ? void 0 : _priceUndiscounted$ne.amount) ? React.createElement(React.Fragment, null, React.createElement(S.Price, {
    "data-test": "price"
  }, React.createElement(Money, {
    money: price === null || price === void 0 ? void 0 : price.net
  })), React.createElement(S.PriceUndiscounted, {
    "data-test": "price"
  }, React.createElement(Money, {
    money: priceUndiscounted === null || priceUndiscounted === void 0 ? void 0 : priceUndiscounted.net
  }))) : React.createElement(S.Price, {
    "data-test": "price"
  }, React.createElement(Money, {
    money: price === null || price === void 0 ? void 0 : price.net
  }))));
};

export { CartSummaryRow };