import _extends from "@babel/runtime/helpers/extends";
import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useCheckoutStepFromPath, useCheckoutStepState } from "@hooks";
import { CHECKOUT_STEPS } from "@temp/core/config";
import { checkIfShippingRequiredForProducts } from "@utils/core";
var CheckoutRouter = React.memo(function (_ref) {
  var items = _ref.items,
      checkout = _ref.checkout,
      payment = _ref.payment,
      totalPrice = _ref.totalPrice,
      RenderAddress = _ref.RenderAddress,
      RenderShipping = _ref.RenderShipping,
      RenderPayment = _ref.RenderPayment,
      RenderReview = _ref.RenderReview,
      locationPrueba = _ref.locationPrueba;
  var location = useLocation();
  var navigate = useNavigate();

  var _useCheckoutStepState = useCheckoutStepState(items, checkout, payment, totalPrice),
      recommendedStep = _useCheckoutStepState.recommendedStep,
      maxPossibleStep = _useCheckoutStepState.maxPossibleStep;

  var stepFromPath = useCheckoutStepFromPath(location === null || location === void 0 ? void 0 : location.pathname);
  var isShippingRequiredForProducts = checkIfShippingRequiredForProducts(items);

  var getStepLink = function getStepLink() {
    var _CHECKOUT_STEPS$find;

    return ((_CHECKOUT_STEPS$find = CHECKOUT_STEPS.find(function (stepObj) {
      return stepObj.step === recommendedStep;
    })) === null || _CHECKOUT_STEPS$find === void 0 ? void 0 : _CHECKOUT_STEPS$find.link) || CHECKOUT_STEPS[0].link;
  };

  useEffect(function () {
    if (!(location === null || location === void 0 ? void 0 : location.pathname.includes(CHECKOUT_STEPS[4].link)) && (!stepFromPath || stepFromPath && maxPossibleStep < stepFromPath) || (location === null || location === void 0 ? void 0 : location.pathname.includes(CHECKOUT_STEPS[1].link)) && !isShippingRequiredForProducts) {
      return navigate(getStepLink());
    }
  }, [location === null || location === void 0 ? void 0 : location.pathname]);
  return React.createElement(Routes, null, React.createElement(Route, {
    path: CHECKOUT_STEPS[4].link,
    element: React.createElement(RenderReview, null)
  }), React.createElement(Route, {
    path: CHECKOUT_STEPS[0].link,
    element: React.createElement(RenderAddress, null)
  }), React.createElement(Route, {
    path: CHECKOUT_STEPS[1].link,
    element: React.createElement(RenderShipping, null)
  }), React.createElement(Route, {
    path: CHECKOUT_STEPS[2].link,
    element: React.createElement(RenderPayment, null)
  }), React.createElement(Route, {
    path: CHECKOUT_STEPS[3].link,
    element: React.createElement(RenderReview, null)
  }), React.createElement(Route, {
    path: "*",
    element: function element(props) {
      return React.createElement(Navigate, _extends({}, props, {
        to: getStepLink()
      }));
    }
  }));
});
export { CheckoutRouter };