import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import * as React from "react";
import "./scss/index.scss";

var LuzattAcceptanceField = function LuzattAcceptanceField(_ref) {
  var _ref$label = _ref.label,
      label = _ref$label === void 0 ? "" : _ref$label,
      iconLeft = _ref.iconLeft,
      iconRight = _ref.iconRight,
      errors = _ref.errors,
      helpText = _ref.helpText,
      _ref$styleType = _ref.styleType,
      styleType = _ref$styleType === void 0 ? "white" : _ref$styleType,
      checked = _ref.checked,
      _ref$onChange = _ref.onChange,
      onChange = _ref$onChange === void 0 ? function () {
    return null;
  } : _ref$onChange,
      rest = _objectWithoutProperties(_ref, ["label", "iconLeft", "iconRight", "errors", "helpText", "styleType", "checked", "onChange"]);

  // const ref = React.useRef<HTMLDivElement>(null);
  return React.createElement("div", {
    className: "inputLuzattAcceptance"
  }, React.createElement("input", _extends({
    checked: checked,
    onClick: function onClick(evt) {
      onChange(evt);
    }
  }, rest)), React.createElement("span", null, "Acepto los", " ", React.createElement("a", {
    className: "acceptanceUrl",
    href: "/page/terminos-condiciones/",
    target: "_blank"
  }, "t\xE9rminos y condiciones"), " ", "y la", " ", React.createElement("a", {
    className: "acceptanceUrl",
    href: "/page/politica-tratamiento-datos/",
    target: "_blank"
  }, "pol\xEDtica de tratamiento de datos")));
};

export default LuzattAcceptanceField;