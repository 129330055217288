import "./scss/index.scss";
import * as React from "react";
import NavDelivery from "./NavDelivery";

var MenuDelivery = function MenuDelivery(_ref) {
  var finishedAmount = _ref.finishedAmount,
      inProgressAmount = _ref.inProgressAmount,
      isLoadingFinishedAmount = _ref.isLoadingFinishedAmount,
      handleInProgressRefetch = _ref.handleInProgressRefetch,
      handleFinishedRefetch = _ref.handleFinishedRefetch;
  return React.createElement(NavDelivery, {
    finishedAmount: finishedAmount,
    inProgressAmount: inProgressAmount,
    isLoadingFinishedAmount: isLoadingFinishedAmount,
    handleInProgressRefetch: handleInProgressRefetch,
    handleFinishedRefetch: handleFinishedRefetch
  });
};

export default MenuDelivery;