import React from "react";
import Media from "react-responsive";
import { FormattedMessage } from "react-intl";
import { Button, Loader } from "@components/atoms";
import { OrderTabelDelivery } from "@components/molecules";
import { xLargeScreen } from "@styles/constants";
import * as S from "./styles";
export var OrderTableDelivery = function OrderTableDelivery(_ref) {
  var _dataLoadMore$pageInf;

  var data = _ref.data,
      dataLoadMore = _ref.dataLoadMore,
      inProgressFlag = _ref.inProgressFlag,
      loadMore = _ref.loadMore,
      deliveryMan = _ref.deliveryMan,
      loading = _ref.loading;
  return React.createElement(React.Fragment, null, React.createElement(Media, {
    minWidth: xLargeScreen
  }, React.createElement(S.TableHead, {
    hasInProgressState: inProgressFlag
  }, React.createElement(S.HeadShop, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.OrderTableDelivery.OrderTableDelivery.580557469",
    defaultMessage: "establishment"
  })), React.createElement(S.HeadNumberOrder, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.OrderTableDelivery.OrderTableDelivery.4220354369",
    defaultMessage: "order number"
  })), React.createElement(S.HeadOrder, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.OrderTableDelivery.OrderTableDelivery.1128268068",
    defaultMessage: "products"
  })), React.createElement(S.HeadStatus, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.OrderTableDelivery.OrderTableDelivery.3394423989",
    defaultMessage: "status"
  })), React.createElement(S.HeadDate, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.OrderTableDelivery.OrderTableDelivery.2076378394",
    defaultMessage: "Order date"
  })), !inProgressFlag && React.createElement(S.HeadDate, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.OrderTableDelivery.OrderTableDelivery.2618892129",
    defaultMessage: "finished date"
  })), React.createElement(S.HeadDetail, null, " "))), React.createElement(OrderTabelDelivery, {
    orders: data === null || data === void 0 ? void 0 : data.edges,
    inProgressFlag: inProgressFlag
  }), loading ? React.createElement(S.Loader, null, React.createElement(Loader, null)) : (dataLoadMore === null || dataLoadMore === void 0 ? void 0 : (_dataLoadMore$pageInf = dataLoadMore.pageInfo) === null || _dataLoadMore$pageInf === void 0 ? void 0 : _dataLoadMore$pageInf.hasNextPage) && React.createElement(S.Wrapper, null, React.createElement(Button, {
    testingContext: "loadMoreOrdersButton",
    onClick: function onClick() {
      loadMore();
    }
  }, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.OrderTableDelivery.OrderTableDelivery.1138265409",
    defaultMessage: "Load more"
  }))));
};
OrderTableDelivery.displayName = "OrderTableDelivery";