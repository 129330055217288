import React from "react";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "@temp/redux";
import { AppDeliveryLayout, MetaConsumer, OverlayManager, OverlayProvider } from "../components";
import "../globalStyles/scss/index.scss";
import { RoutesDelivery } from "./routes";
import Notifications from "./Notifications";

var AppComponentDelivery = function AppComponentDelivery() {
  return React.createElement(ReduxProvider, {
    store: store
  }, React.createElement(OverlayProvider, null, React.createElement(MetaConsumer, null), React.createElement(AppDeliveryLayout, null, React.createElement(RoutesDelivery, null)), React.createElement(OverlayManager, null), React.createElement(Notifications, null)));
};

export default AppComponentDelivery;