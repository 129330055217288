import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "../../views/Homee/scss/index.scss";
import * as React from "react";
import { useVouchersAvailableToUser } from "@saleor/sdk";
import { MetaWrapper, AlertVouchersAvailableToUser } from "@temp/components";
import { ChangeAmountProductsContext } from "@temp/contexts";
import { PageHomeProducts } from "@temp/views/Homee";

var HomeProducts = function HomeProducts(_ref) {
  var allProducts = _ref.allProducts;

  var _React$useContext = React.useContext(ChangeAmountProductsContext),
      maxProductsPerCategory = _React$useContext.maxProductsPerCategory;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      showVouchersList = _React$useState2[0],
      setShowVouchersList = _React$useState2[1];

  var _useVouchersAvailable = useVouchersAvailableToUser({
    fetchPolicy: localStorage.getItem("vourcherListSeen") ? "cache-first" : "network-only"
  }),
      vouchersData = _useVouchersAvailable.data,
      vouchersLoading = _useVouchersAvailable.loading;

  React.useEffect(function () {
    var _vouchersData$voucher;

    var voucherListSeen = localStorage.getItem("vourcherListSeen");

    if (!vouchersLoading && (vouchersData === null || vouchersData === void 0 ? void 0 : (_vouchersData$voucher = vouchersData.vouchersAvailableToUser) === null || _vouchersData$voucher === void 0 ? void 0 : _vouchersData$voucher.length) > 0 && !voucherListSeen && voucherListSeen !== "null") {
      localStorage.setItem("vourcherListSeen", "seen");
      setShowVouchersList(true);
    } else {
      setShowVouchersList(false);
    }
  }, [vouchersLoading]);
  React.useEffect(function () {
    var scrollProduct = localStorage.getItem("scroll-product");
    var scrollHome = localStorage.getItem("scroll-home");

    if (!scrollProduct && !scrollHome) {
      window.scrollTo(0, 0);
    }

    if (scrollProduct !== null) {
      window.scrollTo(0, +scrollProduct);
      localStorage.removeItem("scroll-product");
    }

    if (scrollHome !== null) {
      window.scrollTo(0, +scrollHome);
      localStorage.removeItem("scroll-home");
    }

    return function () {};
  }, []);
  return React.createElement(React.Fragment, null, React.createElement(MetaWrapper, {
    meta: {
      type: "product.sale"
    }
  }, React.createElement(PageHomeProducts, {
    allProducts: allProducts,
    maxProductsPerCategory: maxProductsPerCategory
  })), showVouchersList && React.createElement(AlertVouchersAvailableToUser, {
    hideModal: function hideModal() {
      return setShowVouchersList(false);
    },
    homeEntry: true,
    vouchersAvailableToUser: vouchersData === null || vouchersData === void 0 ? void 0 : vouchersData.vouchersAvailableToUser
  }));
};

export default HomeProducts;