import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 10px;\n  background-color: white;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  margin: 0px;\n  border-radius: 8px;\n  background-color: #efeff2;\n  color: #f76268;\n  div {\n    padding: 5px;\n    display: grid;\n    grid-template-columns: 15% 85%;\n    label {\n      padding: 0;\n      display: flex;\n      justify-content: center;\n      align-items: center;\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  height: auto;\n  padding: 0 1rem;\n  margin: ", ";\n  input {\n    outline: none;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Comments = styled.div(_templateObject(), function (props) {
  return props.desktop ? "0" : "20px auto";
});
export var CommentsCheck = styled.div(_templateObject2());
export var CommentsCheckParragrah = styled.p(_templateObject3());
export var CommentsObservation = styled.div(_templateObject4());