import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl"; // import { FormattedMessage, useIntl } from "react-intl";

import Media from "react-responsive";
import ReactSVG from "react-svg";
import { Icon } from "@components/atoms"; // import { Icon, IconButton } from "@components/atoms";

import { CachedImage, TextField } from "@components/molecules";
import { TaxedMoney } from "@components/containers";
import { xLargeScreen } from "@styles/constants";
import { commonMessages } from "@temp/intl";
import * as S from "./styles";

var trashImg = require("../../../../images/eliminar.svg");

var editImg = require("../../../../images/edit.svg");

var QuantityLeftButton = function QuantityLeftButton(subtract, index) {
  return React.createElement(S.QuantityButtons, {
    "data-test": "quantityControls"
  }, React.createElement("div", {
    onClick: subtract,
    "data-test": "subtractButton"
  }, React.createElement(Icon, {
    size: 16,
    name: "horizontal_line",
    color: "white"
  })));
};

var QuantityRightButton = function QuantityRightButton(add, index) {
  return React.createElement(S.QuantityButtons, {
    "data-test": "quantityControls"
  }, React.createElement("div", {
    onClick: add,
    "data-test": "increaseButton"
  }, React.createElement(Icon, {
    size: 16,
    name: "plus",
    color: "white"
  })));
};
/**
 * Product row displayed on cart page
 */


export var CartRow = function CartRow(_ref) {
  var _unitPrice$price, _unitPrice$priceUndis;

  var index = _ref.index,
      totalPrice = _ref.totalPrice,
      unitPrice = _ref.unitPrice,
      name = _ref.name,
      sku = _ref.sku,
      quantity = _ref.quantity,
      maxQuantity = _ref.maxQuantity,
      observation = _ref.observation,
      observationEdit = _ref.observationEdit,
      thumbnail = _ref.thumbnail,
      _ref$attributes = _ref.attributes,
      attributes = _ref$attributes === void 0 ? [] : _ref$attributes,
      variantId = _ref.variantId,
      onDataChange = _ref.onDataChange,
      setShowEdit = _ref.setShowEdit,
      setQuantityEdit = _ref.setQuantityEdit,
      setIdEdit = _ref.setIdEdit,
      setObservationEdit = _ref.setObservationEdit,
      onRemove = _ref.onRemove,
      id = _ref.id;

  var _useState = useState(quantity.toString()),
      _useState2 = _slicedToArray(_useState, 2),
      tempQuantity = _useState2[0],
      setTempQuantity = _useState2[1];

  var _useState3 = useState(observation),
      _useState4 = _slicedToArray(_useState3, 2),
      tempObservation = _useState4[0],
      setTempObservation = _useState4[1];

  var _useState5 = useState(observation),
      _useState6 = _slicedToArray(_useState5, 2),
      editObservation = _useState6[0],
      setEditObservation = _useState6[1];

  var _useState7 = useState(false),
      _useState8 = _slicedToArray(_useState7, 2),
      isTooMuch = _useState8[0],
      setIsTooMuch = _useState8[1];

  var intl = useIntl();

  var handleBlurQuantityInput = function handleBlurQuantityInput() {
    var newQuantity = parseInt(tempQuantity, 10);

    if (isNaN(newQuantity) || newQuantity <= 0) {
      newQuantity = quantity;
    } else if (newQuantity > maxQuantity) {
      newQuantity = maxQuantity;
    }

    if (quantity !== newQuantity) {
      onDataChange(newQuantity, tempObservation);
    }

    var newTempQuantity = newQuantity.toString();

    if (tempQuantity !== newTempQuantity) {
      setTempQuantity(newTempQuantity);
    }

    setIsTooMuch(false);
  };

  useEffect(function () {
    setTempQuantity(quantity.toString());
    return function () {
      setTempQuantity("0");
    };
  }, [quantity]);
  useEffect(function () {
    setTempObservation(observation);
    setEditObservation(observation);
    return function () {
      setTempObservation("");
      setEditObservation("");
    };
  }, [observation]);
  var add = React.useCallback(function () {
    return quantity < maxQuantity && onDataChange(quantity + 1, tempObservation);
  }, [quantity]);
  var subtract = React.useCallback(function () {
    return quantity > 1 && onDataChange(quantity - 1, tempObservation);
  }, [quantity]);

  var handleQuantityChange = function handleQuantityChange(evt) {
    var newQuantity = parseInt(evt.target.value, 10);
    setTempQuantity(evt.target.value);
    setIsTooMuch(!isNaN(newQuantity) && newQuantity > maxQuantity);
  };

  var quantityErrors = isTooMuch ? [{
    message: intl.formatMessage(commonMessages.maxQtyIs, {
      maxQuantity: maxQuantity
    })
  }] : undefined;
  var nameValue = name.length > 45 ? "".concat(name.slice(0, 45), "...") : name;
  var priceValidateDiscount = (unitPrice === null || unitPrice === void 0 ? void 0 : (_unitPrice$price = unitPrice.price) === null || _unitPrice$price === void 0 ? void 0 : _unitPrice$price.net.amount) !== (unitPrice === null || unitPrice === void 0 ? void 0 : (_unitPrice$priceUndis = unitPrice.priceUndiscounted) === null || _unitPrice$priceUndis === void 0 ? void 0 : _unitPrice$priceUndis.net.amount);
  return React.createElement(React.Fragment, null, React.createElement(S.WrapperContainer, null, React.createElement(S.Wrapper, {
    "data-test": "cartRow",
    "data-test-id": sku
  }, React.createElement(S.Photo, null, React.createElement(CachedImage, _extends({
    "data-test": "itemImage"
  }, thumbnail))), React.createElement(S.Description, null, React.createElement(S.Name, {
    "data-test": "itemName"
  }, nameValue)), React.createElement(S.Quantity, null, React.createElement(TextField, {
    name: "quantity",
    label: intl.formatMessage(commonMessages.qty),
    value: tempQuantity,
    onBlur: handleBlurQuantityInput,
    onChange: handleQuantityChange,
    contentLeft: QuantityLeftButton(subtract, index),
    contentRight: QuantityRightButton(add, index),
    errors: quantityErrors,
    quantityInput: true
  })), React.createElement(S.Trash, null, React.createElement(ReactSVG, {
    path: trashImg,
    onClick: onRemove,
    className: "overlay__header-back"
  })), React.createElement(Media, {
    minWidth: xLargeScreen
  }, React.createElement(S.ObservationDesktop, null, React.createElement(TextField, {
    name: "productObservation",
    label: intl.formatMessage(_defineProperty({
      defaultMessage: "Observations",
      "id": "@next.components.organisms.CartRow.CartRow.722592990"
    }, "id", "@next.components.organisms.CartRow.CartRow.722592990")),
    value: editObservation !== "null" && editObservation !== null ? editObservation : "",
    onChange: function onChange(e) {
      if (e.target.value.length <= 140) {
        setEditObservation(e.target.value);
      }
    },
    onBlur: function onBlur() {
      if (editObservation.trim() !== tempObservation.trim()) {
        var observationData = editObservation.trim() || "null";
        onDataChange(Number(tempQuantity), observationData);
      }
    },
    autoComplete: "observation",
    textAreaInputLabel: true,
    placeholder: intl.formatMessage(_defineProperty({
      defaultMessage: "Ex: The hamburger without onion",
      "id": "@next.components.organisms.CartRow.CartRow.1721244567"
    }, "id", "@next.components.organisms.CartRow.CartRow.1721244567"))
  }))), React.createElement(S.UnitPrice, null, priceValidateDiscount ? React.createElement(React.Fragment, null, React.createElement("p", {
    "data-test": "unitPrice"
  }, React.createElement(TaxedMoney, {
    taxedMoney: unitPrice === null || unitPrice === void 0 ? void 0 : unitPrice.price,
    quantity: parseInt(tempQuantity, 10),
    basket: true
  })), React.createElement("p", {
    "data-test": "unitPrice"
  }, React.createElement(TaxedMoney, {
    taxedMoney: unitPrice === null || unitPrice === void 0 ? void 0 : unitPrice.priceUndiscounted,
    quantity: parseInt(tempQuantity, 10),
    basket: true
  }))) : React.createElement("p", {
    "data-test": "unitPrice"
  }, React.createElement(TaxedMoney, {
    taxedMoney: unitPrice === null || unitPrice === void 0 ? void 0 : unitPrice.price,
    quantity: parseInt(tempQuantity, 10),
    basket: true
  })))), React.createElement(Media, {
    maxWidth: xLargeScreen
  }, React.createElement(S.Observation, null, React.createElement(S.ObservationData, null, React.createElement(S.ObservationTitle, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.CartRow.CartRow.722592990",
    defaultMessage: "Observations"
  }), ": "), React.createElement(S.ObservationValue, null, tempObservation !== "null" && tempObservation !== null && tempObservation !== "" ? tempObservation : React.createElement(FormattedMessage, commonMessages.notDefined))), React.createElement(S.ObservationEdit, null, React.createElement(ReactSVG, {
    path: editImg,
    onClick: function onClick() {
      setShowEdit(true);
      setQuantityEdit(tempQuantity);
      setIdEdit(variantId);
      setObservationEdit(tempObservation);
    }
  }))))));
};