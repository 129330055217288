import React from "react";
import { Route, Routes } from "react-router-dom";
import { Account } from "@temp/views/Account";
import { OrderDetails } from "./views";
export var baseUrl = "/my-account/";
export var userOrderDetailsUrl = "".concat(baseUrl, "order/:id/");
export var orderHistoryUrl = "".concat(baseUrl, "order/history/");

var RoutesUserAccount = function RoutesUserAccount() {
  return React.createElement(Routes, null, React.createElement(Route, {
    path: userOrderDetailsUrl,
    element: React.createElement(OrderDetails, null)
  }), React.createElement(Route, {
    path: "*",
    element: React.createElement(Account, null)
  }));
};

export default RoutesUserAccount;