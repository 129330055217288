import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "./scss/index.scss";
import classNames from "classnames";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import Media from "react-responsive";
import { Link } from "react-router-dom";
import { useCart } from "@saleor/sdk";
import { xMediumScreen } from "@styles/constants";
import { useAppDispatch, updateCategorySearchValue, useAppSelector } from "@temp/redux";
import { Loader, OrderButton } from "../../components";
import { generateCategoryUrl } from "../../core/utils";
import noPhotoImg from "../../images/no-photo.svg";

var Page = function Page(_ref) {
  var loading = _ref.loading,
      categoriesList = _ref.categoriesList;

  var _useCart = useCart(),
      items = _useCart.items;

  var categoriesExist = function categoriesExist() {
    return categoriesList && categoriesList.length > 0;
  };

  var _React$useState = React.useState(0),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      scrollPosition = _React$useState2[0],
      setScrollPosition = _React$useState2[1];

  var dispatch = useAppDispatch();

  var _useAppSelector = useAppSelector(function (store) {
    return store;
  }),
      hasAttentionAtCurrentTime = _useAppSelector.tradeAttention.hasAttentionAtCurrentTime;

  var handleClickTo = React.useCallback(function (category) {
    localStorage.setItem("categories-from-click", "true");
    localStorage.setItem("scroll-categories", window.scrollY.toString());
    dispatch(updateCategorySearchValue({
      searchCategory: {
        name: category.name,
        value: category.id,
        subcategories: []
      }
    }));
  }, []);
  React.useEffect(function () {
    var handleScroll = function handleScroll() {
      var position = window.pageYOffset;

      if (position > 0 && scrollPosition === 0 || position === 0 && scrollPosition > 0) {
        setScrollPosition(position);
      } else if (position <= 0) {
        setScrollPosition(0);
      }
    };

    window.addEventListener("scroll", handleScroll, {
      passive: true
    });
    return function () {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return React.createElement(React.Fragment, null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement("div", {
    className: "headerContainerCategoriesContainer",
    style: scrollPosition === 0 && !hasAttentionAtCurrentTime ? {
      marginTop: "26px",
      boxShadow: "none"
    } : scrollPosition > 0 && !hasAttentionAtCurrentTime ? {
      boxShadow: "10px 7px 8px rgba(0, 0, 0, 0.2)",
      marginTop: "26px"
    } : scrollPosition > 0 && hasAttentionAtCurrentTime ? {
      boxShadow: "10px 7px 8px rgba(0, 0, 0, 0.2)"
    } : {}
  }, React.createElement("div", {
    className: "headerContainerCategories"
  }, React.createElement("div", null, " "), React.createElement("div", {
    className: "headerContainerCategories__buttonOrder"
  }, React.createElement(OrderButton, {
    amount: items !== undefined ? items.length : 0
  }))), React.createElement("div", {
    className: "headerContainerCategoriesContainer__categoriesTitle"
  }, React.createElement("h1", null, React.createElement(FormattedMessage, {
    id: "views.Categories.Page.3583204912",
    defaultMessage: "Categories"
  }))))), React.createElement(Media, {
    maxWidth: xMediumScreen
  }, React.createElement(OrderButton, {
    amount: items !== undefined ? items.length : 0
  })), loading && !categoriesList ? React.createElement(Loader, null) : categoriesExist() && React.createElement("div", {
    className: "categories-pageCate"
  }, React.createElement("div", {
    className: "categories-pageCate__categories"
  }, React.createElement("div", {
    className: "container"
  }, React.createElement("div", {
    className: "categories-pageCate__categories__list"
  }, categoriesList.map(function (category) {
    return React.createElement("div", {
      key: category.id,
      className: "categories-pageCate__categories__list__container"
    }, React.createElement(Link, {
      onClick: function onClick() {
        handleClickTo(category);
      },
      to: generateCategoryUrl(category.id, category.name),
      key: category.id
    }, React.createElement("div", {
      className: classNames("categories-pageCate__categories__list__image", {
        "categories-pageCate__categories__list__image--no-photo": !category.backgroundImage
      }),
      style: {
        backgroundImage: "url(".concat(category.backgroundImage ? category.backgroundImage.url : noPhotoImg, ")")
      }
    }), React.createElement("p", null, category.name)));
  }))))));
};

export default Page;