import React from "react";
import { FormattedMessage } from "react-intl";
import ReactSVG from "react-svg";
import { Money } from "@components/containers";
import * as S from "./styles";
import observationsImg from "../../../../images/checkout/checkoutObservations.svg";
import totalImg from "../../../../images/checkout/checkoutMoney.svg";
/**
 * Order info
 */

var StatusDataFooter = function StatusDataFooter(_ref) {
  var data = _ref.data;
  return React.createElement(S.Wrapper, null, data.checkoutObservation && React.createElement(React.Fragment, null, React.createElement(S.Title, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataFooter.StatusDataFooter.3086091618",
    defaultMessage: "order observations"
  })), React.createElement(S.CheckoutObservation, null, React.createElement(S.CheckoutObservationImage, null, React.createElement(ReactSVG, {
    path: observationsImg
  })), React.createElement(S.CheckoutObservationData, null, data.checkoutObservation))), React.createElement(S.Title, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataFooter.StatusDataFooter.2303681303",
    defaultMessage: "order value"
  })), React.createElement(S.Total, null, React.createElement(S.TotalImage, null, React.createElement(ReactSVG, {
    path: totalImg
  })), React.createElement(S.TotalData, null, React.createElement(S.SubTotalData, null, React.createElement(S.SubTotalParragraphData, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataFooter.StatusDataFooter.3123839650",
    defaultMessage: "subtotal"
  })), React.createElement(S.SubTotalSpanData, null, React.createElement(Money, {
    money: data.subtotal.net
  }))), data.shippingPrice !== undefined && data.shippingPrice !== null && data.shippingPrice !== "null" && React.createElement(S.ShippingTotalData, null, React.createElement(S.ShippingTotalParragraphData, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataFooter.StatusDataFooter.2278129743",
    defaultMessage: "shipping"
  })), React.createElement(S.ShippingTotalSpanData, null, React.createElement(Money, {
    money: data.shippingPrice.net
  }))), data.discount !== undefined && data.discount !== null && data.discount !== "null" && data.discount.amount > 0 && React.createElement(S.ShippingTotalData, null, React.createElement(S.ShippingTotalParragraphData, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataFooter.StatusDataFooter.293722515",
    defaultMessage: "discount"
  })), React.createElement(S.ShippingTotalSpanData, null, " -", React.createElement(Money, {
    money: data.discount
  }))), React.createElement(S.TotalTotalData, null, React.createElement(S.TotalTotalParragraphData, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataFooter.StatusDataFooter.1214258741",
    defaultMessage: "total"
  })), React.createElement(S.TotalTotalSpanData, null, React.createElement(Money, {
    money: data.total.net
  }))))));
};

export { StatusDataFooter };