import React from "react";
import { FormattedMessage } from "react-intl";
import ReactSVG from "react-svg";
import Media from "react-responsive";
import { xMediumScreen } from "@styles/constants";
import { StatusData, StatusDataFooter } from "@components/molecules";
import { statusMessages } from "@temp/intl";
import * as S from "./styles";

var loadingRestaurant = require("../../../../images/cooking.gif");

var loadingShop = require("../../../../images/coloredBasket.gif");

var goBackImg = require("../../../../images/arrowBack.svg");

/**
 * fullfilled status structure
 */
var StatusPaymentTimeout = function StatusPaymentTimeout(_ref) {
  var data = _ref.data,
      goBack = _ref.goBack,
      navigation = _ref.navigation;

  var restaurant = function restaurant(trade) {
    var indexFinalData = trade.tradeTypes.findIndex(function (data) {
      return data.uniqueType.includes(5);
    });

    if (indexFinalData !== -1) {
      return true;
    }

    return false;
  };

  var restaurantValue = restaurant(data.trade);
  return React.createElement(S.Wrapper, null, React.createElement(S.Tile, null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(S.Title, null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(S.BackTitle, {
    onClick: goBack
  }, React.createElement(ReactSVG, {
    path: goBackImg
  }), React.createElement(S.BackTitleParragraph, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.StatusPaymentTimeout.StatusPaymentTimeout.3471378517",
    defaultMessage: "back"
  })))), React.createElement(FormattedMessage, statusMessages.stepNamePaymentTimeout)))), React.createElement(S.Content, null, React.createElement(Media, {
    maxWidth: xMediumScreen
  }, React.createElement(S.Navigation, null, navigation)), React.createElement(Media, {
    maxWidth: xMediumScreen
  }, React.createElement(S.Title, null, React.createElement(FormattedMessage, statusMessages.stepNamePaymentTimeout))), React.createElement(S.ContentContainer, null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(S.Navigation, null, navigation)), restaurantValue ? React.createElement(S.Images, null, React.createElement(S.Gif, null, React.createElement("img", {
    alt: "cargando",
    src: loadingRestaurant
  }))) : React.createElement(S.GifShop, null, React.createElement("img", {
    alt: "cargando",
    src: loadingShop
  })), React.createElement(S.Parragraph, {
    "data-test": "checkoutPageSubtitle"
  }, React.createElement(S.ParragraphButton, null, React.createElement(FormattedMessage, statusMessages.stepNamePaymentTimeoutWaiting))))), React.createElement(S.Aside, null, React.createElement(StatusData, {
    deliveryPhone: false,
    data: data,
    deliveryData: false
  })), React.createElement(S.Footer, null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(StatusDataFooter, {
    data: data
  }))));
};

export { StatusPaymentTimeout };