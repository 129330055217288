import { Skeleton } from "@components/atoms/Skeleton/Skeleton";
import { Category } from "@temp/components";
import * as React from "react";
import { useParams } from "react-router-dom";
export var CategoryContent = function CategoryContent(_ref) {
  var data = _ref.data,
      loading = _ref.loading,
      categoryIdRemove = _ref.categoryIdRemove,
      maxProductsPerCategory = _ref.maxProductsPerCategory;

  var _useParams = useParams(),
      id = _useParams.id,
      redirect = _useParams.redirect;

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  React.useEffect(function () {
    var categoryIdLocalStorage = JSON.parse(localStorage.getItem("category_products_cache")) || [];

    if (categoryIdLocalStorage.length === 0) {
      localStorage.setItem("category_products_cache", JSON.stringify([id]));
    } else if (categoryIdLocalStorage.length > 0 && !categoryIdLocalStorage.includes(id)) {
      categoryIdLocalStorage.push(id);
      localStorage.setItem("category_products_cache", JSON.stringify(categoryIdLocalStorage));
    }

    return function () {};
  }, []);
  React.useEffect(function () {
    if (loading) {
      window.scrollTo(0, 0);
    }
  }, [loading]);

  if (loading) {
    return React.createElement("div", {
      className: "categories-page"
    }, React.createElement("div", {
      className: "categories-pageCate__loading"
    }, React.createElement(Skeleton, null)));
  }

  return React.createElement(Category, {
    loading: loading,
    redirect: redirect && true,
    data: data,
    maxProductsPerCategory: maxProductsPerCategory
  });
};
export default CategoryContent;