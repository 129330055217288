import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { STATUS_STEPS } from "@temp/core/config";

var StatusRouter = function StatusRouter(_ref) {
  var RenderPending = _ref.RenderPending,
      RenderFullfilled = _ref.RenderFullfilled,
      RenderSent = _ref.RenderSent,
      RenderArrived = _ref.RenderArrived;
  var getStepLink = STATUS_STEPS[0].link;
  return React.createElement(Routes, null, React.createElement(Route, {
    path: STATUS_STEPS[0].link,
    element: React.createElement(RenderPending, null)
  }), React.createElement(Route, {
    path: STATUS_STEPS[1].link,
    element: React.createElement(RenderFullfilled, null)
  }), React.createElement(Route, {
    path: STATUS_STEPS[2].link,
    element: React.createElement(RenderSent, null)
  }), React.createElement(Route, {
    path: STATUS_STEPS[3].link,
    element: React.createElement(RenderArrived, null)
  }), React.createElement(Route, {
    path: "*",
    element: React.createElement(Navigate, {
      to: getStepLink
    })
  }));
};

export { StatusRouter };