import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCategoryData } from "@temp/hooks";
import { useCart } from "@saleor/sdk";
import { generateCategoryProductUrl } from "@temp/core/utils";
import { PageCategory } from "@temp/views/Category";
import { useAppDispatch, updateSugcategorySearchValue } from "@temp/redux";
import { MetaWrapper } from "..";
export var Category = function Category(_ref) {
  var _ProductsSubCategory$7, _ProductsSubCategory$8, _ProductsSubCategory$9, _ProductsSubCategory$10, _ProductsSubCategory$11;

  var data = _ref.data,
      loading = _ref.loading,
      redirect = _ref.redirect,
      maxProductsPerCategory = _ref.maxProductsPerCategory;
  var location = useLocation();

  var _useCart = useCart(),
      items = _useCart.items;

  var navigate = useNavigate();
  var dispatch = useAppDispatch();
  React.useEffect(function () {
    if (localStorage.getItem("category_products_id")) {
      localStorage.removeItem("category_products_id");
    }

    localStorage.removeItem("subcategory_url");
    localStorage.removeItem("loadingLoadMoreSubCategory");
    localStorage.removeItem("loadingLoadMoreSaleCategory");
    localStorage.setItem("product_url", location.pathname);
    localStorage.setItem("category_url", location.pathname);
    return function () {};
  }, []);

  var _useCategoryData = useCategoryData(data, items),
      sendProducts = _useCategoryData.sendProducts,
      subCategoriesAmount = _useCategoryData.subCategoriesAmount,
      ProductsSubCategory = _useCategoryData.ProductsSubCategory;

  var updateSearchValue = React.useCallback(function (subCategory) {
    dispatch(updateSugcategorySearchValue({
      searchSubcategory: {
        name: subCategory === null || subCategory === void 0 ? void 0 : subCategory.name,
        value: subCategory === null || subCategory === void 0 ? void 0 : subCategory.id
      }
    }));
  }, []);
  React.useEffect(function () {
    if (subCategoriesAmount === 1 && localStorage.getItem("categories-from-click")) {
      var _ProductsSubCategory$, _ProductsSubCategory$2, _ProductsSubCategory$3, _ProductsSubCategory$4;

      var subCategoryUrl = generateCategoryProductUrl((_ProductsSubCategory$ = ProductsSubCategory[0]) === null || _ProductsSubCategory$ === void 0 ? void 0 : (_ProductsSubCategory$2 = _ProductsSubCategory$.subCategory) === null || _ProductsSubCategory$2 === void 0 ? void 0 : _ProductsSubCategory$2.id, (_ProductsSubCategory$3 = ProductsSubCategory[0]) === null || _ProductsSubCategory$3 === void 0 ? void 0 : (_ProductsSubCategory$4 = _ProductsSubCategory$3.subCategory) === null || _ProductsSubCategory$4 === void 0 ? void 0 : _ProductsSubCategory$4.name);

      if (subCategoryUrl && redirect) {
        var _ProductsSubCategory$5;

        localStorage.removeItem("categories-from-click");
        updateSearchValue((_ProductsSubCategory$5 = ProductsSubCategory[0]) === null || _ProductsSubCategory$5 === void 0 ? void 0 : _ProductsSubCategory$5.subCategory);
        navigate("".concat(subCategoryUrl, "true/true/"));
      } else if (subCategoryUrl && !redirect) {
        var _ProductsSubCategory$6;

        localStorage.removeItem("categories-from-click");
        updateSearchValue((_ProductsSubCategory$6 = ProductsSubCategory[0]) === null || _ProductsSubCategory$6 === void 0 ? void 0 : _ProductsSubCategory$6.subCategory);
        localStorage.removeItem("oneSubcategory");
        navigate("".concat(subCategoryUrl, "true"));
      }
    } else {
      localStorage.removeItem("oneSubcategory");
    }

    if (subCategoriesAmount > 1) {
      localStorage.removeItem("categories-from-click");
    }
  }, [subCategoriesAmount]);
  return React.createElement("div", {
    className: "categories-page"
  }, React.createElement(MetaWrapper, {
    meta: {
      description: (_ProductsSubCategory$7 = ProductsSubCategory[0]) === null || _ProductsSubCategory$7 === void 0 ? void 0 : (_ProductsSubCategory$8 = _ProductsSubCategory$7.category) === null || _ProductsSubCategory$8 === void 0 ? void 0 : _ProductsSubCategory$8.seoDescription,
      title: (_ProductsSubCategory$9 = ProductsSubCategory[0]) === null || _ProductsSubCategory$9 === void 0 ? void 0 : (_ProductsSubCategory$10 = _ProductsSubCategory$9.category) === null || _ProductsSubCategory$10 === void 0 ? void 0 : _ProductsSubCategory$10.seoTitle,
      type: "product.category"
    }
  }, React.createElement(PageCategory, {
    redirect: redirect,
    category: (_ProductsSubCategory$11 = ProductsSubCategory[0]) === null || _ProductsSubCategory$11 === void 0 ? void 0 : _ProductsSubCategory$11.category,
    products: sendProducts,
    maxProductsPerCategory: maxProductsPerCategory
  })));
};
export default Category;