import "./scss/index.scss";
import React from "react";
import ReactSVG from "react-svg";
import { FormattedMessage } from "react-intl";
import { Button } from "@components/atoms";
import { PinchZoomImage } from "..";

var closeWhite = require("../../images/closewhite.svg");

export var AlertShowImage = function AlertShowImage(_ref) {
  var title = _ref.title,
      image = _ref.image,
      hideModal = _ref.hideModal;
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "showImage",
    onClick: hideModal
  }), React.createElement("div", {
    className: "showImage__data"
  }, React.createElement("div", {
    className: "showImage__data__close"
  }, React.createElement("p", null, title), React.createElement(ReactSVG, {
    path: closeWhite,
    onClick: hideModal
  })), React.createElement("div", {
    className: "showImage__data__content"
  }, React.createElement(PinchZoomImage, {
    src: image,
    alt: ""
  })), React.createElement("div", {
    className: "showImage__data__footer"
  }, React.createElement(Button, {
    className: "showImage__data__footer__button",
    onClick: hideModal,
    testingContext: "closeButton"
  }, React.createElement(FormattedMessage, {
    id: "components.AlertShowImage.index.281464262",
    defaultMessage: "close"
  })))));
};
export default AlertShowImage;