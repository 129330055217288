import React from "react";
import { Provider as ReduxProvider } from "react-redux";
import { CurrentTradeDetailsProvider } from "@temp/contexts";
import { store } from "@temp/redux";
import { MetaConsumer, OverlayManager, OverlayProvider } from "../components";
import ShopProvider from "../components/ShopProvider";
import "../globalStyles/scss/index.scss";
import { RoutesCustomer } from "./routes";
import Notifications from "./Notifications";

var AppComponent = function AppComponent() {
  return React.createElement(ReduxProvider, {
    store: store
  }, React.createElement(CurrentTradeDetailsProvider, null, React.createElement(ShopProvider, null, React.createElement(OverlayProvider, null, React.createElement(MetaConsumer, null), React.createElement(RoutesCustomer, null), React.createElement(OverlayManager, null), React.createElement(Notifications, null)))));
};

export default AppComponent;