import "./scss/index.scss";
import React from "react";
import ReactSVG from "react-svg";
import { FormattedMessage } from "react-intl";
import { Button } from "@components/atoms";
import { useNavigate } from "react-router-dom";
import * as paths from "../../app/routes/paths";

var closeWhite = require("../../images/closewhite.svg"); // const greaterThanEqual = require("../../images/greaterThanEqual.svg");


export var AlertCurrentNeighborhood = function AlertCurrentNeighborhood(_ref) {
  var hideModal = _ref.hideModal,
      neighborhood = _ref.neighborhood;
  var navigate = useNavigate();
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "alertCurrentNeighborhood",
    onClick: hideModal
  }), React.createElement("div", {
    className: "alertCurrentNeighborhood__data"
  }, React.createElement("div", {
    className: "alertCurrentNeighborhood__data__close"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.AlertCurrentNeighborhood.index.3019355745",
    defaultMessage: "Current neighborhood"
  })), React.createElement(ReactSVG, {
    path: closeWhite,
    onClick: hideModal
  })), React.createElement("div", {
    className: "alertCurrentNeighborhood__data__content alertCurrentNeighborhood__data__content--first"
  }), React.createElement("div", {
    className: "alertCurrentNeighborhood__data__content alertCurrentNeighborhood__data__content--value alertCurrentNeighborhood__data__content--first alertCurrentNeighborhood__data__content--last"
  }, neighborhood), React.createElement("div", {
    className: "alertCurrentNeighborhood__data__footer"
  }, React.createElement(Button, {
    type: "button",
    testingContext: "",
    onClick: hideModal
  }, React.createElement(FormattedMessage, {
    id: "components.AlertCurrentNeighborhood.index.1347475195",
    defaultMessage: "Back"
  })), React.createElement(Button, {
    type: "button",
    testingContext: "",
    onClick: function onClick() {
      localStorage.setItem("fromProductsToNeighborhoods", "true");
      navigate(paths.neighborhoodsUrl);
    }
  }, React.createElement(FormattedMessage, {
    id: "components.AlertCurrentNeighborhood.index.2781870132",
    defaultMessage: "Change neighborhood"
  })))));
};
export default AlertCurrentNeighborhood;