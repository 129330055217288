import React from "react";
import { Money } from "@components/containers";
import * as S from "./styles";
export var ToppingValueMultiSelect = function ToppingValueMultiSelect(_ref) {
  var descriptionValue = _ref.descriptionValue,
      dispatch = _ref.dispatch,
      disabled = _ref.disabled,
      factor = _ref.factor,
      idTopping = _ref.idTopping,
      idValue = _ref.idValue,
      isActive = _ref.isActive,
      isLastToppingValueAndTopping = _ref.isLastToppingValueAndTopping,
      light = _ref.light,
      nameValue = _ref.nameValue,
      orderValue = _ref.orderValue,
      price = _ref.price,
      quantity = _ref.quantity,
      ref = _ref.ref,
      valueValue = _ref.valueValue;
  var handleOnChangeCheckbox = React.useCallback(function (idTopping, checkched, idValue, isActive, factor, nameValue, orderValue, price, ref, valueValue, descriptionValue) {
    dispatch({
      type: "update-specific-topping",
      payload: {
        idTopping: idTopping,
        dataValue: {
          idValue: idValue,
          isActive: isActive,
          factor: factor,
          nameValue: nameValue,
          orderValue: orderValue,
          price: price,
          ref: ref,
          valueValue: valueValue,
          descriptionValue: descriptionValue,
          quantity: checkched ? 1 : 0
        }
      }
    });
  }, [dispatch]);
  return React.createElement(React.Fragment, null, isLastToppingValueAndTopping && React.createElement("p", {
    id: "final"
  }), React.createElement(S.Value, {
    light: light
  }, React.createElement(S.ValueNamePrice, null, React.createElement(S.ValueParragraph, {
    htmlFor: "".concat(idValue)
  }, nameValue || valueValue), React.createElement(S.ValueDescription, null, descriptionValue), React.createElement(S.ValuePrice, null, price && price.amount !== 0 && " +", price && price.amount !== 0 && React.createElement(Money, {
    money: price
  }))), React.createElement(S.ValueInput, {
    id: "".concat(idValue),
    type: "checkbox",
    checked: quantity === 1,
    disabled: quantity === 1 ? false : disabled,
    onChange: function onChange(e) {
      var newRef = ref || null;
      handleOnChangeCheckbox(idTopping, e.target.checked, idValue, isActive, factor, nameValue, orderValue, price, newRef, valueValue, descriptionValue);
    }
  })));
};
ToppingValueMultiSelect.displayName = "ToppingValueMultiSelect";