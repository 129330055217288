import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import * as React from "react";
import { Skeleton } from "@components/atoms/Skeleton/Skeleton";
import { useSaleCategory } from "@temp/hooks";
import { PageSaleCategory } from "@temp/views/SaleCategory";
import { useLocation } from "react-router-dom";
import { useCart } from "@saleor/sdk";
import { useAppSelector } from "@temp/redux";
import { getGraphqlIdFromDBId } from "../../core/utils";
import { MetaWrapper } from "..";
export var SaleCategory = function SaleCategory(_ref) {
  var _ProductsSaleSubCateg, _ProductsSaleSubCateg2, _data$pageInfo2;

  var data = _ref.data,
      loadMore = _ref.loadMore,
      loading = _ref.loading,
      redirect = _ref.redirect,
      id = _ref.id,
      PRODUCTS_PER_PAGE = _ref.PRODUCTS_PER_PAGE;
  var location = useLocation();

  var _useCart = useCart(),
      items = _useCart.items;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      loadingMask = _React$useState2[0],
      setLoadingMask = _React$useState2[1];

  var _useAppSelector = useAppSelector(function (store) {
    return store.search;
  }),
      searchQueryValue = _useAppSelector.searchQueryValue;

  React.useEffect(function () {
    setLoadingMask(true);
  }, [id]);
  React.useEffect(function () {
    if (!localStorage.getItem("loadingLoadMoreSaleCategory")) {
      if (!loading) {
        setLoadingMask(false);
      } else {
        setLoadingMask(true);
      }
    }

    if (localStorage.getItem("loadingLoadMoreSaleCategory") && !loading) {
      localStorage.removeItem("loadingLoadMoreSaleCategory");
    }
  }, [loading]);
  React.useEffect(function () {
    if (loadingMask) {
      window.scrollTo(0, 0);
    }
  }, [loadingMask]);
  React.useLayoutEffect(function () {
    localStorage.setItem("product_url", location.pathname);
    localStorage.setItem("salesubcategory_url", location.pathname);
    localStorage.removeItem("category_sale_id");
    localStorage.removeItem("loadingLoadMoreSubCategory");
    var otherProduct = localStorage.getItem("other-product");
    var scrollProduct = localStorage.getItem("scroll-product");

    if (scrollProduct !== null && otherProduct !== "true") {
      window.scrollTo(0, +scrollProduct);
    } else {
      window.scrollTo(0, 0);
    }

    if (otherProduct === "true") {
      localStorage.removeItem("other-product");
    }

    return function () {};
  }, []);

  if (loadingMask) {
    return React.createElement("div", {
      className: "categories-page"
    }, React.createElement("div", {
      className: "categories-pageCate__loading"
    }, React.createElement(Skeleton, null)));
  }

  var categoryId = getGraphqlIdFromDBId(id, "Category");

  var _useSaleCategory = useSaleCategory(data, items, categoryId),
      ProductsSaleSubCategory = _useSaleCategory.ProductsSaleSubCategory;

  return React.createElement("div", {
    className: "categories-page"
  }, React.createElement(MetaWrapper, {
    meta: {
      type: "product.category"
    }
  }, React.createElement(PageSaleCategory, {
    category: (_ProductsSaleSubCateg = ProductsSaleSubCategory[0]) === null || _ProductsSaleSubCateg === void 0 ? void 0 : _ProductsSaleSubCateg.category,
    products: (_ProductsSaleSubCateg2 = ProductsSaleSubCategory[0]) === null || _ProductsSaleSubCateg2 === void 0 ? void 0 : _ProductsSaleSubCateg2.products,
    onLoadMore: function onLoadMore() {
      var _data$pageInfo;

      localStorage.setItem("loadingLoadMoreSaleCategory", "true");
      loadMore(function (prev, next) {
        return {
          saleCategoryProductsLoadMore: _objectSpread(_objectSpread({}, prev.saleCategoryProductsLoadMore), {}, {
            edges: [].concat(_toConsumableArray(prev.saleCategoryProductsLoadMore.edges), _toConsumableArray(next.saleCategoryProductsLoadMore.edges)),
            pageInfo: next.saleCategoryProductsLoadMore.pageInfo
          })
        };
      }, {
        after: data === null || data === void 0 ? void 0 : (_data$pageInfo = data.pageInfo) === null || _data$pageInfo === void 0 ? void 0 : _data$pageInfo.endCursor,
        categoryId: +id,
        perPage: PRODUCTS_PER_PAGE,
        search: searchQueryValue
      });
    },
    displayLoader: loading,
    hasNextPage: data === null || data === void 0 ? void 0 : (_data$pageInfo2 = data.pageInfo) === null || _data$pageInfo2 === void 0 ? void 0 : _data$pageInfo2.hasNextPage,
    redirect: redirect
  })));
};
export default SaleCategory;