import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

/* eslint-disable jsx-a11y/label-has-associated-control */
import "./scss/index.scss";
import classNames from "classnames";
import { filter, find } from "lodash";
import * as React from "react";
import { useClickedOutside } from "../../hooks";
import SelectOptionsList from "./SelectOptionsList";

var updateOptions = function updateOptions(_ref, onChange) {
  var label = _ref.label,
      value = _ref.value;
  return onChange({
    country: label,
    code: value
  });
};

var filterList = function filterList(_ref2) {
  var searchPhrase = _ref2.searchPhrase,
      options = _ref2.options;
  return filter(options, function (_ref3) {
    var label = _ref3.label;
    return label.toLowerCase().includes(searchPhrase.toLowerCase());
  });
};

var isAutofilled = function isAutofilled(inputValue, newInputValue) {
  return newInputValue.length > 1 && newInputValue.substring(0, newInputValue.length - 1) !== inputValue;
};

var findAutofilledOption = function findAutofilledOption(options, inputValue) {
  return find(options, function (_ref4) {
    var label = _ref4.label;
    return label.toLowerCase() === inputValue.toLowerCase();
  });
};

export var Select = function Select(props) {
  var autoComplete = props.autoComplete,
      _props$defaultValue = props.defaultValue,
      defaultValue = _props$defaultValue === void 0 ? {
    label: "",
    value: ""
  } : _props$defaultValue,
      label = props.label,
      _onChange = props.onChange,
      options = props.options,
      name = props.name;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      open = _React$useState2[0],
      setOpen = _React$useState2[1];

  var _React$useState3 = React.useState(defaultValue.label),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      searchPhrase = _React$useState4[0],
      setSearchPhrase = _React$useState4[1];

  var _useClickedOutside = useClickedOutside(),
      clickedOutside = _useClickedOutside.clickedOutside,
      setElementRef = _useClickedOutside.setElementRef;

  var inputRef = React.useRef(null);
  var activeOptionRef = React.useRef(null);

  var resetInputValueToDefault = function resetInputValueToDefault() {
    return setSearchPhrase(defaultValue.label);
  };

  React.useEffect(function () {
    resetInputValueToDefault();
    return function () {};
  }, [clickedOutside, defaultValue]);
  React.useEffect(function () {
    if (activeOptionRef.current && open) {
      activeOptionRef.current.scrollIntoView();
      activeOptionRef.current.focus();
    }

    return function () {};
  }, [open]);
  var shouldOpen = clickedOutside ? false : open;
  var shouldSearch = defaultValue.label !== searchPhrase;

  var renderLabel = function renderLabel(label) {
    return label && React.createElement("label", {
      className: "input__label"
    }, label);
  };

  var changeSelectionRange = function changeSelectionRange(e) {
    return inputRef.current.setSelectionRange(0, e.target.value.length);
  };

  return React.createElement("div", {
    ref: setElementRef(),
    className: classNames("react-select select", {
      "select--open": shouldOpen
    })
  }, React.createElement("input", {
    className: "select__hidden",
    autoComplete: autoComplete,
    name: name,
    defaultValue: defaultValue.value
  }), React.createElement("div", {
    className: "select__container"
  }, React.createElement("div", {
    className: "select__title"
  }, React.createElement("input", {
    ref: inputRef,
    className: "input__field",
    value: searchPhrase,
    onChange: function onChange(e) {
      var value = e.target.value;
      setSearchPhrase(value);

      if (isAutofilled(searchPhrase, value)) {
        var country = findAutofilledOption(options, value);
        return country && updateOptions(country, _onChange);
      }
    },
    onClick: function onClick(e) {
      changeSelectionRange(e);

      if (open) {
        resetInputValueToDefault();
      }

      setOpen(!open);
    }
  }), renderLabel(label)), React.createElement("div", {
    className: classNames("select__options", {
      "select__options--open": shouldOpen
    })
  }, React.createElement(SelectOptionsList, {
    ref: activeOptionRef,
    activeOption: defaultValue,
    options: shouldSearch ? filterList({
      searchPhrase: searchPhrase,
      options: options
    }) : options,
    onChange: _onChange,
    setOpen: setOpen,
    updateOptions: updateOptions
  }))));
};
export default Select;