import { useEffect } from "react";
import socketIOClient from "socket.io-client";
import { useAuth } from "@saleor/sdk";
import { addListFinished, addListInProgress, deleteListFinished, deleteListInProgress, hasntChangedOrderAmount, updateHasChangedOrderAmountTrue, updateOrderDataFinished, useAppDispatch } from "@temp/redux";
import * as pathsDelivery from "@temp/app/routes/pathsDelivery";
import { useNavigate } from "react-router-dom";
import { websocketHost } from "../../constants";
export var useRealtimeDelivery = function useRealtimeDelivery() {
  var _useAuth = useAuth(),
      user = _useAuth.user;

  var dispatch = useAppDispatch();
  var navigate = useNavigate();

  if (user && (user === null || user === void 0 ? void 0 : user.role) === "DELIVERY_MAN") {
    useEffect(function () {
      var socket = socketIOClient(websocketHost || "", {
        secure: false,
        query: {
          token: localStorage.getItem("token")
        }
      });
      socket.on("connect", function () {
        socket.emit("create", user === null || user === void 0 ? void 0 : user.id);
      });
      socket.on("message", function (data) {
        var _jsonData$order, _jsonData$order2, _jsonData$order3, _jsonData$order4, _jsonData$order5, _jsonData$order6, _jsonData$order7, _jsonData$order8, _jsonData$order9, _jsonData$order10, _jsonData$order10$use, _jsonData$order11, _jsonData$order11$shi, _jsonData$order11$shi2, _jsonData$order12, _jsonData$order12$shi, _jsonData$order13, _jsonData$order14, _jsonData$order15, _jsonData$order16, _jsonData$order17, _jsonData$order18, _jsonData$order19, _jsonData$order20, _jsonData$order21, _jsonData$order22, _jsonData$order23, _jsonData$order24;

        var jsonData = JSON.parse(data);
        var orderData = {
          node: {
            id: jsonData === null || jsonData === void 0 ? void 0 : (_jsonData$order = jsonData.order) === null || _jsonData$order === void 0 ? void 0 : _jsonData$order.orderEncodeId,
            created: jsonData === null || jsonData === void 0 ? void 0 : (_jsonData$order2 = jsonData.order) === null || _jsonData$order2 === void 0 ? void 0 : _jsonData$order2.created,
            lines: new Array(jsonData === null || jsonData === void 0 ? void 0 : (_jsonData$order3 = jsonData.order) === null || _jsonData$order3 === void 0 ? void 0 : _jsonData$order3.amountProducts),
            number: jsonData === null || jsonData === void 0 ? void 0 : (_jsonData$order4 = jsonData.order) === null || _jsonData$order4 === void 0 ? void 0 : _jsonData$order4.id,
            status: jsonData === null || jsonData === void 0 ? void 0 : (_jsonData$order5 = jsonData.order) === null || _jsonData$order5 === void 0 ? void 0 : _jsonData$order5.status,
            token: jsonData === null || jsonData === void 0 ? void 0 : (_jsonData$order6 = jsonData.order) === null || _jsonData$order6 === void 0 ? void 0 : _jsonData$order6.token,
            trade: {
              id: jsonData === null || jsonData === void 0 ? void 0 : (_jsonData$order7 = jsonData.order) === null || _jsonData$order7 === void 0 ? void 0 : _jsonData$order7.tradeId,
              name: jsonData === null || jsonData === void 0 ? void 0 : (_jsonData$order8 = jsonData.order) === null || _jsonData$order8 === void 0 ? void 0 : _jsonData$order8.tradeName,
              logoImage: jsonData === null || jsonData === void 0 ? void 0 : (_jsonData$order9 = jsonData.order) === null || _jsonData$order9 === void 0 ? void 0 : _jsonData$order9.tradeImage
            },
            user: {
              firstName: jsonData === null || jsonData === void 0 ? void 0 : (_jsonData$order10 = jsonData.order) === null || _jsonData$order10 === void 0 ? void 0 : (_jsonData$order10$use = _jsonData$order10.user) === null || _jsonData$order10$use === void 0 ? void 0 : _jsonData$order10$use.firstName
            },
            shippingAddress: {
              neighborhood: {
                name: jsonData === null || jsonData === void 0 ? void 0 : (_jsonData$order11 = jsonData.order) === null || _jsonData$order11 === void 0 ? void 0 : (_jsonData$order11$shi = _jsonData$order11.shippingAddress) === null || _jsonData$order11$shi === void 0 ? void 0 : (_jsonData$order11$shi2 = _jsonData$order11$shi.neighborhood) === null || _jsonData$order11$shi2 === void 0 ? void 0 : _jsonData$order11$shi2.name
              },
              streetAddress1: jsonData === null || jsonData === void 0 ? void 0 : (_jsonData$order12 = jsonData.order) === null || _jsonData$order12 === void 0 ? void 0 : (_jsonData$order12$shi = _jsonData$order12.shippingAddress) === null || _jsonData$order12$shi === void 0 ? void 0 : _jsonData$order12$shi.streetAddress1
            }
          }
        }; // INSIDE LISTS

        if (((jsonData === null || jsonData === void 0 ? void 0 : (_jsonData$order13 = jsonData.order) === null || _jsonData$order13 === void 0 ? void 0 : _jsonData$order13.status) === "SENT" || (jsonData === null || jsonData === void 0 ? void 0 : (_jsonData$order14 = jsonData.order) === null || _jsonData$order14 === void 0 ? void 0 : _jsonData$order14.status) === "ARRIVED") && (location.pathname === pathsDelivery.baseDeliveryUrl || "".concat(location.pathname, "/") === pathsDelivery.baseDeliveryUrl || location.pathname === pathsDelivery.orderFinishedDeliveryUrl || "".concat(location.pathname, "/") === pathsDelivery.orderFinishedDeliveryUrl)) {
          var _orderData$node, _orderData$node2;

          dispatch(deleteListInProgress({
            id: orderData === null || orderData === void 0 ? void 0 : (_orderData$node = orderData.node) === null || _orderData$node === void 0 ? void 0 : _orderData$node.id
          }));
          dispatch(addListInProgress({
            data: orderData
          }));
          dispatch(updateHasChangedOrderAmountTrue({
            id: orderData === null || orderData === void 0 ? void 0 : orderData.node.id
          }));
          dispatch(deleteListFinished({
            id: orderData === null || orderData === void 0 ? void 0 : (_orderData$node2 = orderData.node) === null || _orderData$node2 === void 0 ? void 0 : _orderData$node2.id
          }));
        } else if (((jsonData === null || jsonData === void 0 ? void 0 : (_jsonData$order15 = jsonData.order) === null || _jsonData$order15 === void 0 ? void 0 : _jsonData$order15.status) === "DELIVERED" || (jsonData === null || jsonData === void 0 ? void 0 : (_jsonData$order16 = jsonData.order) === null || _jsonData$order16 === void 0 ? void 0 : _jsonData$order16.status) === "UNDELIVERED" || (jsonData === null || jsonData === void 0 ? void 0 : (_jsonData$order17 = jsonData.order) === null || _jsonData$order17 === void 0 ? void 0 : _jsonData$order17.status) === "CANCELED_FULFILLMENT") && (location.pathname === pathsDelivery.orderFinishedDeliveryUrl || "".concat(location.pathname, "/") === pathsDelivery.orderFinishedDeliveryUrl || location.pathname === pathsDelivery.baseDeliveryUrl || "".concat(location.pathname, "/") === pathsDelivery.baseDeliveryUrl)) {
          dispatch(deleteListFinished({
            id: orderData === null || orderData === void 0 ? void 0 : orderData.node.id
          }));
          dispatch(addListFinished({
            data: orderData
          }));
          dispatch(updateHasChangedOrderAmountTrue({
            id: orderData === null || orderData === void 0 ? void 0 : orderData.node.id
          }));
          dispatch(deleteListInProgress({
            id: orderData === null || orderData === void 0 ? void 0 : orderData.node.id
          }));
        } else if ((jsonData === null || jsonData === void 0 ? void 0 : (_jsonData$order18 = jsonData.order) === null || _jsonData$order18 === void 0 ? void 0 : _jsonData$order18.status) === "CANCELED" && (location.pathname === pathsDelivery.orderFinishedDeliveryUrl || "".concat(location.pathname, "/") === pathsDelivery.orderFinishedDeliveryUrl || location.pathname === pathsDelivery.baseDeliveryUrl || "".concat(location.pathname, "/") === pathsDelivery.baseDeliveryUrl)) {
          dispatch(updateOrderDataFinished({
            data: orderData
          }));
          dispatch(hasntChangedOrderAmount());
        } // INSIDE ORDER DETAIL


        if (((jsonData === null || jsonData === void 0 ? void 0 : (_jsonData$order19 = jsonData.order) === null || _jsonData$order19 === void 0 ? void 0 : _jsonData$order19.status) === "SENT" || (jsonData === null || jsonData === void 0 ? void 0 : (_jsonData$order20 = jsonData.order) === null || _jsonData$order20 === void 0 ? void 0 : _jsonData$order20.status) === "ARRIVED") && location.pathname.includes(orderData.node.token)) {
          var _orderData$node3;

          dispatch(deleteListInProgress({
            id: orderData === null || orderData === void 0 ? void 0 : (_orderData$node3 = orderData.node) === null || _orderData$node3 === void 0 ? void 0 : _orderData$node3.id
          }));
          dispatch(addListInProgress({
            data: orderData
          }));
        } else if (((jsonData === null || jsonData === void 0 ? void 0 : (_jsonData$order21 = jsonData.order) === null || _jsonData$order21 === void 0 ? void 0 : _jsonData$order21.status) === "CANCELED_FULFILLMENT" || (jsonData === null || jsonData === void 0 ? void 0 : (_jsonData$order22 = jsonData.order) === null || _jsonData$order22 === void 0 ? void 0 : _jsonData$order22.status) === "DELIVERED" || (jsonData === null || jsonData === void 0 ? void 0 : (_jsonData$order23 = jsonData.order) === null || _jsonData$order23 === void 0 ? void 0 : _jsonData$order23.status) === "UNDELIVERED") && location.pathname.includes(orderData.node.token)) {
          dispatch(deleteListInProgress({
            id: orderData === null || orderData === void 0 ? void 0 : orderData.node.id
          }));
          dispatch(deleteListFinished({
            id: orderData === null || orderData === void 0 ? void 0 : orderData.node.id
          }));
          dispatch(addListFinished({
            data: orderData
          }));
          dispatch(updateHasChangedOrderAmountTrue({
            id: orderData === null || orderData === void 0 ? void 0 : orderData.node.id
          }));
        } else if ((jsonData === null || jsonData === void 0 ? void 0 : (_jsonData$order24 = jsonData.order) === null || _jsonData$order24 === void 0 ? void 0 : _jsonData$order24.status) === "CANCELED" && location.pathname.includes(orderData.node.token)) {
          dispatch(updateOrderDataFinished({
            data: orderData
          }));
          dispatch(hasntChangedOrderAmount());
        }

        if (location.pathname.includes(orderData.node.token)) {
          navigate("".concat(location.pathname, "?order-detail-delivery"), {
            replace: true
          });
        }
      });
      socket.on("error", function () {
        if (!socket.connected) {
          socket.io.connect();
        }
      });

      var hasConnection = function hasConnection() {
        if (!socket.connected) {
          socket.io.connect();

          if (location.pathname === pathsDelivery.orderFinishedDeliveryUrl || "".concat(location.pathname, "/") === pathsDelivery.orderFinishedDeliveryUrl) {
            navigate("".concat(location.pathname, "?order-finished"), {
              replace: true
            });
          } else if (location.pathname === pathsDelivery.baseDeliveryUrl || "".concat(location.pathname, "/") === pathsDelivery.baseDeliveryUrl) {
            navigate("".concat(location.pathname, "?order-in-progress"), {
              replace: true
            });
          } else if (location.pathname.includes("order") && location.pathname.includes("delivery")) {
            navigate("".concat(location.pathname, "?order-detail-delivery"), {
              replace: true
            });
          }
        }
      };

      var keepAliveInterval = setInterval(function () {
        hasConnection();
      }, 30000);
      window.addEventListener("focus", hasConnection);
      window.removeEventListener("online", hasConnection);
      return function () {
        clearInterval(keepAliveInterval);
        socket.emit("remove", user === null || user === void 0 ? void 0 : user.id);
        socket.disconnect();
        window.removeEventListener("focus", hasConnection);
        window.removeEventListener("online", hasConnection);
      };
    }, []);
  }
};