import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  right: 8px;\n  ", ";\n  transform: ", ";\n  ransition-property: transform;\n  transition-duration: 0.5s;\n  transition-timing-function: ease-out;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  margin: 0;\n  padding-left: 10px;\n  text-transform: lowercase;\n  color: white;\n  &:first-letter {\n    text-transform: uppercase;\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  padding: 8px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  background: #f76268;\n  border-radius: 30px;\n  color: white;\n  cursor: pointer;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  width: 80px;\n  height: 30px;\n  padding: 3px 0px;\n  font-size: 13px;\n  background: #ffcbcb;\n  border-radius: 20px;\n  text-align: center;\n  color: #f66167;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  align-self: center;\n  font-size: 14px;\n  color: #afafaf;\n  line-height: 1.1rem;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: 94%;\n  margin: 0 auto;\n  margin-bottom: 5px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Specifications = styled.div(_templateObject());
export var SelectMax = styled.p(_templateObject2());
export var Obligatory = styled.p(_templateObject3());
export var ToppingTitleContainer = styled.div(_templateObject4());
export var ToppingTitle = styled.p(_templateObject5());
export var ToppingTitleImage = styled.div(_templateObject6(), function (props) {
  return props.open ? "top: 2px;" : "bottom: 3px;";
}, function (props) {
  return props.open ? "rotate(0deg)" : "rotate(180deg)";
});