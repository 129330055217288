import React from "react";
import ReactSVG from "react-svg";
import { Radio } from "@components/atoms";
import { PROVIDERS } from "@temp/core/config";
import { BraintreePaymentGateway, DummyPaymentGateway, CashPaymentGateway, DataphonePaymentGateway, AdyenPaymentGateway } from "..";
import * as S from "./styles";
import { NequiPaymentGateway } from "../NequiPaymentGateway";

var cashImg = require("../../../../images/cashImg.svg");

var cardImg = require("../../../../images/cardImg.svg");

var nequiImg = require("../../../../images/logoNequi.svg");

var goOnImg = require("../../../../images/goOn.svg");
/**
 * Payment Gateways list
 */


var PaymentGatewaysList = React.memo(function (_ref) {
  var paymentGateways = _ref.paymentGateways,
      selectedPaymentGateway = _ref.selectedPaymentGateway,
      selectedPaymentGatewayToken = _ref.selectedPaymentGatewayToken,
      selectPaymentGateway = _ref.selectPaymentGateway,
      formRef = _ref.formRef,
      formId = _ref.formId,
      changeSubmitProgress = _ref.changeSubmitProgress,
      _processPayment = _ref.processPayment,
      submitPayment = _ref.submitPayment,
      submitPaymentSuccess = _ref.submitPaymentSuccess,
      onError = _ref.onError,
      totalPrice = _ref.totalPrice;
  return React.createElement(S.Wrapper, null, paymentGateways.map(function (_ref2, index) {
    var id = _ref2.id,
        name = _ref2.name,
        config = _ref2.config;
    var checked = selectedPaymentGateway === id;

    switch (name) {
      case PROVIDERS.BRAINTREE.label:
        return React.createElement("div", {
          key: index
        }, React.createElement(S.Tile, {
          checked: checked
        }, React.createElement(Radio, {
          "data-test": "checkoutPaymentGatewayBraintreeInput",
          name: "payment-method",
          value: "credit-card",
          checked: checked,
          onChange: function onChange() {
            return selectPaymentGateway && selectPaymentGateway(id);
          },
          customLabel: true
        }, React.createElement("span", {
          "data-test": "checkoutPaymentGatewayBraintreeName"
        }, name))), checked && React.createElement(BraintreePaymentGateway, {
          config: config,
          formRef: formRef,
          formId: formId,
          processPayment: function processPayment(token, cardData) {
            return _processPayment(id, token, cardData);
          },
          errors: [],
          onError: onError
        }));

      case PROVIDERS.DUMMY.label:
        return React.createElement("div", {
          key: index
        }, React.createElement(S.Tile, {
          checked: checked
        }, React.createElement(Radio, {
          "data-test": "checkoutPaymentGatewayDummyInput",
          name: "payment-method",
          value: "dummy",
          checked: checked,
          onChange: function onChange() {
            return selectPaymentGateway && selectPaymentGateway(id);
          },
          customLabel: true
        }, React.createElement("span", {
          "data-test": "checkoutPaymentGatewayDummyName"
        }, name))), checked && React.createElement(DummyPaymentGateway, {
          formRef: formRef,
          formId: formId,
          processPayment: function processPayment(token) {
            return _processPayment(id, token);
          },
          initialStatus: selectedPaymentGatewayToken
        }));

      case PROVIDERS.ADYEN.label:
        return React.createElement("div", {
          key: index
        }, React.createElement(S.Tile, {
          checked: checked
        }, React.createElement(Radio, {
          "data-test": "checkoutPaymentGatewayAdyenInput",
          name: "payment-method",
          value: "adyen",
          checked: checked,
          onChange: function onChange() {
            return selectPaymentGateway && selectPaymentGateway(id);
          },
          customLabel: true
        }, React.createElement("span", {
          "data-test": "checkoutPaymentGatewayAdyenName"
        }, name))), checked && React.createElement(AdyenPaymentGateway, {
          config: config,
          formRef: formRef,
          scriptConfig: PROVIDERS.ADYEN.script,
          styleConfig: PROVIDERS.ADYEN.style,
          processPayment: function processPayment() {
            return _processPayment(id);
          },
          submitPayment: submitPayment,
          submitPaymentSuccess: submitPaymentSuccess,
          errors: [],
          onError: onError
        }));

      case PROVIDERS.CASH.key:
        return React.createElement("div", {
          key: index
        }, React.createElement(S.Tile, {
          checked: checked
        }, React.createElement(Radio, {
          "data-test": "checkoutPaymentGatewayCashInput",
          name: "payment-method",
          value: "cash",
          checked: checked,
          onChange: function onChange() {
            return selectPaymentGateway && selectPaymentGateway(id);
          },
          customLabel: true
        }, React.createElement(ReactSVG, {
          path: cashImg
        }), React.createElement("span", {
          "data-test": "checkoutPaymentGatewayCashName"
        }, PROVIDERS.CASH.label), React.createElement(ReactSVG, {
          path: goOnImg
        }))), checked && React.createElement(CashPaymentGateway, {
          changeSubmitProgress: changeSubmitProgress,
          formRef: formRef,
          formId: formId,
          processPayment: function processPayment(token) {
            return _processPayment(id, token);
          },
          onSelect: function onSelect() {
            return selectPaymentGateway && selectPaymentGateway(id);
          },
          initialStatus: "",
          config: config,
          totalPrice: totalPrice
        }));

      case PROVIDERS.DATAPHONE.key:
        return React.createElement("div", {
          key: index
        }, React.createElement(S.Tile, {
          checked: checked
        }, React.createElement(Radio, {
          "data-test": "checkoutPaymentGatewayDataphoneInput",
          name: "payment-method",
          value: "Dataphone",
          checked: checked,
          onChange: function onChange() {
            return selectPaymentGateway && selectPaymentGateway(id);
          },
          customLabel: true
        }, React.createElement(ReactSVG, {
          path: cardImg
        }), React.createElement("span", {
          "data-test": "checkoutPaymentGatewayDataphoneName"
        }, PROVIDERS.DATAPHONE.label), React.createElement(ReactSVG, {
          path: goOnImg
        }))), checked && React.createElement(DataphonePaymentGateway, {
          changeSubmitProgress: changeSubmitProgress,
          formRef: formRef,
          formId: formId,
          processPayment: function processPayment(token) {
            return _processPayment(id, token, undefined);
          },
          initialStatus: "",
          config: config,
          totalPrice: totalPrice
        }));

      case PROVIDERS.NEQUI.key:
        return React.createElement("div", {
          key: index
        }, React.createElement(S.Tile, {
          checked: checked
        }, React.createElement(Radio, {
          "data-test": "checkoutPaymentGatewayDataphoneInput",
          name: "payment-method",
          value: "Dataphone",
          checked: checked,
          onChange: function onChange() {
            return selectPaymentGateway && selectPaymentGateway(id);
          },
          customLabel: true
        }, React.createElement(ReactSVG, {
          path: nequiImg
        }), React.createElement("span", {
          "data-test": "checkoutPaymentGatewayDataphoneName"
        }, PROVIDERS.NEQUI.label), React.createElement(ReactSVG, {
          path: goOnImg
        }))), checked && React.createElement(NequiPaymentGateway, {
          changeSubmitProgress: changeSubmitProgress,
          formRef: formRef,
          formId: formId,
          processPayment: function processPayment(token) {
            return _processPayment(id, token, undefined);
          },
          initialStatus: "",
          config: config,
          totalPrice: totalPrice
        }));

      default:
        return React.createElement(React.Fragment, null);
    }
  }));
});
export { PaymentGatewaysList };