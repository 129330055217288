import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { useSaleProduct } from "./useSaleProduct";
export var useCategoryData = function useCategoryData(data, items) {
  var ProductsSubCategory = [];
  var subCategoriesIds = [];
  data === null || data === void 0 ? void 0 : data.categoryProducts.forEach(function (productVariant) {
    if (productVariant.categoryIdLightLoad) {
      var sumItems = items === null || items === void 0 ? void 0 : items.filter(function (itemsCart) {
        var _itemsCart$variant;

        return (itemsCart === null || itemsCart === void 0 ? void 0 : (_itemsCart$variant = itemsCart.variant) === null || _itemsCart$variant === void 0 ? void 0 : _itemsCart$variant.id) === productVariant.id;
      }).map(function (item) {
        return item.quantity;
      }).reduce(function (a, b) {
        return a + b;
      }, 0);
      var itemsQuantity = sumItems > 0 ? sumItems : null;

      var _useSaleProduct = useSaleProduct(productVariant),
          discountPercentage = _useSaleProduct.discountPercentage,
          discountValue = _useSaleProduct.discountValue;

      var indexProductsSubCategory = ProductsSubCategory === null || ProductsSubCategory === void 0 ? void 0 : ProductsSubCategory.findIndex(function (productSubCategory) {
        var _productSubCategory$s;

        return (productSubCategory === null || productSubCategory === void 0 ? void 0 : (_productSubCategory$s = productSubCategory.subCategory) === null || _productSubCategory$s === void 0 ? void 0 : _productSubCategory$s.id) === (productVariant === null || productVariant === void 0 ? void 0 : productVariant.subCategoryIdLightLoad);
      });
      subCategoriesIds.push(productVariant === null || productVariant === void 0 ? void 0 : productVariant.subCategoryIdLightLoad);

      if (indexProductsSubCategory === -1) {
        var _productVariant$subCa, _productVariant$subCa2;

        ProductsSubCategory.push({
          category: {
            id: productVariant === null || productVariant === void 0 ? void 0 : productVariant.categoryIdLightLoad,
            name: productVariant === null || productVariant === void 0 ? void 0 : productVariant.categoryNameLightLoad
          },
          subCategory: {
            id: productVariant === null || productVariant === void 0 ? void 0 : productVariant.subCategoryIdLightLoad,
            name: (productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$subCa = productVariant.subCategoryOrderTradeLightLoad[0]) === null || _productVariant$subCa === void 0 ? void 0 : _productVariant$subCa.name) || (productVariant === null || productVariant === void 0 ? void 0 : productVariant.subCategoryNameLightLoad),
            orderIndex: (productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$subCa2 = productVariant.subCategoryOrderTradeLightLoad[0]) === null || _productVariant$subCa2 === void 0 ? void 0 : _productVariant$subCa2.orderIndex) || (productVariant === null || productVariant === void 0 ? void 0 : productVariant.subCategoryOrderLightLoad) || 1000000000
          },
          products: [{
            category: {
              id: productVariant === null || productVariant === void 0 ? void 0 : productVariant.categoryIdLightLoad,
              name: productVariant === null || productVariant === void 0 ? void 0 : productVariant.categoryNameLightLoad
            },
            subCategory: {
              id: productVariant === null || productVariant === void 0 ? void 0 : productVariant.subCategoryIdLightLoad,
              name: productVariant === null || productVariant === void 0 ? void 0 : productVariant.subCategoryNameLightLoad
            },
            id: productVariant === null || productVariant === void 0 ? void 0 : productVariant.id,
            name: (productVariant === null || productVariant === void 0 ? void 0 : productVariant.name) || (productVariant === null || productVariant === void 0 ? void 0 : productVariant.productNameLightLoad),
            price: productVariant === null || productVariant === void 0 ? void 0 : productVariant.priceLightLoad,
            discountPercentage: discountPercentage,
            discountValue: discountValue,
            itemsQuantity: itemsQuantity,
            thumbnail: productVariant === null || productVariant === void 0 ? void 0 : productVariant.thumbnailLightLoad,
            __typename: "Product"
          }]
        });
      } else {
        var _ProductsSubCategory$;

        var tempProducts = (_ProductsSubCategory$ = ProductsSubCategory[indexProductsSubCategory]) === null || _ProductsSubCategory$ === void 0 ? void 0 : _ProductsSubCategory$.products;
        ProductsSubCategory[indexProductsSubCategory].products = [].concat(_toConsumableArray(tempProducts), [{
          category: {
            id: productVariant === null || productVariant === void 0 ? void 0 : productVariant.categoryIdLightLoad,
            name: productVariant === null || productVariant === void 0 ? void 0 : productVariant.categoryNameLightLoad
          },
          subCategory: {
            id: productVariant === null || productVariant === void 0 ? void 0 : productVariant.subCategoryIdLightLoad,
            name: productVariant === null || productVariant === void 0 ? void 0 : productVariant.subCategoryNameLightLoad
          },
          id: productVariant === null || productVariant === void 0 ? void 0 : productVariant.id,
          name: productVariant === null || productVariant === void 0 ? void 0 : productVariant.productNameLightLoad,
          price: productVariant === null || productVariant === void 0 ? void 0 : productVariant.priceLightLoad,
          discountPercentage: discountPercentage,
          discountValue: discountValue,
          itemsQuantity: itemsQuantity,
          thumbnail: productVariant === null || productVariant === void 0 ? void 0 : productVariant.thumbnailLightLoad,
          __typename: "Product"
        }]);
      }
    }
  });
  var sendProducts = {
    products: ProductsSubCategory.sort(function (a, b) {
      var _a$subCategory, _b$subCategory;

      return (a === null || a === void 0 ? void 0 : (_a$subCategory = a.subCategory) === null || _a$subCategory === void 0 ? void 0 : _a$subCategory.orderIndex) - (b === null || b === void 0 ? void 0 : (_b$subCategory = b.subCategory) === null || _b$subCategory === void 0 ? void 0 : _b$subCategory.orderIndex);
    })
  };
  var subCategoriesId = new Set(subCategoriesIds);

  var subCategoriesAmount = _toConsumableArray(subCategoriesId).length;

  return {
    sendProducts: sendProducts,
    subCategoriesAmount: subCategoriesAmount,
    ProductsSubCategory: ProductsSubCategory
  };
};