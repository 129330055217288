import { waDeliveryNumber } from "../../constants";
export var baseDeliveryUrl = "/delivery";
export var accountDelivery = "/account/";
export var summaryByAreaDelivery = "/reports/summary-by-area/";
export var orderFinishedDeliveryUrl = "/delivery/finished";
export var orderDeliveryUrl = "/delivery/order/:token/";
export var orderDetailDeliveryUrl = "/delivery/order-detail/:token/"; // --------- Login from whatsapp ---------------
// This path must be the same as the path defined in the variable 'waLoginPath' on the file
// scr/index.tsx

export var waDeliveryLogin = "".concat(baseDeliveryUrl, "/web-access/:userId/:accessCode/");
export var waDeliveryLoginOrder = "".concat(baseDeliveryUrl, "/web-access/:userId/:accessCode/order/:orderToken"); // ------- Expresiones Regulares -------

export var waDeliveryLoginRegExp = new RegExp("".concat(baseDeliveryUrl, "/web-access/[0-9]+/[A-Za-z0-9_]+/"));
export var waDeliveryLoginOrderRegExp = new RegExp("".concat(baseDeliveryUrl, "/web-access/[0-9]+/[A-Za-z0-9_]+/order/[A-Za-z0-9]+")); // ---------------------------------------------

export var urlOpenWhatsappDelivery = "https://api.whatsapp.com/send?phone=".concat(waDeliveryNumber);