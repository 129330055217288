import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import * as React from "react";
import { useIntl } from "react-intl";
import { prodListHeaderCommonMsg } from "@temp/intl";
import { StringParam, useQueryParam } from "use-query-params";
import { useParams } from "react-router-dom";
import { perPage } from "@temp/constants";
import { NotFound, OfflinePlaceholder, MainMenu } from "../../components";
import NetworkStatus from "../../components/NetworkStatus";
import { convertSortByFromString, convertToAttributeScalar, getGraphqlIdFromDBId, maybe } from "../../core/utils";
import Page from "./Page";
import { TypedSearchProductsQuery } from "./queries";
var PRODUCTS_PER_PAGE = +perPage;
export var FilterQuerySet = {
  encode: function encode(valueObj) {
    var str = [];
    Object.keys(valueObj).forEach(function (value) {
      str.push("".concat(value, "_").concat(valueObj[value].join("_")));
    });
    return str.join(".");
  },
  decode: function decode(strValue) {
    var obj = {};
    var propsWithValues = strValue.split(".").filter(function (n) {
      return n;
    });
    propsWithValues.map(function (value) {
      var propWithValues = value.split("_").filter(function (n) {
        return n;
      });
      obj[propWithValues[0]] = propWithValues.slice(1);
    });
    return obj;
  }
};
export var View = function View() {
  var _useQueryParam = useQueryParam("sortBy", StringParam),
      _useQueryParam2 = _slicedToArray(_useQueryParam, 2),
      sort = _useQueryParam2[0],
      setSort = _useQueryParam2[1];

  var _useQueryParam3 = useQueryParam("q", StringParam),
      _useQueryParam4 = _slicedToArray(_useQueryParam3, 2),
      search = _useQueryParam4[0],
      setSearch = _useQueryParam4[1];

  var _useQueryParam5 = useQueryParam("filters", FilterQuerySet),
      _useQueryParam6 = _slicedToArray(_useQueryParam5, 2),
      attributeFilters = _useQueryParam6[0],
      setAttributeFilters = _useQueryParam6[1];

  var intl = useIntl();

  var _useParams = useParams(),
      id = _useParams.id;

  var filters = {
    attributes: attributeFilters,
    pageSize: PRODUCTS_PER_PAGE,
    priceGte: null,
    priceLte: null,
    sortBy: sort || null
  };

  var variables = _objectSpread(_objectSpread({}, filters), {}, {
    attributes: filters.attributes ? convertToAttributeScalar(filters.attributes) : {},
    id: getGraphqlIdFromDBId(id, "Category"),
    query: search || null,
    sortBy: convertSortByFromString(filters.sortBy)
  });

  var sortOptions = [{
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsClear),
    value: null
  }, {
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsPrice),
    value: "price"
  }, {
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsPriceDsc),
    value: "-price"
  }, {
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsName),
    value: "name"
  }, {
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsNameDsc),
    value: "-name"
  }, {
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsUpdatedAt),
    value: "updated_at"
  }, {
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsUpdatedAtDsc),
    value: "-updated_at"
  }];

  var clearFilters = function clearFilters() {
    setAttributeFilters({});
  };

  var onFiltersChange = function onFiltersChange(name, value) {
    if (attributeFilters && attributeFilters.hasOwnProperty(name)) {
      if (attributeFilters[name].includes(value)) {
        if (filters.attributes["".concat(name)].length === 1) {
          var att = _objectSpread({}, attributeFilters);

          delete att["".concat(name)];
          setAttributeFilters(_objectSpread({}, att));
        } else {
          setAttributeFilters(_objectSpread(_objectSpread({}, attributeFilters), {}, _defineProperty({}, "".concat(name), attributeFilters["".concat(name)].filter(function (item) {
            return item !== value;
          }))));
        }
      } else {
        setAttributeFilters(_objectSpread(_objectSpread({}, attributeFilters), {}, _defineProperty({}, "".concat(name), [].concat(_toConsumableArray(attributeFilters["".concat(name)]), [value]))));
      }
    } else {
      setAttributeFilters(_objectSpread(_objectSpread({}, attributeFilters), {}, _defineProperty({}, "".concat(name), [value])));
    }
  };

  return React.createElement(React.Fragment, null, React.createElement(MainMenu, {
    goback: false,
    search: false,
    cart: true
  }), React.createElement(NetworkStatus, null, function (isOnline) {
    return React.createElement(TypedSearchProductsQuery, {
      variables: variables,
      errorPolicy: "all",
      loaderFull: true
    }, function (_ref) {
      var _data$attributes, _data$products;

      var loading = _ref.loading,
          data = _ref.data,
          loadMore = _ref.loadMore;
      var canDisplayFilters = !!(data === null || data === void 0 ? void 0 : (_data$attributes = data.attributes) === null || _data$attributes === void 0 ? void 0 : _data$attributes.edges) && !!(data === null || data === void 0 ? void 0 : (_data$products = data.products) === null || _data$products === void 0 ? void 0 : _data$products.edges);

      if (canDisplayFilters) {
        var handleLoadMore = function handleLoadMore() {
          return loadMore(function (prev, next) {
            return _objectSpread(_objectSpread({}, prev), {}, {
              products: _objectSpread(_objectSpread({}, prev.products), {}, {
                edges: [].concat(_toConsumableArray(prev.products.edges), _toConsumableArray(next.products.edges)),
                pageInfo: next.products.pageInfo
              })
            });
          }, {
            after: data.products.pageInfo.endCursor
          });
        };

        return React.createElement(Page, {
          clearFilters: clearFilters,
          attributes: data.attributes.edges.map(function (edge) {
            return edge.node;
          }),
          displayLoader: loading,
          hasNextPage: maybe(function () {
            return data.products.pageInfo.hasNextPage;
          }, false),
          sortOptions: sortOptions,
          setSearch: setSearch,
          search: search,
          activeSortOption: filters.sortBy,
          filters: filters,
          products: data.products,
          onAttributeFiltersChange: onFiltersChange,
          onLoadMore: handleLoadMore,
          activeFilters: filters.attributes ? Object.keys(filters.attributes).length : 0,
          onOrder: function onOrder(value) {
            setSort(value.value);
          }
        });
      }

      if (data && data.products === null) {
        return React.createElement(NotFound, null);
      }

      if (!isOnline) {
        return React.createElement(OfflinePlaceholder, null);
      }
    });
  }));
};
export default View;