import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import * as React from "react";
import { useAuth } from "@saleor/sdk";
import "./scss/index.scss";
import { finalToppingValuePrice } from "./utils";
import { TableLineView } from "./TableLineView";
export var TableLine = function TableLine(_ref) {
  var _line$productWithOutT3;

  var line = _ref.line,
      trade = _ref.trade,
      index = _ref.index;

  var _useAuth = useAuth(),
      user = _useAuth.user;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      seeMore = _React$useState2[0],
      setSeeMore = _React$useState2[1];

  var _React$useState3 = React.useState(""),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      allToppingsNameState = _React$useState4[0],
      setAllToppingsNameState = _React$useState4[1];

  var _React$useState5 = React.useState(""),
      _React$useState6 = _slicedToArray(_React$useState5, 2),
      ellipsisToppingsNameState = _React$useState6[0],
      setEllipsisToppingsNameState = _React$useState6[1];

  var _React$useState7 = React.useState(""),
      _React$useState8 = _slicedToArray(_React$useState7, 2),
      ellipsisToppingsNameDesktopState = _React$useState8[0],
      setEllipsisToppingsNameDesktopState = _React$useState8[1];

  var _React$useState9 = React.useState(false),
      _React$useState10 = _slicedToArray(_React$useState9, 2),
      changeView = _React$useState10[0],
      setChangeView = _React$useState10[1];

  var ellipsisToppingsNameDesktopRef = React.useRef(null);
  var ellipsisToppingsNameMobilepRef = React.useRef(null);
  var allToppingsNameSeeLessRef = React.useRef(null);
  var allToppingsNameRef = React.useRef(null);
  var priceUndiscounted = React.useMemo(function () {
    return line.unitPrice && line.unitPrice.net && line.unitPrice.net.amount ? line.unitPrice.net.amount : undefined;
  }, [line.unitPrice]);
  var thumbnail = React.useMemo(function () {
    var _line$productWithOutT, _line$productWithOutT2, _user$currentTrade, _user$currentTrade2;

    return {
      thumbnail: (line === null || line === void 0 ? void 0 : (_line$productWithOutT = line.productWithOutTenant) === null || _line$productWithOutT === void 0 ? void 0 : _line$productWithOutT.thumbnail) ? line === null || line === void 0 ? void 0 : (_line$productWithOutT2 = line.productWithOutTenant) === null || _line$productWithOutT2 === void 0 ? void 0 : _line$productWithOutT2.thumbnail : (user === null || user === void 0 ? void 0 : (_user$currentTrade = user.currentTrade) === null || _user$currentTrade === void 0 ? void 0 : _user$currentTrade.id) === (trade === null || trade === void 0 ? void 0 : trade.id) ? user === null || user === void 0 ? void 0 : (_user$currentTrade2 = user.currentTrade) === null || _user$currentTrade2 === void 0 ? void 0 : _user$currentTrade2.defaultProductImageThumbnail : trade === null || trade === void 0 ? void 0 : trade.defaultProductImageThumbnail
    };
  }, [line === null || line === void 0 ? void 0 : (_line$productWithOutT3 = line.productWithOutTenant) === null || _line$productWithOutT3 === void 0 ? void 0 : _line$productWithOutT3.thumbnail]);
  var price = React.useMemo(function () {
    return Math.round(+line.discountAmount) > 1 ? priceUndiscounted - Number(line.discountAmount) : priceUndiscounted;
  }, [priceUndiscounted]);
  var discountedPercentageValue = React.useMemo(function () {
    return line.discountPercentage !== "0" && line.discountPercentage !== null && !isNaN(+line.discountPercentage) ? "-".concat(Math.ceil(+line.discountPercentage), "%") : "0";
  }, [line.discountPercentage]);
  var name = React.useMemo(function () {
    return line.name || line.productWithOutTenant.name;
  }, [line.name, line.productWithOutTenant.name]);
  var unitPriceDefined = React.useMemo(function () {
    return {
      net: {
        amount: price,
        currency: line.unitPrice.net.currency
      },
      gross: {
        amount: price,
        currency: line.unitPrice.gross.currency
      }
    };
  }, [line.unitPrice]);
  var handleSeeMore = React.useCallback(function () {
    setSeeMore(function (more) {
      return !more;
    });
  }, [setSeeMore]);
  var assignToppingsName = React.useCallback(function () {
    var allToppingsName = "";
    var allToppings = JSON.parse(line === null || line === void 0 ? void 0 : line.attributes);

    if (allToppings.length > 0) {
      var toppingsOnlyWithQuantityInValues = allToppings.map(function (topping) {
        var dataValues = topping.dataValues.filter(function (value) {
          return value.quantity > 0 && value.isActive;
        });

        if (dataValues.length > 0) {
          return {
            dataValues: dataValues,
            name: topping.name.charAt(0).toUpperCase() + topping.name.slice(1).toLowerCase()
          };
        }
      }).filter(function (topping) {
        return topping !== undefined;
      });
      allToppingsName = toppingsOnlyWithQuantityInValues.map(function (topping, index) {
        var toppingsValues = topping.dataValues.map(function (value, index) {
          var finalValue = finalToppingValuePrice(value.price, value.quantity);
          var commaValues = index + 1 === topping.dataValues.length ? "" : ", ";
          return value.quantity > 0 && value.isActive && "".concat(value.quantity, " x ").concat(value.nameValue.trim()).concat(finalValue ? "(".concat(finalValue, ")").concat(commaValues) : "".concat(commaValues));
        }).join("");
        var commaToppings = index + 1 === toppingsOnlyWithQuantityInValues.length ? " " : ", ";
        return "<b>".concat(topping.name.trim(), ": </b> ").concat(toppingsValues).concat(commaToppings);
      }).join("");
    }

    setAllToppingsNameState(allToppingsName);
    var ellipsisToppingsName = allToppingsName && allToppingsName.length >= 40 ? "".concat(allToppingsName.slice(0, 40), "...") : "";
    setEllipsisToppingsNameState(ellipsisToppingsName);
    var ellipsisToppingsNameDesktop = allToppingsName && allToppingsName.length >= 60 ? "".concat(allToppingsName.slice(0, 60), "...") : "";
    setEllipsisToppingsNameDesktopState(ellipsisToppingsNameDesktop);
    setChangeView(function (prevChangeView) {
      return !prevChangeView;
    });
  }, [setAllToppingsNameState, setChangeView, setEllipsisToppingsNameState, setEllipsisToppingsNameDesktopState]);
  React.useEffect(function () {
    if (ellipsisToppingsNameMobilepRef === null || ellipsisToppingsNameMobilepRef === void 0 ? void 0 : ellipsisToppingsNameMobilepRef.current) {
      ellipsisToppingsNameMobilepRef.current.innerHTML = ellipsisToppingsNameState;
    }

    if (allToppingsNameRef === null || allToppingsNameRef === void 0 ? void 0 : allToppingsNameRef.current) {
      allToppingsNameRef.current.innerHTML = allToppingsNameState;
    }

    if (seeMore && (allToppingsNameSeeLessRef === null || allToppingsNameSeeLessRef === void 0 ? void 0 : allToppingsNameSeeLessRef.current)) {
      allToppingsNameSeeLessRef.current.innerHTML = allToppingsNameState;
    } else if (!seeMore && (ellipsisToppingsNameDesktopRef === null || ellipsisToppingsNameDesktopRef === void 0 ? void 0 : ellipsisToppingsNameDesktopRef.current)) {
      ellipsisToppingsNameDesktopRef.current.innerHTML = ellipsisToppingsNameDesktopState;
    }
  }, [seeMore, ellipsisToppingsNameState, allToppingsNameState, ellipsisToppingsNameDesktopState, changeView]);
  React.useEffect(function () {
    assignToppingsName();
  }, [seeMore]);
  React.useEffect(function () {
    assignToppingsName();
    window.addEventListener("resize", assignToppingsName);
    return function () {
      window.removeEventListener("resize", assignToppingsName);
    };
  }, []);
  return React.createElement(TableLineView, {
    allToppingsNameState: allToppingsNameState,
    allToppingsNameRef: allToppingsNameRef,
    allToppingsNameSeeLessRef: allToppingsNameSeeLessRef,
    discountedPercentageValue: discountedPercentageValue,
    ellipsisToppingsNameDesktopState: ellipsisToppingsNameDesktopState,
    ellipsisToppingsNameState: ellipsisToppingsNameState,
    ellipsisToppingsNameMobilepRef: ellipsisToppingsNameMobilepRef,
    ellipsisToppingsNameDesktopRef: ellipsisToppingsNameDesktopRef,
    handleSeeMore: handleSeeMore,
    line: line,
    name: name,
    priceUndiscounted: priceUndiscounted,
    price: price,
    seeMore: seeMore,
    unitPriceDefined: unitPriceDefined,
    thumbnail: thumbnail
  });
};