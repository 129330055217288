import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject27() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  min-width: 320px;\n  height: auto;\n  padding: 20px;\n  background: white;\n  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);\n"]);

  _templateObject27 = function _templateObject27() {
    return data;
  };

  return data;
}

function _templateObject26() {
  var data = _taggedTemplateLiteral(["\n    display: flex;\n  "]);

  _templateObject26 = function _templateObject26() {
    return data;
  };

  return data;
}

function _templateObject25() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  position: fixed;\n  left: 0;\n  bottom: 0;\n  display: none;\n  flex-direction: column;\n  ", "\n\n  @media (min-width: 850px) {\n    position: absolute;\n    display: flex;\n  }\n\n  div {\n    margin-bottom: 20px;\n    &:last-of-type {\n      margin-bottom: 0px;\n    }\n  }\n"]);

  _templateObject25 = function _templateObject25() {
    return data;
  };

  return data;
}

function _templateObject24() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-between;\n  span {\n    display: inline-block;\n    text-transform: uppercase;\n  }\n  font-weight: ", ";\n"]);

  _templateObject24 = function _templateObject24() {
    return data;
  };

  return data;
}

function _templateObject23() {
  var data = _taggedTemplateLiteral(["\n  width: 20px;\n  height: 20px;\n  svg {\n    width: 20px;\n    height: 20px;\n  }\n"]);

  _templateObject23 = function _templateObject23() {
    return data;
  };

  return data;
}

function _templateObject22() {
  var data = _taggedTemplateLiteral(["\n    transform: rotate(180deg);\n  "]);

  _templateObject22 = function _templateObject22() {
    return data;
  };

  return data;
}

function _templateObject21() {
  var data = _taggedTemplateLiteral(["\n    display: unset;\n  "]);

  _templateObject21 = function _templateObject21() {
    return data;
  };

  return data;
}

function _templateObject20() {
  var data = _taggedTemplateLiteral(["\n  display: none;\n  ", "\n  ", "\n"]);

  _templateObject20 = function _templateObject20() {
    return data;
  };

  return data;
}

function _templateObject19() {
  var data = _taggedTemplateLiteral(["\n    display: flex;\n  "]);

  _templateObject19 = function _templateObject19() {
    return data;
  };

  return data;
}

function _templateObject18() {
  var data = _taggedTemplateLiteral(["\n    font-size: ", ";\n    cursor: pointer;\n  "]);

  _templateObject18 = function _templateObject18() {
    return data;
  };

  return data;
}

function _templateObject17() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  min-width: 320px;\n  position: fixed;\n  top: 0;\n  left: 0;\n  padding: 15px;\n  height: 50px;\n  display: none;\n  justify-content: space-between;\n  margin: 0;\n  font-size: ", ";\n  text-transform: uppercase;\n  font-weight: ", ";\n  background-color: #f76268;\n  color: white;\n  z-index: 99;\n  ", "\n  ", "\n  @media (min-width: 850px) {\n    position: absolute;\n    display: flex;\n  }\n"]);

  _templateObject17 = function _templateObject17() {
    return data;
  };

  return data;
}

function _templateObject16() {
  var data = _taggedTemplateLiteral(["\n    text-align: start;\n  "]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  text-align: center;\n  ", "\n"]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = _taggedTemplateLiteral(["\n  display: block;\n  height: 1px;\n  border: 0;\n  border-top: 1px solid ", ";\n  margin: 0;\n  padding: 0;\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\n  margin-bottom: 100px;\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n  padding: 30px 0;\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n    padding: 50px 20px 150px 20px;\n  "]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  padding: 35px 20px 150px 20px;\n  @media (min-width: 850px) {\n    height: 400px;\n    padding-bottom: 0px;\n    overflow: auto;\n  }\n  ", "\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  padding-right: 20px;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  width: 60%;\n  padding: 10px;\n  font-size: 16px;\n  font-weight: bold;\n  text-align: center;\n  text-transform: uppercase;\n  background-color: white;\n  border-radius: 10px;\n  border: 1px solid #b5b6b7;\n  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);\n  color: #ed1c24;\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  padding: 10px;\n  margin: 0 auto;\n  font-size: 16px;\n  font-weight: bold;\n  text-align: center;\n  text-transform: uppercase;\n  background-color: #27c763;\n  border-radius: 10px;\n  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);\n  color: white;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  padding: 10px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n    display: flex;\n  "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  min-width: 320px;\n  height: auto;\n  margin-bottom: 0px !important;\n  padding: 10px 5px;\n  display: none;\n  flex-direction: row;\n  justify-content: flex-end;\n  background-color: transparent;\n  z-index: 99;\n\n  ", "\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    top: 0%;\n    overflow-y: scroll;\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    width: 100%;\n    height: 100%;\n    position: fixed;\n    left: 0%;\n    transition: all 0.5s ease;\n    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.30);\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  // background-color: ", ";\n  min-width: 320px;\n  position: relative;\n  background-color: white;\n  ", "\n  ", "\n  @media (min-width: 850px) {\n    height: 550px;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.colors.lightStrong;
}, media.xMediumScreen(_templateObject2()), function (props) {
  return props.mobileCartOpened && media.xMediumScreen(_templateObject3());
});
export var ButtonModify = styled.div(_templateObject4(), function (props) {
  return props.mobileCartOpened && media.xMediumScreen(_templateObject5());
});
export var ButtonContinue = styled.div(_templateObject6());
export var Continue = styled.div(_templateObject7());
export var Modify = styled.button(_templateObject8());
export var ModifyDown = styled.div(_templateObject9());
export var Content = styled.div(_templateObject10(), media.xMediumScreen(_templateObject11()));
export var ProductLine = styled.div(_templateObject12());
export var CartSummaryProductList = styled.div(_templateObject13());
export var HR = styled.hr(_templateObject14(), function (props) {
  return props.theme.colors.baseFontColorTransparent;
});
export var SubTile = styled.p(_templateObject15(), media.xMediumScreen(_templateObject16()));
export var Title = styled.div(_templateObject17(), function (props) {
  return props.theme.typography.h4FontSize;
}, function (props) {
  return props.theme.typography.boldFontWeight;
}, media.xMediumScreen(_templateObject18(), function (props) {
  return props.theme.typography.h4FontSize;
}), function (props) {
  return props.mobileCartOpened && media.xMediumScreen(_templateObject19());
});
export var ArrowUp = styled.div(_templateObject20(), media.xMediumScreen(_templateObject21()), function (props) {
  return props.mobileCartOpened && media.xMediumScreen(_templateObject22());
});
export var Image = styled.div(_templateObject23());
export var CostLine = styled.div(_templateObject24(), function (props) {
  return props.last ? props.theme.typography.boldFontWeight : "normal";
});
export var Costs = styled.div(_templateObject25(), function (props) {
  return props.mobileCartOpened && media.xMediumScreen(_templateObject26());
});
export var TotalCost = styled.div(_templateObject27());