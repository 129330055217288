import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject8() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  display: grid;\n  grid-template-columns: 20% 80%;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  position: relative;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n      width: 100%;\n    "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  & > div {\n    width: calc(50% - ", " / 2);\n    ", "\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-wrap: wrap;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  position: absolute;\n  background: #5bb4f9;\n  border: 0;\n  border-radius: 8px;\n  color: white;\n  font-size: 14px;\n  padding: 10px;\n  cursor: pointer;\n  height: 45px;\n  right: 1px;\n  text-align: center;\n  top: 44%;\n  transform: translate(0, -73%);\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var AddressForm = styled.form(_templateObject());
export var ButtonInputIdentityCard = styled.div(_templateObject2());
export var Wrapper = styled.div(_templateObject3());
export var RowWithTwoCells = styled.div(_templateObject4(), function (props) {
  return props.theme.spacing.fieldSpacer;
}, media.smallScreen(_templateObject5()));
export var RowWithOneCell = styled.div(_templateObject6());
export var TextNameWrite = styled.div(_templateObject7());
export var TextNameWriteImg = styled.div(_templateObject8());