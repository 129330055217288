import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 45px;\n  position: absolute;\n  top: -2px;\n  left: -2px;\n  right: -2px;\n  bottom: -2px;\n  z-index: -1;\n  border-radius: 8px;\n  animation: wavesCart 2s infinite;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: 80%;\n  height: 50px;\n  margin: 0 auto;\n  margin-top: 1rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 10px;\n  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Loader = styled.div(_templateObject());
export var Waves = styled.div(_templateObject2());