import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "./scss/index.scss";
import React from "react";
import ReactSVG from "react-svg";
import { Button, Loader } from "@components/atoms";

var closeWhite = require("../../images/closewhite.svg"); // const greaterThanEqual = require("../../images/greaterThanEqual.svg");


export var AlertshowIframe = function AlertshowIframe(_ref) {
  var buttonLabel = _ref.buttonLabel,
      iframeSrc = _ref.iframeSrc,
      title = _ref.title,
      titleIframe = _ref.titleIframe,
      hideModal = _ref.hideModal;

  var _React$useState = React.useState(true),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      loading = _React$useState2[0],
      setLoading = _React$useState2[1];

  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "showIframe",
    onClick: hideModal
  }), React.createElement("div", {
    className: "showIframe__data"
  }, React.createElement("div", {
    className: "showIframe__data__close"
  }, React.createElement("p", null, title), React.createElement(ReactSVG, {
    path: closeWhite,
    onClick: hideModal
  })), React.createElement("div", {
    className: "showIframe__data__content showIframe__data__content--value showIframe__data__content--first showIframe__data__content--last"
  }, loading && React.createElement("div", {
    className: "showIframe__data__content__loader"
  }, React.createElement(Loader, null)), React.createElement("iframe", {
    className: "".concat(loading ? "showIframe__data__content__iframe" : ""),
    onLoad: function onLoad() {
      if (loading) setLoading(false);
    },
    title: titleIframe,
    width: "100%",
    height: "100%",
    src: iframeSrc
  }, React.createElement("p", null, "Your browser does not support iframes."))), React.createElement("div", {
    className: "showIframe__data__footer"
  }, React.createElement(Button, {
    type: "button",
    testingContext: "",
    onClick: hideModal
  }, buttonLabel))));
};
export default AlertshowIframe;