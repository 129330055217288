import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React from "react";
import * as S from "./styles";
export var Tile = function Tile(_ref) {
  var addressType = _ref.addressType,
      header = _ref.header,
      headerTitle = _ref.headerTitle,
      children = _ref.children,
      footer = _ref.footer,
      props = _objectWithoutProperties(_ref, ["addressType", "header", "headerTitle", "children", "footer"]);

  var addressTypeRender = React.createElement(React.Fragment, null, React.createElement(S.WrapperAddressAll, null, headerTitle && React.createElement(S.HeaderTitle, null, headerTitle), React.createElement(S.WrapperAddress, props, header && React.createElement(S.Header, null, React.createElement(S.Content, null, header)), React.createElement(S.Content, null, children), footer && React.createElement(S.Footer, null, footer))));
  var renderDefault = React.createElement(React.Fragment, null, React.createElement(S.Wrapper, props, header && React.createElement(S.Header, null, React.createElement(S.Content, null, header)), React.createElement(S.Content, null, children), footer && React.createElement(S.Footer, null, footer)));
  return addressType !== undefined && addressType === true ? addressTypeRender : renderDefault;
};