import "./scss/index.scss";
import React from "react";
import ReactSVG from "react-svg";
import { FormattedMessage } from "react-intl";
import Media from "react-responsive";
import { xmediumScreen } from "../../globalStyles/scss/variables.scss";

var closeWhite = require("../../images/closewhite.svg");

export var AlertCallEasyLink = function AlertCallEasyLink(_ref) {
  var hideModal = _ref.hideModal,
      tradeNumber = _ref.tradeNumber;
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "showContainerCallEasyLink",
    onClick: hideModal
  }), React.createElement("div", {
    className: "showContainerCallEasyLink__data"
  }, React.createElement("div", {
    className: "showContainerCallEasyLink__data__close"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.AlertCallEasyLink.index.3644201485",
    defaultMessage: "Order by call"
  })), React.createElement(ReactSVG, {
    path: closeWhite,
    onClick: hideModal
  })), React.createElement("div", {
    className: "showContainerCallEasyLink__data__content showContainerCallEasyLink__data__content--first"
  }), React.createElement("div", {
    className: "showContainerCallEasyLink__data__content  showContainerCallEasyLink__data__content--amount showContainerCallEasyLink__data__content--first"
  }, React.createElement(FormattedMessage, {
    id: "components.AlertCallEasyLink.index.1983744022",
    defaultMessage: "To place your order by phone call, please contact the number"
  }), ": "), React.createElement("div", {
    className: "showContainerCallEasyLink__data__content__orderNumber"
  }, React.createElement("span", null, tradeNumber)), React.createElement("div", {
    className: "showContainerCallEasyLink__data__footer"
  }, React.createElement(Media, {
    maxWidth: xmediumScreen
  }, function (matches) {
    return matches && React.createElement("a", {
      href: "tel:".concat(tradeNumber)
    }, React.createElement("p", null, React.createElement(FormattedMessage, {
      id: "components.AlertCallEasyLink.index.309951297",
      defaultMessage: "call now"
    })));
  }))));
};
export default AlertCallEasyLink;