import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  display: none;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    padding: 30px 20px;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  min-height: 50px;\n  max-height: 180px;\n  display: block;\n  background-color: ", ";\n  padding: 30px;\n  padding: ", ";\n  margin-top: ", ";\n  font-size: ", ";\n  cursor: pointer;\n  border-radius: 10px;\n  animation: ", ";\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Label = styled.label(_templateObject(), function (props) {
  return props.theme.colors.light;
}, function (props) {
  return props.checked ? "28px" : "30px";
}, function (props) {
  return props.defaultAddress ? "20px" : "0x";
}, function (props) {
  return props.theme.typography.smallFontSize;
}, function (props) {
  return props.defaultAddress ? "zoomAddress 2s infinite" : "";
}, media.smallScreen(_templateObject2()));
export var Input = styled.input(_templateObject3());