import * as React from "react";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "./app/routes";
export var useGoBack = function useGoBack() {
  var navigate = useNavigate();
  var goBackCategoryRedirectSale = React.useCallback(function () {
    navigate(baseUrl);
  }, []);
  var goBackSubCategoriesCategory = React.useCallback(function () {
    var categoriesUrl = localStorage.getItem("categories_url");

    if (categoriesUrl && categoriesUrl !== location.pathname) {
      navigate(categoriesUrl);
    } else {
      navigate(baseUrl);
    }
  }, []);
  var goBackCategory = React.useCallback(function () {
    var saleSubCategoryUrl = localStorage.getItem("salesubcategory_url");
    var saleUrl = localStorage.getItem("sale_url");
    var subCategoryUrl = localStorage.getItem("subcategory_url");
    var categoryUrl = localStorage.getItem("category_url");
    var categoriesUrl = localStorage.getItem("categories_url");
    var homeUrl = localStorage.getItem("home_url");
    localStorage.setItem("sale_products_link", "true");

    if (saleSubCategoryUrl && saleSubCategoryUrl !== location.pathname) {
      navigate(saleSubCategoryUrl);
    } else if (saleUrl && saleUrl !== location.pathname) {
      navigate(saleUrl);
    } else if (saleUrl && saleUrl !== location.pathname) {
      navigate(saleUrl);
    } else if (subCategoryUrl && subCategoryUrl !== location.pathname) {
      navigate(subCategoryUrl);
    } else if (categoryUrl && categoryUrl !== location.pathname) {
      navigate(categoryUrl);
    } else if (categoriesUrl && categoriesUrl !== location.pathname) {
      navigate(categoriesUrl);
    } else if (homeUrl && homeUrl !== location.pathname) {
      navigate(homeUrl);
    } else {
      navigate(baseUrl);
    }
  }, []);
  var goBackCategoriesSale = React.useCallback(function () {
    var saleSubCategoryUrl = localStorage.getItem("salesubcategory_url");
    var saleUrl = localStorage.getItem("sale_url");
    var subCategoryUrl = localStorage.getItem("subcategory_url");
    var categoryUrl = localStorage.getItem("category_url");
    var categoriesUrl = localStorage.getItem("categories_url");
    var homeUrl = localStorage.getItem("home_url");
    localStorage.setItem("sale_products_link", "true");

    if (saleSubCategoryUrl && saleSubCategoryUrl !== location.pathname) {
      navigate(saleSubCategoryUrl);
    } else if (saleUrl && saleUrl !== location.pathname) {
      navigate(saleUrl);
    } else if (saleUrl && saleUrl !== location.pathname) {
      navigate(saleUrl);
    } else if (subCategoryUrl && subCategoryUrl !== location.pathname) {
      navigate(subCategoryUrl);
    } else if (categoryUrl && categoryUrl !== location.pathname) {
      navigate(categoryUrl);
    } else if (categoriesUrl && categoriesUrl !== location.pathname) {
      navigate(categoriesUrl);
    } else if (homeUrl && homeUrl !== location.pathname) {
      navigate(homeUrl);
    } else {
      navigate(baseUrl);
    }
  }, []);
  return {
    goBackCategory: goBackCategory,
    goBackCategoryRedirectSale: goBackCategoryRedirectSale,
    goBackSubCategoriesCategory: goBackSubCategoriesCategory,
    goBackCategoriesSale: goBackCategoriesSale
  };
};