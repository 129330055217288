import _extends from "@babel/runtime/helpers/extends";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    var location = useLocation();
    var navigate = useNavigate();
    var params = useParams();
    return React.createElement(Component, _extends({}, props, {
      router: {
        location: location,
        navigate: navigate,
        params: params
      }
    }));
  }

  return ComponentWithRouterProp;
}