import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "./scss/index.scss";
import { useAppSelector, updateSearchTitleHeight, useAppDispatch } from "@temp/redux";
import React from "react";
import ReactSVG from "react-svg";
import { checkoutMessages, commonMessages } from "@temp/intl";
import { FormattedMessage, useIntl } from "react-intl";
import { Money } from "@components/containers";
import { categoriesUrl, saleUrl } from "@temp/app/routes";
import { Search } from "../Search";
import goBackArrowSearch from "../../images/goBackArrowSearch.svg";
import ubicationMobile from "../../images/markerMobile.svg";
import AlertCurrentNeighborhood from "../AlertCurrentNeighborhood";
export var SearchHeaderMobile = function SearchHeaderMobile(_ref) {
  var activeGoBack = _ref.activeGoBack,
      loadingDeliveryPrice = _ref.loadingDeliveryPrice,
      name = _ref.name,
      handleGoBack = _ref.handleGoBack;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      showAlert = _React$useState2[0],
      setShowAlert = _React$useState2[1];

  var _React$useState3 = React.useState(0),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      scrollPosition = _React$useState4[0],
      setScrollPosition = _React$useState4[1];

  var _React$useState5 = React.useState(false),
      _React$useState6 = _slicedToArray(_React$useState5, 2),
      showCategoriesFilter = _React$useState6[0],
      setShowCategoriesFilter = _React$useState6[1];

  var intl = useIntl();

  var _useAppSelector = useAppSelector(function (state) {
    return state;
  }),
      _useAppSelector$deliv = _useAppSelector.deliveryCustomer,
      deliveryPrice = _useAppSelector$deliv.deliveryPrice,
      deliveryNeighborhoodName = _useAppSelector$deliv.deliveryNeighborhoodName,
      hasAttentionAtCurrentTime = _useAppSelector.tradeAttention.hasAttentionAtCurrentTime;

  var dispatch = useAppDispatch();
  React.useEffect(function () {
    var handleScroll = function handleScroll() {
      var position = window.pageYOffset;

      if (position > 0 && scrollPosition === 0 || position === 0 && scrollPosition > 0) {
        setScrollPosition(position);
      } else if (position <= 0) {
        setScrollPosition(0);
      }
    };

    window.addEventListener("scroll", handleScroll, {
      passive: true
    });
    return function () {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  React.useLayoutEffect(function () {
    dispatch(updateSearchTitleHeight({
      searchTitleHeight: document.getElementById("searchTitle") && document.getElementById("searchTitle").clientHeight - 24 // 24 corresponde a la altura estandar del título con una sola línea

    }));
    return function () {};
  }, [name]);
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "searchContainer ".concat(scrollPosition === 0 && !hasAttentionAtCurrentTime ? "searchContainer__hasNotAttentionNotScroll" : scrollPosition > 0 && !hasAttentionAtCurrentTime ? "searchContainer__hasNotAttentionScroll" : scrollPosition > 0 && hasAttentionAtCurrentTime && "searchContainer__hasAttentionScroll")
  }, React.createElement("div", {
    className: "searchContainer__price"
  }, React.createElement("div", {
    className: "searchContainer__price__priceOrder__neighborhood"
  }, React.createElement(ReactSVG, {
    className: "searchContainer__price__priceOrder__ubicationImage",
    path: ubicationMobile
  }), React.createElement("div", {
    onClick: function onClick() {
      return setShowAlert(true);
    }
  }, React.createElement("p", {
    className: "searchContainer__price__priceOrder__subTitleNeighborhood"
  }, intl.formatMessage(checkoutMessages.neighborhood), ":"), React.createElement("p", {
    className: "searchContainer__price__priceOrder__nameNeighborhood"
  }, deliveryNeighborhoodName))), React.createElement("div", {
    className: "searchContainer__price__priceOrder"
  }, loadingDeliveryPrice ? React.createElement("div", {
    className: "searchContainer__skeleton"
  }) : React.createElement(React.Fragment, null, React.createElement("p", {
    className: "searchContainer__price__priceOrder__textInfoPriceOrder"
  }, intl.formatMessage(checkoutMessages.priceShipping), ":"), parseInt("".concat(deliveryPrice.amount), 10) > 0 ? React.createElement("p", {
    className: "searchContainer__price__priceOrder__amount"
  }, React.createElement(Money, {
    money: deliveryPrice
  })) : React.createElement("p", {
    className: "searchContainer__price__priceOrder__free"
  }, intl.formatMessage(checkoutMessages.shippingFree))))), React.createElement("div", {
    className: "searchContainer__search"
  }, location.pathname !== categoriesUrl && location.pathname !== saleUrl && name ? React.createElement(React.Fragment, null, React.createElement(Search, {
    showCategoriesFilter: showCategoriesFilter,
    setShowCategoriesFilter: setShowCategoriesFilter
  }), name && React.createElement("div", {
    id: "searchTitle",
    className: "searchContainer__search__nameContainer"
  }, activeGoBack && React.createElement(ReactSVG, {
    onClick: handleGoBack,
    path: goBackArrowSearch
  }), React.createElement("h1", null, name))) : location.pathname === categoriesUrl ? React.createElement("div", {
    id: "searchTitle",
    className: "searchContainer__search__nameContainer"
  }, activeGoBack && React.createElement(ReactSVG, {
    onClick: handleGoBack,
    path: goBackArrowSearch
  }), React.createElement("h1", null, React.createElement(FormattedMessage, {
    id: "components.SearchHeader.SearchHeaderMobile.3583204912",
    defaultMessage: "Categories"
  }))) : location.pathname === saleUrl ? React.createElement(React.Fragment, null, React.createElement(Search, {
    showCategoriesFilter: showCategoriesFilter,
    setShowCategoriesFilter: setShowCategoriesFilter
  }), React.createElement("div", {
    id: "searchTitle",
    className: "searchContainer__search__nameContainer"
  }, activeGoBack && React.createElement(ReactSVG, {
    onClick: handleGoBack,
    path: goBackArrowSearch
  }), React.createElement("h1", null, intl.formatMessage(commonMessages.sale)))) : React.createElement(React.Fragment, null, React.createElement(Search, {
    showCategoriesFilter: showCategoriesFilter,
    setShowCategoriesFilter: setShowCategoriesFilter
  })))), showAlert && React.createElement(AlertCurrentNeighborhood, {
    hideModal: function hideModal() {
      setShowAlert(false);
    },
    neighborhood: deliveryNeighborhoodName
  }));
};