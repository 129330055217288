import React from "react";
import { Loader } from "@components/atoms";
import { Container } from "../Container";
import * as S from "./styles";

/**
 * Template for checkout page.
 */
var Checkout = function Checkout(_ref) {
  var activeStep = _ref.activeStep,
      loading = _ref.loading,
      navigation = _ref.navigation,
      checkout = _ref.checkout,
      paymentGateways = _ref.paymentGateways,
      _ref$hidePaymentGatew = _ref.hidePaymentGateways,
      hidePaymentGateways = _ref$hidePaymentGatew === void 0 ? false : _ref$hidePaymentGatew,
      cartSummary = _ref.cartSummary,
      button = _ref.button,
      paymentSelect = _ref.paymentSelect;
  return React.createElement(Container, null, loading && React.createElement(S.Loader, null, React.createElement(Loader, {
    fullScreen: true
  })), React.createElement(S.Wrapper, null, React.createElement(S.Navigation, null, navigation), React.createElement(S.Checkout, null, checkout), React.createElement(S.PaymentGateways, {
    hide: hidePaymentGateways
  }, paymentGateways), React.createElement(S.CartSummary, null, cartSummary), activeStep && activeStep !== "Address" && React.createElement(S.Button, null, !window.location.pathname.includes("payment"), button)));
};

export { Checkout };