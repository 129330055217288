import "./scss/index.scss";
import React from "react"; // import axios from "axios";
// import { useAuth } from "@saleor/sdk";

import SentButtons from "./SentButtons";
import ArrivedButtons from "./ArrivedButtons"; // import { apiUrl } from "../../constants";

var FooterDelivery = function FooterDelivery(_ref) {
  var sent = _ref.sent,
      arrived = _ref.arrived,
      setShowArrived = _ref.setShowArrived,
      setShowDelivered = _ref.setShowDelivered,
      setShowDecision = _ref.setShowDecision,
      setShowToComplete = _ref.setShowToComplete,
      setShowUnDelivered = _ref.setShowUnDelivered;
  return sent && React.createElement(SentButtons, {
    undeliveredClick: function undeliveredClick() {
      setShowArrived(false);
      setShowDelivered(false);
      setShowDecision(false);
      setShowUnDelivered(true);
    },
    toComplete: function toComplete() {
      setShowToComplete(true);
      setShowArrived(false);
      setShowDelivered(false);
      setShowDecision(false);
      setShowUnDelivered(false);
    }
  }) || arrived && React.createElement(ArrivedButtons, {
    undeliveredClick: function undeliveredClick() {
      setShowArrived(false);
      setShowDelivered(false);
      setShowDecision(false);
      setShowUnDelivered(true);
    },
    deliveredClick: function deliveredClick() {
      setShowArrived(false);
      setShowDelivered(true);
      setShowDecision(true);
      setShowUnDelivered(false);
    }
  });
};

export default FooterDelivery;