import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React from "react";
export var Money = function Money(_ref) {
  var money = _ref.money,
      defaultValue = _ref.defaultValue,
      quantity = _ref.quantity,
      basket = _ref.basket,
      props = _objectWithoutProperties(_ref, ["money", "defaultValue", "quantity", "basket"]);

  if (!money) {
    return React.createElement("span", props, defaultValue);
  }

  var conversorValue = money.currency === "COP" ? "es-CO" : undefined;
  var maximumFractionsDigitsValue = money.currency === "COP" ? 0 : 2;
  var moneyValue = basket && quantity && (money === null || money === void 0 ? void 0 : money.amount) ? money.amount * quantity : money === null || money === void 0 ? void 0 : money.amount;
  return React.createElement("span", props, money.currency && money.currency !== "" && moneyValue ? moneyValue.toLocaleString(conversorValue, {
    currency: money.currency,
    style: "currency",
    minimumFractionDigits: 0,
    maximumFractionDigits: maximumFractionsDigitsValue
  }) : moneyValue && moneyValue.toString());
};
Money.displayName = "Money";
export default Money;