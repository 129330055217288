import React from "react";
import { FormattedMessage } from "react-intl";
import ReactSVG from "react-svg";
import Media from "react-responsive";
import { xMediumScreen } from "@styles/constants";
import { StatusData, StatusDataFooter } from "@components/molecules";
import * as S from "./styles";

var goBackImg = require("../../../../images/arrowBack.svg");

var scheduledGif = require("../../../../images/scheduled.gif");

/**
 * draft status structure
 */
var StatusScheduled = function StatusScheduled(_ref) {
  var data = _ref.data,
      goBack = _ref.goBack,
      navigation = _ref.navigation;
  return React.createElement(S.Wrapper, null, React.createElement(S.Tile, null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(S.Title, null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(S.BackTitle, {
    onClick: goBack
  }, React.createElement(ReactSVG, {
    path: goBackImg
  }), React.createElement(S.BackTitleParragraph, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.StatusScheduled.StatusScheduled.3471378517",
    defaultMessage: "back"
  })))), React.createElement(FormattedMessage, {
    id: "@next.components.organisms.StatusScheduled.StatusScheduled.2860262317",
    defaultMessage: "Scheduled order"
  })))), React.createElement(S.Content, null, React.createElement(Media, {
    maxWidth: xMediumScreen
  }, React.createElement(S.Navigation, null, navigation)), React.createElement(Media, {
    maxWidth: xMediumScreen
  }, React.createElement(S.Title, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.StatusScheduled.StatusScheduled.2860262317",
    defaultMessage: "Scheduled order"
  }))), React.createElement(S.ContentContainer, null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(S.Navigation, null, navigation)), React.createElement(S.Gif, null, React.createElement("img", {
    alt: "cargando",
    src: scheduledGif
  })), React.createElement(S.ParragraphTitle, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.StatusScheduled.StatusScheduled.1469397642",
    defaultMessage: "The establishment has scheduled your order"
  })), data.cancellationReason && React.createElement(S.Parragraph, null, React.createElement(S.Span, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.StatusScheduled.StatusScheduled.3077186403",
    defaultMessage: "Reason"
  }), ": "), data.cancellationReason))), React.createElement(S.Aside, null, React.createElement(StatusData, {
    deliveryPhone: false,
    data: data,
    deliveryData: true
  })), React.createElement(S.Footer, null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(StatusDataFooter, {
    data: data
  }))));
};

export { StatusScheduled };