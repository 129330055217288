import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import * as React from "react";
import Media from "react-responsive";
import { FormattedMessage } from "react-intl";
import { xLargeScreen } from "@styles/constants";
import "./scss/index.scss";
import { TableLine } from "./TableLine";

var Table = function Table(_ref) {
  var subtotal = _ref.subtotal,
      totalCost = _ref.totalCost,
      discount = _ref.discount,
      discountName = _ref.discountName,
      trade = _ref.trade,
      lines = _ref.lines,
      rowProps = _objectWithoutProperties(_ref, ["subtotal", "totalCost", "discount", "discountName", "trade", "lines"]);

  if ((lines === null || lines === void 0 ? void 0 : lines.length) > 0) {
    return React.createElement(React.Fragment, null, React.createElement(Media, {
      minWidth: xLargeScreen
    }, React.createElement("div", {
      className: "tableHead"
    }, React.createElement("div", {
      className: "tableHead__product"
    }, React.createElement(FormattedMessage, {
      id: "components.CartTable.Table.3476647774",
      defaultMessage: "product"
    })), React.createElement("div", {
      className: "tableHead__price"
    }, React.createElement(FormattedMessage, {
      id: "components.CartTable.Table.3699647124",
      defaultMessage: "toppings"
    })), React.createElement("div", {
      className: "tableHead__price"
    }, React.createElement(FormattedMessage, {
      id: "components.CartTable.Table.3888873998",
      defaultMessage: "price"
    })), React.createElement("div", {
      className: "tableHead__observations"
    }, React.createElement(FormattedMessage, {
      id: "components.CartTable.Table.3339610539",
      defaultMessage: "observations"
    })), React.createElement("div", {
      className: "tableHead__discount"
    }, React.createElement(FormattedMessage, {
      id: "components.CartTable.Table.293722515",
      defaultMessage: "discount"
    })), React.createElement("div", {
      className: "tableHead__amount"
    }, React.createElement(FormattedMessage, {
      id: "components.CartTable.Table.1235209082",
      defaultMessage: "amount"
    })), React.createElement("div", {
      className: "tableHead__totalPrice"
    }, React.createElement(FormattedMessage, {
      id: "components.CartTable.Table.325526350",
      defaultMessage: "total price"
    })))), React.createElement("ul", null, lines === null || lines === void 0 ? void 0 : lines.map(function (line, index) {
      return React.createElement(TableLine, {
        trade: trade,
        key: line.id ? "id-".concat(line.id, "-").concat(index) : "idx-".concat(index),
        line: line,
        index: index
      });
    })));
  }

  return React.createElement(React.Fragment, null);
};

export default Table;