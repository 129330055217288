import "./scss/index.scss";
import React from "react";
import ReactSVG from "react-svg";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "@components/atoms";
import { useAuth } from "@saleor/sdk";
import { alertMessages, commonMessages } from "@temp/intl";

var closeWhite = require("../../images/closewhite.svg");

export var AlertNeigDoesNotMatchOrWithoutCoverage = function AlertNeigDoesNotMatchOrWithoutCoverage(_ref) {
  var idAddress = _ref.idAddress,
      hideModal = _ref.hideModal,
      currentNeighborhoodName = _ref.currentNeighborhoodName,
      loading = _ref.loading,
      realNeighborhoodName = _ref.realNeighborhoodName,
      realNeighborhoodTradeId = _ref.realNeighborhoodTradeId,
      neighborhoodDoesNotMatch = _ref.neighborhoodDoesNotMatch,
      newAddress = _ref.newAddress,
      setCreateUserAddress = _ref.setCreateUserAddress,
      setUpdateUserAddress = _ref.setUpdateUserAddress,
      setShowAlertNeighborhoodWithoutCoverage = _ref.setShowAlertNeighborhoodWithoutCoverage;

  var _useAuth = useAuth(),
      user = _useAuth.user;

  var intl = useIntl();
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "alertNeigDoesNotMatchOrWithoutCoverage",
    onClick: hideModal
  }), React.createElement("div", {
    className: "alertNeigDoesNotMatchOrWithoutCoverage__data"
  }, React.createElement("div", {
    className: "alertNeigDoesNotMatchOrWithoutCoverage__data__close"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.AlertNeigDoesNotMatchOrWithoutCoverage.index.709932324",
    defaultMessage: "Alert"
  })), React.createElement(ReactSVG, {
    path: closeWhite,
    onClick: hideModal
  })), React.createElement("div", {
    className: "alertNeigDoesNotMatchOrWithoutCoverage__data__content alertNeigDoesNotMatchOrWithoutCoverage__data__content--first"
  }), React.createElement("div", {
    className: "alertNeigDoesNotMatchOrWithoutCoverage__data__content alertNeigDoesNotMatchOrWithoutCoverage__data__content--value alertNeigDoesNotMatchOrWithoutCoverage__data__content--first"
  }), React.createElement("div", {
    className: "alertNeigDoesNotMatchOrWithoutCoverage__data__content alertNeigDoesNotMatchOrWithoutCoverage__data__content--value alertNeigDoesNotMatchOrWithoutCoverage__data__content--first alertNeigDoesNotMatchOrWithoutCoverage__data__content--last"
  }, neighborhoodDoesNotMatch ? React.createElement(React.Fragment, null, React.createElement("p", null, intl.formatMessage(alertMessages.addressIsNotInNeighborhoodIsInOther, {
    isNotFound: React.createElement("span", {
      key: "alertNeigDoesNotMatchOrWithoutCoverage-07",
      className: "alertNeigDoesNotMatchOrWithoutCoverage__data__content__underline"
    }, intl.formatMessage(commonMessages.isNotFound)),
    currentNeighborhood: React.createElement("span", {
      key: "alertNeigDoesNotMatchOrWithoutCoverage-01"
    }, currentNeighborhoodName),
    newNeighborhood: React.createElement("span", {
      key: "alertNeigDoesNotMatchOrWithoutCoverage-02"
    }, realNeighborhoodName)
  })), React.createElement("p", null, intl.formatMessage(alertMessages.changeNeighborhood, {
    newNeighborhood: realNeighborhoodName
  }))) : React.createElement(React.Fragment, null, React.createElement("p", null, intl.formatMessage(alertMessages.addressIsNotInNeighborhoodIsInOtherTradeDoesHaveCoverage, {
    isNotFound: React.createElement("span", {
      key: "alertNeigDoesNotMatchOrWithoutCoverage-03",
      className: "alertNeigDoesNotMatchOrWithoutCoverage__data__content__underline"
    }, intl.formatMessage(commonMessages.isNotFound)),
    currentNeighborhood: React.createElement("span", {
      key: "alertNeigDoesNotMatchOrWithoutCoverage-04"
    }, currentNeighborhoodName),
    newNeighborhood: React.createElement("span", {
      key: "alertNeigDoesNotMatchOrWithoutCoverage-05"
    }, realNeighborhoodName),
    dontHaveCoverage: React.createElement("span", {
      key: "alertNeigDoesNotMatchOrWithoutCoverage-06",
      className: "alertNeigDoesNotMatchOrWithoutCoverage__data__content__underline"
    }, "no tiene cobertura")
  })), React.createElement("p", null, intl.formatMessage(alertMessages.checkYourAddressAndTryAgain)))), React.createElement("div", {
    className: "alertNeigDoesNotMatchOrWithoutCoverage__data__footer"
  }, neighborhoodDoesNotMatch ? React.createElement(React.Fragment, null, React.createElement(Button, {
    type: "button",
    testingContext: "",
    onClick: hideModal
  }, React.createElement(FormattedMessage, {
    id: "components.AlertNeigDoesNotMatchOrWithoutCoverage.index.2680335018",
    defaultMessage: "No"
  })), React.createElement(Button, {
    disabled: loading,
    type: "button",
    testingContext: "",
    onClick: function onClick() {
      if (user.currentTrade !== null) {
        var currentTradeId = isNaN(parseInt(user.currentTrade.id, 10)) ? window.atob(user.currentTrade.id).split(":")[1] : user.currentTrade.id;
        var realNeighborhoodTradeIdConvert = isNaN(parseInt(realNeighborhoodTradeId, 10)) ? window.atob(realNeighborhoodTradeId).split(":")[1] : realNeighborhoodTradeId;

        if (currentTradeId !== realNeighborhoodTradeIdConvert) {
          setShowAlertNeighborhoodWithoutCoverage(true);
          hideModal();
          return;
        }
      }

      if (location.pathname.includes("/address/add")) {
        setCreateUserAddress({
          input: newAddress
        });
      } else if (location.pathname.includes("/address/edit")) {
        setUpdateUserAddress({
          id: idAddress,
          input: newAddress
        });
      }
    }
  }, React.createElement(FormattedMessage, {
    id: "components.AlertNeigDoesNotMatchOrWithoutCoverage.index.3308036799",
    defaultMessage: "Yes"
  }))) : React.createElement(Button, {
    type: "button",
    testingContext: "",
    onClick: hideModal
  }, React.createElement(FormattedMessage, {
    id: "components.AlertNeigDoesNotMatchOrWithoutCoverage.index.3577703060",
    defaultMessage: "Ok"
  })))));
};
export default AlertNeigDoesNotMatchOrWithoutCoverage;