import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { Formik } from "formik";
import React, { memo, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { AddNewTile, TileGrid } from "@components/atoms";
import { AddressTileOption } from "@components/molecules";
import { checkoutMessages } from "@temp/intl"; // import { IAddress } from "@types";
// import { identity } from "lodash";

import { useAuth } from "@saleor/sdk";
import { AlertCheckoutErrors } from "@temp/components";
import { useNavigate } from "react-router-dom";
import { AddressCheckout } from "../../../../components/AddressCheckout";
import * as S from "./styles";
/**
 * Addresses tiles to select with add new address tile opening address form addition modal.
 */

var AddressGridSelector = memo(function (_ref) {
  var _user$currentTrade, _user$currentTrade$ob;

  var addresses = _ref.addresses,
      selectedAddressId = _ref.selectedAddressId,
      countriesOptions = _ref.countriesOptions,
      userId = _ref.userId,
      errors = _ref.errors,
      onSelect = _ref.onSelect,
      formId = _ref.formId,
      formRef = _ref.formRef,
      addNewModalTarget = _ref.addNewModalTarget,
      nameOptions = _ref.nameOptions,
      neighborhoodsAttention = _ref.neighborhoodsAttention,
      newAddressFormId = _ref.newAddressFormId,
      testingContext = _ref.testingContext;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      showErrors = _React$useState2[0],
      setShowErrors = _React$useState2[1];

  var _React$useState3 = React.useState(false),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      displayShowAddress = _React$useState4[0],
      setdisplayShowAddress = _React$useState4[1];

  var _React$useState5 = React.useState(false),
      _React$useState6 = _slicedToArray(_React$useState5, 2),
      redirectEdit = _React$useState6[0],
      setRedirectEdit = _React$useState6[1];

  var _React$useState7 = React.useState(),
      _React$useState8 = _slicedToArray(_React$useState7, 2),
      addressData = _React$useState8[0],
      setAddressData = _React$useState8[1];

  var _React$useState9 = React.useState(nameOptions),
      _React$useState10 = _slicedToArray(_React$useState9, 2),
      nameOptionsSelect = _React$useState10[0],
      setnameOptionsSelect = _React$useState10[1];

  var intl = useIntl();

  var _useAuth = useAuth(),
      user = _useAuth.user;

  var navigate = useNavigate();
  useEffect(function () {
    localStorage.removeItem("address-undefined");
    localStorage.removeItem("address-user-id");
    localStorage.removeItem("address-data");
    localStorage.removeItem("address-neighborhood");
    localStorage.removeItem("address-name-options");
    localStorage.removeItem("address-from");
    localStorage.removeItem("address-validate-edit");
  }, []);
  var obligatoryFieldIdentityCard = user === null || user === void 0 ? void 0 : (_user$currentTrade = user.currentTrade) === null || _user$currentTrade === void 0 ? void 0 : (_user$currentTrade$ob = _user$currentTrade.obligatoryField) === null || _user$currentTrade$ob === void 0 ? void 0 : _user$currentTrade$ob.filter(function (obligatoryField) {
    return (obligatoryField === null || obligatoryField === void 0 ? void 0 : obligatoryField.fieldName) && (obligatoryField === null || obligatoryField === void 0 ? void 0 : obligatoryField.fieldName) !== "" && (obligatoryField === null || obligatoryField === void 0 ? void 0 : obligatoryField.fieldName.toLowerCase()) === "identity_card";
  });
  var addNewTile = React.createElement(AddNewTile, {
    "data-test": "".concat(testingContext, "AddressTileAddNew"),
    key: "newTile",
    type: intl.formatMessage(_defineProperty({
      defaultMessage: "address",
      "id": "@next.components.organisms.AddressGridSelector.AddressGridSelector.2132027491"
    }, "id", "@next.components.organisms.AddressGridSelector.AddressGridSelector.2132027491")),
    onClick: function onClick() {
      newAddress();
    },
    tileType: "addNewCheckout",
    selectShippingAddress: true
  });

  var selectAddress = function selectAddress(address, id) {
    onSelect(address, id);
  };

  var newUserAddresses = addresses.slice(0, 1);
  var addAddress = React.useMemo(function () {
    var _newUserAddresses$, _newUserAddresses$$ad, _newUserAddresses$$ad2, _user$defaultShipping, _user$defaultShipping2, _newUserAddresses$2, _newUserAddresses$2$a, _user$defaultShipping3, _newUserAddresses$3, _newUserAddresses$3$a, _newUserAddresses$4, _newUserAddresses$4$a, _user$defaultShipping4, _newUserAddresses$5, _newUserAddresses$5$a, _user$defaultShipping5, _newUserAddresses$6, _newUserAddresses$6$a, _newUserAddresses$6$a2, _user$defaultShipping6, _user$defaultShipping7, _newUserAddresses$7, _newUserAddresses$7$a, _newUserAddresses$8, _newUserAddresses$8$a, _user$defaultShipping8;

    return {
      address: {
        OtherAddress: true,
        city: ((_newUserAddresses$ = newUserAddresses[0]) === null || _newUserAddresses$ === void 0 ? void 0 : (_newUserAddresses$$ad = _newUserAddresses$.address) === null || _newUserAddresses$$ad === void 0 ? void 0 : (_newUserAddresses$$ad2 = _newUserAddresses$$ad.city2) === null || _newUserAddresses$$ad2 === void 0 ? void 0 : _newUserAddresses$$ad2.name) || (user === null || user === void 0 ? void 0 : (_user$defaultShipping = user.defaultShippingAddress) === null || _user$defaultShipping === void 0 ? void 0 : (_user$defaultShipping2 = _user$defaultShipping.city2) === null || _user$defaultShipping2 === void 0 ? void 0 : _user$defaultShipping2.name),
        city2: ((_newUserAddresses$2 = newUserAddresses[0]) === null || _newUserAddresses$2 === void 0 ? void 0 : (_newUserAddresses$2$a = _newUserAddresses$2.address) === null || _newUserAddresses$2$a === void 0 ? void 0 : _newUserAddresses$2$a.city2) || (user === null || user === void 0 ? void 0 : (_user$defaultShipping3 = user.defaultShippingAddress) === null || _user$defaultShipping3 === void 0 ? void 0 : _user$defaultShipping3.city2),
        companyName: ((_newUserAddresses$3 = newUserAddresses[0]) === null || _newUserAddresses$3 === void 0 ? void 0 : (_newUserAddresses$3$a = _newUserAddresses$3.address) === null || _newUserAddresses$3$a === void 0 ? void 0 : _newUserAddresses$3$a.companyName) || null,
        country: ((_newUserAddresses$4 = newUserAddresses[0]) === null || _newUserAddresses$4 === void 0 ? void 0 : (_newUserAddresses$4$a = _newUserAddresses$4.address) === null || _newUserAddresses$4$a === void 0 ? void 0 : _newUserAddresses$4$a.country) || (user === null || user === void 0 ? void 0 : (_user$defaultShipping4 = user.defaultShippingAddress) === null || _user$defaultShipping4 === void 0 ? void 0 : _user$defaultShipping4.country),
        country2: ((_newUserAddresses$5 = newUserAddresses[0]) === null || _newUserAddresses$5 === void 0 ? void 0 : (_newUserAddresses$5$a = _newUserAddresses$5.address) === null || _newUserAddresses$5$a === void 0 ? void 0 : _newUserAddresses$5$a.country2) || (user === null || user === void 0 ? void 0 : (_user$defaultShipping5 = user.defaultShippingAddress) === null || _user$defaultShipping5 === void 0 ? void 0 : _user$defaultShipping5.country2),
        countryArea: ((_newUserAddresses$6 = newUserAddresses[0]) === null || _newUserAddresses$6 === void 0 ? void 0 : (_newUserAddresses$6$a = _newUserAddresses$6.address) === null || _newUserAddresses$6$a === void 0 ? void 0 : (_newUserAddresses$6$a2 = _newUserAddresses$6$a.region2) === null || _newUserAddresses$6$a2 === void 0 ? void 0 : _newUserAddresses$6$a2.name) || (user === null || user === void 0 ? void 0 : (_user$defaultShipping6 = user.defaultShippingAddress) === null || _user$defaultShipping6 === void 0 ? void 0 : (_user$defaultShipping7 = _user$defaultShipping6.region2) === null || _user$defaultShipping7 === void 0 ? void 0 : _user$defaultShipping7.name),
        firstName: "",
        isDefaultBillingAddress: false,
        isDefaultShippingAddress: false || undefined,
        lastName: "",
        latitude: "0",
        longitude: "0",
        neighborhood: ((_newUserAddresses$7 = newUserAddresses[0]) === null || _newUserAddresses$7 === void 0 ? void 0 : (_newUserAddresses$7$a = _newUserAddresses$7.address) === null || _newUserAddresses$7$a === void 0 ? void 0 : _newUserAddresses$7$a.neighborhood) || {
          id: "",
          name: "Seleccionar un barrio"
        },
        phone: "",
        postalCode: "No definido",
        region2: ((_newUserAddresses$8 = newUserAddresses[0]) === null || _newUserAddresses$8 === void 0 ? void 0 : (_newUserAddresses$8$a = _newUserAddresses$8.address) === null || _newUserAddresses$8$a === void 0 ? void 0 : _newUserAddresses$8$a.region2) || (user === null || user === void 0 ? void 0 : (_user$defaultShipping8 = user.defaultShippingAddress) === null || _user$defaultShipping8 === void 0 ? void 0 : _user$defaultShipping8.region2),
        streetAddress1: "",
        streetAddress2: "",
        firstNameWrite: "",
        firstNameSelect: undefined
      }
    };
  }, [newUserAddresses]);
  var editAddress = React.useCallback(function () {
    setRedirectEdit(true);
    localStorage.setItem("address-undefined", "false");
  }, []);
  var newAddress = React.useCallback(function () {
    localStorage.setItem("address-user-id", userId || "");
    localStorage.setItem("address-data", JSON.stringify(addAddress));
    localStorage.setItem("address-neighborhood", JSON.stringify(neighborhoodsAttention));
    localStorage.setItem("address-name-options", JSON.stringify(nameOptions));
    localStorage.setItem("address-from", "checkout");
    navigate("/address/add");
  }, []);
  var validateAddress = React.useCallback(function (address) {
    if ((address === null || address === void 0 ? void 0 : address.streetAddress1) === "" || (address === null || address === void 0 ? void 0 : address.streetAddress1) === undefined || (address === null || address === void 0 ? void 0 : address.streetAddress1.toLowerCase()) === "no definida") {
      localStorage.setItem("address-validate-edit", "true");
      localStorage.setItem("address-undefined", "true");
      editAddress();
    } else {
      localStorage.setItem("address-undefined", "false");
    }

    if (localStorage.getItem("address-validate-edit") !== "true" && localStorage.getItem("select-address-inprogress") === null) {
      localStorage.setItem("address-validate-edit", "false");

      if (!displayShowAddress) {
        setdisplayShowAddress(true);
      }
    }
  }, []);
  useEffect(function () {
    if (redirectEdit) {
      localStorage.setItem("address-undefined", "true");
      localStorage.setItem("address-validate-edit", "true");
      localStorage.setItem("address-user-id", "");
      localStorage.setItem("address-from", "checkout");
      localStorage.setItem("address-data", JSON.stringify(addressData));
      localStorage.setItem("address-neighborhood", JSON.stringify(neighborhoodsAttention));
      localStorage.setItem("address-name-options", JSON.stringify(nameOptionsSelect));
      navigate("/address/edit");
    }
  }, [redirectEdit]);
  useEffect(function () {
    var getAddressId = localStorage.getItem("address-id");
    var addressData = localStorage.getItem("address-data");
    var address = {};

    if (addressData !== null && addressData !== "" && addressData !== "undefined") {
      address = JSON.parse(addressData || "");
    }

    if (getAddressId !== null && getAddressId !== "" && address !== undefined && localStorage.getItem("hasSelectedAddress") === null) {
      localStorage.setItem("hasSelectedAddress", "true");
      selectAddress(address, getAddressId);
    }

    setnameOptionsSelect(nameOptions);

    if (localStorage.getItem("flag_address") === null && localStorage.getItem("flag_address") !== "true" && localStorage.getItem("address-validate-edit") !== "true") {
      var _obligatoryFieldIdent, _obligatoryFieldIdent2, _obligatoryFieldIdent3, _obligatoryFieldIdent4, _obligatoryFieldIdent5, _obligatoryFieldIdent6, _obligatoryFieldIdent7, _obligatoryFieldIdent8, _obligatoryFieldIdent9;

      if ((addresses === null || addresses === void 0 ? void 0 : addresses.length) === 1) {
        var _addresses$, _addresses$2, _addresses$2$address, _addresses$3, _addresses$3$address, _addresses$4, _addresses$4$address;

        setAddress((_addresses$ = addresses[0]) === null || _addresses$ === void 0 ? void 0 : _addresses$.address);

        if (((_addresses$2 = addresses[0]) === null || _addresses$2 === void 0 ? void 0 : (_addresses$2$address = _addresses$2.address) === null || _addresses$2$address === void 0 ? void 0 : _addresses$2$address.streetAddress1) === "" || ((_addresses$3 = addresses[0]) === null || _addresses$3 === void 0 ? void 0 : (_addresses$3$address = _addresses$3.address) === null || _addresses$3$address === void 0 ? void 0 : _addresses$3$address.streetAddress1) === undefined || ((_addresses$4 = addresses[0]) === null || _addresses$4 === void 0 ? void 0 : (_addresses$4$address = _addresses$4.address) === null || _addresses$4$address === void 0 ? void 0 : _addresses$4$address.streetAddress1.toUpperCase()) === "NO DEFINIDA") {
          localStorage.setItem("address-undefined", "true");
          setRedirectEdit(true);
        } else {
          localStorage.setItem("address-undefined", "false");
        }

        if (localStorage.getItem("address-validate-edit") !== "true" && localStorage.getItem("select-address-inprogress") === null) {
          localStorage.setItem("address-validate-edit", "false");

          if (!displayShowAddress) {
            setdisplayShowAddress(true);
          }
        }
      } else {
        addresses.forEach(function (address) {
          var _address$address, _user$defaultShipping9;

          if ((address === null || address === void 0 ? void 0 : (_address$address = address.address) === null || _address$address === void 0 ? void 0 : _address$address.id) === (user === null || user === void 0 ? void 0 : (_user$defaultShipping9 = user.defaultShippingAddress) === null || _user$defaultShipping9 === void 0 ? void 0 : _user$defaultShipping9.id)) {
            setAddress(address === null || address === void 0 ? void 0 : address.address);
            validateAddress(address === null || address === void 0 ? void 0 : address.address);
          }
        });
      }

      if (!(user === null || user === void 0 ? void 0 : user.hasConfirmedName) || (!(user === null || user === void 0 ? void 0 : user.identityCard) || (user === null || user === void 0 ? void 0 : user.identityCard) === "" || (user === null || user === void 0 ? void 0 : user.identityCard) === "null" || (user === null || user === void 0 ? void 0 : user.identityCard) === undefined) && obligatoryFieldIdentityCard && obligatoryFieldIdentityCard.length > 0 && ((_obligatoryFieldIdent = obligatoryFieldIdentityCard[0]) === null || _obligatoryFieldIdent === void 0 ? void 0 : _obligatoryFieldIdent.fieldName) && ((_obligatoryFieldIdent2 = obligatoryFieldIdentityCard[0]) === null || _obligatoryFieldIdent2 === void 0 ? void 0 : _obligatoryFieldIdent2.fieldName) !== "" && ((_obligatoryFieldIdent3 = obligatoryFieldIdentityCard[0]) === null || _obligatoryFieldIdent3 === void 0 ? void 0 : _obligatoryFieldIdent3.fieldName.toLowerCase()) === "identity_card" && ((_obligatoryFieldIdent4 = obligatoryFieldIdentityCard[0]) === null || _obligatoryFieldIdent4 === void 0 ? void 0 : _obligatoryFieldIdent4.role) && ((_obligatoryFieldIdent5 = obligatoryFieldIdentityCard[0]) === null || _obligatoryFieldIdent5 === void 0 ? void 0 : _obligatoryFieldIdent5.role) !== "" && ((_obligatoryFieldIdent6 = obligatoryFieldIdentityCard[0]) === null || _obligatoryFieldIdent6 === void 0 ? void 0 : _obligatoryFieldIdent6.role.toLowerCase()) === "customer" && ((_obligatoryFieldIdent7 = obligatoryFieldIdentityCard[0]) === null || _obligatoryFieldIdent7 === void 0 ? void 0 : _obligatoryFieldIdent7.modelName) && ((_obligatoryFieldIdent8 = obligatoryFieldIdentityCard[0]) === null || _obligatoryFieldIdent8 === void 0 ? void 0 : _obligatoryFieldIdent8.modelName) !== "" && ((_obligatoryFieldIdent9 = obligatoryFieldIdentityCard[0]) === null || _obligatoryFieldIdent9 === void 0 ? void 0 : _obligatoryFieldIdent9.modelName.toLowerCase()) === "user") {
        localStorage.setItem("address-undefined", "true");
        setRedirectEdit(true);
      }
    }

    return function () {};
  }, []);
  var initialValuesConfir = React.useCallback(function () {
    if (addresses.length === 1) {
      var _addresses$5, _addresses$5$address;

      return {
        addressTileOption: (_addresses$5 = addresses[0]) === null || _addresses$5 === void 0 ? void 0 : (_addresses$5$address = _addresses$5.address) === null || _addresses$5$address === void 0 ? void 0 : _addresses$5$address.id
      };
    }

    return {
      addressTileOption: selectedAddressId
    };
  }, [addresses, selectedAddressId]);
  var setAddress = React.useCallback(function (address) {
    var _firstNameSelect3;

    var firstNameWrite = "";
    var firstNameSelect = {
      id: intl.formatMessage(checkoutMessages.myHome),
      name: intl.formatMessage(checkoutMessages.myHome)
    };

    if ((address === null || address === void 0 ? void 0 : address.firstName) !== null && (address === null || address === void 0 ? void 0 : address.firstName) !== "") {
      firstNameSelect = {
        id: address === null || address === void 0 ? void 0 : address.firstName,
        name: address === null || address === void 0 ? void 0 : address.firstName
      };
    }

    if ((nameOptions === null || nameOptions === void 0 ? void 0 : nameOptions.findIndex(function (nameoptionDefault) {
      var _firstNameSelect;

      if (((_firstNameSelect = firstNameSelect) === null || _firstNameSelect === void 0 ? void 0 : _firstNameSelect.id) !== undefined) {
        var _firstNameSelect2;

        return nameoptionDefault === null || nameoptionDefault === void 0 ? void 0 : nameoptionDefault.id.includes((_firstNameSelect2 = firstNameSelect) === null || _firstNameSelect2 === void 0 ? void 0 : _firstNameSelect2.id);
      }
    })) === -1 && (address === null || address === void 0 ? void 0 : address.firstName) !== null && (address === null || address === void 0 ? void 0 : address.firstName) !== "") {
      setnameOptionsSelect([].concat(_toConsumableArray(nameOptionsSelect), [firstNameSelect]));
    }

    setAddressData({
      address: {
        city: address === null || address === void 0 ? void 0 : address.city,
        city2: address === null || address === void 0 ? void 0 : address.city2,
        companyName: address === null || address === void 0 ? void 0 : address.companyName,
        country: address === null || address === void 0 ? void 0 : address.country,
        country2: address === null || address === void 0 ? void 0 : address.country2,
        countryArea: address === null || address === void 0 ? void 0 : address.countryArea,
        desktopView: (address === null || address === void 0 ? void 0 : address.desktopView) !== undefined,
        desktopViewThirdElement: address === null || address === void 0 ? void 0 : address.desktopViewThirdElement,
        firstNameSelect: ((_firstNameSelect3 = firstNameSelect) === null || _firstNameSelect3 === void 0 ? void 0 : _firstNameSelect3.id) === undefined ? undefined : firstNameSelect,
        firstNameWrite: firstNameWrite === undefined ? "" : firstNameWrite,
        firstName: address === null || address === void 0 ? void 0 : address.firstName,
        id: address === null || address === void 0 ? void 0 : address.id,
        isDefaultBillingAddress: address === null || address === void 0 ? void 0 : address.isDefaultBillingAddress,
        isDefaultShippingAddress: (address === null || address === void 0 ? void 0 : address.isDefaultShippingAddress) || undefined,
        lastName: address === null || address === void 0 ? void 0 : address.lastName,
        latitude: address === null || address === void 0 ? void 0 : address.latitude,
        longitude: address === null || address === void 0 ? void 0 : address.longitude,
        neighborhood: address === null || address === void 0 ? void 0 : address.neighborhood,
        phone: address === null || address === void 0 ? void 0 : address.phone,
        postalCode: address === null || address === void 0 ? void 0 : address.postalCode,
        region2: address === null || address === void 0 ? void 0 : address.region2,
        streetAddress1: (address === null || address === void 0 ? void 0 : address.streetAddress1.toLowerCase()) === "no definida" ? "" : address === null || address === void 0 ? void 0 : address.streetAddress1,
        streetAddress2: address === null || address === void 0 ? void 0 : address.streetAddress2
      },
      id: address === null || address === void 0 ? void 0 : address.id
    });
  }, []);
  useEffect(function () {
    if (errors && (errors === null || errors === void 0 ? void 0 : errors.length) > 0) {
      setShowErrors(true);
    }

    return function () {
      setShowErrors(false);
    };
  }, [errors]);
  return React.createElement(React.Fragment, null, React.createElement(Formik, {
    initialValues: initialValuesConfir(),
    enableReinitialize: true,
    onSubmit: function onSubmit(values, _ref2) {
      var setSubmitting = _ref2.setSubmitting;

      if (onSelect) {
        onSelect(undefined, values.addressTileOption);
      }

      setSubmitting(false);
    }
  }, function (_ref3) {
    var handleChange = _ref3.handleChange,
        handleSubmit = _ref3.handleSubmit,
        handleBlur = _ref3.handleBlur,
        values = _ref3.values,
        setFieldValue = _ref3.setFieldValue,
        setFieldTouched = _ref3.setFieldTouched;
    return React.createElement("form", {
      id: formId,
      ref: formRef,
      onSubmit: handleSubmit
    }, React.createElement(TileGrid, {
      columns: 1,
      elements: addresses.reduce(function (elements, _ref4, index) {
        var id = _ref4.id,
            address = _ref4.address;

        if (index === 0) {
          elements.push(React.createElement(React.Fragment, null, React.createElement("p", null, React.createElement(FormattedMessage, checkoutMessages.selectShippingAddress)), React.createElement(AddressTileOption, {
            testingContext: testingContext,
            "data-test": "".concat(testingContext, "AddressTileOption"),
            "data-test-id": index,
            key: "addressTile-".concat(id),
            id: id,
            inputName: "addressTileOption",
            address: address,
            defaultAddress: true,
            onChange: function onChange() {
              setFieldValue("addressTileOption", id); // selectAddress(id);
            },
            onClick: function onClick() {
              setAddress(address);
              validateAddress(address);
            },
            checked: !!values.addressTileOption && values.addressTileOption === id
          })));
        } else {
          elements.push(React.createElement(AddressTileOption, {
            testingContext: testingContext,
            "data-test": "".concat(testingContext, "AddressTileOption"),
            "data-test-id": index,
            key: "addressTile-".concat(id),
            id: id,
            inputName: "addressTileOption",
            address: address,
            defaultAddress: false,
            onChange: function onChange() {
              setFieldValue("addressTileOption", id); // selectAddress(id);
            },
            onClick: function onClick() {
              setAddress(address);
              validateAddress(address);
            },
            checked: !!values.addressTileOption && values.addressTileOption === id
          }));
        }

        return elements;
      }, [addNewTile])
    }), addresses.length === 0 && React.createElement(S.Parragraph, null, " ", "Lo sentimos, actualmente el establecimiento no cuenta con domicilios a las direcciones que tienes registradas. Para continuar con el pedido debes agregar una nueva direcci\xF3n"));
  }), displayShowAddress && React.createElement(AddressCheckout, {
    address: addressData,
    hideModal: function hideModal() {
      setdisplayShowAddress(false);
    },
    openEdit: function openEdit() {
      setdisplayShowAddress(false);
      editAddress();
    },
    selectData: function selectData() {
      setdisplayShowAddress(false);
      selectAddress(addressData || undefined, addressData === null || addressData === void 0 ? void 0 : addressData.id);
    }
  }), showErrors && React.createElement(AlertCheckoutErrors, {
    hideModal: function hideModal() {
      setShowErrors(false);
    },
    errors: errors
  }));
});
AddressGridSelector.displayName = "AddressGridSelector";
export { AddressGridSelector };