import React from "react";
import { FormattedMessage } from "react-intl";
import ReactSVG from "react-svg";
import Media from "react-responsive"; // import { Address, DropdownMenu, IconButton, Tile } from "@components/atoms";

import { Address, Tile } from "@components/atoms";
import { xMediumScreen } from "@styles/constants";
import * as S from "./styles";
import removeImg from "../../../../images/basurita20x20.svg";
import editImg from "../../../../images/edit.svg";
export var AddressTile = function AddressTile(_ref) {
  var onEdit = _ref.onEdit,
      onRemove = _ref.onRemove,
      setDefault = _ref.setDefault,
      address = _ref.address;
  // const intl = useIntl();
  var headerTitle = React.createElement(S.TypeAddress, null, (address === null || address === void 0 ? void 0 : address.OtherAddress) && React.createElement("h3", null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.AddressTile.AddressTile.1613626570",
    defaultMessage: "Other Address"
  })), address.isDefaultShippingAddress && React.createElement("h2", null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.AddressTile.AddressTile.4109348993",
    defaultMessage: "Default Shipping Address"
  })));
  var header = React.createElement(S.HeaderContent, null, address.firstName ? address.firstName : React.createElement("div", null), !address.isDefaultShippingAddress && React.createElement(S.ButtonDefault, null, React.createElement("button", {
    onClick: function onClick() {
      return setDefault("SHIPPING");
    }
  }, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.AddressTile.AddressTile.2328621588",
    defaultMessage: "By Default"
  }))));
  var footer = React.createElement(S.FooterContent, null, React.createElement(S.FooterEdit, null, React.createElement(ReactSVG, {
    path: editImg,
    onClick: onEdit
  })), !address.isDefaultShippingAddress && React.createElement(S.FooterTrash, null, React.createElement(ReactSVG, {
    path: removeImg,
    "data-test": "deleteButton",
    onClick: onRemove
  })));
  var content = React.createElement(Address, address);
  return React.createElement(React.Fragment, null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, function (matches) {
    return matches ? address.desktopView ? React.createElement(S.WrapperMargin, {
      "data-test-billing-default": address.isDefaultBillingAddress,
      "data-test-shipping-default": address.isDefaultShippingAddress
    }, React.createElement(Tile, {
      footer: footer,
      header: header,
      headerTitle: headerTitle,
      addressType: true
    }, content)) : address.desktopViewThirdElement ? React.createElement(S.WrapperThirdElement, {
      "data-test-billing-default": address.isDefaultBillingAddress,
      "data-test-shipping-default": address.isDefaultShippingAddress
    }, React.createElement(Tile, {
      footer: footer,
      header: header,
      headerTitle: headerTitle,
      addressType: true
    }, content)) : React.createElement(S.Wrapper, {
      "data-test-billing-default": address.isDefaultBillingAddress,
      "data-test-shipping-default": address.isDefaultShippingAddress
    }, React.createElement(Tile, {
      footer: footer,
      header: header,
      headerTitle: headerTitle,
      addressType: true
    }, content)) : React.createElement(S.Wrapper, {
      "data-test-billing-default": address.isDefaultBillingAddress,
      "data-test-shipping-default": address.isDefaultShippingAddress
    }, React.createElement(Tile, {
      footer: footer,
      header: header,
      headerTitle: headerTitle,
      addressType: true
    }, content));
  }));
};