export var useCategoriesData = function useCategoriesData(homeProductsData) {
  var _homeProductsData$hom;

  var categoriesData = [];
  homeProductsData === null || homeProductsData === void 0 ? void 0 : (_homeProductsData$hom = homeProductsData.homeProducts) === null || _homeProductsData$hom === void 0 ? void 0 : _homeProductsData$hom.forEach(function (productVariant) {
    if (productVariant === null || productVariant === void 0 ? void 0 : productVariant.categoryIdLightLoad) {
      var indexCategoriesData = categoriesData === null || categoriesData === void 0 ? void 0 : categoriesData.findIndex(function (categories) {
        return (categories === null || categories === void 0 ? void 0 : categories.id) === (productVariant === null || productVariant === void 0 ? void 0 : productVariant.categoryIdLightLoad);
      });

      if (indexCategoriesData === -1 || (categoriesData === null || categoriesData === void 0 ? void 0 : categoriesData.length) === 0) {
        var _productVariant$categ, _productVariant$categ2, _productVariant$categ3;

        categoriesData === null || categoriesData === void 0 ? void 0 : categoriesData.push({
          backgroundImage: (productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$categ = productVariant.categoryOrderTradeLightLoad[0]) === null || _productVariant$categ === void 0 ? void 0 : _productVariant$categ.backgroundImage) || (productVariant === null || productVariant === void 0 ? void 0 : productVariant.categoryBackgroundImageLightLoad),
          id: productVariant === null || productVariant === void 0 ? void 0 : productVariant.categoryIdLightLoad,
          name: (productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$categ2 = productVariant.categoryOrderTradeLightLoad[0]) === null || _productVariant$categ2 === void 0 ? void 0 : _productVariant$categ2.name) || (productVariant === null || productVariant === void 0 ? void 0 : productVariant.categoryNameLightLoad),
          orderIndex: (productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$categ3 = productVariant.categoryOrderTradeLightLoad[0]) === null || _productVariant$categ3 === void 0 ? void 0 : _productVariant$categ3.orderIndex) || (productVariant === null || productVariant === void 0 ? void 0 : productVariant.categoryOrderLightLoad) || 1000000000
        });
      }
    }
  });
  return {
    sendCategories: {
      categories: categoriesData.sort(function (a, b) {
        return (a === null || a === void 0 ? void 0 : a.orderIndex) - (b === null || b === void 0 ? void 0 : b.orderIndex);
      })
    }
  };
};