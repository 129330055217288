import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from "react";
export var useProductVariantsAttributesValuesSelection = function useProductVariantsAttributesValuesSelection(productVariantsAttributes) {
  var _useState = useState({}),
      _useState2 = _slicedToArray(_useState, 2),
      productVariantsAttributesSelectedValues = _useState2[0],
      setProductVariantsAttributesSelectedValues = _useState2[1];

  useEffect(function () {
    var variableAttributesSelectedValue = {};
    Object.keys(productVariantsAttributes).forEach(function (productVariantsAttributeId) {
      variableAttributesSelectedValue[productVariantsAttributeId] = null;
    });
    return function () {};
  }, []);

  var selectProductVariantsAttributesValue = function selectProductVariantsAttributesValue(selectedProductVariantsAttributeId, selectedProductVariantsAttributeValue) {
    setProductVariantsAttributesSelectedValues(function (prevVariantsAttributesSelectedValue) {
      var newVariantsAttributesSelectedValue = {};
      Object.keys(productVariantsAttributes).forEach(function (productVariantsAttributeId) {
        if (productVariantsAttributeId === selectedProductVariantsAttributeId) {
          var selectedValue = null;

          if (selectedProductVariantsAttributeValue) {
            selectedValue = productVariantsAttributes[productVariantsAttributeId].values.find(function (value) {
              return value.value === selectedProductVariantsAttributeValue;
            }) || null;
          }

          newVariantsAttributesSelectedValue[productVariantsAttributeId] = selectedValue;
        } else {
          newVariantsAttributesSelectedValue[productVariantsAttributeId] = prevVariantsAttributesSelectedValue[productVariantsAttributeId];
        }
      });
      return newVariantsAttributesSelectedValue;
    });
  };

  return [productVariantsAttributesSelectedValues, selectProductVariantsAttributesValue];
};