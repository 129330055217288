import React from "react";
import { FormattedMessage } from "react-intl";
import ReactSVG from "react-svg";
import Media from "react-responsive";
import { xMediumScreen } from "@styles/constants";
import { StatusData, StatusDataFooter } from "@components/molecules";
import { statusMessages } from "@temp/intl";
import * as S from "./styles";
import waitingImg from "../../../../images/delivered.svg";
import goBackImg from "../../../../images/arrowBack.svg";

/**
 * delivered status structure
 */
var StatusDelivered = function StatusDelivered(_ref) {
  var data = _ref.data,
      goBack = _ref.goBack;
  return React.createElement(S.Wrapper, null, React.createElement(S.Tile, null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(S.Title, {
    "data-test": "checkoutPageSubtitle"
  }, React.createElement(S.BackTitle, {
    onClick: goBack
  }, React.createElement(ReactSVG, {
    path: goBackImg
  }), React.createElement(S.BackTitleParragraph, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.StatusDelivered.StatusDelivered.3471378517",
    defaultMessage: "back"
  }))), React.createElement(FormattedMessage, statusMessages.stepNameDelivered)))), React.createElement(S.Content, null, React.createElement(Media, {
    maxWidth: xMediumScreen
  }, React.createElement(S.Title, {
    "data-test": "checkoutPageSubtitle"
  }, React.createElement(FormattedMessage, statusMessages.stepNameDelivered))), React.createElement(S.ContentContainer, null, React.createElement(S.Images, null, React.createElement(ReactSVG, {
    path: waitingImg
  })), React.createElement(S.Parragraph, {
    "data-test": "checkoutPageSubtitle"
  }, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.StatusDelivered.StatusDelivered.1937966122",
    defaultMessage: "the delivery man has delivered the order"
  })))), React.createElement(S.Aside, null, React.createElement(StatusData, {
    deliveryPhone: false,
    data: data,
    deliveryData: true
  })), React.createElement(S.Footer, null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(StatusDataFooter, {
    data: data
  }))));
};

export { StatusDelivered };