import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React from "react";
import { Money } from "../Money";
import { ShopContext } from "../../../../components/ShopProvider/context";
export var TaxedMoney = function TaxedMoney(_ref) {
  var taxedMoney = _ref.taxedMoney,
      defaultValue = _ref.defaultValue,
      quantity = _ref.quantity,
      basket = _ref.basket,
      props = _objectWithoutProperties(_ref, ["taxedMoney", "defaultValue", "quantity", "basket"]);

  var _React$useContext = React.useContext(ShopContext),
      displayGrossPrices = _React$useContext.displayGrossPrices;

  var money = taxedMoney ? displayGrossPrices ? taxedMoney.gross : taxedMoney.net : undefined;
  return React.createElement(Money, _extends({}, props, {
    money: money,
    quantity: quantity,
    basket: basket,
    defaultValue: defaultValue
  }));
};
TaxedMoney.displayName = "TaxedMoney";
export default TaxedMoney;