import * as React from "react";
import ReactSVG from "react-svg";
import { FormattedMessage } from "react-intl";
import { Button } from "../..";
import searchEmptyImg from "../../../images/noFoundOne.svg";
export var NothingFound = function NothingFound(_ref) {
  var search = _ref.search,
      overlay = _ref.overlay;
  return React.createElement("div", {
    className: "search__products--not-found"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.OverlayManager.Search.NothingFound.3166222023",
    defaultMessage: "Sorry, but we couldn\u2019t match any search results for: {search}",
    values: {
      search: search
    }
  })), React.createElement("div", {
    className: "search__products__blank__img--not-found"
  }, React.createElement(ReactSVG, {
    path: searchEmptyImg
  })), React.createElement("div", {
    className: "search__products__blank__button"
  }, React.createElement(Button, {
    testingContext: "emptyCartHideOverlayButton",
    secondary: true,
    onClick: overlay.hide
  }, React.createElement(FormattedMessage, {
    id: "components.OverlayManager.Search.NothingFound.3515754810",
    defaultMessage: "Continue Shopping"
  }))));
};
export default NothingFound;