import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "./scss/index.scss";
import * as React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useCart } from "@saleor/sdk";
import { commonMessages } from "@temp/intl";
import { useAppDispatch, useAppSelector, updateCategorySearchValue, updateSugcategorySearchValue } from "@temp/redux";
import { ProductList } from "../../@next/components/organisms";
import { OrderButton, ProductNoFound, ProductsFeatured } from "../../components";
import { maybe, generateCategoryProductUrl } from "../../core/utils";

var Page = function Page(_ref) {
  var maxProductsPerCategory = _ref.maxProductsPerCategory,
      redirect = _ref.redirect,
      category = _ref.category,
      products = _ref.products;

  var _useCart = useCart(),
      items = _useCart.items;

  var _useAppSelector = useAppSelector(function (state) {
    return state;
  }),
      _useAppSelector$searc = _useAppSelector.search,
      searchCategory = _useAppSelector$searc.searchCategory,
      searchQueryValue = _useAppSelector$searc.searchQueryValue,
      searchTitleHeight = _useAppSelector$searc.searchTitleHeight;

  var dispatch = useAppDispatch();
  var canDisplayProducts = maybe(function () {
    return !!products && products.products.length > 0;
  });
  var hasProducts = canDisplayProducts && products.products.length > 0;
  var intl = useIntl();

  var _React$useState = React.useState(0),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      scrollPosition = _React$useState2[0],
      setScrollPosition = _React$useState2[1];

  var _React$useState3 = React.useState(window.innerWidth <= 850 ? 160 : 250),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      marginTopState = _React$useState4[0],
      setMarginTopState = _React$useState4[1];

  var _useAppSelector2 = useAppSelector(function (store) {
    return store.tradeAttention;
  }),
      hasAttentionAtCurrentTime = _useAppSelector2.hasAttentionAtCurrentTime;

  var _handleClickTo = React.useCallback(function (subCategory) {
    localStorage.setItem("scroll-category", window.scrollY.toString());
    dispatch(updateSugcategorySearchValue({
      searchSubcategory: {
        name: subCategory.name,
        value: subCategory.id
      }
    }));
  }, []);

  React.useEffect(function () {
    var handleScroll = function handleScroll() {
      var position = window.pageYOffset;

      if (position > 0 && scrollPosition === 0 || position === 0 && scrollPosition > 0) {
        setScrollPosition(position);
      } else if (position <= 0) {
        setScrollPosition(0);
      }
    };

    window.addEventListener("scroll", handleScroll, {
      passive: true
    });
    return function () {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  React.useEffect(function () {
    var changeMarginTop = function changeMarginTop() {
      if (window.innerWidth <= 850 && marginTopState !== 160 + searchTitleHeight && hasAttentionAtCurrentTime) {
        setMarginTopState(160 + searchTitleHeight);
      } else if (window.innerWidth > 850 && marginTopState !== 250 + searchTitleHeight && hasAttentionAtCurrentTime) {
        setMarginTopState(250 + searchTitleHeight);
      } else if (window.innerWidth <= 850 && marginTopState !== 190 + searchTitleHeight && !hasAttentionAtCurrentTime) {
        setMarginTopState(190 + searchTitleHeight);
      } else if (window.innerWidth > 850 && marginTopState !== 270 + searchTitleHeight && !hasAttentionAtCurrentTime) {
        setMarginTopState(270 + searchTitleHeight);
      }
    };

    changeMarginTop();
    return function () {};
  }, [searchTitleHeight]);
  React.useEffect(function () {
    var changeMarginTop = function changeMarginTop() {
      if (window.innerWidth <= 850 && marginTopState !== 160 + searchTitleHeight && hasAttentionAtCurrentTime) {
        setMarginTopState(160 + searchTitleHeight);
      } else if (window.innerWidth > 850 && marginTopState !== 250 + searchTitleHeight && hasAttentionAtCurrentTime) {
        setMarginTopState(250 + searchTitleHeight);
      } else if (window.innerWidth <= 850 && marginTopState !== 190 + searchTitleHeight && !hasAttentionAtCurrentTime) {
        setMarginTopState(190 + searchTitleHeight);
      } else if (window.innerWidth > 850 && marginTopState !== 270 + searchTitleHeight && !hasAttentionAtCurrentTime) {
        setMarginTopState(270 + searchTitleHeight);
      }
    };

    changeMarginTop();
    window.addEventListener("resize", changeMarginTop);
    return function () {
      window.removeEventListener("resize", changeMarginTop);
    };
  }, []);
  React.useEffect(function () {
    var _products$products;

    if ((products === null || products === void 0 ? void 0 : (_products$products = products.products) === null || _products$products === void 0 ? void 0 : _products$products.length) > 0) {
      var scrollCategory = localStorage.getItem("scroll-category");
      var scrollProduct = localStorage.getItem("scroll-product");

      if (!scrollCategory && !scrollProduct) {
        window.scrollTo(0, 0);
      }

      if (scrollCategory !== null) {
        window.scrollTo(0, +scrollCategory);
        localStorage.removeItem("scroll-category");
      }

      if (scrollProduct !== null) {
        window.scrollTo(0, +scrollProduct);
        localStorage.removeItem("scroll-product");
      }
    }

    return function () {};
  }, [products.products.length]);
  React.useEffect(function () {
    if ((searchCategory.value.toUpperCase() === intl.formatMessage(commonMessages.all).toUpperCase() || searchCategory.value === "") && searchCategory.value !== category.id) {
      dispatch(updateCategorySearchValue({
        searchCategory: {
          name: category.name,
          value: category.id,
          subcategories: []
        }
      }));
    }
  }, [category]);
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "category"
  }, React.createElement("div", {
    className: "container"
  }, React.createElement("div", {
    className: "container-category",
    style: {
      marginTop: marginTopState
    }
  }, canDisplayProducts ? products.products.map(function (data, index) {
    return React.createElement("div", {
      key: "category-".concat(index)
    }, React.createElement("div", {
      className: "container-category__sign"
    }, React.createElement("h2", null, data.subCategory.name, "\xA0\xA0\xA0\xA0"), React.createElement("div", null, " ")), React.createElement(OrderButton, {
      amount: items !== undefined ? items.length : 0
    }), data.products.length > maxProductsPerCategory ? React.createElement(React.Fragment, null, React.createElement("div", {
      className: "container-category__see-more"
    }, React.createElement(Link, {
      onClick: function onClick() {
        _handleClickTo(data.subCategory);
      },
      to: generateCategoryProductUrl(data.subCategory.id, data.subCategory.name),
      key: category.id
    }, React.createElement(FormattedMessage, {
      id: "views.Category.Page.207418477",
      defaultMessage: "See all"
    }))), React.createElement(ProductList, {
      seeMore: true,
      handleClickTo: function handleClickTo() {
        _handleClickTo(data.subCategory);
      },
      products: data.products.slice(0, maxProductsPerCategory),
      to: generateCategoryProductUrl(data.subCategory.id, data.subCategory.name)
    })) : React.createElement(ProductList, {
      products: data.products
    }));
  }) : (searchQueryValue !== "" || searchCategory.value !== "") && React.createElement(ProductNoFound, null))), !hasProducts && searchQueryValue === "" && searchCategory.value === "" && React.createElement(ProductsFeatured, {
    title: intl.formatMessage(commonMessages.youMightLike)
  })));
};

export default Page;