import { Footer, MainMenu, SearchHeaderDesktop } from "@temp/components";
import { perPage } from "@temp/constants";
import * as React from "react";
import Media from "react-responsive";
import { useParams } from "react-router-dom";
import { xMediumScreen } from "@styles/constants";
import { useAppSelector } from "@temp/redux";
import { TypedSaleCategoryProductsListLoadMore } from "./queries";
import SaleCategoryContent from "./SaleCategoryContent";
var PRODUCTS_PER_PAGE = +perPage;
export var view = function view() {
  var _useAppSelector = useAppSelector(function (store) {
    return store;
  }),
      searchQueryValue = _useAppSelector.search.searchQueryValue;

  var _useParams = useParams(),
      id = _useParams.id,
      redirect = _useParams.redirect;

  var redirectView = redirect && true;
  return React.createElement(React.Fragment, null, redirectView ? React.createElement(MainMenu, {
    gobackCategoryRedirectSale: true,
    search: true,
    cart: true
  }) : React.createElement(MainMenu, {
    gobackCategoriesSale: true,
    search: true,
    cart: true
  }), React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(SearchHeaderDesktop, {
    saleCategory: true,
    redirect: redirectView
  })), React.createElement(TypedSaleCategoryProductsListLoadMore, {
    fetchPolicy: "".concat(localStorage.getItem("scroll-product") ? "cache-first" : "cache-and-network"),
    variables: {
      perPage: PRODUCTS_PER_PAGE,
      categoryId: +id,
      search: searchQueryValue
    }
  }, function (_ref) {
    var data = _ref.data,
        loading = _ref.loading,
        loadMore = _ref.loadMore;
    return React.createElement(SaleCategoryContent, {
      data: data.saleCategoryProductsLoadMore,
      loading: loading,
      loadMore: loadMore
    });
  }), React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(Footer, null)));
};
export default view;