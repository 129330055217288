import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Checkbox } from "@components/atoms";
import { Money } from "@components/containers";
import { checkoutMessages } from "@temp/intl";
import { AlertPromo } from "@temp/components";
import { useCart } from "@saleor/sdk";
import { DiscountForm } from "../DiscountForm";
import * as S from "./styles";

/**
 * Payment options used in checkout.
 */
var CheckoutPayment = function CheckoutPayment(_ref) {
  var _vouchersData$voucher;

  var applyPromoButtonRef = _ref.applyPromoButtonRef,
      promoCodeErrors = _ref.promoCodeErrors,
      promoCodeDiscountFormId = _ref.promoCodeDiscountFormId,
      promoCodeDiscountFormRef = _ref.promoCodeDiscountFormRef,
      promoCodeDiscount = _ref.promoCodeDiscount,
      inputPromoValue = _ref.inputPromoValue,
      showSetPromo = _ref.showSetPromo,
      pendingPromoCode = _ref.pendingPromoCode,
      paymentGatewayFormRef = _ref.paymentGatewayFormRef,
      submitInProgress = _ref.submitInProgress,
      changeSubmitProgress = _ref.changeSubmitProgress,
      onPaymentGatewayError = _ref.onPaymentGatewayError,
      setpendingPromoCode = _ref.setpendingPromoCode,
      setshowSetPromo = _ref.setshowSetPromo,
      setInputPromoValue = _ref.setInputPromoValue,
      setShowPromoCodeForm = _ref.setShowPromoCodeForm,
      showPromoCodeForm = _ref.showPromoCodeForm,
      addPromoCode = _ref.addPromoCode,
      removeVoucherCode = _ref.removeVoucherCode,
      submitUnchangedDiscount = _ref.submitUnchangedDiscount,
      loadingPromoCode = _ref.loadingPromoCode,
      handleShowVoucherList = _ref.handleShowVoucherList,
      vouchersData = _ref.vouchersData,
      vouchersLoading = _ref.vouchersLoading;

  var _useCart = useCart(),
      totalPrice = _useCart.totalPrice;

  useEffect(function () {
    var isVoucherCode = !!(promoCodeDiscount === null || promoCodeDiscount === void 0 ? void 0 : promoCodeDiscount.voucherCode);

    if (isVoucherCode && setShowPromoCodeForm) {
      setShowPromoCodeForm(isVoucherCode);
    }

    return function () {};
  }, [promoCodeDiscount === null || promoCodeDiscount === void 0 ? void 0 : promoCodeDiscount.voucherCode]);

  var handleChangeShowPromoCodeForm = function handleChangeShowPromoCodeForm() {
    var isVoucherCode = !!(promoCodeDiscount === null || promoCodeDiscount === void 0 ? void 0 : promoCodeDiscount.voucherCode);

    if (setShowPromoCodeForm && !isVoucherCode) {
      setShowPromoCodeForm(!showPromoCodeForm);
    }
  };

  var handleSubmitPromoCode = function handleSubmitPromoCode(discountForm, notSubmitForm) {
    var newPromoCode = discountForm === null || discountForm === void 0 ? void 0 : discountForm.promoCode;
    var savedPromoCode = promoCodeDiscount === null || promoCodeDiscount === void 0 ? void 0 : promoCodeDiscount.voucherCode;

    if ((!newPromoCode || newPromoCode && savedPromoCode && (newPromoCode === null || newPromoCode === void 0 ? void 0 : newPromoCode.toLowerCase()) === (savedPromoCode === null || savedPromoCode === void 0 ? void 0 : savedPromoCode.toLowerCase()) || !showPromoCodeForm) && savedPromoCode) {
      removeVoucherCode(savedPromoCode, notSubmitForm);
    } else if (newPromoCode && newPromoCode !== savedPromoCode) {
      addPromoCode(newPromoCode, notSubmitForm);
    } else {
      submitUnchangedDiscount();
    }
  };

  return React.createElement(S.Wrapper, null, React.createElement("section", null, React.createElement(S.Title, {
    "data-test": "checkoutPageSubtitle"
  }, React.createElement(FormattedMessage, checkoutMessages.paymentMethod)), React.createElement(Checkbox, {
    "data-test": "checkoutPaymentPromoCodeCheckbox",
    name: "payment-promo-code",
    checked: showPromoCodeForm,
    onChange: handleChangeShowPromoCodeForm
  }, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.CheckoutPayment.CheckoutPayment.778517627",
    defaultMessage: "Do you want to apply a discount?"
  })), showPromoCodeForm && React.createElement(React.Fragment, null, React.createElement(S.DiscountField, null, React.createElement(DiscountForm, {
    applyPromoButtonRef: applyPromoButtonRef,
    discount: {
      promoCode: promoCodeDiscount === null || promoCodeDiscount === void 0 ? void 0 : promoCodeDiscount.voucherCode
    },
    formId: promoCodeDiscountFormId,
    formRef: promoCodeDiscountFormRef,
    handleSubmit: handleSubmitPromoCode,
    errors: promoCodeErrors,
    setInputPromoValue: setInputPromoValue,
    showSetPromo: showSetPromo,
    inputPromoValue: inputPromoValue,
    loadingPromoCode: loadingPromoCode
  })), !vouchersLoading && (vouchersData === null || vouchersData === void 0 ? void 0 : vouchersData.vouchersAvailableToUser) && (vouchersData === null || vouchersData === void 0 ? void 0 : (_vouchersData$voucher = vouchersData.vouchersAvailableToUser) === null || _vouchersData$voucher === void 0 ? void 0 : _vouchersData$voucher.length) > 0 && React.createElement(S.AvailableDiscount, {
    onClick: handleShowVoucherList
  }, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.CheckoutPayment.CheckoutPayment.4069072846",
    defaultMessage: "Or select a discount "
  }), React.createElement(S.AvailableDiscountLink, {
    onClick: handleShowVoucherList
  }, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.CheckoutPayment.CheckoutPayment.2367602973",
    defaultMessage: "here"
  })))), React.createElement(S.Divider, null), React.createElement(S.TotalPrice, null, React.createElement(S.TotalPriceTitle, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.CheckoutPayment.CheckoutPayment.785948222",
    defaultMessage: "Total price"
  }), ": "), React.createElement(S.TotalPriceData, null, React.createElement(Money, {
    money: totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.net
  })))), showSetPromo && React.createElement(AlertPromo, {
    discountValue: inputPromoValue,
    hideModal: function hideModal() {
      if (setshowSetPromo) {
        setshowSetPromo(false);
      }
    },
    handleSubmit: handleSubmitPromoCode,
    errors: promoCodeErrors,
    pendingPromoCode: pendingPromoCode,
    setpendingPromoCode: setpendingPromoCode,
    paymentGatewayFormRef: paymentGatewayFormRef,
    changeSubmitProgress: changeSubmitProgress,
    onPaymentGatewayError: onPaymentGatewayError,
    submitInProgress: submitInProgress
  }));
};

export { CheckoutPayment };