import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject17() {
  var data = _taggedTemplateLiteral(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(255, 255, 255, 0.7);\n  z-index: 99999;\n"]);

  _templateObject17 = function _templateObject17() {
    return data;
  };

  return data;
}

function _templateObject16() {
  var data = _taggedTemplateLiteral(["\n  height: 3.8rem;\n  padding-top: 2rem;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  button {\n    width: 45%;\n  }\n  button:first-child {\n    height: 30px;\n    font-size: 0.87rem;\n    background: white;\n    color: #ed1c24;\n    border-radius: 10px;\n  }\n  button:last-child {\n    height: 30px;\n    padding: 0;\n    background: #ed1c24;\n    border-radius: 10px;\n    span {\n      font-size: 0.87rem;\n    }\n  }\n"]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n"]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = _taggedTemplateLiteral(["\n  width: 60%;\n  margin-top: 20px;\n\n  div {\n    border: none;\n    margin-bottom: 0rem;\n  }\n\n  div:hover {\n    border: none;\n    border-radius: 10px;\n    outline: none;\n  }\n\n  > div:first-of-type {\n    background-color: white;\n    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);\n  }\n\n  label {\n    background-color: white;\n  }\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\n  width: 60%;\n  margin-top: 20px;\n  div {\n    border: none;\n    border-radius: 10px;\n    margin-bottom: 0rem;\n  }\n\n  div:hover {\n    border: none;\n    outline: none;\n  }\n\n  input {\n    background-color: white;\n    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);\n  }\n\n  label {\n    background-color: white;\n  }\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n\n  > div {\n    width: 100%;\n  }\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n     width: 100%;\n  "]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  width: 50%;\n  ", "\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n    width: 100%;\n  "]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  width: 100%;\n  img {\n    width: 50px;\n    border-radius: 25px;\n  }\n  ", "\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  padding: 1.5rem;\n  margin-bottom: 1rem;\n  background-color: ", ";\n  border-radius: 10px;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  color: grey;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  border-bottom: none;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  width: 95%;\n  font-weight: ", ";\n  font-size: ", ";\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  @media (min-width: 850px) {\n    padding-bottom: 0px;\n    height: 1.3rem;\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  height: auto;\n  margin-bottom: 1.5rem;\n  @media (min-width: 850px) {\n    margin-top: 1.7rem;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  background: #bdbdbd;\n  height: 47px;\n  padding-top: 10px;\n  width: 55px;\n  cursor: pointer;\n  margin-bottom: 25px !important;\n  img {\n    margin-top: -10px;\n    border-radius: 10px;\n    width: 55px;\n    height: 47px;\n  }\n  input {\n    display: none;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  align-items: center;\n  justify-content: flex-start;\n  @media (min-width: 850px) {\n    width: 100%;\n    margin-top: 43px;\n    margin-left: 10px;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var AddImage = styled.div(_templateObject2());
export var TileWrapper = styled.div(_templateObject3());
export var Header = styled.div(_templateObject4(), function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.theme.typography.h4FontSize;
});
export var HeaderSmall = styled(Header)(_templateObject5());
export var HeaderSmallPersonal = styled.div(_templateObject6());
export var Content = styled.div(_templateObject7(), function (props) {
  return props.theme.tile.backgroundColor;
});
export var ContentOneLine = styled.div(_templateObject8(), media.smallScreen(_templateObject9()));
export var ContentEdit = styled.div(_templateObject10(), media.smallScreen(_templateObject11()));
export var ContentEditOneLine = styled.div(_templateObject12());
export var ContentExtendInput = styled.div(_templateObject13());
export var ContentExtendSelect = styled.div(_templateObject14());
export var Form = styled.form(_templateObject15(), function (props) {
  return props.theme.tile.backgroundColor;
});
export var FormButtons = styled.div(_templateObject16());
export var Loader = styled.div(_templateObject17());