import * as React from "react";
import { FormattedMessage } from "react-intl";
import ReactSVG from "react-svg";
import emptyImg from "../../../images/emptyBasket-v5.svg";
import { Button } from "../.."; // const emptyImg = require("../../../images/emptyBasket.svg");

var Empty = function Empty(_ref) {
  var overlayHide = _ref.overlayHide;
  return React.createElement("div", {
    className: "cart__empty"
  }, React.createElement("div", {
    className: "cart__empty__title"
  }, React.createElement("h4", null, React.createElement(FormattedMessage, {
    id: "components.OverlayManager.Cart.Empty.966560142",
    defaultMessage: "Your bag is empty"
  }))), React.createElement("div", {
    className: "cart__empty__img"
  }, React.createElement(ReactSVG, {
    path: emptyImg
  })), React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.OverlayManager.Cart.Empty.4121934554",
    defaultMessage: "You haven\u2019t added anything to your bag. We\u2019re sure you\u2019ll find something in our store"
  })), React.createElement("div", {
    className: "cart__empty__action"
  }, React.createElement(Button, {
    testingContext: "emptyCartHideOverlayButton",
    secondary: true,
    onClick: overlayHide
  }, React.createElement(FormattedMessage, {
    id: "components.OverlayManager.Cart.Empty.3515754810",
    defaultMessage: "Continue Shopping"
  }))));
};

export default Empty;