import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { ToppingTitle, ToppingValueMultiSelect, ToppingValueMultiSelectAdd } from "@components/molecules";
import React, { useEffect, useLayoutEffect } from "react";
import * as S from "./styles";
export var ToppingMulti = function ToppingMulti(_ref) {
  var _dataValues$sort;

  var checkMinTotalAmount = _ref.checkMinTotalAmount,
      dataValues = _ref.dataValues,
      dispatch = _ref.dispatch,
      dispatchToppingsPosition = _ref.dispatchToppingsPosition,
      idTopping = _ref.idTopping,
      index = _ref.index,
      isLastTopping = _ref.isLastTopping,
      maxSelected = _ref.maxSelected,
      minSelected = _ref.minSelected,
      name = _ref.name,
      obligatoryButton = _ref.obligatoryButton,
      slug = _ref.slug,
      totalAmount = _ref.totalAmount,
      toppingType = _ref.toppingType;
  var id = "toppingPosition-".concat(index);

  var _React$useState = React.useState(true),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      showValues = _React$useState2[0],
      setShowValues = _React$useState2[1];

  var _React$useState3 = React.useState(false),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      disabled = _React$useState4[0],
      setDisabled = _React$useState4[1];

  var _React$useState5 = React.useState(false),
      _React$useState6 = _slicedToArray(_React$useState5, 2),
      obligatory = _React$useState6[0],
      setObligatory = _React$useState6[1];

  var handleShowValues = React.useCallback(function () {
    setShowValues(function (value) {
      return !value;
    });
  }, [setShowValues]);
  var dataValuesOrderAsc = dataValues === null || dataValues === void 0 ? void 0 : (_dataValues$sort = dataValues.sort(function (a, b) {
    return a.nameValue.localeCompare(b.nameValue);
  })) === null || _dataValues$sort === void 0 ? void 0 : _dataValues$sort.sort(function (c, d) {
    return c.orderValue - d.orderValue;
  });
  useEffect(function () {
    if (totalAmount === maxSelected && !(maxSelected === 1 && toppingType.toUpperCase() === "MULTISELECT")) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [totalAmount]);
  useEffect(function () {
    dispatchToppingsPosition({
      type: "add-position",
      payload: {
        id: id,
        index: index,
        obligatory: obligatory
      }
    });
  }, []);
  useEffect(function () {
    if (checkMinTotalAmount !== obligatory) {
      dispatchToppingsPosition({
        type: "update-obligatory",
        payload: {
          id: id,
          index: index,
          obligatory: checkMinTotalAmount
        }
      });
      setObligatory(checkMinTotalAmount);
    }
  }, [checkMinTotalAmount]);
  useEffect(function () {
    var time = setTimeout(function () {
      dispatchToppingsPosition({
        type: "update-positions"
      });
    }, 500);
    return function () {
      clearTimeout(time);
    };
  }, [showValues]);
  useLayoutEffect(function () {
    var buttonWithLunch = document.getElementById("toppingPosition-".concat(index, "-withLunch"));

    if (buttonWithLunch) {
      buttonWithLunch.style.display = "none";
    }
  }, []);
  return React.createElement(S.Topping, null, React.createElement(ToppingTitle, {
    id: id,
    index: index,
    maxSelected: maxSelected,
    minSelected: minSelected,
    name: name,
    handleShowValues: handleShowValues,
    showValues: showValues
  }), showValues && toppingType.toUpperCase() === "MULTISELECT_ADD" && dataValuesOrderAsc.map(function (value, index) {
    return value.isActive && React.createElement(ToppingValueMultiSelectAdd, _extends({}, value, {
      light: !checkMinTotalAmount && obligatoryButton,
      key: value.idValue,
      disabled: disabled,
      dispatch: dispatch,
      idTopping: idTopping,
      isLastToppingValueAndTopping: isLastTopping && dataValues.length - 1 === index
    }));
  }), showValues && toppingType.toUpperCase() === "MULTISELECT" && dataValuesOrderAsc.map(function (value, index) {
    return value.isActive && React.createElement(ToppingValueMultiSelect, _extends({}, value, {
      light: !checkMinTotalAmount && obligatoryButton,
      key: value.idValue,
      disabled: disabled,
      dispatch: dispatch,
      idTopping: idTopping,
      isLastToppingValueAndTopping: isLastTopping && dataValues.length - 1 === index
    }));
  }));
};
ToppingMulti.displayName = "ToppingMulti";