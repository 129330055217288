import React from "react";
import { FormattedMessage } from "react-intl"; // import { commonMessages } from "@temp/intl";

import * as S from "./styles";

/**
 * Address summary
 */
var ObservationsSummary = function ObservationsSummary(_ref) {
  var address = _ref.address,
      email = _ref.email;

  if (address === null || address === void 0 ? void 0 : address.lastName) {
    return React.createElement(S.Label, null, React.createElement("span", null, React.createElement(FormattedMessage, {
      id: "@next.components.molecules.ObservationsSummary.ObservationsSummary.722592990",
      defaultMessage: "Observations"
    }), ":", " "), React.createElement("span", null, address.lastName), " ", React.createElement("br", null));
  }

  return React.createElement(React.Fragment, null);
};

export { ObservationsSummary };