import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "./scss/index.scss";
import * as React from "react";
import { MenuDelivery, SelectField } from "@temp/components";
import { useOrdersByUser, useOrdersAmountByUser } from "@saleor/sdk/";
import { perPage } from "@temp/constants";
import { updateAllDataFinished, hasntChangedOrderAmount, useAppDispatch, useAppSelector } from "@temp/redux";
import { useNavigate } from "react-router-dom";
import { OrderTabelDeliverySkeleton } from "@app/components/molecules";
import { OrderSortField } from "gqlTypes/globalTypes";
import { FormattedMessage, useIntl } from "react-intl";
import { orderMessages } from "@temp/intl";
import { OrderTableDelivery } from "@app/components/organisms";
var ORDERS_PER_APICALL = +perPage;
var finishedState = ["DELIVERED", "UNDELIVERED", "CANCELED_FULFILLMENT"];

var View = function View() {
  var _data$edges, _data$edges$, _data$edges$$node, _data$edges$2, _data$edges$2$node, _data$edges2, _data$edges2$node, _data$edges3, _data$edges3$node;

  var dispatch = useAppDispatch();
  var navigate = useNavigate();
  var intl = useIntl();

  var _useAppSelector = useAppSelector(function (store) {
    return store;
  }),
      deliveryOrderFinished = _useAppSelector.deliveryOrderFinished;

  var _React$useState = React.useState(true),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      loadingSkeleton = _React$useState2[0],
      setLoadingSkeleton = _React$useState2[1];

  var dateOptions = React.useMemo(function () {
    return [{
      label: intl.formatMessage(orderMessages.finishedDate),
      value: OrderSortField.FINISHED_DATE
    }, {
      label: intl.formatMessage(orderMessages.orderDate),
      value: OrderSortField.CREATION_DATE
    }];
  }, []);

  var _React$useState3 = React.useState(dateOptions[0].value),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      optionsSelectValue = _React$useState4[0],
      setOptionsSelectValue = _React$useState4[1];

  var _React$useState5 = React.useState(dateOptions[0]),
      _React$useState6 = _slicedToArray(_React$useState5, 2),
      dateSelectValueState = _React$useState6[0],
      setDateSelectValueState = _React$useState6[1];

  var _useOrdersByUser = useOrdersByUser({
    perPage: ORDERS_PER_APICALL,
    sortBy: {
      direction: "DESC",
      field: (dateSelectValueState === null || dateSelectValueState === void 0 ? void 0 : dateSelectValueState.value) === OrderSortField.FINISHED_DATE ? OrderSortField.FINISHED_DATE : OrderSortField.CREATION_DATE
    }
  }, {
    fetchPolicy: !!localStorage.getItem("status_page_finished_delivery") && !!localStorage.getItem("scroll_order_finished_delivery") && localStorage.getItem("status_page_finished_delivery") === "true" ? "cache-first" : "cache-and-network"
  }),
      data = _useOrdersByUser.data,
      loading = _useOrdersByUser.loading,
      loadMore = _useOrdersByUser.loadMore,
      refetch = _useOrdersByUser.refetch;

  var lenghtData = data === null || data === void 0 ? void 0 : (_data$edges = data.edges) === null || _data$edges === void 0 ? void 0 : _data$edges.length;

  var _useOrdersAmountByUse = useOrdersAmountByUser({
    fetchPolicy: "network-only"
  }),
      dataOrderAmount = _useOrdersAmountByUse.data,
      loadingOrderAmount = _useOrdersAmountByUse.loading,
      refetchOrderAmount = _useOrdersAmountByUse.refetch;

  React.useEffect(function () {
    if ((data === null || data === void 0 ? void 0 : data.edges.length) > 0 && !loading) {
      var newOrders = data === null || data === void 0 ? void 0 : data.edges.filter(function (order) {
        var _order$node, _order$node$status;

        return finishedState.includes(order === null || order === void 0 ? void 0 : (_order$node = order.node) === null || _order$node === void 0 ? void 0 : (_order$node$status = _order$node.status) === null || _order$node$status === void 0 ? void 0 : _order$node$status.toUpperCase());
      });
      dispatch(updateAllDataFinished({
        data: newOrders
      }));
    } else if ((data === null || data === void 0 ? void 0 : data.edges.length) === 0 && !loading) {
      dispatch(updateAllDataFinished({
        data: []
      }));
    }
  }, [loading, lenghtData, data === null || data === void 0 ? void 0 : (_data$edges$ = data.edges[0]) === null || _data$edges$ === void 0 ? void 0 : (_data$edges$$node = _data$edges$.node) === null || _data$edges$$node === void 0 ? void 0 : _data$edges$$node.number, data === null || data === void 0 ? void 0 : (_data$edges$2 = data.edges[0]) === null || _data$edges$2 === void 0 ? void 0 : (_data$edges$2$node = _data$edges$2.node) === null || _data$edges$2$node === void 0 ? void 0 : _data$edges$2$node.status, data === null || data === void 0 ? void 0 : (_data$edges2 = data.edges[lenghtData - 1]) === null || _data$edges2 === void 0 ? void 0 : (_data$edges2$node = _data$edges2.node) === null || _data$edges2$node === void 0 ? void 0 : _data$edges2$node.number, data === null || data === void 0 ? void 0 : (_data$edges3 = data.edges[lenghtData - 1]) === null || _data$edges3 === void 0 ? void 0 : (_data$edges3$node = _data$edges3.node) === null || _data$edges3$node === void 0 ? void 0 : _data$edges3$node.status]);
  React.useEffect(function () {
    if (deliveryOrderFinished.hasChangedOrderAmount) {
      refetchOrderAmount();
      dispatch(hasntChangedOrderAmount());
    }
  }, [deliveryOrderFinished.hasChangedOrderAmount]);

  var onFetchMore = function onFetchMore() {
    var _pageInfo;

    loadMore({
      after: (_pageInfo = data.pageInfo) === null || _pageInfo === void 0 ? void 0 : _pageInfo.endCursor,
      perPage: ORDERS_PER_APICALL,
      sortBy: {
        direction: "DESC",
        field: (dateSelectValueState === null || dateSelectValueState === void 0 ? void 0 : dateSelectValueState.value) === OrderSortField.FINISHED_DATE ? OrderSortField.FINISHED_DATE : OrderSortField.CREATION_DATE
      }
    });
  };

  React.useEffect(function () {
    if (location.search.includes("order-finished")) {
      refetch();
      navigate(location.pathname, {
        replace: true
      });
    }
  }, [location.search]);
  React.useEffect(function () {
    if (!loading && loadingSkeleton) {
      setLoadingSkeleton(false);
    } else if (loading && !loadingSkeleton && optionsSelectValue !== (dateSelectValueState === null || dateSelectValueState === void 0 ? void 0 : dateSelectValueState.value)) {
      setLoadingSkeleton(true);
      setOptionsSelectValue(dateSelectValueState === null || dateSelectValueState === void 0 ? void 0 : dateSelectValueState.value);
    }
  }, [dateSelectValueState === null || dateSelectValueState === void 0 ? void 0 : dateSelectValueState.value, loading]);
  React.useLayoutEffect(function () {
    var timer = setTimeout(function () {
      if (localStorage.getItem("scroll_order_finished_delivery") && localStorage.getItem("status_page_finished_delivery") && localStorage.getItem("status_page_finished_delivery") === "true") {
        window.scrollTo(0, Number(localStorage.getItem("scroll_order_finished_delivery")));
      }
    }, 0);
    return function () {
      clearTimeout(timer);
      localStorage.removeItem("status_page_finished_delivery");
      localStorage.setItem("scroll_order_finished_delivery", "".concat(scrollY));
    };
  }, []);
  return React.createElement(React.Fragment, null, React.createElement(MenuDelivery, {
    isLoadingFinishedAmount: loadingOrderAmount,
    finishedAmount: dataOrderAmount === null || dataOrderAmount === void 0 ? void 0 : dataOrderAmount.ordersAmount,
    handleFinishedRefetch: function handleFinishedRefetch() {
      refetch({
        perPage: ORDERS_PER_APICALL,
        sortBy: {
          direction: "DESC",
          field: (dateSelectValueState === null || dateSelectValueState === void 0 ? void 0 : dateSelectValueState.value) === OrderSortField.FINISHED_DATE ? OrderSortField.FINISHED_DATE : OrderSortField.CREATION_DATE
        }
      });
      setLoadingSkeleton(true);
    }
  }), React.createElement("div", {
    className: "selectOrderFinishedDelivery"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "views.OrderFinishedDelivery.View.3602970690",
    defaultMessage: "Sort by"
  }), ": "), React.createElement(SelectField, {
    className: "selectOrderFinishedDelivery__selectDate",
    value: dateSelectValueState,
    placeholder: "Select date type",
    options: dateOptions,
    isMulti: false,
    onChange: function onChange(value) {
      setDateSelectValueState(value);
    }
  })), (deliveryOrderFinished === null || deliveryOrderFinished === void 0 ? void 0 : deliveryOrderFinished.edges.length) === 0 && loading || loadingSkeleton ? React.createElement("div", {
    className: "OrderFinished"
  }, React.createElement("div", {
    className: "OrderFinished__table"
  }, React.createElement(OrderTabelDeliverySkeleton, null))) : React.createElement("div", {
    className: "OrderFinished"
  }, React.createElement("div", {
    className: "OrderFinished__table"
  }, React.createElement(OrderTableDelivery, {
    data: deliveryOrderFinished,
    dataLoadMore: data,
    inProgressFlag: false,
    loadMore: onFetchMore,
    loading: loading,
    deliveryMan: true
  }))));
};

export default View;