import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "./scss/index.scss";
import React, { useEffect, useState } from "react";
import ReactSVG from "react-svg";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Loader } from "@components/atoms";
import { commonMessages } from "@temp/intl";

var closeWhite = require("../../images/closewhite.svg");

var informationImg = require("../../images/information.svg"); // const greaterThanEqual = require("../../images/greaterThanEqual.svg");


export var AlertPromo = function AlertPromo(_ref) {
  var _errors$;

  var discountValue = _ref.discountValue,
      errors = _ref.errors,
      pendingPromoCode = _ref.pendingPromoCode,
      paymentGatewayFormRef = _ref.paymentGatewayFormRef,
      submitInProgress = _ref.submitInProgress,
      changeSubmitProgress = _ref.changeSubmitProgress,
      onPaymentGatewayError = _ref.onPaymentGatewayError,
      setpendingPromoCode = _ref.setpendingPromoCode,
      hideModal = _ref.hideModal,
      handleSubmit = _ref.handleSubmit;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      invalidCode = _useState2[0],
      setInvalidCode = _useState2[1];

  var _useState3 = useState(false),
      _useState4 = _slicedToArray(_useState3, 2),
      loading = _useState4[0],
      setLoading = _useState4[1];

  var intl = useIntl();

  var _handleSubmit = function _handleSubmit() {
    setLoading(true);
    setpendingPromoCode(true);
    handleSubmit({
      promoCode: discountValue
    }, false);
  };

  var closeModal = function closeModal() {
    hideModal();

    if (paymentGatewayFormRef.current) {
      paymentGatewayFormRef.current.dispatchEvent(new Event("submit", {
        cancelable: true
      }));
    } else {
      changeSubmitProgress(false);
      onPaymentGatewayError([{
        message: intl.formatMessage(commonMessages.choosePaymentMethod)
      }]);
    }
  };

  useEffect(function () {
    setInvalidCode(true);
    return function () {
      setInvalidCode(false);
    };
  }, [errors]);
  useEffect(function () {
    if (!pendingPromoCode && errors.length === 0) {
      hideModal();
    }

    return function () {
      hideModal();
    };
  }, [pendingPromoCode]);
  useEffect(function () {
    setInvalidCode(false);
    return function () {
      setInvalidCode(false);
    };
  }, []);
  useEffect(function () {
    if (!pendingPromoCode && !submitInProgress) {
      setLoading(false);
    }

    return function () {
      setLoading(false);
    };
  }, [pendingPromoCode, submitInProgress]);
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "showContainerPromo",
    onClick: hideModal
  }), React.createElement("div", {
    className: "showContainerPromo__data"
  }, React.createElement("div", {
    className: "showContainerPromo__data__close"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.AlertPromo.index.1262795626",
    defaultMessage: "Discount Code"
  })), React.createElement(ReactSVG, {
    path: closeWhite,
    onClick: hideModal
  })), React.createElement("div", {
    className: "showContainerPromo__data__content showContainerPromo__data__content--first"
  }), React.createElement("div", {
    className: "showContainerPromo__data__content  showContainerPromo__data__content--amount showContainerPromo__data__content--first showContainerPromo__data__content--last"
  }, React.createElement(FormattedMessage, {
    id: "components.AlertPromo.index.421071439",
    defaultMessage: "Do yo want to apply the discount code? <span>{discountValue}</span>",
    values: {
      discountValue: discountValue,
      span: function span() {
        for (var _len = arguments.length, chunks = new Array(_len), _key = 0; _key < _len; _key++) {
          chunks[_key] = arguments[_key];
        }

        return React.createElement("span", null, chunks);
      }
    }
  }), errors && invalidCode && React.createElement("div", {
    className: "showContainerPromo__data__content__error"
  }, React.createElement(ReactSVG, {
    path: informationImg
  }), " ", React.createElement("p", null, (_errors$ = errors[0]) === null || _errors$ === void 0 ? void 0 : _errors$.message))), React.createElement("div", {
    className: "showContainerPromo__data__footer"
  }, React.createElement(Button, {
    type: "button",
    testingContext: "",
    onClick: closeModal
  }, React.createElement(FormattedMessage, {
    id: "components.AlertPromo.index.2680335018",
    defaultMessage: "No"
  })), React.createElement(Button, {
    type: "button",
    testingContext: "",
    onClick: _handleSubmit
  }, React.createElement(FormattedMessage, {
    id: "components.AlertPromo.index.3308036799",
    defaultMessage: "Yes"
  })))), loading && React.createElement("div", {
    className: "showContainerPromo__loader"
  }, React.createElement(Loader, null)));
};
export default AlertPromo;