import "./scss/index.scss";
import React from "react";
import ReactSVG from "react-svg";
import { FormattedMessage } from "react-intl";
import { Button } from "@components/atoms";

var closeWhite = require("../../images/closewhite.svg"); // const greaterThanEqual = require("../../images/greaterThanEqual.svg");


export var AlertErrors = function AlertErrors(_ref) {
  var hideModal = _ref.hideModal,
      errors = _ref.errors;
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "showContainer",
    onClick: hideModal
  }), React.createElement("div", {
    className: "showContainer__data"
  }, React.createElement("div", {
    className: "showContainer__data__close"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.AlertErrors.index.709932324",
    defaultMessage: "Alert"
  })), React.createElement(ReactSVG, {
    path: closeWhite,
    onClick: hideModal
  })), React.createElement("div", {
    className: "showContainer__data__content showContainer__data__content--first"
  }), React.createElement("div", {
    className: "showContainer__data__content showContainer__data__content--value showContainer__data__content--first showContainer__data__content--last"
  }, React.createElement("ul", null, errors === null || errors === void 0 ? void 0 : errors.map(function (element, index) {
    return React.createElement("li", {
      key: index
    }, element.message);
  }))), React.createElement("div", {
    className: "showContainer__data__footer"
  }, React.createElement(Button, {
    type: "button",
    testingContext: "",
    onClick: hideModal
  }, React.createElement(FormattedMessage, {
    id: "components.AlertErrors.index.4075927731",
    defaultMessage: "Accept"
  })))));
};
export default AlertErrors;