import arrow from "../../images/SelectArrow.svg";
import gestionPedido from "../../images/gestionPedido.svg";
import reportes from "../../images/reportes.svg";
import reporteZonas from "../../images/reporteZonas.svg";

function createDeliveryMenuStructure(intl) {
  var menuStructure = [{
    ariaLabel: "orders",
    children: [],
    icon: gestionPedido,
    iconActive: "",
    iconActiveSize: 0,
    iconInactive: "",
    iconInactiveSize: 0,
    iconSize: 32,
    label: intl.formatMessage({
      id: "ordersManagement",
      defaultMessage: "Orders management"
    }),
    testingContextId: "orders",
    url: "/delivery"
  }];
  var reportsStructure = {
    ariaLabel: "reports",
    children: [{
      ariaLabel: "summary",
      icon: reporteZonas,
      iconActive: "",
      iconActiveSize: 0,
      iconInactive: "",
      iconInactiveSize: 0,
      iconSize: 22,
      label: intl.formatMessage({
        id: "summaryByArea",
        defaultMessage: "Summary by area"
      }),
      testingContextId: "summaryByArea",
      url: "/reports/summary-by-area/"
    }],
    icon: reportes,
    iconActive: arrow,
    iconActiveSize: 16,
    iconInactive: "icon-arrowRightGrey--0",
    iconInactiveSize: 20,
    iconSize: 32,
    label: intl.formatMessage({
      id: "reports",
      defaultMessage: "Reports"
    }),
    testingContextId: "reports",
    url: ""
  };
  return [].concat(menuStructure, [reportsStructure]);
}

export default createDeliveryMenuStructure;