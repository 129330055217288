import { useSubcategoriesAndCategoriesAllByTrade } from "@saleor/sdk";
import { useEffect } from "react";
import { updateSubcategoriesCategoriesAllLoaded, useAppDispatch, useAppSelector } from "@temp/redux";
import { organizeDataCategories } from "../utils";
export var useQueryAllCategoriesSubcategories = function useQueryAllCategoriesSubcategories() {
  var dispatch = useAppDispatch();

  var _useAppSelector = useAppSelector(function (store) {
    return store.search;
  }),
      subcategoriesCategoriesAllLoaded = _useAppSelector.subcategoriesCategoriesAllLoaded;

  var fetchPolicyValue = subcategoriesCategoriesAllLoaded ? "cache-first" : "network-only";

  var _useSubcategoriesAndC = useSubcategoriesAndCategoriesAllByTrade({
    fetchPolicy: "".concat(fetchPolicyValue)
  }),
      data = _useSubcategoriesAndC.data,
      loading = _useSubcategoriesAndC.loading;

  var organizeCategoriesData = organizeDataCategories(data === null || data === void 0 ? void 0 : data.subcategoriesCategoriesAllTrade);
  var categoriesData = Object.values(organizeCategoriesData);
  useEffect(function () {
    if (!loading && !subcategoriesCategoriesAllLoaded && (data === null || data === void 0 ? void 0 : data.subcategoriesCategoriesAllTrade.length) > 0) {
      dispatch(updateSubcategoriesCategoriesAllLoaded(true));
    }
  }, [loading]);
  return {
    categoriesDataJSON: organizeCategoriesData,
    categoriesData: categoriesData,
    loading: loading
  };
};