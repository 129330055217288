import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  display: block;\n  text-align: center;\n  button {\n    width: 200px;\n    height: 55px;\n    margin: 0 auto;\n    margin-top: 30px;\n    padding: 10px 5px;\n    border-radius: 10px;\n    background-color: #ffffff;\n    color: #ed1c24;\n    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);\n    text-align: center;\n    & > span {\n      width: 130px;\n      display: flex;\n      justify-content: space-around;\n      align-items: center;\n      margin: 0 auto;\n    }\n    & span span {\n      padding-top: 5px;\n      font-size: 1.1rem;\n    }\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  width: 200px;\n  height: 50px;\n  margin: 0 auto;\n  margin-top: 10px;\n  display: block;\n  border-radius: 10px;\n  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n  width: 127px;\n  text-align: center;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  & > div {\n    display: flex;\n  }\n  width: 100%;\n  display: flex;\n  justify-content: space between;\n  flex-direction: row;\n  flex-wrap: wrap;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var List = styled.div(_templateObject());
export var Link = styled.a(_templateObject2());
export var Loader = styled.div(_templateObject3());
export var Wrapper = styled.div(_templateObject4());