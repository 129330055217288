import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { categoriesSlice, deliveryCustomerSlice, deliveryOrdersAmountByUserSlice, deliveryOrdersInProgressSlice, deliveryOrdersFinishedSlice, ordersSlice, searchSlice, tradeAttentionSlice } from "./slices";
export var store = configureStore({
  reducer: {
    categories: categoriesSlice.reducer,
    deliveryCustomer: deliveryCustomerSlice.reducer,
    deliveryOrdersAmountByUser: deliveryOrdersAmountByUserSlice.reducer,
    deliveryOrderInProgress: deliveryOrdersInProgressSlice.reducer,
    deliveryOrderFinished: deliveryOrdersFinishedSlice.reducer,
    orders: ordersSlice.reducer,
    search: searchSlice.reducer,
    tradeAttention: tradeAttentionSlice.reducer
  }
});
export var useAppDispatch = useDispatch;
export var useAppSelector = useSelector;