import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  query SaleCategoryProductsList($amountByScreen: Int, $search: String) {\n    saleCategoryProducts(amountByScreen: $amountByScreen, search: $search) {\n      ...ProductsLightLoad\n      categoryOrderLightLoad\n      categoryOrderTradeLightLoad {\n        orderIndex\n        name\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedQuery } from "../../core/queries";
import { productsLightLoadFragment } from "../../core/fragments";
var saleCategoryProducts = gql(_templateObject(), productsLightLoadFragment);
export var TypedSaleCategoryProducts = TypedQuery(saleCategoryProducts);