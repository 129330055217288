import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\n  grid-area: toppings;\n  color: ", ";\n  font-size: ", ";\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n  grid-area: quantity;\n  color: ", ";\n  font-size: ", ";\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: center;\n  text-decoration: line-through;\n  color: #ed1c24;\n  span {\n    font-size: 0.87rem;\n  }\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: center;\n  font-size: ", ";\n  span {\n    font-size: 1rem;\n  }\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  grid-area: name;\n  font-size: ", ";\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  grid-area: sku;\n  color: ", ";\n  font-size: ", ";\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  width: 30px;\n  height: 32px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  top: 0px;\n  left: 0px;\n  font-size: 12px;\n  font-weight: bold;\n  color: white;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  width: 30px;\n  height: 30px;\n  position: relative;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  position: absolute;\n  top: -10px;\n  right: -10px;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  & > div {\n    width: 25px;\n    height: 23px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background: white;\n    box-shadow: 0px 0px 4px rgb(0 0 0 / 20%);\n    border-radius: 4px;\n  }\n  & svg {\n    width: 18px;\n    margin-left: 2px;\n    margin-top: 4px;\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  grid-area: photo;\n  width: inherit;\n  height: inherit;\n  position: relative;\n  img {\n    width: inherit;\n    height: inherit;\n    object-fit: contain;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  grid-area: pricePhoto;\n  & > a {\n    width: 65px;\n    height: 65px;\n    margin-bottom: 3px;\n    display: block;\n    box-shadow: 0px 0px 4px rgb(0 0 0 / 20%);\n    border-radius: 8px;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  grid-row-gap: 6px;\n  grid-column-gap: 20px;\n  grid-template-columns: 60px auto auto;\n  grid-template-areas:\n    \"pricePhoto name name\"\n    \"pricePhoto toppings toppings\"\n    \"pricePhoto quantity quantity\";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var ContainerPhoto = styled.div(_templateObject2());
export var Photo = styled.div(_templateObject3());
export var EditImg = styled.div(_templateObject4());
export var Discount = styled.div(_templateObject5());
export var DiscountValue = styled.div(_templateObject6());
export var DiscountValueSpan = styled.span(_templateObject7());
export var Sku = styled.div(_templateObject8(), function (props) {
  return props.theme.colors.baseFontColorSemiTransparent;
}, function (props) {
  return props.theme.typography.smallFontSize;
});
export var Name = styled.div(_templateObject9(), function (props) {
  return props.theme.typography.h4FontSize;
});
export var Price = styled.div(_templateObject10(), function (props) {
  return props.theme.typography.smallFontSize;
});
export var PriceUndiscounted = styled.div(_templateObject11());
export var Quantity = styled.div(_templateObject12(), function (props) {
  return props.theme.colors.baseFontColorSemiTransparent;
}, function (props) {
  return props.theme.typography.smallFontSize;
});
export var ToppingsName = styled.div(_templateObject13(), function (props) {
  return props.theme.colors.baseFontColorSemiTransparent;
}, function (props) {
  return props.theme.typography.smallFontSize;
});