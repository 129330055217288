import { createSlice } from "@reduxjs/toolkit";
var initialState = {
  categories: null
};
export var categoriesSlice = createSlice({
  name: "categories",
  initialState: initialState,
  reducers: {
    addCategories: function addCategories(state, action) {
      state.categories = action.payload.categories;
    }
  }
});
var addCategories = categoriesSlice.actions.addCategories;
export { addCategories };