import * as React from "react";
import Media from "react-media";
import { xMediumScreen } from "@styles/constants";
import { useAuth } from "@saleor/sdk";
import { AccountTab } from "@pages";
import AddressBook from "../../account/AddressBook/AddressBook";
export var AccountMedia = function AccountMedia() {
  var _useAuth = useAuth(),
      user = _useAuth.user;

  return React.createElement(Media, {
    query: {
      maxWidth: xMediumScreen
    }
  }, function (matches) {
    return matches ? React.createElement(React.Fragment, null, React.createElement(AccountTab, null)) : React.createElement(AddressBook, {
      user: user
    });
  });
};