import * as React from "react";
import Media from "react-responsive";
import { useParams } from "react-router-dom";
import { xMediumScreen } from "@styles/constants";
import { Footer, MainMenu, SearchHeaderDesktop } from "@temp/components";
import { ChangeAmountProductsContext } from "@temp/contexts";
import { useAppDispatch, useAppSelector, restoreSubCategory } from "@temp/redux";
import CategoryContent from "./CategoryContent";
import { TypedCategoryProductsList } from "./queries";
export var view = function view() {
  var _useParams = useParams(),
      id = _useParams.id,
      redirect = _useParams.redirect;

  var _useAppSelector = useAppSelector(function (store) {
    return store.search;
  }),
      searchQueryValue = _useAppSelector.searchQueryValue;

  var dispatch = useAppDispatch();

  var _React$useContext = React.useContext(ChangeAmountProductsContext),
      maxProductsPerCategory = _React$useContext.maxProductsPerCategory;

  var categoryIdRemove = JSON.parse(localStorage.getItem("category_products_cache")) || [];
  var fetchPolicyValue = categoryIdRemove.includes(id) ? "cache-first" : "cache-and-network";
  var redirectView = redirect && true;
  React.useEffect(function () {
    dispatch(restoreSubCategory());
  }, []);
  return React.createElement(React.Fragment, null, redirectView ? React.createElement(MainMenu, {
    search: true,
    cart: true
  }) : React.createElement(MainMenu, {
    gobackCategoriesSale: true,
    search: true,
    cart: true
  }), React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(SearchHeaderDesktop, {
    category: true,
    redirect: redirectView
  })), React.createElement(TypedCategoryProductsList, {
    fetchPolicy: "".concat(fetchPolicyValue),
    variables: {
      amountByScreen: maxProductsPerCategory + 1,
      categoryId: +id,
      search: searchQueryValue
    }
  }, function (_ref) {
    var data = _ref.data,
        loading = _ref.loading;
    return React.createElement(CategoryContent, {
      data: data,
      loading: loading,
      categoryIdRemove: categoryIdRemove,
      maxProductsPerCategory: maxProductsPerCategory
    });
  }), React.createElement(Footer, null));
};
export default view;