import React from "react";
import { FormattedMessage } from "react-intl";

var ReportViewDesktop = function ReportViewDesktop(props) {
  var _data$deliverymanSumm, _data$deliverymanSumm2;

  var data = props.data,
      totalQuantity = props.totalQuantity,
      changeFilter = props.changeFilter;
  return React.createElement("div", {
    className: changeFilter ? "overlayReport" : ""
  }, changeFilter && React.createElement("div", {
    className: "infoText alertBorder"
  }, React.createElement("p", null, React.createElement("span", {
    role: "img",
    "aria-label": "Pointing up"
  }, "\u261D\uFE0F")), React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "views.ReportSummaryByArea.ReportViewDesktop.3690815071",
    defaultMessage: "Tap the filter button to apply the change"
  }))), React.createElement("div", {
    className: "contentReportDesktop"
  }, React.createElement(FormattedMessage, {
    id: "views.ReportSummaryByArea.ReportViewDesktop.75546233",
    defaultMessage: "Amount"
  }), ":", " ", React.createElement("span", {
    className: "totalQuantity"
  }, totalQuantity), React.createElement("div", {
    className: "gridReportDesktop"
  }, React.createElement("div", {
    className: "headTableZone"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "views.ReportSummaryByArea.ReportViewDesktop.1611289554",
    defaultMessage: "Zones"
  }))), React.createElement("div", {
    className: "headTableAmount"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "views.ReportSummaryByArea.ReportViewDesktop.75546233",
    defaultMessage: "Amount"
  }))), (data === null || data === void 0 ? void 0 : (_data$deliverymanSumm = data.deliverymanSummaryByShippingZone) === null || _data$deliverymanSumm === void 0 ? void 0 : _data$deliverymanSumm.length) > 0 ? React.createElement(React.Fragment, null, data === null || data === void 0 ? void 0 : (_data$deliverymanSumm2 = data.deliverymanSummaryByShippingZone) === null || _data$deliverymanSumm2 === void 0 ? void 0 : _data$deliverymanSumm2.map(function (item, index) {
    return React.createElement(React.Fragment, null, React.createElement("div", {
      className: "positionFirstColumn"
    }, React.createElement("div", {
      className: index % 2 !== 0 ? "rowTwo" : "rowOne"
    }, React.createElement("p", null, item === null || item === void 0 ? void 0 : item.shippingZoneName))), React.createElement("div", {
      className: "positionSecondColumn"
    }, React.createElement("div", {
      className: index % 2 !== 0 ? "rowTwo" : "rowOne"
    }, React.createElement("p", null, item === null || item === void 0 ? void 0 : item.quantity))));
  })) : React.createElement("p", {
    className: "noData"
  }, React.createElement(FormattedMessage, {
    id: "views.ReportSummaryByArea.ReportViewDesktop.4205644805",
    defaultMessage: "No results found"
  })))));
};

ReportViewDesktop.displayName = "ReportViewDesktop";
export default ReportViewDesktop;