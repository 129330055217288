import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

export var initialStateToppingsPosition = [];
export var toppingsPositionReducer = function toppingsPositionReducer(prevState, action) {
  var _action$payload, _document$getElementB;

  switch (action.type) {
    case "add-position":
      return [].concat(_toConsumableArray(prevState), [_objectSpread(_objectSpread({}, action.payload), {}, {
        obligatory: !(action === null || action === void 0 ? void 0 : (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.obligatory),
        position: (_document$getElementB = document.getElementById(action.payload.id)) === null || _document$getElementB === void 0 ? void 0 : _document$getElementB.offsetTop
      })]);

    case "update-obligatory":
      return prevState.map(function (state) {
        if (state.id === action.payload.id) {
          var _action$payload2;

          return _objectSpread(_objectSpread({}, state), {}, {
            obligatory: !(action === null || action === void 0 ? void 0 : (_action$payload2 = action.payload) === null || _action$payload2 === void 0 ? void 0 : _action$payload2.obligatory)
          });
        }

        return state;
      });

    case "update-positions":
      return prevState.map(function (state) {
        var element = document.getElementById(state.id);
        return _objectSpread(_objectSpread({}, state), {}, {
          position: element === null || element === void 0 ? void 0 : element.offsetTop
        });
      });

    default:
      return prevState;
  }
};