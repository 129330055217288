import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: 80%;\n  height: 50px;\n  margin: 0 auto;\n  margin-top: 1rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 10px;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Loader = styled.div(_templateObject());