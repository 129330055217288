import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "./scss/index.scss";
import * as React from "react";
import { Skeleton } from "@components/atoms/Skeleton/Skeleton";
import { HomeProducts, MetaWrapper, OverlayContext, OverlayTheme, OverlayType } from "@temp/components";
import { useLocation, useNavigate } from "react-router-dom";
import { generateCategoryUrl } from "@utils/core";
import { useCategoriesData, useHomeProductsData } from "@temp/hooks";
import { useCart } from "@saleor/sdk";
import { addCategories, initialStateCategory, initialStateSubcategory, initialInputValueAndQueryValue, restoreSearchData, restoreCategory, restoreSubCategory, useAppDispatch, useAppSelector, updateCategorySearchValue } from "@temp/redux";

var HomeContentProducts = function HomeContentProducts(_ref) {
  var homeProductsData = _ref.homeProductsData,
      loadingHomeProducts = _ref.loadingHomeProducts;

  var _useCart = useCart(),
      items = _useCart.items;

  var location = useLocation();
  var overlayContext = React.useContext(OverlayContext);
  var dispatch = useAppDispatch();

  var _useAppSelector = useAppSelector(function (state) {
    return state.search;
  }),
      searchQueryValue = _useAppSelector.searchQueryValue,
      searchCategory = _useAppSelector.searchCategory,
      searchSubcategory = _useAppSelector.searchSubcategory;

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  React.useEffect(function () {
    var checkoutModify = localStorage.getItem("checkout-modify");

    if (checkoutModify === "true") {
      overlayContext.show(OverlayType.cart, OverlayTheme.right);
    }

    localStorage.removeItem("order_token");
    localStorage.removeItem("checkout-modify");
    localStorage.removeItem("flag_progress");
    localStorage.removeItem("flag_basket");
    localStorage.removeItem("flag_history");
    localStorage.removeItem("flag_address");
    localStorage.removeItem("sale_url");
    localStorage.removeItem("salesubcategory_url");
    localStorage.removeItem("categories_url");
    localStorage.removeItem("category_url");
    localStorage.removeItem("subcategory_url");
    localStorage.removeItem("address-id");
    localStorage.removeItem("address-validate-edit");
    localStorage.removeItem("flag-input-other-values");
    localStorage.removeItem("scroll-category");
    localStorage.removeItem("loadingLoadMoreSubCategory");
    localStorage.removeItem("loadingLoadMoreSaleCategory");
    localStorage.setItem("product_url", location.pathname);
    localStorage.setItem("home_url", location.pathname);
    dispatch(restoreCategory());
    dispatch(restoreSubCategory());

    if (localStorage.getItem("home_search_clean")) {
      dispatch(restoreSearchData());
      localStorage.removeItem("home_search_clean");
    }

    return function () {};
  }, []);
  var navigate = useNavigate();

  var _React$useState = React.useState(true),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      loadingFlag = _React$useState2[0],
      setLoadingFlag = _React$useState2[1];

  var _useHomeProductsData = useHomeProductsData(homeProductsData, items),
      allProducts = _useHomeProductsData.allProducts;

  React.useEffect(function () {
    if (!loadingHomeProducts) {
      var timeOut = setTimeout(function () {
        if (allProducts.length === 1 && searchQueryValue === initialInputValueAndQueryValue && searchCategory.value === initialStateCategory.value && searchSubcategory.value === initialStateSubcategory.value) {
          var _allProducts$, _allProducts$$categor, _allProducts$2, _allProducts$2$catego, _allProducts$3, _allProducts$3$catego, _allProducts$4, _allProducts$4$catego;

          var categoryRedirectUrl = generateCategoryUrl((_allProducts$ = allProducts[0]) === null || _allProducts$ === void 0 ? void 0 : (_allProducts$$categor = _allProducts$.category) === null || _allProducts$$categor === void 0 ? void 0 : _allProducts$$categor.id, (_allProducts$2 = allProducts[0]) === null || _allProducts$2 === void 0 ? void 0 : (_allProducts$2$catego = _allProducts$2.category) === null || _allProducts$2$catego === void 0 ? void 0 : _allProducts$2$catego.name);
          dispatch(updateCategorySearchValue({
            searchCategory: {
              name: (_allProducts$3 = allProducts[0]) === null || _allProducts$3 === void 0 ? void 0 : (_allProducts$3$catego = _allProducts$3.category) === null || _allProducts$3$catego === void 0 ? void 0 : _allProducts$3$catego.name,
              value: (_allProducts$4 = allProducts[0]) === null || _allProducts$4 === void 0 ? void 0 : (_allProducts$4$catego = _allProducts$4.category) === null || _allProducts$4$catego === void 0 ? void 0 : _allProducts$4$catego.id,
              subcategories: []
            }
          }));
          navigate("".concat(categoryRedirectUrl, "true"));
        } else {
          localStorage.setItem("home_cache", "true");
          localStorage.removeItem("oneSubcategory");
          setLoadingFlag(false);
        }
      }, 600);
      return function () {
        clearTimeout(timeOut);
      };
    }

    if (loadingHomeProducts) {
      localStorage.removeItem("home_cache");
      setLoadingFlag(true);
    }
  }, [loadingHomeProducts]);
  React.useEffect(function () {
    if (!loadingHomeProducts && searchQueryValue === initialInputValueAndQueryValue && searchCategory.value === initialStateCategory.value && searchSubcategory.value === initialStateSubcategory.value) {
      var _useCategoriesData = useCategoriesData(homeProductsData),
          sendCategories = _useCategoriesData.sendCategories;

      dispatch(addCategories({
        categories: sendCategories.categories
      }));
    }
  }, [loadingHomeProducts]);

  if (loadingFlag && !(localStorage.getItem("home_cache") === "true")) {
    return React.createElement(React.Fragment, null, React.createElement(MetaWrapper, {
      meta: {
        type: "product.sale"
      }
    }, React.createElement("div", {
      className: "container-categoryHome__loading"
    }, React.createElement(Skeleton, null))));
  }

  return React.createElement(HomeProducts, {
    allProducts: allProducts
  });
};

export default HomeContentProducts;