import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "./scss/index.scss";
import * as React from "react";
import { useIntl } from "react-intl";
import Media from "react-media";
import ReactSVG from "react-svg";
import { useAuth } from "@saleor/sdk";
import { xMediumScreen } from "@styles/constants";
import { checkoutMessages } from "@temp/intl";
import { useAppSelector } from "@temp/redux";
import Nav from "./Nav";
import NavLateral from "./NavLateral";
import AlertCurrentNeighborhood from "../AlertCurrentNeighborhood";

var ubicationDesktop = require("../../images/marker.svg");

var Footer = React.memo(function (_ref) {
  var goBackLateral = _ref.goBackLateral;
  var intl = useIntl();

  var _useAuth = useAuth(),
      user = _useAuth.user;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      withOutCategory = _React$useState2[0],
      setWithOutCategory = _React$useState2[1];

  var _React$useState3 = React.useState(false),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      showAlert = _React$useState4[0],
      setShowAlert = _React$useState4[1];

  var _useAppSelector = useAppSelector(function (state) {
    return state;
  }),
      deliveryNeighborhoodName = _useAppSelector.deliveryCustomer.deliveryNeighborhoodName,
      hasAttentionAtCurrentTime = _useAppSelector.tradeAttention.hasAttentionAtCurrentTime;

  React.useEffect(function () {
    var oneSubcategory = localStorage.getItem("oneSubcategory");

    if (oneSubcategory) {
      setWithOutCategory(true);
    }

    if (!oneSubcategory) {
      setWithOutCategory(false);
    }
  }, []);
  return React.createElement(Media, {
    query: {
      maxWidth: xMediumScreen
    }
  }, function (matches) {
    var _user$role;

    return matches ? React.createElement(Nav, {
      withOutCategories: withOutCategory
    }) : React.createElement(React.Fragment, null, React.createElement("div", {
      className: "headerContainer__footer"
    }, React.createElement("div", {
      className: "descriptionShippingAddress__footer",
      style: !hasAttentionAtCurrentTime ? {
        top: "100px"
      } : {}
    }, (user === null || user === void 0 ? void 0 : (_user$role = user.role) === null || _user$role === void 0 ? void 0 : _user$role.toUpperCase()) === "CUSTOMER" && React.createElement("div", {
      className: "priceOrder__footer__neighborhood"
    }, React.createElement(ReactSVG, {
      className: "priceOrder__footer__ubicationImage",
      path: ubicationDesktop
    }), React.createElement("div", {
      onClick: function onClick() {
        return setShowAlert(true);
      },
      style: {
        cursor: "pointer"
      }
    }, React.createElement("p", {
      className: "priceOrder__footer__subTitleNeighborhood"
    }, intl.formatMessage(checkoutMessages.neighborhood), ":"), React.createElement("p", {
      className: "priceOrder__footer__nameNeighborhood"
    }, deliveryNeighborhoodName))))), React.createElement(NavLateral, {
      goBackLateral: true,
      withOutCategories: withOutCategory
    }), showAlert && React.createElement(AlertCurrentNeighborhood, {
      hideModal: function hideModal() {
        setShowAlert(false);
      },
      neighborhood: deliveryNeighborhoodName
    }));
  });
});
export default Footer;