import { createSlice } from "@reduxjs/toolkit";
var initialState = {
  dayName: "",
  fromMinute: 0,
  fromHour: 0,
  hasAttentionAtCurrentTime: true
};
export var tradeAttentionSlice = createSlice({
  name: "tradeAttention",
  initialState: initialState,
  reducers: {
    updateTradeAttention: function updateTradeAttention(state, action) {
      var _action$payload, _action$payload2, _action$payload2$curr, _action$payload2$curr2, _action$payload3, _action$payload4, _action$payload4$curr, _action$payload4$curr2, _action$payload5, _action$payload6, _action$payload6$curr, _action$payload6$curr2, _action$payload7, _action$payload8, _action$payload8$curr;

      state.dayName = (action === null || action === void 0 ? void 0 : (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.currentTrade) ? action === null || action === void 0 ? void 0 : (_action$payload2 = action.payload) === null || _action$payload2 === void 0 ? void 0 : (_action$payload2$curr = _action$payload2.currentTrade) === null || _action$payload2$curr === void 0 ? void 0 : (_action$payload2$curr2 = _action$payload2$curr.nextAttentionTime) === null || _action$payload2$curr2 === void 0 ? void 0 : _action$payload2$curr2.dayName : "";
      state.fromMinute = (action === null || action === void 0 ? void 0 : (_action$payload3 = action.payload) === null || _action$payload3 === void 0 ? void 0 : _action$payload3.currentTrade) ? action === null || action === void 0 ? void 0 : (_action$payload4 = action.payload) === null || _action$payload4 === void 0 ? void 0 : (_action$payload4$curr = _action$payload4.currentTrade) === null || _action$payload4$curr === void 0 ? void 0 : (_action$payload4$curr2 = _action$payload4$curr.nextAttentionTime) === null || _action$payload4$curr2 === void 0 ? void 0 : _action$payload4$curr2.fromMinute : 0;
      state.fromHour = (action === null || action === void 0 ? void 0 : (_action$payload5 = action.payload) === null || _action$payload5 === void 0 ? void 0 : _action$payload5.currentTrade) ? action === null || action === void 0 ? void 0 : (_action$payload6 = action.payload) === null || _action$payload6 === void 0 ? void 0 : (_action$payload6$curr = _action$payload6.currentTrade) === null || _action$payload6$curr === void 0 ? void 0 : (_action$payload6$curr2 = _action$payload6$curr.nextAttentionTime) === null || _action$payload6$curr2 === void 0 ? void 0 : _action$payload6$curr2.fromHour : 0;
      state.hasAttentionAtCurrentTime = (action === null || action === void 0 ? void 0 : (_action$payload7 = action.payload) === null || _action$payload7 === void 0 ? void 0 : _action$payload7.currentTrade) ? action === null || action === void 0 ? void 0 : (_action$payload8 = action.payload) === null || _action$payload8 === void 0 ? void 0 : (_action$payload8$curr = _action$payload8.currentTrade) === null || _action$payload8$curr === void 0 ? void 0 : _action$payload8$curr.hasAttentionAtCurrentTime : true;
    }
  }
});
var updateTradeAttention = tradeAttentionSlice.actions.updateTradeAttention;
export { updateTradeAttention };