import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "./scss/index.scss";
import React, { useState } from "react";
import ReactSVG from "react-svg";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "@components/atoms";
import { TextField } from "@components/molecules";

var closeWhite = require("../../images/closewhite.svg"); // const greaterThanEqual = require("../../images/greaterThanEqual.svg");


export var AlertObservation = function AlertObservation(_ref) {
  var hideModal = _ref.hideModal,
      quantity = _ref.quantity,
      id = _ref.id,
      tempObservation = _ref.tempObservation,
      updateItem = _ref.updateItem;

  var _useState = useState(tempObservation),
      _useState2 = _slicedToArray(_useState, 2),
      observation = _useState2[0],
      setObservation = _useState2[1];

  var intl = useIntl();

  var saveObservation = function saveObservation() {
    if (observation.trim() !== tempObservation.trim()) {
      var observationData = observation.trim() || "null";
      updateItem(id, +quantity, observationData);
      hideModal();
    }
  };

  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "showContainerObservation"
  }), React.createElement("div", {
    className: "showContainerObservation__data"
  }, React.createElement("div", {
    className: "showContainerObservation__data__close"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.AlertObservation.index.197077183",
    defaultMessage: "Edit"
  })), React.createElement(ReactSVG, {
    path: closeWhite,
    onClick: hideModal
  })), React.createElement("div", {
    className: "showContainerObservation__data__content showContainerObservation__data__content--first"
  }), React.createElement("div", {
    className: "showContainerObservation__data__content  showContainerObservation__data__content--amount showContainerObservation__data__content--first showContainerObservation__data__content--last"
  }, React.createElement(TextField, {
    name: "productObservation",
    label: intl.formatMessage(_defineProperty({
      defaultMessage: "Observations",
      "id": "components.AlertObservation.index.722592990"
    }, "id", "components.AlertObservation.index.722592990")),
    value: observation,
    onChange: function onChange(e) {
      if (e.target.value.length <= 255) {
        setObservation(e.target.value);
      }
    },
    autoComplete: "observation",
    textAreaInputLabel: true,
    placeholder: intl.formatMessage(_defineProperty({
      defaultMessage: "Ex: The hamburger without onion",
      "id": "components.AlertObservation.index.1721244567"
    }, "id", "components.AlertObservation.index.1721244567"))
  })), React.createElement("div", {
    className: "showContainerObservation__data__footer"
  }, React.createElement(Button, {
    type: "button",
    testingContext: "",
    onClick: hideModal
  }, React.createElement(FormattedMessage, {
    id: "components.AlertObservation.index.3528672691",
    defaultMessage: "Cancel"
  })), React.createElement(Button, {
    type: "button",
    testingContext: "",
    onClick: saveObservation
  }, React.createElement(FormattedMessage, {
    id: "components.AlertObservation.index.3640052215",
    defaultMessage: "Continue"
  })))));
};
export default AlertObservation;