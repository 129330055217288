export var apiUrl = process.env.API_URI;
export var appUrl = process.env.APP_URL;
export var sentryDsn = process.env.SENTRY_DSN;
var sampleRate = parseFloat(process.env.SENTRY_APM);
export var sentrySampleRate = isNaN(sampleRate) ? 0 : sampleRate;
export var serviceWorkerTimeout = parseInt(process.env.SERVICE_WORKER_TIMEOUT, 10) || 60 * 1000;
export var waNumber = process.env.WA_NUMBER;
export var waDeliveryNumber = process.env.WA_DELIVERY_NUMBER;
export var websocketHost = process.env.WEBSOCKET_HOST;
export var perPage = process.env.PER_PAGE || 20;
export var pageTermsSlug = process.env.PAGE_TERMS_SLUG || "";
export var pagePpdSlug = process.env.PAGE_PPD_SLUG || "";
export var GOOGLE_RECAPTCHA_KEY = process.env.GOOGLE_RECAPTCHA_KEY || "";
export var returnToWaFromEpTermsUrl = process.env.RETURN_TO_WA_FROM_EP_TERMS_URL || "";
export var wantToReadEpTermsUrl = process.env.WANT_TO_READ_EP_TERMS_URL || "";
export var signInType = {
  normal: "normal",
  orderDetail: "order-detail",
  ordersList: "orders-list",
  profile: "profile"
};