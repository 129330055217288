import { createSlice } from "@reduxjs/toolkit";
var initialState = {
  edges: []
};
export var deliveryOrdersInProgressSlice = createSlice({
  name: "deliveryOrdersInProgress",
  initialState: initialState,
  reducers: {
    addListInProgress: function addListInProgress(state, action) {
      state.edges.unshift(action.payload.data);
    },
    deleteListInProgress: function deleteListInProgress(state, action) {
      var _state$edges;

      state.edges = state === null || state === void 0 ? void 0 : (_state$edges = state.edges) === null || _state$edges === void 0 ? void 0 : _state$edges.filter(function (data) {
        var _data$node;

        return (data === null || data === void 0 ? void 0 : (_data$node = data.node) === null || _data$node === void 0 ? void 0 : _data$node.id.toString()) !== action.payload.id.toString();
      });
    },
    updateAllDataInProgress: function updateAllDataInProgress(state, action) {
      state.edges = action.payload.data;
    },
    updateOrderDataInProgress: function updateOrderDataInProgress(state, action) {
      var _state$edges2;

      var orderIndex = state === null || state === void 0 ? void 0 : (_state$edges2 = state.edges) === null || _state$edges2 === void 0 ? void 0 : _state$edges2.findIndex(function (data) {
        return data.node.id.toString() === action.payload.data.node.id.toString();
      });

      if (orderIndex !== -1) {
        state.edges[orderIndex] = action.payload.data;
      }
    }
  }
});
var _deliveryOrdersInProg = deliveryOrdersInProgressSlice.actions,
    addListInProgress = _deliveryOrdersInProg.addListInProgress,
    deleteListInProgress = _deliveryOrdersInProg.deleteListInProgress,
    updateAllDataInProgress = _deliveryOrdersInProg.updateAllDataInProgress,
    updateOrderDataInProgress = _deliveryOrdersInProg.updateOrderDataInProgress;
export { addListInProgress, deleteListInProgress, updateAllDataInProgress, updateOrderDataInProgress };