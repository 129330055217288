import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { useSaleProduct } from "./useSaleProduct";
export var useSaleCategory = function useSaleCategory(data, items, categoryId) {
  var _data$edges;

  var ProductsSaleSubCategory = [];
  data === null || data === void 0 ? void 0 : (_data$edges = data.edges) === null || _data$edges === void 0 ? void 0 : _data$edges.forEach(function (productVariant) {
    if (productVariant === null || productVariant === void 0 ? void 0 : productVariant.node.categoryIdLightLoad) {
      var sumItems = items === null || items === void 0 ? void 0 : items.filter(function (itemsCart) {
        var _itemsCart$variant, _productVariant$node;

        return (itemsCart === null || itemsCart === void 0 ? void 0 : (_itemsCart$variant = itemsCart.variant) === null || _itemsCart$variant === void 0 ? void 0 : _itemsCart$variant.id) === (productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node = productVariant.node) === null || _productVariant$node === void 0 ? void 0 : _productVariant$node.id);
      }).map(function (item) {
        return item.quantity;
      }).reduce(function (a, b) {
        return a + b;
      }, 0);
      var itemsQuantity = sumItems > 0 ? sumItems : null;

      var _useSaleProduct = useSaleProduct(productVariant === null || productVariant === void 0 ? void 0 : productVariant.node),
          discountPercentage = _useSaleProduct.discountPercentage,
          discountValue = _useSaleProduct.discountValue;

      if ((productVariant === null || productVariant === void 0 ? void 0 : productVariant.node.categoryIdLightLoad) === categoryId && (ProductsSaleSubCategory === null || ProductsSaleSubCategory === void 0 ? void 0 : ProductsSaleSubCategory.length) === 0) {
        var _productVariant$node2, _productVariant$node3, _productVariant$node4, _productVariant$node5, _productVariant$node6, _productVariant$node7, _productVariant$node8, _productVariant$node9, _productVariant$node10, _productVariant$node11, _productVariant$node12;

        ProductsSaleSubCategory === null || ProductsSaleSubCategory === void 0 ? void 0 : ProductsSaleSubCategory.push({
          category: {
            id: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node2 = productVariant.node) === null || _productVariant$node2 === void 0 ? void 0 : _productVariant$node2.categoryIdLightLoad,
            name: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node3 = productVariant.node) === null || _productVariant$node3 === void 0 ? void 0 : _productVariant$node3.categoryNameLightLoad
          },
          products: [{
            category: {
              id: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node4 = productVariant.node) === null || _productVariant$node4 === void 0 ? void 0 : _productVariant$node4.categoryIdLightLoad,
              name: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node5 = productVariant.node) === null || _productVariant$node5 === void 0 ? void 0 : _productVariant$node5.categoryNameLightLoad
            },
            subCategory: {
              id: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node6 = productVariant.node) === null || _productVariant$node6 === void 0 ? void 0 : _productVariant$node6.subCategoryIdLightLoad,
              name: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node7 = productVariant.node) === null || _productVariant$node7 === void 0 ? void 0 : _productVariant$node7.subCategoryNameLightLoad
            },
            id: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node8 = productVariant.node) === null || _productVariant$node8 === void 0 ? void 0 : _productVariant$node8.id,
            name: (productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node9 = productVariant.node) === null || _productVariant$node9 === void 0 ? void 0 : _productVariant$node9.name) || (productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node10 = productVariant.node) === null || _productVariant$node10 === void 0 ? void 0 : _productVariant$node10.productNameLightLoad),
            price: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node11 = productVariant.node) === null || _productVariant$node11 === void 0 ? void 0 : _productVariant$node11.priceLightLoad,
            discountPercentage: discountPercentage,
            discountValue: discountValue,
            itemsQuantity: itemsQuantity,
            thumbnail: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node12 = productVariant.node) === null || _productVariant$node12 === void 0 ? void 0 : _productVariant$node12.thumbnailLightLoad,
            __typename: "Product"
          }]
        });
      } else if ((productVariant === null || productVariant === void 0 ? void 0 : productVariant.node.categoryIdLightLoad) === categoryId && (ProductsSaleSubCategory === null || ProductsSaleSubCategory === void 0 ? void 0 : ProductsSaleSubCategory.length) > 0) {
        var _productVariant$node13, _productVariant$node14, _productVariant$node15, _productVariant$node16, _productVariant$node17, _productVariant$node18, _productVariant$node19, _productVariant$node20, _productVariant$node21;

        var tempProducts = ProductsSaleSubCategory[0].products;
        ProductsSaleSubCategory[0].products = [].concat(_toConsumableArray(tempProducts), [{
          category: {
            id: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node13 = productVariant.node) === null || _productVariant$node13 === void 0 ? void 0 : _productVariant$node13.categoryIdLightLoad,
            name: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node14 = productVariant.node) === null || _productVariant$node14 === void 0 ? void 0 : _productVariant$node14.categoryNameLightLoad
          },
          subCategory: {
            id: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node15 = productVariant.node) === null || _productVariant$node15 === void 0 ? void 0 : _productVariant$node15.subCategoryIdLightLoad,
            name: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node16 = productVariant.node) === null || _productVariant$node16 === void 0 ? void 0 : _productVariant$node16.subCategoryNameLightLoad
          },
          id: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node17 = productVariant.node) === null || _productVariant$node17 === void 0 ? void 0 : _productVariant$node17.id,
          name: (productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node18 = productVariant.node) === null || _productVariant$node18 === void 0 ? void 0 : _productVariant$node18.name) || (productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node19 = productVariant.node) === null || _productVariant$node19 === void 0 ? void 0 : _productVariant$node19.productNameLightLoad),
          price: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node20 = productVariant.node) === null || _productVariant$node20 === void 0 ? void 0 : _productVariant$node20.priceLightLoad,
          discountPercentage: discountPercentage,
          discountValue: discountValue,
          itemsQuantity: itemsQuantity,
          thumbnail: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$node21 = productVariant.node) === null || _productVariant$node21 === void 0 ? void 0 : _productVariant$node21.thumbnailLightLoad,
          __typename: "Product"
        }]);
      }
    }
  });
  return {
    ProductsSaleSubCategory: ProductsSaleSubCategory
  };
};