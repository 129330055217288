import "./scss/index.scss";
import React from "react";
import ReactSVG from "react-svg";
import { FormattedMessage } from "react-intl";
import { animated, useSpring } from "react-spring";
import { Button } from "@components/atoms";

var closeWhite = require("../../images/closewhite.svg"); // const greaterThanEqual = require("../../images/greaterThanEqual.svg");


export var AlertDeliveryToComplete = function AlertDeliveryToComplete(_ref) {
  var arrivedClick = _ref.arrivedClick,
      deliveredClick = _ref.deliveredClick,
      hideModal = _ref.hideModal;

  var arrived = function arrived() {
    arrivedClick();
    hideModal();
  };

  var delivered = function delivered() {
    deliveredClick();
    hideModal();
  };

  var scaleButton = useSpring({
    config: {
      friction: 40,
      mass: 5,
      tension: 350
    },
    from: {
      scale: 1.1
    },
    loop: true,
    to: {
      scale: 1
    }
  });
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "showContainer",
    onClick: hideModal
  }), React.createElement("div", {
    className: "showContainer__data"
  }, React.createElement("div", {
    className: "showContainer__data__close"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.AlertDeliveryToComplete.index.1548055602",
    defaultMessage: "To complete"
  })), React.createElement(ReactSVG, {
    path: closeWhite,
    onClick: hideModal
  })), React.createElement("div", {
    className: "showContainer__data__content  showContainer__data__content--amount showContainer__data__content--first showContainer__data__content--last"
  }, React.createElement(FormattedMessage, {
    id: "components.AlertDeliveryToComplete.index.2251888877",
    defaultMessage: "What is the delivery status?"
  })), React.createElement("div", {
    className: "showContainer__data__footerButton"
  }, React.createElement(Button, {
    type: "button",
    testingContext: "",
    onClick: arrived
  }, React.createElement(FormattedMessage, {
    id: "components.AlertDeliveryToComplete.index.4099700715",
    defaultMessage: "Arrived"
  })), React.createElement(animated.button, {
    type: "button",
    onClick: delivered,
    style: scaleButton
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.AlertDeliveryToComplete.index.1464199760",
    defaultMessage: "Delivered"
  }))))));
};
export default AlertDeliveryToComplete;