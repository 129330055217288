import "./scss/index.scss";
import React from "react";
import ReactSVG from "react-svg";
import { FormattedMessage } from "react-intl";
import { Button } from "@components/atoms";

var closeWhite = require("../../images/closewhite.svg");

export var AlertSeeVoucher = function AlertSeeVoucher(_ref) {
  var hideModal = _ref.hideModal,
      paymentConfirmationFile = _ref.paymentConfirmationFile;
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "showContainerSeeVoucher",
    onClick: hideModal
  }), React.createElement("div", {
    className: "showContainerSeeVoucher__data"
  }, React.createElement("div", {
    className: "showContainerSeeVoucher__data__close"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.AlertSeeVoucher.index.1934027242",
    defaultMessage: "Voucher"
  })), React.createElement(ReactSVG, {
    path: closeWhite,
    onClick: hideModal
  })), React.createElement("div", {
    className: "showContainerSeeVoucher__data__content  showContainerSeeVoucher__data__content--amount showContainerSeeVoucher__data__content--first showContainerSeeVoucher__data__content--last"
  }, React.createElement("img", {
    alt: "voucher",
    src: paymentConfirmationFile,
    style: {
      width: "100%"
    }
  })), React.createElement("div", {
    className: "showContainerSeeVoucher__data__footerButton"
  }, React.createElement(Button, {
    type: "button",
    testingContext: "",
    onClick: hideModal
  }, React.createElement(FormattedMessage, {
    id: "components.AlertSeeVoucher.index.2324218928",
    defaultMessage: "Close"
  })), React.createElement(Button, {
    type: "button",
    testingContext: "",
    onClick: function onClick() {
      var win = window.open();
      win.document.write("\n                <style>\n                  @page {\n                    size: auto;\n                    margin: 0;\n                  }\n                  body {\n                    margin: 0;\n                    padding: 0;\n                  }\n                  img {\n                    max-width: 100%;\n                    max-height: 100%;\n                  }\n                </style>\n                <img src=\"".concat(paymentConfirmationFile, "\" onload=\"window.print();window.close()\" />\n              "));
      win.focus();
    }
  }, React.createElement(FormattedMessage, {
    id: "components.AlertSeeVoucher.index.1982430146",
    defaultMessage: "Download"
  })))));
};
export default AlertSeeVoucher;