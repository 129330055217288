import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Icon } from "../Icon";
import { Tile } from "../Tile";
import * as S from "./styles";
export var AddNewTile = function AddNewTile(_ref) {
  var tileType = _ref.tileType,
      type = _ref.type,
      selectShippingAddress = _ref.selectShippingAddress,
      props = _objectWithoutProperties(_ref, ["tileType", "type", "selectShippingAddress"]);

  return React.createElement(Tile, _extends({
    tileType: tileType || "addNew"
  }, props), React.createElement(S.Content, null, React.createElement("p", null, tileType === "addNewCheckout" ? React.createElement(Icon, {
    size: 24,
    name: "plus",
    color: "#ED1C24"
  }) : React.createElement(Icon, {
    size: 24,
    name: "plus",
    color: "#ffffff"
  })), React.createElement("p", null, selectShippingAddress !== undefined && selectShippingAddress ? // Add address at checkout
  React.createElement(FormattedMessage, {
    id: "@next.components.atoms.AddNewTile.AddNewTile.3099331554",
    defaultMessage: "Add"
  }) : React.createElement(FormattedMessage, {
    id: "@next.components.atoms.AddNewTile.AddNewTile.3623935073",
    defaultMessage: "Add address"
  }))));
};