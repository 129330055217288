import React from "react";
import ReactSVG from "react-svg";
import { FormattedMessage } from "react-intl";
import * as S from "./styles";

var mayorque = require("../../../../images/goOn.svg"); // import * as S from "./styles";


export var CheckoutAddress = function CheckoutAddress(_ref) {
  var firstName = _ref.firstName,
      lastName = _ref.lastName,
      companyName = _ref.companyName,
      streetAddress1 = _ref.streetAddress1,
      streetAddress2 = _ref.streetAddress2,
      city = _ref.city,
      postalCode = _ref.postalCode,
      countryArea = _ref.countryArea,
      country = _ref.country,
      phone = _ref.phone,
      neighborhood = _ref.neighborhood,
      city2 = _ref.city2;
  return React.createElement(S.Wrapper, null, React.createElement("div", null, React.createElement(S.Name, null, firstName), " ", React.createElement("br", null), streetAddress1 && React.createElement(S.Label, null, React.createElement("span", null, React.createElement(FormattedMessage, {
    id: "@next.components.atoms.Address.CheckoutAddress.359810770",
    defaultMessage: "Address"
  }), " ", ": "), " ", React.createElement("span", null, streetAddress1), " ", streetAddress2 && React.createElement("span", null, ", ", " ", streetAddress2), React.createElement("br", null)), (neighborhood === null || neighborhood === void 0 ? void 0 : neighborhood.name) && React.createElement(S.Label, null, React.createElement("span", null, React.createElement(FormattedMessage, {
    id: "@next.components.atoms.Address.CheckoutAddress.1024405385",
    defaultMessage: "Neighborhood"
  }), " ", ": "), React.createElement("span", null, neighborhood === null || neighborhood === void 0 ? void 0 : neighborhood.name), " ", React.createElement("br", null))), React.createElement(S.Image, null, React.createElement(ReactSVG, {
    path: mayorque
  })));
};