export var organizeDataCategories = function organizeDataCategories(data) {
  var categoriesData = {};
  data === null || data === void 0 ? void 0 : data.forEach(function (category) {
    if (categoriesData["".concat(category.parent.id)]) {
      categoriesData["".concat(category.parent.id)].subcategories.push({
        value: category.id,
        name: category.nameSubcategoryOrderIndexTrade || category.name
      });
    } else {
      categoriesData["".concat(category.parent.id)] = {
        value: category.parent.id,
        name: category.nameCategoryOrderIndexTrade || category.parent.name,
        subcategories: [{
          value: category.id,
          name: category.nameSubcategoryOrderIndexTrade || category.name
        }]
      };
    }
  });
  return categoriesData;
};