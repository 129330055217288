import React from "react"; // import { useHistory } from "react-router-dom";

import ReactSVG from "react-svg"; // import { Container } from "../Container";

import gobackImg from "../../../../images/goback.svg";
import * as S from "./styles";

/**
 * Cart template for cart page with list of products added by user.
 */
var Cart = function Cart(_ref) {
  var actionGoBack = _ref.actionGoBack,
      breadcrumbs = _ref.breadcrumbs,
      title = _ref.title,
      cancelButton = _ref.cancelButton,
      cartHeader = _ref.cartHeader,
      cartFooter = _ref.cartFooter,
      cart = _ref.cart,
      button = _ref.button,
      quantityItems = _ref.quantityItems;

  var validateGoBack = function validateGoBack() {
    var checkoutModify = localStorage.getItem("checkout-modify");

    if (checkoutModify !== null && checkoutModify === "true") {
      return false;
    }

    return true;
  };

  return React.createElement(S.Container, null, React.createElement(S.Wrapper, null, React.createElement(S.ContainerHeader, null, React.createElement(S.Breadcrumbs, null, validateGoBack() && React.createElement("li", {
    onClick: actionGoBack
  }, React.createElement(ReactSVG, {
    path: gobackImg
  }))), React.createElement(S.Title, null, title), React.createElement(S.Complement, null, " ")), React.createElement(S.QuantityItems, null, quantityItems), React.createElement(S.Cart, null, cart), React.createElement(S.ContainerFooter, null, React.createElement(S.CartFooter, null, cartFooter), React.createElement(S.CancelButton, null, cancelButton), React.createElement(S.ProceedButton, null, button))));
};

export { Cart };