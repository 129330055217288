import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ReactSVG from "react-svg";
import { animated, useSpring } from "react-spring";
import { commonMessages } from "@temp/intl";
import selectArrowWhite from "../../../../images/SelectArrowWhitef.svg";
import * as S from "./styles";
import "./styles.scss";
export var ToppingTitle = function ToppingTitle(_ref) {
  var id = _ref.id,
      index = _ref.index,
      maxSelected = _ref.maxSelected,
      minSelected = _ref.minSelected,
      name = _ref.name,
      handleShowValues = _ref.handleShowValues,
      showValues = _ref.showValues;
  var intl = useIntl();
  var scaleButton = useSpring({
    config: {
      friction: 40,
      mass: 5,
      tension: 350
    },
    from: {
      scale: 1.1
    },
    loop: true,
    to: {
      scale: 1
    }
  });
  return React.createElement(React.Fragment, null, React.createElement(S.Specifications, {
    id: id
  }, maxSelected && maxSelected === minSelected ? React.createElement(S.SelectMax, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.ToppingTitle.ToppingTitle.1215077113",
    defaultMessage: "{quantity,plural,one {Choose {quantity} option} other {Choose {quantity} options}}",
    description: "items quantity in cart",
    values: {
      quantity: maxSelected
    }
  })) : maxSelected && maxSelected > 0 && minSelected === 0 ? React.createElement(S.SelectMax, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.ToppingTitle.ToppingTitle.3156382311",
    defaultMessage: "{quantity,plural,one {Choose maximum {quantity} option} other {Choose maximum {quantity} options}}",
    description: "items quantity in cart",
    values: {
      quantity: maxSelected
    }
  })) : maxSelected && maxSelected > 0 && minSelected > 0 ? React.createElement(S.SelectMax, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.ToppingTitle.ToppingTitle.1986371441",
    defaultMessage: "Choose minimum {minSelected} and maximum {maxSelected} options",
    description: "items quantity in cart",
    values: {
      minSelected: minSelected,
      maxSelected: maxSelected
    }
  })) : !maxSelected && minSelected > 0 && React.createElement(S.SelectMax, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.ToppingTitle.ToppingTitle.2237475491",
    defaultMessage: "{minSelected,plural,one {Choose minimum {minSelected} option} other {Choose minimum {minSelected} options}}",
    description: "items quantity in cart",
    values: {
      minSelected: minSelected
    }
  })), minSelected > 0 && React.createElement(React.Fragment, null, React.createElement(S.Obligatory, {
    id: "toppingPosition-".concat(index, "-withOutLunch")
  }, intl.formatMessage(commonMessages.obligatory)), React.createElement(animated.button, {
    style: scaleButton,
    id: "toppingPosition-".concat(index, "-withLunch"),
    className: "obligatoryButton"
  }, intl.formatMessage(commonMessages.obligatory)))), React.createElement(S.ToppingTitleContainer, {
    onClick: handleShowValues
  }, React.createElement(S.ToppingTitle, null, name), React.createElement(S.ToppingTitleImage, {
    open: showValues
  }, React.createElement(ReactSVG, {
    path: selectArrowWhite
  }))));
};
ToppingTitle.displayName = "ToppingTitle";