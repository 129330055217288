import "./scss/index.scss";
import { commonMessages } from "@temp/intl";
import React from "react";
import { useIntl } from "react-intl";
import ReactSVG from "react-svg";
import { animated, useSpring } from "react-spring";
import { useAppSelector } from "@temp/redux";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "@temp/app/routes";

var productNoFound = require("../../images/noFoundProduct.svg");

export var ProductNoFound = function ProductNoFound() {
  var intl = useIntl();
  var navigate = useNavigate();

  var _useAppSelector = useAppSelector(function (state) {
    return state.search;
  }),
      searchCategory = _useAppSelector.searchCategory,
      searchSubcategory = _useAppSelector.searchSubcategory,
      searchQueryValue = _useAppSelector.searchQueryValue;

  var scaleButton = useSpring({
    config: {
      friction: 80,
      mass: 10,
      tension: 350
    },
    from: {
      scale: 1.3
    },
    loop: true,
    to: {
      scale: 1
    }
  });
  return React.createElement("div", {
    className: "productNoFound"
  }, searchQueryValue !== "" && !location.pathname.includes("sale") && (searchCategory.value === "" || searchCategory.value.toUpperCase() === intl.formatMessage(commonMessages.all).toUpperCase()) && (searchSubcategory.value === "" || searchSubcategory.value.toUpperCase() === intl.formatMessage(commonMessages.all).toUpperCase()) ? React.createElement(React.Fragment, null, React.createElement("p", null, intl.formatMessage(commonMessages.weDidNotFindProductsWithTheWord), " ", React.createElement("span", null, "\"".concat(searchQueryValue, "\""))), React.createElement(ReactSVG, {
    path: productNoFound
  }), React.createElement("span", null, intl.formatMessage(commonMessages.checkThatTheWordIsSpelledCorrectly), " ")) : searchQueryValue === "" && searchCategory.value !== "" && searchCategory.value.toUpperCase() !== intl.formatMessage(commonMessages.all).toUpperCase() && !location.pathname.includes("sale") && (searchSubcategory.value === "" || searchSubcategory.value.toUpperCase() === intl.formatMessage(commonMessages.all).toUpperCase()) ? React.createElement(React.Fragment, null, React.createElement(animated.div, {
    className: "productNoFound__search",
    style: scaleButton
  }, React.createElement("p", {
    onClick: function onClick() {
      return navigate(baseUrl);
    }
  }, " ", "Buscar en todo el cat\xE1logo", " ")), React.createElement("p", null, intl.formatMessage(commonMessages.weDidNotFindProductsInTheCategory), " ", React.createElement("span", null, "\"".concat(searchCategory.name, "\""))), React.createElement(ReactSVG, {
    path: productNoFound
  }), React.createElement("span", null, intl.formatMessage(commonMessages.youCanTryModifyingFilters), " ")) : searchQueryValue !== "" && searchCategory.value !== "" && searchCategory.value.toUpperCase() !== intl.formatMessage(commonMessages.all).toUpperCase() && !location.pathname.includes("sale") && (searchSubcategory.value === "" || searchSubcategory.value.toUpperCase() === intl.formatMessage(commonMessages.all).toUpperCase()) ? React.createElement(React.Fragment, null, React.createElement(animated.div, {
    className: "productNoFound__search",
    style: scaleButton
  }, React.createElement("p", {
    onClick: function onClick() {
      return navigate(baseUrl);
    }
  }, " ", "Buscar en todo el cat\xE1logo", " ")), React.createElement("p", null, intl.formatMessage(commonMessages.weDidNotFindProductsWithTheWord), " ", React.createElement("span", null, "\"".concat(searchQueryValue, "\" ")), intl.formatMessage(commonMessages.inTheCategory), " ", React.createElement("span", null, "".concat(searchCategory.name))), React.createElement(ReactSVG, {
    path: productNoFound
  }), React.createElement("span", null, intl.formatMessage(commonMessages.checkThatTheWordIsSpelledCorrectly), " ")) : searchQueryValue === "" && searchCategory.value !== "" && searchCategory.value.toUpperCase() !== intl.formatMessage(commonMessages.all).toUpperCase() && searchSubcategory.value !== "" && !location.pathname.includes("sale") && searchSubcategory.value.toUpperCase() !== intl.formatMessage(commonMessages.all).toUpperCase() ? React.createElement(React.Fragment, null, React.createElement(animated.div, {
    className: "productNoFound__search",
    style: scaleButton
  }, React.createElement("p", {
    onClick: function onClick() {
      return navigate(baseUrl);
    }
  }, " ", "Buscar en todo el cat\xE1logo", " ")), React.createElement("p", null, intl.formatMessage(commonMessages.weDidNotFindProductsInTheCategory), " ", React.createElement("span", null, "".concat(searchCategory.name, " - ").concat(searchSubcategory.name))), React.createElement(ReactSVG, {
    path: productNoFound
  }), React.createElement("span", null, intl.formatMessage(commonMessages.youCanTryModifyingFilters), " ")) : searchQueryValue !== "" && searchCategory.value !== "" && searchCategory.value.toUpperCase() !== intl.formatMessage(commonMessages.all).toUpperCase() && searchSubcategory.value !== "" && !location.pathname.includes("sale") && searchSubcategory.value.toUpperCase() !== intl.formatMessage(commonMessages.all).toUpperCase() ? React.createElement(React.Fragment, null, React.createElement(animated.div, {
    className: "productNoFound__search",
    style: scaleButton
  }, React.createElement("p", {
    onClick: function onClick() {
      return navigate(baseUrl);
    }
  }, " ", "Buscar en todo el cat\xE1logo", " ")), React.createElement("p", null, intl.formatMessage(commonMessages.weDidNotFindProductsWithTheWord), " ", React.createElement("span", null, "\"".concat(searchQueryValue, "\" ")), intl.formatMessage(commonMessages.inTheCategory), " ", React.createElement("span", null, "".concat(searchCategory.name, " - ").concat(searchSubcategory.name))), React.createElement(ReactSVG, {
    path: productNoFound
  }), React.createElement("span", null, intl.formatMessage(commonMessages.checkThatTheWordIsSpelledCorrectly), " ")) : searchQueryValue !== "" && (searchCategory.value === "" || searchCategory.value.toUpperCase() === intl.formatMessage(commonMessages.all).toUpperCase()) && location.pathname.includes("sale") && (searchSubcategory.value === "" || searchSubcategory.value.toUpperCase() === intl.formatMessage(commonMessages.all).toUpperCase()) ? React.createElement(React.Fragment, null, React.createElement(animated.div, {
    className: "productNoFound__search",
    style: scaleButton
  }, React.createElement("p", {
    onClick: function onClick() {
      return navigate(baseUrl);
    }
  }, " ", "Buscar en todo el cat\xE1logo", " ")), React.createElement("p", null, intl.formatMessage(commonMessages.weDidNotFindProductsWithTheWordSale), " ", React.createElement("span", null, "\"".concat(searchQueryValue, "\""))), React.createElement(ReactSVG, {
    path: productNoFound
  }), React.createElement("span", null, intl.formatMessage(commonMessages.checkThatTheWordIsSpelledCorrectlySale), " ")) : searchQueryValue === "" && searchCategory.value !== "" && searchCategory.value.toUpperCase() !== intl.formatMessage(commonMessages.all).toUpperCase() && location.pathname.includes("sale") && (searchSubcategory.value === "" || searchSubcategory.value.toUpperCase() === intl.formatMessage(commonMessages.all).toUpperCase()) ? React.createElement(React.Fragment, null, React.createElement(animated.div, {
    className: "productNoFound__search",
    style: scaleButton
  }, React.createElement("p", {
    onClick: function onClick() {
      return navigate(baseUrl);
    }
  }, " ", "Buscar en todo el cat\xE1logo", " ")), React.createElement("p", null, intl.formatMessage(commonMessages.weDidNotFindProductsInTheCategorySale), " ", React.createElement("span", null, "\"".concat(searchCategory.name, "\""))), React.createElement(ReactSVG, {
    path: productNoFound
  }), React.createElement("span", null, intl.formatMessage(commonMessages.youCanTryModifyingFiltersSale), " ")) : searchQueryValue !== "" && searchCategory.value !== "" && searchCategory.value.toUpperCase() !== intl.formatMessage(commonMessages.all).toUpperCase() && location.pathname.includes("sale") && (searchSubcategory.value === "" || searchSubcategory.value.toUpperCase() === intl.formatMessage(commonMessages.all).toUpperCase()) && React.createElement(React.Fragment, null, React.createElement(animated.div, {
    className: "productNoFound__search",
    style: scaleButton
  }, React.createElement("p", {
    onClick: function onClick() {
      return navigate(baseUrl);
    }
  }, " ", "Buscar en todo el cat\xE1logo", " ")), React.createElement("p", null, intl.formatMessage(commonMessages.weDidNotFindProductsWithTheWordSale), " ", React.createElement("span", null, "\"".concat(searchQueryValue, "\" ")), intl.formatMessage(commonMessages.inTheCategory), " ", React.createElement("span", null, "".concat(searchCategory.name))), React.createElement(ReactSVG, {
    path: productNoFound
  }), React.createElement("span", null, intl.formatMessage(commonMessages.checkThatTheWordIsSpelledCorrectlySale), " ")));
};