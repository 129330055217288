import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Status } from "@components/templates";
import { OrderStatus, useOrderByTokenStatusPageProblem } from "@saleor/sdk";
import { baseUrl } from "@temp/app/routes";
import { MainMenu } from "../../../components";
import { StatusProblemRouter } from "./StatusProblemRouter";
import { StatusTimeOutSubpage, StatusDeliveredSubpage, StatusUnDeliveredSubpage, StatusCanceledSubpage } from "./subpages";
var initialOrderData = {
  __typename: "Order",
  amountOfProducts: 0,
  cancellationReason: null,
  checkoutObservation: null,
  created: "",
  scheduledAt: null,
  lastFulfillment: null,
  id: "",
  discount: null,
  number: null,
  paymentGateway: null,
  paymentStatus: "",
  paymentStatusDisplay: null,
  paymentToken: null,
  responseTime: null,
  shippingAddress: null,
  shippingPrice: null,
  status: OrderStatus.TIMEOUT,
  subtotal: null,
  token: "",
  total: null,
  trade: null
};

var StatusProblemPage = function StatusProblemPage() {
  var _orderData$trade, _orderData$trade2, _orderData$trade3;

  var params = useParams();
  var location = useLocation();
  var navigate = useNavigate();
  var token = "";
  var letter = "";
  var tokenUrl = params.token || null;

  var _useState = useState(initialOrderData),
      _useState2 = _slicedToArray(_useState, 2),
      orderData = _useState2[0],
      setOrderData = _useState2[1];

  var _useState3 = useState(true),
      _useState4 = _slicedToArray(_useState3, 2),
      loadingState = _useState4[0],
      setLoadingState = _useState4[1];
  /* Identifies where the token comes from */


  if (tokenUrl && ["timeout", "delivered", "undelivered", "canceled", "canceled_fulfillment"].indexOf(tokenUrl) === -1) {
    token = tokenUrl;
    letter = tokenUrl;
    localStorage.setItem("order_token", letter);
  } else if ((location === null || location === void 0 ? void 0 : location.state) !== undefined && (location === null || location === void 0 ? void 0 : location.state) !== null) {
    var _location$state;

    token = location === null || location === void 0 ? void 0 : (_location$state = location.state) === null || _location$state === void 0 ? void 0 : _location$state.token;

    if (token == null) {
      navigate(baseUrl);
    } else {
      letter = token;
      localStorage.setItem("order_token", letter);
    }
  } else if (localStorage.getItem("order_token") !== null && localStorage.getItem("order_token") !== "null") {
    token = localStorage.getItem("order_token");
  } else {
    navigate(baseUrl);
    token = null;
  }

  var goBackStatus = function goBackStatus() {
    if (localStorage.getItem("flag_basket")) {
      navigate(baseUrl);
    } else if (localStorage.getItem("flag_progress")) {
      navigate("/order-in-progress/");
    } else if (localStorage.getItem("flag_history")) {
      navigate("/order-history/");
    }
  };

  var _useOrderByTokenStatu = useOrderByTokenStatusPageProblem({
    hasDeliveryManData: true,
    hasLines: false,
    hasTradeTypes: false,
    token: token || ""
  }, {
    fetchPolicy: "cache-and-network",
    skip: !token
  }),
      data = _useOrderByTokenStatu.data,
      loading = _useOrderByTokenStatu.loading;

  useEffect(function () {
    if (!loading && (data === null || data === void 0 ? void 0 : data.orderByToken)) {
      localStorage.setItem("order_status", data.orderByToken.status);
      setOrderData(_objectSpread({}, data === null || data === void 0 ? void 0 : data.orderByToken));
      setLoadingState(false);
    }
  }, [loading, data]);
  useEffect(function () {
    localStorage.setItem("status_page", "true");
  }, []);

  var _useState5 = useState(false),
      _useState6 = _slicedToArray(_useState5, 2),
      mobileCartOpened = _useState6[0],
      setMobileCartOpened = _useState6[1];

  var statusView = !loadingState ? React.createElement(StatusProblemRouter, {
    RenderTimeOut: function RenderTimeOut() {
      return React.createElement(StatusTimeOutSubpage, {
        data: orderData,
        goBack: goBackStatus
      });
    },
    RenderShipping: function RenderShipping() {
      return React.createElement(StatusCanceledSubpage, {
        data: orderData,
        goBack: goBackStatus
      });
    },
    RenderDelivered: function RenderDelivered() {
      return React.createElement(StatusDeliveredSubpage, {
        data: orderData,
        goBack: goBackStatus
      });
    },
    RenderUnDelivered: function RenderUnDelivered() {
      return React.createElement(StatusUnDeliveredSubpage, {
        data: orderData,
        goBack: goBackStatus
      });
    }
  }) : React.createElement(React.Fragment, null);
  return React.createElement(React.Fragment, null, React.createElement(MainMenu, {
    gobackStatus: true,
    goback: false,
    search: false,
    cart: false,
    cartCheckout: false,
    mobileCartOpened: mobileCartOpened,
    setMobileCartOpened: setMobileCartOpened,
    differentTradeToActual: true,
    orderStatus: orderData === null || orderData === void 0 ? void 0 : orderData.status,
    tradeName: orderData === null || orderData === void 0 ? void 0 : (_orderData$trade = orderData.trade) === null || _orderData$trade === void 0 ? void 0 : _orderData$trade.name,
    tradeLogo: orderData === null || orderData === void 0 ? void 0 : (_orderData$trade2 = orderData.trade) === null || _orderData$trade2 === void 0 ? void 0 : _orderData$trade2.logoImage,
    tradeNumber: orderData === null || orderData === void 0 ? void 0 : (_orderData$trade3 = orderData.trade) === null || _orderData$trade3 === void 0 ? void 0 : _orderData$trade3.phoneNumber,
    orderNumber: orderData === null || orderData === void 0 ? void 0 : orderData.number
  }), React.createElement(Status, {
    loading: loadingState,
    status: statusView
  }));
};

export { StatusProblemPage };