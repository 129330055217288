import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import * as React from "react";
import { animated, useSpring } from "react-spring";
import "./scss/index.scss";

var generateClassName = function generateClassName(_ref) {
  var errors = _ref.errors,
      iconLeft = _ref.iconLeft,
      styleType = _ref.styleType;
  var baseClass = "input__field";
  var errorsClass = errors && errors.length ? " input__field--error" : "";
  var iconLeftClass = iconLeft ? " input__field--left-icon" : "";
  var styleTypeClass = styleType === "grey" ? " input__field--grey" : "";
  return baseClass.concat(errorsClass, iconLeftClass, styleTypeClass);
};

var TextField = function TextField(_ref2) {
  var _ref2$label = _ref2.label,
      label = _ref2$label === void 0 ? "" : _ref2$label,
      iconLeft = _ref2.iconLeft,
      iconRight = _ref2.iconRight,
      errors = _ref2.errors,
      helpText = _ref2.helpText,
      iconLeftRef = _ref2.iconLeftRef,
      inputRef = _ref2.inputRef,
      onClickIconRight = _ref2.onClickIconRight,
      onClickIconLeft = _ref2.onClickIconLeft,
      _ref2$styleType = _ref2.styleType,
      styleType = _ref2$styleType === void 0 ? "white" : _ref2$styleType,
      rest = _objectWithoutProperties(_ref2, ["label", "iconLeft", "iconRight", "errors", "helpText", "iconLeftRef", "inputRef", "onClickIconRight", "onClickIconLeft", "styleType"]);

  var scaleButton = useSpring({
    config: {
      friction: 40,
      mass: 5,
      tension: 350
    },
    from: {
      scale: 1.07
    },
    loop: true,
    to: {
      scale: 1
    }
  });
  return React.createElement("div", {
    className: "input"
  }, iconLeft && onClickIconLeft ? React.createElement("span", {
    className: "input__icon-left",
    onClick: onClickIconLeft,
    ref: iconLeftRef
  }, iconLeft) : iconLeft && !onClickIconLeft ? React.createElement("span", {
    className: "input__icon-left"
  }, " ", iconLeft, " ") : null, iconRight && onClickIconRight ? React.createElement(animated.span, {
    className: "input__icon-right",
    onClick: onClickIconRight,
    style: scaleButton
  }, iconRight) : iconRight && !onClickIconRight ? React.createElement("span", {
    className: "input__icon-right"
  }, " ", iconRight, " ") : null, React.createElement("div", {
    className: "input__content"
  }, inputRef ? React.createElement("input", _extends({}, rest, {
    ref: inputRef,
    className: generateClassName({
      errors: errors,
      iconLeft: iconLeft,
      styleType: styleType
    })
  })) : React.createElement("input", _extends({}, rest, {
    className: generateClassName({
      errors: errors,
      iconLeft: iconLeft,
      styleType: styleType
    })
  })), label ? React.createElement("span", {
    className: "input__label"
  }, label) : null), errors && React.createElement("span", {
    className: "input__error"
  }, errors.map(function (error) {
    return error.message;
  }).join(" ")), helpText && React.createElement("span", {
    className: "input__help-text"
  }, helpText));
};

export default TextField;