import "./scss/index.scss";
import * as React from "react";
import Media from "react-responsive";
import { ChangeAmountProductsContext } from "@temp/contexts";
import { xMediumScreen } from "@styles/constants";
import { Footer, MainMenu, SearchHeaderDesktop } from "@temp/components";
import { useAppSelector, restoreSearchData, useAppDispatch, restoreCategory, restoreSubCategory } from "@temp/redux";
import { TypedSaleCategoryProducts } from "./queries";
import SaleContent from "./SaleContent";

var View = function View() {
  var _useAppSelector = useAppSelector(function (store) {
    return store.search;
  }),
      searchQueryValue = _useAppSelector.searchQueryValue;

  var _React$useContext = React.useContext(ChangeAmountProductsContext),
      maxProductsPerCategory = _React$useContext.maxProductsPerCategory;

  var dispatch = useAppDispatch();
  React.useEffect(function () {
    if (localStorage.getItem("searchQueryValue") !== searchQueryValue) {
      window.scrollTo(0, 0);
      localStorage.removeItem("sale_cache");
    }

    localStorage.setItem("searchQueryValue", searchQueryValue);
  }, [searchQueryValue]);
  React.useEffect(function () {
    if (localStorage.getItem("sale_search_clean")) {
      dispatch(restoreSearchData());
      localStorage.removeItem("sale_search_clean");
    }

    dispatch(restoreCategory());
    dispatch(restoreSubCategory());
  }, []);
  var navigationEntries = performance.getEntriesByType("navigation");
  var isPageReloaded = navigationEntries.length > 0 && navigationEntries[0].type === "reload";
  var fetchPolicyValue = isPageReloaded ? "no-cache" : localStorage.getItem("sale_cache") === "true" ? "cache-first" : "cache-and-network";
  return React.createElement(React.Fragment, null, React.createElement(MainMenu, {
    goback: false,
    search: true,
    cart: true
  }), React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(SearchHeaderDesktop, {
    sale: true
  })), React.createElement(TypedSaleCategoryProducts, {
    fetchPolicy: "".concat(fetchPolicyValue),
    variables: {
      amountByScreen: maxProductsPerCategory + 1,
      search: searchQueryValue
    }
  }, function (_ref) {
    var data = _ref.data,
        loading = _ref.loading;
    return React.createElement(SaleContent, {
      data: data,
      loading: loading,
      maxProductsPerCategory: maxProductsPerCategory
    });
  }), React.createElement(Footer, null));
};

export default View;