import _extends from "@babel/runtime/helpers/extends";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { Formik } from "formik";
import { pick } from "lodash";
import React, { useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useAccountUpdate, useAuth } from "@saleor/sdk";
import { checkoutMessages, commonMessages } from "@temp/intl";
import { AddressFormContent } from "./AddressFormContent";
var ADDRESS_FIELDS = ["city", "companyName", "countryArea", "firstName", "firstNameSelect", "firstNameWrite", "lastName", "country", "phone", "streetAddress1", "streetAddress2", "email", "latitude", "longitude", "neighborhood", "city2", "region2", "country2"];
export var AddressForm = function AddressForm(_ref) {
  var _JSON$parse, _obligatoryFieldIdent;

  var address = _ref.address,
      handleSubmit = _ref.handleSubmit,
      formId = _ref.formId,
      defaultValue = _ref.defaultValue,
      countriesOptions = _ref.countriesOptions,
      nameOptions = _ref.nameOptions,
      neighborhoodsAttention = _ref.neighborhoodsAttention,
      errorsQuery = _ref.errorsQuery,
      setErrorsQuery = _ref.setErrorsQuery,
      showIdentityCard = _ref.showIdentityCard,
      showUserName = _ref.showUserName,
      showIdentityCardType = _ref.showIdentityCardType,
      showUserEmail = _ref.showUserEmail,
      userIdentityCard = _ref.userIdentityCard,
      userIdentityCardType = _ref.userIdentityCardType,
      userEmail = _ref.userEmail,
      obligatoryFieldIdentityCard = _ref.obligatoryFieldIdentityCard,
      obligatoryFieldIdentityCardType = _ref.obligatoryFieldIdentityCardType,
      obligatoryFieldUserEmail = _ref.obligatoryFieldUserEmail,
      handleErrorName = _ref.handleErrorName,
      handleLoadingUserName = _ref.handleLoadingUserName;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      obligateName = _React$useState2[0],
      setObligateName = _React$useState2[1];

  var _React$useState3 = React.useState(false),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      obligateIdentityCard = _React$useState4[0],
      setObligateIdentityCard = _React$useState4[1];

  var _React$useState5 = React.useState(false),
      _React$useState6 = _slicedToArray(_React$useState5, 2),
      obligateIdentityCardType = _React$useState6[0],
      setObligateIdentityCardType = _React$useState6[1];

  var _React$useState7 = React.useState(false),
      _React$useState8 = _slicedToArray(_React$useState7, 2),
      obligateUserEmail = _React$useState8[0],
      setObligateUserEmail = _React$useState8[1];

  var _React$useState9 = React.useState(false),
      _React$useState10 = _slicedToArray(_React$useState9, 2),
      submitFlag = _React$useState10[0],
      setSubmitFlag = _React$useState10[1];

  var _React$useState11 = React.useState(false),
      _React$useState12 = _slicedToArray(_React$useState11, 2),
      enterUp = _React$useState12[0],
      setEnterUp = _React$useState12[1];

  var _React$useState13 = React.useState(false),
      _React$useState14 = _slicedToArray(_React$useState13, 2),
      enterDown = _React$useState14[0],
      setEnterDown = _React$useState14[1];

  var _React$useState15 = React.useState(),
      _React$useState16 = _slicedToArray(_React$useState15, 2),
      valuesSubmit = _React$useState16[0],
      setValuesSubmit = _React$useState16[1];

  var _React$useState17 = React.useState(""),
      _React$useState18 = _slicedToArray(_React$useState17, 2),
      nameValue = _React$useState18[0],
      setNameValue = _React$useState18[1];

  var _React$useState19 = React.useState(userIdentityCard || ""),
      _React$useState20 = _slicedToArray(_React$useState19, 2),
      identityCardValue = _React$useState20[0],
      setIdentityCardValue = _React$useState20[1];

  var _React$useState21 = React.useState(userEmail || ""),
      _React$useState22 = _slicedToArray(_React$useState21, 2),
      userEmailValue = _React$useState22[0],
      setUserEmailValue = _React$useState22[1];

  var _useAccountUpdate = useAccountUpdate(),
      _useAccountUpdate2 = _slicedToArray(_useAccountUpdate, 2),
      setAccountUpdate = _useAccountUpdate2[0],
      _useAccountUpdate2$ = _useAccountUpdate2[1],
      error = _useAccountUpdate2$.error,
      loading = _useAccountUpdate2$.loading;

  var _React$useState23 = React.useState(),
      _React$useState24 = _slicedToArray(_React$useState23, 2),
      errorName = _React$useState24[0],
      setErrorName = _React$useState24[1];

  var _React$useState25 = React.useState(),
      _React$useState26 = _slicedToArray(_React$useState25, 2),
      valuesState = _React$useState26[0],
      setValuesState = _React$useState26[1];

  var intl = useIntl();

  var _useAuth = useAuth(),
      user = _useAuth.user;

  var addressWithPickedFields = {};

  if (address) {
    addressWithPickedFields = pick(address, ADDRESS_FIELDS);
  }

  if (address === null || address === void 0 ? void 0 : address.address) {
    addressWithPickedFields = pick(address === null || address === void 0 ? void 0 : address.address, ADDRESS_FIELDS);
  }

  if (defaultValue) {
    addressWithPickedFields.country = defaultValue;
  }

  var _React$useState27 = React.useState(userIdentityCardType || ((_JSON$parse = JSON.parse(obligatoryFieldIdentityCardType && ((_obligatoryFieldIdent = obligatoryFieldIdentityCardType[0]) === null || _obligatoryFieldIdent === void 0 ? void 0 : _obligatoryFieldIdent.fieldValues) || "{}")) === null || _JSON$parse === void 0 ? void 0 : _JSON$parse.values[0])),
      _React$useState28 = _slicedToArray(_React$useState27, 2),
      identityCardTypeValue = _React$useState28[0],
      setIdentityCardTypeValue = _React$useState28[1];

  var handleSubmitKeyDown = React.useCallback(function () {
    setSubmitFlag(true);
  }, [setSubmitFlag]);
  var handleOnChange = React.useCallback(function (e) {
    setNameValue(e.target.value);
  }, [setNameValue]);
  var handleOnChangeIdentityCard = React.useCallback(function (e) {
    setIdentityCardValue(e.target.value);
  }, [setIdentityCardValue]);
  var handleOnChangeIdentityCardType = React.useCallback(function (value) {
    setIdentityCardTypeValue(value);
  }, [setIdentityCardTypeValue]);
  var handleOnChangeUserEmail = React.useCallback(function (e) {
    setUserEmailValue(e.target.value);
  }, [setUserEmailValue]);
  var clearError = React.useCallback(function (flag) {
    switch (flag) {
      case "userName":
        setErrorName(function (errorName) {
          return _objectSpread(_objectSpread({}, errorName), {}, {
            userName: ""
          });
        });
        break;

      case "userAddress":
        setErrorName(function (errorName) {
          return _objectSpread(_objectSpread({}, errorName), {}, {
            userAddress: ""
          });
        });
        break;

      case "identityCardType":
        setErrorName(function (errorName) {
          return _objectSpread(_objectSpread({}, errorName), {}, {
            identityCardType: ""
          });
        });
        break;

      case "identityCard":
        setErrorName(function (errorName) {
          return _objectSpread(_objectSpread({}, errorName), {}, {
            identityCard: ""
          });
        });
        break;

      case "userEmail":
        setErrorName(function (errorName) {
          return _objectSpread(_objectSpread({}, errorName), {}, {
            userEmail: ""
          });
        });
        break;

      case "userNeighborhood":
        setErrorName(function (errorName) {
          return _objectSpread(_objectSpread({}, errorName), {}, {
            userNeighborhood: ""
          });
        });
        break;

      case "userFirstNameWrite":
        setErrorName(function (errorName) {
          return _objectSpread(_objectSpread({}, errorName), {}, {
            userFirstNameWrite: ""
          });
        });
        break;

      default:
        setErrorName({});
        break;
    }
  }, [setErrorName, errorName]);
  var setFocusField = React.useCallback(function (errorName, showUserName, showIdentityCard, showIdentityCardType, showUserEmail) {
    if ((errorName === null || errorName === void 0 ? void 0 : errorName.userName) && showUserName && document.getElementById("userName")) {
      var _document$getElementB;

      return (_document$getElementB = document.getElementById("userName")) === null || _document$getElementB === void 0 ? void 0 : _document$getElementB.focus();
    }

    if ((errorName === null || errorName === void 0 ? void 0 : errorName.identityCardType) && showIdentityCardType && document.getElementById("identityCardType")) {
      var _document$getElementB2;

      return (_document$getElementB2 = document.getElementById("identityCardType")) === null || _document$getElementB2 === void 0 ? void 0 : _document$getElementB2.focus();
    }

    if ((errorName === null || errorName === void 0 ? void 0 : errorName.identityCard) && showIdentityCard && document.getElementById("identityCard")) {
      var _document$getElementB3;

      return (_document$getElementB3 = document.getElementById("identityCard")) === null || _document$getElementB3 === void 0 ? void 0 : _document$getElementB3.focus();
    }

    if ((errorName === null || errorName === void 0 ? void 0 : errorName.userEmail) && showUserEmail && document.getElementById("userEmail")) {
      var _document$getElementB4;

      return (_document$getElementB4 = document.getElementById("userEmail")) === null || _document$getElementB4 === void 0 ? void 0 : _document$getElementB4.focus();
    }

    if ((errorName === null || errorName === void 0 ? void 0 : errorName.userAddress) && document.getElementById("userAddress")) {
      var _document$getElementB5;

      return (_document$getElementB5 = document.getElementById("userAddress")) === null || _document$getElementB5 === void 0 ? void 0 : _document$getElementB5.focus();
    }

    if ((errorName === null || errorName === void 0 ? void 0 : errorName.userNeighborhood) && document.getElementById("userNeighborhood")) {
      var _document$getElementB6;

      return (_document$getElementB6 = document.getElementById("userNeighborhood")) === null || _document$getElementB6 === void 0 ? void 0 : _document$getElementB6.focus();
    }

    if ((errorName === null || errorName === void 0 ? void 0 : errorName.userFirstNameWrite) && document.getElementById("userFirstNameWrite")) {
      var _document$getElementB7;

      return (_document$getElementB7 = document.getElementById("userFirstNameWrite")) === null || _document$getElementB7 === void 0 ? void 0 : _document$getElementB7.focus();
    }
  }, []);
  useEffect(function () {
    var _valuesSubmit$streetA;

    if (!obligateName) {
      var _valuesState$streetAd, _valuesState$streetAd2, _valuesState$streetAd3;

      if (error && !loading && ((valuesState === null || valuesState === void 0 ? void 0 : valuesState.streetAddress1) === "" || (valuesState === null || valuesState === void 0 ? void 0 : (_valuesState$streetAd = valuesState.streetAddress1) === null || _valuesState$streetAd === void 0 ? void 0 : _valuesState$streetAd.toLowerCase()) === "no definida")) {
        var _error$extraInfo;

        setErrorName({
          userName: error === null || error === void 0 ? void 0 : (_error$extraInfo = error.extraInfo) === null || _error$extraInfo === void 0 ? void 0 : _error$extraInfo.userInputErrors,
          userAddress: [{
            message: intl.formatMessage(commonMessages.fieldRequired)
          }]
        });
      }

      if (!error && !loading && ((valuesState === null || valuesState === void 0 ? void 0 : valuesState.streetAddress1) === "" || (valuesState === null || valuesState === void 0 ? void 0 : (_valuesState$streetAd2 = valuesState.streetAddress1) === null || _valuesState$streetAd2 === void 0 ? void 0 : _valuesState$streetAd2.toLowerCase()) === "no definida")) {
        setErrorName({
          userAddress: [{
            message: intl.formatMessage(commonMessages.fieldRequired)
          }]
        });
      }

      if (error && !loading && (valuesState === null || valuesState === void 0 ? void 0 : valuesState.streetAddress1) !== "" && (valuesState === null || valuesState === void 0 ? void 0 : (_valuesState$streetAd3 = valuesState.streetAddress1) === null || _valuesState$streetAd3 === void 0 ? void 0 : _valuesState$streetAd3.toLowerCase()) !== "no definida") {
        var _error$extraInfo2;

        setErrorName({
          userName: error === null || error === void 0 ? void 0 : (_error$extraInfo2 = error.extraInfo) === null || _error$extraInfo2 === void 0 ? void 0 : _error$extraInfo2.userInputErrors
        });
      }
    }

    if (!error && !loading && ((valuesSubmit === null || valuesSubmit === void 0 ? void 0 : valuesSubmit.streetAddress1) !== "" || (valuesSubmit === null || valuesSubmit === void 0 ? void 0 : (_valuesSubmit$streetA = valuesSubmit.streetAddress1) === null || _valuesSubmit$streetA === void 0 ? void 0 : _valuesSubmit$streetA.toLowerCase()) !== "no definida")) {
      setSubmitFlag(false);
    }

    return function () {
      setErrorName(undefined);
      setSubmitFlag(false);
    };
  }, [error, loading]);
  useEffect(function () {
    if (handleLoadingUserName) {
      handleLoadingUserName(loading);
    }

    return function () {
      if (handleLoadingUserName) {
        handleLoadingUserName(false);
      }
    };
  }, [loading]);
  useEffect(function () {
    if (handleErrorName) {
      handleErrorName(errorName);
    }

    return function () {
      if (handleErrorName) {
        handleErrorName(undefined);
      }
    };
  }, [errorName]);
  useEffect(function () {
    var _valuesSubmit$streetA2;

    if (!loading && !error && ((errorName === null || errorName === void 0 ? void 0 : errorName.userName) === undefined || (errorName === null || errorName === void 0 ? void 0 : errorName.userName) === "") && ((errorName === null || errorName === void 0 ? void 0 : errorName.userAddress) === undefined || (errorName === null || errorName === void 0 ? void 0 : errorName.userAddress) === "") && ((errorName === null || errorName === void 0 ? void 0 : errorName.identityCard) === undefined || (errorName === null || errorName === void 0 ? void 0 : errorName.identityCard) === "") && ((errorName === null || errorName === void 0 ? void 0 : errorName.identityCardType) === undefined || (errorName === null || errorName === void 0 ? void 0 : errorName.identityCardType) === "") && ((errorName === null || errorName === void 0 ? void 0 : errorName.userEmail) === undefined || (errorName === null || errorName === void 0 ? void 0 : errorName.userEmail) === "") && ((errorName === null || errorName === void 0 ? void 0 : errorName.userNeighborhood) === undefined || (errorName === null || errorName === void 0 ? void 0 : errorName.userNeighborhood) === "") && handleSubmit && valuesSubmit && ((valuesSubmit === null || valuesSubmit === void 0 ? void 0 : valuesSubmit.streetAddress1) !== "" || (valuesSubmit === null || valuesSubmit === void 0 ? void 0 : (_valuesSubmit$streetA2 = valuesSubmit.streetAddress1) === null || _valuesSubmit$streetA2 === void 0 ? void 0 : _valuesSubmit$streetA2.toLowerCase()) !== "no definida") && !submitFlag && !enterDown) {
      setEnterUp(true);
      handleSubmit(valuesSubmit);
    }

    return function () {};
  }, [loading, error, errorName, submitFlag]);
  return React.createElement(Formik, {
    initialValues: addressWithPickedFields,
    enableReinitialize: true,
    validate: function validate(values) {
      var _values$firstNameSele, _values$firstNameSele2;

      var errores = {};

      if ((values === null || values === void 0 ? void 0 : (_values$firstNameSele = values.firstNameSelect) === null || _values$firstNameSele === void 0 ? void 0 : (_values$firstNameSele2 = _values$firstNameSele.id) === null || _values$firstNameSele2 === void 0 ? void 0 : _values$firstNameSele2.toUpperCase()) === intl.formatMessage(checkoutMessages.other).toUpperCase() && (values === null || values === void 0 ? void 0 : values.firstNameWrite) === "") {
        errores.firstNameWrite = React.createElement(FormattedMessage, {
          id: "@next.components.organisms.AddressForm.AddressForm.806123233",
          defaultMessage: "This field is required"
        });
      }
    },
    onSubmit: function onSubmit(values, _ref2) {
      var setSubmitting = _ref2.setSubmitting;
      var execution = false;
      var error = false;
      var errorAccountUpdate = false;

      var currentErrorName = _objectSpread({}, errorName);

      setEnterUp(false);
      setEnterDown(false);
      setValuesState(values);
      setSubmitFlag(true);

      if (handleSubmit) {
        var _values$neighborhood, _values$neighborhood2, _values$neighborhood3, _values$firstNameSele3, _values$firstNameSele4, _values$firstNameWrit, _values$streetAddress;

        if (showUserName && nameValue.trim() === "") {
          execution = true;
          error = true;
          errorAccountUpdate = true;
          setObligateName(true);
          currentErrorName = _objectSpread(_objectSpread({}, currentErrorName), {}, {
            userName: [{
              message: intl.formatMessage(commonMessages.fieldRequired)
            }]
          });
        }

        if (showIdentityCard && identityCardValue.trim() === "") {
          execution = true;
          error = true;
          errorAccountUpdate = true;
          setObligateIdentityCard(true);
          currentErrorName = _objectSpread(_objectSpread({}, currentErrorName), {}, {
            identityCard: [{
              message: intl.formatMessage(commonMessages.fieldRequired)
            }]
          });
        }

        if (showIdentityCardType && !identityCardTypeValue) {
          execution = true;
          error = true;
          errorAccountUpdate = true;
          setObligateIdentityCardType(true);
          currentErrorName = _objectSpread(_objectSpread({}, currentErrorName), {}, {
            identityCardType: [{
              message: intl.formatMessage(commonMessages.fieldRequired)
            }]
          });
        }

        if (showUserEmail && userEmailValue.trim() === "") {
          execution = true;
          error = true;
          errorAccountUpdate = true;
          setObligateUserEmail(true);
          currentErrorName = _objectSpread(_objectSpread({}, currentErrorName), {}, {
            userEmail: [{
              message: intl.formatMessage(commonMessages.fieldRequired)
            }]
          });
        }

        if (values.streetAddress1 === "" || values.streetAddress1 === "no definida") {
          execution = true;
          error = true;
          errorAccountUpdate = true;
          currentErrorName = _objectSpread(_objectSpread({}, currentErrorName), {}, {
            userAddress: [{
              message: intl.formatMessage(commonMessages.fieldRequired)
            }]
          });
        }

        if (!(values === null || values === void 0 ? void 0 : values.neighborhood) || (values === null || values === void 0 ? void 0 : (_values$neighborhood = values.neighborhood) === null || _values$neighborhood === void 0 ? void 0 : _values$neighborhood.id) === "" || (values === null || values === void 0 ? void 0 : (_values$neighborhood2 = values.neighborhood) === null || _values$neighborhood2 === void 0 ? void 0 : _values$neighborhood2.id) === null || (values === null || values === void 0 ? void 0 : (_values$neighborhood3 = values.neighborhood) === null || _values$neighborhood3 === void 0 ? void 0 : _values$neighborhood3.id) === undefined) {
          execution = true;
          error = true;
          errorAccountUpdate = true;
          currentErrorName = _objectSpread(_objectSpread({}, currentErrorName), {}, {
            userNeighborhood: [{
              message: intl.formatMessage(commonMessages.fieldRequired)
            }]
          });
        }

        if ((values === null || values === void 0 ? void 0 : (_values$firstNameSele3 = values.firstNameSelect) === null || _values$firstNameSele3 === void 0 ? void 0 : (_values$firstNameSele4 = _values$firstNameSele3.id) === null || _values$firstNameSele4 === void 0 ? void 0 : _values$firstNameSele4.toUpperCase()) === intl.formatMessage(checkoutMessages.other).toUpperCase() && (values === null || values === void 0 ? void 0 : (_values$firstNameWrit = values.firstNameWrite) === null || _values$firstNameWrit === void 0 ? void 0 : _values$firstNameWrit.trim()) === "") {
          execution = true;
          error = true;
          errorAccountUpdate = true;
          currentErrorName = _objectSpread(_objectSpread({}, currentErrorName), {}, {
            userFirstNameWrite: [{
              message: intl.formatMessage(commonMessages.fieldRequired)
            }]
          });
        }

        if (showUserName && nameValue.trim() !== "" && obligateName) {
          setObligateName(false);
        }

        if (showIdentityCard && identityCardValue.trim() !== "" && obligateIdentityCard) {
          setObligateIdentityCard(false);
        }

        if (showIdentityCardType && (identityCardTypeValue === null || identityCardTypeValue === void 0 ? void 0 : identityCardTypeValue.value.trim()) !== "" && obligateIdentityCardType) {
          setObligateIdentityCardType(false);
        }

        if (showUserEmail && userEmailValue.trim() !== "" && obligateUserEmail) {
          setObligateUserEmail(false);
        }

        var accountUpdateInput = {
          firstName: user === null || user === void 0 ? void 0 : user.firstName,
          identityCard: user === null || user === void 0 ? void 0 : user.identityCard,
          identityCardType: user === null || user === void 0 ? void 0 : user.identityCardType,
          userEmail: user === null || user === void 0 ? void 0 : user.userEmail
        };

        if (showUserName && nameValue.trim() !== "") {
          accountUpdateInput.firstName = nameValue;
        }

        if (showIdentityCard && identityCardValue.trim() !== "") {
          accountUpdateInput.identityCard = identityCardValue;
        }

        if (showIdentityCardType) {
          accountUpdateInput.identityCardType = identityCardTypeValue === null || identityCardTypeValue === void 0 ? void 0 : identityCardTypeValue.value;
        }

        if (showUserEmail) {
          accountUpdateInput.userEmail = userEmailValue;
        }

        if (!errorAccountUpdate) {
          setAccountUpdate({
            input: accountUpdateInput
          });
          execution = true;
        }

        setErrorName(currentErrorName);
        setFocusField(currentErrorName, showUserName, showIdentityCard, showIdentityCardType, showUserEmail);

        if (!execution && (values.streetAddress1 !== "" || ((_values$streetAddress = values.streetAddress1) === null || _values$streetAddress === void 0 ? void 0 : _values$streetAddress.toLowerCase()) !== "no definida") && !error && !enterUp) {
          setEnterDown(true);
          setSubmitFlag(false);
          handleSubmit(values);
        }

        setValuesSubmit(values);
      }

      setSubmitting(false);
    }
  }, function (_ref3) {
    var handleChange = _ref3.handleChange,
        handleSubmit = _ref3.handleSubmit,
        handleBlur = _ref3.handleBlur,
        values = _ref3.values,
        errors = _ref3.errors,
        setFieldValue = _ref3.setFieldValue,
        setFieldTouched = _ref3.setFieldTouched;
    return React.createElement(AddressFormContent, _extends({
      validateErrors: errors
    }, {
      countriesOptions: countriesOptions,
      defaultValue: defaultValue,
      errorName: errorName,
      errorsQuery: errorsQuery,
      formId: formId,
      identityCardValue: identityCardValue,
      identityCardTypeValue: identityCardTypeValue,
      userEmailValue: userEmailValue,
      nameOptions: nameOptions,
      neighborhoodsAttention: neighborhoodsAttention,
      obligatoryFieldIdentityCard: obligatoryFieldIdentityCard,
      obligatoryFieldIdentityCardType: obligatoryFieldIdentityCardType,
      obligatoryFieldUserEmail: obligatoryFieldUserEmail,
      clearError: clearError,
      handleBlur: handleBlur,
      handleChange: handleChange,
      handleSubmit: handleSubmit,
      handleSubmitKeyDown: handleSubmitKeyDown,
      handleOnChange: handleOnChange,
      handleOnChangeIdentityCard: handleOnChangeIdentityCard,
      handleOnChangeIdentityCardType: handleOnChangeIdentityCardType,
      handleOnChangeUserEmail: handleOnChangeUserEmail,
      setErrorsQuery: setErrorsQuery,
      setFieldTouched: setFieldTouched,
      setFieldValue: setFieldValue,
      showIdentityCard: showIdentityCard,
      showIdentityCardType: showIdentityCardType,
      showUserEmail: showUserEmail,
      showUserName: showUserName,
      nameValue: nameValue,
      values: values
    }));
  });
};