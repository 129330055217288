import * as React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { OrderDataDelivery } from "@pages";
import { Account } from "@temp/views/Account";
import { OrderFinishedDeliveryPage } from "@temp/views/OrderFinishedDelivery";
import { ReportSummaryByAreaPage } from "@temp/views/ReportSummaryByArea";
import { OrderDetails } from "../../userAccount/views";
import { HomeeDeliveryPage } from "../../views/HomeeDelivery";
import * as paths from "./pathsDelivery";
export var RoutesDelivery = function RoutesDelivery() {
  return React.createElement(Routes, null, React.createElement(Route, {
    path: paths.baseDeliveryUrl,
    element: React.createElement(HomeeDeliveryPage, null)
  }), React.createElement(Route, {
    path: paths.accountDelivery,
    element: React.createElement(Account, null)
  }), React.createElement(Route, {
    path: paths.summaryByAreaDelivery,
    element: React.createElement(ReportSummaryByAreaPage, null)
  }), React.createElement(Route, {
    path: paths.orderFinishedDeliveryUrl,
    element: React.createElement(OrderFinishedDeliveryPage, null)
  }), React.createElement(Route, {
    path: paths.orderDeliveryUrl,
    element: React.createElement(OrderDataDelivery, null)
  }), React.createElement(Route, {
    path: paths.orderDetailDeliveryUrl,
    element: React.createElement(OrderDetails, null)
  }), React.createElement(Route, {
    path: "*",
    element: React.createElement(Navigate, {
      to: paths.baseDeliveryUrl,
      replace: true
    })
  }));
};
export default RoutesDelivery;