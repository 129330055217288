import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Media from "react-responsive";
import ReactSVG from "react-svg";
import { CachedImage } from "@components/molecules";
import { xLargeScreen } from "@styles/constants";
import { translateOrderStatusDelivery } from "@temp/intl";
import { useNavigate } from "react-router-dom";
import { maybe } from "../../../../core/utils";
import * as S from "./styles";

var logoBasketImg = require("../../../../images/basketStatus.svg");

export var OrderTabelDelivery = function OrderTabelDelivery(_ref) {
  var orders = _ref.orders,
      inProgressFlag = _ref.inProgressFlag;
  var intl = useIntl();
  var navigate = useNavigate();
  return React.createElement(React.Fragment, null, orders && orders.map(function (order) {
    var _order$node, _order$node$lastFulfi, _order$node$lastFulfi2;

    var dateRender = new Date(order.node.created).toLocaleString("en-GB", {
      hour12: true,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit"
    });
    var finishedDateRender = (order === null || order === void 0 ? void 0 : (_order$node = order.node) === null || _order$node === void 0 ? void 0 : (_order$node$lastFulfi = _order$node.lastFulfillment) === null || _order$node$lastFulfi === void 0 ? void 0 : _order$node$lastFulfi.finishedAt) ? new Date((_order$node$lastFulfi2 = order.node.lastFulfillment) === null || _order$node$lastFulfi2 === void 0 ? void 0 : _order$node$lastFulfi2.finishedAt).toLocaleString("en-GB", {
      hour12: true,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit"
    }) : null;
    var orderDetail = "/delivery/order/".concat(order.node.token);
    return React.createElement(Media, {
      key: order.node.number,
      maxWidth: xLargeScreen
    }, function (matches) {
      var _order$node3, _order$node4, _order$node4$shipping, _order$node5, _order$node5$shipping, _order$node6, _order$node6$shipping, _order$node7, _order$node8, _order$node9, _order$node10, _order$node11, _order$node13, _order$node14, _order$node15, _order$node16, _order$node17;

      return matches ? React.createElement(React.Fragment, null, React.createElement(S.Row, {
        "data-test": "orderEntry",
        "data-test-id": order.node.number,
        key: order.node.number,
        onClick: function onClick(evt) {
          var _order$node2;

          evt.stopPropagation();
          localStorage.setItem("order_token", order === null || order === void 0 ? void 0 : (_order$node2 = order.node) === null || _order$node2 === void 0 ? void 0 : _order$node2.token);
          navigate(orderDetail);
        }
      }, React.createElement(S.Image, null, order.node.trade.logoImage ? React.createElement(S.ImageShop, null, React.createElement(CachedImage, {
        url: maybe(function () {
          return order.node.trade.logoImage.url;
        }),
        alt: maybe(function () {
          return order.node.trade.logoImage.alt ? order.node.trade.logoImage.alt : "";
        }, "")
      }, React.createElement(React.Fragment, null))) : React.createElement(ReactSVG, {
        path: logoBasketImg
      })), React.createElement(S.OrderData, null, React.createElement(S.OrderFirstLine, null, React.createElement(S.OrderNumber, null, React.createElement(FormattedMessage, {
        id: "@next.components.molecules.OrderTabelDelivery.OrderTabelDelivery.2768410132",
        defaultMessage: "order"
      }), " ", " #", order.node.number), React.createElement(S.OrderStatus, null, React.createElement(S.OrderStatusParragraph, null, translateOrderStatusDelivery(order.node.status.toUpperCase(), intl), order.node.status === "SCHEDULED" && order.node.scheduledAt && function () {
        var scheduledDate = new Date(order.node.scheduledAt);
        var dateScheduled = scheduledDate.toLocaleDateString("es-ES", {
          day: "2-digit",
          month: "short"
        });
        var time = scheduledDate.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true
        });
        time = time.replace(/\./g, "").replace(/\s/g, "").replace(/(p|a)/i, " $1");
        return React.createElement("span", {
          style: {
            display: "block",
            fontSize: 12,
            margin: 0,
            marginTop: -6
          }
        }, "(", dateScheduled !== new Date(order.node.created).toLocaleDateString("es-ES", {
          day: "2-digit",
          month: "short"
        }) ? "".concat(dateScheduled, " ") : "", time, ")");
      }()))), React.createElement(S.OrderTrade, null, order.node.trade.name), React.createElement(S.OrderProducts, null, order.node.amountOfProducts, " ", React.createElement(S.OrderProductsParragraph, null, React.createElement(FormattedMessage, {
        id: "@next.components.molecules.OrderTabelDelivery.OrderTabelDelivery.1128268068",
        defaultMessage: "products"
      })))), React.createElement(S.OrderGlobalDate, null, React.createElement(S.OrderDate, null, React.createElement(S.OrderDateSpanLabel, null, React.createElement(FormattedMessage, {
        id: "@next.components.molecules.OrderTabelDelivery.OrderTabelDelivery.2076378394",
        defaultMessage: "Order date"
      }), ": "), React.createElement(S.OrderDateSpan, null, dateRender)), finishedDateRender && React.createElement(S.OrderDate, null, React.createElement(S.OrderDateSpanLabel, null, React.createElement(FormattedMessage, {
        id: "@next.components.molecules.OrderTabelDelivery.OrderTabelDelivery.3803213041",
        defaultMessage: "Finished date"
      }), ": "), React.createElement(S.OrderDateSpan, null, finishedDateRender))), ((_order$node3 = order.node) === null || _order$node3 === void 0 ? void 0 : _order$node3.shippingAddress) && React.createElement(S.OrderCustomerData, null, React.createElement(S.OrderCustomerDataParragraph, null, React.createElement(S.OrderCustomerDataSpanFirst, null, React.createElement(FormattedMessage, {
        id: "@next.components.molecules.OrderTabelDelivery.OrderTabelDelivery.2132027491",
        defaultMessage: "address"
      }), ": "), React.createElement(S.OrderCustomerDataSpan, null, (_order$node4 = order.node) === null || _order$node4 === void 0 ? void 0 : (_order$node4$shipping = _order$node4.shippingAddress) === null || _order$node4$shipping === void 0 ? void 0 : _order$node4$shipping.streetAddress1, " ", (_order$node5 = order.node) === null || _order$node5 === void 0 ? void 0 : (_order$node5$shipping = _order$node5.shippingAddress) === null || _order$node5$shipping === void 0 ? void 0 : _order$node5$shipping.streetAddress2)), React.createElement(S.OrderCustomerDataParragraph, null, React.createElement(S.OrderCustomerDataSpanFirst, null, React.createElement(FormattedMessage, {
        id: "@next.components.molecules.OrderTabelDelivery.OrderTabelDelivery.2473649715",
        defaultMessage: "neighborhood"
      }), ": "), React.createElement(S.OrderCustomerDataSpan, null, (_order$node6 = order.node) === null || _order$node6 === void 0 ? void 0 : (_order$node6$shipping = _order$node6.shippingAddress) === null || _order$node6$shipping === void 0 ? void 0 : _order$node6$shipping.neighborhood.name)), React.createElement(S.OrderCustomerDataParragraph, null, React.createElement(S.OrderCustomerDataSpanFirst, null, React.createElement(FormattedMessage, {
        id: "@next.components.molecules.OrderTabelDelivery.OrderTabelDelivery.2825310502",
        defaultMessage: "customer name"
      }), ": "), React.createElement(S.OrderCustomerDataSpan, null, order.node.user.firstName))), (order === null || order === void 0 ? void 0 : (_order$node7 = order.node) === null || _order$node7 === void 0 ? void 0 : _order$node7.status) !== "DELIVERED" && (order === null || order === void 0 ? void 0 : (_order$node8 = order.node) === null || _order$node8 === void 0 ? void 0 : _order$node8.status) !== "UNDELIVERED" && (order === null || order === void 0 ? void 0 : (_order$node9 = order.node) === null || _order$node9 === void 0 ? void 0 : _order$node9.status) !== "CANCELED" && (order === null || order === void 0 ? void 0 : (_order$node10 = order.node) === null || _order$node10 === void 0 ? void 0 : _order$node10.status) !== "TIMEOUT" && (order === null || order === void 0 ? void 0 : (_order$node11 = order.node) === null || _order$node11 === void 0 ? void 0 : _order$node11.status) !== "CANCELED_FULFILLMENT" ? React.createElement(S.CompleteMobile, null, React.createElement(S.CompleteSpan, null, React.createElement(FormattedMessage, {
        id: "@next.components.molecules.OrderTabelDelivery.OrderTabelDelivery.1940650041",
        defaultMessage: "View or complete"
      }))) : React.createElement(S.OrderStatusDetail, null, React.createElement(FormattedMessage, {
        id: "@next.components.molecules.OrderTabelDelivery.OrderTabelDelivery.3796752745",
        defaultMessage: "see detail"
      })))) : React.createElement(S.RowGrather, {
        inProgressState: inProgressFlag,
        "data-test": "orderEntry",
        "data-test-id": order.node.number,
        key: "RowGrather".concat(order.node.number),
        onClick: function onClick(evt) {
          var _order$node12;

          evt.stopPropagation();
          localStorage.setItem("order_token", order === null || order === void 0 ? void 0 : (_order$node12 = order.node) === null || _order$node12 === void 0 ? void 0 : _order$node12.token);
          navigate(orderDetail);
        }
      }, React.createElement(S.ImageGrather, null, order.node.trade.logoImage ? React.createElement(S.ImageShopGrather, null, React.createElement(CachedImage, {
        url: maybe(function () {
          return order.node.trade.logoImage.url;
        }),
        alt: maybe(function () {
          return order.node.trade.logoImage.alt ? order.node.trade.logoImage.alt : "";
        }, "")
      }, React.createElement(React.Fragment, null))) : React.createElement(ReactSVG, {
        path: logoBasketImg
      })), React.createElement(S.OrderTradeGrather, null, order.node.trade.name), React.createElement(S.OrderNumberGrather, null, React.createElement(FormattedMessage, {
        id: "@next.components.molecules.OrderTabelDelivery.OrderTabelDelivery.2768410132",
        defaultMessage: "order"
      }), " ", " #", order.node.number), React.createElement(S.OrderProductsGrather, null, order.node.amountOfProducts, " "), React.createElement(S.OrderStatusParragraphGrather, null, translateOrderStatusDelivery(order.node.status.toUpperCase(), intl), order.node.status === "SCHEDULED" && order.node.scheduledAt && function () {
        var scheduledDate = new Date(order.node.scheduledAt);
        var dateScheduled = scheduledDate.toLocaleDateString("es-ES", {
          day: "2-digit",
          month: "short"
        });
        var time = scheduledDate.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true
        });
        time = time.replace(/\./g, "").replace(/\s/g, "").replace(/(p|a)/i, " $1");
        return React.createElement("span", {
          style: {
            display: "block",
            fontSize: 12,
            margin: 0,
            marginTop: -6
          }
        }, "(", dateScheduled !== new Date(order.node.created).toLocaleDateString("es-ES", {
          day: "2-digit",
          month: "short"
        }) ? "".concat(dateScheduled, " ") : "", time, ")");
      }()), React.createElement(S.OrderDateGrather, null, dateRender), finishedDateRender && React.createElement(S.OrderDateGrather, null, finishedDateRender), (order === null || order === void 0 ? void 0 : (_order$node13 = order.node) === null || _order$node13 === void 0 ? void 0 : _order$node13.status) !== "DELIVERED" && (order === null || order === void 0 ? void 0 : (_order$node14 = order.node) === null || _order$node14 === void 0 ? void 0 : _order$node14.status) !== "UNDELIVERED" && (order === null || order === void 0 ? void 0 : (_order$node15 = order.node) === null || _order$node15 === void 0 ? void 0 : _order$node15.status) !== "CANCELED" && (order === null || order === void 0 ? void 0 : (_order$node16 = order.node) === null || _order$node16 === void 0 ? void 0 : _order$node16.status) !== "TIMEOUT" && (order === null || order === void 0 ? void 0 : (_order$node17 = order.node) === null || _order$node17 === void 0 ? void 0 : _order$node17.status) !== "CANCELED_FULFILLMENT" ? React.createElement(S.Complete, null, React.createElement(S.CompleteSpan, null, React.createElement(FormattedMessage, {
        id: "@next.components.molecules.OrderTabelDelivery.OrderTabelDelivery.1940650041",
        defaultMessage: "View or complete"
      }))) : React.createElement(S.OrderStatusDetailGrather, null, React.createElement(FormattedMessage, {
        id: "@next.components.molecules.OrderTabelDelivery.OrderTabelDelivery.3796752745",
        defaultMessage: "see detail"
      })));
    });
  }));
};
OrderTabelDelivery.displayName = "OrderTabelDelivery";