import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n  padding: 10px 0;\n  align-self: center;\n  text-align: center;\n  color: rgb(247, 98, 104);\n  background: #efeff2;\n  border-radius: 8px;\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  align-self: center;\n  text-align: center;\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  margin-bottom: 30px;\n  display: grid;\n  grid-template-columns: 50% 50%;\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  display: block;\n  background-color: ", ";\n  padding: 20px;\n  ", "\n  font-size: ", ";\n  cursor: pointer;\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n    padding: 30px 20px;\n    padding-bottom: 10px;\n  "]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  padding: 30px;\n  padding-bottom: 10px;\n\n  ", "\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 200px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  svg {\n    transform: scale(5);\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  font-size: 1.5rem;\n  font-weight: ", ";\n  text-align: center;\n  padding: 0 0 0.5rem 0;\n  margin-bottom: 1rem;\n  border-bottom: 1px solid rgba(50, 50, 50, 0.1);\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  border-bottom: 1px solid\n    ", ";\n  margin-bottom: 30px;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n  cursor: pointer;\n  &::after {\n    content: \"\";\n    width: 100%;\n    height: 2px;\n    display: block;\n    background-color: #0000ff;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  margin: 0 auto;\n  margin-bottom: 5px;\n  text-align: center;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var AvailableDiscount = styled.p(_templateObject());
export var AvailableDiscountLink = styled.span(_templateObject2());
export var Wrapper = styled.div(_templateObject3());
export var Divider = styled.div(_templateObject4(), function (props) {
  return props.theme.colors.baseFontColorTransparent;
});
export var Title = styled.h3(_templateObject5(), function (props) {
  return props.theme.typography.boldFontWeight;
});
export var Image = styled.div(_templateObject6());
export var DiscountField = styled.div(_templateObject7(), media.smallScreen(_templateObject8()));
export var Tile = styled.label(_templateObject9(), function (props) {
  return props.theme.colors.light;
}, function (props) {
  return props.checked && "border: 2px solid #21125E;";
}, function (props) {
  return props.theme.typography.smallFontSize;
});
export var TotalPrice = styled.div(_templateObject10());
export var TotalPriceTitle = styled.p(_templateObject11());
export var TotalPriceData = styled.p(_templateObject12());