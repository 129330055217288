import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import * as React from "react";
import { FormattedMessage } from "react-intl";
import Media from "react-responsive";
import { useNavigate } from "react-router-dom";
import ReactSVG from "react-svg";
import { Money } from "@components/containers";
import { xMediumScreen } from "@styles/constants";
import { CartTable, NotFound } from "../../../components";
import goBackImg from "../../../images/arrowBack.svg";

var extractOrderLines = function extractOrderLines(lines) {
  return lines === null || lines === void 0 ? void 0 : lines.map(function (line) {
    return _objectSpread(_objectSpread({
      quantity: line.quantity,
      totalPrice: line.totalPrice,
      observation: line.observation
    }, line.variant), {}, {
      name: line.productName,
      unitPrice: line.unitPrice,
      discountAmount: +(line === null || line === void 0 ? void 0 : line.discountAmount),
      discountPercentage: line === null || line === void 0 ? void 0 : line.discountPercentage,
      attributes: line.attributes,
      id: line.id
    });
  }).sort(function (a, b) {
    return b.id.toLowerCase().localeCompare(a.id.toLowerCase());
  });
};

var Page = function Page(_ref) {
  var _order$total, _order$total2, _order$subtotal;

  var guest = _ref.guest,
      order = _ref.order;
  var orderNumber = order === null || order === void 0 ? void 0 : order.number;
  var navigate = useNavigate();
  return order ? React.createElement(React.Fragment, null, React.createElement("div", {
    className: "order-details__header"
  }, React.createElement("div", {
    className: "order-details__header--container"
  }, React.createElement("h3", null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement("div", {
    onClick: function onClick() {
      return navigate(-1);
    },
    className: "order-details__header--goBack"
  }, React.createElement(ReactSVG, {
    path: goBackImg
  }), React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "userAccount.views.OrderDetails.Page.3471378517",
    defaultMessage: "back"
  })))), React.createElement(FormattedMessage, {
    id: "userAccount.views.OrderDetails.Page.1798164394",
    defaultMessage: "Your order No: <span>{orderNumber}</span>",
    values: {
      orderNumber: orderNumber,
      span: function span() {
        for (var _len = arguments.length, chunks = new Array(_len), _key = 0; _key < _len; _key++) {
          chunks[_key] = arguments[_key];
        }

        return React.createElement("span", null, chunks);
      }
    }
  })))), React.createElement("div", {
    className: "order-details__header--total"
  }, React.createElement(FormattedMessage, {
    id: "userAccount.views.OrderDetails.Page.3737221427",
    defaultMessage: "Total value"
  }), ": ", React.createElement("span", null, React.createElement(Money, {
    money: order === null || order === void 0 ? void 0 : (_order$total = order.total) === null || _order$total === void 0 ? void 0 : _order$total.net
  }))), React.createElement(CartTable, {
    lines: extractOrderLines(order.lines),
    totalCost: React.createElement(Money, {
      money: order === null || order === void 0 ? void 0 : (_order$total2 = order.total) === null || _order$total2 === void 0 ? void 0 : _order$total2.net
    }),
    subtotal: React.createElement(Money, {
      money: order === null || order === void 0 ? void 0 : (_order$subtotal = order.subtotal) === null || _order$subtotal === void 0 ? void 0 : _order$subtotal.net
    }),
    trade: order === null || order === void 0 ? void 0 : order.trade
  })) : React.createElement(NotFound, null);
};

export default Page;