import { createSlice } from "@reduxjs/toolkit";
var initialState = {
  deliveryPrice: {
    amount: 0,
    currency: "COP"
  },
  deliveryNeighborhoodName: ""
};
export var deliveryCustomerSlice = createSlice({
  name: "deliveryCustomer",
  initialState: initialState,
  reducers: {
    updateDeliveryPrice: function updateDeliveryPrice(state, action) {
      state.deliveryPrice = action.payload.deliveryPrice;
    },
    updateDeliveryNeighborhoodName: function updateDeliveryNeighborhoodName(state, action) {
      state.deliveryNeighborhoodName = action.payload.deliveryNeighborhoodName;
    }
  }
});
var _deliveryCustomerSlic = deliveryCustomerSlice.actions,
    updateDeliveryPrice = _deliveryCustomerSlic.updateDeliveryPrice,
    updateDeliveryNeighborhoodName = _deliveryCustomerSlic.updateDeliveryNeighborhoodName;
export { updateDeliveryPrice, updateDeliveryNeighborhoodName };