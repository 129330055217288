import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 80px;\n  margin: 0 0 0 0;\n  position: fixed;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  z-index: 999;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  grid-area: button;\n  background-color: white;\n  button {\n    width: 280px;\n    margin: 0 auto;\n    display: block;\n    border-radius: 8px;\n  }\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  width: 280px;\n  height: 58px;\n  position: absolute;\n  top: calc(50% - 30px);\n  left: calc(50% - 140px);\n  z-index: -1;\n  border-radius: 8px;\n  animation: wavesCheckout 2s infinite;\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n    position: fixed;\n    bottom: 0;\n    z-index: 9999;\n  "]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  grid-area: cartSummary;\n\n  ", "\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  grid-area: paymentGateways;\n  margin-bottom: 80px;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  grid-area: checkout;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  grid-area: navigation;\n  height: 85px;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    grid-template-columns: 1fr;\n    grid-template-areas:\n      \"navigation\"\n      \"checkout\"\n      \"paymentGateways\"\n      \"button\";\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 115px;\n  margin-bottom: 30px;\n  display: grid;\n\n  grid-template-columns: 8fr 4fr;\n  grid-template-rows: 85px auto auto;\n  grid-column-gap: 30px;\n  grid-template-areas:\n    \"navigation cartSummary\"\n    \"checkout cartSummary\"\n    \"paymentGateways cartSummary\"\n    \"button .\";\n\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(255, 255, 255, 0.7);\n  z-index: 99999;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Loader = styled.div(_templateObject());
export var Wrapper = styled.div(_templateObject2(), media.xMediumScreen(_templateObject3()));
export var Navigation = styled.div(_templateObject4());
export var Checkout = styled.div(_templateObject5());
export var PaymentGateways = styled.div(_templateObject6(), function (props) {
  return props.hide && "display: none;";
});
export var CartSummary = styled.div(_templateObject7(), media.xMediumScreen(_templateObject8()));
export var Waves = styled.div(_templateObject9());
export var Button = styled.div(_templateObject10());