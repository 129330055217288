import "./scss/index.scss";
import React from "react";
import ReactSVG from "react-svg";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Loader } from "@components/atoms";
import { getErrorMessage } from "@temp/intl";

var closeWhite = require("../../images/closewhite.svg"); // const greaterThanEqual = require("../../images/greaterThanEqual.svg");


export var AlertSendUbication = function AlertSendUbication(_ref) {
  var errors = _ref.errors,
      loading = _ref.loading,
      hideModal = _ref.hideModal,
      submit = _ref.submit;
  var intl = useIntl();
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "alertSendUbication",
    onClick: hideModal
  }), React.createElement("div", {
    className: "alertSendUbication__data"
  }, React.createElement("div", {
    className: "alertSendUbication__data__close"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.AlertSendUbication.index.2807408585",
    defaultMessage: "Send ubication"
  })), React.createElement(ReactSVG, {
    path: closeWhite,
    onClick: hideModal
  })), React.createElement("div", {
    className: "alertSendUbication__data__content alertSendUbication__data__content--first"
  }), React.createElement("div", {
    className: "alertSendUbication__data__content  alertSendUbication__data__content--amount alertSendUbication__data__content--first alertSendUbication__data__content--last"
  }, React.createElement("p", null, "Toca el bot\xF3n ", React.createElement("span", null, "\"Enviar Ubicaci\xF3n\""), " para localizar el barrio en el que te encuentras"), errors.length > 0 && React.createElement("div", {
    className: "validateCode__error"
  }, errors.map(function (error, index) {
    return React.createElement("p", {
      key: "".concat(index, "-errorAlertSendUbication")
    }, getErrorMessage(error.code.toString(), intl));
  }))), React.createElement("div", {
    className: "alertSendUbication__data__footer"
  }, React.createElement(Button, {
    type: "button",
    testingContext: "",
    onClick: hideModal
  }, React.createElement(FormattedMessage, {
    id: "components.AlertSendUbication.index.1928937976",
    defaultMessage: "Choose neighborhood"
  })), React.createElement(Button, {
    testingContext: "",
    className: "alertSendUbication__data__footer__p",
    onClick: submit
  }, React.createElement(FormattedMessage, {
    id: "components.AlertSendUbication.index.2807408585",
    defaultMessage: "Send ubication"
  })))), loading && React.createElement("div", {
    className: "alertSendUbication__loading"
  }, React.createElement(Loader, null)));
};
export default AlertSendUbication;