import "./scss/index.scss";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { ProductList } from "../../@next/components/organisms";

var Page = function Page(_ref) {
  var maxProductsPerCategory = _ref.maxProductsPerCategory,
      saleProducts = _ref.saleProducts;
  return React.createElement(React.Fragment, null, saleProducts.length > 0 && React.createElement(React.Fragment, null, React.createElement("div", {
    className: "container-categoryHome__sign"
  }, React.createElement("h2", null, React.createElement(FormattedMessage, {
    id: "views.Homee.PageSaleProducts.1512152579",
    defaultMessage: "Super discounts"
  }), "\xA0\xA0\xA0\xA0"), React.createElement("div", null, " ")), saleProducts.length > maxProductsPerCategory ? React.createElement(React.Fragment, null, React.createElement("div", {
    className: "container-categoryHome__see-more"
  }, React.createElement(Link, {
    to: "/sale/",
    onClick: function onClick() {
      localStorage.setItem("scroll-home", window.scrollY.toString());
    }
  }, React.createElement(FormattedMessage, {
    id: "views.Homee.PageSaleProducts.207418477",
    defaultMessage: "See all"
  }))), React.createElement(ProductList, {
    handleClickTo: function handleClickTo() {
      localStorage.setItem("scroll-home", window.scrollY.toString());
    },
    seeMore: true,
    products: saleProducts.slice(0, maxProductsPerCategory),
    to: "/sale/"
  })) : React.createElement(ProductList, {
    products: saleProducts.slice(0, maxProductsPerCategory)
  })));
};

export default Page;