import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { commonMessages } from "@temp/intl";
import { useAccountUpdate, useAuth } from "@saleor/sdk";
import { Attribute, IconButton, Loader, Tile } from "@components/atoms";
import { AlertErrors } from "@temp/components";
import { AccountUpdateForm } from "./AccountUpdateForm";
import * as S from "./styles";
export var AccountTile = function AccountTile() {
  var _user$photo, _error$extraInfo;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      isEditing = _React$useState2[0],
      setIsEditing = _React$useState2[1];

  var _React$useState3 = React.useState(false),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      showErrors = _React$useState4[0],
      setShowErrors = _React$useState4[1];

  var _useAccountUpdate = useAccountUpdate(),
      _useAccountUpdate2 = _slicedToArray(_useAccountUpdate, 2),
      setAccountUpdate = _useAccountUpdate2[0],
      _useAccountUpdate2$ = _useAccountUpdate2[1],
      data = _useAccountUpdate2$.data,
      error = _useAccountUpdate2$.error,
      loading = _useAccountUpdate2$.loading;

  var intl = useIntl();

  var _useAuth = useAuth(),
      user = _useAuth.user;

  React.useEffect(function () {
    if (data && !error) {
      setIsEditing(false);
    }

    if (error) {
      setShowErrors(true);
    }

    return function () {
      setIsEditing(false);
      setShowErrors(false);
    };
  }, [data, error]);
  return React.createElement(React.Fragment, null, React.createElement(S.TileWrapper, null, React.createElement(Tile, null, React.createElement(S.Wrapper, null, React.createElement(S.Header, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.AccountTabTiles.AccountTile.3843483622",
    defaultMessage: "MY DATA"
  })), React.createElement(S.Content, null, React.createElement(S.HeaderSmall, null, !isEditing ? React.createElement(React.Fragment, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.AccountTabTiles.AccountTile.3253048083",
    defaultMessage: "Personal details"
  }), React.createElement(IconButton, {
    testingContext: "editDetailsButton",
    name: "edit",
    size: 22,
    onClick: function onClick() {
      return setIsEditing(function (isEditing) {
        return !isEditing;
      });
    }
  })) : React.createElement(S.HeaderSmallPersonal, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.AccountTabTiles.AccountTile.3253048083",
    defaultMessage: "Personal details"
  }))), isEditing ? React.createElement(AccountUpdateForm, {
    initialValues: {
      firstName: user && user.firstName || "",
      identityCardType: user && user.identityCardType !== "null" && user.identityCardType || "",
      identityCard: user && user.identityCard !== "null" && user.identityCard || "",
      photo: (user === null || user === void 0 ? void 0 : (_user$photo = user.photo) === null || _user$photo === void 0 ? void 0 : _user$photo.url) || "",
      userEmail: (user === null || user === void 0 ? void 0 : user.userEmail) || ""
    },
    handleSubmit: function handleSubmit(data) {
      setAccountUpdate({
        input: data
      });
    },
    hide: function hide() {
      setIsEditing(false);
    }
  }) : React.createElement(S.ContentOneLine, {
    "data-test": "personalDetailsSection"
  }, React.createElement(Attribute, {
    description: intl.formatMessage(commonMessages.firstName),
    attributeValue: user && user.firstName || "-",
    testingContext: "firstNameText"
  }), React.createElement(Attribute, {
    description: intl.formatMessage(commonMessages.IDType),
    attributeValue: user && user.identityCardType !== "null" && user.identityCardType || intl.formatMessage(commonMessages.notDefined),
    testingContext: "identityCardType"
  }), React.createElement(Attribute, {
    description: intl.formatMessage(commonMessages.ID),
    attributeValue: user && user.identityCard !== "null" && user.identityCard || intl.formatMessage(commonMessages.notDefined),
    testingContext: "lastNameText"
  }), React.createElement(Attribute, {
    description: intl.formatMessage(commonMessages.eMail),
    attributeValue: user && user.userEmail !== "null" && user.userEmail || intl.formatMessage(commonMessages.notDefined),
    testingContext: "emailText"
  })))))), showErrors && React.createElement(AlertErrors, {
    errors: error === null || error === void 0 ? void 0 : (_error$extraInfo = error.extraInfo) === null || _error$extraInfo === void 0 ? void 0 : _error$extraInfo.userInputErrors,
    hideModal: function hideModal() {
      setShowErrors(false);
    }
  }), loading && React.createElement(S.Loader, null, React.createElement(Loader, null)));
};