import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
// This component relies on parts of the application that are
// are not yet implemented (ie wishlist)
// Replace it with MainMenu component once all the blocks are finished
import React from "react";
import Media from "react-responsive";
import { Icon, NavLink } from "@components/atoms";
import { largeScreen, smallScreen } from "@styles/constants";
import { maybe } from "@utils/misc"; // import LogoSmall from "images/logo-small.svg";
// import Logo from "images/logo.svg";

import { Dropdown } from "./Dropdown";
import * as S from "./styles";
var menuVisibleRatio = 0.8;

var getElementWidth = function getElementWidth(node) {
  return node.scrollWidth;
};

var isMenuVisible = function isMenuVisible(node) {
  var wrapperWidth = node.scrollWidth;
  var child = node.lastElementChild;

  if (wrapperWidth > 0) {
    var childWidth = maybe(function () {
      return getElementWidth(child);
    }, 0);

    if (childWidth / wrapperWidth < menuVisibleRatio) {
      return true;
    }
  }

  return false;
};

var useElementWidthChanged = function useElementWidthChanged(callback) {
  var _React$useState = React.useState(0),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      width = _React$useState2[0],
      setWidth = _React$useState2[1];

  var nodeRef = React.useRef(null);
  var resizeTimer;

  var eventHandler = function eventHandler(_e) {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      var newWidth = getElementWidth(nodeRef.current);

      if (width !== newWidth) {
        setWidth(getElementWidth(nodeRef.current));

        if (callback) {
          callback();
        }
      }
    }, 250);
  };

  var setRef = React.useCallback(function (node) {
    if (node !== null) {
      nodeRef.current = node;
      setWidth(getElementWidth(node));
      window.addEventListener("resize", eventHandler);
    }
  }, []);
  React.useEffect(function () {
    return function () {
      window.removeEventListener("resize", eventHandler);
    };
  }, []);
  return [setRef, {
    width: width,
    node: nodeRef.current
  }];
};

export var TopNavbar = function TopNavbar(_ref) {
  var items = _ref.items;

  var _React$useState3 = React.useState(false),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      navVisible = _React$useState4[0],
      setNavVisible = _React$useState4[1];

  var _useElementWidthChang = useElementWidthChanged(),
      _useElementWidthChang2 = _slicedToArray(_useElementWidthChang, 2),
      setRef = _useElementWidthChang2[0],
      _useElementWidthChang3 = _useElementWidthChang2[1],
      width = _useElementWidthChang3.width,
      node = _useElementWidthChang3.node;

  var _React$useState5 = React.useState(null),
      _React$useState6 = _slicedToArray(_React$useState5, 2),
      currentElement = _React$useState6[0],
      setCurrentElement = _React$useState6[1];

  React.useEffect(function () {
    if (node) {
      setNavVisible(isMenuVisible(node));
    }

    return function () {};
  }, [width]);
  return React.createElement(React.Fragment, null, React.createElement(S.Wrapper, {
    "data-test": "topNavigation"
  }, React.createElement(S.Navigation, {
    ref: setRef
  }, !navVisible && React.createElement(S.Mobile, null, React.createElement("li", null, React.createElement(Icon, {
    name: "hamburger"
  }))), React.createElement(S.Desktop, {
    style: {
      visibility: navVisible ? "visible" : "hidden"
    }
  }, items.map(function (item, index) {
    return React.createElement("li", {
      key: item.id
    }, item.children.length > 0 ? React.createElement(S.Button, {
      onClick: function onClick() {
        return setCurrentElement(index);
      }
    }, item.name) : React.createElement(NavLink, {
      item: item
    }));
  }))), React.createElement(S.Center, null, React.createElement(Media, {
    maxWidth: smallScreen
  }), React.createElement(Media, {
    minWidth: smallScreen
  })), React.createElement(S.Actions, null, React.createElement(Media, {
    minWidth: largeScreen
  }, React.createElement(S.IconWrapper, null, React.createElement(Icon, {
    name: "profile",
    size: 24
  })), React.createElement(S.IconWrapper, null, React.createElement(Icon, {
    name: "heart",
    size: 24
  }))), React.createElement(S.IconWrapper, null, React.createElement(Icon, {
    name: "cart",
    size: 24
  })), React.createElement(S.SearchButton, null, React.createElement(Media, {
    minWidth: smallScreen
  }, React.createElement(S.Text, null, "SEARCH")), React.createElement(Icon, {
    name: "search",
    size: 24
  })))), currentElement !== null && React.createElement(Dropdown, {
    item: items[currentElement],
    onHide: function onHide() {
      return setCurrentElement(null);
    }
  }));
};