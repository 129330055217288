import "./scss/index.scss";
import React from "react";
import ReactSVG from "react-svg";
import { FormattedMessage } from "react-intl";
import { Button } from "@components/atoms";

var closeWhite = require("../../images/closewhite.svg"); // const greaterThanEqual = require("../../images/greaterThanEqual.svg");


export var AlertShowInformation = function AlertShowInformation(_ref) {
  var message = _ref.message,
      hideModal = _ref.hideModal,
      errors = _ref.errors;
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "showInformation",
    onClick: hideModal
  }), React.createElement("div", {
    className: "showInformation__data"
  }, React.createElement("div", {
    className: "showInformation__data__close"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.AlertShowInformation.index.709932324",
    defaultMessage: "Alert"
  })), React.createElement(ReactSVG, {
    path: closeWhite,
    onClick: hideModal
  })), React.createElement("div", {
    className: "showInformation__data__content showInformation__data__content--first"
  }), React.createElement("div", {
    className: "showInformation__data__content showInformation__data__content--value showInformation__data__content--first showInformation__data__content--last"
  }, message), React.createElement("div", {
    className: "showInformation__data__footer"
  }, React.createElement(Button, {
    type: "button",
    testingContext: "",
    onClick: hideModal
  }, React.createElement(FormattedMessage, {
    id: "components.AlertShowInformation.index.3577703060",
    defaultMessage: "Ok"
  })))));
};
export default AlertShowInformation;