import * as React from "react";
import { Link } from "react-router-dom";
import { Thumbnail } from "@components/molecules";
import { generateProductVariantUrl } from "../../../core/utils";

var ProductItem = function ProductItem(_ref) {
  var _product$subCategory, _product$category;

  var product = _ref.product;
  return React.createElement("li", {
    className: "search__products__item"
  }, React.createElement(Link, {
    to: generateProductVariantUrl(product.id, product.name, product === null || product === void 0 ? void 0 : (_product$subCategory = product.subCategory) === null || _product$subCategory === void 0 ? void 0 : _product$subCategory.id)
  }, React.createElement(Thumbnail, {
    source: product
  }), React.createElement("span", null, React.createElement("h4", null, product.name), React.createElement("p", null, (product === null || product === void 0 ? void 0 : (_product$category = product.category) === null || _product$category === void 0 ? void 0 : _product$category.name) || "-"))));
};

export default ProductItem;