import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { STATUS_PROBLEM_STEPS } from "@temp/core/config";

var StatusProblemRouter = function StatusProblemRouter(_ref) {
  var RenderTimeOut = _ref.RenderTimeOut,
      RenderShipping = _ref.RenderShipping,
      RenderDelivered = _ref.RenderDelivered,
      RenderUnDelivered = _ref.RenderUnDelivered;
  return React.createElement(Routes, null, React.createElement(Route, {
    path: STATUS_PROBLEM_STEPS[0].link,
    element: React.createElement(RenderTimeOut, null)
  }), React.createElement(Route, {
    path: STATUS_PROBLEM_STEPS[1].link,
    element: React.createElement(RenderShipping, null)
  }), React.createElement(Route, {
    path: STATUS_PROBLEM_STEPS[2].link,
    element: React.createElement(RenderDelivered, null)
  }), React.createElement(Route, {
    path: STATUS_PROBLEM_STEPS[3].link,
    element: React.createElement(RenderUnDelivered, null)
  }), React.createElement(Route, {
    path: STATUS_PROBLEM_STEPS[4].link,
    element: React.createElement(RenderUnDelivered, null)
  }), React.createElement(Route, {
    path: "*",
    element: React.createElement(Navigate, {
      to: "/"
    })
  }));
};

export { StatusProblemRouter };