import React from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import "./scss/index.scss";

var PinchZoomImage = function PinchZoomImage(_ref) {
  var src = _ref.src,
      alt = _ref.alt;
  return React.createElement("div", {
    className: "containerPinchZoomImage"
  }, React.createElement(TransformWrapper, {
    initialScale: 1
  }, React.createElement(TransformComponent, null, React.createElement("img", {
    src: src,
    alt: alt,
    className: "zoomable-image"
  }))));
};

export default PinchZoomImage;