import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { memo } from "react";
import { FormattedMessage } from "react-intl"; // import { Checkbox, ErrorMessage } from "@components/atoms";

import { checkoutMessages } from "@temp/intl";
import { filterNotEmptyArrayItems } from "@utils/misc";
import { AddressForm } from "../AddressForm";
import { AddressGridSelector } from "../AddressGridSelector"; // import { LuzattAcceptanceField } from "../../../../components";

import * as S from "./styles";

/**
 * Address form used in checkout.
 */
var CheckoutAddress = memo(function (_ref) {
  var checkoutShippingAddress = _ref.checkoutShippingAddress,
      email = _ref.email,
      selectedUserShippingAddressId = _ref.selectedUserShippingAddressId,
      userAddresses = _ref.userAddresses,
      countries = _ref.countries,
      userId = _ref.userId,
      shippingFormId = _ref.shippingFormId,
      shippingFormRef = _ref.shippingFormRef,
      shippingAddressRequired = _ref.shippingAddressRequired,
      setShippingAddress = _ref.setShippingAddress,
      shippingErrors = _ref.shippingErrors,
      nameOptions = _ref.nameOptions,
      newAddressFormId = _ref.newAddressFormId,
      neighborhoodsAttention = _ref.neighborhoodsAttention;
  return React.createElement(S.Wrapper, null, shippingAddressRequired && React.createElement(React.Fragment, null, React.createElement("section", null, React.createElement(S.Title, {
    "data-test": "checkoutPageSubtitle"
  }, React.createElement(FormattedMessage, checkoutMessages.shippingAddress)), userAddresses ? React.createElement(AddressGridSelector, {
    testingContext: "shipping",
    formId: shippingFormId,
    formRef: shippingFormRef,
    addresses: userAddresses,
    selectedAddressId: selectedUserShippingAddressId,
    countriesOptions: countries === null || countries === void 0 ? void 0 : countries.filter(filterNotEmptyArrayItems),
    userId: userId,
    errors: shippingErrors,
    onSelect: function onSelect(address, id) {
      setShippingAddress(address, undefined, id);
    },
    newAddressFormId: newAddressFormId,
    neighborhoodsAttention: neighborhoodsAttention,
    nameOptions: nameOptions
  }) : React.createElement(AddressForm, {
    testingContext: "shippingAddressForm",
    formId: shippingFormId,
    formRef: shippingFormRef,
    countriesOptions: countries === null || countries === void 0 ? void 0 : countries.filter(filterNotEmptyArrayItems),
    address: _objectSpread(_objectSpread({}, checkoutShippingAddress), {}, {
      email: email
    }),
    handleSubmit: function handleSubmit(address) {
      setShippingAddress(address, address === null || address === void 0 ? void 0 : address.email);
    },
    includeEmail: true,
    errors: shippingErrors
  })), React.createElement(S.Divider, null)));
});
export { CheckoutAddress };