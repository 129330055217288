import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  width: 80%;\n  height: 50px;\n  margin: 0 auto;\n  margin-top: 1rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 10px;\n  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  margin-top: 1rem;\n  button {\n    margin: 0 auto;\n    border-radius: 10px;\n  }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  text-transform: uppercase;\n  text-align: center;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  text-transform: uppercase;\n  text-align: center;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  text-transform: uppercase;\n  text-align: center;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  text-transform: uppercase;\n  text-align: center;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  text-transform: uppercase;\n  text-align: center;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  text-transform: uppercase;\n  text-align: center;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  padding: 15px 10px;\n  margin: 0 auto;\n  margin-top: 20px;\n  display: grid;\n  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;\n  border-radius: 10px;\n  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var TableHead = styled.div(_templateObject());
export var HeadShop = styled.p(_templateObject2());
export var HeadNumberOrder = styled.p(_templateObject3());
export var HeadOrder = styled.p(_templateObject4());
export var HeadStatus = styled.p(_templateObject5());
export var HeadDate = styled.p(_templateObject6());
export var HeadDetail = styled.p(_templateObject7());
export var Wrapper = styled.div(_templateObject8());
export var Loader = styled.div(_templateObject9());