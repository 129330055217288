import { createSlice } from "@reduxjs/toolkit";
export var initialStateCategory = {
  name: "",
  value: "",
  subcategories: []
};
export var initialStateSubcategory = {
  name: "",
  value: ""
};
export var initialInputValueAndQueryValue = "";
var initialState = {
  openModal: false,
  searchCategory: initialStateCategory,
  searchSubcategory: initialStateSubcategory,
  searchInputValue: initialInputValueAndQueryValue,
  searchQueryValue: initialInputValueAndQueryValue,
  searchTitleHeight: 0,
  subcategoriesCategoriesAllLoaded: false,
  subcategoriesCategoriesSaleLoaded: false
};
export var searchSlice = createSlice({
  name: "search",
  initialState: initialState,
  reducers: {
    handleModal: function handleModal(state, action) {
      state.openModal = action.payload.openModal;
    },
    restoreSearchData: function restoreSearchData(state) {
      state.searchCategory = initialState.searchCategory;
      state.searchSubcategory = initialState.searchSubcategory;
      state.searchInputValue = initialState.searchInputValue;
      state.searchQueryValue = initialState.searchQueryValue;
    },
    restoreCategory: function restoreCategory(state) {
      state.searchCategory = initialState.searchCategory;
    },
    restoreSubCategory: function restoreSubCategory(state) {
      state.searchSubcategory = initialState.searchSubcategory;
    },
    updateCategoryInititalValue: function updateCategoryInititalValue(state) {
      state.searchCategory = initialStateCategory;
    },
    updateCategorySearchValue: function updateCategorySearchValue(state, action) {
      state.searchCategory = action.payload.searchCategory;
    },
    updateSearchTitleHeight: function updateSearchTitleHeight(state, action) {
      state.searchTitleHeight = action.payload.searchTitleHeight;
    },
    updateSugcategorySearchValue: function updateSugcategorySearchValue(state, action) {
      state.searchSubcategory = action.payload.searchSubcategory;
    },
    updateInputValue: function updateInputValue(state, action) {
      state.searchInputValue = action.payload.searchInputValue;
    },
    updateQueryValue: function updateQueryValue(state) {
      var _state$searchInputVal;

      state.searchQueryValue = (_state$searchInputVal = state.searchInputValue) === null || _state$searchInputVal === void 0 ? void 0 : _state$searchInputVal.trim();
    },
    updateSubcategoriesCategoriesAllLoaded: function updateSubcategoriesCategoriesAllLoaded(state, action) {
      state.subcategoriesCategoriesAllLoaded = action.payload;
    },
    updateSubcategoriesCategoriesSaleLoaded: function updateSubcategoriesCategoriesSaleLoaded(state, action) {
      state.subcategoriesCategoriesSaleLoaded = action.payload;
    }
  }
});
var _searchSlice$actions = searchSlice.actions,
    handleModal = _searchSlice$actions.handleModal,
    restoreSearchData = _searchSlice$actions.restoreSearchData,
    restoreCategory = _searchSlice$actions.restoreCategory,
    restoreSubCategory = _searchSlice$actions.restoreSubCategory,
    updateCategoryInititalValue = _searchSlice$actions.updateCategoryInititalValue,
    updateCategorySearchValue = _searchSlice$actions.updateCategorySearchValue,
    updateSugcategorySearchValue = _searchSlice$actions.updateSugcategorySearchValue,
    updateInputValue = _searchSlice$actions.updateInputValue,
    updateQueryValue = _searchSlice$actions.updateQueryValue,
    updateSearchTitleHeight = _searchSlice$actions.updateSearchTitleHeight,
    updateSubcategoriesCategoriesAllLoaded = _searchSlice$actions.updateSubcategoriesCategoriesAllLoaded,
    updateSubcategoriesCategoriesSaleLoaded = _searchSlice$actions.updateSubcategoriesCategoriesSaleLoaded;
export { handleModal, restoreSearchData, restoreCategory, restoreSubCategory, updateCategoryInititalValue, updateCategorySearchValue, updateSugcategorySearchValue, updateInputValue, updateQueryValue, updateSearchTitleHeight, updateSubcategoriesCategoriesAllLoaded, updateSubcategoriesCategoriesSaleLoaded };