import "./scss/styles.scss";
import backgroundMobile from "@temp/images/ecommerce/login/backgroundMobileWithOutMoped.svg";
import backgroundWhite from "@temp/images/ecommerce/login/backgroundWhite.svg";
import backgroundWhiteWrongTrade from "@temp/images/ecommerce/login/backgroundWhiteWrongTrade.jpg";
import redMoped from "@temp/images/ecommerce/login/moped.svg";
import wrongTrade from "@temp/images/ecommerce/login/wrongTrade.svg";
import principleLogo from "@temp/images/ecommerce/login/logo-EasyPedidoCompleteLight.svg";
import React from "react";
import Media from "react-responsive";
import { xMediumScreen } from "@styles/constants";
import { Loader } from "@temp/components";
import { useIntl } from "react-intl";
import { commonMessages, logInMessages } from "@temp/intl";
import { animated, useSpring } from "react-spring";
import ReactSVG from "react-svg";
import { LoginInput, LoginValidateCode } from "../Components";
export var EcommerceLoginView = function EcommerceLoginView(_ref) {
  var accessCodeSeconds = _ref.accessCodeSeconds,
      showValidateCode = _ref.showValidateCode,
      defaultCountryCode = _ref.defaultCountryCode,
      userWaNumber = _ref.userWaNumber,
      setUserWaNumber = _ref.setUserWaNumber,
      errorsVerifyCustomerAccount = _ref.errorsVerifyCustomerAccount,
      seeCode = _ref.seeCode,
      errorsValidateTradeCode = _ref.errorsValidateTradeCode,
      code = _ref.code,
      resendCode = _ref.resendCode,
      siteSettings = _ref.siteSettings,
      showWrongTrade = _ref.showWrongTrade,
      validateTradeCodeDataLoading = _ref.validateTradeCodeDataLoading,
      verifyTradeStaffAccountDataLoading = _ref.verifyTradeStaffAccountDataLoading,
      tradeName = _ref.tradeName,
      handleChange = _ref.handleChange,
      handleCodeInput = _ref.handleCodeInput,
      handleSeeCode = _ref.handleSeeCode,
      handleValidateCode = _ref.handleValidateCode,
      handleReSendCode = _ref.handleReSendCode,
      handleLogIn = _ref.handleLogIn,
      userHasAcceptedTyc = _ref.userHasAcceptedTyc,
      loading = _ref.loading,
      tradeLogo = _ref.tradeLogo;
  var intl = useIntl();
  var scaleButton = useSpring({
    config: {
      friction: 40,
      mass: 5,
      tension: 350
    },
    from: {
      scale: 1.1
    },
    loop: true,
    to: {
      scale: 1
    }
  });
  return React.createElement("main", {
    className: "loginEcommerce"
  }, React.createElement(Media, {
    maxWidth: xMediumScreen
  }, function (matches) {
    return matches ? React.createElement("img", {
      className: "loginEcommerce__background",
      alt: "",
      src: backgroundMobile
    }) : React.createElement("img", {
      className: "loginEcommerce__background",
      alt: "",
      src: showWrongTrade ? backgroundWhiteWrongTrade : backgroundWhite
    });
  }), React.createElement("section", {
    className: "loginEcommerce__left"
  }, showWrongTrade ? React.createElement("div", {
    className: "loginEcommerce__left__error"
  }, React.createElement("p", null, intl.formatMessage(logInMessages.tradeNotFound))) : React.createElement("div", {
    className: "loginEcommerce__left__content"
  }, React.createElement("div", {
    className: "loginEcommerce__left__content__logo"
  }, React.createElement("img", {
    alt: (tradeLogo === null || tradeLogo === void 0 ? void 0 : tradeLogo.alt) || "Easy Pedido",
    src: (tradeLogo === null || tradeLogo === void 0 ? void 0 : tradeLogo.url) || principleLogo
  })), React.createElement("p", {
    className: "loginEcommerce__left__content__name"
  }, tradeName), React.createElement("form", {
    className: "loginEcommerce__left__content__form"
  }, React.createElement(Media, {
    maxWidth: xMediumScreen
  }, function (matches) {
    return matches && React.createElement(ReactSVG, {
      path: redMoped
    });
  }), React.createElement(LoginInput, {
    showValidateCode: showValidateCode,
    defaultCountryCode: defaultCountryCode,
    userWaNumber: userWaNumber,
    setUserWaNumber: setUserWaNumber,
    errorsVerifyCustomerAccount: errorsVerifyCustomerAccount
  }), showValidateCode && React.createElement(LoginValidateCode, {
    accessCodeSeconds: accessCodeSeconds,
    seeCode: seeCode,
    errorsValidateTradeCode: errorsValidateTradeCode,
    code: code,
    resendCode: resendCode,
    siteSettings: siteSettings,
    userHasAcceptedTyc: userHasAcceptedTyc,
    userWaNumber: userWaNumber,
    handleChange: handleChange,
    handleCodeInput: handleCodeInput,
    handleSeeCode: handleSeeCode,
    handleValidateCode: handleValidateCode,
    handleReSendCode: handleReSendCode
  }), !showValidateCode && React.createElement(React.Fragment, null, React.createElement(animated.input, {
    style: scaleButton,
    type: "submit",
    className: "loginEcommerce__left__content__logInButton",
    onClick: function onClick(e) {
      return handleLogIn(e, userWaNumber);
    },
    value: intl.formatMessage(commonMessages.toAccess)
  }))))), React.createElement(Media, {
    minWidth: xMediumScreen
  }, showWrongTrade && React.createElement("section", {
    className: "loginEcommerce__right"
  }, React.createElement("img", {
    alt: "",
    className: "loginEcommerce__right__background",
    src: wrongTrade
  }))), (verifyTradeStaffAccountDataLoading || validateTradeCodeDataLoading || loading) && React.createElement("div", {
    className: "loginEcommerce__loading"
  }, React.createElement(Loader, null)));
};
EcommerceLoginView.displayName = "EcommerceLoginView";