import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-between;\n  padding: 0;\n  margin: 0 15px 0 0;\n  width: 50px;\n\n  > div {\n    display: flex;\n  }\n\n  svg {\n    cursor: pointer;\n    justify-self: center;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var QuantityButtons = styled.div(_templateObject());