import React from "react";
import { FormattedMessage } from "react-intl"; // import { commonMessages } from "@temp/intl";

import * as S from "./styles";

/**
 * Address summary
 */
var AddressSummary = function AddressSummary(_ref) {
  var address = _ref.address,
      email = _ref.email;

  if (address) {
    var _address$neighborhood, _address$neighborhood2;

    return React.createElement(S.Wrapper, {
      "data-test": "addressTile"
    }, address.firstName && React.createElement(S.Label, null, React.createElement("span", null, address.firstName), " ", React.createElement("br", null)), address.streetAddress1 && React.createElement(S.Label, null, React.createElement("span", null, React.createElement(FormattedMessage, {
      id: "@next.components.molecules.AddressSummary.AddressSummary.359810770",
      defaultMessage: "Address"
    }), ":", " "), " ", React.createElement("span", null, address.streetAddress1), " ", address.streetAddress2 && React.createElement(React.Fragment, null, ", ", " ", React.createElement("span", null, address.streetAddress2)), React.createElement("br", null)), ((_address$neighborhood = address.neighborhood) === null || _address$neighborhood === void 0 ? void 0 : _address$neighborhood.name) && React.createElement(S.Label, null, React.createElement("span", null, React.createElement(FormattedMessage, {
      id: "@next.components.molecules.AddressSummary.AddressSummary.1024405385",
      defaultMessage: "Neighborhood"
    }), ":", " "), React.createElement("span", null, (_address$neighborhood2 = address.neighborhood) === null || _address$neighborhood2 === void 0 ? void 0 : _address$neighborhood2.name), " ", React.createElement("br", null)));
  }

  if (email) {
    return React.createElement(S.Wrapper, {
      "data-test": "emailTile"
    }, email);
  }

  return React.createElement(React.Fragment, null);
};

export { AddressSummary };