import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import * as React from "react";
import { META_DEFAULTS } from "../../core/config";
import MetaConsumer from "./consumer";
import { Provider as MetaProvider } from "./context";

var removeEmpty = function removeEmpty(obj) {
  var newObj = {};
  Object.keys(obj).forEach(function (prop) {
    if (obj[prop] && obj[prop] !== "") {
      newObj[prop] = obj[prop];
    }
  });
  return newObj;
};

var MetaWrapper = function MetaWrapper(_ref) {
  var children = _ref.children,
      meta = _ref.meta;
  return React.createElement(MetaProvider, {
    value: _objectSpread(_objectSpread({}, META_DEFAULTS), removeEmpty(meta))
  }, React.createElement(MetaConsumer, null, children));
};

export default MetaWrapper;