import _extends from "@babel/runtime/helpers/extends";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { ToppingMulti } from "@components/organisms";
import React, { useEffect, useReducer } from "react";
import { useIntl } from "react-intl";
import { commonMessages } from "@temp/intl";
import { initialState, toppingsReducer } from "./ToppingsReducer";
import { toppingsPositionReducer, initialStateToppingsPosition } from "./ToppingsPositionReducer";
import * as S from "./styles";
export var Toppings = React.memo(function (_ref) {
  var handleAllowAddProduct = _ref.handleAllowAddProduct,
      handleUpdateToppingsToAddAndProductPrice = _ref.handleUpdateToppingsToAddAndProductPrice,
      toppingsProduct = _ref.toppingsProduct,
      line = _ref.line,
      obligatoryButton = _ref.obligatoryButton;
  var intl = useIntl();

  var _useReducer = useReducer(toppingsReducer, initialState),
      _useReducer2 = _slicedToArray(_useReducer, 2),
      toppings = _useReducer2[0],
      dispatch = _useReducer2[1];

  var _useReducer3 = useReducer(toppingsPositionReducer, initialStateToppingsPosition),
      _useReducer4 = _slicedToArray(_useReducer3, 2),
      toppingsPosition = _useReducer4[0],
      dispatchToppingsPosition = _useReducer4[1];

  var handleObligatoryButton = React.useCallback(function (toppingsPosition) {
    var position = 0;
    var flagObligatory = false;
    toppingsPosition.forEach(function (topping) {
      var buttonWithLunch = document.getElementById("toppingPosition-".concat(topping.index, "-withLunch"));
      var buttonWithOuthLunch = document.getElementById("toppingPosition-".concat(topping.index, "-withOutLunch"));

      if (buttonWithLunch) {
        buttonWithLunch.style.display = "none";
      }

      if (buttonWithOuthLunch) {
        buttonWithOuthLunch.style.display = "block";
      }

      if (topping.obligatory && !flagObligatory) {
        if (buttonWithLunch) {
          buttonWithLunch.style.display = "block";
        }

        if (buttonWithOuthLunch) {
          buttonWithOuthLunch.style.display = "none";
        }

        flagObligatory = true;
        position = topping.position;
      }
    });
    return position;
  }, []);
  useEffect(function () {
    var position = handleObligatoryButton(toppingsPosition);
    window.scrollTo(0, position - 120);
  }, [obligatoryButton]);
  useEffect(function () {
    handleObligatoryButton(toppingsPosition);
  }, [toppingsPosition]);
  useEffect(function () {
    var toppingDontCheckMinTotalAmount = toppings.filter(function (topping) {
      return (topping === null || topping === void 0 ? void 0 : topping.checkMinTotalAmount) === false;
    });

    if (toppingDontCheckMinTotalAmount.length > 0) {
      handleAllowAddProduct(false);
    } else {
      handleAllowAddProduct(true);
    }

    handleUpdateToppingsToAddAndProductPrice(toppings);
  }, [toppings]);
  useEffect(function () {
    dispatch({
      type: "reset"
    });
  }, [line]);
  useEffect(function () {
    var _toppingsPositionAndA;

    var toppingsByProduct = [];
    var toppingsPositionAndAttributesWithDependencies = [];
    toppingsProduct === null || toppingsProduct === void 0 ? void 0 : toppingsProduct.forEach(function (topping) {
      var _findTopping$dataValu2, _findTopping$dataValu3;

      var totalAmount = 0;
      var checkMinTotalAmount = false;
      var attributesWithDependenciesToAdd = [];
      var findTopping = line.length > 0 && line.find(function (lineTopping) {
        var _lineTopping$idToppin, _topping$attributeSet, _topping$attributeSet2;

        return (lineTopping === null || lineTopping === void 0 ? void 0 : (_lineTopping$idToppin = lineTopping.idTopping) === null || _lineTopping$idToppin === void 0 ? void 0 : _lineTopping$idToppin.toString()) === (topping === null || topping === void 0 ? void 0 : (_topping$attributeSet = topping.attributeSettings) === null || _topping$attributeSet === void 0 ? void 0 : (_topping$attributeSet2 = _topping$attributeSet.id) === null || _topping$attributeSet2 === void 0 ? void 0 : _topping$attributeSet2.toString());
      });
      var dataValues = topping.values.map(function (value) {
        return {
          assignedAttributeVariantDependencies: (value === null || value === void 0 ? void 0 : value.assignedAttributeVariantDependencies) || [],
          descriptionValue: value.description,
          factor: value.factor,
          idValue: value.id,
          isActive: value.isActive,
          nameValue: value.name,
          orderValue: value.sortOrder || 10000000000,
          price: value.price,
          ref: value.ref,
          valueValue: value.value,
          quantity: 0
        };
      });

      if (findTopping) {
        var _findTopping$dataValu;

        var dataValuesFindTopping = findTopping === null || findTopping === void 0 ? void 0 : (_findTopping$dataValu = findTopping.dataValues) === null || _findTopping$dataValu === void 0 ? void 0 : _findTopping$dataValu.map(function (value) {
          return value.idValue;
        });
        dataValues = dataValues.filter(function (value) {
          return !(dataValuesFindTopping === null || dataValuesFindTopping === void 0 ? void 0 : dataValuesFindTopping.includes(value.idValue));
        });
        dataValues = [].concat(_toConsumableArray(dataValues), _toConsumableArray(findTopping === null || findTopping === void 0 ? void 0 : findTopping.dataValues));
        totalAmount = findTopping === null || findTopping === void 0 ? void 0 : findTopping.totalAmount;
        checkMinTotalAmount = findTopping === null || findTopping === void 0 ? void 0 : findTopping.checkMinTotalAmount;
      } else {
        totalAmount = 0;
        checkMinTotalAmount = topping.attributeSettings.minSelected <= 0;
      }

      var newTopping = {
        dataValues: dataValues.sort(function (a, b) {
          return a.orderValue - b.orderValue;
        }),
        idTopping: topping.attributeSettings.id,
        maxSelected: topping.attributeSettings.maxSelected,
        minSelected: topping.attributeSettings.minSelected,
        name: topping.attribute.name,
        toppingOrder: topping.attributeSettings.sortOrder || 10000000000,
        toppingType: topping.attributeSettings.attributeType,
        totalAmount: totalAmount,
        slug: topping.attribute.slug,
        checkMinTotalAmount: checkMinTotalAmount
      };

      if (newTopping.dataValues.length > 0) {
        toppingsByProduct = [].concat(_toConsumableArray(toppingsByProduct), [newTopping]);
      }

      var valuesWithQuantity = findTopping === null || findTopping === void 0 ? void 0 : (_findTopping$dataValu2 = findTopping.dataValues) === null || _findTopping$dataValu2 === void 0 ? void 0 : (_findTopping$dataValu3 = _findTopping$dataValu2.filter(function (value) {
        return value.quantity > 0;
      })) === null || _findTopping$dataValu3 === void 0 ? void 0 : _findTopping$dataValu3.map(function (value) {
        return value.idValue;
      });

      if ((valuesWithQuantity === null || valuesWithQuantity === void 0 ? void 0 : valuesWithQuantity.length) > 0) {
        var assignedAttributeVariantDependencies = topping.values.filter(function (value) {
          var _value$assignedAttrib;

          return (value === null || value === void 0 ? void 0 : (_value$assignedAttrib = value.assignedAttributeVariantDependencies) === null || _value$assignedAttrib === void 0 ? void 0 : _value$assignedAttrib.length) > 0 && valuesWithQuantity.includes(value.id);
        }).map(function (value) {
          return value.assignedAttributeVariantDependencies;
        }).reduce(function (acc, dependencies) {
          return [].concat(_toConsumableArray(acc), _toConsumableArray(dependencies));
        }, []);

        if (assignedAttributeVariantDependencies.length > 0) {
          attributesWithDependenciesToAdd = assignedAttributeVariantDependencies.map(function (attribute) {
            var totalAmount = 0;
            var checkMinTotalAmount = false;
            var findTopping = line.length > 0 && line.find(function (lineTopping) {
              var _lineTopping$idToppin2, _attribute$attributeS, _attribute$attributeS2;

              return (lineTopping === null || lineTopping === void 0 ? void 0 : (_lineTopping$idToppin2 = lineTopping.idTopping) === null || _lineTopping$idToppin2 === void 0 ? void 0 : _lineTopping$idToppin2.toString()) === (attribute === null || attribute === void 0 ? void 0 : (_attribute$attributeS = attribute.attributeSettings) === null || _attribute$attributeS === void 0 ? void 0 : (_attribute$attributeS2 = _attribute$attributeS.id) === null || _attribute$attributeS2 === void 0 ? void 0 : _attribute$attributeS2.toString());
            });
            var dataValues = attribute.values.map(function (value) {
              return {
                assignedAttributeVariantDependencies: (value === null || value === void 0 ? void 0 : value.assignedAttributeVariantDependencies) || [],
                descriptionValue: value.description,
                factor: value.factor,
                idValue: value.id,
                isActive: value.isActive,
                nameValue: value.name,
                orderValue: value.sortOrder || 10000000000,
                price: value.price,
                ref: value.ref,
                valueValue: value.value,
                quantity: 0
              };
            });

            if (findTopping) {
              var _findTopping$dataValu4, _dataValues;

              var _dataValuesFindTopping = findTopping === null || findTopping === void 0 ? void 0 : (_findTopping$dataValu4 = findTopping.dataValues) === null || _findTopping$dataValu4 === void 0 ? void 0 : _findTopping$dataValu4.map(function (value) {
                return value.idValue;
              });

              dataValues = (_dataValues = dataValues) === null || _dataValues === void 0 ? void 0 : _dataValues.filter(function (value) {
                return !_dataValuesFindTopping.includes(value.idValue);
              });
              dataValues = [].concat(_toConsumableArray(dataValues), _toConsumableArray(findTopping === null || findTopping === void 0 ? void 0 : findTopping.dataValues));
              totalAmount = findTopping === null || findTopping === void 0 ? void 0 : findTopping.totalAmount;
              checkMinTotalAmount = findTopping === null || findTopping === void 0 ? void 0 : findTopping.checkMinTotalAmount;
            } else {
              totalAmount = 0;
              checkMinTotalAmount = attribute.attributeSettings.minSelected <= 0;
            }

            return {
              dataValues: dataValues.sort(function (a, b) {
                return a.orderValue - b.orderValue;
              }),
              idTopping: attribute.attributeSettings.id,
              maxSelected: attribute.attributeSettings.maxSelected,
              minSelected: attribute.attributeSettings.minSelected,
              name: attribute.attribute.name,
              toppingOrder: attribute.attributeSettings.sortOrder || 10000000000,
              toppingType: attribute.attributeSettings.attributeType,
              totalAmount: totalAmount,
              slug: attribute.attribute.slug,
              checkMinTotalAmount: checkMinTotalAmount
            };
          });
          toppingsPositionAndAttributesWithDependencies = [].concat(_toConsumableArray(toppingsPositionAndAttributesWithDependencies), [{
            attributesWithDependencies: attributesWithDependenciesToAdd.sort(function (a, b) {
              var aToppingOrder = a.toppingOrder;
              var bToppingOrder = b.toppingOrder;

              if (aToppingOrder < bToppingOrder) {
                return -1;
              }

              if (aToppingOrder > bToppingOrder) {
                return 1;
              }

              return 0;
            }),
            idToppingDependency: topping.attributeSettings.id
          }]);
        }
      }
    });
    toppingsByProduct = toppingsByProduct.sort(function (a, b) {
      return a.toppingOrder - b.toppingOrder;
    });

    if (((_toppingsPositionAndA = toppingsPositionAndAttributesWithDependencies) === null || _toppingsPositionAndA === void 0 ? void 0 : _toppingsPositionAndA.length) > 0) {
      var toppingIdAndIndex = {};
      toppingsByProduct.forEach(function (topping, index) {
        toppingIdAndIndex = _objectSpread(_objectSpread({}, toppingIdAndIndex), {}, _defineProperty({}, topping.idTopping, index));
      });
      toppingsPositionAndAttributesWithDependencies.forEach(function (topping) {
        var _toppingsByProduct;

        (_toppingsByProduct = toppingsByProduct).splice.apply(_toppingsByProduct, [toppingIdAndIndex[topping.idToppingDependency] + 1, 0].concat(_toConsumableArray(topping.attributesWithDependencies)));
      });
    }

    dispatch({
      type: "add-all-toppings",
      payload: toppingsByProduct
    });
    return function () {};
  }, [line]);
  return React.createElement(React.Fragment, null, toppings.length > 0 && React.createElement(React.Fragment, null, React.createElement(S.PrincipalTitle, null, "".concat(intl.formatMessage(commonMessages.choose), " :")), toppings === null || toppings === void 0 ? void 0 : toppings.map(function (topping, index) {
    return React.createElement(React.Fragment, {
      key: topping.idTopping
    }, React.createElement(ToppingMulti, _extends({}, topping, {
      index: index,
      isLastTopping: toppings.length - 1 === index,
      dispatchToppingsPosition: dispatchToppingsPosition,
      dispatch: dispatch,
      obligatoryButton: obligatoryButton
    })));
  })));
});
Toppings.displayName = "Toppings";