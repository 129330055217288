import { SubCategory } from "@temp/components";
import { perPage } from "@temp/constants";
import * as React from "react";
import { useParams } from "react-router-dom";
var PRODUCTS_PER_PAGE = +perPage;
export var SubCategoryContent = function SubCategoryContent(_ref) {
  var data = _ref.data,
      loadMore = _ref.loadMore,
      loading = _ref.loading;

  var _useParams = useParams(),
      id = _useParams.id,
      redirect = _useParams.redirect,
      redirectHome = _useParams.redirectHome;

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  return React.createElement(SubCategory, {
    loading: loading,
    data: data,
    id: id,
    redirect: redirect && true,
    redirectHome: redirectHome && true,
    loadMore: loadMore,
    PRODUCTS_PER_PAGE: PRODUCTS_PER_PAGE
  });
};
export default SubCategoryContent;