import "./scss/index.scss";
import React from "react";
import { animated, useSpring } from "react-spring";
import { Loader } from "@components/atoms"; // import { generatePath, Link } from "react-router-dom";
// import { cartUrl } from "../../../../app/routes";

export var CartButton = function CartButton(_ref) {
  var loading = _ref.loading,
      message = _ref.message,
      handleOnClick = _ref.handleOnClick;
  var scaleButton = useSpring({
    config: {
      friction: 40,
      mass: 5,
      tension: 350
    },
    from: {
      scale: 1.1
    },
    loop: true,
    to: {
      scale: 1
    }
  });
  return loading ? React.createElement("div", {
    className: "container__loading"
  }, React.createElement(Loader, null)) : React.createElement(animated.button, {
    className: "addProductToCart",
    onClick: handleOnClick,
    style: scaleButton
  }, React.createElement("p", null, message));
};
CartButton.displayName = "CartButton";
export default CartButton;