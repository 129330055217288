import React from "react";
import { FormattedMessage } from "react-intl";
import * as S from "./styles"; // import * as S from "./styles";

export var Address = function Address(_ref) {
  var firstName = _ref.firstName,
      lastName = _ref.lastName,
      companyName = _ref.companyName,
      streetAddress1 = _ref.streetAddress1,
      streetAddress2 = _ref.streetAddress2,
      city = _ref.city,
      postalCode = _ref.postalCode,
      countryArea = _ref.countryArea,
      country = _ref.country,
      phone = _ref.phone,
      neighborhood = _ref.neighborhood,
      city2 = _ref.city2;
  return React.createElement("div", null, streetAddress1 && streetAddress1.toLowerCase() !== "no definida" && React.createElement(S.Label, null, React.createElement("span", null, React.createElement(FormattedMessage, {
    id: "@next.components.atoms.Address.Address.359810770",
    defaultMessage: "Address"
  }), " ", ": "), " ", React.createElement("span", null, streetAddress1), " ", streetAddress2 && React.createElement("span", null, ", ", " ", streetAddress2), React.createElement("br", null)), (neighborhood === null || neighborhood === void 0 ? void 0 : neighborhood.name) && React.createElement(React.Fragment, null, neighborhood === null || neighborhood === void 0 ? void 0 : neighborhood.name, " ", React.createElement("br", null), " ", React.createElement("br", null)), city2 === null || city2 === void 0 ? void 0 : city2.name, React.createElement("br", null), country.country, React.createElement("br", null));
};