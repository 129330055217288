import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  align-self: center;\n  div {\n    display: flex;\n    align-items: center;\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  span:nth-child(1) {\n    font-weight: bold;\n  }\n  span:nth-child(2),\n  span:nth-child(3) {\n    font-weight: lighter;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  font-weight: lighter;\n  color: #f76268;\n  border-bottom: 1px solid grey;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-columns: 80% 20%;\n  position: relative;\n  border-radius: 8px;\n  div:first-of-type {\n    padding-right: 20px;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var Name = styled.p(_templateObject2());
export var Label = styled.div(_templateObject3());
export var Image = styled.div(_templateObject4());