import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "./scss/index.scss";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Media from "react-responsive";
import { Loader } from "@components/atoms";
import { OrderStatus } from "@globalTypes/globalTypes";
import { MenuDelivery } from "@temp/components";
import { useDeliverymanSummaryByArea } from "@saleor/sdk";
import ReportViewDesktop from "./ReportViewDesktop";
import ReportViewMobile from "./ReportViewMobile";

var View = function View() {
  var intl = useIntl();

  var _React$useState = React.useState(0),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      totalQuantityOrders = _React$useState2[0],
      setTotalQuantityOrders = _React$useState2[1];

  var _React$useState3 = React.useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().split("T")[0]),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      startDate = _React$useState4[0],
      setStartDate = _React$useState4[1];

  var _React$useState5 = React.useState(new Date().toISOString().split("T")[0]),
      _React$useState6 = _slicedToArray(_React$useState5, 2),
      endDate = _React$useState6[0],
      setEndDate = _React$useState6[1];

  var _React$useState7 = React.useState(null),
      _React$useState8 = _slicedToArray(_React$useState7, 2),
      orderStatus = _React$useState8[0],
      setOrderStatus = _React$useState8[1];

  var _React$useState9 = React.useState(false),
      _React$useState10 = _slicedToArray(_React$useState9, 2),
      onClickedSearch = _React$useState10[0],
      setOnClickedSearch = _React$useState10[1];

  var _React$useState11 = React.useState(true),
      _React$useState12 = _slicedToArray(_React$useState11, 2),
      dateIsValid = _React$useState12[0],
      setDateIsValid = _React$useState12[1];

  var _React$useState13 = React.useState(false),
      _React$useState14 = _slicedToArray(_React$useState13, 2),
      changeFilter = _React$useState14[0],
      setChangeFilter = _React$useState14[1];

  var _useDeliverymanSummar = useDeliverymanSummaryByArea({
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().split("T")[0],
    endDate: new Date(new Date().setHours(0, 0, 0, 0)).toISOString().split("T")[0],
    orderStatus: null,
    utcDifference: Number(new Date().getTimezoneOffset() / -60)
  }, {
    fetchPolicy: "no-cache"
  }),
      data = _useDeliverymanSummar.data,
      loading = _useDeliverymanSummar.loading,
      refetch = _useDeliverymanSummar.refetch;

  var OrderStatusOptions = React.useMemo(function () {
    return [{
      name: intl.formatMessage({
        defaultMessage: "All",
        "id": "views.ReportSummaryByArea.View.1517876668"
      }).toUpperCase(),
      value: intl.formatMessage({
        defaultMessage: "All",
        "id": "views.ReportSummaryByArea.View.1517876668"
      }).toUpperCase()
    }, {
      name: intl.formatMessage({
        defaultMessage: "Delivered",
        "id": "views.ReportSummaryByArea.View.1464199760"
      }).toUpperCase(),
      value: OrderStatus.DELIVERED.toUpperCase()
    }, {
      name: intl.formatMessage({
        defaultMessage: "Undelivered",
        "id": "views.ReportSummaryByArea.View.3853742296"
      }).toUpperCase(),
      value: OrderStatus.UNDELIVERED.toUpperCase()
    }];
  }, []);

  var handleFilterOrderStatusChange = function handleFilterOrderStatusChange(event) {
    if (event.target.value === intl.formatMessage({
      defaultMessage: "All",
      "id": "views.ReportSummaryByArea.View.1517876668"
    }).toUpperCase()) {
      setOrderStatus(null);
    } else if (event.target.value === OrderStatus.DELIVERED.toUpperCase()) {
      setOrderStatus(OrderStatus.DELIVERED.toUpperCase());
    } else if (event.target.value === OrderStatus.UNDELIVERED.toUpperCase()) {
      setOrderStatus(OrderStatus.UNDELIVERED.toUpperCase());
    }

    setOnClickedSearch(false);
    setChangeFilter(true);
  };

  var handleStartDateChange = function handleStartDateChange(e) {
    setStartDate(e.target.value);
    validateDates(e.target.value, endDate);
    setOnClickedSearch(false);
    setChangeFilter(true);
  };

  var handleEndDateChange = function handleEndDateChange(e) {
    setEndDate(e.target.value);
    validateDates(startDate, e.target.value);
    setOnClickedSearch(false);
    setChangeFilter(true);
  };

  var validateDates = function validateDates(start, end) {
    var startDate = new Date(start);
    var endDate = new Date(end);

    if (startDate <= endDate) {
      setDateIsValid(true);
    } else {
      setDateIsValid(false);
    }
  };

  React.useEffect(function () {
    if (startDate !== "" && endDate !== "" && onClickedSearch && dateIsValid) {
      refetch({
        startDate: new Date(startDate).toISOString().split("T")[0],
        endDate: new Date(endDate).toISOString().split("T")[0],
        orderStatus: orderStatus,
        utcDifference: Number(new Date().getTimezoneOffset() / -60)
      });
      setOnClickedSearch(false);
    }
  }, [startDate, endDate, onClickedSearch, dateIsValid]);
  React.useEffect(function () {
    var _data$me, _data$me2, _data$me2$deliveryman;

    if (!loading && data && (data === null || data === void 0 ? void 0 : data.me) && (data === null || data === void 0 ? void 0 : (_data$me = data.me) === null || _data$me === void 0 ? void 0 : _data$me.deliverymanSummaryByShippingZone) && (data === null || data === void 0 ? void 0 : (_data$me2 = data.me) === null || _data$me2 === void 0 ? void 0 : (_data$me2$deliveryman = _data$me2.deliverymanSummaryByShippingZone) === null || _data$me2$deliveryman === void 0 ? void 0 : _data$me2$deliveryman.length) > 0) {
      var _data$me3, _data$me3$deliveryman;

      var totalQuantity = 0;
      data === null || data === void 0 ? void 0 : (_data$me3 = data.me) === null || _data$me3 === void 0 ? void 0 : (_data$me3$deliveryman = _data$me3.deliverymanSummaryByShippingZone) === null || _data$me3$deliveryman === void 0 ? void 0 : _data$me3$deliveryman.map(function (item) {
        totalQuantity += item.quantity;
      });
      setTotalQuantityOrders(totalQuantity);
    } else {
      setTotalQuantityOrders(0);
    }
  }, [loading, data]);
  return React.createElement(React.Fragment, null, React.createElement(MenuDelivery, null), React.createElement("div", {
    className: "header"
  }, React.createElement("div", {
    className: "contentDate"
  }, React.createElement("div", {
    className: "filterDateDesktop"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "views.ReportSummaryByArea.View.1518809512",
    defaultMessage: "From"
  }), ":"), React.createElement("input", {
    className: "inputDate",
    type: "date",
    value: startDate,
    onChange: handleStartDateChange
  })), React.createElement("div", {
    className: "filterDateDesktop"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "views.ReportSummaryByArea.View.1597631170",
    defaultMessage: "Until"
  }), ":"), React.createElement("input", {
    className: "inputDate",
    type: "date",
    value: endDate,
    onChange: handleEndDateChange
  }))), !dateIsValid && React.createElement("p", {
    className: "errorDate"
  }, React.createElement(FormattedMessage, {
    id: "views.ReportSummaryByArea.View.479324866",
    defaultMessage: "The From date must be less than or equal to the To date"
  })), React.createElement("div", {
    className: "contentFilter"
  }, React.createElement("div", null, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "views.ReportSummaryByArea.View.1588172936",
    defaultMessage: "State"
  }), ":"), React.createElement("select", {
    className: "inputDate",
    id: "DeliveryManStatus",
    name: "DeliveryManStatus",
    value: orderStatus === null ? intl.formatMessage(_defineProperty({
      defaultMessage: "All",
      "id": "views.ReportSummaryByArea.View.1517876668"
    }, "id", "views.ReportSummaryByArea.View.1517876668")).toUpperCase() : orderStatus,
    onChange: handleFilterOrderStatusChange
  }, OrderStatusOptions.map(function (option) {
    return React.createElement("option", {
      value: option.value,
      key: option.value
    }, option.name);
  }))), React.createElement("button", {
    className: "buttonFilter",
    onClick: function onClick() {
      setOnClickedSearch(true);
      setChangeFilter(false);
    }
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "views.ReportSummaryByArea.View.216494201",
    defaultMessage: "Filter"
  }))))), loading && !data ? React.createElement("div", {
    className: "loader"
  }, React.createElement(Loader, null)) : React.createElement(React.Fragment, null, React.createElement(Media, {
    minWidth: 850
  }, React.createElement(ReportViewDesktop, {
    data: data === null || data === void 0 ? void 0 : data.me,
    totalQuantity: totalQuantityOrders,
    changeFilter: changeFilter
  })), React.createElement(Media, {
    maxWidth: 849
  }, React.createElement(ReportViewMobile, {
    data: data === null || data === void 0 ? void 0 : data.me,
    totalQuantity: totalQuantityOrders,
    changeFilter: changeFilter
  }))));
};

export default View;