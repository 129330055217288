import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { useSaleProduct } from "./useSaleProduct";
export var useHomeProductsData = function useHomeProductsData(homeProductsData, items) {
  var _homeProductsData$hom;

  var allProducts = [];
  homeProductsData === null || homeProductsData === void 0 ? void 0 : (_homeProductsData$hom = homeProductsData.homeProducts) === null || _homeProductsData$hom === void 0 ? void 0 : _homeProductsData$hom.forEach(function (productVariant) {
    var sumItems = items === null || items === void 0 ? void 0 : items.filter(function (itemsCart) {
      var _itemsCart$variant;

      return (itemsCart === null || itemsCart === void 0 ? void 0 : (_itemsCart$variant = itemsCart.variant) === null || _itemsCart$variant === void 0 ? void 0 : _itemsCart$variant.id) === productVariant.id;
    }).map(function (item) {
      return item.quantity;
    }).reduce(function (a, b) {
      return a + b;
    }, 0);
    var itemsQuantity = sumItems > 0 ? sumItems : null;
    var indexProductsSubCategory = allProducts === null || allProducts === void 0 ? void 0 : allProducts.findIndex(function (productSubCategory) {
      var _productSubCategory$c;

      return (productSubCategory === null || productSubCategory === void 0 ? void 0 : (_productSubCategory$c = productSubCategory.category) === null || _productSubCategory$c === void 0 ? void 0 : _productSubCategory$c.id) === (productVariant === null || productVariant === void 0 ? void 0 : productVariant.categoryIdLightLoad);
    });

    var _useSaleProduct = useSaleProduct(productVariant),
        discountPercentage = _useSaleProduct.discountPercentage,
        discountValue = _useSaleProduct.discountValue;

    if (indexProductsSubCategory === -1) {
      var _productVariant$categ, _productVariant$categ2;

      allProducts === null || allProducts === void 0 ? void 0 : allProducts.push({
        category: {
          id: productVariant === null || productVariant === void 0 ? void 0 : productVariant.categoryIdLightLoad,
          name: (productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$categ = productVariant.categoryOrderTradeLightLoad[0]) === null || _productVariant$categ === void 0 ? void 0 : _productVariant$categ.name) || (productVariant === null || productVariant === void 0 ? void 0 : productVariant.categoryNameLightLoad),
          orderIndex: (productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$categ2 = productVariant.categoryOrderTradeLightLoad[0]) === null || _productVariant$categ2 === void 0 ? void 0 : _productVariant$categ2.orderIndex) || (productVariant === null || productVariant === void 0 ? void 0 : productVariant.categoryOrderLightLoad) || 1000000000
        },
        products: [{
          category: {
            id: productVariant === null || productVariant === void 0 ? void 0 : productVariant.categoryIdLightLoad,
            name: productVariant === null || productVariant === void 0 ? void 0 : productVariant.categoryNameLightLoad
          },
          subCategory: {
            id: productVariant === null || productVariant === void 0 ? void 0 : productVariant.subCategoryIdLightLoad,
            name: productVariant === null || productVariant === void 0 ? void 0 : productVariant.subCategoryNameLightLoad
          },
          id: productVariant === null || productVariant === void 0 ? void 0 : productVariant.id,
          orderIndex: (productVariant === null || productVariant === void 0 ? void 0 : productVariant.orderIndexSubCategoryLightLoad) || 1000000000,
          name: (productVariant === null || productVariant === void 0 ? void 0 : productVariant.name) || (productVariant === null || productVariant === void 0 ? void 0 : productVariant.productNameLightLoad),
          price: productVariant === null || productVariant === void 0 ? void 0 : productVariant.priceLightLoad,
          discountPercentage: discountPercentage,
          discountValue: discountValue,
          itemsQuantity: itemsQuantity,
          thumbnail: productVariant === null || productVariant === void 0 ? void 0 : productVariant.thumbnailLightLoad,
          __typename: "Product"
        }]
      });
    } else {
      var _allProducts$indexPro;

      var tempProducts = (_allProducts$indexPro = allProducts[indexProductsSubCategory]) === null || _allProducts$indexPro === void 0 ? void 0 : _allProducts$indexPro.products;
      allProducts[indexProductsSubCategory].products = [].concat(_toConsumableArray(tempProducts), [{
        category: {
          id: productVariant === null || productVariant === void 0 ? void 0 : productVariant.categoryIdLightLoad,
          name: productVariant === null || productVariant === void 0 ? void 0 : productVariant.categoryNameLightLoad
        },
        subCategory: {
          id: productVariant === null || productVariant === void 0 ? void 0 : productVariant.subCategoryIdLightLoad,
          name: productVariant === null || productVariant === void 0 ? void 0 : productVariant.subCategoryNameLightLoad
        },
        id: productVariant === null || productVariant === void 0 ? void 0 : productVariant.id,
        orderIndex: (productVariant === null || productVariant === void 0 ? void 0 : productVariant.orderIndexSubCategoryLightLoad) || 1000000000,
        name: (productVariant === null || productVariant === void 0 ? void 0 : productVariant.name) || (productVariant === null || productVariant === void 0 ? void 0 : productVariant.productNameLightLoad),
        price: productVariant === null || productVariant === void 0 ? void 0 : productVariant.priceLightLoad,
        discountPercentage: discountPercentage,
        discountValue: discountValue,
        itemsQuantity: itemsQuantity,
        thumbnail: productVariant === null || productVariant === void 0 ? void 0 : productVariant.thumbnailLightLoad,
        __typename: "Product"
      }]);
    }
  });
  allProducts.sort(function (a, b) {
    var _a$category, _b$category;

    return (a === null || a === void 0 ? void 0 : (_a$category = a.category) === null || _a$category === void 0 ? void 0 : _a$category.orderIndex) - (b === null || b === void 0 ? void 0 : (_b$category = b.category) === null || _b$category === void 0 ? void 0 : _b$category.orderIndex);
  });
  return {
    allProducts: allProducts
  };
};