import "./scss/index.scss";
import React from "react";
import { FormattedMessage } from "react-intl";
import ReactSVG from "react-svg"; // import * as S from "./styles";

// import { Container } from "../Container";
var emptyImg = require("../../../../images/emptyBasket.svg");
/**
 * Template for empty cart page.
 */


var CartEmpty = function CartEmpty(_ref) {
  var button = _ref.button;
  return React.createElement("div", {
    className: "cart__empty"
  }, React.createElement("div", {
    className: "cart__empty__title"
  }, React.createElement("h4", null, React.createElement(FormattedMessage, {
    id: "@next.components.templates.CartEmpty.CartEmpty.966560142",
    defaultMessage: "Your bag is empty"
  }))), React.createElement("div", {
    className: "cart__empty__img"
  }, React.createElement(ReactSVG, {
    path: emptyImg
  })), React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "@next.components.templates.CartEmpty.CartEmpty.4121934554",
    defaultMessage: "You haven\u2019t added anything to your bag. We\u2019re sure you\u2019ll find something in our store"
  })), React.createElement("div", {
    className: "cart__empty__action"
  }, button));
};

export { CartEmpty };