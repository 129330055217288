import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
export var useSaleProduct = function useSaleProduct(productVariant) {
  var _productVariant$saleC, _productVariant$saleC2, _productVariant$saleP, _productVariant$saleP2, _productVariant$saleS, _productVariant$saleS2, _productVariant$price3, _productVariant$price4, _productVariant$price5, _productVariant$price6, _productVariant$price7;

  var saleCategoryLightLoadPercentage = productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$saleC = productVariant.saleCategoryLightLoad) === null || _productVariant$saleC === void 0 ? void 0 : _productVariant$saleC.filter(function (data) {
    return (data === null || data === void 0 ? void 0 : data.type.toLowerCase()) === "percentage";
  });
  var saleCategoryLightLoadFixed = productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$saleC2 = productVariant.saleCategoryLightLoad) === null || _productVariant$saleC2 === void 0 ? void 0 : _productVariant$saleC2.filter(function (data) {
    return (data === null || data === void 0 ? void 0 : data.type.toLowerCase()) === "fixed";
  });
  var saleProductsLightLoadPercentage = productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$saleP = productVariant.saleProductsLightLoad) === null || _productVariant$saleP === void 0 ? void 0 : _productVariant$saleP.filter(function (data) {
    return (data === null || data === void 0 ? void 0 : data.type.toLowerCase()) === "percentage";
  });
  var saleProductsLightLoadFixed = productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$saleP2 = productVariant.saleProductsLightLoad) === null || _productVariant$saleP2 === void 0 ? void 0 : _productVariant$saleP2.filter(function (data) {
    return (data === null || data === void 0 ? void 0 : data.type.toLowerCase()) === "fixed";
  });
  var saleSubCategoryLightLoadPercentage = productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$saleS = productVariant.saleSubCategoryLightLoad) === null || _productVariant$saleS === void 0 ? void 0 : _productVariant$saleS.filter(function (data) {
    return (data === null || data === void 0 ? void 0 : data.type.toLowerCase()) === "percentage";
  });
  var saleSubCategoryLightLoadFixed = productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$saleS2 = productVariant.saleSubCategoryLightLoad) === null || _productVariant$saleS2 === void 0 ? void 0 : _productVariant$saleS2.filter(function (data) {
    return (data === null || data === void 0 ? void 0 : data.type.toLowerCase()) === "fixed";
  });
  var saleCategoryLightLoadPercentageLastOne = [];

  if ((saleCategoryLightLoadPercentage === null || saleCategoryLightLoadPercentage === void 0 ? void 0 : saleCategoryLightLoadPercentage.length) > 0) {
    saleCategoryLightLoadPercentage.sort(function (a, b) {
      return (b === null || b === void 0 ? void 0 : b.value) - (a === null || a === void 0 ? void 0 : a.value);
    });
    saleCategoryLightLoadPercentageLastOne = [saleCategoryLightLoadPercentage[0]];
  }

  var saleCategoryLightLoadFixedLastOne = [];

  if ((saleCategoryLightLoadFixed === null || saleCategoryLightLoadFixed === void 0 ? void 0 : saleCategoryLightLoadFixed.length) > 0) {
    saleCategoryLightLoadFixed.sort(function (a, b) {
      return (b === null || b === void 0 ? void 0 : b.value) - (a === null || a === void 0 ? void 0 : a.value);
    });
    saleCategoryLightLoadFixedLastOne = [saleCategoryLightLoadFixed[0]];
  }

  var saleProductsLightLoadPercentageLastOne = [];

  if ((saleProductsLightLoadPercentage === null || saleProductsLightLoadPercentage === void 0 ? void 0 : saleProductsLightLoadPercentage.length) > 0) {
    saleProductsLightLoadPercentage.sort(function (a, b) {
      return (b === null || b === void 0 ? void 0 : b.value) - (a === null || a === void 0 ? void 0 : a.value);
    });
    saleProductsLightLoadPercentageLastOne = [saleProductsLightLoadPercentage[0]];
  }

  var saleProductsLightLoadFixedLastOne = [];

  if ((saleProductsLightLoadFixed === null || saleProductsLightLoadFixed === void 0 ? void 0 : saleProductsLightLoadFixed.length) > 0) {
    saleProductsLightLoadFixed.sort(function (a, b) {
      return (b === null || b === void 0 ? void 0 : b.value) - (a === null || a === void 0 ? void 0 : a.value);
    });
    saleProductsLightLoadFixedLastOne = [saleProductsLightLoadFixed[0]];
  }

  var saleSubCategoryLightLoadPercentageLastOne = [];

  if ((saleSubCategoryLightLoadPercentage === null || saleSubCategoryLightLoadPercentage === void 0 ? void 0 : saleSubCategoryLightLoadPercentage.length) > 0) {
    saleSubCategoryLightLoadPercentage.sort(function (a, b) {
      return (b === null || b === void 0 ? void 0 : b.value) - (a === null || a === void 0 ? void 0 : a.value);
    });
    saleSubCategoryLightLoadPercentageLastOne = [saleSubCategoryLightLoadPercentage[0]];
  }

  var saleSubCategoryLightLoadFixedLastOne = [];

  if ((saleSubCategoryLightLoadFixed === null || saleSubCategoryLightLoadFixed === void 0 ? void 0 : saleSubCategoryLightLoadFixed.length) > 0) {
    saleSubCategoryLightLoadFixed.sort(function (a, b) {
      return (b === null || b === void 0 ? void 0 : b.value) - (a === null || a === void 0 ? void 0 : a.value);
    });
    saleSubCategoryLightLoadFixedLastOne = [saleSubCategoryLightLoadFixed[0]];
  }

  var salePercentage = [].concat(_toConsumableArray(saleCategoryLightLoadPercentageLastOne), _toConsumableArray(saleProductsLightLoadPercentageLastOne), _toConsumableArray(saleSubCategoryLightLoadPercentageLastOne));
  var saleFixed = [].concat(_toConsumableArray(saleCategoryLightLoadFixedLastOne), _toConsumableArray(saleProductsLightLoadFixedLastOne), _toConsumableArray(saleSubCategoryLightLoadFixedLastOne));
  var salePercentageBiggest = salePercentage.sort(function (a, b) {
    return (b === null || b === void 0 ? void 0 : b.value) - (a === null || a === void 0 ? void 0 : a.value);
  })[0];
  var saleFixedBiggest = saleFixed.sort(function (a, b) {
    return (b === null || b === void 0 ? void 0 : b.value) - (a === null || a === void 0 ? void 0 : a.value);
  })[0];

  switch (true) {
    case salePercentageBiggest !== undefined && saleFixedBiggest !== undefined:
      {
        var _productVariant$price, _productVariant$price2;

        var discountFixedPrice = (productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$price = productVariant.priceLightLoad) === null || _productVariant$price === void 0 ? void 0 : _productVariant$price.amount) - (saleFixedBiggest === null || saleFixedBiggest === void 0 ? void 0 : saleFixedBiggest.value);
        var discountFixedPercentagePrice = 100 - discountFixedPrice * 100 / (productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$price2 = productVariant.priceLightLoad) === null || _productVariant$price2 === void 0 ? void 0 : _productVariant$price2.amount);

        switch (true) {
          case discountFixedPercentagePrice > (salePercentageBiggest === null || salePercentageBiggest === void 0 ? void 0 : salePercentageBiggest.value):
            return {
              discountPercentage: discountFixedPercentagePrice > 100 ? 100 : Math.floor(discountFixedPercentagePrice),
              discountValue: {
                __typename: "Money",
                amount: discountFixedPercentagePrice > 100 ? 0 : discountFixedPrice,
                currency: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$price3 = productVariant.priceLightLoad) === null || _productVariant$price3 === void 0 ? void 0 : _productVariant$price3.currency
              }
            };

          case discountFixedPercentagePrice < (salePercentageBiggest === null || salePercentageBiggest === void 0 ? void 0 : salePercentageBiggest.value):
            return {
              discountPercentage: Math.floor(salePercentageBiggest === null || salePercentageBiggest === void 0 ? void 0 : salePercentageBiggest.value),
              discountValue: {
                __typename: "Money",
                amount: (productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$price4 = productVariant.priceLightLoad) === null || _productVariant$price4 === void 0 ? void 0 : _productVariant$price4.amount) * (1 - (salePercentageBiggest === null || salePercentageBiggest === void 0 ? void 0 : salePercentageBiggest.value) / 100),
                currency: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$price5 = productVariant.priceLightLoad) === null || _productVariant$price5 === void 0 ? void 0 : _productVariant$price5.currency
              }
            };

          default:
            return {
              discountPercentage: Math.floor(salePercentageBiggest === null || salePercentageBiggest === void 0 ? void 0 : salePercentageBiggest.value),
              discountValue: {
                __typename: "Money",
                amount: (productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$price6 = productVariant.priceLightLoad) === null || _productVariant$price6 === void 0 ? void 0 : _productVariant$price6.amount) * (1 - (salePercentageBiggest === null || salePercentageBiggest === void 0 ? void 0 : salePercentageBiggest.value) / 100),
                currency: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$price7 = productVariant.priceLightLoad) === null || _productVariant$price7 === void 0 ? void 0 : _productVariant$price7.currency
              }
            };
        }
      }

    case salePercentageBiggest !== undefined && saleFixedBiggest === undefined:
      {
        var _productVariant$price8, _productVariant$price9;

        return {
          discountPercentage: Math.floor(salePercentageBiggest === null || salePercentageBiggest === void 0 ? void 0 : salePercentageBiggest.value),
          discountValue: {
            __typename: "Money",
            amount: (productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$price8 = productVariant.priceLightLoad) === null || _productVariant$price8 === void 0 ? void 0 : _productVariant$price8.amount) * (1 - (salePercentageBiggest === null || salePercentageBiggest === void 0 ? void 0 : salePercentageBiggest.value) / 100),
            currency: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$price9 = productVariant.priceLightLoad) === null || _productVariant$price9 === void 0 ? void 0 : _productVariant$price9.currency
          }
        };
      }

    case salePercentageBiggest === undefined && saleFixedBiggest !== undefined:
      {
        var _productVariant$price10, _productVariant$price11, _productVariant$price12;

        var _discountFixedPrice = (productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$price10 = productVariant.priceLightLoad) === null || _productVariant$price10 === void 0 ? void 0 : _productVariant$price10.amount) - (saleFixedBiggest === null || saleFixedBiggest === void 0 ? void 0 : saleFixedBiggest.value);

        var _discountFixedPercentagePrice = 100 - _discountFixedPrice * 100 / (productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$price11 = productVariant.priceLightLoad) === null || _productVariant$price11 === void 0 ? void 0 : _productVariant$price11.amount);

        return {
          discountPercentage: _discountFixedPercentagePrice > 100 ? 100 : Math.floor(_discountFixedPercentagePrice),
          discountValue: {
            __typename: "Money",
            amount: _discountFixedPercentagePrice > 100 ? 0 : _discountFixedPrice,
            currency: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$price12 = productVariant.priceLightLoad) === null || _productVariant$price12 === void 0 ? void 0 : _productVariant$price12.currency
          }
        };
      }

    default:
      {
        var _productVariant$price13, _productVariant$price14;

        return {
          discountPercentage: 0,
          discountValue: {
            __typename: "Money",
            amount: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$price13 = productVariant.priceLightLoad) === null || _productVariant$price13 === void 0 ? void 0 : _productVariant$price13.amount,
            currency: productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$price14 = productVariant.priceLightLoad) === null || _productVariant$price14 === void 0 ? void 0 : _productVariant$price14.currency
          }
        };
      }
  }
};