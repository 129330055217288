import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  text-align: right;\n  background: white;\n  padding: ", ";\n  ", "\n  button {\n    width: 88%;\n    border-radius: 10px;\n    padding: 0.9rem 1rem;\n    &:last-child {\n      display: block;\n      margin: 0 auto;\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Footer = styled.div(_templateObject(), function (props) {
  return "0.5rem ".concat(props.theme.spacing.gutter, " 0.5rem ").concat(props.theme.spacing.gutter);
}, function (_ref) {
  var divider = _ref.divider,
      theme = _ref.theme;
  return divider && "border-top: 1px solid ".concat(theme.colors.light, ";");
});