import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  query SearchResults($query: String!, $perPage: Int!, $after: String) {\n    productsSearchLightLoad(search: $query, first: $perPage, after: $after) {\n      edges {\n        node {\n          id\n          name\n          descriptionJson\n          productIdLightLoad\n          productNameLightLoad\n          productDescriptionJsonLightLoad\n          productIsAvailableForPurchaseLightLoad\n          productAvailableForPurchaseLightLoad\n          subCategoryIdLightLoad\n          subCategoryNameLightLoad\n          categoryIdLightLoad\n          categoryNameLightLoad\n          orderIndexSubCategoryLightLoad\n          productImagesLightLoad {\n            id\n            alt\n            url\n          }\n          priceLightLoad {\n            amount\n            currency\n          }\n          saleProductsLightLoad {\n            name\n            type\n            value\n          }\n          saleSubCategoryLightLoad {\n            name\n            type\n            value\n          }\n          saleCategoryLightLoad {\n            name\n            type\n            value\n          }\n          thumbnailLightLoad {\n            alt\n            url\n          }\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n        hasPreviousPage\n        startCursor\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedQuery } from "../../../core/queries";
var searchResultsQuery = gql(_templateObject());
export var TypedSearchResults = TypedQuery(searchResultsQuery);