import "./scss/index.scss";
import React from "react";
import ReactSVG from "react-svg";
import { FormattedMessage } from "react-intl";
import { Button } from "@components/atoms";

var closeWhite = require("../../images/closewhite.svg");

var greaterThanEqual = require("../../images/greaterThanEqual.svg");

export var AlertPayment = function AlertPayment(_ref) {
  var hideModal = _ref.hideModal,
      totalPrice = _ref.totalPrice;
  var currencyLocal = "COP";
  var conversorValue = currencyLocal === "COP" ? "es-CO" : undefined;
  var maximumFractionsDigitsValue = currencyLocal === "COP" ? 0 : 2;
  var totalPay = "0";
  var total = "0";

  if (totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.totalPay) {
    totalPay = Number(totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.totalPay).toLocaleString(conversorValue, {
      currency: currencyLocal,
      style: "currency",
      minimumFractionDigits: 0,
      maximumFractionDigits: maximumFractionsDigitsValue
    });
  }

  if (totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.total) {
    total = Number(totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.total).toLocaleString(conversorValue, {
      currency: currencyLocal,
      style: "currency",
      minimumFractionDigits: 0,
      maximumFractionDigits: maximumFractionsDigitsValue
    });
  }

  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "showContainerPay",
    onClick: hideModal
  }), React.createElement("div", {
    className: "showContainerPay__data"
  }, React.createElement("div", {
    className: "showContainerPay__data__close"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.AlertPayment.index.709932324",
    defaultMessage: "Alert"
  })), React.createElement(ReactSVG, {
    path: closeWhite,
    onClick: hideModal
  })), React.createElement("div", {
    className: "showContainerPay__data__content showContainerPay__data__content--first"
  }, React.createElement(ReactSVG, {
    path: greaterThanEqual
  })), React.createElement("div", {
    className: "showContainerPay__data__content showContainerPay__data__content--last"
  }, React.createElement(FormattedMessage, {
    id: "components.AlertPayment.index.613551891",
    defaultMessage: "You selected pay width: <span>{totalPay}</span>,",
    description: "Alert minimum per purchase",
    values: {
      totalPay: totalPay,
      span: function span() {
        for (var _len = arguments.length, chunks = new Array(_len), _key = 0; _key < _len; _key++) {
          chunks[_key] = arguments[_key];
        }

        return React.createElement("span", null, chunks);
      }
    }
  }), React.createElement(FormattedMessage, {
    id: "components.AlertPayment.index.3049695043",
    defaultMessage: " but the purchase value is <span>{total}</span>",
    description: "Alert minimum per purchase",
    values: {
      total: total,
      span: function span() {
        for (var _len2 = arguments.length, chunks = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
          chunks[_key2] = arguments[_key2];
        }

        return React.createElement("span", null, chunks);
      }
    }
  }), React.createElement("br", null), React.createElement("br", null), React.createElement(FormattedMessage, {
    id: "components.AlertPayment.index.4146045119",
    defaultMessage: "Please select a value greather than: <span>{total}</span>",
    description: "Alert minimum per purchase",
    values: {
      total: total,
      span: function span() {
        for (var _len3 = arguments.length, chunks = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
          chunks[_key3] = arguments[_key3];
        }

        return React.createElement("span", null, chunks);
      }
    }
  })), React.createElement("div", {
    className: "showContainerPay__data__footer"
  }, React.createElement(Button, {
    type: "button",
    testingContext: "",
    onClick: hideModal
  }, React.createElement(FormattedMessage, {
    id: "components.AlertPayment.index.1409486258",
    defaultMessage: "Correct"
  })))));
};
export default AlertPayment;