import "./scss/index.scss";
import React from "react";
import ReactSVG from "react-svg";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Button } from "@components/atoms";
import { baseUrl } from "@temp/app/routes";

var closeWhite = require("../../images/closewhite.svg"); // const greaterThanEqual = require("../../images/greaterThanEqual.svg");


export var AlertCheckoutErrors = function AlertCheckoutErrors(_ref) {
  var hideModal = _ref.hideModal,
      updatePaymentMethod = _ref.updatePaymentMethod,
      errors = _ref.errors;
  var navigate = useNavigate();
  var insufficientFlag = errors === null || errors === void 0 ? void 0 : errors.filter(function (e) {
    return (e === null || e === void 0 ? void 0 : e.code) === "INSUFFICIENT_STOCK";
  });
  var shippingMethodNotFound = errors === null || errors === void 0 ? void 0 : errors.filter(function (e) {
    return (e === null || e === void 0 ? void 0 : e.code) === "SHIPPING_METHOD_NOT_SET";
  });
  var notSupportedPaymentMethod = errors === null || errors === void 0 ? void 0 : errors.filter(function (e) {
    return (e === null || e === void 0 ? void 0 : e.code) === "NOT_SUPPORTED_GATEWAY";
  });

  var closeModal = function closeModal() {
    if (notSupportedPaymentMethod.length > 0) {
      if (updatePaymentMethod) {
        updatePaymentMethod();
      }

      if (!location.pathname.includes("checkout/payment")) {
        navigate("/checkout/payment");
      }
    }

    hideModal();
  };

  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "showContainer",
    onClick: closeModal
  }), React.createElement("div", {
    className: "showContainer__data"
  }, React.createElement("div", {
    className: "showContainer__data__close"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.AlertCheckoutErrors.index.709932324",
    defaultMessage: "Alert"
  })), React.createElement(ReactSVG, {
    path: closeWhite,
    onClick: hideModal
  })), React.createElement("div", {
    className: "showContainer__data__content showContainer__data__content--first showContainer__data__content--last"
  }, React.createElement("ul", null, errors.map(function (element, index) {
    return element.code === "INSUFFICIENT_STOCK" ? React.createElement("li", {
      key: index
    }, JSON.parse(element.message).message) : React.createElement("li", {
      key: index
    }, element.message);
  }))), React.createElement("div", {
    className: "showContainer__data__footer"
  }, notSupportedPaymentMethod.length > 0 ? React.createElement(Button, {
    type: "button",
    color: "primary",
    testingContext: "",
    onClick: closeModal
  }, React.createElement(FormattedMessage, {
    id: "components.AlertCheckoutErrors.index.3577703060",
    defaultMessage: "Ok"
  })) : React.createElement(Button, {
    type: "button",
    color: "primary",
    testingContext: "",
    onClick: function onClick() {
      if (shippingMethodNotFound.length > 0 && !location.pathname.includes("checkout/address")) {
        navigate("/checkout/address");
      } else if (shippingMethodNotFound.length > 0) {
        navigate("/checkout");
        navigate("/checkout/address");
      }

      hideModal();
    }
  }, React.createElement(FormattedMessage, {
    id: "components.AlertCheckoutErrors.index.321321683",
    defaultMessage: "Acept"
  })), insufficientFlag.length > 0 && React.createElement(Button, {
    type: "button",
    color: "primary",
    className: "showContainer__data__footer__modify",
    testingContext: "",
    onClick: function onClick() {
      localStorage.setItem("checkout-modify", "true");
      navigate(baseUrl);
    }
  }, React.createElement(FormattedMessage, {
    id: "components.AlertCheckoutErrors.index.2249881922",
    defaultMessage: "Modify"
  })))));
};
export default AlertCheckoutErrors;