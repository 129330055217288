export var finalToppingValuePrice = function finalToppingValuePrice(price, quantity) {
  var finalValue = null;
  var valuePrice = price && price.amount > 0 ? {
    amount: price.amount * quantity,
    currency: price.currency
  } : null;

  if (valuePrice) {
    var conversorValue = valuePrice.currency === "COP" ? "es-CO" : undefined;
    var maximumFractionsDigitsValue = valuePrice.currency === "COP" ? 0 : 2;
    var moneyValue = valuePrice === null || valuePrice === void 0 ? void 0 : valuePrice.amount;
    finalValue = valuePrice.currency && valuePrice.currency !== "" && moneyValue ? moneyValue.toLocaleString(conversorValue, {
      currency: valuePrice.currency,
      style: "currency",
      minimumFractionDigits: 0,
      maximumFractionDigits: maximumFractionsDigitsValue
    }) : moneyValue && moneyValue.toString();
  }

  return finalValue;
};