import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n      fragment IndividualProduct on ProductVariant {\n        id\n        name\n        productIdLightLoad\n        productNameLightLoad\n        categoryNameLightLoad\n        categoryIdLightLoad\n        hasAttributeLightLoad\n        productDefaultImageIdLightLoad\n        productImagesLightLoad {\n          id\n          alt\n          url\n        }\n        priceLightLoad {\n          amount\n          currency\n        }\n        saleProductsLightLoad {\n          name\n          type\n          value\n        }\n        saleSubCategoryLightLoad {\n          name\n          type\n          value\n        }\n        saleCategoryLightLoad {\n          name\n          type\n          value\n        }\n      }\n    "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import "./scss/index.scss";
import React, { useContext } from "react";
import { ApolloContext } from "react-apollo";
import gql from "graphql-tag";
import { useCart, useProductDescriptionAndAttributes } from "@saleor/sdk";
import { useProductData } from "@temp/hooks";
import { useParams } from "react-router-dom";
import Page from "./Page";
import { MetaWrapper, NotFound, MainMenu } from "../../components";
import { getGraphqlIdFromDBId } from "../../core/utils";

var PageWithQueryAttributes = function PageWithQueryAttributes(props) {
  return React.createElement(Page, props);
};

var View = function View() {
  var _useParams = useParams(),
      id = _useParams.id,
      lineId = _useParams.lineId;

  var _useCart = useCart(),
      addItem = _useCart.addItem,
      items = _useCart.items,
      removeItem = _useCart.removeItem,
      updateItem = _useCart.updateItem;

  var _useContext = useContext(ApolloContext),
      ApolloClient = _useContext.client;

  var productVariantId = getGraphqlIdFromDBId(id, "ProductVariant");

  var _useProductDescriptio = useProductDescriptionAndAttributes({
    id: id
  }, {
    fetchPolicy: "network-only"
  }),
      dataNetwork = _useProductDescriptio.data,
      loadingNetwork = _useProductDescriptio.loading;

  var productVariantActual = ApolloClient.readFragment({
    id: "ProductVariant:".concat(productVariantId),
    fragment: gql(_templateObject()),
    fragmentName: "IndividualProduct"
  });

  if (!(productVariantActual === null || productVariantActual === void 0 ? void 0 : productVariantActual.id)) {
    return React.createElement(NotFound, null);
  }

  var _useProductData = useProductData(productVariantId, items, productVariantActual, lineId),
      productFinalData = _useProductData.productFinalData;

  return React.createElement(React.Fragment, null, React.createElement(MainMenu, {
    gobackProduct: true,
    search: false,
    cart: true
  }), !!(productFinalData === null || productFinalData === void 0 ? void 0 : productFinalData.name) && !!(productFinalData === null || productFinalData === void 0 ? void 0 : productFinalData.price) ? React.createElement(MetaWrapper, {
    meta: {
      title: productFinalData === null || productFinalData === void 0 ? void 0 : productFinalData.name
    }
  }, React.createElement("div", {
    className: "categories-page"
  }, React.createElement(PageWithQueryAttributes, {
    dataNetwork: dataNetwork === null || dataNetwork === void 0 ? void 0 : dataNetwork.productDescriptionAndAttributesLightLoad,
    loadingNetwork: loadingNetwork,
    product: productFinalData,
    add: addItem,
    items: items,
    updateItem: updateItem,
    removeItem: removeItem,
    lineId: lineId
  }))) : React.createElement(NotFound, null));
};

export default View;