import "./scss/index.scss";
import * as React from "react";
import { useParams } from "react-router-dom";
import ViewNetwork from "./ViewNetwork";

var View = function View() {
  var _useParams = useParams(),
      id = _useParams.id;

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  React.useEffect(function () {
    localStorage.removeItem("loadingLoadMoreSubCategory");
    localStorage.removeItem("loadingLoadMoreSaleCategory");
    window.scrollTo(0, 0);
    return function () {};
  }, [id]);
  return React.createElement(React.Fragment, null, React.createElement(ViewNetwork, null));
};

export default View;