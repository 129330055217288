import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  font-weight: ", ";\n  text-transform: uppercase;\n  transition: 300ms;\n  z-index: 0;\n\n  ", "\n\n  &:hover, &:focus {\n    outline: none;\n    color: ", ";\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
import { NavLink } from "react-router-dom";
export var Link = styled(NavLink)(_templateObject(), function (_ref) {
  var theme = _ref.theme;
  return theme.typography.boldFontWeight;
}, function (_ref2) {
  var fullWidth = _ref2.fullWidth;
  return fullWidth && "\n      display: block;\n      width: 100%;\n  ";
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.colors.primary;
});