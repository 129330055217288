import "../../views/Sale/scss/index.scss";
import * as React from "react";
import { Skeleton } from "@components/atoms/Skeleton/Skeleton";
import { PageSale } from "@temp/views/Sale";
import { MetaWrapper } from "..";

var Sale = function Sale(_ref) {
  var data = _ref.data,
      loading = _ref.loading,
      sendProducts = _ref.sendProducts,
      maxProductsPerCategory = _ref.maxProductsPerCategory;

  if (loading) {
    return React.createElement("div", {
      className: "categories-page"
    }, React.createElement("div", {
      className: "categories-pageCate__loading"
    }, React.createElement(Skeleton, null)));
  }

  return React.createElement("div", {
    className: "categories-page"
  }, React.createElement(MetaWrapper, {
    meta: {
      type: "product.sale"
    }
  }, React.createElement(PageSale, {
    sales: sendProducts,
    maxProductsPerCategory: maxProductsPerCategory
  })));
};

export default Sale;