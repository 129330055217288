import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { generateCategoryProductUrl, generateCategoryUrl, getGraphqlIdFromDBId } from "@temp/core/utils";
import { commonMessages } from "@temp/intl";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector, updateCategorySearchValue, updateSugcategorySearchValue, updateQueryValue } from "@temp/redux";
import { baseUrl, saleUrl } from "@temp/app/routes/paths";
export var useCategoriesSubCategories = function useCategoriesSubCategories(id, categoriesData, categoriesDataJSON, loading, hideModal) {
  var dispatch = useAppDispatch();
  var navigate = useNavigate();
  var intl = useIntl();

  var _useAppSelector = useAppSelector(function (state) {
    return state.search;
  }),
      searchCategory = _useAppSelector.searchCategory,
      searchSubcategory = _useAppSelector.searchSubcategory;

  var initialStateCategory = {
    name: intl.formatMessage(commonMessages.all),
    value: intl.formatMessage(commonMessages.all),
    subcategories: []
  };
  var initialStateSubcategory = {
    name: intl.formatMessage(commonMessages.all),
    value: intl.formatMessage(commonMessages.all)
  };

  var _useState = useState({
    category: searchCategory.value !== "" ? searchCategory : initialStateCategory,
    subCategory: searchSubcategory.value !== "" ? searchSubcategory : initialStateSubcategory
  }),
      _useState2 = _slicedToArray(_useState, 2),
      values = _useState2[0],
      setValues = _useState2[1];

  var _useState3 = useState([initialStateSubcategory]),
      _useState4 = _slicedToArray(_useState3, 2),
      subCategoryOptions = _useState4[0],
      setSubCategoryOptions = _useState4[1];

  categoriesData.unshift(initialStateCategory);
  var handleSubCategoryValues = useCallback(function (initialStateCategory, searchSubcategory, values) {
    setValues({
      category: values.category,
      subCategory: searchSubcategory.value !== "" ? searchSubcategory : initialStateSubcategory
    });

    if (values.category.value !== initialStateCategory.value) {
      var newSubcategoryOptions = categoriesDataJSON[values.category.value].subcategories;
      newSubcategoryOptions.unshift(initialStateSubcategory);
      setSubCategoryOptions(newSubcategoryOptions);
    } else {
      setSubCategoryOptions([initialStateSubcategory]);
    }
  }, [categoriesDataJSON]);
  useEffect(function () {
    if (!loading) {
      handleSubCategoryValues(initialStateCategory, searchSubcategory, values);
    }
  }, [loading]);
  var submit = useCallback(function (values) {
    var _values$category, _values$category2, _values$category3, _values$subCategory, _values$subCategory2, _values$subCategory3, _values$subCategory4, _values$category4, _values$subCategory5, _values$category5, _values$category6, _values$category7, _values$subCategory6, _values$category8, _values$category9;

    if ((values === null || values === void 0 ? void 0 : (_values$category = values.category) === null || _values$category === void 0 ? void 0 : _values$category.value) === initialStateCategory.value && location.pathname !== baseUrl && !location.pathname.includes("sale")) {
      navigate(baseUrl);
    } else if ((values === null || values === void 0 ? void 0 : (_values$category2 = values.category) === null || _values$category2 === void 0 ? void 0 : _values$category2.value) === initialStateCategory.value && location.pathname !== baseUrl && location.pathname.includes("sale") && location.pathname !== saleUrl) {
      navigate(saleUrl);
    } else if ((values === null || values === void 0 ? void 0 : (_values$category3 = values.category) === null || _values$category3 === void 0 ? void 0 : _values$category3.value) === initialStateCategory.value && (location.pathname === baseUrl || location.pathname === saleUrl)) {
      hideModal();
    } else if ((values === null || values === void 0 ? void 0 : (_values$subCategory = values.subCategory) === null || _values$subCategory === void 0 ? void 0 : _values$subCategory.value) !== initialStateSubcategory.value && (values === null || values === void 0 ? void 0 : (_values$subCategory2 = values.subCategory) === null || _values$subCategory2 === void 0 ? void 0 : _values$subCategory2.value) !== getGraphqlIdFromDBId(id, "Category") && (values === null || values === void 0 ? void 0 : (_values$subCategory3 = values.subCategory) === null || _values$subCategory3 === void 0 ? void 0 : _values$subCategory3.value) !== searchSubcategory.value || (values === null || values === void 0 ? void 0 : (_values$subCategory4 = values.subCategory) === null || _values$subCategory4 === void 0 ? void 0 : _values$subCategory4.value) !== initialStateSubcategory.value && (values === null || values === void 0 ? void 0 : (_values$category4 = values.category) === null || _values$category4 === void 0 ? void 0 : _values$category4.value) === searchCategory.value && (values === null || values === void 0 ? void 0 : (_values$subCategory5 = values.subCategory) === null || _values$subCategory5 === void 0 ? void 0 : _values$subCategory5.value) !== searchSubcategory.value) {
      var categoryProducUrl = "".concat(location.pathname.includes("sale") ? "/sale" : "").concat(generateCategoryProductUrl(values.subCategory.value, values.subCategory.name));
      localStorage.setItem("product_url", categoryProducUrl);
      localStorage.setItem("subcategory_url", categoryProducUrl);
      dispatch(updateSugcategorySearchValue({
        searchSubcategory: values.subCategory
      }));
      dispatch(updateCategorySearchValue({
        searchCategory: values.category
      }));
      dispatch(updateQueryValue());
      navigate(categoryProducUrl);
      hideModal();
    } else if ((values === null || values === void 0 ? void 0 : (_values$category5 = values.category) === null || _values$category5 === void 0 ? void 0 : _values$category5.value) !== initialStateCategory.value && (values === null || values === void 0 ? void 0 : (_values$category6 = values.category) === null || _values$category6 === void 0 ? void 0 : _values$category6.value) !== getGraphqlIdFromDBId(id, "Category") && (values === null || values === void 0 ? void 0 : (_values$category7 = values.category) === null || _values$category7 === void 0 ? void 0 : _values$category7.value) !== searchCategory.value || (values === null || values === void 0 ? void 0 : (_values$subCategory6 = values.subCategory) === null || _values$subCategory6 === void 0 ? void 0 : _values$subCategory6.value) === initialStateSubcategory.value && ((values === null || values === void 0 ? void 0 : (_values$category8 = values.category) === null || _values$category8 === void 0 ? void 0 : _values$category8.value) !== getGraphqlIdFromDBId(id, "Category") || (values === null || values === void 0 ? void 0 : (_values$category9 = values.category) === null || _values$category9 === void 0 ? void 0 : _values$category9.value) === searchCategory.value)) {
      var categoryUrl = "".concat(location.pathname.includes("sale") ? "/sale" : "").concat(generateCategoryUrl(values.category.value, values.category.name));
      localStorage.setItem("product_url", categoryUrl);

      if (location.pathname.includes("sale")) {
        localStorage.setItem("salesubcategory_url", categoryUrl);
      } else {
        localStorage.setItem("category_url", categoryUrl);
      }

      dispatch(updateSugcategorySearchValue({
        searchSubcategory: values.subCategory
      }));
      dispatch(updateCategorySearchValue({
        searchCategory: values.category
      }));
      dispatch(updateQueryValue());
      navigate(categoryUrl);
      hideModal();
    } else {
      hideModal();
    }
  }, [id]);
  var handleCleanFilters = useCallback(function () {
    setValues({
      category: initialStateCategory,
      subCategory: initialStateSubcategory
    });
  }, []);
  var handleCleanSubCategoryFilters = useCallback(function () {
    setValues(function (values) {
      return _objectSpread(_objectSpread({}, values), {}, {
        subCategory: initialStateSubcategory
      });
    });
  }, []);
  return {
    setValues: setValues,
    values: values,
    handleSubCategoryValues: handleSubCategoryValues,
    initialStateCategory: initialStateCategory,
    subCategoryOptions: subCategoryOptions,
    handleCleanFilters: handleCleanFilters,
    handleCleanSubCategoryFilters: handleCleanSubCategoryFilters,
    submit: submit
  };
};