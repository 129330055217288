/* eslint-disable import/no-unresolved */
import React from "react";
import { FormattedMessage } from "react-intl"; // import { RichTextContent } from "@components/atoms";

import * as S from "./styles";
// enum TABS {
//   DESCRIPTION,
//   ATTRIBUTES,
// }
export var ProductDescription = React.memo(function (_ref) {
  var _ref$description = _ref.description,
      description = _ref$description === void 0 ? "" : _ref$description,
      _ref$descriptionJson = _ref.descriptionJson,
      descriptionJson = _ref$descriptionJson === void 0 ? "" : _ref$descriptionJson,
      attributes = _ref.attributes;
  // const [activeTab, setActiveTab] = React.useState<TABS>(
  //   description.length > 0 || descriptionJson.length > 0
  //     ? TABS.DESCRIPTION
  //     : TABS.ATTRIBUTES
  // );
  // const descriptionJsonValue = JSON.parse(descriptionJson);
  return React.createElement("div", null, description && React.createElement(S.Wrapper, null, React.createElement("h2", null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.ProductDescription.ProductDescription.2623707255",
    defaultMessage: "DESCRIPTION"
  })), React.createElement("p", null, description === null || description === void 0 ? void 0 : description.replace(/"/g, ""))));
});