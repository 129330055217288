import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "./scss/index.scss";
import * as React from "react";
import { useIntl } from "react-intl";
import { Button, Icon, Loader } from "@components/atoms";
import { useCart } from "@saleor/sdk";
import { commonMessages } from "@temp/intl";
import { useAppSelector, useAppDispatch, updateCategorySearchValue } from "@temp/redux";
import { maybe } from "../../core/utils";
import { OrderButton, ProductNoFound, ProductsFeatured } from "../../components";
import { ProductList } from "../../@next/components/organisms";

var Page = function Page(_ref) {
  var category = _ref.category,
      displayLoader = _ref.displayLoader,
      hasNextPage = _ref.hasNextPage,
      onLoadMore = _ref.onLoadMore,
      products = _ref.products,
      redirect = _ref.redirect;

  var _useCart = useCart(),
      items = _useCart.items;

  var _useAppSelector = useAppSelector(function (state) {
    return state;
  }),
      _useAppSelector$searc = _useAppSelector.search,
      searchCategory = _useAppSelector$searc.searchCategory,
      searchQueryValue = _useAppSelector$searc.searchQueryValue,
      searchTitleHeight = _useAppSelector$searc.searchTitleHeight;

  var dispatch = useAppDispatch();
  var canDisplayProducts = maybe(function () {
    return !!products && products.length > 0;
  });
  var hasProducts = canDisplayProducts && products.length > 0;
  var intl = useIntl();

  var _React$useState = React.useState(window.innerWidth <= 850 ? 140 : 240),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      marginTopState = _React$useState2[0],
      setMarginTopState = _React$useState2[1];

  var _useAppSelector2 = useAppSelector(function (store) {
    return store;
  }),
      hasAttentionAtCurrentTime = _useAppSelector2.tradeAttention.hasAttentionAtCurrentTime;

  React.useEffect(function () {
    var changeMarginTop = function changeMarginTop() {
      if (window.innerWidth <= 850 && marginTopState !== 160 + searchTitleHeight && hasAttentionAtCurrentTime) {
        setMarginTopState(160 + searchTitleHeight);
      } else if (window.innerWidth > 850 && marginTopState !== 250 + searchTitleHeight && hasAttentionAtCurrentTime) {
        setMarginTopState(250 + searchTitleHeight);
      } else if (window.innerWidth <= 850 && marginTopState !== 180 + searchTitleHeight && !hasAttentionAtCurrentTime) {
        setMarginTopState(180 + searchTitleHeight);
      } else if (window.innerWidth > 850 && marginTopState !== 270 + searchTitleHeight && !hasAttentionAtCurrentTime) {
        setMarginTopState(270 + searchTitleHeight);
      }
    };

    changeMarginTop();
    return function () {};
  }, [searchTitleHeight]);
  React.useEffect(function () {
    var changeMarginTop = function changeMarginTop() {
      if (window.innerWidth <= 850 && marginTopState !== 160 + searchTitleHeight && hasAttentionAtCurrentTime) {
        setMarginTopState(160 + searchTitleHeight);
      } else if (window.innerWidth > 850 && marginTopState !== 250 + searchTitleHeight && hasAttentionAtCurrentTime) {
        setMarginTopState(250 + searchTitleHeight);
      } else if (window.innerWidth <= 850 && marginTopState !== 180 + searchTitleHeight && !hasAttentionAtCurrentTime) {
        setMarginTopState(180 + searchTitleHeight);
      } else if (window.innerWidth > 850 && marginTopState !== 270 + searchTitleHeight && !hasAttentionAtCurrentTime) {
        setMarginTopState(270 + searchTitleHeight);
      }
    };

    changeMarginTop();
    window.addEventListener("resize", changeMarginTop);
    return function () {
      window.removeEventListener("resize", changeMarginTop);
    };
  }, []);
  React.useEffect(function () {
    if (searchCategory.value.toUpperCase() === intl.formatMessage(commonMessages.all).toUpperCase() || searchCategory.value === "" && category) {
      var newValue = category === null || category === void 0 ? void 0 : category.id;
      var newName = category === null || category === void 0 ? void 0 : category.name;
      dispatch(updateCategorySearchValue({
        searchCategory: {
          name: newName,
          value: newValue,
          subcategories: []
        }
      }));
    }
  }, [category]);
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "category"
  }, React.createElement("div", {
    className: "container"
  }, React.createElement("div", {
    className: "container__saleCategory",
    style: {
      marginTop: marginTopState
    }
  }, canDisplayProducts ? React.createElement(React.Fragment, null, React.createElement(OrderButton, {
    amount: items !== undefined ? items.length : 0
  }), React.createElement(ProductList, {
    products: products
  }), !displayLoader && hasNextPage ? React.createElement("div", {
    className: "container__saleCategory__loadMore"
  }, React.createElement(Button, {
    testingContext: "ProductList",
    onClick: onLoadMore
  }, React.createElement(Icon, {
    size: 24,
    name: "plus",
    color: "#ED1C24"
  }), React.createElement("span", null, "Ver m\xE1s"))) : displayLoader && hasNextPage && React.createElement("div", {
    className: "container__subCategory__loading"
  }, React.createElement(Loader, null))) : (searchQueryValue !== "" || searchCategory.value !== "") && React.createElement(ProductNoFound, null)), !hasProducts && searchQueryValue === "" && searchCategory.value === "" && React.createElement(ProductsFeatured, {
    title: intl.formatMessage(commonMessages.youMightLike)
  }))));
};

export default Page;