import "./scss/index.scss";
import * as React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useCart } from "@saleor/sdk/";
import { commonMessages } from "@temp/intl";
import { useAppDispatch, updateCategorySearchValue } from "@temp/redux";
import { ProductList } from "../../@next/components/organisms";
import { maybe, generateCategoryUrl } from "../../core/utils";
import { OrderButton, ProductNoFound, ProductsFeatured } from "../../components";

var Page = function Page(_ref) {
  var maxProductsPerCategory = _ref.maxProductsPerCategory,
      allProducts = _ref.allProducts;

  var _useCart = useCart(),
      items = _useCart.items;

  var dispatch = useAppDispatch();
  var canDisplayProducts = maybe(function () {
    return !!allProducts && allProducts.length > 0;
  });
  var hasProducts = canDisplayProducts && allProducts.length > 0;
  var intl = useIntl();
  var handleClickToMoreCategories = React.useCallback(function (category) {
    localStorage.setItem("categories-from-click", "true");
    localStorage.setItem("scroll-home", window.scrollY.toString());
    dispatch(updateCategorySearchValue({
      searchCategory: {
        name: category.name,
        value: category.id,
        subcategories: []
      }
    }));
  }, []);
  return React.createElement(React.Fragment, null, canDisplayProducts && allProducts.length > 0 ? React.createElement(React.Fragment, null, React.createElement(OrderButton, {
    amount: items !== undefined ? items.length : 0
  }), allProducts.map(function (data, index) {
    return React.createElement("div", {
      key: "homee-".concat(index)
    }, React.createElement("div", {
      className: "container-categoryHome__sign"
    }, React.createElement("h2", null, data.category.name, "\xA0\xA0\xA0\xA0"), React.createElement("div", null, " ")), data.products.length > maxProductsPerCategory ? React.createElement(React.Fragment, null, React.createElement("div", {
      className: "container-categoryHome__see-more"
    }, React.createElement(Link, {
      onClick: function onClick() {
        handleClickToMoreCategories(data.category);
      },
      key: data.category.id,
      to: generateCategoryUrl(data.category.id, data.category.name)
    }, React.createElement(FormattedMessage, {
      id: "views.Homee.PageProducts.207418477",
      defaultMessage: "See all"
    }))), React.createElement(ProductList, {
      handleClickTo: function handleClickTo() {
        handleClickToMoreCategories(data.category);
      },
      products: data.products.slice(0, maxProductsPerCategory),
      seeMore: true,
      to: generateCategoryUrl(data.category.id, data.category.name)
    })) : React.createElement(ProductList, {
      products: data.products
    }));
  })) : React.createElement(ProductNoFound, null), !hasProducts && React.createElement(ProductsFeatured, {
    title: intl.formatMessage(commonMessages.youMightLike)
  }));
};

export default Page;