import React from "react";
import { isEqual } from "lodash";
import ReactSVG from "react-svg";
import { Money } from "@components/containers";
import { Thumbnail } from "@components/molecules";
import { useAuth } from "@saleor/sdk";
import * as S from "./styles";
import * as S2 from "../../organisms/AddToCartSection/styles";

var percentajeImg = require("../../../../images/ofertadescuenta.svg");

export var ProductTile = function ProductTile(_ref) {
  var _user$currentTrade;

  var otherProducts = _ref.otherProducts,
      product = _ref.product;

  var _useAuth = useAuth(),
      user = _useAuth.user;

  var userThumbnail = {
    thumbnail: user === null || user === void 0 ? void 0 : (_user$currentTrade = user.currentTrade) === null || _user$currentTrade === void 0 ? void 0 : _user$currentTrade.defaultProductImageThumbnail
  };
  return React.createElement(S.Wrapper, null, !isEqual(product === null || product === void 0 ? void 0 : product.discountPercentage, 0) ? React.createElement(React.Fragment, null, React.createElement(S.Image, null, React.createElement(Thumbnail, {
    source: product.thumbnail ? product : userThumbnail
  }), React.createElement(S.Percentaje, null, React.createElement(S.PercentajeValue, null, (product === null || product === void 0 ? void 0 : product.discountPercentage) && React.createElement("span", null, "-".concat(+(product === null || product === void 0 ? void 0 : product.discountPercentage), "%")), React.createElement(ReactSVG, {
    path: percentajeImg
  }))), product.itemsQuantity && React.createElement(S.ItemsQuantity, null, product.itemsQuantity)), React.createElement(S.PriceWithDiscount, null, React.createElement(Money, {
    money: product === null || product === void 0 ? void 0 : product.discountValue
  }), React.createElement(S2.UndiscountedPrice, null, React.createElement(Money, {
    money: product === null || product === void 0 ? void 0 : product.price
  })))) : React.createElement(React.Fragment, null, React.createElement(S.Image, null, React.createElement(Thumbnail, {
    source: product.thumbnail ? product : userThumbnail
  }), product.itemsQuantity && React.createElement(S.ItemsQuantity, null, product.itemsQuantity)), React.createElement(S.Price, null, React.createElement(Money, {
    money: product === null || product === void 0 ? void 0 : product.price
  }))), React.createElement(S.Title, null, product === null || product === void 0 ? void 0 : product.name));
};