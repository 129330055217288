import React from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useAppSelector } from "@temp/redux";
import { useCategoriesSubCategories, useQueryAllCategoriesSubcategories } from "../hooks";
import { AlertFilters } from "../AlertFilters";
export var AlertFiltersAll = function AlertFiltersAll(_ref) {
  var hideModal = _ref.hideModal;

  var _useParams = useParams(),
      id = _useParams.id;

  var intl = useIntl();

  var _useAppSelector = useAppSelector(function (state) {
    return state.search;
  }),
      searchCategory = _useAppSelector.searchCategory,
      searchSubcategory = _useAppSelector.searchSubcategory;

  var _useQueryAllCategorie = useQueryAllCategoriesSubcategories(),
      categoriesData = _useQueryAllCategorie.categoriesData,
      categoriesDataJSON = _useQueryAllCategorie.categoriesDataJSON,
      loading = _useQueryAllCategorie.loading;

  var _useCategoriesSubCate = useCategoriesSubCategories(id, categoriesData, categoriesDataJSON, loading, hideModal),
      setValues = _useCategoriesSubCate.setValues,
      values = _useCategoriesSubCate.values,
      handleSubCategoryValues = _useCategoriesSubCate.handleSubCategoryValues,
      initialStateCategory = _useCategoriesSubCate.initialStateCategory,
      subCategoryOptions = _useCategoriesSubCate.subCategoryOptions,
      handleCleanFilters = _useCategoriesSubCate.handleCleanFilters,
      handleCleanSubCategoryFilters = _useCategoriesSubCate.handleCleanSubCategoryFilters,
      submit = _useCategoriesSubCate.submit;

  return React.createElement(AlertFilters, {
    hideModal: hideModal,
    searchCategory: searchCategory,
    categoriesData: categoriesData,
    values: values,
    setValues: setValues,
    handleSubCategoryValues: handleSubCategoryValues,
    initialStateCategory: initialStateCategory,
    intl: intl,
    searchSubcategory: searchSubcategory,
    subCategoryOptions: subCategoryOptions,
    handleCleanFilters: handleCleanFilters,
    handleCleanSubCategoryFilters: handleCleanSubCategoryFilters,
    submit: submit
  });
};