import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Media from "react-responsive";
import ReactSVG from "react-svg";
import { CachedImage } from "@components/molecules";
import { xLargeScreen } from "@styles/constants";
import { translateOrderStatus } from "@temp/intl";
import { useNavigate } from "react-router-dom";
import { maybe } from "../../../../core/utils";
import * as S from "./styles";

var logoBasketImg = require("../../../../images/basketStatus.svg");

export var OrderTabel = function OrderTabel(_ref) {
  var orders = _ref.orders,
      inProgressFlag = _ref.inProgressFlag;
  var intl = useIntl();
  var navigate = useNavigate();
  return React.createElement(React.Fragment, null, orders && orders.map(function (order) {
    var _order$node, _order$node2;

    var dateRender = new Date(order.node.created).toLocaleString("en-GB", {
      hour12: true,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit"
    });
    var inProgress = ["CANCELED_FULFILLMENT", "PENDING", "DRAFT", "FULFILLED", "FULFILLED_WITH_CHOICES", "SENT", "ARRIVED", "SCHEDULED"];
    var orderRedirect = inProgress.includes(order.node.status.toUpperCase()) ? "/statusl/".concat(order === null || order === void 0 ? void 0 : (_order$node = order.node) === null || _order$node === void 0 ? void 0 : _order$node.id, "/").concat(order.node.status.toLowerCase()) : "/pstatusp/".concat(order === null || order === void 0 ? void 0 : (_order$node2 = order.node) === null || _order$node2 === void 0 ? void 0 : _order$node2.id, "/").concat(order.node.status.toLowerCase());
    return React.createElement(Media, {
      key: order.node.number,
      maxWidth: xLargeScreen
    }, function (matches) {
      return matches ? React.createElement(React.Fragment, null, React.createElement(S.Row, {
        "data-test": "orderEntry",
        "data-test-id": order.node.number,
        key: order.node.number,
        onClick: function onClick(evt) {
          var _order$node3;

          evt.stopPropagation();
          localStorage.setItem("order_token", order === null || order === void 0 ? void 0 : (_order$node3 = order.node) === null || _order$node3 === void 0 ? void 0 : _order$node3.token);

          if (inProgressFlag) {
            localStorage.removeItem("flag_history");
            localStorage.removeItem("flag_basket");
            localStorage.setItem("flag_progress", "true");
          } else {
            localStorage.removeItem("flag_progress");
            localStorage.removeItem("flag_basket");
            localStorage.setItem("flag_history", "true");
          }

          navigate(orderRedirect);
        }
      }, React.createElement(S.Image, null, order.node.trade.logoImage ? React.createElement(S.ImageShop, null, React.createElement(CachedImage, {
        url: maybe(function () {
          return order.node.trade.logoImage.url;
        }),
        alt: maybe(function () {
          return order.node.trade.logoImage.alt ? order.node.trade.logoImage.alt : "";
        }, "")
      }, React.createElement(React.Fragment, null))) : React.createElement(ReactSVG, {
        path: logoBasketImg
      })), React.createElement(S.OrderData, null, React.createElement(S.OrderNumber, null, React.createElement(FormattedMessage, {
        id: "@next.components.molecules.OrderTabel.OrderTabel.2768410132",
        defaultMessage: "order"
      }), " ", " #", order.node.number), React.createElement(S.OrderDate, null, dateRender), React.createElement(S.OrderTrade, null, order.node.trade.name), React.createElement(S.OrderProducts, null, order.node.amountOfProducts, " ", React.createElement(S.OrderProductsParragraph, null, React.createElement(FormattedMessage, {
        id: "@next.components.molecules.OrderTabel.OrderTabel.1128268068",
        defaultMessage: "products"
      })))), React.createElement(S.OrderStatus, null, React.createElement(S.OrderStatusParragraph, null, translateOrderStatus(order.node.status.toUpperCase(), intl), order.node.status === "SCHEDULED" && order.node.scheduledAt && function () {
        var scheduledDate = new Date(order.node.scheduledAt);
        var dateScheduled = scheduledDate.toLocaleDateString("es-ES", {
          day: "2-digit",
          month: "short"
        });
        var time = scheduledDate.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true
        });
        time = time.replace(/\./g, "").replace(/\s/g, "").replace(/(p|a)/i, " $1");
        return React.createElement("span", {
          style: {
            display: "block",
            fontSize: 12,
            margin: 0,
            marginTop: -6
          }
        }, "(", dateScheduled !== new Date(order.node.created).toLocaleDateString("es-ES", {
          day: "2-digit",
          month: "short"
        }) ? "".concat(dateScheduled, " ") : "", time, ")");
      }())), React.createElement(S.OrderStatusDetail, null, React.createElement(FormattedMessage, {
        id: "@next.components.molecules.OrderTabel.OrderTabel.3796752745",
        defaultMessage: "see detail"
      })))) : React.createElement(S.RowGrather, {
        "data-test": "orderEntry",
        "data-test-id": order.node.number,
        key: "RowGrather".concat(order.node.number),
        onClick: function onClick(evt) {
          var _order$node4;

          evt.stopPropagation();
          localStorage.setItem("order_token", order === null || order === void 0 ? void 0 : (_order$node4 = order.node) === null || _order$node4 === void 0 ? void 0 : _order$node4.token);

          if (inProgressFlag) {
            localStorage.removeItem("flag_history");
            localStorage.removeItem("flag_basket");
            localStorage.setItem("flag_progress", "true");
          } else {
            localStorage.removeItem("flag_progress");
            localStorage.removeItem("flag_basket");
            localStorage.setItem("flag_history", "true");
          }

          navigate(orderRedirect);
        }
      }, React.createElement(S.ImageGrather, null, order.node.trade.logoImage ? React.createElement(S.ImageShopGrather, null, React.createElement(CachedImage, {
        url: maybe(function () {
          return order.node.trade.logoImage.url;
        }),
        alt: maybe(function () {
          return order.node.trade.logoImage.alt ? order.node.trade.logoImage.alt : "";
        }, "")
      }, React.createElement(React.Fragment, null))) : React.createElement(ReactSVG, {
        path: logoBasketImg
      })), React.createElement(S.OrderTradeGrather, null, order.node.trade.name), React.createElement(S.OrderNumberGrather, null, React.createElement(FormattedMessage, {
        id: "@next.components.molecules.OrderTabel.OrderTabel.2768410132",
        defaultMessage: "order"
      }), " ", " #", order.node.number), React.createElement(S.OrderProductsGrather, null, order.node.amountOfProducts, " ", React.createElement(S.OrderProductsParragraphGrather, null, React.createElement(FormattedMessage, {
        id: "@next.components.molecules.OrderTabel.OrderTabel.1128268068",
        defaultMessage: "products"
      }))), React.createElement(S.OrderStatusParragraphGrather, null, translateOrderStatus(order.node.status.toUpperCase(), intl), order.node.status === "SCHEDULED" && order.node.scheduledAt && function () {
        var scheduledDate = new Date(order.node.scheduledAt);
        var dateScheduled = scheduledDate.toLocaleDateString("es-ES", {
          day: "2-digit",
          month: "short"
        });
        var time = scheduledDate.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true
        });
        time = time.replace(/\./g, "").replace(/\s/g, "").replace(/(p|a)/i, " $1");
        return React.createElement("span", {
          style: {
            display: "block",
            fontSize: 12,
            margin: 0,
            marginTop: -6
          }
        }, "(", dateScheduled !== new Date(order.node.created).toLocaleDateString("es-ES", {
          day: "2-digit",
          month: "short"
        }) ? "".concat(dateScheduled, " ") : "", time, ")");
      }()), React.createElement(S.OrderDateGrather, null, dateRender), React.createElement(S.OrderStatusDetailGrather, null, React.createElement(FormattedMessage, {
        id: "@next.components.molecules.OrderTabel.OrderTabel.3796752745",
        defaultMessage: "see detail"
      })));
    });
  }));
};
OrderTabel.displayName = "OrderTabel";