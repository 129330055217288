import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n    margin: 0;\n  "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  width: 28px;\n  height: 30px;\n  padding: 8px;\n  margin: 0 auto;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  grid-area: trash;\n  background: rgb(247, 98, 104);\n  border-radius: 8px;\n\n  div > div {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n  svg {\n    width: 20px;\n    height: 20px;\n  }\n  ", ";\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  grid-area: quantity;\n  min-width: 120px;\n  // margin: 0 15px;\n  div {\n    margin: 0 auto;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-between;\n  padding: 0;\n  margin: 0 15px 0 0;\n  width: 50px;\n\n  > div {\n    display: flex;\n  }\n\n  svg {\n    cursor: pointer;\n    justify-self: center;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: 80%;\n  height: 50px;\n  margin: 0 auto;\n  margin-top: 1rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 10px;\n  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);\n\n  > div {\n    position: static !important;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Loader = styled.div(_templateObject());
export var QuantityButtons = styled.div(_templateObject2());
export var Quantity = styled.div(_templateObject3());
export var Trash = styled.div(_templateObject4(), media.xLargeScreen(_templateObject5()));