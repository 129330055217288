import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  query HomeSaleProductsList($amountByScreen: Int, $search: String) {\n    homeSaleProducts(amountByScreen: $amountByScreen, search: $search) {\n      ...ProductsLightLoad\n      categoryOrderLightLoad\n      categoryOrderTradeLightLoad {\n        orderIndex\n      }\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  query HomeProductsList($amountByScreen: Int, $search: String) {\n    homeProducts(amountByScreen: $amountByScreen, search: $search) {\n      ...ProductsLightLoad\n      categoryBackgroundImageLightLoad {\n        alt\n        url\n      }\n      categoryOrderLightLoad\n      categoryOrderTradeLightLoad {\n        orderIndex\n        name\n        backgroundImage {\n          alt\n          url\n        }\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedQuery } from "../../core/queries";
import { productsLightLoadFragment } from "../../core/fragments";
var homeProductsQuery = gql(_templateObject(), productsLightLoadFragment);
export var TypedHomeProductsList = TypedQuery(homeProductsQuery);
var homeSaleProducts = gql(_templateObject2(), productsLightLoadFragment);
export var TypedHomeSaleProductsList = TypedQuery(homeSaleProducts);