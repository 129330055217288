import "./scss/index.scss";
import React from "react";
import ReactSVG from "react-svg";
import { FormattedMessage } from "react-intl";
import { Button } from "@components/atoms";

var closeWhite = require("../../images/closewhite.svg"); // const greaterThanEqual = require("../../images/greaterThanEqual.svg");


export var AlertObligatoryToppings = function AlertObligatoryToppings(_ref) {
  var hideModal = _ref.hideModal,
      toppingsName = _ref.toppingsName;
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "showObligatoryToppings",
    onClick: hideModal
  }), React.createElement("div", {
    className: "showObligatoryToppings__data"
  }, React.createElement("div", {
    className: "showObligatoryToppings__data__close"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.AlertObligatoryToppings.index.709932324",
    defaultMessage: "Alert"
  })), React.createElement(ReactSVG, {
    path: closeWhite,
    onClick: hideModal
  })), React.createElement("div", {
    className: "showObligatoryToppings__data__content showObligatoryToppings__data__content--first"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.AlertObligatoryToppings.index.7824804",
    defaultMessage: "To select the product you must first define"
  }), ": ")), React.createElement("div", {
    className: "showObligatoryToppings__data__content showObligatoryToppings__data__content--first"
  }), React.createElement("div", {
    className: "showObligatoryToppings__data__content  showObligatoryToppings__data__content--amount showObligatoryToppings__data__content--first showObligatoryToppings__data__content--last"
  }, React.createElement("ul", null, toppingsName.map(function (toppingName, index) {
    return React.createElement("li", {
      key: "".concat(toppingName, "-").concat(index)
    }, toppingName);
  }))), React.createElement("div", {
    className: "showObligatoryToppings__data__footer"
  }, React.createElement(Button, {
    type: "button",
    testingContext: "",
    onClick: hideModal
  }, React.createElement(FormattedMessage, {
    id: "components.AlertObligatoryToppings.index.3577703060",
    defaultMessage: "Ok"
  })))));
};
export default AlertObligatoryToppings;