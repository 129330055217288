import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useCurrentTradeDetails } from "@saleor/sdk";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { updateTradeAttention, useAppDispatch } from "@temp/redux";
import { CurrentTradeDetailsContext } from "./context";
var urlsToSearch = ["category", "sale", "subcategory", "shipping", "review", "payment"];
var loadPage = false;
export var CurrentTradeDetailsProvider = function CurrentTradeDetailsProvider(_ref) {
  var children = _ref.children;
  var location = useLocation();
  var dispatch = useAppDispatch();

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      changeState = _React$useState2[0],
      setChangeState = _React$useState2[1];

  var _React$useState3 = React.useState(new Date().getTime()),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      timeToRequest = _React$useState4[0],
      setTimeToRequest = _React$useState4[1];

  var _React$useState5 = React.useState({
    data: null,
    loading: true,
    changeData: true
  }),
      _React$useState6 = _slicedToArray(_React$useState5, 2),
      datos = _React$useState6[0],
      setDatos = _React$useState6[1];

  var _useCurrentTradeDetai = useCurrentTradeDetails({}, {
    fetchPolicy: "network-only",
    skip: changeState
  }),
      dataInput = _useCurrentTradeDetai.data,
      loadingInput = _useCurrentTradeDetai.loading;

  React.useEffect(function () {
    if (dataInput && !loadingInput) {
      setDatos(function (prevDatos) {
        return {
          changeData: !prevDatos.changeData,
          data: dataInput,
          loading: loadingInput
        };
      });
    }

    if (!loadingInput && dataInput && !location.pathname.includes("delivery")) {
      dispatch(updateTradeAttention({
        currentTrade: dataInput.currentTrade
      }));
    }
  }, [dataInput, loadingInput]);
  React.useEffect(function () {
    var validation = location.pathname.includes(urlsToSearch[0]) || location.pathname.includes(urlsToSearch[1]) || location.pathname.includes(urlsToSearch[2]) || location.pathname.includes(urlsToSearch[3]) || location.pathname.includes(urlsToSearch[4]) || location.pathname.includes(urlsToSearch[5]) || location.pathname === "/";

    if (validation && loadPage && new Date().getTime() - timeToRequest > 1000) {
      setDatos(function (prevDatos) {
        return _objectSpread(_objectSpread({}, prevDatos), {}, {
          loading: true
        });
      });
      setTimeToRequest(new Date().getTime());
      setChangeState(false);
    } else if (loadPage) {
      setChangeState(true);
    }

    loadPage = true;
  }, [location.pathname]);
  return React.createElement(CurrentTradeDetailsContext.Provider, {
    value: datos
  }, children);
};
CurrentTradeDetailsProvider.displayName = "CurrentTradeDetailsProvider";