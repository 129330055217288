import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  cursor: pointer;\n  justify-content: flex-start;\n  align-items: center;\n  padding-right: 1.25rem;\n  input[type=\"checkbox\"] {\n    display: none;\n    position: relative;\n    right: -999em;\n  }\n  div {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 26px;\n    width: 26px;\n\n    span {\n      animation: ", ";\n      animation-duration: 5s;\n      border: 1px solid ", ";\n      width: 20px;\n      height: 20px;\n      display: inline-block;\n    }\n  }\n\n  input {\n    outline: none;\n  }\n\n  input:checked + div {\n    span {\n      position: relative;\n    }\n    span::before {\n      content: \"\";\n      width: 8px;\n      height: 2px;\n      position: absolute;\n      bottom: 5px;\n      left: 1px;\n      background: ", ";\n      transform: rotate(55deg);\n    }\n    span::after {\n      content: \"\";\n      width: 2px;\n      height: 13px;\n      position: absolute;\n      bottom: 2px;\n      right: 5.5px;\n      background: ", ";\n      transform: rotate(40deg);\n    }\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  margin-bottom: 1rem;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  cursor: pointer;\n  position: relative;\n  margin-left: -4px;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  from {\n    transform: scale(1);\n  }\n  10% {\n    transform: scale(2);\n  }\n  20% {\n    transform: scale(1);\n  }\n  30% {\n    transform: scale(2);\n  }\n  40% {\n    transform: scale(1);\n  }\n  50% {\n    transform: scale(2);\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
import { keyframes } from "styled-components";
var scaleBox = keyframes(_templateObject());
export var Checkbox = styled.div(_templateObject2());
export var Label = styled.label(_templateObject3(), function (props) {
  return props.animation ? scaleBox : "";
}, function (props) {
  return props.theme.colors.secondary;
}, function (props) {
  return props.theme.colors.secondary;
}, function (props) {
  return props.theme.colors.secondary;
});