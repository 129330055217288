import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React from "react";
export var useForm = function useForm(initialValues) {
  var _React$useState = React.useState(initialValues),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      valuesForm = _React$useState2[0],
      setValuesForm = _React$useState2[1];

  var handleChangeInput = function handleChangeInput(e) {
    var newValue = "";
    var _e$target = e.target,
        name = _e$target.name,
        value = _e$target.value;

    if (typeof value === "string") {
      newValue = value.toLowerCase();
    } else {
      newValue = value;
    }

    setValuesForm(function (prevValuesForm) {
      return _objectSpread(_objectSpread({}, prevValuesForm), {}, _defineProperty({}, name, newValue));
    });
  };

  return _objectSpread({
    handleChangeInput: handleChangeInput
  }, valuesForm);
};