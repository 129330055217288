import * as React from "react";
import { FormattedMessage } from "react-intl";
import { animated, useSpring } from "react-spring";
import { Button } from "@components/atoms";
import "./scss/index.scss";

var SentButtons = function SentButtons(_ref) {
  var undeliveredClick = _ref.undeliveredClick,
      toComplete = _ref.toComplete;
  var scaleButton = useSpring({
    config: {
      friction: 40,
      mass: 5,
      tension: 350
    },
    from: {
      scale: 1.1
    },
    loop: true,
    to: {
      scale: 1
    }
  });
  return React.createElement("footer", {
    className: "footerDelivery"
  }, React.createElement("div", {
    className: "footerDelivery__left"
  }, React.createElement(Button, {
    type: "button",
    testingContext: "",
    onClick: undeliveredClick
  }, React.createElement(FormattedMessage, {
    id: "components.FooterDelivery.SentButtons.3853742296",
    defaultMessage: "Undelivered"
  }))), React.createElement("div", {
    className: "footerDelivery__right"
  }, React.createElement(animated.button, {
    className: "footerDelivery__p",
    type: "button",
    onClick: toComplete,
    style: scaleButton
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.FooterDelivery.SentButtons.1548055602",
    defaultMessage: "To complete"
  })))));
};

export default SentButtons;