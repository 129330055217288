import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { Formik } from "formik";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import ReactSVG from "react-svg";
import { Radio } from "@components/atoms";
import { Money } from "@components/containers";
import { checkoutMessages } from "@temp/intl";
import { AlertCheckoutErrors } from "@temp/components";
import domi from "../../../../images/domicilio.svg";
import moto from "../../../../images/moto.svg";
import * as S from "./styles";

/**
 * Shipping method selector used in checkout.
 */
var CheckoutShipping = function CheckoutShipping(_ref) {
  var shippingMethods = _ref.shippingMethods,
      selectedShippingMethodId = _ref.selectedShippingMethodId,
      selectShippingMethod = _ref.selectShippingMethod,
      errors = _ref.errors,
      formId = _ref.formId,
      formRef = _ref.formRef;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      showErrors = _React$useState2[0],
      setShowErrors = _React$useState2[1];

  useEffect(function () {
    if (errors && (errors === null || errors === void 0 ? void 0 : errors.length) > 0) {
      setShowErrors(true);
    }

    return function () {
      setShowErrors(false);
    };
  }, [errors]);
  return React.createElement("section", null, React.createElement(S.Title, {
    "data-test": "checkoutPageSubtitle"
  }, React.createElement(FormattedMessage, checkoutMessages.shippingMethod)), (shippingMethods === null || shippingMethods === void 0 ? void 0 : shippingMethods.length) === 0 ? React.createElement(S.Parragraph, null, " ", "Lo sentimos, actualmente el establecimiento no cuenta con domicilios a las direcciones que tienes registradas o a la direcci\xF3n que tienes asignada para el pedido. Para continuar con el pedido debes agregar una nueva direcci\xF3n") : React.createElement(React.Fragment, null, React.createElement(S.Content, null, React.createElement(S.Image, null, React.createElement(ReactSVG, {
    path: moto
  })), React.createElement(S.Message, null, React.createElement(FormattedMessage, checkoutMessages.shippingMessage))), React.createElement(Formik, {
    initialValues: {
      shippingMethod: selectedShippingMethodId
    },
    enableReinitialize: true,
    onSubmit: function onSubmit(values, _ref2) {
      var _shippingMethods$;

      var setSubmitting = _ref2.setSubmitting;

      if (selectShippingMethod && ((_shippingMethods$ = shippingMethods[0]) === null || _shippingMethods$ === void 0 ? void 0 : _shippingMethods$.id)) {
        var _shippingMethods$2;

        selectShippingMethod((_shippingMethods$2 = shippingMethods[0]) === null || _shippingMethods$2 === void 0 ? void 0 : _shippingMethods$2.id);
      }

      setSubmitting(false);
    }
  }, function (_ref3) {
    var handleChange = _ref3.handleChange,
        handleSubmit = _ref3.handleSubmit,
        handleBlur = _ref3.handleBlur,
        values = _ref3.values,
        setFieldValue = _ref3.setFieldValue,
        setFieldTouched = _ref3.setFieldTouched;
    return React.createElement(S.ShippingMethodForm, {
      id: formId,
      ref: formRef,
      onSubmit: handleSubmit
    }, shippingMethods === null || shippingMethods === void 0 ? void 0 : shippingMethods.map(function (element, index) {
      if (element === null || element === void 0 ? void 0 : element.id) {
        var _shippingMethods$3, _shippingMethods$3$pr, _shippingMethods$4, _shippingMethods$4$pr, _shippingMethods$5;

        var id = element.id;
        var checked = !!values.shippingMethod && values.shippingMethod === id;
        return React.createElement(S.Tile, {
          key: id,
          "data-test": "shippingMethodTile",
          "data-test-id": id
        }, React.createElement(Radio, {
          name: "shippingMethod",
          value: id,
          checked: checked,
          customLabel: true,
          onChange: function onChange() {
            return setFieldValue("shippingMethod", id);
          }
        }), React.createElement(S.TileMessage, null, React.createElement(S.Image, null, React.createElement(ReactSVG, {
          path: domi
        })), React.createElement(S.TileTitleWrapper, null, ((_shippingMethods$3 = shippingMethods[0]) === null || _shippingMethods$3 === void 0 ? void 0 : (_shippingMethods$3$pr = _shippingMethods$3.price) === null || _shippingMethods$3$pr === void 0 ? void 0 : _shippingMethods$3$pr.amount) === 0 ? React.createElement(S.TileTitle, null, React.createElement(FormattedMessage, checkoutMessages.shippingLabelFree)) : React.createElement(S.TileTitle, null, React.createElement(FormattedMessage, checkoutMessages.shippingLabelCost)), React.createElement(S.TileTitleBox, null, ((_shippingMethods$4 = shippingMethods[0]) === null || _shippingMethods$4 === void 0 ? void 0 : (_shippingMethods$4$pr = _shippingMethods$4.price) === null || _shippingMethods$4$pr === void 0 ? void 0 : _shippingMethods$4$pr.amount) === 0 ? React.createElement(FormattedMessage, checkoutMessages.shippingFree) : React.createElement(S.TileTitleBoxContent, null, React.createElement(Money, {
          "data-test": "checkoutShippingMethodOptionPrice",
          money: (_shippingMethods$5 = shippingMethods[0]) === null || _shippingMethods$5 === void 0 ? void 0 : _shippingMethods$5.price
        }))))));
      }

      React.createElement(React.Fragment, null);
    }));
  })), showErrors && React.createElement(AlertCheckoutErrors, {
    hideModal: function hideModal() {
      setShowErrors(false);
    },
    errors: errors
  }));
};

export { CheckoutShipping };