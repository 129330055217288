import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  text-align: center;\n  font-size: 1.5rem;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  text-align: center;\n  color: #f76268;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: 160px;\n  height: 65px;\n  padding-top: 10px;\n  padding-bottom: 10px;\n  margin: 0 auto;\n  box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.2);\n  border-radius: 10px;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Time = styled.div(_templateObject());
export var ParragraphTime = styled.p(_templateObject2());
export var DataTime = styled.p(_templateObject3());