import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ReactSVG from "react-svg";
import { AddressSummary, TextField } from "@components/molecules";
import { Checkbox } from "@components/atoms";
import { ObservationsSummary } from "@components/molecules/ObservationsSummary";
import { TotalSummary } from "@components/molecules/TotalSummary";
import { checkoutMessages } from "@temp/intl";
import * as S from "./styles";

var checkoutHome = require("../../../../images/checkout/checkoutHome.svg");

var checkoutCash = require("../../../../images/checkout/checkoutCash.svg");

var checkoutMoney = require("../../../../images/checkout/checkoutMoney.svg");

var dataphoneImg = require("../../../../images/dataphoneImg.svg");

var nequiImg = require("../../../../images/logoNequi.svg");
/**
 * Review order view showed in checkout.
 */


var currencyLocal = "COP";
var conversorValue = currencyLocal === "COP" ? "es-CO" : undefined;
var maximumFractionsDigitsValue = currencyLocal === "COP" ? 0 : 2;
var CheckoutReview = React.memo(function (_ref) {
  var token = _ref.token,
      shippingAddress = _ref.shippingAddress,
      billingAddress = _ref.billingAddress,
      shippingMethodName = _ref.shippingMethodName,
      checkoutObservation = _ref.checkoutObservation,
      setCheckoutObservation = _ref.setCheckoutObservation,
      paymentMethodName = _ref.paymentMethodName,
      email = _ref.email,
      errors = _ref.errors;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      checkCheckoutObservation = _React$useState2[0],
      setcheckCheckoutObservation = _React$useState2[1];

  var intl = useIntl();
  var tokenReplace = React.useMemo(function () {
    return token === null || token === void 0 ? void 0 : token.replace(",", "").replace(".", "").replace("$", "").replace("'", "");
  }, [token]);
  var moneyValue = React.useMemo(function () {
    return Number(tokenReplace).toLocaleString(conversorValue, {
      currency: currencyLocal,
      style: "currency",
      minimumFractionDigits: 0,
      maximumFractionDigits: maximumFractionsDigitsValue
    });
  }, [tokenReplace]);
  var handleChangeCheckCheckoutObservation = React.useCallback(function () {
    setcheckCheckoutObservation(!checkCheckoutObservation);
  }, [setcheckCheckoutObservation]);
  return React.createElement(S.Wrapper, {
    "data-test": "sectionTitle"
  }, React.createElement(Checkbox, {
    "data-test": "checkoutPaymentPromoCodeCheckbox",
    name: "payment-promo-code",
    checked: checkCheckoutObservation,
    onChange: handleChangeCheckCheckoutObservation
  }, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.CheckoutReview.CheckoutReview.3134269262",
    defaultMessage: "Do you want to add any comments?"
  })), checkCheckoutObservation && React.createElement(TextField, {
    name: "checkoutObservations",
    label: intl.formatMessage(_defineProperty({
      defaultMessage: "Observations",
      "id": "@next.components.organisms.CheckoutReview.CheckoutReview.722592990"
    }, "id", "@next.components.organisms.CheckoutReview.CheckoutReview.722592990")),
    value: checkoutObservation,
    onChange: function onChange(e) {
      setCheckoutObservation(e.target.value);
    },
    autoComplete: "observation",
    textAreaInputLabel: true,
    placeholder: intl.formatMessage(_defineProperty({
      defaultMessage: "Ex: The hamburger without onion",
      "id": "@next.components.organisms.CheckoutReview.CheckoutReview.1721244567"
    }, "id", "@next.components.organisms.CheckoutReview.CheckoutReview.1721244567"))
  }), React.createElement(S.Title, {
    "data-test": "checkoutPageSubtitle"
  }, React.createElement(FormattedMessage, checkoutMessages.reviewOrder)), React.createElement(S.Grid, null, React.createElement("section", {
    "data-test": "shippingAddressSection"
  }, React.createElement(S.SubTitle, null, React.createElement(FormattedMessage, checkoutMessages.shippingAddressSummary)), React.createElement(S.Container, null, React.createElement(S.Image, null, React.createElement(ReactSVG, {
    path: checkoutHome
  })), React.createElement(AddressSummary, {
    address: shippingAddress,
    email: email
  })), React.createElement(ObservationsSummary, {
    address: shippingAddress,
    email: email
  })), React.createElement("section", null, React.createElement(S.SubTitle, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.CheckoutReview.CheckoutReview.348821000",
    defaultMessage: "Payment Method"
  })), React.createElement(S.Container, null, paymentMethodName === "dataphone" ? React.createElement(React.Fragment, null, React.createElement(S.ImageDataphone, null, React.createElement(ReactSVG, {
    path: dataphoneImg
  })), React.createElement(S.ParragraphTitle, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.CheckoutReview.CheckoutReview.1475423084",
    defaultMessage: "Card (Dataphone)"
  }), React.createElement(S.Parragraph, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.CheckoutReview.CheckoutReview.1050498566",
    defaultMessage: "The establishment will have its own dataphone"
  })))) : paymentMethodName === "cash" ? React.createElement(React.Fragment, null, React.createElement(S.Image, null, React.createElement(ReactSVG, {
    path: checkoutCash
  })), React.createElement(S.ParragraphTitle, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.CheckoutReview.CheckoutReview.1923088536",
    defaultMessage: "Cash"
  }), token !== "0" ? React.createElement(S.Parragraph, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.CheckoutReview.CheckoutReview.986524671",
    defaultMessage: "With return for"
  }), " ", ": ", React.createElement(S.Token, null, moneyValue)) : React.createElement(S.Parragraph, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.CheckoutReview.CheckoutReview.648924719",
    defaultMessage: "The establishment does not know how much you are going to pay"
  })))) : paymentMethodName === "nequi" && React.createElement(React.Fragment, null, React.createElement(S.ImageDataphone, null, React.createElement(ReactSVG, {
    path: nequiImg
  })), React.createElement(S.ParragraphTitle, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.CheckoutReview.CheckoutReview.2137966381",
    defaultMessage: "Nequi"
  }), React.createElement(S.Parragraph, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.CheckoutReview.CheckoutReview.2442821705",
    defaultMessage: "The establishment will send you the payment instructions to your WhatsApp"
  })))))), React.createElement("section", null, React.createElement(S.SubTitle, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.CheckoutReview.CheckoutReview.878013594",
    defaultMessage: "Total"
  })), React.createElement(S.Container, null, React.createElement(S.Image, null, React.createElement(ReactSVG, {
    path: checkoutMoney
  })), React.createElement(TotalSummary, null)))));
});
export { CheckoutReview };