import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { useSaleProduct } from "./useSaleProduct";
export var useSaleData = function useSaleData(data, items) {
  var _data$saleCategoryPro;

  var ProductsSaleSubCategory = [];
  data === null || data === void 0 ? void 0 : (_data$saleCategoryPro = data.saleCategoryProducts) === null || _data$saleCategoryPro === void 0 ? void 0 : _data$saleCategoryPro.forEach(function (productVariant) {
    if (productVariant === null || productVariant === void 0 ? void 0 : productVariant.categoryIdLightLoad) {
      var sumItems = items === null || items === void 0 ? void 0 : items.filter(function (itemsCart) {
        var _itemsCart$variant;

        return (itemsCart === null || itemsCart === void 0 ? void 0 : (_itemsCart$variant = itemsCart.variant) === null || _itemsCart$variant === void 0 ? void 0 : _itemsCart$variant.id) === productVariant.id;
      }).map(function (item) {
        return item.quantity;
      }).reduce(function (a, b) {
        return a + b;
      }, 0);
      var itemsQuantity = sumItems > 0 ? sumItems : null;

      var _useSaleProduct = useSaleProduct(productVariant),
          discountPercentage = _useSaleProduct.discountPercentage,
          discountValue = _useSaleProduct.discountValue;

      var indexProductsSubCategory = ProductsSaleSubCategory === null || ProductsSaleSubCategory === void 0 ? void 0 : ProductsSaleSubCategory.findIndex(function (productSubCategory) {
        var _productSubCategory$c;

        return (productSubCategory === null || productSubCategory === void 0 ? void 0 : (_productSubCategory$c = productSubCategory.category) === null || _productSubCategory$c === void 0 ? void 0 : _productSubCategory$c.id) === (productVariant === null || productVariant === void 0 ? void 0 : productVariant.categoryIdLightLoad);
      });

      if (indexProductsSubCategory === -1) {
        var _productVariant$categ, _productVariant$categ2;

        ProductsSaleSubCategory === null || ProductsSaleSubCategory === void 0 ? void 0 : ProductsSaleSubCategory.push({
          category: {
            id: productVariant === null || productVariant === void 0 ? void 0 : productVariant.categoryIdLightLoad,
            name: (productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$categ = productVariant.categoryOrderTradeLightLoad[0]) === null || _productVariant$categ === void 0 ? void 0 : _productVariant$categ.name) || (productVariant === null || productVariant === void 0 ? void 0 : productVariant.categoryNameLightLoad),
            orderIndex: (productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$categ2 = productVariant.categoryOrderTradeLightLoad[0]) === null || _productVariant$categ2 === void 0 ? void 0 : _productVariant$categ2.orderIndex) || (productVariant === null || productVariant === void 0 ? void 0 : productVariant.categoryOrderLightLoad) || 1000000000
          },
          products: [{
            category: {
              id: productVariant === null || productVariant === void 0 ? void 0 : productVariant.categoryIdLightLoad,
              name: productVariant === null || productVariant === void 0 ? void 0 : productVariant.categoryNameLightLoad
            },
            subCategory: {
              id: productVariant === null || productVariant === void 0 ? void 0 : productVariant.subCategoryIdLightLoad,
              name: productVariant === null || productVariant === void 0 ? void 0 : productVariant.subCategoryNameLightLoad
            },
            id: productVariant === null || productVariant === void 0 ? void 0 : productVariant.id,
            name: (productVariant === null || productVariant === void 0 ? void 0 : productVariant.name) || (productVariant === null || productVariant === void 0 ? void 0 : productVariant.productNameLightLoad),
            price: productVariant === null || productVariant === void 0 ? void 0 : productVariant.priceLightLoad,
            discountPercentage: discountPercentage,
            discountValue: discountValue,
            itemsQuantity: itemsQuantity,
            thumbnail: productVariant === null || productVariant === void 0 ? void 0 : productVariant.thumbnailLightLoad,
            __typename: "Product"
          }]
        });
      } else {
        var _ProductsSaleSubCateg;

        var tempProducts = (_ProductsSaleSubCateg = ProductsSaleSubCategory[indexProductsSubCategory]) === null || _ProductsSaleSubCateg === void 0 ? void 0 : _ProductsSaleSubCateg.products;
        ProductsSaleSubCategory[indexProductsSubCategory].products = [].concat(_toConsumableArray(tempProducts), [{
          category: {
            id: productVariant === null || productVariant === void 0 ? void 0 : productVariant.categoryIdLightLoad,
            name: productVariant === null || productVariant === void 0 ? void 0 : productVariant.categoryNameLightLoad
          },
          subCategory: {
            id: productVariant === null || productVariant === void 0 ? void 0 : productVariant.subCategoryIdLightLoad,
            name: productVariant === null || productVariant === void 0 ? void 0 : productVariant.subCategoryNameLightLoad
          },
          id: productVariant === null || productVariant === void 0 ? void 0 : productVariant.id,
          name: (productVariant === null || productVariant === void 0 ? void 0 : productVariant.name) || (productVariant === null || productVariant === void 0 ? void 0 : productVariant.productNameLightLoad),
          price: productVariant === null || productVariant === void 0 ? void 0 : productVariant.priceLightLoad,
          discountPercentage: discountPercentage,
          discountValue: discountValue,
          itemsQuantity: itemsQuantity,
          thumbnail: productVariant === null || productVariant === void 0 ? void 0 : productVariant.thumbnailLightLoad,
          __typename: "Product"
        }]);
      }
    }
  });
  var sendProducts = {
    products: ProductsSaleSubCategory.sort(function (a, b) {
      var _a$category, _b$category;

      return (a === null || a === void 0 ? void 0 : (_a$category = a.category) === null || _a$category === void 0 ? void 0 : _a$category.orderIndex) - (b === null || b === void 0 ? void 0 : (_b$category = b.category) === null || _b$category === void 0 ? void 0 : _b$category.orderIndex);
    })
  };
  var categoryAmount = ProductsSaleSubCategory.length;
  return {
    sendProducts: sendProducts,
    categoryAmount: categoryAmount,
    ProductsSaleSubCategory: ProductsSaleSubCategory
  };
};