import "./scss/index.scss";
import React from "react";
import ReactSVG from "react-svg";
import { FormattedMessage } from "react-intl";
import { Button, Loader } from "@components/atoms";
import * as S from "./styles";

var closeWhite = require("../../images/closewhite.svg"); // const greaterThanEqual = require("../../images/greaterThanEqual.svg");


export var AlertDeleteCheckout = function AlertDeleteCheckout(_ref) {
  var removeCheckout = _ref.removeCheckout,
      hideModal = _ref.hideModal,
      loading = _ref.loading;

  var closeModal = function closeModal() {
    if (loading) {
      hideModal();
    }
  };

  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "showContainer",
    onClick: closeModal
  }), React.createElement("div", {
    className: "showContainer__data"
  }, React.createElement("div", {
    className: "showContainer__data__close"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.AlertDeleteCheckout.index.1258888549",
    defaultMessage: "Confirmation"
  })), React.createElement(ReactSVG, {
    path: closeWhite,
    onClick: hideModal
  })), React.createElement("div", {
    className: "showContainer__data__content  showContainer__data__content--amount showContainer__data__content--first showContainer__data__content--last"
  }, React.createElement(FormattedMessage, {
    id: "components.AlertDeleteCheckout.index.2683454070",
    defaultMessage: "Do you want to empty the bag?"
  })), React.createElement("div", {
    className: "showContainer__data__footer"
  }, !loading ? React.createElement(S.Loader, null, React.createElement(Loader, null)) : React.createElement(React.Fragment, null, React.createElement(Button, {
    type: "button",
    testingContext: "",
    onClick: closeModal
  }, React.createElement(FormattedMessage, {
    id: "components.AlertDeleteCheckout.index.2680335018",
    defaultMessage: "No"
  })), React.createElement(Button, {
    type: "button",
    testingContext: "",
    onClick: function onClick() {
      removeCheckout();
      hideModal();
    }
  }, React.createElement(FormattedMessage, {
    id: "components.AlertDeleteCheckout.index.3308036799",
    defaultMessage: "Yes"
  }))))));
};
export default AlertDeleteCheckout;