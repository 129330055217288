import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React from "react";
import { NavLink, ShadowBox } from "@components/atoms";
import { useHandlerWhenClickedOutside } from "@hooks";
import * as S from "./styles";

var Column = function Column(_ref) {
  var item = _ref.item;
  return React.createElement(S.RowItem, null, React.createElement(S.SubLink, {
    item: item
  }), item.children.length > 0 && item.children.map(function (subitem) {
    return React.createElement(S.SubLink, {
      item: subitem,
      light: true
    });
  }));
};

export var Dropdown = function Dropdown(_ref2) {
  var onHide = _ref2.onHide,
      item = _ref2.item;

  var _useHandlerWhenClicke = useHandlerWhenClickedOutside(onHide),
      setElementRef = _useHandlerWhenClicke.setElementRef;

  return React.createElement(S.Wrapper, {
    ref: setElementRef,
    onMouseLeave: onHide,
    onClick: onHide
  }, React.createElement(S.Rows, null, item.children.map(function (item) {
    return React.createElement(Column, {
      item: item
    });
  })), React.createElement(S.Side, null, React.createElement(S.RowItem, null, React.createElement(NavLink, {
    item: _objectSpread(_objectSpread({}, item), {}, {
      name: "view all products"
    })
  }))), React.createElement(ShadowBox, null));
};