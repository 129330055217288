import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  query CategoryProductsList(\n    $amountByScreen: Int\n    $categoryId: Int\n    $search: String\n  ) {\n    categoryProducts(\n      amountByScreen: $amountByScreen\n      categoryId: $categoryId\n      search: $search\n    ) {\n      ...ProductsLightLoad\n      subCategoryOrderLightLoad\n      subCategoryOrderTradeLightLoad {\n        orderIndex\n        name\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedQuery } from "../../core/queries";
import { productsLightLoadFragment } from "../../core/fragments";
var categoryProducts = gql(_templateObject(), productsLightLoadFragment);
export var TypedCategoryProductsList = TypedQuery(categoryProducts);