import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "./scss/index.scss";
import React, { useState } from "react";
import ReactSVG from "react-svg";
import { FormattedMessage } from "react-intl";
import Button from "../Button";
import Loader from "../Loader";

var closeWhite = require("../../images/closewhite.svg");

var discountImg = require("../../images/descuento.svg");

export var AlertVouchersAvailableToUser = function AlertVouchersAvailableToUser(_ref) {
  var hideModal = _ref.hideModal,
      homeEntry = _ref.homeEntry,
      setInputPromoValue = _ref.setInputPromoValue,
      setShowPromoCodeForm = _ref.setShowPromoCodeForm,
      vouchersAvailableToUser = _ref.vouchersAvailableToUser,
      applyPromoButtonRef = _ref.applyPromoButtonRef;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      loading = _useState2[0],
      setLoading = _useState2[1];

  var handleClick = function handleClick(voucherCode) {
    if (setShowPromoCodeForm) {
      setShowPromoCodeForm(true);
    }

    if (setInputPromoValue) {
      setInputPromoValue(voucherCode.toLowerCase());
    }

    setLoading(true);

    if (applyPromoButtonRef === null || applyPromoButtonRef === void 0 ? void 0 : applyPromoButtonRef.current) {
      setTimeout(function () {
        var _applyPromoButtonRef$;

        (_applyPromoButtonRef$ = applyPromoButtonRef.current) === null || _applyPromoButtonRef$ === void 0 ? void 0 : _applyPromoButtonRef$.click();
        setLoading(false);
        hideModal();
      }, 300);
    } else {
      setLoading(false);
      hideModal();
    }
  };

  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "showVouchersAvailableToUser"
  }), React.createElement("div", {
    className: "showVouchersAvailableToUser__data"
  }, React.createElement("div", {
    className: "showVouchersAvailableToUser__data__close"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.AlertVouchersAvailableToUser.index.4214410856",
    defaultMessage: "discount codes"
  })), React.createElement(ReactSVG, {
    path: closeWhite,
    onClick: hideModal
  })), React.createElement("div", {
    className: "showVouchersAvailableToUser__data__content showVouchersAvailableToUser__data__content--first"
  }), React.createElement("div", {
    className: "showVouchersAvailableToUser__data__content showVouchersAvailableToUser__data__content--value showVouchersAvailableToUser__data__content--first showVouchersAvailableToUser__data__content__selected"
  }, homeEntry ? React.createElement(FormattedMessage, {
    id: "components.AlertVouchersAvailableToUser.index.440104626",
    defaultMessage: "You have the following discount codes available, remember that you can apply your discount when confirming your purchase",
    description: "Opening alert"
  }) : React.createElement(React.Fragment, null, React.createElement(FormattedMessage, {
    id: "components.AlertVouchersAvailableToUser.index.2793418183",
    defaultMessage: "Do you want to apply a discount code?",
    description: "Opening alert"
  }), React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.AlertVouchersAvailableToUser.index.2109253803",
    defaultMessage: "Select your discount",
    description: "Opening alert"
  }), ":"))), React.createElement("div", {
    className: "showVouchersAvailableToUser__data__content showVouchersAvailableToUser__data__content--value showVouchersAvailableToUser__data__content--first showVouchersAvailableToUser__data__content--last"
  }, vouchersAvailableToUser === null || vouchersAvailableToUser === void 0 ? void 0 : vouchersAvailableToUser.map(function (voucher) {
    var _voucher$discountValu, _voucher$discountValu2, _voucher$description;

    return React.createElement("div", {
      key: voucher === null || voucher === void 0 ? void 0 : voucher.code,
      className: "showVouchersAvailableToUser__data__content__list\n                  ".concat(homeEntry ? "showVouchersAvailableToUser__data__content__list--homeEntry" : "", "\n                  "),
      onClick: function onClick() {
        if (!homeEntry) {
          handleClick(voucher === null || voucher === void 0 ? void 0 : voucher.code);
        }
      }
    }, React.createElement("div", null, React.createElement(ReactSVG, {
      path: discountImg
    }), React.createElement("p", null, voucher === null || voucher === void 0 ? void 0 : voucher.code.toLowerCase()), (voucher === null || voucher === void 0 ? void 0 : (_voucher$discountValu = voucher.discountValueType) === null || _voucher$discountValu === void 0 ? void 0 : _voucher$discountValu.toUpperCase()) === "PERCENTAGE" ? React.createElement("p", null, "- ".concat(Math.floor(+(voucher === null || voucher === void 0 ? void 0 : voucher.discountValue)), " %")) : (voucher === null || voucher === void 0 ? void 0 : (_voucher$discountValu2 = voucher.discountValueType) === null || _voucher$discountValu2 === void 0 ? void 0 : _voucher$discountValu2.toUpperCase()) === "FIXED" && React.createElement("p", null, "- ".concat(parseInt(voucher === null || voucher === void 0 ? void 0 : voucher.discountValue, 10)))), (voucher === null || voucher === void 0 ? void 0 : voucher.description) && (voucher === null || voucher === void 0 ? void 0 : (_voucher$description = voucher.description) === null || _voucher$description === void 0 ? void 0 : _voucher$description.trim()) !== "" && React.createElement("p", {
      className: "showVouchersAvailableToUser__data__content__list__description"
    }, voucher === null || voucher === void 0 ? void 0 : voucher.description), !homeEntry && React.createElement("div", {
      className: "applyDiscountButton"
    }, React.createElement(Button, {
      type: "button",
      testingContext: ""
    }, React.createElement(FormattedMessage, {
      id: "components.AlertVouchersAvailableToUser.index.3476123",
      defaultMessage: "Apply"
    }))));
  })), homeEntry && React.createElement("div", {
    className: "showVouchersAvailableToUser__data__footer"
  }, React.createElement(Button, {
    type: "button",
    testingContext: "",
    onClick: hideModal
  }, React.createElement(FormattedMessage, {
    id: "components.AlertVouchersAvailableToUser.index.3577703060",
    defaultMessage: "Ok"
  })))), loading && React.createElement("div", {
    className: "showVouchersAvailableToUser__loader"
  }, React.createElement(Loader, null)));
};
export default AlertVouchersAvailableToUser;