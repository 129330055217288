import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useHomeSaleProducts } from "@saleor/sdk";
import * as React from "react";
import { useAppSelector } from "@temp/redux";
import { HomeSaleProductsContext } from "./context";
import { ChangeAmountProductsContext } from "../ChangeAmountProducts/context";
export var HomeSaleProductsProvider = function HomeSaleProductsProvider(_ref) {
  var children = _ref.children;

  var _React$useContext = React.useContext(ChangeAmountProductsContext),
      maxProductsPerCategory = _React$useContext.maxProductsPerCategory;

  var _React$useState = React.useState({
    dataSale: null,
    loadingSale: true
  }),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      datos = _React$useState2[0],
      setDatos = _React$useState2[1];

  var _useAppSelector = useAppSelector(function (state) {
    return state;
  }),
      searchQueryValue = _useAppSelector.search.searchQueryValue;

  var fetchPolicyValue = localStorage.getItem("home_cache") === "true" ? "cache-first" : "cache-and-network";
  var datosQuery = useHomeSaleProducts({
    amountByScreen: maxProductsPerCategory + 1,
    search: searchQueryValue
  }, {
    fetchPolicy: "".concat(fetchPolicyValue)
  });
  React.useEffect(function () {
    setDatos({
      dataSale: datosQuery.data,
      loadingSale: datosQuery.loading
    });
  }, [datosQuery.data, datosQuery.loading]);
  return React.createElement(HomeSaleProductsContext.Provider, {
    value: datos
  }, children);
};
HomeSaleProductsProvider.displayName = "HomeSaleProductsProvider";