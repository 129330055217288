import React from "react";
import ReactSVG from "react-svg"; // import amexImg from "images/amex.svg";
// import discoverImg from "images/discover.svg";
// import jcbImg from "images/jcb.svg";
// import maestroImg from "images/maestro.svg";
// import mastercardImg from "images/mastercard.svg";
// import visaImg from "images/visa.svg";

import * as S from "./styles";
var providers = new Map(); // providers.set("visa", visaImg);
// providers.set("maestro", maestroImg);
// providers.set("mastercard", mastercardImg);
// providers.set("amex", amexImg);
// providers.set("jcb", jcbImg);
// providers.set("discover", discoverImg);

export var CreditCardIcon = function CreditCardIcon(_ref) {
  var creditCardProvider = _ref.creditCardProvider;
  return React.createElement(S.CreditCardIcon, null, providers.has(creditCardProvider) && React.createElement(ReactSVG, {
    path: providers.get(creditCardProvider)
  }));
};