import "./scss/index.scss";
import React from "react";
import ReactSVG from "react-svg";
import { FormattedMessage, useIntl } from "react-intl"; // import { Button } from "@components/atoms";

import { Button, Loader } from "@components/atoms";
import { alertMessages, commonMessages } from "@temp/intl";

var closeWhite = require("../../images/closewhite.svg");

export var AlertNeighborhoodOtherTrade = function AlertNeighborhoodOtherTrade(_ref) {
  var loading = _ref.loading,
      hideModal = _ref.hideModal,
      realNeighborhoodName = _ref.realNeighborhoodName,
      handleChangeCurrentTrade = _ref.handleChangeCurrentTrade,
      setSubmit = _ref.setSubmit;
  var intl = useIntl();
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "alertNeighborhoodOtherTrade",
    onClick: hideModal
  }), React.createElement("div", {
    className: "alertNeighborhoodOtherTrade__data"
  }, React.createElement("div", {
    className: "alertNeighborhoodOtherTrade__data__close"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.AlertNeighborhoodOtherTrade.index.709932324",
    defaultMessage: "Alert"
  })), React.createElement(ReactSVG, {
    path: closeWhite,
    onClick: hideModal
  })), React.createElement("div", {
    className: "alertNeighborhoodOtherTrade__data__content alertNeighborhoodOtherTrade__data__content--first"
  }), React.createElement("div", {
    className: "alertNeighborhoodOtherTrade__data__content alertNeighborhoodOtherTrade__data__content--value alertNeighborhoodOtherTrade__data__content--first"
  }), React.createElement("div", {
    className: "alertNeighborhoodOtherTrade__data__content alertNeighborhoodOtherTrade__data__content--value alertNeighborhoodOtherTrade__data__content--first alertNeighborhoodOtherTrade__data__content--last"
  }, React.createElement("p", null, intl.formatMessage(alertMessages.neighborhoodIsServedByOtherTrade, {
    newNeighborhood: React.createElement("span", null, realNeighborhoodName)
  }))), React.createElement("div", {
    className: "alertNeighborhoodOtherTrade__data__footer"
  }, React.createElement(Button, {
    type: "button",
    testingContext: "",
    onClick: hideModal
  }, React.createElement(FormattedMessage, {
    id: "components.AlertNeighborhoodOtherTrade.index.1347475195",
    defaultMessage: "Back"
  })), React.createElement(Button, {
    disabled: loading,
    type: "button",
    testingContext: "",
    onClick: function onClick() {
      handleChangeCurrentTrade();
      setSubmit(true);
    }
  }, intl.formatMessage(commonMessages.change)))), loading && React.createElement("div", {
    className: "alertNeighborhoodOtherTrade__loading"
  }, React.createElement(Loader, null)));
};
export default AlertNeighborhoodOtherTrade;