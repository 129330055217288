import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import "./scss/index.scss";
import { InputSelect, TextField } from "@components/molecules";
import React from "react";
import { FormattedMessage } from "react-intl";
import ReactSVG from "react-svg";
import { commonMessages } from "@temp/intl";
import Button from "../Button";
import Loader from "../Loader";
import closeThinImg from "../../images/search/closeGrey.svg";

var closeWhite = require("../../images/closewhite.svg");

export var AlertFilters = function AlertFilters(_ref) {
  var _values$subCategory;

  var hideModal = _ref.hideModal,
      searchCategory = _ref.searchCategory,
      categoriesData = _ref.categoriesData,
      values = _ref.values,
      setValues = _ref.setValues,
      handleSubCategoryValues = _ref.handleSubCategoryValues,
      initialStateCategory = _ref.initialStateCategory,
      intl = _ref.intl,
      searchSubcategory = _ref.searchSubcategory,
      subCategoryOptions = _ref.subCategoryOptions,
      handleCleanFilters = _ref.handleCleanFilters,
      handleCleanSubCategoryFilters = _ref.handleCleanSubCategoryFilters,
      submit = _ref.submit;
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "showCategoriesSubcategories"
  }), React.createElement("div", {
    className: "showCategoriesSubcategories__data"
  }, React.createElement("div", {
    className: "showCategoriesSubcategories__data__close"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.Search.AlertFilters.996289613",
    defaultMessage: "Filters"
  })), React.createElement(ReactSVG, {
    path: closeWhite,
    onClick: hideModal
  })), React.createElement("div", {
    className: "showCategoriesSubcategories__data__content showCategoriesSubcategories__data__content--first"
  }), React.createElement("div", {
    id: "showCategoriesModal",
    className: "showCategoriesSubcategories__data__content showCategoriesSubcategories__data__content--first"
  }, (values === null || values === void 0 ? void 0 : values.category.name.toUpperCase()) !== intl.formatMessage(commonMessages.all).toUpperCase() && React.createElement(React.Fragment, null, React.createElement("div", {
    className: "closeImage"
  }, React.createElement(ReactSVG, {
    path: closeThinImg,
    onClick: handleCleanFilters
  })), React.createElement("div", {
    className: "closeImageInput"
  })), React.createElement(InputSelect, {
    defaultValue: searchCategory,
    label: intl.formatMessage(commonMessages.category),
    name: "category",
    options: categoriesData,
    value: values === null || values === void 0 ? void 0 : values.category,
    onChange: function onChange(value, name) {
      setValues(function (prevValues) {
        return _objectSpread(_objectSpread({}, prevValues), {}, _defineProperty({}, name, value));
      });
      handleSubCategoryValues(initialStateCategory, initialStateCategory, _objectSpread(_objectSpread({}, values), {}, _defineProperty({}, name, value)));
    },
    optionLabelKey: "name",
    optionValueKey: "value",
    autoComplete: "given-name"
  }), !location.pathname.includes("sale") && (values.category.name.toUpperCase() === intl.formatMessage(commonMessages.all).toUpperCase() ? React.createElement(TextField, {
    name: "subCategory",
    label: intl.formatMessage(commonMessages.subCategory),
    value: values === null || values === void 0 ? void 0 : (_values$subCategory = values.subCategory) === null || _values$subCategory === void 0 ? void 0 : _values$subCategory.name,
    disabled: true
  }) : subCategoryOptions.length === 0 ? React.createElement("div", {
    className: "showCategoriesSubcategories__data__content__loading"
  }, React.createElement(Loader, null)) : React.createElement(React.Fragment, null, (values === null || values === void 0 ? void 0 : values.subCategory.name.toUpperCase()) !== intl.formatMessage(commonMessages.all).toUpperCase() && handleCleanSubCategoryFilters && React.createElement(React.Fragment, null, React.createElement("div", {
    className: "closeImageSubCategory"
  }, React.createElement(ReactSVG, {
    path: closeThinImg,
    onClick: handleCleanSubCategoryFilters
  })), React.createElement("div", {
    className: "closeImageInput"
  })), React.createElement(InputSelect, {
    defaultValue: searchSubcategory,
    label: intl.formatMessage(commonMessages.subCategory),
    name: "subCategory",
    options: subCategoryOptions,
    value: values.subCategory,
    onChange: function onChange(value, name) {
      return setValues(function (valuesPrev) {
        return _objectSpread(_objectSpread({}, valuesPrev), {}, _defineProperty({}, name, value));
      });
    },
    optionLabelKey: "name",
    optionValueKey: "value",
    autoComplete: "given-name"
  })))), React.createElement("div", {
    className: "showCategoriesSubcategories__data__footer"
  }, React.createElement(Button, {
    type: "button",
    testingContext: "",
    onClick: function onClick() {
      return submit(values);
    }
  }, intl.formatMessage(commonMessages.apply)))));
};