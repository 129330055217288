import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject44() {
  var data = _taggedTemplateLiteral(["\n  background: linear-gradient(90deg, #e8e8e8 0px, #f8f8f8 40px, #e8e8e8 80px);\n  background-size: 350px;\n  display: block;\n  width: 90%;\n  margin: 0 auto;\n  height: 1.45rem;\n  border-radius: 3px;\n  animation: animationSkeleton 2s infinite;\n"]);

  _templateObject44 = function _templateObject44() {
    return data;
  };

  return data;
}

function _templateObject43() {
  var data = _taggedTemplateLiteral(["\n  background: linear-gradient(90deg, #e8e8e8 0px, #f8f8f8 40px, #e8e8e8 80px);\n  background-size: 350px;\n  display: block;\n  width: 100%;\n  height: 1.45rem;\n  margin-top: 1.3rem;\n  border-radius: 3px;\n  animation: animationSkeleton 2s infinite;\n"]);

  _templateObject43 = function _templateObject43() {
    return data;
  };

  return data;
}

function _templateObject42() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  display: block;\n  margin-left: auto;\n  margin-right: auto;\n  text-align: center;\n  font-weight: bold;\n  color: white;\n  text-transform: uppercase;\n"]);

  _templateObject42 = function _templateObject42() {
    return data;
  };

  return data;
}

function _templateObject41() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  max-width: 1000px;\n  padding-top: 10px;\n  padding-bottom: 10px;\n  grid-area: detail;\n  border-radius: 8px;\n  color: white;\n  text-transform: uppercase;\n"]);

  _templateObject41 = function _templateObject41() {
    return data;
  };

  return data;
}

function _templateObject40() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  margin-top: 20px !important;\n  margin-bottom: 20px !important;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 8px;\n"]);

  _templateObject40 = function _templateObject40() {
    return data;
  };

  return data;
}

function _templateObject39() {
  var data = _taggedTemplateLiteral(["\n  width: 70%;\n  padding: 10px 0px;\n  margin: 0 auto;\n  align-self: center;\n  color: white;\n  border-radius: 14px;\n  text-transform: lowercase;\n  text-align: center;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n"]);

  _templateObject39 = function _templateObject39() {
    return data;
  };

  return data;
}

function _templateObject38() {
  var data = _taggedTemplateLiteral(["\n  align-self: center;\n  width: 100%;\n  color: #2699fb;\n  overflow-wrap: break-word;\n  text-align: center;\n  font-size: 0.8rem;\n"]);

  _templateObject38 = function _templateObject38() {
    return data;
  };

  return data;
}

function _templateObject37() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n  text-transform: lowercase;\n  text-align: center;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n"]);

  _templateObject37 = function _templateObject37() {
    return data;
  };

  return data;
}

function _templateObject36() {
  var data = _taggedTemplateLiteral(["\n  align-self: center;\n  text-align: center;\n  color: #afafaf;\n"]);

  _templateObject36 = function _templateObject36() {
    return data;
  };

  return data;
}

function _templateObject35() {
  var data = _taggedTemplateLiteral(["\n  align-self: center;\n  text-align: center;\n  color: #f76268;\n"]);

  _templateObject35 = function _templateObject35() {
    return data;
  };

  return data;
}

function _templateObject34() {
  var data = _taggedTemplateLiteral(["\n  align-self: center;\n  color: #ed1c24;\n  text-align: center;\n  text-transform: lowercase;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n"]);

  _templateObject34 = function _templateObject34() {
    return data;
  };

  return data;
}

function _templateObject33() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  align-self: center;\n  display: flex;\n  flex-direction: column;\n  text-align: center;\n"]);

  _templateObject33 = function _templateObject33() {
    return data;
  };

  return data;
}

function _templateObject32() {
  var data = _taggedTemplateLiteral(["\n  height: 5rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  div {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  img {\n    position: relative;\n    top: 8px;\n    width: 4rem;\n    height: 60px;\n    padding: 5px;\n    margin: 0 auto;\n    margin-bottom: 15px;\n    border-radius: 50%;\n    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.3);\n    background-color: white;\n    object-fit: contain;\n  }\n"]);

  _templateObject32 = function _templateObject32() {
    return data;
  };

  return data;
}

function _templateObject31() {
  var data = _taggedTemplateLiteral(["\n  height: 5rem;\n  align-self: center;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  div {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  svg {\n    position: relative;\n    top: 8px;\n    margin: 0 auto;\n    margin-bottom: 15px;\n    background-color: white;\n    transform: scale(1);\n    path {\n      fill: #f76268;\n    }\n    @media (min-width: 500px) {\n      transform: scale(1.5);\n    }\n  }\n"]);

  _templateObject31 = function _templateObject31() {
    return data;
  };

  return data;
}

function _templateObject30() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  padding: 10px;\n  margin-left: auto;\n  margin-top: 15px;\n  display: grid;\n  grid-template-columns: 0.5fr 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr;\n  background-color: white;\n  cursor: pointer;\n  border-radius: 10px;\n  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);\n"]);

  _templateObject30 = function _templateObject30() {
    return data;
  };

  return data;
}

function _templateObject29() {
  var data = _taggedTemplateLiteral(["\n    padding-top: 10px;\n  "]);

  _templateObject29 = function _templateObject29() {
    return data;
  };

  return data;
}

function _templateObject28() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  grid-area: detail;\n  position: relative;\n  color: black;\n  background-color: white;\n  border-top: 1px solid #e0e0e0;\n  text-align: center;\n  text-transform: lowercase;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n  ", "\n"]);

  _templateObject28 = function _templateObject28() {
    return data;
  };

  return data;
}

function _templateObject27() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n  color: #afafaf;\n  text-transform: lowercase;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n"]);

  _templateObject27 = function _templateObject27() {
    return data;
  };

  return data;
}

function _templateObject26() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n  color: black;\n  text-transform: lowercase;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n"]);

  _templateObject26 = function _templateObject26() {
    return data;
  };

  return data;
}

function _templateObject25() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  text-transform: lowercase;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n"]);

  _templateObject25 = function _templateObject25() {
    return data;
  };

  return data;
}

function _templateObject24() {
  var data = _taggedTemplateLiteral(["\n  border-top: 1px solid #e0e0e0;\n  padding: 10px;\n  grid-area: customer-data;\n"]);

  _templateObject24 = function _templateObject24() {
    return data;
  };

  return data;
}

function _templateObject23() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  color: #2699fb;\n  overflow-wrap: break-word;\n  font-size: 0.8rem;\n  text-align: right;\n"]);

  _templateObject23 = function _templateObject23() {
    return data;
  };

  return data;
}

function _templateObject22() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  grid-area: status;\n  padding-left: 15px;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n"]);

  _templateObject22 = function _templateObject22() {
    return data;
  };

  return data;
}

function _templateObject21() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n  text-transform: lowercase;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n"]);

  _templateObject21 = function _templateObject21() {
    return data;
  };

  return data;
}

function _templateObject20() {
  var data = _taggedTemplateLiteral(["\n  color: #afafaf;\n"]);

  _templateObject20 = function _templateObject20() {
    return data;
  };

  return data;
}

function _templateObject19() {
  var data = _taggedTemplateLiteral(["\n  color: #f76268;\n"]);

  _templateObject19 = function _templateObject19() {
    return data;
  };

  return data;
}

function _templateObject18() {
  var data = _taggedTemplateLiteral(["\n  color: #afafaf;\n"]);

  _templateObject18 = function _templateObject18() {
    return data;
  };

  return data;
}

function _templateObject17() {
  var data = _taggedTemplateLiteral(["\n  color: black;\n"]);

  _templateObject17 = function _templateObject17() {
    return data;
  };

  return data;
}

function _templateObject16() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n"]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = _taggedTemplateLiteral(["\n  grid-area: number;\n  color: #ed1c24;\n  text-transform: lowercase;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n"]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  grid-template-areas: \"number status\";\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  padding: 10px;\n  grid-area: global-date;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  grid-area: data;\n  display: flex;\n  padding-left: 20px;\n  padding-bottom: 10px;\n  flex-direction: column;\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  height: 5rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  div {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  img {\n    position: relative;\n    top: 8px;\n    width: 4rem;\n    height: 60px;\n    padding: 5px;\n    margin: 0 auto;\n    margin-bottom: 15px;\n    border-radius: 50%;\n    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.3);\n    background-color: white;\n    object-fit: contain;\n  }\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  height: 5rem;\n  grid-area: logo;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  div {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  svg {\n    position: relative;\n    top: 8px;\n    margin: 0 auto;\n    margin-bottom: 15px;\n    background-color: white;\n    transform: scale(1);\n    path {\n      fill: #f76268;\n    }\n    @media (min-width: 500px) {\n      transform: scale(1.5);\n    }\n  }\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  padding: 10px;\n  margin: 0 auto;\n  margin-top: 15px;\n  display: grid;\n  grid-template-areas:\n    \"logo data data data\"\n    \"global-date global-date global-date global-date\"\n    \"customer-data customer-data customer-data customer-data\"\n    \"detail detail detail detail\";\n  grid-template-columns: 0.5fr 1fr 1fr 1.7fr;\n  background-color: white;\n  border-radius: 10px;\n  cursor: pointer;\n  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n     width: 50%;\n  "]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  width: 25%;\n  ", "\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  width: 10%;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  width: 25%;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  width: 25%;\n  display: flex;\n  flex-wrap: nowrap;\n  justify-content: center;\n\n  img {\n    max-width: 50px;\n    height: auto;\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n     width: 50%;\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  width: 15%;\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject()); // export const Row = styled.div`
//   display: flex;
//   width: 100%;
//   flex-direction: row;
//   text-align: center;
//   justify-content: center;
//   align-items: center;
//   height: 5rem;
//   cursor: pointer;
//   border-bottom: 1px solid ${props => props.theme.colors.tableDivider};
// `;
// export const HeaderRow = styled(Row)`
//   color: ${props => props.theme.colors.lightFont};
//   cursor: default;
// `;

export var IndexNumber = styled.div(_templateObject2(), media.smallScreen(_templateObject3()));
export var ProductsOrdered = styled.div(_templateObject4());
export var DateOfOrder = styled.div(_templateObject5());
export var Value = styled.div(_templateObject6());
export var Status = styled.div(_templateObject7(), media.smallScreen(_templateObject8()));
/* New styles */

export var Row = styled.div(_templateObject9());
export var Image = styled.div(_templateObject10());
export var ImageShop = styled.div(_templateObject11());
export var OrderData = styled.div(_templateObject12());
export var OrderGlobalDate = styled.div(_templateObject13());
export var OrderFirstLine = styled.div(_templateObject14());
export var OrderNumber = styled.p(_templateObject15());
export var OrderDate = styled.p(_templateObject16());
export var OrderDateSpanLabel = styled.span(_templateObject17());
export var OrderDateSpan = styled.span(_templateObject18());
export var OrderTrade = styled.p(_templateObject19());
export var OrderProducts = styled.div(_templateObject20());
export var OrderProductsParragraph = styled.p(_templateObject21());
export var OrderStatus = styled.div(_templateObject22());
export var OrderStatusParragraph = styled.p(_templateObject23());
export var OrderCustomerData = styled.div(_templateObject24());
export var OrderCustomerDataParragraph = styled.p(_templateObject25());
export var OrderCustomerDataSpanFirst = styled.span(_templateObject26());
export var OrderCustomerDataSpan = styled.span(_templateObject27());
export var OrderStatusDetail = styled.p(_templateObject28(), media.xLargeScreen(_templateObject29()));
export var RowGrather = styled.div(_templateObject30());
export var ImageGrather = styled.div(_templateObject31());
export var ImageShopGrather = styled.div(_templateObject32());
export var OrderDateGrather = styled.div(_templateObject33());
export var OrderNumberGrather = styled.p(_templateObject34());
export var OrderTradeGrather = styled.p(_templateObject35());
export var OrderProductsGrather = styled.div(_templateObject36());
export var OrderProductsParragraphGrather = styled.p(_templateObject37());
export var OrderStatusParragraphGrather = styled.p(_templateObject38());
export var OrderStatusDetailGrather = styled.p(_templateObject39());
export var Complete = styled.p(_templateObject40());
export var CompleteMobile = styled.p(_templateObject41());
export var CompleteSpan = styled.span(_templateObject42());
export var Skeleton = styled.span(_templateObject43());
export var SkeletonGrather = styled.span(_templateObject44());