import _objectDestructuringEmpty from "@babel/runtime/helpers/objectDestructuringEmpty";
import "./scss/index.scss";
import * as React from "react";
import Media from "react-media";
import ReactSVG from "react-svg";
import { FormattedMessage } from "react-intl";
import { animated, useSpring } from "react-spring";
import { xMediumScreen } from "@styles/constants";
import { urlOpenWhatsapp } from "../../app/routes/paths";
import { OverlayProvider } from "../../components";

var securityLogo = require("../../images/Seguridad.svg");

export var RequireLoginView = function RequireLoginView(_ref) {
  _objectDestructuringEmpty(_ref);

  var goToWa = function goToWa() {
    window.open("".concat(urlOpenWhatsapp, "&text=Hola%20de%20nuevo"), "_blank");
  };

  var scaleButton = useSpring({
    config: {
      friction: 40,
      mass: 5,
      tension: 350
    },
    from: {
      scale: 1.1
    },
    loop: true,
    to: {
      scale: 1
    }
  });
  return React.createElement(OverlayProvider, null, React.createElement(Media, {
    query: {
      maxWidth: xMediumScreen
    }
  }, function (matches) {
    return matches ? React.createElement("div", {
      className: "walogin__container"
    }, React.createElement("div", {
      className: "walogin__logo"
    }, React.createElement("div", {
      className: "walogin__logo__background"
    }, " "), React.createElement("span", {
      className: "walogin__logo__image_principal"
    }, " ")), React.createElement("div", {
      className: "walogin__sign"
    }, React.createElement("p", {
      className: "walogin__sign__principal"
    }, "EasyPedido"), React.createElement("p", {
      className: "walogin__sign__secondary"
    }, React.createElement(FormattedMessage, {
      id: "views.WaLogin.RequireLoginView.339430415",
      defaultMessage: "Welcome again"
    })), React.createElement("p", {
      className: "walogin__sign__secondary"
    }, React.createElement(FormattedMessage, {
      id: "views.WaLogin.RequireLoginView.2845238869",
      defaultMessage: "To the delivery channel of your neighborhood"
    })), React.createElement("p", {
      className: "walogin__sign__tertiary"
    }, React.createElement(FormattedMessage, {
      id: "views.WaLogin.RequireLoginView.1304006318",
      defaultMessage: "To access you must"
    })), React.createElement("p", {
      className: "walogin__sign__tertiary"
    }, React.createElement(FormattedMessage, {
      id: "views.WaLogin.RequireLoginView.2516060933",
      defaultMessage: "Join with WhatsApp"
    }))), React.createElement(animated.button, {
      className: "walogin__button",
      onClick: goToWa,
      style: scaleButton
    }, React.createElement("span", null, React.createElement(FormattedMessage, {
      id: "views.WaLogin.RequireLoginView.530461131",
      defaultMessage: "Enter from WhatsApp"
    }))), React.createElement("div", {
      className: "walogin__safe walogin__safe__initial"
    }, React.createElement(ReactSVG, {
      path: securityLogo,
      className: "walogin__safe__icon"
    }), React.createElement("p", {
      className: "walogin__safe__text"
    }, React.createElement(FormattedMessage, {
      id: "views.WaLogin.RequireLoginView.291395769",
      defaultMessage: "100% Safe"
    })))) : React.createElement("div", {
      className: "waloginMax"
    }, React.createElement("div", {
      className: "waloginMax__background"
    }, " "), React.createElement("div", {
      className: "waloginMax__container"
    }, React.createElement("div", {
      className: "waloginMax__container__left"
    }, React.createElement("span", {
      className: "waloginMax__container__left__image_principal"
    }, " "), React.createElement(animated.button, {
      className: "waloginMax__container__left__button",
      onClick: goToWa,
      style: scaleButton
    }, React.createElement("span", null, React.createElement(FormattedMessage, {
      id: "views.WaLogin.RequireLoginView.530461131",
      defaultMessage: "Enter from WhatsApp"
    })))), React.createElement("div", {
      className: "waloginMax__container__right"
    }, React.createElement("span", {
      className: "waloginMax__container__right-img"
    }, " "), React.createElement("div", {
      className: "waloginMax__container__right__sign"
    }, React.createElement("p", {
      className: "waloginMax__container__right__sign__principal"
    }, "EasyPedido"), React.createElement("p", {
      className: "waloginMax__container__right__sign__secondary"
    }, React.createElement(FormattedMessage, {
      id: "views.WaLogin.RequireLoginView.339430415",
      defaultMessage: "Welcome again"
    })), React.createElement("p", {
      className: "waloginMax__container__right__sign__secondary"
    }, React.createElement(FormattedMessage, {
      id: "views.WaLogin.RequireLoginView.2845238869",
      defaultMessage: "To the delivery channel of your neighborhood"
    })), React.createElement("p", {
      className: "waloginMax__container__right__sign__tertiary"
    }, React.createElement(FormattedMessage, {
      id: "views.WaLogin.RequireLoginView.1304006318",
      defaultMessage: "To access you must"
    })), React.createElement("p", {
      className: "waloginMax__container__right__sign__tertiary"
    }, React.createElement(FormattedMessage, {
      id: "views.WaLogin.RequireLoginView.2516060933",
      defaultMessage: "Join with WhatsApp"
    }))), React.createElement("div", {
      className: "waloginMax__container__right__safe waloginMax__container__right__safe__initial"
    }, React.createElement(ReactSVG, {
      path: securityLogo,
      className: "waloginMax__container__right__safe__icon"
    }), React.createElement("p", {
      className: "waloginMax__container__right__safe__text"
    }, React.createElement(FormattedMessage, {
      id: "views.WaLogin.RequireLoginView.291395769",
      defaultMessage: "100% Safe"
    }))))));
  }));
};
export default RequireLoginView;