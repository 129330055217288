import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject19() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 30px;\n  margin-top: 5px;\n  background-color: #efeff2;\n"]);

  _templateObject19 = function _templateObject19() {
    return data;
  };

  return data;
}

function _templateObject18() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  min-width: 320px;\n  padding: 10px;\n  position: fixed;\n  bottom: 0;\n  left: 0;\n  display: grid;\n  grid-template-areas:\n    \"quantity editAdd\"\n    \"remove remove\";\n  background-color: white;\n  z-index: 999;\n  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);\n\n  button {\n    width: 100%;\n    margin: 0;\n    left: 0;\n    @media (min-width: 850px) {\n      padding: 0.9rem 0.5rem;\n    }\n  }\n\n  @media (min-width: 850px) {\n    position: -webkit-sticky;\n    position: sticky;\n    box-shadow: none;\n    border-top: 1px solid rgba(0, 0, 0, 0.1);\n  }\n"]);

  _templateObject18 = function _templateObject18() {
    return data;
  };

  return data;
}

function _templateObject17() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject17 = function _templateObject17() {
    return data;
  };

  return data;
}

function _templateObject16() {
  var data = _taggedTemplateLiteral(["\n  width: 33px;\n  margin-left: auto;\n  display: ", ";\n  transition: display 1s ease 1s;\n  position: -webkit-sticky;\n  position: sticky;\n  bottom: ", ";\n  right: 5px;\n  z-index: 9999;\n\n  svg {\n    transform: rotate(-90deg);\n  }\n"]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n"]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n\n  div {\n    width: 90%;\n    display: flex;\n    justify-content: flex-start;\n    margin: 0 auto;\n  }\n\n  > div > div {\n    background-color: white;\n    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);\n  }\n\n  > div > div > span:first-child {\n    justify-content: flex-start;\n    padding-left: 10px;\n  }\n\n  > div > div > span ~ div ~ span > div > div {\n    justify-content: flex-end;\n    padding-right: 10px;\n  }\n\n  label {\n    font-size: 0.9rem;\n  }\n  input {\n    color: #ed1c24;\n    font-size: 1.1rem;\n  }\n  //Chrome\n  input[type=\"number\"]::-webkit-outer-spin-button,\n  input[type=\"number\"]::-webkit-inner-spin-button {\n    -webkit-appearance: none;\n    margin: 0;\n  }\n  //Firefox\n  input[type=\"number\"] {\n    -moz-appearance: textfield;\n  }\n  input[type=\"number\"]:hover,\n  input[type=\"number\"]:focus {\n    -moz-appearance: number-input;\n  }\n  //Other\n  input[type=\"number\"]::-webkit-inner-spin-button,\n  input[type=\"number\"]::-webkit-outer-spin-button {\n    -webkit-appearance: none;\n    margin: 0;\n  }\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  margin-top: 20px;\n\n  .react-select-wrapper,\n  .input {\n    width: 50%;\n    margin-bottom: 1rem;\n  }\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n  text-decoration: line-through;\n  text-align: center;\n  font-weight: bold;\n  color: ", ";\n  font-size: ", ";\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  font-weight: ", ";\n  margin-bottom: ", ";\n  font-size: 1.8rem;\n  > span {\n    display: inline-block;\n    order: 1;\n  }\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  font-weight: ", ";\n  margin-bottom: ", ";\n  font-size: 1.5rem;\n  > span {\n    display: inline-block;\n    margin-top: 2px;\n    margin-right: 10px;\n    order: 1;\n  }\n  p {\n    display: inline-block;\n    margin-left: 10px;\n    order: 2;\n    font-size: 1.3rem;\n    text-decoration: line-through !important;\n  }\n\n  @media (min-width: 850px) {\n    flex-direction: column;\n    align-items: flex-start;\n    > span {\n      display: block;\n      margin-left: 0px;\n    }\n    p {\n      display: block;\n    }\n  }\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  grid-area: remove;\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  grid-area: editAdd;\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  align-self: center;\n  grid-area: quantity;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  padding: 5px 5px 0px 5px;\n  margin-bottom: 20px;\n  border-radius: 8px;\n  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);\n\n  svg {\n    width: 30px;\n    height: 30px;\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  text-transform: uppercase;\n  font-weight: ", ";\n  margin-bottom: ", ";\n  h3 {\n    font-size: 1.1rem !important;\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-between;\n  padding: 0;\n  margin: 0 15px 0 0;\n  width: 50px;\n\n  > div {\n    display: flex;\n  }\n\n  svg {\n    cursor: pointer;\n    justify-self: center;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  padding: 0px 10px;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  button {\n    border-radius: 8px;\n    width: 150px;\n    position: relative;\n    left: calc(50% - 75px);\n    margin: 0 auto;\n  }\n  @media (min-width: 850px) {\n    padding-left: 10px;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var AddToCartSelection = styled.div(_templateObject());
export var ContainerProductNamePricingQuantity = styled.div(_templateObject2());
export var QuantityButtons = styled.div(_templateObject3());
export var ProductNamePricing = styled.div(_templateObject4());
export var ProductNameHeader = styled.h3(_templateObject5(), function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.theme.spacing.spacer;
});
export var FavoriteHeart = styled.div(_templateObject6());
export var FavoriteQuantity = styled.div(_templateObject7());
export var EditAddToCart = styled.div(_templateObject8());
export var RemoveFromCart = styled.div(_templateObject9());
export var ProductPricing = styled.h4(_templateObject10(), function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.theme.spacing.spacer;
});
export var ProductUndiscountedPricing = styled.h4(_templateObject11(), function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.theme.spacing.spacer;
});
export var UndiscountedPrice = styled.p(_templateObject12(), function (props) {
  return props.theme.colors.discount;
}, function (props) {
  return props.theme.typography.smallFontSize;
});
export var VariantPicker = styled.div(_templateObject13());
export var QuantityInput = styled.div(_templateObject14());
export var ErrorMessage = styled.p(_templateObject15(), function (props) {
  return props.theme.colors.error;
});
export var ContainerArrow = styled.div(_templateObject16(), function (props) {
  return props.intersection ? "none" : "block";
}, function (props) {
  return props.edit ? "120px" : "70px";
});
export var PReference = styled.p(_templateObject17());
export var ContainerFooter = styled.div(_templateObject18());
export var RemoveBotton = styled.div(_templateObject19());