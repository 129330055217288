import * as React from "react";
import { ChangeAmountProductsContext } from "@temp/contexts";
import { useAppSelector } from "@temp/redux";
import { TypedHomeSaleProductsList } from "./queries";
import HomeContentSaleProducts from "./HomeContentSaleProducts";

var ViewHomeSaleProducts = function ViewHomeSaleProducts() {
  var _useAppSelector = useAppSelector(function (store) {
    return store;
  }),
      searchQueryValue = _useAppSelector.search.searchQueryValue;

  var _React$useContext = React.useContext(ChangeAmountProductsContext),
      maxProductsPerCategory = _React$useContext.maxProductsPerCategory;

  var fetchPolicyValue = localStorage.getItem("home_cache") === "true" ? "cache-first" : "cache-and-network";
  return React.createElement(TypedHomeSaleProductsList, {
    fetchPolicy: "".concat(fetchPolicyValue),
    variables: {
      amountByScreen: maxProductsPerCategory + 1,
      search: searchQueryValue
    }
  }, function (_ref) {
    var data = _ref.data,
        loading = _ref.loading;
    return React.createElement(HomeContentSaleProducts, {
      homeSaleProductsData: data,
      loadingHomeSaleProducts: loading
    });
  });
};

export default ViewHomeSaleProducts;