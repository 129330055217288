import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject53() {
  var data = _taggedTemplateLiteral(["\n  margin-left: auto;\n  color: #606060;\n"]);

  _templateObject53 = function _templateObject53() {
    return data;
  };

  return data;
}

function _templateObject52() {
  var data = _taggedTemplateLiteral(["\n  text-transform: uppercase;\n  color: black;\n  text-transform: lowercase;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n"]);

  _templateObject52 = function _templateObject52() {
    return data;
  };

  return data;
}

function _templateObject51() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 5px;\n  padding-top: 10px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  border-top: 2px solid #e7e6e6;\n"]);

  _templateObject51 = function _templateObject51() {
    return data;
  };

  return data;
}

function _templateObject50() {
  var data = _taggedTemplateLiteral(["\n  margin-left: auto;\n  color: #606060;\n"]);

  _templateObject50 = function _templateObject50() {
    return data;
  };

  return data;
}

function _templateObject49() {
  var data = _taggedTemplateLiteral(["\n  text-transform: uppercase;\n  color: black;\n  text-transform: lowercase;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n"]);

  _templateObject49 = function _templateObject49() {
    return data;
  };

  return data;
}

function _templateObject48() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n"]);

  _templateObject48 = function _templateObject48() {
    return data;
  };

  return data;
}

function _templateObject47() {
  var data = _taggedTemplateLiteral(["\n  margin-left: auto;\n  color: #606060;\n"]);

  _templateObject47 = function _templateObject47() {
    return data;
  };

  return data;
}

function _templateObject46() {
  var data = _taggedTemplateLiteral(["\n  text-transform: uppercase;\n  color: black;\n  text-transform: lowercase;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n"]);

  _templateObject46 = function _templateObject46() {
    return data;
  };

  return data;
}

function _templateObject45() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n"]);

  _templateObject45 = function _templateObject45() {
    return data;
  };

  return data;
}

function _templateObject44() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n"]);

  _templateObject44 = function _templateObject44() {
    return data;
  };

  return data;
}

function _templateObject43() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"]);

  _templateObject43 = function _templateObject43() {
    return data;
  };

  return data;
}

function _templateObject42() {
  var data = _taggedTemplateLiteral(["\n  padding: 10px;\n  display: grid;\n  grid-template-columns: 20% 80%;\n"]);

  _templateObject42 = function _templateObject42() {
    return data;
  };

  return data;
}

function _templateObject41() {
  var data = _taggedTemplateLiteral(["\n  color: #f76268;\n"]);

  _templateObject41 = function _templateObject41() {
    return data;
  };

  return data;
}

function _templateObject40() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n  color: #606060;\n"]);

  _templateObject40 = function _templateObject40() {
    return data;
  };

  return data;
}

function _templateObject39() {
  var data = _taggedTemplateLiteral(["\n  color: black;\n  font-weight: 700;\n"]);

  _templateObject39 = function _templateObject39() {
    return data;
  };

  return data;
}

function _templateObject38() {
  var data = _taggedTemplateLiteral(["\n  color: #afafaf;\n"]);

  _templateObject38 = function _templateObject38() {
    return data;
  };

  return data;
}

function _templateObject37() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  padding: 10px 0px;\n  display: flex;\n  justify-content: space-between;\n"]);

  _templateObject37 = function _templateObject37() {
    return data;
  };

  return data;
}

function _templateObject36() {
  var data = _taggedTemplateLiteral(["\n  color: black;\n  text-transform: lowercase;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n"]);

  _templateObject36 = function _templateObject36() {
    return data;
  };

  return data;
}

function _templateObject35() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n"]);

  _templateObject35 = function _templateObject35() {
    return data;
  };

  return data;
}

function _templateObject34() {
  var data = _taggedTemplateLiteral(["\n  div {\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  svg {\n    transform: scale(0.7);\n  }\n"]);

  _templateObject34 = function _templateObject34() {
    return data;
  };

  return data;
}

function _templateObject33() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"]);

  _templateObject33 = function _templateObject33() {
    return data;
  };

  return data;
}

function _templateObject32() {
  var data = _taggedTemplateLiteral(["\n  padding: 10px;\n  display: flex;\n  flex-direction: column;\n"]);

  _templateObject32 = function _templateObject32() {
    return data;
  };

  return data;
}

function _templateObject31() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-columns: 20% 80%;\n"]);

  _templateObject31 = function _templateObject31() {
    return data;
  };

  return data;
}

function _templateObject30() {
  var data = _taggedTemplateLiteral(["\n  width: 110px;\n  padding: 10px;\n  margin-left: auto;\n  background-color: #ed1c24;\n  border-radius: 8px;\n  color: white;\n  text-align: center;\n  text-transform: lowercase;\n  cursor: pointer;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n"]);

  _templateObject30 = function _templateObject30() {
    return data;
  };

  return data;
}

function _templateObject29() {
  var data = _taggedTemplateLiteral(["\n  color: #f76268;\n"]);

  _templateObject29 = function _templateObject29() {
    return data;
  };

  return data;
}

function _templateObject28() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n  font-weight: bold;\n"]);

  _templateObject28 = function _templateObject28() {
    return data;
  };

  return data;
}

function _templateObject27() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject27 = function _templateObject27() {
    return data;
  };

  return data;
}

function _templateObject26() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"]);

  _templateObject26 = function _templateObject26() {
    return data;
  };

  return data;
}

function _templateObject25() {
  var data = _taggedTemplateLiteral(["\n  padding: 10px;\n  display: grid;\n  grid-template-columns: 20% 35% 45%;\n"]);

  _templateObject25 = function _templateObject25() {
    return data;
  };

  return data;
}

function _templateObject24() {
  var data = _taggedTemplateLiteral(["\n  padding-right: 10px;\n  display: block;\n  color: #afafaf;\n"]);

  _templateObject24 = function _templateObject24() {
    return data;
  };

  return data;
}

function _templateObject23() {
  var data = _taggedTemplateLiteral(["\n  padding-right: 10px;\n  display: inline-block;\n  color: #afafaf;\n"]);

  _templateObject23 = function _templateObject23() {
    return data;
  };

  return data;
}

function _templateObject22() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n  font-weight: bold;\n  color: black;\n  text-transform: lowercase;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n"]);

  _templateObject22 = function _templateObject22() {
    return data;
  };

  return data;
}

function _templateObject21() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject21 = function _templateObject21() {
    return data;
  };

  return data;
}

function _templateObject20() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"]);

  _templateObject20 = function _templateObject20() {
    return data;
  };

  return data;
}

function _templateObject19() {
  var data = _taggedTemplateLiteral(["\n  padding: 10px;\n  display: grid;\n  grid-template-columns: 20% 80%;\n"]);

  _templateObject19 = function _templateObject19() {
    return data;
  };

  return data;
}

function _templateObject18() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n"]);

  _templateObject18 = function _templateObject18() {
    return data;
  };

  return data;
}

function _templateObject17() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  svg {\n    margin-top: 10px;\n    path {\n      fill: #000000;\n    }\n  }\n"]);

  _templateObject17 = function _templateObject17() {
    return data;
  };

  return data;
}

function _templateObject16() {
  var data = _taggedTemplateLiteral(["\n  align-self: center;\n"]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = _taggedTemplateLiteral(["\n  div {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n"]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 15px;\n  div {\n    width: 32px;\n    height: 32px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border-radius: inherit;\n  }\n  svg {\n    width: 32px;\n    height: 32px;\n    border-radius: inherit;\n  }\n  img {\n    width: 32px;\n    height: 32px;\n    object-fit: contain;\n    border-radius: inherit;\n  }\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  padding: 10px;\n  display: grid;\n  grid-template-columns: 20% 65% 15%;\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n  align-self: center;\n  text-align: end;\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  align-self: center;\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  div {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  padding: 10px;\n  display: grid;\n  grid-template-columns: 20% 50% 30%;\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  align-self: center;\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  div {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  svg {\n    transform: scale(0.5);\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  padding: 10px;\n  display: grid;\n  grid-template-columns: 20% 80%;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  text-transform: lowercase;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  padding: 7px 15px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  background: #efeff2;\n  border-radius: 8px;\n  color: #f76268;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  padding: 7px 15px;\n  background: #efeff2;\n  border-radius: 8px;\n  color: #f76268;\n  text-transform: lowercase;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  margin: 20px 0px;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var Title = styled.p(_templateObject2());
export var TitleMyOrder = styled.div(_templateObject3());
export var TitleMyOrderTitle = styled.p(_templateObject4());
export var TitleMyOrderNumber = styled.p(_templateObject5());
export var CheckoutObservation = styled.div(_templateObject6());
export var CheckoutObservationImage = styled.div(_templateObject7());
export var CheckoutObservationData = styled.p(_templateObject8());
export var Date = styled.div(_templateObject9());
export var DateImg = styled.div(_templateObject10());
export var DateDate = styled.p(_templateObject11());
export var DateTime = styled.p(_templateObject12());
export var Delivery = styled.div(_templateObject13());
export var DeliveryData = styled.div(_templateObject14());
export var DeliveryImgData = styled.div(_templateObject15());
export var DeliveryNameData = styled.p(_templateObject16());
export var DeliveryImage = styled.div(_templateObject17());
export var DeliveryNumber = styled.div(_templateObject18());
export var Shipping = styled.div(_templateObject19());
export var ShippingImage = styled.div(_templateObject20());
export var ShippingData = styled.div(_templateObject21());
export var ShippingBoldData = styled.span(_templateObject22());
export var ShippingParragraphData = styled.p(_templateObject23());
export var ShippingParragraphObservationsData = styled.p(_templateObject24());
export var Order = styled.div(_templateObject25());
export var OrderImage = styled.div(_templateObject26());
export var OrderData = styled.div(_templateObject27());
export var OrderParragraphData = styled.p(_templateObject28());
export var OrderBoldData = styled.span(_templateObject29());
export var OrderButton = styled.div(_templateObject30());
export var PaymentContainer = styled.div(_templateObject31());
export var Payment = styled.div(_templateObject32());
export var PaymentImage = styled.div(_templateObject33());
export var PaymentDataphoneImage = styled.div(_templateObject34());
export var PaymentData = styled.div(_templateObject35());
export var PaymentParragraphCashData = styled.p(_templateObject36());
export var PaymentParragraphDataState = styled.div(_templateObject37());
export var PaymentParragraphData = styled.p(_templateObject38());
export var PaymentParragraphDataSpan = styled.span(_templateObject39());
export var PaymentParragraphDataSpanState = styled.span(_templateObject40());
export var PaymentBoldData = styled.span(_templateObject41());
export var Total = styled.div(_templateObject42());
export var TotalImage = styled.div(_templateObject43());
export var TotalData = styled.div(_templateObject44());
export var SubTotalData = styled.div(_templateObject45());
export var SubTotalParragraphData = styled.p(_templateObject46());
export var SubTotalSpanData = styled.span(_templateObject47());
export var ShippingTotalData = styled.div(_templateObject48());
export var ShippingTotalParragraphData = styled.p(_templateObject49());
export var ShippingTotalSpanData = styled.span(_templateObject50());
export var TotalTotalData = styled.div(_templateObject51());
export var TotalTotalParragraphData = styled.p(_templateObject52());
export var TotalTotalSpanData = styled.span(_templateObject53());