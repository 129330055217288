import _extends from "@babel/runtime/helpers/extends";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React from "react";
import { components } from "react-select";
import { ThemeContext } from "styled-components";
import { Icon, InputLabel, Select } from "@components/atoms";
import * as S from "./styles";
export var InputSelect = function InputSelect(_ref) {
  var label = _ref.label,
      id = _ref.id,
      inputProps = _ref.inputProps,
      props = _objectWithoutProperties(_ref, ["label", "id", "inputProps"]);

  var customTheme = React.useContext(ThemeContext);
  var borderColor = customTheme.input.border;
  var customStyles = {
    control: function control(provided, state) {
      return _objectSpread(_objectSpread({}, provided), {}, {
        ":hover": {
          border: "1px solid #448bfd",
          outlineColor: "#448bfd",
          outlineStyle: "solid",
          outlineWidth: "1px"
        },
        ":hover ~ label": {
          color: "#6f6f6f"
        },
        background: "none",
        border: state.menuIsOpen ? "1px solid #448bfd" : "1px solid ".concat(borderColor),
        borderRadius: 8,
        boxShadow: 0,
        boxSizing: "border-box",
        margin: 0,
        outline: state.menuIsOpen ? "1px solid #448bfd" : "",
        padding: "0.55rem 1rem"
      });
    },
    valueContainer: function valueContainer(provided) {
      return _objectSpread(_objectSpread({}, provided), {}, {
        padding: 0
      });
    }
  };
  var customComponents = {
    Control: function Control(props) {
      var customTheme = React.useContext(ThemeContext);
      return React.createElement(React.Fragment, null, React.createElement(components.Control, _extends({
        "data-test": "inputSelect"
      }, _objectSpread({
        customTheme: customTheme
      }, props))), React.createElement(InputLabel, {
        labelBackground: customTheme.colors.light,
        active: props.selectProps.menuIsOpen || props.hasValue
      }, label));
    },
    IndicatorSeparator: function IndicatorSeparator() {
      return null;
    },
    IndicatorsContainer: function IndicatorsContainer(_ref2) {
      var selectProps = _ref2.selectProps,
          hasValue = _ref2.hasValue,
          clearValue = _ref2.clearValue;
      var showClearIndicator = selectProps.isClearable || selectProps.isMulti && selectProps.isClearable === undefined;

      if (showClearIndicator && hasValue) {
        return React.createElement(S.ClearIndicator, {
          onClick: clearValue
        }, React.createElement(Icon, {
          name: "select_x",
          size: 10
        }));
      } // Boolean to string conversion done due to
      // https://github.com/styled-components/styled-components/issues/1198


      return React.createElement(S.DropdownIndicator, {
        rotate: String(selectProps.menuIsOpen)
      }, React.createElement("svg", {
        xmlns: "http://www.w3.org/2000/svg",
        width: "32",
        height: "32",
        viewBox: "0 0 32 32"
      }, React.createElement("g", {
        id: "Grupo_3284",
        "data-name": "Grupo 3284",
        transform: "translate(32 32) rotate(180)"
      }, React.createElement("path", {
        id: "Trazado_2006",
        "data-name": "Trazado 2006",
        d: "M16,32A16,16,0,1,0,0,16,16,16,0,0,0,16,32Z",
        transform: "translate(32 32) rotate(180)",
        fill: "none"
      }), React.createElement("path", {
        id: "path",
        d: "M8.523,5.95l-3.7-3.7-3.7,3.7L0,4.824,4.824,0,9.648,4.824Z",
        transform: "translate(11.176 12.679)",
        fill: "#448bfd",
        fillRule: "evenodd"
      }))));
    },
    Input: function Input(props) {
      return React.createElement(components.Input, _objectSpread(_objectSpread({}, props), inputProps));
    },
    Option: function Option(props) {
      var customTheme = React.useContext(ThemeContext);
      return React.createElement(components.Option, _objectSpread({
        customTheme: customTheme
      }, props));
    }
  };
  return React.createElement(Select, _extends({
    id: id,
    customComponents: customComponents
  }, props, {
    customStyles: customStyles
  }));
};