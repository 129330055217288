import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

var _this = this;

import * as React from "react";
import { useAlert } from "react-alert";
import { StringParam, useQueryParams } from "use-query-params";
import { BASE_URL } from "../../core/config";
import { TypedAccountConfirmMutation } from "./queries";
import "./scss/index.scss";

var AccountConfirm = function AccountConfirm(_ref) {
  var history = _ref.history;

  var _useQueryParams = useQueryParams({
    email: StringParam,
    token: StringParam
  }),
      _useQueryParams2 = _slicedToArray(_useQueryParams, 1),
      query = _useQueryParams2[0];

  var alert = useAlert();

  var displayConfirmationAlert = function displayConfirmationAlert(anyErrors) {
    alert.show({
      content: anyErrors.length > 0 ? anyErrors.map(function (error) {
        return error.message;
      }).join(" ") : "You can now log in",
      title: anyErrors.length > 0 ? "Error" : "Account confirmed"
    }, {
      type: anyErrors.length > 0 ? "error" : "success",
      timeout: 5000
    });
  };

  React.useEffect(function () {
    _this.accountManagerFn({
      variables: {
        email: query.email,
        token: query.token
      }
    }).then(function (result) {
      var possibleErrors = result.data.confirmAccount.errors;
      displayConfirmationAlert(possibleErrors);
    }).catch(function () {
      var errors = [{
        message: "Something went wrong while activating your account."
      }];
      displayConfirmationAlert(errors);
    }).finally(function () {
      history.push(BASE_URL);
    });

    return function () {};
  }, []);
  return React.createElement(TypedAccountConfirmMutation, null, function (accountConfirm) {
    _this.accountManagerFn = accountConfirm;
    return React.createElement("div", null);
  });
};

export default AccountConfirm;