import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "./scss/index.scss";
import React from "react";
import ReactSVG from "react-svg";
import { FormattedMessage, useIntl } from "react-intl";
import Media from "react-responsive";
import { easyLinkMessages } from "@temp/intl";
import { xmediumScreen } from "../../globalStyles/scss/variables.scss";
export var AlertShareOptions = function AlertShareOptions(_ref) {
  var closeIcon = _ref.closeIcon,
      hideModal = _ref.hideModal,
      easyLink = _ref.easyLink,
      logoIcon = _ref.logoIcon,
      shareIcon = _ref.shareIcon,
      slug = _ref.slug,
      tradeName = _ref.tradeName;
  var intl = useIntl();

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      showCopyLink = _React$useState2[0],
      setShowCopyLink = _React$useState2[1];

  var copyLinkRef = React.useRef(null);
  var shareLinkRef = React.useRef(null);
  React.useLayoutEffect(function () {
    var handleCleanClickEffect = function handleCleanClickEffect() {
      if (copyLinkRef === null || copyLinkRef === void 0 ? void 0 : copyLinkRef.current) {
        var _copyLinkRef$current, _copyLinkRef$current$;

        copyLinkRef === null || copyLinkRef === void 0 ? void 0 : (_copyLinkRef$current = copyLinkRef.current) === null || _copyLinkRef$current === void 0 ? void 0 : (_copyLinkRef$current$ = _copyLinkRef$current.classList) === null || _copyLinkRef$current$ === void 0 ? void 0 : _copyLinkRef$current$.remove("showContainerShareOptions__data__content--active");
      }

      if (shareLinkRef === null || shareLinkRef === void 0 ? void 0 : shareLinkRef.current) {
        var _shareLinkRef$current, _shareLinkRef$current2;

        shareLinkRef === null || shareLinkRef === void 0 ? void 0 : (_shareLinkRef$current = shareLinkRef.current) === null || _shareLinkRef$current === void 0 ? void 0 : (_shareLinkRef$current2 = _shareLinkRef$current.classList) === null || _shareLinkRef$current2 === void 0 ? void 0 : _shareLinkRef$current2.remove("showContainerShareOptions__data__content--active");
      }
    };

    handleCleanClickEffect();
    return function () {
      handleCleanClickEffect();
    };
  }, []);
  React.useEffect(function () {
    var handleVisibilityChange = function handleVisibilityChange() {
      if (copyLinkRef === null || copyLinkRef === void 0 ? void 0 : copyLinkRef.current) {
        var _copyLinkRef$current2, _copyLinkRef$current3;

        copyLinkRef === null || copyLinkRef === void 0 ? void 0 : (_copyLinkRef$current2 = copyLinkRef.current) === null || _copyLinkRef$current2 === void 0 ? void 0 : (_copyLinkRef$current3 = _copyLinkRef$current2.classList) === null || _copyLinkRef$current3 === void 0 ? void 0 : _copyLinkRef$current3.remove("showContainerShareOptions__data__content--active");
      }

      if (shareLinkRef === null || shareLinkRef === void 0 ? void 0 : shareLinkRef.current) {
        var _shareLinkRef$current3, _shareLinkRef$current4;

        shareLinkRef === null || shareLinkRef === void 0 ? void 0 : (_shareLinkRef$current3 = shareLinkRef.current) === null || _shareLinkRef$current3 === void 0 ? void 0 : (_shareLinkRef$current4 = _shareLinkRef$current3.classList) === null || _shareLinkRef$current4 === void 0 ? void 0 : _shareLinkRef$current4.remove("showContainerShareOptions__data__content--active");
      }
    };

    window.addEventListener("visibilitychange", handleVisibilityChange);
    return function () {
      window.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
  React.useEffect(function () {
    var timeout = 0;

    if (showCopyLink) {
      clearTimeout(timeout);
      timeout = setTimeout(function () {
        setShowCopyLink(false);
      }, 2000);
    }

    return function () {
      clearTimeout(timeout);
    };
  }, [showCopyLink]);
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "showContainerShareOptions",
    onClick: hideModal
  }), React.createElement("div", {
    className: "showContainerShareOptions__data"
  }, React.createElement("div", {
    className: "showContainerShareOptions__data__close"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.AlertShareOptions.index.382188090",
    defaultMessage: "Options"
  })), React.createElement(ReactSVG, {
    path: closeIcon,
    onClick: hideModal
  })), React.createElement(Media, {
    maxWidth: xmediumScreen
  }, function (matches) {
    return matches && React.createElement("div", {
      ref: shareLinkRef,
      onClick: function onClick() {
        var _shareLinkRef$current5, _shareLinkRef$current6;

        var newVariable = window.navigator;
        shareLinkRef === null || shareLinkRef === void 0 ? void 0 : (_shareLinkRef$current5 = shareLinkRef.current) === null || _shareLinkRef$current5 === void 0 ? void 0 : (_shareLinkRef$current6 = _shareLinkRef$current5.classList) === null || _shareLinkRef$current6 === void 0 ? void 0 : _shareLinkRef$current6.add("showContainerShareOptions__data__content--active");

        if (newVariable === null || newVariable === void 0 ? void 0 : newVariable.share) {
          newVariable.share({
            url: easyLink,
            text: intl.formatMessage(easyLinkMessages.iWantToShareTheDeliveryLink, {
              trade: tradeName
            }),
            title: ""
          }) // eslint-disable-next-line no-console
          .then(function () {
            return console.log("Successful share");
          }) // eslint-disable-next-line no-console
          .catch(function (error) {
            return console.log("Error sharing", error);
          });
        } else {
          // eslint-disable-next-line no-alert
          alert("Share is not supported");
        }
      },
      onTouchEnd: function onTouchEnd() {
        var _shareLinkRef$current7, _shareLinkRef$current8;

        shareLinkRef === null || shareLinkRef === void 0 ? void 0 : (_shareLinkRef$current7 = shareLinkRef.current) === null || _shareLinkRef$current7 === void 0 ? void 0 : (_shareLinkRef$current8 = _shareLinkRef$current7.classList) === null || _shareLinkRef$current8 === void 0 ? void 0 : _shareLinkRef$current8.remove("showContainerShareOptions__data__content--active");
      },
      onTouchMove: function onTouchMove() {
        var _shareLinkRef$current9, _shareLinkRef$current10;

        shareLinkRef === null || shareLinkRef === void 0 ? void 0 : (_shareLinkRef$current9 = shareLinkRef.current) === null || _shareLinkRef$current9 === void 0 ? void 0 : (_shareLinkRef$current10 = _shareLinkRef$current9.classList) === null || _shareLinkRef$current10 === void 0 ? void 0 : _shareLinkRef$current10.remove("showContainerShareOptions__data__content--active");
      },
      onTouchCancel: function onTouchCancel() {
        var _shareLinkRef$current11, _shareLinkRef$current12;

        shareLinkRef === null || shareLinkRef === void 0 ? void 0 : (_shareLinkRef$current11 = shareLinkRef.current) === null || _shareLinkRef$current11 === void 0 ? void 0 : (_shareLinkRef$current12 = _shareLinkRef$current11.classList) === null || _shareLinkRef$current12 === void 0 ? void 0 : _shareLinkRef$current12.remove("showContainerShareOptions__data__content--active");
      },
      onMouseOut: function onMouseOut() {
        var _shareLinkRef$current13, _shareLinkRef$current14;

        shareLinkRef === null || shareLinkRef === void 0 ? void 0 : (_shareLinkRef$current13 = shareLinkRef.current) === null || _shareLinkRef$current13 === void 0 ? void 0 : (_shareLinkRef$current14 = _shareLinkRef$current13.classList) === null || _shareLinkRef$current14 === void 0 ? void 0 : _shareLinkRef$current14.remove("showContainerShareOptions__data__content--active");
      },
      onBlur: function onBlur() {
        var _shareLinkRef$current15, _shareLinkRef$current16;

        shareLinkRef === null || shareLinkRef === void 0 ? void 0 : (_shareLinkRef$current15 = shareLinkRef.current) === null || _shareLinkRef$current15 === void 0 ? void 0 : (_shareLinkRef$current16 = _shareLinkRef$current15.classList) === null || _shareLinkRef$current16 === void 0 ? void 0 : _shareLinkRef$current16.remove("showContainerShareOptions__data__content--active");
      },
      className: "showContainerShareOptions__data__content showContainerShareOptions__data__content--first"
    }, React.createElement(ReactSVG, {
      path: shareIcon
    }), React.createElement("p", null, React.createElement(FormattedMessage, {
      id: "components.AlertShareOptions.index.3677561703",
      defaultMessage: "Share"
    })));
  }), React.createElement("div", {
    ref: copyLinkRef,
    onClick: function onClick() {
      var _copyLinkRef$current4, _copyLinkRef$current5;

      copyLinkRef === null || copyLinkRef === void 0 ? void 0 : (_copyLinkRef$current4 = copyLinkRef.current) === null || _copyLinkRef$current4 === void 0 ? void 0 : (_copyLinkRef$current5 = _copyLinkRef$current4.classList) === null || _copyLinkRef$current5 === void 0 ? void 0 : _copyLinkRef$current5.add("showContainerShareOptions__data__content--active");

      if (!showCopyLink) {
        window.navigator.clipboard.writeText(easyLink);
        setShowCopyLink(true);
      }
    },
    onTouchEnd: function onTouchEnd() {
      var _copyLinkRef$current6, _copyLinkRef$current7;

      copyLinkRef === null || copyLinkRef === void 0 ? void 0 : (_copyLinkRef$current6 = copyLinkRef.current) === null || _copyLinkRef$current6 === void 0 ? void 0 : (_copyLinkRef$current7 = _copyLinkRef$current6.classList) === null || _copyLinkRef$current7 === void 0 ? void 0 : _copyLinkRef$current7.remove("showContainerShareOptions__data__content--active");
    },
    onTouchMove: function onTouchMove() {
      var _copyLinkRef$current8, _copyLinkRef$current9;

      copyLinkRef === null || copyLinkRef === void 0 ? void 0 : (_copyLinkRef$current8 = copyLinkRef.current) === null || _copyLinkRef$current8 === void 0 ? void 0 : (_copyLinkRef$current9 = _copyLinkRef$current8.classList) === null || _copyLinkRef$current9 === void 0 ? void 0 : _copyLinkRef$current9.remove("showContainerShareOptions__data__content--active");
    },
    onTouchCancel: function onTouchCancel() {
      var _copyLinkRef$current10, _copyLinkRef$current11;

      copyLinkRef === null || copyLinkRef === void 0 ? void 0 : (_copyLinkRef$current10 = copyLinkRef.current) === null || _copyLinkRef$current10 === void 0 ? void 0 : (_copyLinkRef$current11 = _copyLinkRef$current10.classList) === null || _copyLinkRef$current11 === void 0 ? void 0 : _copyLinkRef$current11.remove("showContainerShareOptions__data__content--active");
    },
    onMouseOut: function onMouseOut() {
      var _copyLinkRef$current12, _copyLinkRef$current13;

      copyLinkRef === null || copyLinkRef === void 0 ? void 0 : (_copyLinkRef$current12 = copyLinkRef.current) === null || _copyLinkRef$current12 === void 0 ? void 0 : (_copyLinkRef$current13 = _copyLinkRef$current12.classList) === null || _copyLinkRef$current13 === void 0 ? void 0 : _copyLinkRef$current13.remove("showContainerShareOptions__data__content--active");
    },
    onMouseMove: function onMouseMove() {
      var _copyLinkRef$current14, _copyLinkRef$current15;

      copyLinkRef === null || copyLinkRef === void 0 ? void 0 : (_copyLinkRef$current14 = copyLinkRef.current) === null || _copyLinkRef$current14 === void 0 ? void 0 : (_copyLinkRef$current15 = _copyLinkRef$current14.classList) === null || _copyLinkRef$current15 === void 0 ? void 0 : _copyLinkRef$current15.remove("showContainerShareOptions__data__content--active");
    },
    onBlur: function onBlur() {
      var _copyLinkRef$current16, _copyLinkRef$current17;

      copyLinkRef === null || copyLinkRef === void 0 ? void 0 : (_copyLinkRef$current16 = copyLinkRef.current) === null || _copyLinkRef$current16 === void 0 ? void 0 : (_copyLinkRef$current17 = _copyLinkRef$current16.classList) === null || _copyLinkRef$current17 === void 0 ? void 0 : _copyLinkRef$current17.remove("showContainerShareOptions__data__content--active");
    },
    className: "showContainerShareOptions__data__content showContainerShareOptions__data__content--second"
  }, React.createElement(ReactSVG, {
    path: logoIcon
  }), React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.AlertShareOptions.index.2781470033",
    defaultMessage: "Copy link"
  }))), showCopyLink && React.createElement("div", {
    className: "showContainerShareOptions__data__copy"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.AlertShareOptions.index.218581354",
    defaultMessage: "Link copied"
  })))));
};
export default AlertShareOptions;