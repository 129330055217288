import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "./scss/index.scss";
import * as React from "react";
import Media from "react-responsive";
import { xMediumScreen } from "@styles/constants";
import { Footer, MainMenu, SearchHeaderDesktop } from "@temp/components";
import { useAppSelector } from "@temp/redux";
import ViewHomeProducts from "./ViewHomeProducts";
import ViewHomeSaleProducts from "./ViewHomeSaleProducts";

var View = function View() {
  var _useAppSelector = useAppSelector(function (store) {
    return store;
  }),
      searchTitleHeight = _useAppSelector.search.searchTitleHeight,
      hasAttentionAtCurrentTime = _useAppSelector.tradeAttention.hasAttentionAtCurrentTime;

  var _React$useState = React.useState(window.innerWidth <= 850 ? 110 : 200),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      marginTopState = _React$useState2[0],
      setMarginTopState = _React$useState2[1];

  React.useEffect(function () {
    var changeMarginTop = function changeMarginTop() {
      if (window.innerWidth <= 850 && marginTopState !== 110 + searchTitleHeight && hasAttentionAtCurrentTime) {
        setMarginTopState(110 + searchTitleHeight);
      } else if (window.innerWidth > 850 && marginTopState !== 200 + searchTitleHeight && hasAttentionAtCurrentTime) {
        setMarginTopState(200 + searchTitleHeight);
      } else if (window.innerWidth <= 850 && marginTopState !== 140 + searchTitleHeight && !hasAttentionAtCurrentTime) {
        setMarginTopState(140 + searchTitleHeight);
      } else if (window.innerWidth > 850 && marginTopState !== 230 + searchTitleHeight && !hasAttentionAtCurrentTime) {
        setMarginTopState(230 + searchTitleHeight);
      }
    };

    changeMarginTop();
    return function () {};
  }, [searchTitleHeight]);
  React.useEffect(function () {
    var changeMarginTop = function changeMarginTop() {
      if (window.innerWidth <= 850 && marginTopState !== 110 + searchTitleHeight && hasAttentionAtCurrentTime) {
        setMarginTopState(110 + searchTitleHeight);
      } else if (window.innerWidth > 850 && marginTopState !== 200 + searchTitleHeight && hasAttentionAtCurrentTime) {
        setMarginTopState(200 + searchTitleHeight);
      } else if (window.innerWidth <= 850 && marginTopState !== 140 + searchTitleHeight && !hasAttentionAtCurrentTime) {
        setMarginTopState(140 + searchTitleHeight);
      } else if (window.innerWidth > 850 && marginTopState !== 230 + searchTitleHeight && !hasAttentionAtCurrentTime) {
        setMarginTopState(230 + searchTitleHeight);
      }
    };

    changeMarginTop();
    window.addEventListener("resize", changeMarginTop);
    return function () {
      window.removeEventListener("resize", changeMarginTop);
    };
  }, []);
  return React.createElement(React.Fragment, null, React.createElement(Footer, null), React.createElement(MainMenu, {
    goback: false,
    search: true,
    cart: true
  }), React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(SearchHeaderDesktop, {
    home: true
  })), React.createElement("div", {
    className: "categories-page"
  }, React.createElement("div", {
    className: "category"
  }, React.createElement("div", {
    className: "container",
    style: {
      marginTop: marginTopState
    }
  }, React.createElement("div", {
    className: "container-categoryHome"
  }, React.createElement(ViewHomeSaleProducts, null), React.createElement(ViewHomeProducts, null))))));
};

export default View;