import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { useSaleProduct } from "./useSaleProduct";
export var useHomeProductsSaleData = function useHomeProductsSaleData(homeSaleProductsData, items) {
  var _homeSaleProductsData;

  var saleProducts = [];
  homeSaleProductsData === null || homeSaleProductsData === void 0 ? void 0 : (_homeSaleProductsData = homeSaleProductsData.homeSaleProducts) === null || _homeSaleProductsData === void 0 ? void 0 : _homeSaleProductsData.forEach(function (productVariant) {
    var sumItems = items === null || items === void 0 ? void 0 : items.filter(function (itemsCart) {
      var _itemsCart$variant;

      return (itemsCart === null || itemsCart === void 0 ? void 0 : (_itemsCart$variant = itemsCart.variant) === null || _itemsCart$variant === void 0 ? void 0 : _itemsCart$variant.id) === productVariant.id;
    }).map(function (item) {
      return item.quantity;
    }).reduce(function (a, b) {
      return a + b;
    }, 0);
    var itemsQuantity = sumItems > 0 ? sumItems : null;

    var _useSaleProduct = useSaleProduct(productVariant),
        discountPercentage = _useSaleProduct.discountPercentage,
        discountValue = _useSaleProduct.discountValue;

    if (saleProducts.length === 0) {
      var _saleProducts, _productVariant$categ;

      (_saleProducts = saleProducts) === null || _saleProducts === void 0 ? void 0 : _saleProducts.push({
        category: {
          id: productVariant === null || productVariant === void 0 ? void 0 : productVariant.categoryIdLightLoad,
          name: (productVariant === null || productVariant === void 0 ? void 0 : (_productVariant$categ = productVariant.categoryOrderTradeLightLoad[0]) === null || _productVariant$categ === void 0 ? void 0 : _productVariant$categ.name) || (productVariant === null || productVariant === void 0 ? void 0 : productVariant.categoryNameLightLoad)
        },
        subCategory: {
          id: productVariant === null || productVariant === void 0 ? void 0 : productVariant.subCategoryIdLightLoad,
          name: productVariant === null || productVariant === void 0 ? void 0 : productVariant.subCategoryNameLightLoad
        },
        id: productVariant === null || productVariant === void 0 ? void 0 : productVariant.id,
        orderIndex: (productVariant === null || productVariant === void 0 ? void 0 : productVariant.orderIndexSubCategoryLightLoad) || 1000000000,
        name: (productVariant === null || productVariant === void 0 ? void 0 : productVariant.name) || (productVariant === null || productVariant === void 0 ? void 0 : productVariant.productNameLightLoad),
        price: productVariant === null || productVariant === void 0 ? void 0 : productVariant.priceLightLoad,
        discountPercentage: discountPercentage,
        discountValue: discountValue,
        itemsQuantity: itemsQuantity,
        thumbnail: productVariant === null || productVariant === void 0 ? void 0 : productVariant.thumbnailLightLoad,
        __typename: "Product"
      });
    } else {
      var tempProducts = saleProducts;
      saleProducts = [].concat(_toConsumableArray(tempProducts), [{
        category: {
          id: productVariant === null || productVariant === void 0 ? void 0 : productVariant.categoryIdLightLoad,
          name: productVariant === null || productVariant === void 0 ? void 0 : productVariant.categoryNameLightLoad
        },
        subCategory: {
          id: productVariant === null || productVariant === void 0 ? void 0 : productVariant.subCategoryIdLightLoad,
          name: productVariant === null || productVariant === void 0 ? void 0 : productVariant.subCategoryNameLightLoad
        },
        id: productVariant === null || productVariant === void 0 ? void 0 : productVariant.id,
        orderIndex: (productVariant === null || productVariant === void 0 ? void 0 : productVariant.orderIndexSubCategoryLightLoad) || 1000000000,
        name: (productVariant === null || productVariant === void 0 ? void 0 : productVariant.name) || (productVariant === null || productVariant === void 0 ? void 0 : productVariant.productNameLightLoad),
        price: productVariant === null || productVariant === void 0 ? void 0 : productVariant.priceLightLoad,
        discountPercentage: discountPercentage,
        discountValue: discountValue,
        itemsQuantity: itemsQuantity,
        thumbnail: productVariant === null || productVariant === void 0 ? void 0 : productVariant.thumbnailLightLoad,
        __typename: "Product"
      }]);
    }
  });
  saleProducts.sort(function (a, b) {
    return a.orderIndex - b.orderIndex;
  });
  return {
    saleProducts: saleProducts
  };
};