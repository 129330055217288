import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n  margin-top: auto;\n  padding: 0 1rem;\n  margin-bottom: 1rem;\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  padding: 1rem 1.25rem;\n  // @media (min-width: 850px) {\n  //   padding: 0;\n  // }\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  border-bottom: 2px solid ", ";\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n        color: ", ";\n        align-items: center;\n        justify-content: center;\n        :hover {\n          cursor: pointer;\n          color: ", ";\n          background-color: ", ";\n          svg path {\n            fill: ", ";\n          }\n        }\n      "]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n        :hover {\n          cursor: pointer;\n          border-color: ", ";\n        }\n      "]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  // background-color: ", ";\n  height: 100%;\n  padding: 0;\n  overflow: auto;\n  background-color: #EFEFF2;\n  border: 1px transparent solid;\n  border-radius: 8px;\n  transition: all 0.3s, color 0s, fill 0s;\n\n  display: flex;\n  flex-direction: column;\n  align-items: left;\n  ", ";\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 0.5rem;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 0.3rem;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n        width: 150px;\n        height: 50px;\n        margin-left: auto;\n        margin-bottom: 20px;\n        overflow: hidden;\n        background: ", ";\n        color: ", ";\n        align-items: center;\n        justify-content: center;\n        border-radius: 10px;\n        div {\n          width: 100%;\n          justify-content: space-around;\n          flex-direction: row;\n          text-transform: capitalize;\n        }\n        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.3);\n        :hover {\n          cursor: pointer;\n          color: ", ";\n          background-color: ", ";\n          svg path {\n            fill: ", ";\n          }\n        }\n        @media (min-width: 850px) {\n          position: relative;\n        }\n      "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n        width: 250px;\n        height: 50px;\n        position: fixed;\n        bottom: 10px;\n        left: calc(50% - 125px);\n        overflow: hidden;\n        background: ", ";\n        color: ", ";\n        align-items: center;\n        justify-content: center;\n        border-radius: 10px;\n        div {\n          width: 100%;\n          justify-content: space-around;\n          align-items: center;\n          flex-direction: row;\n          text-transform: capitalize;\n        }\n        :hover {\n          cursor: pointer;\n          color: ", ";\n          background-color: ", ";\n          svg path {\n            fill: ", ";\n          }\n        }\n        @media (min-width: 850px) {\n          position: relative;\n          left: calc(50% - 110px);\n        }\n      "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n        :hover {\n          cursor: pointer;\n          border-color: ", ";\n        }\n      "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  border: 1px transparent solid;\n  height: 100%;\n  padding: 0;\n  transition: all 0.3s, color 0s, fill 0s;\n  display: flex;\n  flex-direction: column;\n  align-items: left;\n  ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
import { css } from "styled-components";
export var Wrapper = styled.div(_templateObject(), function (props) {
  if (props.tileType === "hover") {
    return css(_templateObject2(), props.theme.tile.hoverBorder);
  }

  if (props.tileType === "addNew") {
    return css(_templateObject3(), props.theme.colors.secondary, props.theme.colors.white, props.theme.colors.white, props.theme.colors.secondary, props.theme.colors.white);
  }

  if (props.tileType === "addNewCheckout") {
    return css(_templateObject4(), props.theme.colors.white, props.theme.colors.secondary, props.theme.colors.white, props.theme.colors.secondary, props.theme.colors.white);
  }
});
Wrapper.displayName = "Tile";
export var WrapperAddressAll = styled.div(_templateObject5());
export var HeaderTitle = styled.div(_templateObject6());
export var WrapperAddress = styled.div(_templateObject7(), function (props) {
  return props.theme.tile.backgroundColor;
}, function (props) {
  if (props.tileType === "hover") {
    return css(_templateObject8(), props.theme.tile.hoverBorder);
  }

  if (props.tileType === "addNew") {
    return css(_templateObject9(), props.theme.colors.secondary, props.theme.colors.white, props.theme.colors.secondary, props.theme.colors.white);
  }
});
WrapperAddress.displayName = "Tile";
export var Header = styled.div(_templateObject10(), function (props) {
  return props.theme.tile.divisionLine;
});
export var Content = styled.div(_templateObject11());
export var Footer = styled.div(_templateObject12());