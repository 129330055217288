import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import classNames from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";
import ReactSVG from "react-svg";
import "./scss/index.scss";
import imageEasyPedido from "../../images/logo.svg";

var closeWhite = require("../../images/x.svg");

var SideBar = function SideBar(props) {
  var _user$currentTrade, _user$currentTrade2;

  var menuItems = props.menuItems,
      user = props.user,
      onHide = props.onHide;
  var navigate = useNavigate();
  var indexActiveMenuItem = menuItems.findIndex(function (menu) {
    return location.pathname.includes(menu === null || menu === void 0 ? void 0 : menu.ariaLabel);
  });

  var _React$useState = React.useState({
    ariaLabel: indexActiveMenuItem === -1 ? menuItems[0].ariaLabel : menuItems[indexActiveMenuItem].ariaLabel
  }),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      activeMenu = _React$useState2[0],
      setActiveMenu = _React$useState2[1];

  return React.createElement(React.Fragment, null, React.createElement("aside", {
    className: "containerSideBar"
  }, React.createElement("div", {
    className: "contentsSideBar"
  }, React.createElement("div", {
    className: "bodySideBar"
  }, React.createElement("div", {
    className: (user === null || user === void 0 ? void 0 : (_user$currentTrade = user.currentTrade) === null || _user$currentTrade === void 0 ? void 0 : _user$currentTrade.logoImage) || (user === null || user === void 0 ? void 0 : (_user$currentTrade2 = user.currentTrade) === null || _user$currentTrade2 === void 0 ? void 0 : _user$currentTrade2.logoImageMainTrade) ? "userLogoSideBar" : "userLogoEasySideBar"
  }, React.createElement("div", {
    className: "closeSideBarWithoutLogo",
    onClick: onHide
  }, React.createElement(ReactSVG, {
    path: closeWhite,
    onClick: onHide
  })), React.createElement("img", {
    style: {
      borderRadius: "0%",
      height: 60,
      marginBottom: 20,
      objectFit: "contain",
      width: 60
    },
    src: imageEasyPedido,
    alt: "logo"
  }))), React.createElement("div", {
    className: "menuItems"
  }, menuItems === null || menuItems === void 0 ? void 0 : menuItems.map(function (item) {
    var _item$children, _item$children2;

    return React.createElement(React.Fragment, {
      key: item.ariaLabel
    }, React.createElement("div", {
      className: classNames("menuItem", {
        menuItemActive: activeMenu.ariaLabel === item.ariaLabel || location.pathname.includes(item.ariaLabel),
        menuItemWithOutChildren: (item === null || item === void 0 ? void 0 : (_item$children = item.children) === null || _item$children === void 0 ? void 0 : _item$children.length) === 0 || !item.children,
        menuItemWithChildren: ((_item$children2 = item.children) === null || _item$children2 === void 0 ? void 0 : _item$children2.length) > 0
      }),
      onClick: function onClick() {
        if (item.url !== "") {
          navigate(item.url);
          onHide();
        }

        setActiveMenu({
          ariaLabel: item.ariaLabel
        });
      }
    }, React.createElement("div", {
      className: "menuIcon"
    }, React.createElement("div", {
      className: "menuIconContainerFirst"
    }, React.createElement(ReactSVG, {
      path: item.icon
    }))), React.createElement("p", {
      className: "menuLabel"
    }, item.label), item.iconInactive && item.ariaLabel !== activeMenu.ariaLabel ? React.createElement("div", {
      className: "menuIcon"
    }, React.createElement("div", {
      className: "menuIconContainer"
    }, React.createElement(ReactSVG, {
      path: item.iconInactive
    }))) : item.iconActive && item.ariaLabel === activeMenu.ariaLabel && React.createElement("div", {
      className: "menuIcon"
    }, React.createElement("div", {
      className: "menuIconContainer"
    }, React.createElement(ReactSVG, {
      path: item.iconActive
    })))), item.ariaLabel === activeMenu.ariaLabel && item.children && React.createElement("div", {
      className: classNames("subMenuItems", {
        subMenuItemsActive: location.pathname.includes(item.ariaLabel)
      })
    }, item.children.map(function (child) {
      return React.createElement("div", {
        key: child.ariaLabel,
        className: classNames("subMenuItem", {
          subMenuItemActive: location.pathname.includes(child.ariaLabel)
        }),
        onClick: function onClick() {
          if (child.url) {
            navigate(child.url);
            onHide();
          }
        }
      }, React.createElement("div", {
        className: "menuIcon"
      }, React.createElement("div", {
        className: "menuIconContainer"
      }, React.createElement(ReactSVG, {
        path: child.icon
      }))), React.createElement("p", {
        className: "menuLabel"
      }, child.label));
    })));
  })))));
};

SideBar.displayName = "SideBar";
export default SideBar;