import * as React from "react";
import { ChangeAmountProductsContext } from "@temp/contexts";
import { useAppSelector } from "@temp/redux";
import { TypedHomeProductsList } from "./queries";
import HomeContentProducts from "./HomeContentProducts";

var ViewHomeProducts = function ViewHomeProducts() {
  var _useAppSelector = useAppSelector(function (store) {
    return store;
  }),
      searchQueryValue = _useAppSelector.search.searchQueryValue;

  var _React$useContext = React.useContext(ChangeAmountProductsContext),
      maxProductsPerCategory = _React$useContext.maxProductsPerCategory;

  var fetchPolicyValue = localStorage.getItem("home_cache") === "true" ? "cache-first" : "cache-and-network";
  return React.createElement(TypedHomeProductsList, {
    fetchPolicy: "".concat(fetchPolicyValue),
    variables: {
      amountByScreen: maxProductsPerCategory + 1,
      search: searchQueryValue
    }
  }, function (_ref) {
    var data = _ref.data,
        loading = _ref.loading;
    return React.createElement(HomeContentProducts, {
      homeProductsData: data,
      loadingHomeProducts: loading
    });
  });
};

export default ViewHomeProducts;