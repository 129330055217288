import "./scss/index.scss";
import React from "react";
import ReactSVG from "react-svg";
import { FormattedMessage } from "react-intl";
import { animated, useSpring } from "react-spring";
import { Button } from "@components/atoms";

var closeWhite = require("../../images/closewhite.svg");

export var AddressCheckout = function AddressCheckout(_ref) {
  var hideModal = _ref.hideModal,
      openEdit = _ref.openEdit,
      selectData = _ref.selectData,
      address = _ref.address;
  var scaleButton = useSpring({
    config: {
      friction: 40,
      mass: 5,
      tension: 350
    },
    from: {
      scale: 1.1
    },
    loop: true,
    to: {
      scale: 1
    }
  });
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "showContainer",
    onClick: hideModal
  }), React.createElement("div", {
    className: "showContainer__data"
  }, React.createElement("div", {
    className: "showContainer__data__close"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.AddressCheckout.index.3715138468",
    defaultMessage: "Address to send"
  })), React.createElement(ReactSVG, {
    path: closeWhite,
    onClick: hideModal
  })), React.createElement("div", {
    className: "showContainer__data__content showContainer__data__content--first"
  }, address.address.firstName && React.createElement(React.Fragment, null, React.createElement("span", null, " ", address.address.firstName))), React.createElement("div", {
    className: "showContainer__data__content"
  }, address.address.streetAddress1 && React.createElement(React.Fragment, null, React.createElement(FormattedMessage, {
    id: "components.AddressCheckout.index.359810770",
    defaultMessage: "Address"
  }), ":", React.createElement("span", null, " ", address.address.streetAddress1), React.createElement("span", null, ", ", " ", address.address.streetAddress2))), React.createElement("div", {
    className: "showContainer__data__content"
  }, React.createElement(FormattedMessage, {
    id: "components.AddressCheckout.index.1024405385",
    defaultMessage: "Neighborhood"
  }), ":", address.address.neighborhoodSelect !== undefined ? React.createElement("span", null, " ", address.address.neighborhoodSelect.name) : React.createElement("span", null, " ", address.address.neighborhood.name)), React.createElement("div", {
    className: "showContainer__data__content showContainer__data__content--last"
  }, address.address.lastName && React.createElement(React.Fragment, null, React.createElement(FormattedMessage, {
    id: "components.AddressCheckout.index.722592990",
    defaultMessage: "Observations"
  }), ":", React.createElement("span", null, " ", address.address.lastName))), React.createElement("div", {
    className: "showContainer__data__footer"
  }, React.createElement(Button, {
    type: "button",
    testingContext: "",
    onClick: openEdit
  }, React.createElement(FormattedMessage, {
    id: "components.AddressCheckout.index.197077183",
    defaultMessage: "Edit"
  })), React.createElement(animated.button, {
    className: "buttonCheckAddress",
    onClick: selectData,
    style: scaleButton
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.AddressCheckout.index.3640052215",
    defaultMessage: "Continue"
  }))))));
};
export default AddressCheckout;