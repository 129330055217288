import React from "react";
import { FormattedMessage } from "react-intl";
import ReactSVG from "react-svg";
import Media from "react-responsive";
import { xMediumScreen } from "@styles/constants";
import { CachedImage, StatusData, StatusDataFooter } from "@components/molecules"; // import { Checkbox, ErrorMessage } from "@components/atoms";

import { statusMessages } from "@temp/intl"; // import { LuzattAcceptanceField } from "../../../../components";

import { maybe } from "../../../../core/utils";
import * as S from "./styles";

var homeSendingImg = require("../../../../images/casa.svg");

var loading = require("../../../../images/moto.gif");

var logoShopImg = require("../../../../images/shopWithOutLogo.svg");

var goBackImg = require("../../../../images/arrowBack.svg");

/**
 * sent status structure
 */
var StatusSent = function StatusSent(_ref) {
  var _data$trade, _data$lastFulfillment, _data$lastFulfillment2;

  var data = _ref.data,
      goBack = _ref.goBack,
      navigation = _ref.navigation;
  return React.createElement(S.Wrapper, null, React.createElement(S.Tile, null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(S.Title, null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(S.BackTitle, {
    onClick: goBack
  }, React.createElement(ReactSVG, {
    path: goBackImg
  }), React.createElement(S.BackTitleParragraph, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.StatusSent.StatusSent.3471378517",
    defaultMessage: "back"
  })))), React.createElement(FormattedMessage, statusMessages.stepNameSending)))), React.createElement(S.Content, null, React.createElement(Media, {
    maxWidth: xMediumScreen
  }, React.createElement(S.Navigation, null, navigation)), React.createElement(Media, {
    maxWidth: xMediumScreen
  }, React.createElement(S.Title, null, React.createElement(FormattedMessage, statusMessages.stepNameSending))), React.createElement(S.ContentContainer, null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(S.Navigation, null, navigation)), React.createElement(S.Images, null, React.createElement(S.Image, null, (data === null || data === void 0 ? void 0 : (_data$trade = data.trade) === null || _data$trade === void 0 ? void 0 : _data$trade.logoImage) ? React.createElement(S.ImageShop, null, React.createElement(CachedImage, {
    url: maybe(function () {
      var _data$trade2, _data$trade2$logoImag;

      return data === null || data === void 0 ? void 0 : (_data$trade2 = data.trade) === null || _data$trade2 === void 0 ? void 0 : (_data$trade2$logoImag = _data$trade2.logoImage) === null || _data$trade2$logoImag === void 0 ? void 0 : _data$trade2$logoImag.url;
    }),
    alt: maybe(function () {
      var _data$trade3, _data$trade3$logoImag, _data$trade4, _data$trade4$logoImag;

      return (data === null || data === void 0 ? void 0 : (_data$trade3 = data.trade) === null || _data$trade3 === void 0 ? void 0 : (_data$trade3$logoImag = _data$trade3.logoImage) === null || _data$trade3$logoImag === void 0 ? void 0 : _data$trade3$logoImag.alt) ? data === null || data === void 0 ? void 0 : (_data$trade4 = data.trade) === null || _data$trade4 === void 0 ? void 0 : (_data$trade4$logoImag = _data$trade4.logoImage) === null || _data$trade4$logoImag === void 0 ? void 0 : _data$trade4$logoImag.alt : "";
    }, "")
  }, React.createElement(React.Fragment, null))) : React.createElement(ReactSVG, {
    path: logoShopImg
  })), React.createElement(S.Image, null, React.createElement(S.Gif, null, React.createElement("img", {
    alt: "cargando",
    src: loading
  }))), React.createElement(S.ImageHome, null, React.createElement(ReactSVG, {
    path: homeSendingImg
  }))), React.createElement(S.Parragraph, {
    "data-test": "checkoutPageSubtitle"
  }, React.createElement(FormattedMessage, statusMessages.stepNameSendingWaiting)), (data === null || data === void 0 ? void 0 : (_data$lastFulfillment = data.lastFulfillment) === null || _data$lastFulfillment === void 0 ? void 0 : (_data$lastFulfillment2 = _data$lastFulfillment.deliveryman) === null || _data$lastFulfillment2 === void 0 ? void 0 : _data$lastFulfillment2.phoneNumber) && React.createElement(Media, {
    minWidth: xMediumScreen
  }, function (matches) {
    var _data$lastFulfillment3, _data$lastFulfillment4;

    return matches && React.createElement(S.Delivery, null, React.createElement(S.DeliveryTile, null, React.createElement(FormattedMessage, {
      id: "@next.components.organisms.StatusSent.StatusSent.546197434",
      defaultMessage: "delivery man number"
    })), React.createElement(S.DeliveryNumber, null, data === null || data === void 0 ? void 0 : (_data$lastFulfillment3 = data.lastFulfillment) === null || _data$lastFulfillment3 === void 0 ? void 0 : (_data$lastFulfillment4 = _data$lastFulfillment3.deliveryman) === null || _data$lastFulfillment4 === void 0 ? void 0 : _data$lastFulfillment4.phoneNumber));
  }))), React.createElement(S.Aside, null, React.createElement(StatusData, {
    deliveryPhone: true,
    data: data,
    deliveryData: true
  })), React.createElement(S.Footer, null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(StatusDataFooter, {
    data: data
  }))));
};

export { StatusSent };