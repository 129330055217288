import _objectDestructuringEmpty from "@babel/runtime/helpers/objectDestructuringEmpty";
import React from "react";
import Media from "react-responsive";
import { xLargeScreen } from "@styles/constants";
import * as S from "./styles";
export var OrderTabelDeliverySkeleton = function OrderTabelDeliverySkeleton(_ref) {
  _objectDestructuringEmpty(_ref);

  return React.createElement(Media, {
    maxWidth: xLargeScreen
  }, function (matches) {
    return matches ? React.createElement(React.Fragment, null, React.createElement(S.Row, {
      "data-test": "orderEntry"
    }, React.createElement(S.Image, null, React.createElement(S.Skeleton, null)), React.createElement(S.OrderData, null, React.createElement(S.OrderFirstLine, null, React.createElement(S.OrderNumber, null, React.createElement(S.Skeleton, null)), React.createElement(S.OrderStatus, null, React.createElement(S.Skeleton, null))), React.createElement(S.OrderTrade, null, React.createElement(S.Skeleton, null))), React.createElement(S.OrderGlobalDate, null, React.createElement(S.OrderDate, null, React.createElement(S.Skeleton, null)), React.createElement(S.OrderDate, null, React.createElement(S.Skeleton, null))), React.createElement(S.OrderCustomerData, null, React.createElement(S.OrderCustomerDataParragraph, null, React.createElement(S.Skeleton, null)), React.createElement(S.OrderCustomerDataParragraph, null, React.createElement(S.Skeleton, null))), React.createElement(S.OrderStatusDetail, null, React.createElement(S.Skeleton, null)))) : React.createElement(S.RowGrather, {
      "data-test": "orderEntry"
    }, React.createElement(S.ImageGrather, null, React.createElement(S.SkeletonGrather, null)), React.createElement(S.OrderTradeGrather, null, React.createElement(S.SkeletonGrather, null)), React.createElement(S.OrderNumberGrather, null, React.createElement(S.SkeletonGrather, null)), React.createElement(S.OrderProductsGrather, null, React.createElement(S.SkeletonGrather, null)), React.createElement(S.OrderStatusParragraphGrather, null, React.createElement(S.SkeletonGrather, null)), React.createElement(S.OrderDateGrather, null, React.createElement(S.SkeletonGrather, null)), React.createElement(S.OrderDateGrather, null, React.createElement(S.SkeletonGrather, null)), React.createElement(S.OrderStatusDetailGrather, null, React.createElement(S.SkeletonGrather, null)));
  });
};
OrderTabelDeliverySkeleton.displayName = "OrderTabelDeliverySkeleton";