import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  width: 25px;\n  text-align: center;\n  color: #ed1c24;\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  width: 19px;\n  height: 19px;\n  appearance: none;\n  text-align: center;\n  border: 1px solid grey;\n  border-radius: 2px;\n  cursor: pointer;\n  outline: none;\n\n  &:checked {\n    position: relative;\n    background-color: #1475f6;\n  }\n\n  &:checked:after {\n    content: \"\";\n    width: 2px;\n    height: 14px;\n    display: block;\n    position: absolute;\n    right: 4px;\n    top: 1px;\n    background-color: white;\n    transform: rotate(40deg);\n  }\n\n  &:checked:before {\n    content: \"\";\n    width: 9px;\n    height: 2px;\n    display: block;\n    position: absolute;\n    top: 10px;\n    background-color: white;\n    transform: rotate(45deg);\n  }\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  width: 25px;\n  text-align: center;\n  color: #ed1c24;\n  cursor: pointer;\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  width: 90px;\n  margin-right: 10px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  background: white;\n  border-radius: 10px;\n  box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 10%);\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  font-size: 16px;\n  font-weight: bold;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  padding-left: 10px;\n  padding-right: 10px;\n  font-size: 14px;\n  font-weight: lighter;\n  color: grey;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  font-size: 15px;\n  font-weight: lighter;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  margin-left: 10px;\n  display: flex;\n  flex-direction: column;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  width: 90%;\n  height: auto;\n  margin: 0 auto;\n  padding: 5px;\n  display: grid;\n  grid-template-columns: 91% 9%;\n  align-items: center;\n  border-bottom: 1px dotted grey;\n  ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  animation: ", " 2s;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  0% {\n    background: rgba(255, 203, 203, 0.0);\n  }\n  10% {\n    background: rgba(255, 203, 203, 0.2);\n  }\n  20% {\n    background: rgba(255, 203, 203, 0.4);\n  }\n  30% {\n    background: rgba(255, 203, 203, 0.6);\n  }\n  40% {\n    background: rgba(255, 203, 203, 0.8);\n  }\n  50% {\n    background: rgba(255, 203, 203, 1);\n  }\n  60% {\n    background: rgba(255, 203, 203, 0.8);\n  }\n  70% {\n    background: rgba(255, 203, 203, 0.6);\n  }\n  80% {\n    background: rgba(255, 203, 203, 0.4);\n  }\n  90% {\n    background: rgba(255, 203, 203, 0.2);\n  }\n  100% {\n    background: rgba(255, 203, 203, 0.0);\n  }"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
import { css, keyframes } from "styled-components";
var hunchAnimation = keyframes(_templateObject());
var animationCssHelper = css(_templateObject2(), hunchAnimation);
export var Value = styled.div(_templateObject3(), function (props) {
  return props.light ? animationCssHelper : "animation: ''";
});
export var ValueNamePrice = styled.div(_templateObject4());
export var ValueParragraph = styled.label(_templateObject5());
export var ValueDescription = styled.label(_templateObject6());
export var ValuePrice = styled.label(_templateObject7());
export var ValueAddition = styled.div(_templateObject8());
export var ValueLess = styled.p(_templateObject9());
export var ValueInput = styled.input(_templateObject10());
export var ValueMore = styled.p(_templateObject11());