import "./scss/index.scss";
import { ProductDescription } from "@components/molecules";
import React from "react";
export var ProductDescriptionGlobal = function ProductDescriptionGlobal(_ref) {
  var loadingNetwork = _ref.loadingNetwork,
      dataNetwork = _ref.dataNetwork,
      productDescriptionCache = _ref.productDescriptionCache;
  return !productDescriptionCache && loadingNetwork ? React.createElement("div", {
    className: "containerDescription"
  }, React.createElement("div", {
    className: "containerDescription__description"
  }, React.createElement("div", {
    className: "skeleton-placeholder"
  }))) : (productDescriptionCache || (dataNetwork === null || dataNetwork === void 0 ? void 0 : dataNetwork.description) || (dataNetwork === null || dataNetwork === void 0 ? void 0 : dataNetwork.productDescription)) && !loadingNetwork ? React.createElement("div", {
    className: "containerDescription"
  }, React.createElement("div", {
    className: "containerDescription__description"
  }, React.createElement(ProductDescription, {
    description: productDescriptionCache || (dataNetwork === null || dataNetwork === void 0 ? void 0 : dataNetwork.description) || (dataNetwork === null || dataNetwork === void 0 ? void 0 : dataNetwork.productDescription)
  }))) : React.createElement(React.Fragment, null);
};