import * as React from "react";
import { RichTextContent } from "@components/atoms";
export var Page = function Page(_ref) {
  var page = _ref.page;
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "epTerms-article-page__header",
    style: null
  }, React.createElement("span", {
    className: "epTerms-article-page__header__title"
  }, React.createElement("h1", null, page.title))), React.createElement("div", {
    className: "epTerms-article-page"
  }, React.createElement("div", {
    className: "container"
  }, React.createElement("div", {
    className: "epTerms-article-page__container"
  }, React.createElement("div", {
    className: "epTerms-article-page__content"
  }, React.createElement(RichTextContent, {
    descriptionJson: page.contentJson
  }))))));
};
export default Page;