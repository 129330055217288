import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import * as React from "react";
import { ChangeAmountProductsContext } from "./context";
export var ChangeAmountProductsProvider = function ChangeAmountProductsProvider(_ref) {
  var children = _ref.children;

  var _React$useState = React.useState(7),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      maxProductsPerCategory = _React$useState2[0],
      setMaxProductsPerCategory = _React$useState2[1];

  var changeAmountProducts = React.useCallback(function () {
    if (window.innerWidth <= 412 && maxProductsPerCategory !== 2) {
      setMaxProductsPerCategory(2);
    } else if (window.innerWidth > 412 && window.innerWidth <= 554 && maxProductsPerCategory !== 3) {
      setMaxProductsPerCategory(3);
    } else if (window.innerWidth > 554 && window.innerWidth <= 666 && maxProductsPerCategory !== 4) {
      setMaxProductsPerCategory(4);
    } else if (window.innerWidth > 666 && window.innerWidth <= 792 && maxProductsPerCategory !== 5) {
      setMaxProductsPerCategory(5);
    } else if (window.innerWidth > 792 && window.innerWidth <= 849 && maxProductsPerCategory !== 6) {
      setMaxProductsPerCategory(6);
    } else if (window.innerWidth > 849 && window.innerWidth <= 952 && maxProductsPerCategory !== 3) {
      setMaxProductsPerCategory(3);
    } else if (window.innerWidth > 952 && window.innerWidth <= 1033 && maxProductsPerCategory !== 4) {
      setMaxProductsPerCategory(4);
    } else if (window.innerWidth > 1033 && window.innerWidth <= 1245 && maxProductsPerCategory !== 5) {
      setMaxProductsPerCategory(5);
    } else if (window.innerWidth > 1245 && window.innerWidth <= 1370 && maxProductsPerCategory !== 6) {
      setMaxProductsPerCategory(6);
    } else if (window.innerWidth > 1370 && window.innerWidth <= 1500 && maxProductsPerCategory !== 7) {
      setMaxProductsPerCategory(7);
    }
  }, []);
  React.useEffect(function () {
    window.addEventListener("resize", changeAmountProducts);
    return function () {
      window.removeEventListener("resize", changeAmountProducts);
    };
  }, []);
  React.useEffect(function () {
    if (window.location.pathname === "/") {
      localStorage.removeItem("scroll-categories");
      localStorage.removeItem("scroll-category");
      localStorage.removeItem("scroll-home");
      localStorage.removeItem("scroll-product");
      localStorage.removeItem("scroll-sale");
    }

    localStorage.removeItem("home_cache");
    localStorage.removeItem("sale_cache");
    localStorage.removeItem("products_cache");
    localStorage.removeItem("category_sale_cache");
    localStorage.removeItem("category_products_cache");
    localStorage.removeItem("subcategory_products_cache");
    changeAmountProducts();
    return function () {};
  }, []);
  return React.createElement(ChangeAmountProductsContext.Provider, {
    value: {
      maxProductsPerCategory: maxProductsPerCategory
    }
  }, children);
};