import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject14() {
  var data = _taggedTemplateLiteral(["\n  color: #21125e;\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  color: #f76268;\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n  width: 80%;\n  display: block;\n  padding: 15px 10px;\n  margin-top: 20px;\n  text-align: center;\n  border-radius: 8px;\n  background: #efeff2;\n  color: #5ed575;\n  font-size: 1.3rem;\n  div {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    span {\n      font-size: 1.3rem;\n    }\n  }\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 30px;\n  display: grid;\n  grid-template-columns: 20% 80%;\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  margin-bottom: 70px;\n  > div:first-of-type {\n    display: none;\n  }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  color: grey;\n  text-align: center;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  width: 80%;\n  display: block;\n  margin: 0 auto;\n  text-align: center;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  svg {\n    width: 50px;\n    height: 50px;\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 40px;\n  display: grid;\n  grid-template-columns: 20% 80%;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  div {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  font-size: 1.5rem;\n  font-weight: ", ";\n  text-align: center;\n  padding: 0 0 0.5rem 0;\n  margin-bottom: 1rem;\n  border-bottom: 1px solid rgba(50, 50, 50, 0.1);\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 20px;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var ShippingMethodForm = styled.form(_templateObject());
export var Title = styled.h3(_templateObject2(), function (props) {
  return props.theme.typography.boldFontWeight;
});
export var Slide = styled.div(_templateObject3());
export var Content = styled.div(_templateObject4());
export var Image = styled.div(_templateObject5());
export var Message = styled.span(_templateObject6());
export var Parragraph = styled.p(_templateObject7());
export var Tile = styled.div(_templateObject8());
export var TileMessage = styled.div(_templateObject9());
export var TileTitleWrapper = styled.div(_templateObject10());
export var TileTitle = styled.span(_templateObject11());
export var TileTitleBox = styled.span(_templateObject12());
export var TileTitleBoxContent = styled.div(_templateObject13());
export var Price = styled.span(_templateObject14());