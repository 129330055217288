import "./scss/index.scss";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "@components/atoms";
export var AlertLeftEpTerms = function AlertLeftEpTerms(_ref) {
  var wantToReadTerms = _ref.wantToReadTerms,
      returnToWa = _ref.returnToWa;
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "showContainer"
  }), React.createElement("div", {
    className: "showContainer__data"
  }, React.createElement("div", {
    className: "showContainer__data__close"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.AlertLeftEpTerms.index.709932324",
    defaultMessage: "Alert"
  }))), React.createElement("div", {
    className: "showContainer__data__content showContainer__data__content--first"
  }), React.createElement("div", {
    className: "showContainer__data__content showContainer__data__content--value showContainer__data__content--first showContainer__data__content--last"
  }, React.createElement(FormattedMessage, {
    id: "components.AlertLeftEpTerms.index.1657462509",
    defaultMessage: "Here, you can read our terms and conditions and also the data protection policy.",
    description: "EpTerms alert"
  })), React.createElement("div", {
    className: "showContainer__data__footer"
  }, React.createElement(Button, {
    type: "button",
    testingContext: "",
    onClick: wantToReadTerms
  }, React.createElement(FormattedMessage, {
    id: "components.AlertLeftEpTerms.index.3457127017",
    defaultMessage: "Read terms"
  })), React.createElement(Button, {
    type: "button",
    testingContext: "",
    onClick: returnToWa
  }, React.createElement(FormattedMessage, {
    id: "components.AlertLeftEpTerms.index.432106173",
    defaultMessage: "Continue in Whatsapp"
  })))));
};
export default AlertLeftEpTerms;