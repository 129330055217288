import "./scss/index.scss";
import * as React from "react";
import { Loader } from "@components/atoms";
import { useAuth, useOrderByTokenLines } from "@saleor/sdk";
import { MainMenu } from "@temp/components";
import { useLocation, useParams } from "react-router-dom";
import Page from "./Page";

var View = function View() {
  var _data$orderByToken3, _data$orderByToken4, _data$orderByToken4$t, _data$orderByToken5, _data$orderByToken5$t, _data$orderByToken6, _data$orderByToken6$t, _data$orderByToken7, _data$orderByToken7$t, _data$orderByToken8, _data$orderByToken8$t, _data$orderByToken9, _data$orderByToken9$t;

  var _useParams = useParams(),
      token = _useParams.token;

  var location = useLocation();

  var _useAuth = useAuth(),
      user = _useAuth.user;

  var guest = !user;

  var _useOrderByTokenLines = useOrderByTokenLines({
    token: token || localStorage.getItem("order_token"),
    hasLines: true,
    hasDeliveryManData: false,
    hasTradeTypes: false
  }),
      data = _useOrderByTokenLines.data,
      loading = _useOrderByTokenLines.loading;

  React.useEffect(function () {
    var _data$orderByToken;

    if (data === null || data === void 0 ? void 0 : (_data$orderByToken = data.orderByToken) === null || _data$orderByToken === void 0 ? void 0 : _data$orderByToken.token) {
      var _data$orderByToken2;

      localStorage.setItem("order_token", data === null || data === void 0 ? void 0 : (_data$orderByToken2 = data.orderByToken) === null || _data$orderByToken2 === void 0 ? void 0 : _data$orderByToken2.token);
    }
  }, [data === null || data === void 0 ? void 0 : (_data$orderByToken3 = data.orderByToken) === null || _data$orderByToken3 === void 0 ? void 0 : _data$orderByToken3.token]);

  if (loading) {
    return React.createElement(Loader, null);
  }

  return React.createElement(React.Fragment, null, location.pathname.includes("delivery") ? React.createElement(MainMenu, {
    goback: true,
    deliveryAccess: true,
    search: false,
    cart: false,
    differentTradeToActual: true,
    tradeLogo: data === null || data === void 0 ? void 0 : (_data$orderByToken4 = data.orderByToken) === null || _data$orderByToken4 === void 0 ? void 0 : (_data$orderByToken4$t = _data$orderByToken4.trade) === null || _data$orderByToken4$t === void 0 ? void 0 : _data$orderByToken4$t.logoImage,
    tradeNumber: data === null || data === void 0 ? void 0 : (_data$orderByToken5 = data.orderByToken) === null || _data$orderByToken5 === void 0 ? void 0 : (_data$orderByToken5$t = _data$orderByToken5.trade) === null || _data$orderByToken5$t === void 0 ? void 0 : _data$orderByToken5$t.phoneNumber,
    tradeName: data === null || data === void 0 ? void 0 : (_data$orderByToken6 = data.orderByToken) === null || _data$orderByToken6 === void 0 ? void 0 : (_data$orderByToken6$t = _data$orderByToken6.trade) === null || _data$orderByToken6$t === void 0 ? void 0 : _data$orderByToken6$t.name
  }) : React.createElement(MainMenu, {
    goback: true,
    search: false,
    cart: false,
    differentTradeToActual: true,
    tradeLogo: data === null || data === void 0 ? void 0 : (_data$orderByToken7 = data.orderByToken) === null || _data$orderByToken7 === void 0 ? void 0 : (_data$orderByToken7$t = _data$orderByToken7.trade) === null || _data$orderByToken7$t === void 0 ? void 0 : _data$orderByToken7$t.logoImage,
    tradeNumber: data === null || data === void 0 ? void 0 : (_data$orderByToken8 = data.orderByToken) === null || _data$orderByToken8 === void 0 ? void 0 : (_data$orderByToken8$t = _data$orderByToken8.trade) === null || _data$orderByToken8$t === void 0 ? void 0 : _data$orderByToken8$t.phoneNumber,
    tradeName: data === null || data === void 0 ? void 0 : (_data$orderByToken9 = data.orderByToken) === null || _data$orderByToken9 === void 0 ? void 0 : (_data$orderByToken9$t = _data$orderByToken9.trade) === null || _data$orderByToken9$t === void 0 ? void 0 : _data$orderByToken9$t.name
  }), React.createElement("div", {
    className: "order-details"
  }, React.createElement(Page, {
    guest: guest,
    order: data === null || data === void 0 ? void 0 : data.orderByToken
  })));
};

export default View;