import "./scss/index.scss";
import React from "react";
import { ChangeAmountProductsContext } from "@temp/contexts";
export var SkeletonProduct = function SkeletonProduct() {
  var _React$useContext = React.useContext(ChangeAmountProductsContext),
      maxProductsPerCategory = _React$useContext.maxProductsPerCategory;

  return React.createElement(React.Fragment, null, new Array(maxProductsPerCategory - 1).fill(0).map(function (_, index) {
    return React.createElement("section", {
      key: index,
      className: "box",
      style: {
        marginLeft: "30px",
        display: "inline-block"
      }
    }, React.createElement("div", {
      className: "product-img"
    }), React.createElement("div", {
      className: "skeleton-placeholder"
    }), React.createElement("div", {
      className: "skeleton-placeholder"
    }));
  }));
};