import "./scss/index.scss";
import React from "react";
import ReactSVG from "react-svg";
import { FormattedMessage } from "react-intl";
import { Button } from "@components/atoms";

var closeWhite = require("../../images/closewhite.svg"); // const greaterThanEqual = require("../../images/greaterThanEqual.svg");


export var AlertShowErrors = function AlertShowErrors(_ref) {
  var message = _ref.message,
      hideModal = _ref.hideModal,
      errors = _ref.errors;
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "showErrors",
    onClick: hideModal
  }), React.createElement("div", {
    className: "showErrors__data"
  }, React.createElement("div", {
    className: "showErrors__data__close"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.AlertShowErrors.index.709932324",
    defaultMessage: "Alert"
  })), React.createElement(ReactSVG, {
    path: closeWhite,
    onClick: hideModal
  })), React.createElement("div", {
    className: "showErrors__data__content showErrors__data__content--first"
  }), React.createElement("div", {
    className: "showErrors__data__content showErrors__data__content--value showErrors__data__content--first showErrors__data__content--last"
  }, React.createElement("ul", null, errors.map(function (error, index) {
    return React.createElement("li", {
      key: index
    }, error === null || error === void 0 ? void 0 : error.message);
  }))), React.createElement("div", {
    className: "showErrors__data__footer"
  }, React.createElement(Button, {
    type: "button",
    testingContext: "",
    onClick: hideModal
  }, React.createElement(FormattedMessage, {
    id: "components.AlertShowErrors.index.3577703060",
    defaultMessage: "Ok"
  })))));
};
export default AlertShowErrors;