import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from "react";
export var useProductVariantsAttributes = function useProductVariantsAttributes(productVariants) {
  var _useState = useState({}),
      _useState2 = _slicedToArray(_useState, 2),
      productVariantsAttributes = _useState2[0],
      setProductVariantsAttributes = _useState2[1];

  useEffect(function () {
    var variantsAttributes = {};
    productVariants.forEach(function (productVariant) {
      productVariant.attributes.forEach(function (productVariantAttribute) {
        var productVariantAttributeId = productVariantAttribute.attribute.id;
        var variantsAttributeExists = variantsAttributes.hasOwnProperty(productVariantAttributeId);

        if (variantsAttributeExists) {
          var variantsAttributeValueExists = variantsAttributes[productVariantAttributeId].values.includes(productVariantAttribute.values[0]);

          if (!variantsAttributeValueExists) {
            variantsAttributes[productVariantAttributeId].values.push(productVariantAttribute.values[0]);
          }
        } else {
          variantsAttributes[productVariantAttributeId] = {
            attribute: productVariantAttribute.attribute,
            values: [productVariantAttribute.values[0]]
          };
        }
      });
    });
    setProductVariantsAttributes(variantsAttributes);
    return function () {
      setProductVariantsAttributes({});
    };
  }, [productVariants]);
  return productVariantsAttributes;
};