import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "./scss/index.scss";
import React, { useState } from "react";
import ReactSVG from "react-svg";
import { FormattedMessage } from "react-intl";
import { Button, Loader } from "@components/atoms";
import * as S from "./styles";

var closeWhite = require("../../images/closewhite.svg"); // const greaterThanEqual = require("../../images/greaterThanEqual.svg");


export var AlertDeleteAddress = function AlertDeleteAddress(_ref) {
  var deleteAddressId = _ref.deleteAddressId,
      setDeleteUserAddress = _ref.setDeleteUserAddress,
      hideModal = _ref.hideModal;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      loading = _useState2[0],
      setLoading = _useState2[1];

  var closeModal = function closeModal() {
    if (!loading) {
      hideModal();
    }
  };

  var removeDelete = function removeDelete() {
    setLoading(true);
    setDeleteUserAddress({
      addressId: deleteAddressId
    });
  };

  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "showContainer",
    onClick: closeModal
  }), React.createElement("div", {
    className: "showContainer__data"
  }, React.createElement("div", {
    className: "showContainer__data__close"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.AlertDeleteAddress.index.1258888549",
    defaultMessage: "Confirmation"
  })), !loading && React.createElement(ReactSVG, {
    path: closeWhite,
    onClick: hideModal
  })), React.createElement("div", {
    className: "showContainer__data__content  showContainer__data__content--amount showContainer__data__content--first showContainer__data__content--last"
  }, React.createElement(FormattedMessage, {
    id: "components.AlertDeleteAddress.index.681135413",
    defaultMessage: "Do you want to delete the address?"
  })), React.createElement("div", {
    className: "showContainer__data__footer"
  }, loading ? React.createElement(S.Loader, null, React.createElement(Loader, null)) : React.createElement(React.Fragment, null, React.createElement(Button, {
    type: "button",
    testingContext: "",
    onClick: closeModal
  }, React.createElement(FormattedMessage, {
    id: "components.AlertDeleteAddress.index.2680335018",
    defaultMessage: "No"
  })), React.createElement(Button, {
    type: "button",
    testingContext: "",
    onClick: removeDelete
  }, React.createElement(FormattedMessage, {
    id: "components.AlertDeleteAddress.index.3308036799",
    defaultMessage: "Yes"
  }))))));
};
export default AlertDeleteAddress;