import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  border-radius: 8px;\n  padding: 0.25rem;\n  :hover {\n    background-color: ", ";\n  }\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 30px;\n  height: 27px;\n  text-align: center;\n  border-radius: 5px;\n  background-color: white;\n  box-shadow: -1px 3px 4px rgba(0, 0, 0, 0.2);\n  cursor: pointer;\n  div {\n    width: 1.3rem;\n    height: 1.3rem;\n    text-align: center;\n    line-height: 1.5rem;\n  }\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 30px;\n  height: 27px;\n  margin-left: auto;\n  text-align: center;\n  border-radius: 5px;\n  background-color: #f76268;\n  cursor: pointer;\n  div {\n    width: 1.3rem;\n    height: 1.3rem;\n    text-align: center;\n    line-height: 1.5rem;\n  }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n\n  // > div {\n  //   display: inline-block;\n  //   padding: 0;\n  //   margin: 0;\n  //   margin-right: 0.6rem;\n  // }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  h2,\n  h3 {\n    font-size: 1rem;\n  }\n  h2 {\n    color: rgb(247, 98, 104);\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  button {\n    padding: 7px 10px;\n    background-color: #ed1c24;\n    color: white;\n    font-weight: bold;\n    border-radius: 8px;\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  height: 100%;\n  font-size: ", ";\n  margin-top: 35px;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  height: 100%;\n  font-size: ", ";\n  margin-top: 60px;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  height: 100%;\n  font-size: ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.typography.smallFontSize;
});
export var WrapperMargin = styled.div(_templateObject2(), function (props) {
  return props.theme.typography.smallFontSize;
});
export var WrapperThirdElement = styled.div(_templateObject3(), function (props) {
  return props.theme.typography.smallFontSize;
});
export var HeaderContent = styled.div(_templateObject4(), function (props) {
  return props.theme.colors.lightFont;
});
export var ButtonDefault = styled.div(_templateObject5());
export var TypeAddress = styled.div(_templateObject6());
export var FooterContent = styled.div(_templateObject7());
export var FooterTrash = styled.div(_templateObject8());
export var FooterEdit = styled.div(_templateObject9());
export var MenuItem = styled.div(_templateObject10(), function (props) {
  return props.theme.colors.primaryLight;
});