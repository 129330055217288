import gql from "graphql-tag";

export const baseSaleFragment = gql`
  fragment BaseSale on DiscountSaleInfo {
    name
    type
    value
  }
`;

export const productsLightLoadFragment = gql`
  ${baseSaleFragment}
  fragment ProductsLightLoad on ProductVariant {
    id
    name
    productIdLightLoad
    productNameLightLoad
    subCategoryIdLightLoad
    subCategoryNameLightLoad
    categoryIdLightLoad
    categoryNameLightLoad
    orderIndexSubCategoryLightLoad
    hasAttributeLightLoad
    productDefaultImageIdLightLoad
    productImagesLightLoad {
      id
      alt
      url
    }
    priceLightLoad {
      amount
      currency
    }
    saleProductsLightLoad {
      ...BaseSale
    }
    saleSubCategoryLightLoad {
      ...BaseSale
    }
    saleCategoryLightLoad {
      ...BaseSale
    }
    thumbnailLightLoad {
      alt
      url
    }
  }
`;
