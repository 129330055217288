import React from "react";
import ReactSVG from "react-svg";
import { Link } from "react-router-dom";
import { ProductTile } from "@components/molecules";
import { generateProductVariantUrl } from "../../../../core/utils";
import * as S from "./styles";
import "./scss/index.scss";

var arrow = require("../../../../images/arrowBack.svg");

export var ProductList = function ProductList(_ref) {
  var otherProducts = _ref.otherProducts,
      handleClickTo = _ref.handleClickTo,
      products = _ref.products,
      seeMore = _ref.seeMore,
      testingContextId = _ref.testingContextId,
      to = _ref.to;
  products.sort(function (a, b) {
    var lesser = (a === null || a === void 0 ? void 0 : a.orderIndex) ? a === null || a === void 0 ? void 0 : a.orderIndex : a === null || a === void 0 ? void 0 : a.id;
    var grather = (b === null || b === void 0 ? void 0 : b.orderIndex) ? b === null || b === void 0 ? void 0 : b.orderIndex : b === null || b === void 0 ? void 0 : b.id;
    return +lesser - +grather;
  });
  return React.createElement(React.Fragment, null, React.createElement(S.List, {
    "data-test": "productList",
    "data-test-id": testingContextId
  }, products === null || products === void 0 ? void 0 : products.map(function (product, index) {
    var id = product.id,
        name = product.name,
        subCategoryId = product.subCategory.id;
    return id && name && React.createElement(Link, {
      className: "link-product",
      onClick: function onClick() {
        localStorage.setItem("scroll-product", window.scrollY.toString());
      },
      to: generateProductVariantUrl(id, name, subCategoryId),
      key: "productList-".concat(id)
    }, React.createElement(ProductTile, {
      product: product,
      otherProducts: otherProducts
    }));
  }), seeMore && to && handleClickTo && React.createElement(Link, {
    className: "seemoreArrow",
    onClick: handleClickTo,
    to: to
  }, React.createElement(ReactSVG, {
    path: arrow
  }))));
};