import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import "./scss/index.scss";
import { AddressGrid } from "@components/organisms";
import { checkoutMessages } from "@temp/intl";
import { AlertDeleteAddress } from "@temp/components";
import { useCheckout, useDefaultUserAddress, useDeleteUserAddresss } from "@saleor/sdk";
import { AddressTypeEnum, AccountErrorCode } from "@saleor/sdk/lib/gqlTypes/globalTypes";
import { getUserDetailsQuery } from "@saleor/sdk/lib/queries/user";

var AddressBook = function AddressBook(_ref) {
  var _user$currentTrade, _user$currentTrade2, _newUserAddresses$, _newUserAddresses$$ci, _user$defaultShipping, _user$defaultShipping2, _newUserAddresses$2, _user$defaultShipping3, _newUserAddresses$3, _newUserAddresses$4, _user$defaultShipping4, _newUserAddresses$5, _user$defaultShipping5, _newUserAddresses$6, _newUserAddresses$6$r, _user$defaultShipping6, _user$defaultShipping7, _newUserAddresses$7, _newUserAddresses$8, _user$defaultShipping8;

  var user = _ref.user;
  var navigate = useNavigate();
  var intl = useIntl();

  var _useCheckout = useCheckout(),
      updateCheckoutFromBD = _useCheckout.updateCheckoutFromBD;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      showAlertDeleteAddress = _useState2[0],
      setShowAlertDeleteAddress = _useState2[1];

  var _useState3 = useState(""),
      _useState4 = _slicedToArray(_useState3, 2),
      idAddressRemove = _useState4[0],
      setIdAddressRemove = _useState4[1]; // const [addressData, setAddressData] = React.useState(null);


  var _React$useState = React.useState([{
    id: intl.formatMessage(checkoutMessages.other),
    name: intl.formatMessage(checkoutMessages.other)
  }, {
    id: intl.formatMessage(checkoutMessages.myHome),
    name: intl.formatMessage(checkoutMessages.myHome)
  }, {
    id: intl.formatMessage(checkoutMessages.myJob),
    name: intl.formatMessage(checkoutMessages.myJob)
  }, {
    id: intl.formatMessage(checkoutMessages.myBoyfriendHouse),
    name: intl.formatMessage(checkoutMessages.myBoyfriendHouse)
  }]),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      nameOptionsSelect = _React$useState2[0],
      setnameOptionsSelect = _React$useState2[1];

  var _useDefaultUserAddres = useDefaultUserAddress(undefined, {
    refetchQueries: function refetchQueries(result) {
      if (result.data.accountSetDefaultAddress.errors.length > 0) {
        if (result.data.accountSetDefaultAddress.errors.find(function (err) {
          return err.code === AccountErrorCode.NOT_FOUND;
        })) {
          return [{
            query: getUserDetailsQuery
          }];
        }
      }
    }
  }),
      _useDefaultUserAddres2 = _slicedToArray(_useDefaultUserAddres, 2),
      setDefaultUserAddress = _useDefaultUserAddres2[0],
      dataDefaultUserAddress = _useDefaultUserAddres2[1];

  var _useDeleteUserAddress = useDeleteUserAddresss(undefined, {
    refetchQueries: function refetchQueries(result) {
      if (result.data.accountAddressDelete.errors.length > 0) {
        if (result.data.accountAddressDelete.errors.find(function (err) {
          return err.code === AccountErrorCode.NOT_FOUND;
        })) {
          return [{
            query: getUserDetailsQuery
          }];
        }
      }
    }
  }),
      _useDeleteUserAddress2 = _slicedToArray(_useDeleteUserAddress, 1),
      setDeleteUserAddress = _useDeleteUserAddress2[0];

  var nameOptions = [{
    id: intl.formatMessage(checkoutMessages.other),
    name: intl.formatMessage(checkoutMessages.other)
  }, {
    id: intl.formatMessage(checkoutMessages.myHome),
    name: intl.formatMessage(checkoutMessages.myHome)
  }, {
    id: intl.formatMessage(checkoutMessages.myJob),
    name: intl.formatMessage(checkoutMessages.myJob)
  }, {
    id: intl.formatMessage(checkoutMessages.myBoyfriendHouse),
    name: intl.formatMessage(checkoutMessages.myBoyfriendHouse)
  }];
  var currentNeighborhood = (_user$currentTrade = user.currentTrade) === null || _user$currentTrade === void 0 ? void 0 : _user$currentTrade.neighborhoodsAttention.map(function (data) {
    return data.id;
  });
  var userAddressesFilter;

  if (user.currentTrade) {
    userAddressesFilter = user.addresses.filter(function (data) {
      return currentNeighborhood === null || currentNeighborhood === void 0 ? void 0 : currentNeighborhood.includes(data.neighborhood.id);
    });
  } else {
    userAddressesFilter = user.addresses;
  }

  var userAddressPosition = userAddressesFilter.findIndex(function (data) {
    return data.id === user.defaultShippingAddress.id;
  });
  var userAddressToShow = [];

  if (userAddressPosition !== -1) {
    var userAddressFirstPart = userAddressesFilter.slice(0, userAddressPosition);
    var userAddressSecondPart = userAddressesFilter.slice(userAddressPosition + 1, userAddressesFilter.length);
    var userAddressShippingDefault = userAddressesFilter.slice(userAddressPosition, userAddressPosition + 1);
    var finalUserAddresses = [].concat(_toConsumableArray(userAddressShippingDefault), _toConsumableArray(userAddressFirstPart), _toConsumableArray(userAddressSecondPart));
    finalUserAddresses.map(function (data, index) {
      if (index === 0) {
        data.OtherAddress = false;
        data.desktopView = true;
        data.isDefaultShippingAddress = true;
        data.isDefaultBillingAddress = true;
        return;
      }

      if (index !== 0) {
        data.isDefaultShippingAddress = false;
        data.isDefaultBillingAddress = false;
      }

      if (index === 1) {
        data.desktopView = false;
        data.desktopViewThirdElement = false;
        data.OtherAddress = true;
        return;
      }

      if (index === 2) {
        data.desktopViewThirdElement = true;
        return;
      }

      data.desktopView = false;
      data.OtherAddress = false;
      data.desktopViewThirdElement = false;
    });
    userAddressToShow = finalUserAddresses;
  } else {
    userAddressesFilter.map(function (data, index) {
      if (index === 0) {
        data.OtherAddress = false;
        data.desktopView = true;
        data.isDefaultShippingAddress = true;
        data.isDefaultBillingAddress = true;
        return;
      }

      if (index !== 0) {
        data.isDefaultShippingAddress = false;
        data.isDefaultBillingAddress = false;
      }

      if (index === 1) {
        data.desktopView = false;
        data.desktopViewThirdElement = false;
        data.OtherAddress = true;
        return;
      }

      if (index === 2) {
        data.desktopViewThirdElement = true;
        return;
      }

      data.desktopView = false;
      data.OtherAddress = false;
      data.desktopViewThirdElement = false;
    });
    userAddressToShow = userAddressesFilter;
  }

  var neighborhoodsAttention = (_user$currentTrade2 = user.currentTrade) === null || _user$currentTrade2 === void 0 ? void 0 : _user$currentTrade2.neighborhoodsAttention.map(function (data) {
    return {
      id: data.id,
      name: data.name
    };
  });
  var newUserAddresses = userAddressToShow.slice(0, 1);
  var addAddress = {
    address: {
      OtherAddress: true,
      city: ((_newUserAddresses$ = newUserAddresses[0]) === null || _newUserAddresses$ === void 0 ? void 0 : (_newUserAddresses$$ci = _newUserAddresses$.city2) === null || _newUserAddresses$$ci === void 0 ? void 0 : _newUserAddresses$$ci.name) || (user === null || user === void 0 ? void 0 : (_user$defaultShipping = user.defaultShippingAddress) === null || _user$defaultShipping === void 0 ? void 0 : (_user$defaultShipping2 = _user$defaultShipping.city2) === null || _user$defaultShipping2 === void 0 ? void 0 : _user$defaultShipping2.name),
      city2: ((_newUserAddresses$2 = newUserAddresses[0]) === null || _newUserAddresses$2 === void 0 ? void 0 : _newUserAddresses$2.city2) || (user === null || user === void 0 ? void 0 : (_user$defaultShipping3 = user.defaultShippingAddress) === null || _user$defaultShipping3 === void 0 ? void 0 : _user$defaultShipping3.city2),
      companyName: ((_newUserAddresses$3 = newUserAddresses[0]) === null || _newUserAddresses$3 === void 0 ? void 0 : _newUserAddresses$3.companyName) || null,
      country: ((_newUserAddresses$4 = newUserAddresses[0]) === null || _newUserAddresses$4 === void 0 ? void 0 : _newUserAddresses$4.country) || (user === null || user === void 0 ? void 0 : (_user$defaultShipping4 = user.defaultShippingAddress) === null || _user$defaultShipping4 === void 0 ? void 0 : _user$defaultShipping4.country),
      country2: ((_newUserAddresses$5 = newUserAddresses[0]) === null || _newUserAddresses$5 === void 0 ? void 0 : _newUserAddresses$5.country2) || (user === null || user === void 0 ? void 0 : (_user$defaultShipping5 = user.defaultShippingAddress) === null || _user$defaultShipping5 === void 0 ? void 0 : _user$defaultShipping5.country2),
      countryArea: ((_newUserAddresses$6 = newUserAddresses[0]) === null || _newUserAddresses$6 === void 0 ? void 0 : (_newUserAddresses$6$r = _newUserAddresses$6.region2) === null || _newUserAddresses$6$r === void 0 ? void 0 : _newUserAddresses$6$r.name) || (user === null || user === void 0 ? void 0 : (_user$defaultShipping6 = user.defaultShippingAddress) === null || _user$defaultShipping6 === void 0 ? void 0 : (_user$defaultShipping7 = _user$defaultShipping6.region2) === null || _user$defaultShipping7 === void 0 ? void 0 : _user$defaultShipping7.name),
      isDefaultBillingAddress: false,
      isDefaultShippingAddress: false,
      lastName: "",
      latitude: "0",
      longitude: "0",
      neighborhood: ((_newUserAddresses$7 = newUserAddresses[0]) === null || _newUserAddresses$7 === void 0 ? void 0 : _newUserAddresses$7.neighborhood) || {
        id: "",
        name: "Seleccionar un barrio"
      },
      phone: "",
      postalCode: "No definido",
      region2: ((_newUserAddresses$8 = newUserAddresses[0]) === null || _newUserAddresses$8 === void 0 ? void 0 : _newUserAddresses$8.region2) || (user === null || user === void 0 ? void 0 : (_user$defaultShipping8 = user.defaultShippingAddress) === null || _user$defaultShipping8 === void 0 ? void 0 : _user$defaultShipping8.region2),
      streetAddress1: "",
      streetAddress2: "",
      firstNameWrite: "",
      firstNameSelect: undefined
    }
  };

  var editAddress = function editAddress(address) {
    localStorage.setItem("address-from", "profile");
    localStorage.setItem("address-data", JSON.stringify(address));
    localStorage.setItem("address-neighborhood", JSON.stringify(neighborhoodsAttention));
    localStorage.setItem("address-name-options", JSON.stringify(nameOptionsSelect));
    localStorage.setItem("address-user-id", "");
    navigate("/address/edit");
  };

  var newAddress = function newAddress() {
    localStorage.setItem("address-user-id", user.id);
    localStorage.setItem("address-data", JSON.stringify(addAddress));
    localStorage.setItem("address-neighborhood", JSON.stringify(neighborhoodsAttention));
    localStorage.setItem("address-name-options", JSON.stringify(nameOptions));
    localStorage.setItem("address-from", "profile");
    navigate("/address/add");
  };

  var userAddresses = userAddressToShow.map(function (address, index) {
    var addressToDisplay = {
      address: _objectSpread({}, address)
    };

    addressToDisplay.onEdit = function () {
      var firstNameWrite = "";
      var firstNameSelect = {
        id: intl.formatMessage(checkoutMessages.myHome),
        name: intl.formatMessage(checkoutMessages.myHome)
      };

      if (address.firstName !== null && address.firstName !== "") {
        firstNameSelect = {
          id: address.firstName,
          name: address.firstName
        };
      }

      if (nameOptions.findIndex(function (nameoptionDefault) {
        return nameoptionDefault.id.includes(address.firstName);
      }) === -1 && address.firstName !== null && address.firstName !== "") {
        setnameOptionsSelect([].concat(_toConsumableArray(nameOptionsSelect), [firstNameSelect]));
      }

      var addressData = {
        address: {
          OtherAddress: address.OtherAddress,
          city: address.city,
          city2: address.city2,
          companyName: address.companyName,
          country: address.country,
          country2: address.country2,
          countryArea: address.countryArea,
          desktopView: address.desktopView,
          desktopViewThirdElement: address.desktopViewThirdElement,
          firstNameSelect: firstNameSelect,
          firstNameWrite: firstNameWrite,
          id: address.id,
          isDefaultBillingAddress: address.isDefaultBillingAddress,
          isDefaultShippingAddress: address.isDefaultShippingAddress,
          lastName: address.lastName,
          latitude: address.latitude,
          longitude: address.longitude,
          neighborhood: address.neighborhood,
          phone: address.phone,
          postalCode: address.postalCode,
          region2: address.region2,
          streetAddress1: address.streetAddress1.toLowerCase() === "no definida" ? "" : address.streetAddress1,
          streetAddress2: address.streetAddress2
        },
        id: address.id
      };
      editAddress(addressData);
    };

    addressToDisplay.onRemove = function () {
      setIdAddressRemove(address.id);
      setShowAlertDeleteAddress(true);
    };

    addressToDisplay.setDefault = function (type) {
      setDefaultUserAddress({
        id: address.id,
        type: type === "BILLING" ? AddressTypeEnum.BILLING : AddressTypeEnum.SHIPPING
      });
    };

    return addressToDisplay;
  });
  useEffect(function () {
    if (dataDefaultUserAddress.data && !dataDefaultUserAddress.loading) {
      updateCheckoutFromBD(true);
    }
  }, [dataDefaultUserAddress.data, dataDefaultUserAddress.loading]);
  useEffect(function () {
    setShowAlertDeleteAddress(false);
    return function () {
      setShowAlertDeleteAddress(false);
    };
  }, [userAddressesFilter.length]);
  useEffect(function () {
    setnameOptionsSelect(nameOptions);
    localStorage.setItem("address-undefined", "false");
    return function () {
      setnameOptionsSelect([]);
    };
  }, []);
  return React.createElement("div", {
    className: "address-book-container"
  }, React.createElement(AddressGrid, {
    addresses: userAddresses,
    addNewAddress: function addNewAddress() {
      newAddress();
    }
  }), showAlertDeleteAddress && React.createElement(AlertDeleteAddress, {
    deleteAddressId: idAddressRemove,
    setDeleteUserAddress: setDeleteUserAddress,
    hideModal: function hideModal() {
      setShowAlertDeleteAddress(false);
    }
  }));
};

export default AddressBook;