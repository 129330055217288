import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject21() {
  var data = _taggedTemplateLiteral(["\n  width: 170px;\n  margin: 0 auto;\n  padding: 5px 20px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  background-color: #25d366;\n  border-radius: 20px;\n  div {\n    display: flex;\n    align-items: center;\n    width: 35px;\n    heigth: 35px;\n  }\n"]);

  _templateObject21 = function _templateObject21() {
    return data;
  };

  return data;
}

function _templateObject20() {
  var data = _taggedTemplateLiteral(["\n  font-size: 1rem;\n  text-align: center;\n  text-transform: lowercase;\n  color: grey;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n"]);

  _templateObject20 = function _templateObject20() {
    return data;
  };

  return data;
}

function _templateObject19() {
  var data = _taggedTemplateLiteral(["\n  align-self: center;\n  font-size: 1rem;\n  text-align: center;\n  text-transform: lowercase;\n  color: black;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n"]);

  _templateObject19 = function _templateObject19() {
    return data;
  };

  return data;
}

function _templateObject18() {
  var data = _taggedTemplateLiteral(["\n  font-size: 1rem;\n  text-align: center;\n  padding: 0.5rem 0 0.5rem 0;\n  margin-top: 5rem;\n  text-transform: lowercase;\n  color: grey;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n"]);

  _templateObject18 = function _templateObject18() {
    return data;
  };

  return data;
}

function _templateObject17() {
  var data = _taggedTemplateLiteral(["\n  width: 250px;\n  height: 200px;\n  margin: 0 auto;\n  padding-top: 30px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  img {\n    width: 250px;\n    height: 200px;\n    object-fit: contain;\n  }\n"]);

  _templateObject17 = function _templateObject17() {
    return data;
  };

  return data;
}

function _templateObject16() {
  var data = _taggedTemplateLiteral(["\n  width: 250px;\n  height: 90px;\n  margin-top: 80px;\n  padding-bottom: 50px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  img {\n    width: 250px;\n    height: 150px;\n    object-fit: contain;\n  }\n  @media (min-width: 850px) {\n    img {\n      width: 300px;\n      height: 200px;\n    }\n  }\n"]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  heigth: auto;\n  position: relative;\n  margin-bottom: 3rem;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n"]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = _taggedTemplateLiteral(["\n    padding: 0 0 0.5rem 0;\n    border-bottom: 1px solid rgba(50, 50, 50, 0.1);\n    box-shadow: none;\n  "]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  padding: 0.6rem;\n  margin-bottom: 1rem;\n  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);\n  border-radius: 10px;\n  border-bottom: none;\n  font-size: 1.5rem;\n  font-weight: ", ";\n  text-align: center;\n  text-transform: lowercase;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n  ", "\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n    padding: 0;\n    margin-bottom: 40px;\n  "]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  width: 90%;\n  margin: 0 auto;\n  padding-top: 30px;\n  ", "\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  grid-area: footer;\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  grid-area: aside;\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  align-self: center;\n  font-size: 1rem;\n  text-transform: lowercase;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-columns: 50% 50%;\n  position: absolute;\n  top: 0.4rem;\n  left: 1rem;\n  color: #f76268;\n  cursor: pointer;\n  font-size: 1rem;\n  text-transform: uppercase;\n  div {\n    height: 35px;\n    align-self: center;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  svg {\n    transform: scale(0.4);\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n    height: 350px;\n    grid-template-rows: 50% 50%;\n  "]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  width: 90%;\n  height: 100%;\n  margin: 0 auto;\n  display: grid;\n  grid-template-rows: 20% 50% 30%;\n  ", "\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  grid-area: content;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  grid-area: tile;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    grid-template-areas:\n      \"content\"\n      \"aside\";\n    grid-template-columns: 100%;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-areas:\n    \"tile tile\"\n    \"content aside\"\n    \"footer footer\";\n  position: relative;\n  top: -30px;\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled, media } from "@styles";
export var Wrapper = styled.div(_templateObject(), media.xMediumScreen(_templateObject2()));
export var Tile = styled.div(_templateObject3());
export var Content = styled.div(_templateObject4());
export var ContentContainer = styled.div(_templateObject5(), media.xMediumScreen(_templateObject6()));
export var BackTitle = styled.div(_templateObject7());
export var BackTitleParragraph = styled.p(_templateObject8());
export var Aside = styled.div(_templateObject9());
export var Footer = styled.div(_templateObject10());
export var Navigation = styled.div(_templateObject11(), media.xMediumScreen(_templateObject12()));
export var Title = styled.h3(_templateObject13(), function (props) {
  return props.theme.typography.boldFontWeight;
}, media.xMediumScreen(_templateObject14()));
export var Images = styled.div(_templateObject15());
export var Gif = styled.div(_templateObject16());
export var GifShop = styled.div(_templateObject17());
export var Parragraph = styled.div(_templateObject18());
export var ParragraphDescription = styled.p(_templateObject19());
export var ParragraphReason = styled.div(_templateObject20());
export var Button = styled.div(_templateObject21());