import "./scss/styles.scss";
import easyPedido from "@temp/images/easypedido-tienda640x640.jpg";
import redMoped from "@temp/images/easyLink/redMopedLoading.png";
import wrongTrade from "@temp/images/easyLink/wrongTradeWithOutRed.svg";
import React from "react";
import Media from "react-media";
import { xMediumScreen } from "@styles/constants";
import { Loader } from "@temp/components";
import { FormattedMessage } from "react-intl";
import { animated, useSpring } from "react-spring";
export var EasyLinkLoading = function EasyLinkLoading(_ref) {
  var data = _ref.data,
      loading = _ref.loading;
  var scaleButton = useSpring({
    config: {
      friction: 40,
      mass: 5,
      tension: 350
    },
    from: {
      scale: 1.1
    },
    loop: true,
    to: {
      scale: 1
    }
  });
  return React.createElement(Media, {
    query: {
      maxWidth: xMediumScreen
    }
  }, function (matches) {
    return matches ? React.createElement("div", {
      className: "easyLinkLoading__container"
    }, React.createElement("div", {
      className: "easyLinkLoading__logo"
    }, React.createElement("div", {
      className: "easyLinkLoading__logo__background"
    }, " "), React.createElement("span", {
      className: "easyLinkLoading__logo__image_principal"
    }, React.createElement("img", {
      alt: "redMoped",
      src: !loading && (data === null || data === void 0 ? void 0 : data.mainTradeData) === null ? wrongTrade : redMoped
    }))), React.createElement("div", {
      className: "easyLinkLoading__sign"
    }, loading ? React.createElement(Loader, null) : !loading && (data === null || data === void 0 ? void 0 : data.mainTradeData) === null ? React.createElement(React.Fragment, null, React.createElement("p", null, React.createElement(FormattedMessage, {
      id: "views.EasyLink.View.EasyLinkLoading.3215447491",
      defaultMessage: "We sorry the establishment"
    })), React.createElement("p", null, React.createElement(FormattedMessage, {
      id: "views.EasyLink.View.EasyLinkLoading.2134472487",
      defaultMessage: "Was not found"
    }))) : !loading && data === null && React.createElement(animated.button, {
      className: "easyLinkLoading__button",
      onClick: function onClick() {
        return window.location.reload();
      },
      style: scaleButton
    }, React.createElement("span", null, React.createElement(FormattedMessage, {
      id: "views.EasyLink.View.EasyLinkLoading.3294346597",
      defaultMessage: "Try again"
    })))), React.createElement("div", {
      className: "easyLinkLoading__safe"
    }, React.createElement("p", null, "Powered By"), React.createElement("img", {
      alt: "redMoped",
      src: easyPedido
    }))) : React.createElement("div", {
      className: "easyLinkLoadingMax"
    }, React.createElement("div", {
      className: "easyLinkLoadingMax__background"
    }, " "), React.createElement("div", {
      className: "easyLinkLoadingMax__container"
    }, React.createElement("div", {
      className: "easyLinkLoadingMax__container__left"
    }, React.createElement("span", {
      className: "easyLinkLoadingMax__container__left__image_principal"
    }, " "), React.createElement("div", {
      className: "easyLinkLoadingMax__container__left__sign"
    }, loading ? React.createElement(Loader, null) : !loading && (data === null || data === void 0 ? void 0 : data.mainTradeData) === null ? React.createElement(React.Fragment, null, React.createElement("p", null, React.createElement(FormattedMessage, {
      id: "views.EasyLink.View.EasyLinkLoading.3215447491",
      defaultMessage: "We sorry the establishment"
    })), React.createElement("p", null, React.createElement(FormattedMessage, {
      id: "views.EasyLink.View.EasyLinkLoading.2134472487",
      defaultMessage: "Was not found"
    }))) : !loading && data === null && React.createElement(animated.button, {
      className: "easyLinkLoading__button",
      onClick: function onClick() {
        return window.location.reload();
      },
      style: scaleButton
    }, React.createElement("span", null, React.createElement(FormattedMessage, {
      id: "views.EasyLink.View.EasyLinkLoading.3294346597",
      defaultMessage: "Try again"
    }))))), React.createElement("div", {
      className: "easyLinkLoadingMax__container__right"
    }, React.createElement("span", {
      className: "easyLinkLoadingMax__container__right-img"
    }, React.createElement("img", {
      alt: "redMoped",
      src: !loading && (data === null || data === void 0 ? void 0 : data.mainTradeData) === null ? wrongTrade : redMoped
    })), React.createElement("div", {
      className: "easyLinkLoadingMax__container__right__safe"
    }, React.createElement("p", null, "Powered By"), React.createElement("img", {
      alt: "redMoped",
      src: easyPedido
    })))));
  });
};
EasyLinkLoading.displayName = "EasyLinkLoading";