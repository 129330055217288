import "./scss/index.scss";
import React from "react";
import ReactSVG from "react-svg";
import { FormattedMessage } from "react-intl";
import { animated, useSpring } from "react-spring";

var closeWhite = require("../../images/closewhite.svg");

export var AlertRedirectWA = function AlertRedirectWA(_ref) {
  var hideModal = _ref.hideModal,
      handleContinue = _ref.handleContinue;
  var scaleButton = useSpring({
    config: {
      friction: 40,
      mass: 5,
      tension: 350
    },
    from: {
      scale: 1.1
    },
    loop: true,
    to: {
      scale: 1
    }
  });
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "alertRedirectWA",
    onClick: hideModal
  }), React.createElement("div", {
    className: "alertRedirectWA__data"
  }, React.createElement("div", {
    className: "alertRedirectWA__data__close"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.AlertRedirectWA.index.709932324",
    defaultMessage: "Alert"
  })), React.createElement(ReactSVG, {
    path: closeWhite,
    onClick: hideModal
  })), React.createElement("div", {
    className: "alertRedirectWA__data__content alertRedirectWA__data__content--value showContainer__data__content--first showContainer__data__content--last"
  }, React.createElement(FormattedMessage, {
    id: "components.AlertRedirectWA.index.4164534317",
    defaultMessage: "We have sent you a new access link to WhatsApp. The one you used has expired, tap the new link to enter.",
    description: "Alert"
  })), React.createElement("div", {
    className: "alertRedirectWA__data__footer"
  }, React.createElement(animated.button, {
    type: "button",
    onClick: handleContinue,
    style: scaleButton
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.AlertRedirectWA.index.3640052215",
    defaultMessage: "Continue"
  }))))));
};
export default AlertRedirectWA;
AlertRedirectWA.displayName = "AlertRedirectWA";