import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React from "react";
import ReactSelect from "react-select";
import { ThemeContext } from "styled-components";
import { ErrorMessage } from "../ErrorMessage";
import * as S from "./styles";

var optionStyle = function optionStyle(customTheme) {
  return {
    option: function option(provided, state) {
      return _objectSpread(_objectSpread({}, provided), {}, {
        alignItems: "center",
        backgroundColor: state.isSelected ? "#448bfd" : state.isFocused ? customTheme.colors.primaryTransparent : "white",
        color: state.isSelected ? "white" : customTheme.colors.dark,
        display: "flex",
        fontWeight: state.isSelected && customTheme.typography.boldFontWeight,
        margin: "0 auto",
        minHeight: "34px",
        verticalAlign: "middle",
        width: "95%"
      });
    }
  };
};

export var Select = function Select(_ref) {
  var value = _ref.value,
      id = _ref.id,
      onChange = _ref.onChange,
      clearable = _ref.clearable,
      clearValue = _ref.clearValue,
      name = _ref.name,
      options = _ref.options,
      isOptionDisabled = _ref.isOptionDisabled,
      customComponents = _ref.customComponents,
      defaultValue = _ref.defaultValue,
      menuIsOpen = _ref.menuIsOpen,
      customStyles = _ref.customStyles,
      _ref$optionLabelKey = _ref.optionLabelKey,
      optionLabelKey = _ref$optionLabelKey === void 0 ? "label" : _ref$optionLabelKey,
      _ref$optionValueKey = _ref.optionValueKey,
      optionValueKey = _ref$optionValueKey === void 0 ? "value" : _ref$optionValueKey,
      errors = _ref.errors,
      props = _objectWithoutProperties(_ref, ["value", "id", "onChange", "clearable", "clearValue", "name", "options", "isOptionDisabled", "customComponents", "defaultValue", "menuIsOpen", "customStyles", "optionLabelKey", "optionValueKey", "errors"]);

  var customTheme = React.useContext(ThemeContext);

  var handleChange = function handleChange(value) {
    if (onChange) {
      if (name) onChange(value, name);else onChange(value);
    }
  };

  return React.createElement(S.Wrapper, null, React.createElement(ReactSelect, _extends({
    id: id,
    defaultValue: defaultValue,
    onChange: handleChange,
    value: value,
    clearValue: clearValue,
    menuIsOpen: menuIsOpen,
    menuShouldScrollIntoView: true,
    tabSelectsValue: false,
    getOptionLabel: function getOptionLabel(option) {
      return option[optionLabelKey];
    },
    getOptionValue: function getOptionValue(option) {
      return option[optionValueKey];
    },
    openMenuOnFocus: true,
    styles: _objectSpread(_objectSpread({}, optionStyle(customTheme)), customStyles),
    options: options,
    isOptionDisabled: isOptionDisabled,
    placeholder: "",
    components: customComponents,
    isClearable: clearable,
    classNamePrefix: "select-".concat(name)
  }, props)), React.createElement(S.ErrorMessages, null, React.createElement(ErrorMessage, {
    errors: errors
  })));
};