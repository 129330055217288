import "../../views/Homee/scss/index.scss";
import * as React from "react";
import { MetaWrapper } from "@temp/components";
import { ChangeAmountProductsContext } from "@temp/contexts";
import { PageHomeSaleProducts } from "@temp/views/Homee";

var HomeSaleProducts = function HomeSaleProducts(_ref) {
  var saleProducts = _ref.saleProducts;

  var _React$useContext = React.useContext(ChangeAmountProductsContext),
      maxProductsPerCategory = _React$useContext.maxProductsPerCategory;

  return React.createElement(React.Fragment, null, React.createElement(MetaWrapper, {
    meta: {
      type: "product.sale"
    }
  }, React.createElement(PageHomeSaleProducts, {
    saleProducts: saleProducts,
    maxProductsPerCategory: maxProductsPerCategory
  })));
};

export default HomeSaleProducts;