import "./scss/index.scss";
import React from "react";
import { SkeletonProduct } from "../SkeletonProduct/SkeletonProduct";
export var SkeletonDescription = function SkeletonDescription() {
  return React.createElement(React.Fragment, null, new Array(3).fill(0).map(function (_, index) {
    return React.createElement("div", {
      key: index
    }, React.createElement("section", {
      className: "box",
      style: {
        marginLeft: "30px",
        display: "inline-block"
      }
    }, React.createElement("div", {
      className: "skeleton-placeholderSubtitle"
    }), React.createElement("div", {
      className: "line"
    }), React.createElement("div", {
      className: "skeleton-placeholderSeeMore"
    }), React.createElement("div", {
      className: "product-img"
    }), React.createElement("div", {
      className: "skeleton-placeholder"
    }), React.createElement("div", {
      className: "skeleton-placeholder"
    })), React.createElement(SkeletonProduct, null));
  }));
};