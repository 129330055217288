import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ThankYou } from "@components/organisms";
import { BASE_URL } from "@temp/core/config";
import { generateGuestOrderDetailsUrl } from "@utils/core";
import { MainMenu } from "../../../components";

var ThankYouPage = function ThankYouPage() {
  var location = useLocation();
  var navigate = useNavigate();
  var _location$state = location.state,
      token = _location$state.token,
      orderNumber = _location$state.orderNumber;
  return React.createElement(React.Fragment, null, React.createElement(MainMenu, {
    goback: true,
    search: false,
    cart: false
  }), React.createElement(ThankYou, {
    continueShopping: function continueShopping() {
      return navigate(BASE_URL);
    },
    orderNumber: orderNumber,
    orderDetails: function orderDetails() {
      return navigate(generateGuestOrderDetailsUrl(token));
    }
  }));
};

export { ThankYouPage };