import "./scss/index.scss";
import { commonMessages } from "@temp/intl";
import React from "react";
import ReactSVG from "react-svg";
import { FormattedMessage, useIntl } from "react-intl";

var closeWhite = require("../../images/closewhite.svg");

var googleMaps = require("../../images/delivery/google_maps.svg");

var waze = require("../../images/delivery/waze.svg"); // const greaterThanEqual = require("../../images/greaterThanEqual.svg");


export var AlertMap = function AlertMap(_ref) {
  var hideModal = _ref.hideModal,
      googleMapsURL = _ref.googleMapsURL,
      wazeURL = _ref.wazeURL;
  var intl = useIntl();
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "showContainerMap",
    onClick: hideModal
  }), React.createElement("div", {
    className: "showContainerMap__data"
  }, React.createElement("div", {
    className: "showContainerMap__data__close"
  }, React.createElement("p", null, intl.formatMessage(commonMessages.openWith)), React.createElement(ReactSVG, {
    path: closeWhite,
    onClick: hideModal
  })), React.createElement("div", {
    className: "showContainerMap__data__content"
  }, React.createElement("a", {
    href: googleMapsURL
  }, React.createElement(ReactSVG, {
    path: googleMaps
  }), React.createElement(FormattedMessage, {
    id: "components.AlertMap.index.2667785137",
    defaultMessage: "Google maps"
  })), React.createElement("a", {
    href: wazeURL
  }, React.createElement(ReactSVG, {
    path: waze
  }), React.createElement(FormattedMessage, {
    id: "components.AlertMap.index.1178999080",
    defaultMessage: "Waze"
  })))));
};
export default AlertMap;