import React from "react";
import { FormattedMessage } from "react-intl";
import ReactSVG from "react-svg";
import Media from "react-responsive";
import { xMediumScreen } from "@styles/constants";
import { StatusData, StatusDataFooter } from "@components/molecules"; // import { Checkbox, ErrorMessage } from "@components/atoms";

import { statusMessages } from "@temp/intl"; // import { LuzattAcceptanceField } from "../../../../components";

import * as S from "./styles";
import waitingImg from "../../../../images/undelivered.svg";
import goBackImg from "../../../../images/arrowBack.svg";

/**
 * Undelivered status structure
 */
var StatusUnDelivered = function StatusUnDelivered(_ref) {
  var data = _ref.data,
      goBack = _ref.goBack;
  return React.createElement(S.Wrapper, null, React.createElement(S.Tile, null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(S.Title, {
    "data-test": "checkoutPageSubtitle"
  }, React.createElement(S.BackTitle, {
    onClick: goBack
  }, React.createElement(ReactSVG, {
    path: goBackImg
  }), React.createElement(S.BackTitleParragraph, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.StatusUnDelivered.StatusUnDelivered.3471378517",
    defaultMessage: "back"
  }))), React.createElement(FormattedMessage, statusMessages.stepNameUnDelivered)))), React.createElement(S.Content, null, React.createElement(Media, {
    maxWidth: xMediumScreen
  }, React.createElement(S.Title, null, React.createElement(FormattedMessage, statusMessages.stepNameUnDelivered))), React.createElement(S.ContentContainer, null, React.createElement(S.Images, null, React.createElement(ReactSVG, {
    path: waitingImg
  })), data.lastFulfillment.cancellationReason && React.createElement(S.Parragraph, null, React.createElement(S.Span, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.StatusUnDelivered.StatusUnDelivered.3077186403",
    defaultMessage: "Reason"
  }), ": "), data.lastFulfillment.cancellationReason))), React.createElement(S.Aside, null, React.createElement(StatusData, {
    deliveryPhone: false,
    data: data,
    deliveryData: true
  })), React.createElement(S.Footer, null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(StatusDataFooter, {
    data: data
  }))));
};

export { StatusUnDelivered };