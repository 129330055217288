import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject16() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  min-width: 320px;\n  grid-area: footer;\n  position: fixed;\n  bottom: 0px;\n  left: 0px;\n  z-index: 999;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  background: white;\n  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);\n"]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = _taggedTemplateLiteral(["\n  width: 230px;\n  text-align: right;\n  padding: 0.5rem 0 1rem 0;\n\n  button {\n    width: 100%;\n    padding: 0.6rem;\n    border-radius: 8px;\n    background-color: white;\n    color: #ed1c24;\n    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);\n\n    span {\n      font-size: 16px;\n    }\n  }\n"]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = _taggedTemplateLiteral(["\n  width: 230px;\n  position: relative;\n  margin-bottom: 10px;\n  text-align: right;\n\n  button {\n    width: 100%;\n    padding: 0.6rem;\n    border-radius: 8px;\n\n    span {\n      font-size: 16px;\n    }\n  }\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 45px;\n  position: absolute;\n  top: -2px;\n  left: -2px;\n  right: -2px;\n  bottom: -2px;\n  z-index: -1;\n  border-radius: 8px;\n  animation: wavesCart 2s infinite;\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n  height: 55vh;\n  grid-area: body;\n  padding: 5px 20px;\n  padding-bottom: 30px;\n  overflow: auto;\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  width: 80%;\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n    display: none;\n  "]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  ", ";\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  grid-area: quantity;\n  width: 85%;\n  margin: 0 auto;\n  padding: 10px 0;\n  font-weight: bold;\n\n  p {\n    color: gray;\n  }\n\n  span {\n    color: #ed1c24;\n  }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  height: 50px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n\n  svg {\n    width: 30px;\n    height: 30px;\n  }\n\n  h1 {\n    padding-left: 10px;\n    font-size: 16px;\n    text-align: center;\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  height: 50px;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  height: 50px;\n\n  li::marker {\n    color: transparent;\n  }\n\n  li svg {\n    position: absolute;\n    top: 8px;\n    left: 8px;\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  grid-area: header;\n  position: relative;\n  top: 0px;\n  left: 0px;\n  z-index: 999;\n  width: 100%;\n  height: 50px;\n  display: grid;\n  grid-template-columns: 20% 60% 20%;\n  background-color: white;\n  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  width: 100vw;\n  min-width: 320px;\n  max-width: 100vw;\n  height: 100%;\n  display: grid;\n  grid-template-areas:\n    \"header\"\n    \"quantity\"\n    \"body\"\n    \"footer\";\n  grid-template-rows: min-content min-content auto min-content;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    width: 100%;      \n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  margin: 0 auto;\n  width: 100vw;\n  min-width: 320px;\n  height: 100%;\n  display: grid;\n  grid-template-rows: 100%;\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Container = styled.div(_templateObject(), media.largeScreen(_templateObject2()));
export var Wrapper = styled.div(_templateObject3());
export var ContainerHeader = styled.div(_templateObject4());
export var Breadcrumbs = styled.div(_templateObject5());
export var Complement = styled.div(_templateObject6());
export var Title = styled.div(_templateObject7());
export var QuantityItems = styled.div(_templateObject8());
export var CartHeader = styled.div(_templateObject9(), media.mediumScreen(_templateObject10()));
export var CartFooter = styled.div(_templateObject11());
export var Cart = styled.div(_templateObject12());
export var Waves = styled.div(_templateObject13());
export var ProceedButton = styled.div(_templateObject14());
export var CancelButton = styled.div(_templateObject15());
export var ContainerFooter = styled.div(_templateObject16());