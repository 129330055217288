import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject30() {
  var data = _taggedTemplateLiteral(["\n  color: grey;\n"]);

  _templateObject30 = function _templateObject30() {
    return data;
  };

  return data;
}

function _templateObject29() {
  var data = _taggedTemplateLiteral(["\n  color: #ed1c24;\n"]);

  _templateObject29 = function _templateObject29() {
    return data;
  };

  return data;
}

function _templateObject28() {
  var data = _taggedTemplateLiteral(["\n  width: 32px;\n  height: 32px;\n  margin: 0 auto;\n  align-self: center;\n  padding: 5px;\n  border-radius: 8px;\n  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);\n"]);

  _templateObject28 = function _templateObject28() {
    return data;
  };

  return data;
}

function _templateObject27() {
  var data = _taggedTemplateLiteral(["\n  align-self: center;\n"]);

  _templateObject27 = function _templateObject27() {
    return data;
  };

  return data;
}

function _templateObject26() {
  var data = _taggedTemplateLiteral(["\n  padding: 10px 15px;\n  display: grid;\n  grid-template-columns: 80% 20%;\n"]);

  _templateObject26 = function _templateObject26() {
    return data;
  };

  return data;
}

function _templateObject25() {
  var data = _taggedTemplateLiteral(["\n  grid-area: Observation;\n  align-self: center;\n  div {\n    margin-bottom: 0;\n  }\n  label {\n    color: rgb(247, 98, 104);\n  }\n"]);

  _templateObject25 = function _templateObject25() {
    return data;
  };

  return data;
}

function _templateObject24() {
  var data = _taggedTemplateLiteral(["\n  grid-area: quantity;\n  min-width: 120px;\n  // margin: 0 15px;\n  div {\n    margin: 0 auto;\n  }\n"]);

  _templateObject24 = function _templateObject24() {
    return data;
  };

  return data;
}

function _templateObject23() {
  var data = _taggedTemplateLiteral(["\n  grid-area: unitPrice;\n  display: flex;\n  flex-direction: column;\n  p {\n    display: block;\n    text-align: center;\n    font-weight: bold;\n  }\n\n  p:nth-child(2) {\n    display: block;\n    color: #ed1c24;\n    text-decoration: line-through;\n    font-size: 14px !important;\n  }\n"]);

  _templateObject23 = function _templateObject23() {
    return data;
  };

  return data;
}

function _templateObject22() {
  var data = _taggedTemplateLiteral(["\n    margin: 0;\n  "]);

  _templateObject22 = function _templateObject22() {
    return data;
  };

  return data;
}

function _templateObject21() {
  var data = _taggedTemplateLiteral(["\n  width: 28px;\n  height: 30px;\n  padding: 8px;\n  margin: 0 auto;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  grid-area: trash;\n  background: rgb(247, 98, 104);\n  border-radius: 8px;\n\n  div > div {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n  svg {\n    width: 20px;\n    height: 20px;\n  }\n  ", ";\n"]);

  _templateObject21 = function _templateObject21() {
    return data;
  };

  return data;
}

function _templateObject20() {
  var data = _taggedTemplateLiteral(["\n    p {\n      text-align: right;\n    }\n  "]);

  _templateObject20 = function _templateObject20() {
    return data;
  };

  return data;
}

function _templateObject19() {
  var data = _taggedTemplateLiteral(["\n  grid-area: totalPrice;\n  ", "\n"]);

  _templateObject19 = function _templateObject19() {
    return data;
  };

  return data;
}

function _templateObject18() {
  var data = _taggedTemplateLiteral(["\n    display: block;\n  "]);

  _templateObject18 = function _templateObject18() {
    return data;
  };

  return data;
}

function _templateObject17() {
  var data = _taggedTemplateLiteral(["\n  display: none;\n  ", "\n"]);

  _templateObject17 = function _templateObject17() {
    return data;
  };

  return data;
}

function _templateObject16() {
  var data = _taggedTemplateLiteral(["\n    font-weight: normal;\n    flex-direction: column;\n  "]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n  display: flex;\n  justify-content: center;\n  font-weight: bold;\n  ", "\n\n  p {\n    margin: 0;\n  }\n"]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n  color: rgba(125, 125, 125, 0.6);\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\n  font-weight: ", ";\n  height: 70px;\n  overflow: hidden;\n  font-size: 14px;\n  text-align: left;\n  text-transform: uppercase;\n  margin-bottom: 6px;\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n  margin: 0;\n  flex-grow: 1;\n  display: flex;\n  justify-content: flex-start;\n  white-space: nowrap;\n  background-color: white;\n  padding: 0px 15px;\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n    flex-flow: column;\n  "]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  grid-auto-columns: max-content;\n  grid-template-columns: repeat(auto-fit, minmax(166px, 500px));\n  margin-left: -15px;\n  ", ";\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  margin: 6px 0;\n  text-align: left;\n  margin-bottom: 10px;\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n    margin-left: 0px;\n  "]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  grid-area: description;\n  height: 100%;\n  margin-top: 20px;\n  margin-left: 20px;\n  ", "\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: auto;\n  grid-area: photo;\n  display: flex;\n  justify-content: center;\n  align-items: flex-start;\n  align-self: top;\n\n  img {\n    width: 70px;\n    height: 70px;\n    object-fit: contain;\n    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);\n    border-radius: 8px;\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-between;\n  padding: 0;\n  margin: 0 15px 0 0;\n  width: 50px;\n\n  > div {\n    display: flex;\n  }\n\n  svg {\n    cursor: pointer;\n    justify-self: center;\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n    grid-template-columns: 2fr 1fr 2fr;\n    // grid-row-gap: 10px;\n    grid-column-gap: 15px;\n    grid-template-areas: \"photo description description\"\n    \"unitPrice trash quantity\";\n    padding: 1rem;\n  "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    grid-template-areas: \"photo description unitPrice quantity trash\";\n    grid-template-columns: 0.5fr 2fr 1fr 1fr 0.5fr;\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  min-height: 140px;\n  max-height: min-content;\n  width: 100%;\n  grid-template-areas: \"photo description unitPrice quantity Observation trash\";\n  grid-template-columns: 0.5fr 1fr 1fr 1fr 2fr 0.5fr;\n  align-items: center;\n  padding: 0.8rem 0.5rem;\n  border-bottom: 1px solid #cdcdcd;\n  ", ";\n  ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  margin-bottom: 10px;\n  border-radius: 8px;\n  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var WrapperContainer = styled.div(_templateObject());
export var Wrapper = styled.div(_templateObject2(), media.xLargeScreen(_templateObject3()), media.mediumScreen(_templateObject4()));
export var QuantityButtons = styled.div(_templateObject5());
export var Photo = styled.div(_templateObject6());
export var Description = styled.div(_templateObject7(), media.mediumScreen(_templateObject8()));
export var Sku = styled.p(_templateObject9());
export var Attributes = styled.div(_templateObject10(), media.mediumScreen(_templateObject11()));
export var SingleAttribute = styled.p(_templateObject12());
export var Name = styled.p(_templateObject13(), function (props) {
  return props.theme.typography.boldFontWeight;
});
export var LightFont = styled.span(_templateObject14(), function (props) {
  return props.theme.typography.smallFontSize;
});
export var Price = styled.div(_templateObject15(), function (props) {
  return props.theme.typography.h4FontSize;
}, media.mediumScreen(_templateObject16()));
export var PriceLabel = styled.p(_templateObject17(), media.mediumScreen(_templateObject18()));
export var TotalPrice = styled(Price)(_templateObject19(), media.mediumScreen(_templateObject20()));
export var Trash = styled.div(_templateObject21(), media.xLargeScreen(_templateObject22()));
export var UnitPrice = styled(Price)(_templateObject23());
export var Quantity = styled.div(_templateObject24());
export var ObservationDesktop = styled.div(_templateObject25());
export var Observation = styled.div(_templateObject26());
export var ObservationData = styled.div(_templateObject27());
export var ObservationEdit = styled.div(_templateObject28());
export var ObservationTitle = styled.p(_templateObject29());
export var ObservationValue = styled.p(_templateObject30());