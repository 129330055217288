import React from "react";
import { Navigate } from "react-router-dom";
import { StatusFulfilled, StatusPaymentTimeout, StatusRefusedPayment, StatusWaitingForAuth, StatusWaitingPayment } from "@components/organisms";

var StatusFulfilledSubpage = function StatusFulfilledSubpage(_ref) {
  var data = _ref.data,
      goBack = _ref.goBack,
      navigation = _ref.navigation;

  if (JSON.stringify(data) !== "{}") {
    var _data$status, _data$status2, _data$status3, _data$status4, _data$status5, _data$status6, _data$status7, _data$status8, _data$status9, _data$status10, _data$status11, _data$status12, _data$status13, _data$status14, _data$status15;

    if ((data === null || data === void 0 ? void 0 : (_data$status = data.status) === null || _data$status === void 0 ? void 0 : _data$status.toUpperCase()) === "PENDING" || (data === null || data === void 0 ? void 0 : (_data$status2 = data.status) === null || _data$status2 === void 0 ? void 0 : _data$status2.toUpperCase()) === "DRAFT") {
      return React.createElement(Navigate, {
        to: "/statusl/".concat(data === null || data === void 0 ? void 0 : data.id, "/pending-draft")
      });
    }

    if ((data === null || data === void 0 ? void 0 : (_data$status3 = data.status) === null || _data$status3 === void 0 ? void 0 : _data$status3.toUpperCase()) === "FULFILLED" || (data === null || data === void 0 ? void 0 : (_data$status4 = data.status) === null || _data$status4 === void 0 ? void 0 : _data$status4.toUpperCase()) === "SCHEDULED") {
      return React.createElement(StatusFulfilled, {
        data: data,
        goBack: goBack,
        navigation: navigation
      });
    }

    if ((data === null || data === void 0 ? void 0 : (_data$status5 = data.status) === null || _data$status5 === void 0 ? void 0 : _data$status5.toUpperCase()) === "WAITING_PAYMENT") {
      return React.createElement(StatusWaitingPayment, {
        data: data,
        goBack: goBack,
        navigation: navigation
      });
    }

    if ((data === null || data === void 0 ? void 0 : (_data$status6 = data.status) === null || _data$status6 === void 0 ? void 0 : _data$status6.toUpperCase()) === "PAYMENT_TIMEOUT") {
      return React.createElement(StatusPaymentTimeout, {
        data: data,
        goBack: goBack,
        navigation: navigation
      });
    }

    if ((data === null || data === void 0 ? void 0 : (_data$status7 = data.status) === null || _data$status7 === void 0 ? void 0 : _data$status7.toUpperCase()) === "WAITING_FOR_AUTH") {
      return React.createElement(StatusWaitingForAuth, {
        data: data,
        goBack: goBack,
        navigation: navigation
      });
    }

    if ((data === null || data === void 0 ? void 0 : (_data$status8 = data.status) === null || _data$status8 === void 0 ? void 0 : _data$status8.toUpperCase()) === "REFUSED_PAYMENT") {
      return React.createElement(StatusRefusedPayment, {
        data: data,
        goBack: goBack,
        navigation: navigation
      });
    }

    if ((data === null || data === void 0 ? void 0 : (_data$status9 = data.status) === null || _data$status9 === void 0 ? void 0 : _data$status9.toUpperCase()) === "SENT" || (data === null || data === void 0 ? void 0 : (_data$status10 = data.status) === null || _data$status10 === void 0 ? void 0 : _data$status10.toUpperCase()) === "CANCELED_FULFILLMENT") {
      return React.createElement(Navigate, {
        to: "/statusl/".concat(data === null || data === void 0 ? void 0 : data.id, "/sent")
      });
    }

    if ((data === null || data === void 0 ? void 0 : (_data$status11 = data.status) === null || _data$status11 === void 0 ? void 0 : _data$status11.toUpperCase()) === "ARRIVED") {
      return React.createElement(Navigate, {
        to: "/statusl/".concat(data === null || data === void 0 ? void 0 : data.id, "/arrived")
      });
    }

    if ((data === null || data === void 0 ? void 0 : (_data$status12 = data.status) === null || _data$status12 === void 0 ? void 0 : _data$status12.toUpperCase()) === "TIMEOUT") {
      return React.createElement(Navigate, {
        to: "/pstatusp/".concat(data === null || data === void 0 ? void 0 : data.id, "/timeout")
      });
    }

    if ((data === null || data === void 0 ? void 0 : (_data$status13 = data.status) === null || _data$status13 === void 0 ? void 0 : _data$status13.toUpperCase()) === "CANCELED") {
      return React.createElement(Navigate, {
        to: "/pstatusp/".concat(data === null || data === void 0 ? void 0 : data.id, "/canceled")
      });
    }

    if ((data === null || data === void 0 ? void 0 : (_data$status14 = data.status) === null || _data$status14 === void 0 ? void 0 : _data$status14.toUpperCase()) === "DELIVERED") {
      return React.createElement(Navigate, {
        to: "/pstatusp/".concat(data === null || data === void 0 ? void 0 : data.id, "/delivered")
      });
    }

    if ((data === null || data === void 0 ? void 0 : (_data$status15 = data.status) === null || _data$status15 === void 0 ? void 0 : _data$status15.toUpperCase()) === "UNDELIVERED") {
      return React.createElement(Navigate, {
        to: "/pstatusp/".concat(data === null || data === void 0 ? void 0 : data.id, "/undelivered")
      });
    }

    return React.createElement(Navigate, {
      to: "/"
    });
  }

  return React.createElement(Navigate, {
    to: "/"
  });
};

export { StatusFulfilledSubpage };