import _defineProperty from "@babel/runtime/helpers/defineProperty";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl"; // import ReactSVG from "react-svg";

import { Checkbox } from "@components/atoms";
import { TextField } from "@components/molecules"; // import ProductVariantPicker from "../ProductVariantPicker";

import * as S from "./styles"; // const favoriteImg = require("../../../../images/favorito64x64.svg");

export var ProductObservation = React.memo(function (_ref) {
  var checkProductObservation = _ref.checkProductObservation,
      desktop = _ref.desktop,
      productObservationMessage = _ref.productObservationMessage,
      handleChangeCheckProductObservation = _ref.handleChangeCheckProductObservation,
      handleSetProductObservationMessage = _ref.handleSetProductObservationMessage;
  var intl = useIntl();
  return React.createElement(S.Comments, {
    desktop: desktop
  }, React.createElement(S.CommentsCheck, null, React.createElement(Checkbox, {
    "data-test": "checkoutPaymentPromoCodeCheckbox",
    name: "payment-promo-code",
    checked: checkProductObservation,
    onChange: function onChange() {
      return handleChangeCheckProductObservation();
    }
  }, React.createElement(S.CommentsCheckParragrah, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.ProductObservation.ProductObservation.2728013674",
    defaultMessage: "Do you want to add any observation?"
  })))), checkProductObservation && React.createElement(S.CommentsObservation, null, React.createElement(TextField, {
    name: "productObservation",
    label: intl.formatMessage(_defineProperty({
      defaultMessage: "Observations",
      "id": "@next.components.molecules.ProductObservation.ProductObservation.722592990"
    }, "id", "@next.components.molecules.ProductObservation.ProductObservation.722592990")),
    value: productObservationMessage,
    onChange: function onChange(e) {
      if (e.target.value.length <= 140) {
        handleSetProductObservationMessage(e.target.value);
      }
    },
    autoComplete: "observation",
    textAreaInputLabel: true,
    placeholder: intl.formatMessage(_defineProperty({
      defaultMessage: "Ex: The hamburger without onion",
      "id": "@next.components.molecules.ProductObservation.ProductObservation.1721244567"
    }, "id", "@next.components.molecules.ProductObservation.ProductObservation.1721244567"))
  })));
});