import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import * as React from "react";
import { Query } from "react-apollo";
import Media from "react-responsive";
import { xMediumScreen } from "@styles/constants";
import { Skeleton } from "@components/atoms/Skeleton/Skeleton";
import { Error } from "../components/Error";
import { maybe } from "./utils";
export function TypedQuery(query) {
  return function (props) {
    var _ref = props,
        children = _ref.children,
        _ref$displayError = _ref.displayError,
        displayError = _ref$displayError === void 0 ? true : _ref$displayError,
        _ref$displayLoader = _ref.displayLoader,
        displayLoader = _ref$displayLoader === void 0 ? true : _ref$displayLoader,
        _ref$renderOnError = _ref.renderOnError,
        renderOnError = _ref$renderOnError === void 0 ? false : _ref$renderOnError,
        _ref$alwaysRender = _ref.alwaysRender,
        alwaysRender = _ref$alwaysRender === void 0 ? false : _ref$alwaysRender,
        _ref$fetchPolicy = _ref.fetchPolicy,
        fetchPolicy = _ref$fetchPolicy === void 0 ? "cache-and-network" : _ref$fetchPolicy,
        errorPolicy = _ref.errorPolicy,
        skip = _ref.skip,
        variables = _ref.variables,
        onCompleted = _ref.onCompleted;
    return React.createElement(Query, {
      query: query,
      variables: variables,
      skip: skip,
      fetchPolicy: fetchPolicy,
      errorPolicy: errorPolicy,
      onCompleted: onCompleted
    }, function (queryData) {
      var error = queryData.error,
          loading = queryData.loading,
          data = queryData.data,
          fetchMore = queryData.fetchMore;
      var hasData = maybe(function () {
        return !!Object.keys(data).length;
      }, false);

      var loadMore = function loadMore(mergeFunc, extraVariables) {
        return fetchMore({
          query: query,
          updateQuery: function updateQuery(previousResults, _ref2) {
            var fetchMoreResult = _ref2.fetchMoreResult;

            if (!fetchMoreResult) {
              return previousResults;
            }

            return mergeFunc(previousResults, fetchMoreResult);
          },
          variables: _objectSpread(_objectSpread({}, variables), extraVariables)
        });
      };

      if (displayError && error && !hasData) {
        return React.createElement(Error, {
          error: error.message
        });
      }

      if (displayLoader && loading && !hasData) {
        return React.createElement(React.Fragment, null, React.createElement(Media, {
          minWidth: xMediumScreen
        }, React.createElement("div", {
          style: {
            width: "calc(90% - 250px)",
            marginLeft: "auto",
            marginTop: "15%"
          }
        }, React.createElement(Skeleton, null))), React.createElement(Media, {
          maxWidth: xMediumScreen
        }, React.createElement("div", {
          style: {
            marginTop: "55%"
          }
        }, React.createElement(Skeleton, null))));
      }

      if (hasData || renderOnError && error || alwaysRender) {
        return children(_objectSpread(_objectSpread({}, queryData), {}, {
          loadMore: loadMore
        }));
      }

      return null;
    });
  };
}