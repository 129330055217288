import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

export var initialState = [];
export var toppingsReducer = function toppingsReducer(prevState, action) {
  switch (action.type) {
    case "add-all-toppings":
      return [].concat(_toConsumableArray(prevState), _toConsumableArray(action.payload));

    case "remove-all-toppings":
      return _toConsumableArray(action.payload);

    case "update-specific-topping":
      {
        var dependenciesToAdd = [];
        var dependenciesToDelete = [];
        var indexDependencyToAdd = -1;
        var newState = prevState.map(function (state, index) {
          if (state.idTopping === action.payload.idTopping) {
            var newCheckMinTotalAmount;
            var newTotalAmount = 0;
            var newValues = state.dataValues.map(function (value) {
              var _value$assignedAttrib;

              if (value.idValue === action.payload.dataValue.idValue && (value === null || value === void 0 ? void 0 : (_value$assignedAttrib = value.assignedAttributeVariantDependencies) === null || _value$assignedAttrib === void 0 ? void 0 : _value$assignedAttrib.length) > 0) {
                if (action.payload.dataValue.quantity === 1 && value.quantity === 0) {
                  indexDependencyToAdd = index;

                  if (dependenciesToAdd.length === 0) {
                    dependenciesToAdd = _toConsumableArray(value.assignedAttributeVariantDependencies);
                  } else {
                    dependenciesToAdd = [].concat(_toConsumableArray(dependenciesToAdd), _toConsumableArray(value.assignedAttributeVariantDependencies));
                  }
                }

                if (action.payload.dataValue.quantity === 0) {
                  if (dependenciesToDelete.length === 0) {
                    dependenciesToDelete = _toConsumableArray(value.assignedAttributeVariantDependencies);
                  } else {
                    dependenciesToDelete = [].concat(_toConsumableArray(dependenciesToDelete), _toConsumableArray(value.assignedAttributeVariantDependencies));
                  }
                }
              }

              if (action.payload.dataValue.quantity === 1 && state.maxSelected === 1) {
                var _value$assignedAttrib2;

                if (value.idValue === action.payload.dataValue.idValue) {
                  newTotalAmount = 1;
                  return _objectSpread(_objectSpread({}, value), action.payload.dataValue);
                }

                if (value.idValue !== action.payload.dataValue.idValue && value.quantity > 0 && (value === null || value === void 0 ? void 0 : (_value$assignedAttrib2 = value.assignedAttributeVariantDependencies) === null || _value$assignedAttrib2 === void 0 ? void 0 : _value$assignedAttrib2.length) > 0) {
                  if (dependenciesToDelete.length === 0) {
                    dependenciesToDelete = _toConsumableArray(value.assignedAttributeVariantDependencies);
                  } else {
                    dependenciesToDelete = [].concat(_toConsumableArray(dependenciesToDelete), _toConsumableArray(value.assignedAttributeVariantDependencies));
                  }
                }

                return _objectSpread(_objectSpread({}, value), {}, {
                  quantity: 0
                });
              }

              if (value.idValue === action.payload.dataValue.idValue) {
                newTotalAmount += action.payload.dataValue.quantity;
                return _objectSpread(_objectSpread({}, value), action.payload.dataValue);
              }

              newTotalAmount += value.quantity;
              return value;
            });

            if (state.minSelected >= 1 && newTotalAmount >= state.minSelected || state.minSelected <= 0) {
              newCheckMinTotalAmount = true;
            } else {
              newCheckMinTotalAmount = false;
            }

            return _objectSpread(_objectSpread({}, state), {}, {
              checkMinTotalAmount: newCheckMinTotalAmount,
              dataValues: newValues,
              totalAmount: newTotalAmount
            });
          }

          return state;
        });

        if (dependenciesToDelete.length > 0) {
          var idsToppingToDelete = dependenciesToDelete.map(function (dependencie) {
            return dependencie.attributeSettings.id;
          });
          newState = newState.filter(function (state) {
            return !idsToppingToDelete.includes(state.idTopping);
          });
        }

        if (dependenciesToAdd.length > 0) {
          var newToppings = [];
          dependenciesToAdd.forEach(function (dependency) {
            var totalAmount = 0;
            var checkMinTotalAmount = dependency.attributeSettings.minSelected <= 0;
            var dataValues = dependency.values.map(function (value) {
              return {
                assignedAttributeVariantDependencies: value.assignedAttributeVariantDependencies,
                descriptionValue: value.description,
                factor: value.factor,
                idValue: value.id,
                isActive: value.isActive,
                nameValue: value.name,
                orderValue: value.sortOrder || 10000000000,
                price: value.price,
                ref: value.ref,
                valueValue: value.value,
                quantity: 0
              };
            });

            if (newToppings.length === 0) {
              newToppings = [{
                checkMinTotalAmount: checkMinTotalAmount,
                dataValues: dataValues.sort(function (a, b) {
                  return a.orderValue - b.orderValue;
                }),
                idTopping: dependency.attributeSettings.id,
                maxSelected: dependency.attributeSettings.maxSelected,
                minSelected: dependency.attributeSettings.minSelected,
                name: dependency.attribute.name,
                slug: dependency.attribute.slug,
                toppingOrder: dependency.attributeSettings.sortOrder || 10000000000,
                toppingType: dependency.attributeSettings.attributeType,
                totalAmount: totalAmount
              }];
            } else {
              newToppings = [].concat(_toConsumableArray(newToppings), [{
                checkMinTotalAmount: checkMinTotalAmount,
                dataValues: dataValues.sort(function (a, b) {
                  return a.orderValue - b.orderValue;
                }),
                idTopping: dependency.attributeSettings.id,
                maxSelected: dependency.attributeSettings.maxSelected,
                minSelected: dependency.attributeSettings.minSelected,
                name: dependency.attribute.name,
                slug: dependency.attribute.slug,
                toppingOrder: dependency.attributeSettings.sortOrder || 10000000000,
                toppingType: dependency.attributeSettings.attributeType,
                totalAmount: totalAmount
              }]);
            }
          });
          newToppings = newToppings.sort(function (a, b) {
            return a.toppingOrder - b.toppingOrder;
          });

          if (indexDependencyToAdd >= 0) {
            var _newState;

            (_newState = newState).splice.apply(_newState, [indexDependencyToAdd + 1, 0].concat(_toConsumableArray(newToppings)));
          }
        }

        return newState;
      }

    case "reset":
      return initialState;

    default:
      return prevState;
  }
};