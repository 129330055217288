import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(255, 255, 255, 0.7);\n  z-index: 99999;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Loader = styled.div(_templateObject());