import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React from "react";
import { CheckoutAddress } from "@components/atoms";
import * as S from "./styles";

/**
 * Address tile option.
 */
var AddressTileOption = function AddressTileOption(_ref) {
  var id = _ref.id,
      inputName = _ref.inputName,
      address = _ref.address,
      defaultAddress = _ref.defaultAddress,
      onChange = _ref.onChange,
      checked = _ref.checked,
      testingContext = _ref.testingContext,
      props = _objectWithoutProperties(_ref, ["id", "inputName", "address", "defaultAddress", "onChange", "checked", "testingContext"]);

  return React.createElement(S.Label, {
    checked: !!checked,
    "data-test": testingContext ? "".concat(testingContext, "AddressTile") : "addressTile",
    "data-test-id": id,
    htmlFor: id,
    defaultAddress: defaultAddress
  }, React.createElement(S.Input, _extends({}, props, {
    type: "radio",
    name: inputName,
    value: id,
    checked: checked,
    onChange: onChange,
    id: id
  })), React.createElement(CheckoutAddress, address));
};

export { AddressTileOption };