import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "./scss/index.scss";
import React from "react";
import ReactSVG from "react-svg";
import { useIntl } from "react-intl";
import { useAppSelector, updateSearchTitleHeight, useAppDispatch } from "@temp/redux";
import { useGoBack } from "@temp/utils";
import { commonMessages } from "@temp/intl";
import { Search } from "..";
import goBackImg from "../../images/arrowBack.svg";
export var SearchHeaderDesktop = function SearchHeaderDesktop(_ref) {
  var category = _ref.category,
      home = _ref.home,
      sale = _ref.sale,
      saleCategory = _ref.saleCategory,
      subCategory = _ref.subCategory,
      redirect = _ref.redirect,
      redirectHome = _ref.redirectHome;
  var intl = useIntl();

  var _React$useState = React.useState(0),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      scrollPosition = _React$useState2[0],
      setScrollPosition = _React$useState2[1];

  var _React$useState3 = React.useState(false),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      showCategoriesFilter = _React$useState4[0],
      setShowCategoriesFilter = _React$useState4[1];

  var dispatch = useAppDispatch();

  var _useGoBack = useGoBack(),
      goBackCategory = _useGoBack.goBackCategory,
      goBackCategoriesSale = _useGoBack.goBackCategoriesSale,
      goBackSubCategoriesCategory = _useGoBack.goBackSubCategoriesCategory,
      goBackCategoryRedirectSale = _useGoBack.goBackCategoryRedirectSale;

  var _useAppSelector = useAppSelector(function (store) {
    return store;
  }),
      _useAppSelector$searc = _useAppSelector.search,
      searchCategory = _useAppSelector$searc.searchCategory,
      searchSubcategory = _useAppSelector$searc.searchSubcategory,
      hasAttentionAtCurrentTime = _useAppSelector.tradeAttention.hasAttentionAtCurrentTime;

  React.useLayoutEffect(function () {
    dispatch(updateSearchTitleHeight({
      searchTitleHeight: document.getElementById("searchTitle") && document.getElementById("searchTitle").clientHeight - 44 // 44 corresponde a la altura estandar del título con una sola línea

    }));
    return function () {};
  }, [sale, saleCategory, category, subCategory]);
  React.useEffect(function () {
    var handleScroll = function handleScroll() {
      var position = window.pageYOffset;

      if (position > 0 && scrollPosition === 0 || position === 0 && scrollPosition > 0) {
        setScrollPosition(position);
      } else if (position <= 0) {
        setScrollPosition(0);
      }
    };

    window.addEventListener("scroll", handleScroll, {
      passive: true
    });
    return function () {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return React.createElement("div", {
    className: "categories-page headerContainerSaleCategory ".concat(scrollPosition > 0 && "headerContainerSaleCategory__boxShadow", "\n            ").concat(!hasAttentionAtCurrentTime && "headerContainerSaleCategory__hasNotAttention", " ").concat(showCategoriesFilter && "headerContainerSaleCategory__open")
  }, React.createElement("div", {
    className: "headerContainerSaleCategory__buttonGoBackUbication"
  }, (saleCategory || subCategory) && React.createElement("div", {
    onClick: function onClick() {
      if (saleCategory) {
        if (redirect) {
          goBackCategoryRedirectSale();
        } else {
          goBackCategoriesSale();
        }
      } else if (subCategory) {
        if (redirect && !redirectHome) {
          goBackSubCategoriesCategory();
        } else {
          goBackCategoriesSale();
        }
      }
    },
    className: "headerContainerSaleCategory__buttonGoBack"
  }, React.createElement(ReactSVG, {
    path: goBackImg
  }), React.createElement("p", null, intl.formatMessage(commonMessages.back))), category && (redirect ? React.createElement("div", null, " ") : React.createElement("div", {
    onClick: goBackCategory,
    className: "headerContainerSaleCategory__buttonGoBack"
  }, React.createElement(ReactSVG, {
    path: goBackImg
  }), React.createElement("p", null, intl.formatMessage(commonMessages.back)))), (sale || home) && React.createElement("div", {
    className: "headerContainerSaleCategory__buttonGoBack"
  })), React.createElement("div", {
    className: "headerContainerSaleCategory__search"
  }, React.createElement(Search, {
    showCategoriesFilter: showCategoriesFilter,
    setShowCategoriesFilter: setShowCategoriesFilter
  })), sale && React.createElement("h1", {
    id: "searchTitle"
  }, intl.formatMessage(commonMessages.sale)), (saleCategory || category) && React.createElement("h1", {
    id: "searchTitle"
  }, searchCategory.name), subCategory && React.createElement("h1", {
    id: "searchTitle"
  }, searchSubcategory.name));
};