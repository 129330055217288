import "./scss/index.scss";
import React, { useCallback, useEffect, useRef } from "react";
import ReactSVG from "react-svg";
import { useIntl } from "react-intl";
import { commonMessages } from "@temp/intl";
import { debounce } from "@temp/core/utils";
import { handleModal, useAppDispatch, useAppSelector, updateInputValue, updateQueryValue } from "@temp/redux";
import closeThinImg from "../../images/close_search.svg";
import filterImg from "../../images/search/filter.svg";
import searchImg from "../../images/search/search.svg";
import { AlertFiltersAll } from "./AlertFiltersAll";
import { AlertFiltersSale } from "./AlertFiltersSale";
export var Search = function Search(_ref) {
  var showCategoriesFilter = _ref.showCategoriesFilter,
      setShowCategoriesFilter = _ref.setShowCategoriesFilter;
  var searchInputRef = useRef(null);

  var _useAppSelector = useAppSelector(function (store) {
    return store.search;
  }),
      searchInputValue = _useAppSelector.searchInputValue,
      searchQueryValue = _useAppSelector.searchQueryValue;

  var dispatch = useAppDispatch();
  var intl = useIntl();
  var handleErase = useCallback(function () {
    searchInputRef.current.value = "";
    dispatch(updateInputValue({
      searchInputValue: ""
    }));
    dispatch(updateQueryValue());
  }, []);

  var handleOnChange = function handleOnChange(e) {
    dispatch(updateInputValue({
      searchInputValue: e.target.value
    }));
    dispatch(updateQueryValue());
  };

  var onOptimisedHandleChange = debounce(handleOnChange, 800);
  useEffect(function () {
    if (searchInputValue === "") {
      searchInputRef.current.value = "";
    }

    localStorage.removeItem("home_cache");
    localStorage.removeItem("sale_cache");
    localStorage.removeItem("products_cache");
    localStorage.removeItem("category_sale_cache");
    localStorage.removeItem("category_products_cache");
    localStorage.removeItem("subcategory_products_cache");
  }, [searchInputValue]);
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "search"
  }, React.createElement(ReactSVG, {
    className: "".concat(searchInputValue ? "search__close--enabled" : "search__close--disabled"),
    path: closeThinImg,
    onClick: function onClick() {
      return handleErase();
    }
  }), React.createElement("div", {
    className: "search__inputContainer"
  }, React.createElement(ReactSVG, {
    className: "search__clickSearch",
    path: searchImg
  }), React.createElement("input", {
    type: "text",
    ref: searchInputRef,
    defaultValue: searchQueryValue,
    placeholder: intl.formatMessage(commonMessages.search),
    onChange: onOptimisedHandleChange
  })), React.createElement(ReactSVG, {
    className: "search__filter",
    path: filterImg,
    onClick: function onClick() {
      dispatch(handleModal({
        openModal: true
      }));
      setShowCategoriesFilter(true);
      document.body.style.overflow = "hidden";
    }
  })), showCategoriesFilter && (location.pathname.includes("sale") ? React.createElement(AlertFiltersSale, {
    hideModal: function hideModal() {
      dispatch(handleModal({
        openModal: false
      }));
      setShowCategoriesFilter(false);
      document.body.style.overflow = "auto";
    }
  }) : React.createElement(AlertFiltersAll, {
    hideModal: function hideModal() {
      dispatch(handleModal({
        openModal: false
      }));
      setShowCategoriesFilter(false);
      document.body.style.overflow = "auto";
    }
  })));
};