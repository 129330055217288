import * as React from "react";
import { useAuth } from "@saleor/sdk";
import { MobileNavList, Overlay } from "..";

var MobileNav = function MobileNav(_ref) {
  var overlay = _ref.overlay;
  var items = overlay.context.data;

  var _useAuth = useAuth(),
      user = _useAuth.user;

  return React.createElement(Overlay, {
    testingContext: "mobileNavigationOverlay",
    context: overlay
  }, React.createElement("div", {
    className: "side-nav",
    onClick: function onClick(evt) {
      return evt.stopPropagation();
    }
  }, React.createElement(MobileNavList, {
    items: items,
    hideOverlay: overlay.hide,
    user: user
  })));
};

export default MobileNav;