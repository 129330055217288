import "./scss/index.scss";
import React from "react";
import ReactSVG from "react-svg";
import { FormattedMessage } from "react-intl";
import { Button } from "@components/atoms";

var closeWhite = require("../../images/closewhite.svg");

export var AlertReasonIdentityCard = function AlertReasonIdentityCard(_ref) {
  var hideModal = _ref.hideModal,
      reason = _ref.reason;
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "showContainer",
    onClick: hideModal
  }), React.createElement("div", {
    className: "showContainer__data"
  }, React.createElement("div", {
    className: "showContainer__data__close"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.AlertReasonIdentityCard.index.2731129013",
    defaultMessage: "Obligatory field"
  })), React.createElement(ReactSVG, {
    path: closeWhite,
    onClick: hideModal
  })), React.createElement("div", {
    className: "showContainer__data__content showContainer__data__content--value showContainer__data__content--first showContainer__data__content--last"
  }, reason), React.createElement("div", {
    className: "showContainer__data__footer"
  }, React.createElement(Button, {
    type: "button",
    testingContext: "",
    onClick: hideModal
  }, React.createElement(FormattedMessage, {
    id: "components.AlertReasonIdentityCard.index.3577703060",
    defaultMessage: "Ok"
  })))));
};
export default AlertReasonIdentityCard;