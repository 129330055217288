import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import * as React from "react";
import TextField from "../TextField";
import DebounceChange from "./DebounceChange";

var DebouncedTextField = function DebouncedTextField(props) {
  var time = props.time,
      resetValue = props.resetValue,
      iconLeftRef = props.iconLeftRef,
      originalValue = props.value,
      inputRef = props.inputRef,
      onChange = props.onChange,
      onClickIconRight = props.onClickIconRight,
      onClickIconLeft = props.onClickIconLeft,
      textFieldProps = _objectWithoutProperties(props, ["time", "resetValue", "iconLeftRef", "value", "inputRef", "onChange", "onClickIconRight", "onClickIconLeft"]);

  return React.createElement(DebounceChange, {
    resetValue: resetValue,
    debounce: onChange,
    time: time,
    value: originalValue
  }, function (_ref) {
    var change = _ref.change,
        value = _ref.value;
    return React.createElement(TextField, _extends({}, textFieldProps, {
      iconLeftRef: iconLeftRef,
      inputRef: inputRef,
      value: value,
      onChange: change,
      onClickIconRight: onClickIconRight,
      onClickIconLeft: onClickIconLeft
    }));
  });
};

DebouncedTextField.defaultProps = {
  time: 250
};
export default DebouncedTextField;