import "./scss/index.scss";
import React from "react";
import ReactSVG from "react-svg";
import { FormattedMessage } from "react-intl";
import { Button } from "@components/atoms";

var closeWhite = require("../../images/closewhite.svg"); // const greaterThanEqual = require("../../images/greaterThanEqual.svg");


export var AlertCheckoutAmount = function AlertCheckoutAmount(_ref) {
  var hideModal = _ref.hideModal,
      minItems = _ref.minItems;
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "showContainer",
    onClick: hideModal
  }), React.createElement("div", {
    className: "showContainer__data"
  }, React.createElement("div", {
    className: "showContainer__data__close"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.AlertCheckoutAmount.index.709932324",
    defaultMessage: "Alert"
  })), React.createElement(ReactSVG, {
    path: closeWhite,
    onClick: hideModal
  })), React.createElement("div", {
    className: "showContainer__data__content showContainer__data__content--first"
  }), React.createElement("div", {
    className: "showContainer__data__content  showContainer__data__content--amount showContainer__data__content--first showContainer__data__content--last"
  }, React.createElement(FormattedMessage, {
    id: "components.AlertCheckoutAmount.index.1550747014",
    defaultMessage: "The minimum number of items per purchase is <span>{minItems}</span>",
    description: "Alert minimum per purchase",
    values: {
      minItems: minItems,
      span: function span() {
        for (var _len = arguments.length, chunks = new Array(_len), _key = 0; _key < _len; _key++) {
          chunks[_key] = arguments[_key];
        }

        return React.createElement("span", null, chunks);
      }
    }
  })), React.createElement("div", {
    className: "showContainer__data__footer"
  }, React.createElement(Button, {
    type: "button",
    testingContext: "",
    onClick: hideModal
  }, React.createElement(FormattedMessage, {
    id: "components.AlertCheckoutAmount.index.321321683",
    defaultMessage: "Acept"
  })))));
};
export default AlertCheckoutAmount;