import React from "react";
import { FormattedMessage } from "react-intl";
import ReactSVG from "react-svg";
import Media from "react-responsive";
import { xMediumScreen } from "@styles/constants";
import { CachedImage, StatusData, StatusDataFooter } from "@components/molecules";
import { statusMessages } from "@temp/intl";
import { maybe } from "../../../../core/utils";
import * as S from "./styles";

var logoImg = require("../../../../images/logo.svg");

var logoShopImg = require("../../../../images/shopWithOutLogo.svg");

var loading = require("../../../../images/searchOrder.gif");

var goBackImg = require("../../../../images/arrowBack.svg");

/**
 * draft status structure
 */
var StatusDraft = function StatusDraft(_ref) {
  var _data$trade;

  var data = _ref.data,
      goBack = _ref.goBack,
      navigation = _ref.navigation;
  return React.createElement(S.Wrapper, null, React.createElement(S.Tile, null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(S.Title, null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(S.BackTitle, {
    onClick: goBack
  }, React.createElement(ReactSVG, {
    path: goBackImg
  }), React.createElement(S.BackTitleParragraph, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.StatusDraft.StatusDraft.3471378517",
    defaultMessage: "back"
  })))), React.createElement(FormattedMessage, statusMessages.stepNameDraft)))), React.createElement(S.Content, null, React.createElement(Media, {
    maxWidth: xMediumScreen
  }, React.createElement(S.Navigation, null, navigation)), React.createElement(Media, {
    maxWidth: xMediumScreen
  }, React.createElement(S.Title, null, React.createElement(FormattedMessage, statusMessages.stepNameDraft))), React.createElement(S.ContentContainer, null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(S.Navigation, null, navigation)), React.createElement(S.Images, null, React.createElement(S.Image, null, React.createElement(ReactSVG, {
    path: logoImg
  })), React.createElement(S.Image, null, React.createElement(S.Gif, null, React.createElement("img", {
    alt: "cargando",
    src: loading
  }))), React.createElement(S.Image, null, (data === null || data === void 0 ? void 0 : (_data$trade = data.trade) === null || _data$trade === void 0 ? void 0 : _data$trade.logoImage) ? React.createElement(S.ImageShop, null, React.createElement(CachedImage, {
    url: maybe(function () {
      var _data$trade2, _data$trade2$logoImag;

      return data === null || data === void 0 ? void 0 : (_data$trade2 = data.trade) === null || _data$trade2 === void 0 ? void 0 : (_data$trade2$logoImag = _data$trade2.logoImage) === null || _data$trade2$logoImag === void 0 ? void 0 : _data$trade2$logoImag.url;
    }),
    alt: maybe(function () {
      var _data$trade3, _data$trade3$logoImag, _data$trade4, _data$trade4$logoImag;

      return (data === null || data === void 0 ? void 0 : (_data$trade3 = data.trade) === null || _data$trade3 === void 0 ? void 0 : (_data$trade3$logoImag = _data$trade3.logoImage) === null || _data$trade3$logoImag === void 0 ? void 0 : _data$trade3$logoImag.alt) ? data === null || data === void 0 ? void 0 : (_data$trade4 = data.trade) === null || _data$trade4 === void 0 ? void 0 : (_data$trade4$logoImag = _data$trade4.logoImage) === null || _data$trade4$logoImag === void 0 ? void 0 : _data$trade4$logoImag.alt : "";
    }, "")
  }, React.createElement(React.Fragment, null))) : React.createElement(ReactSVG, {
    path: logoShopImg
  }))), React.createElement(S.Parragraph, {
    "data-test": "checkoutPageSubtitle"
  }, React.createElement(FormattedMessage, statusMessages.stepNameDraftWaiting)))), React.createElement(S.Aside, null, React.createElement(StatusData, {
    deliveryPhone: false,
    data: data,
    deliveryData: false
  })), React.createElement(S.Footer, null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(StatusDataFooter, {
    data: data
  }))));
};

export { StatusDraft };