import React from "react";
import { FormattedMessage } from "react-intl";

var ReportViewMobile = function ReportViewMobile(props) {
  var _data$deliverymanSumm, _data$deliverymanSumm2;

  var data = props.data,
      totalQuantity = props.totalQuantity,
      changeFilter = props.changeFilter;
  return React.createElement("div", {
    className: changeFilter ? "overlayReport" : ""
  }, changeFilter && React.createElement("div", {
    className: "infoText alertBorder"
  }, React.createElement("p", null, React.createElement("span", {
    role: "img",
    "aria-label": "Pointing up"
  }, "\u261D\uFE0F")), React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "views.ReportSummaryByArea.ReportViewMobile.3690815071",
    defaultMessage: "Tap the filter button to apply the change"
  }))), React.createElement("div", {
    className: "reportMobile"
  }, React.createElement(FormattedMessage, {
    id: "views.ReportSummaryByArea.ReportViewMobile.75546233",
    defaultMessage: "Amount"
  }), ":", " ", React.createElement("span", {
    className: "totalQuantity"
  }, totalQuantity), (data === null || data === void 0 ? void 0 : (_data$deliverymanSumm = data.deliverymanSummaryByShippingZone) === null || _data$deliverymanSumm === void 0 ? void 0 : _data$deliverymanSumm.length) > 0 ? React.createElement(React.Fragment, null, data === null || data === void 0 ? void 0 : (_data$deliverymanSumm2 = data.deliverymanSummaryByShippingZone) === null || _data$deliverymanSumm2 === void 0 ? void 0 : _data$deliverymanSumm2.map(function (item, index) {
    return React.createElement("div", {
      className: "content",
      key: index
    }, React.createElement("div", {
      key: index + 1,
      className: "report"
    }, React.createElement("div", {
      className: "titleReport"
    }, React.createElement("p", null, React.createElement(FormattedMessage, {
      id: "views.ReportSummaryByArea.ReportViewMobile.140514590",
      defaultMessage: "Zone"
    }), " ", item === null || item === void 0 ? void 0 : item.shippingZoneName)), React.createElement("hr", {
      style: {
        width: "95%"
      }
    }), React.createElement("div", {
      className: "reportBody"
    }, React.createElement("p", {
      className: "labels"
    }, React.createElement(FormattedMessage, {
      id: "views.ReportSummaryByArea.ReportViewMobile.75546233",
      defaultMessage: "Amount"
    }), ":"), React.createElement("p", {
      className: "labels"
    }, item === null || item === void 0 ? void 0 : item.quantity))));
  })) : React.createElement("p", {
    className: "noData"
  }, React.createElement(FormattedMessage, {
    id: "views.ReportSummaryByArea.ReportViewMobile.4205644805",
    defaultMessage: "No results found"
  }))));
};

ReportViewMobile.displayName = "ReportViewMobile";
export default ReportViewMobile;