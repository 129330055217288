import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { useSaleProduct } from "./useSaleProduct";
export var useProductData = function useProductData(productVariantId, items, productVariantActual, lineId) {
  var _productVariantActual, _productVariantActual2, _productVariantActual3, _productVariantActual4, _productVariantActual5;

  var itemsQuantity = null;
  var itemsObservation = "null";
  var itemsToppings = [];
  var line = [];

  if (lineId && items && items.length > 0) {
    var _line$, _line$2, _line$3;

    line = items === null || items === void 0 ? void 0 : items.filter(function (itemsCart) {
      return (itemsCart === null || itemsCart === void 0 ? void 0 : itemsCart.id.toString()) === lineId.toString();
    });
    itemsQuantity = (_line$ = line[0]) === null || _line$ === void 0 ? void 0 : _line$.quantity;
    itemsObservation = (_line$2 = line[0]) === null || _line$2 === void 0 ? void 0 : _line$2.observation;

    if (typeof ((_line$3 = line[0]) === null || _line$3 === void 0 ? void 0 : _line$3.attributes) === "string") {
      var _line$4;

      itemsToppings = JSON.parse((_line$4 = line[0]) === null || _line$4 === void 0 ? void 0 : _line$4.attributes);
    } else {
      var _line$5;

      itemsToppings = (_line$5 = line[0]) === null || _line$5 === void 0 ? void 0 : _line$5.attributes;
    }
  }

  var _useSaleProduct = useSaleProduct(productVariantActual),
      discountPercentage = _useSaleProduct.discountPercentage,
      discountValue = _useSaleProduct.discountValue;

  var defaultImageIndex = (productVariantActual === null || productVariantActual === void 0 ? void 0 : (_productVariantActual = productVariantActual.productImagesLightLoad) === null || _productVariantActual === void 0 ? void 0 : _productVariantActual.length) > 0 ? productVariantActual.productImagesLightLoad.findIndex(function (image) {
    return image.id === btoa("ProductImage:".concat(productVariantActual === null || productVariantActual === void 0 ? void 0 : productVariantActual.productDefaultImageIdLightLoad));
  }) : -1;
  var images = [];

  if (defaultImageIndex !== -1 && (productVariantActual === null || productVariantActual === void 0 ? void 0 : (_productVariantActual2 = productVariantActual.productImagesLightLoad) === null || _productVariantActual2 === void 0 ? void 0 : _productVariantActual2.length) > 0) {
    images = [productVariantActual === null || productVariantActual === void 0 ? void 0 : productVariantActual.productImagesLightLoad[defaultImageIndex]];
    productVariantActual === null || productVariantActual === void 0 ? void 0 : productVariantActual.productImagesLightLoad.splice(defaultImageIndex, 1);
    images = [].concat(_toConsumableArray(images), _toConsumableArray(productVariantActual === null || productVariantActual === void 0 ? void 0 : productVariantActual.productImagesLightLoad));
  } else if (defaultImageIndex === -1 && (productVariantActual === null || productVariantActual === void 0 ? void 0 : (_productVariantActual3 = productVariantActual.productImagesLightLoad) === null || _productVariantActual3 === void 0 ? void 0 : _productVariantActual3.length) > 0) {
    images = productVariantActual === null || productVariantActual === void 0 ? void 0 : productVariantActual.productImagesLightLoad;
  }

  var productFinalData = {
    id: productVariantActual === null || productVariantActual === void 0 ? void 0 : productVariantActual.id,
    name: (productVariantActual === null || productVariantActual === void 0 ? void 0 : productVariantActual.name) || (productVariantActual === null || productVariantActual === void 0 ? void 0 : productVariantActual.productNameLightLoad),
    description: (productVariantActual === null || productVariantActual === void 0 ? void 0 : (_productVariantActual4 = productVariantActual.description) === null || _productVariantActual4 === void 0 ? void 0 : _productVariantActual4.replace(/"/g, "")) || (productVariantActual === null || productVariantActual === void 0 ? void 0 : (_productVariantActual5 = productVariantActual.productDescription) === null || _productVariantActual5 === void 0 ? void 0 : _productVariantActual5.replace(/"/g, "")),
    price: productVariantActual === null || productVariantActual === void 0 ? void 0 : productVariantActual.priceLightLoad,
    discountPercentage: discountPercentage,
    discountValue: discountValue,
    itemsQuantity: itemsQuantity,
    itemsObservation: itemsObservation,
    itemsToppings: itemsToppings,
    line: line,
    hasAttributes: productVariantActual === null || productVariantActual === void 0 ? void 0 : productVariantActual.hasAttributeLightLoad,
    attributes: productVariantActual === null || productVariantActual === void 0 ? void 0 : productVariantActual.attributes,
    images: images,
    thumbnail: (productVariantActual === null || productVariantActual === void 0 ? void 0 : productVariantActual.thumbnailLightLoad) ? productVariantActual === null || productVariantActual === void 0 ? void 0 : productVariantActual.thumbnailLightLoad[0] : null,
    __typename: "Product",
    category: {
      id: productVariantActual === null || productVariantActual === void 0 ? void 0 : productVariantActual.categoryIdLightLoad,
      name: productVariantActual === null || productVariantActual === void 0 ? void 0 : productVariantActual.categoryNameLightLoad,
      __typename: "Category"
    }
  };
  return {
    productFinalData: productFinalData
  };
};