import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import * as React from "react"; // import { RouteComponentProps } from "react-router";

import { Skeleton } from "@components/atoms/Skeleton/Skeleton";
import { useCart } from "@saleor/sdk";
import { useSubCategoryData } from "@temp/hooks";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "@temp/redux";
import { PageSubCategory } from "@temp/views/subCategory";
import { MetaWrapper } from "..";
import { getGraphqlIdFromDBId } from "../../core/utils";
export var SubCategory = function SubCategory(_ref) {
  var _ProductsSubCategory$, _ProductsSubCategory$2, _data$pageInfo;

  var loading = _ref.loading,
      data = _ref.data,
      redirect = _ref.redirect,
      redirectHome = _ref.redirectHome,
      id = _ref.id,
      loadMore = _ref.loadMore,
      PRODUCTS_PER_PAGE = _ref.PRODUCTS_PER_PAGE;
  var location = useLocation();

  var _useCart = useCart(),
      items = _useCart.items;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      loadingMask = _React$useState2[0],
      setLoadingMask = _React$useState2[1];

  var _useAppSelector = useAppSelector(function (store) {
    return store.search;
  }),
      searchQueryValue = _useAppSelector.searchQueryValue;

  React.useEffect(function () {
    if (redirect && redirectHome) {
      localStorage.setItem("oneSubcategory", "true");
    }
  }, [redirect, redirectHome]);
  React.useEffect(function () {
    setLoadingMask(true);
  }, [id]);
  React.useEffect(function () {
    if (!localStorage.getItem("loadingLoadMoreSubCategory")) {
      if (!loading) {
        setLoadingMask(false);
      } else {
        setLoadingMask(true);
      }
    }

    if (localStorage.getItem("loadingLoadMoreSubCategory") && !loading) {
      localStorage.removeItem("loadingLoadMoreSubCategory");
    }
  }, [loading]);
  React.useEffect(function () {
    if (loadingMask) {
      window.scrollTo(0, 0);
    }
  }, [loadingMask]);
  React.useLayoutEffect(function () {
    localStorage.setItem("product_url", location.pathname);
    localStorage.setItem("subcategory_url", location.pathname);
    localStorage.removeItem("loadingLoadMoreSaleCategory");
    var otherProduct = localStorage.getItem("other-product");
    var scrollProduct = localStorage.getItem("scroll-product");

    if (scrollProduct !== null && otherProduct !== "true") {
      window.scrollTo(0, +scrollProduct);
      localStorage.removeItem("scroll-product");
    } else {
      window.scrollTo(0, 0);
    }

    if (otherProduct === "true") {
      localStorage.removeItem("other-product");
    }

    return function () {};
  }, []);

  if (loadingMask) {
    return React.createElement("div", {
      className: "categories-page"
    }, React.createElement("div", {
      className: "categories-pageCate__loading"
    }, React.createElement(Skeleton, null)));
  }

  var subCategoryId = getGraphqlIdFromDBId(id, "Category");

  var _useSubCategoryData = useSubCategoryData(data, items, subCategoryId),
      products = _useSubCategoryData.products,
      ProductsSubCategory = _useSubCategoryData.ProductsSubCategory;

  return React.createElement(MetaWrapper, {
    meta: {
      type: "product.category"
    }
  }, React.createElement(PageSubCategory, {
    redirect: redirect,
    redirectHome: redirectHome,
    category: (_ProductsSubCategory$ = ProductsSubCategory[0]) === null || _ProductsSubCategory$ === void 0 ? void 0 : _ProductsSubCategory$.category,
    subcategory: (_ProductsSubCategory$2 = ProductsSubCategory[0]) === null || _ProductsSubCategory$2 === void 0 ? void 0 : _ProductsSubCategory$2.subCategory,
    products: products,
    hasNextPage: data === null || data === void 0 ? void 0 : (_data$pageInfo = data.pageInfo) === null || _data$pageInfo === void 0 ? void 0 : _data$pageInfo.hasNextPage,
    displayLoader: loading,
    onLoadMore: function onLoadMore() {
      var _data$pageInfo2;

      localStorage.setItem("loadingLoadMoreSubCategory", "true");
      loadMore(function (prev, next) {
        return {
          subCategoryProductsLoadMore: _objectSpread(_objectSpread({}, prev.subCategoryProductsLoadMore), {}, {
            edges: [].concat(_toConsumableArray(prev.subCategoryProductsLoadMore.edges), _toConsumableArray(next.subCategoryProductsLoadMore.edges)),
            pageInfo: next.subCategoryProductsLoadMore.pageInfo
          })
        };
      }, {
        subCategoryId: +id,
        after: data === null || data === void 0 ? void 0 : (_data$pageInfo2 = data.pageInfo) === null || _data$pageInfo2 === void 0 ? void 0 : _data$pageInfo2.endCursor,
        perPage: PRODUCTS_PER_PAGE,
        search: searchQueryValue
      });
    }
  }));
};
export default SubCategory;