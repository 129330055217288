import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "./scss/index.scss";
import * as React from "react";
import Media from "react-responsive";
import { useIntl, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import ReactSVG from "react-svg";
import { useCart } from "@saleor/sdk";
import { xMediumScreen } from "@styles/constants";
import { commonMessages } from "@temp/intl";
import { getDBIdFromGraphqlId } from "@utils/core";
import { useAppDispatch, useAppSelector, updateCategorySearchValue } from "@temp/redux";
import { ProductList } from "../../@next/components/organisms";
import { generateSaleCategoryProductUrl } from "../../core/utils";
import { OrderButton, ProductNoFound, ProductsFeatured } from "../../components";

var emptyImg = require("../../images/emptySale.svg");

var Page = function Page(_ref) {
  var displayLoader = _ref.displayLoader,
      filters = _ref.filters,
      hasNextPage = _ref.hasNextPage,
      maxProductsPerCategory = _ref.maxProductsPerCategory,
      onLoadMore = _ref.onLoadMore,
      sales = _ref.sales;

  var _useCart = useCart(),
      items = _useCart.items;

  var hasProducts = !!sales && sales.products.length > 0;

  var _useAppSelector = useAppSelector(function (state) {
    return state;
  }),
      _useAppSelector$searc = _useAppSelector.search,
      searchQueryValue = _useAppSelector$searc.searchQueryValue,
      searchTitleHeight = _useAppSelector$searc.searchTitleHeight,
      hasAttentionAtCurrentTime = _useAppSelector.tradeAttention.hasAttentionAtCurrentTime;

  var intl = useIntl();
  var dispatch = useAppDispatch();

  var _React$useState = React.useState(0),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      scrollPosition = _React$useState2[0],
      setScrollPosition = _React$useState2[1];

  var _React$useState3 = React.useState(window.innerWidth <= 850 ? 130 : 40),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      marginTopState = _React$useState4[0],
      setMarginTopState = _React$useState4[1];

  React.useEffect(function () {
    var handleScroll = function handleScroll() {
      var position = window.pageYOffset;

      if (position > 0 && scrollPosition === 0 || position === 0 && scrollPosition > 0) {
        setScrollPosition(position);
      } else if (position <= 0) {
        setScrollPosition(0);
      }
    };

    window.addEventListener("scroll", handleScroll, {
      passive: true
    });
    return function () {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  React.useEffect(function () {
    var changeMarginTop = function changeMarginTop() {
      if (window.innerWidth <= 850 && marginTopState !== 160 + searchTitleHeight && hasAttentionAtCurrentTime) {
        setMarginTopState(160 + searchTitleHeight);
      } else if (window.innerWidth > 850 && marginTopState !== 270 + searchTitleHeight && hasAttentionAtCurrentTime) {
        setMarginTopState(270 + searchTitleHeight);
      } else if (window.innerWidth <= 850 && marginTopState !== 180 + searchTitleHeight && !hasAttentionAtCurrentTime) {
        setMarginTopState(180 + searchTitleHeight);
      } else if (window.innerWidth > 850 && marginTopState !== 290 + searchTitleHeight && !hasAttentionAtCurrentTime) {
        setMarginTopState(290 + searchTitleHeight);
      }
    };

    changeMarginTop();
    return function () {};
  }, [searchTitleHeight]);
  React.useEffect(function () {
    var changeMarginTop = function changeMarginTop() {
      if (window.innerWidth <= 850 && marginTopState !== 160 + searchTitleHeight && hasAttentionAtCurrentTime) {
        setMarginTopState(160 + searchTitleHeight);
      } else if (window.innerWidth > 850 && marginTopState !== 270 + searchTitleHeight && hasAttentionAtCurrentTime) {
        setMarginTopState(270 + searchTitleHeight);
      } else if (window.innerWidth <= 850 && marginTopState !== 180 + searchTitleHeight && !hasAttentionAtCurrentTime) {
        setMarginTopState(180 + searchTitleHeight);
      } else if (window.innerWidth > 850 && marginTopState !== 290 + searchTitleHeight && !hasAttentionAtCurrentTime) {
        setMarginTopState(290 + searchTitleHeight);
      }
    };

    changeMarginTop();
    window.addEventListener("resize", changeMarginTop);
    return function () {
      window.removeEventListener("resize", changeMarginTop);
    };
  }, []);
  React.useEffect(function () {
    var _sales$products;

    if ((sales === null || sales === void 0 ? void 0 : (_sales$products = sales.products) === null || _sales$products === void 0 ? void 0 : _sales$products.length) > 0) {
      var scrollSale = localStorage.getItem("scroll-sale");
      var scrollProduct = localStorage.getItem("scroll-product");

      if (!scrollSale && !scrollProduct && !(localStorage.getItem("sale_cache") === "true")) {
        window.scrollTo(0, 0);
      }

      if (scrollSale !== null) {
        window.scrollTo(0, +scrollSale);
        localStorage.removeItem("scroll-sale");
      }

      if (scrollProduct !== null) {
        window.scrollTo(0, +scrollProduct);
        localStorage.removeItem("scroll-product");
      }
    }
  }, [sales]);
  var handleOnClickLink = React.useCallback(function (category) {
    localStorage.setItem("category_sale_id", "".concat(getDBIdFromGraphqlId(category.id, "Category")));
    localStorage.setItem("scroll-sale", window.scrollY.toString());
    dispatch(updateCategorySearchValue({
      searchCategory: {
        name: category.name,
        value: category.id,
        subcategories: []
      }
    }));
  }, []);
  React.useLayoutEffect(function () {
    if (localStorage.getItem("sale_products_link")) {
      localStorage.removeItem("sale_products_link");
    }
  }, []);
  return React.createElement("div", {
    className: "category"
  }, React.createElement("div", {
    className: "container"
  }, sales && sales.products.length === 0 ? searchQueryValue === "" ? React.createElement("div", {
    className: "container-sale",
    style: {
      marginTop: marginTopState
    }
  }, React.createElement("div", {
    className: "sale__empty"
  }, React.createElement("div", {
    className: "sale__empty__img"
  }, React.createElement(ReactSVG, {
    path: emptyImg
  })), React.createElement("div", {
    className: "sale__empty__title"
  }, React.createElement(FormattedMessage, {
    id: "views.Sale.Page.298255923",
    defaultMessage: "No offers available"
  })))) : React.createElement("div", {
    className: "container-sale",
    style: {
      marginTop: marginTopState
    }
  }, React.createElement(ProductNoFound, null)) : React.createElement("div", {
    className: "container-sale",
    style: {
      marginTop: marginTopState
    }
  }, React.createElement(OrderButton, {
    amount: items !== undefined ? items.length : 0
  }), sales.products.map(function (data, index) {
    return React.createElement("div", {
      key: "sale-".concat(index)
    }, React.createElement("div", {
      className: "container-sale container-sale__perCategory container-sale__sign"
    }, React.createElement("h2", null, data.category.name, "\xA0\xA0\xA0\xA0"), React.createElement("div", null, " ")), React.createElement(Media, {
      maxWidth: xMediumScreen
    }, React.createElement(OrderButton, {
      amount: items !== undefined ? items.length : 0
    })), data.products.length > maxProductsPerCategory ? React.createElement(React.Fragment, null, React.createElement("div", {
      className: "container-sale__see-more"
    }, React.createElement(Link, {
      to: generateSaleCategoryProductUrl(data.category.id, data.category.name),
      onClick: function onClick() {
        handleOnClickLink(data.category);
      },
      key: data.category.id
    }, React.createElement(FormattedMessage, {
      id: "views.Sale.Page.207418477",
      defaultMessage: "See all"
    }))), React.createElement(ProductList, {
      seeMore: true,
      handleClickTo: function handleClickTo() {
        handleOnClickLink(data.category);
      },
      products: data.products.slice(0, maxProductsPerCategory),
      to: generateSaleCategoryProductUrl(data.category.id, data.category.name)
    })) : React.createElement(ProductList, {
      products: data.products
    }));
  }))), !hasProducts && searchQueryValue === "" && React.createElement(ProductsFeatured, {
    title: intl.formatMessage(commonMessages.youMightLike)
  }));
};

export default Page;