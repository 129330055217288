import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "./scss/styles.scss";
import backgroundMobile from "@temp/images/ecommerce/login/backgroundMobileLoading.svg";
import backgroundWhite from "@temp/images/ecommerce/login/backgroundDesktopLoading.svg";
import redMoped from "@temp/images/ecommerce/login/redMopedLoading.png";
import principleLogo from "@temp/images/ecommerce/login/logo-EasyPedidoCompleteLight.svg";
import securityLogo from "@temp/images/Seguridad.svg";
import React from "react";
import Media from "react-responsive";
import { xMediumScreen } from "@styles/constants";
import { Loader } from "@temp/components";
import { FormattedMessage, useIntl } from "react-intl";
import { animated, useSpring } from "react-spring";
import ReactSVG from "react-svg";
import { logInMessages } from "@temp/intl";
export var EcommerceLoginLoading = function EcommerceLoginLoading(_ref) {
  var loading = _ref.loading,
      messageError = _ref.messageError;
  var intl = useIntl();

  var _React$useState = React.useState(true),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      loadingInside = _React$useState2[0],
      setLoadingInside = _React$useState2[1];

  var scaleButton = useSpring({
    config: {
      friction: 40,
      mass: 5,
      tension: 350
    },
    from: {
      scale: 1.1
    },
    loop: true,
    to: {
      scale: 1
    }
  });
  React.useEffect(function () {
    var timeOutLoading = 0;

    if (!loading) {
      timeOutLoading = setTimeout(function () {
        setLoadingInside(false);
      }, 2000);
    }

    return function () {
      clearTimeout(timeOutLoading);
    };
  }, [loading]);
  return React.createElement("main", {
    className: "loginEcommerce"
  }, React.createElement(Media, {
    maxWidth: xMediumScreen
  }, function (matches) {
    return matches ? React.createElement("img", {
      className: "loginEcommerce__background",
      alt: "",
      src: backgroundMobile
    }) : React.createElement("img", {
      className: "loginEcommerce__background",
      alt: "",
      src: backgroundWhite
    });
  }), React.createElement("section", {
    className: "loginEcommerce__left"
  }, React.createElement("div", {
    className: "loginEcommerce__left__content"
  }, React.createElement("div", {
    className: "loginEcommerce__left__content__logo"
  }, React.createElement("img", {
    alt: "Easy Pedido",
    src: principleLogo
  })), React.createElement("form", {
    className: "loginEcommerce__left__content__form loginEcommerce__left__content__form__loading"
  }, React.createElement(Media, {
    maxWidth: xMediumScreen
  }, function (matches) {
    return matches && React.createElement("img", {
      className: "loginEcommerce__mopedDIA",
      src: redMoped,
      alt: ""
    });
  }), loadingInside ? React.createElement("div", {
    className: "loginEcommerce__loadingDIA"
  }, React.createElement(Loader, null), React.createElement("p", null, intl.formatMessage(logInMessages.loadingStore), "...")) : React.createElement(React.Fragment, null, React.createElement("p", {
    className: "loginEcommerce__errorsDIA"
  }, messageError), React.createElement(animated.input, {
    style: scaleButton,
    type: "submit",
    className: "loginEcommerce__left__content__doItAgain",
    onClick: function onClick() {
      return window.location.reload();
    },
    value: "Volver a intentar"
  })), React.createElement("div", {
    className: "loginEcommerce__safeDIA"
  }, React.createElement(ReactSVG, {
    path: securityLogo,
    className: "loginEcommerce__safeDIA__icon"
  }), React.createElement("p", {
    className: "loginEcommerce__safeDIA__text"
  }, React.createElement(FormattedMessage, {
    id: "views.Ecommerce.Login.EcommerceLoginLoading.291395769",
    defaultMessage: "100% Safe"
  })))))));
};
EcommerceLoginLoading.displayName = "EcommerceLoginLoading";