import React from "react";
import { CartRow } from "./CartRow";

var ProductList = function ProductList(_ref) {
  var lines = _ref.lines,
      hide = _ref.hide,
      onDataChange = _ref.onDataChange,
      remove = _ref.remove;
  return React.createElement("ul", {
    className: "cart__list"
  }, lines.map(function (line, index) {
    return React.createElement(CartRow, {
      key: "".concat(line === null || line === void 0 ? void 0 : line.id, "-cart"),
      index: index,
      line: line,
      onDataChange: onDataChange,
      remove: remove,
      hide: hide
    });
  }));
};

export default ProductList;