import React from "react";
import { FormattedMessage } from "react-intl";
import ReactSVG from "react-svg";
import { Button } from "@components/atoms";
import { Container } from "@components/templates";
import { checkoutMessages } from "@temp/intl";
import * as S from "./styles";

var thankYouImg = require("../../../../images/thankYouImg.svg");
/**
 * Thank you page after completing the checkout.
 */


var ThankYou = function ThankYou(_ref) {
  var orderNumber = _ref.orderNumber,
      continueShopping = _ref.continueShopping,
      orderDetails = _ref.orderDetails;
  return React.createElement(Container, {
    "data-test": "thankYouView"
  }, React.createElement(S.Wrapper, null, React.createElement(S.ThankYouImg, null, React.createElement(ReactSVG, {
    path: thankYouImg
  })), React.createElement(S.ThankYouHeader, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.ThankYou.ThankYou.251488531",
    defaultMessage: "Thank you for your order!"
  })), React.createElement(S.Paragraph, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.ThankYou.ThankYou.2999163789",
    defaultMessage: "Your order number is"
  }), " ", ": "), React.createElement(S.Paragraph, null, React.createElement("span", null, "#", orderNumber)), React.createElement(S.ParagraphSend, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.ThankYou.ThankYou.1839142789",
    defaultMessage: "The order is in progress"
  })), React.createElement(S.Buttons, null, React.createElement(Button, {
    testingContext: "continueShoppingButton",
    onClick: continueShopping,
    color: "secondary",
    fullWidth: true
  }, React.createElement(FormattedMessage, checkoutMessages.continueShopping)), React.createElement(Button, {
    testingContext: "gotoOrderDetailsButton",
    onClick: orderDetails,
    fullWidth: true
  }, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.ThankYou.ThankYou.3752098833",
    defaultMessage: "ORDER DETAILS"
  })))));
};

export { ThankYou };