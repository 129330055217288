import React from "react";
import Media from "react-responsive";
import { FormattedMessage } from "react-intl";
import { Button, Loader } from "@components/atoms";
import { OrderTabel } from "@components/molecules";
import { xLargeScreen } from "@styles/constants";
import * as S from "./styles";
export var OrderTable = function OrderTable(_ref) {
  var data = _ref.data,
      endCursor = _ref.endCursor,
      hasNextPage = _ref.hasNextPage,
      inProgressFlag = _ref.inProgressFlag,
      loadMore = _ref.loadMore,
      loading = _ref.loading;
  return React.createElement(React.Fragment, null, React.createElement(Media, {
    minWidth: xLargeScreen
  }, React.createElement(S.TableHead, null, React.createElement(S.HeadShop, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.OrderTable.OrderTable.580557469",
    defaultMessage: "establishment"
  })), React.createElement(S.HeadNumberOrder, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.OrderTable.OrderTable.4220354369",
    defaultMessage: "order number"
  })), React.createElement(S.HeadOrder, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.OrderTable.OrderTable.2768410132",
    defaultMessage: "order"
  })), React.createElement(S.HeadStatus, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.OrderTable.OrderTable.3394423989",
    defaultMessage: "status"
  })), React.createElement(S.HeadDate, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.OrderTable.OrderTable.718402331",
    defaultMessage: "date"
  })), React.createElement(S.HeadDetail, null, " "))), React.createElement(OrderTabel, {
    orders: data === null || data === void 0 ? void 0 : data.edges,
    inProgressFlag: inProgressFlag
  }), loading ? React.createElement(S.Loader, null, React.createElement(Loader, null)) : hasNextPage && React.createElement(S.Wrapper, null, React.createElement(Button, {
    testingContext: "loadMoreOrdersButton",
    onClick: function onClick() {
      if (loadMore) {
        loadMore({
          after: endCursor
        });
      }
    }
  }, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.OrderTable.OrderTable.1138265409",
    defaultMessage: "Load more"
  }))));
};
OrderTable.displayName = "OrderTable";