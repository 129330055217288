import React from "react";
import ReactSVG from "react-svg";
import { FormattedMessage } from "react-intl";
import { Button } from "@components/atoms";
import removeImg from "../../../../images/basurita20x20.svg";
import * as S from "./styles"; // import { generatePath, Link } from "react-router-dom";
// import { cartUrl } from "../../../../app/routes";

export var RemoveFromCartButton = function RemoveFromCartButton(_ref) {
  var onSubmit = _ref.onSubmit;
  return React.createElement(S.Button, null, React.createElement(Button, {
    fullWidth: true,
    testingContext: "editProductCartButton",
    onClick: onSubmit,
    style: {
      height: "40px",
      padding: "0",
      marginTop: "10px",
      backgroundColor: "#F76268"
    }
  }, React.createElement(ReactSVG, {
    path: removeImg
  }), React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.RemoveFromCartButton.RemoveFromCartButton.2703758654",
    defaultMessage: "Remove"
  }))));
};
RemoveFromCartButton.displayName = "EditCartButton";
export default RemoveFromCartButton;