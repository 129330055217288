import Media from "react-media";
import React from "react";
import { ProductObservation } from "@components/molecules";
import { Toppings } from "@temp/components";
import { xMediumScreen } from "@styles/constants";
export var ProductObservationsToppings = React.memo(function (_ref) {
  var checkProductObservation = _ref.checkProductObservation,
      desktop = _ref.desktop,
      obligatoryButton = _ref.obligatoryButton,
      productObservationMessage = _ref.productObservationMessage,
      hasAttributes = _ref.hasAttributes,
      loadingNetwork = _ref.loadingNetwork,
      itemsToppings = _ref.itemsToppings,
      toppingsProduct = _ref.toppingsProduct,
      handleChangeCheckProductObservation = _ref.handleChangeCheckProductObservation,
      handleAllowAddProduct = _ref.handleAllowAddProduct,
      handleSetProductObservationMessage = _ref.handleSetProductObservationMessage,
      handleUpdateToppingsToAddAndProductPrice = _ref.handleUpdateToppingsToAddAndProductPrice;
  return React.createElement(Media, {
    query: {
      maxWidth: xMediumScreen
    }
  }, function (matches) {
    return matches ? React.createElement(React.Fragment, null, hasAttributes && !loadingNetwork && React.createElement(Toppings, {
      obligatoryButton: obligatoryButton,
      handleAllowAddProduct: handleAllowAddProduct,
      handleUpdateToppingsToAddAndProductPrice: handleUpdateToppingsToAddAndProductPrice,
      line: itemsToppings,
      toppingsProduct: toppingsProduct
    }), React.createElement(ProductObservation, {
      checkProductObservation: checkProductObservation,
      desktop: desktop,
      productObservationMessage: productObservationMessage,
      handleChangeCheckProductObservation: handleChangeCheckProductObservation,
      handleSetProductObservationMessage: handleSetProductObservationMessage
    })) : React.createElement(React.Fragment, null, React.createElement(ProductObservation, {
      checkProductObservation: checkProductObservation,
      desktop: desktop,
      productObservationMessage: productObservationMessage,
      handleChangeCheckProductObservation: handleChangeCheckProductObservation,
      handleSetProductObservationMessage: handleSetProductObservationMessage
    }), hasAttributes && !loadingNetwork && React.createElement(Toppings, {
      obligatoryButton: obligatoryButton,
      handleAllowAddProduct: handleAllowAddProduct,
      handleUpdateToppingsToAddAndProductPrice: handleUpdateToppingsToAddAndProductPrice,
      line: itemsToppings,
      toppingsProduct: toppingsProduct
    }));
  });
});
ProductObservationsToppings.displayName = "ProductObservationsToppings";